import React, { useState } from "react";
import { getUrlParam } from "../../utils/Utils";
import { useHistory } from "react-router-dom";
import { deleteContent } from "services/NexiDigitalAPI";
import { CircularProgress } from "@material-ui/core";
import ModalWarning from "components/ModalWarning";

type Props = {
  idContentItem: number;
  isNode: boolean;
  closeModal: () => void;
};

const CancelButton = ({ idContentItem, isNode, closeModal }: Props) => {
  const isCreating = Boolean(getUrlParam("isCreating"));
  const history = useHistory();
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const _deleteContent = () => {
    setIsLoadingDelete(true);
    deleteContent(idContentItem).finally(() => {
      if (isNode) {
        closeModal();
      } else {
        history.goBack();
      }
      setIsLoadingDelete(false);
    });
  };

  return (
    <>
      {showWarningModal && (
        <ModalWarning
          description={
            <div style={{ textAlign: "center" }}>
              Tem certeza que deseja continuar? As informações serão perdidas.
            </div>
          }
          acceptAction={() => {
            _deleteContent();
            setShowWarningModal(false);
          }}
          denyAction={() => setShowWarningModal(false)}
          denyText="Cancelar"
        />
      )}
      {isCreating || isNode ? (
        <button
          type="button"
          className="btn btn-secondary mr-3"
          style={{
            color: "white",
            borderColor: "black",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => setShowWarningModal(true)}
        >
          Cancelar
          {isLoadingDelete && (
            <CircularProgress style={{ color: "white", width: 16, height: 16, marginLeft: 5 }} />
          )}
        </button>
      ) : (
        <button
          type="button"
          className="btn content-page-cancel-button mr-3"
          style={{
            color: "black",
            borderColor: "black",
          }}
          onClick={() => {
            history.goBack();
          }}
        >
          Voltar
        </button>
      )}
    </>
  );
};

export default CancelButton;
