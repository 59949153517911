import React, { useState, useEffect } from "react";

import { fabric } from "fabric";

import { useKeyUp, useKeyDown } from "./useKey";
import { imgBASE64 } from "../Utils/imgBASE64";

export const usePanelBar = (
  canvas,
  setFontSizeEvent,
  propStyle,
  setPropStyle,
  bgChange,
  tempJson,
  inputActive,
  syllabusState,
  setSyllabusState,
) => {
  const [clipboardElement, setClipboardElement] = useState(null);
  const [canvasInit, setCanvasInit] = useState(null);
  const [undo, setUndo] = useState([]);
  const [redo, setRedo] = useState([]);

  useEffect(() => {
    if (canvas && !canvasInit) {
      canvas.on("object:modified", function (obj) {
        save();
      });

      setCanvasInit(true);
    }
  }, [canvas, canvasInit]);

  useEffect(() => {
    if (bgChange) {
      save();
    }
  }, [bgChange]);

  useEffect(() => {
    if (tempJson) {
      // console.log(tempJson);
      setUndo((it) => [...it, tempJson]);
    }
  }, [tempJson]);

  useEffect(() => {
    if (inputActive) {
      canvas.discardActiveObject().renderAll();
    }
  }, [inputActive]);

  useKeyUp(
    () => {
      deleteActiveObject();
    },
    ["Backspace", "Delete"],
    inputActive,
    canvas,
  );

  useKeyDown(
    (event, currentKey) => {
      if ((event.metaKey && /Mac OS/.test(navigator.userAgent)) || event.ctrlKey) {
        if (currentKey === "c") {
          copy();
        } else if (currentKey === "x") {
          cut();
        } else if (currentKey === "v") {
          paste();
        } else if (currentKey === "z") {
          if (undo.length > 1) replay("undo");
        } else if (currentKey === "y") {
          if (redo.length > 0) replay("redo");
        }
      }
    },
    ["c", "v", "x", "z", "y"],
    inputActive,
    canvas,
  );

  const createImg = (e) => {
    imgBASE64(e, "image", canvas, () => {
      save();
    });
  };

  const cut = () => {
    if (canvas.getActiveObject() === null) {
      return;
    }
    canvas.getActiveObject().clone(function (cloned) {
      setClipboardElement(cloned);
      //remove after cloned to clipboard
      canvas.remove(canvas.getActiveObject());
    });
  };

  const copy = () => {
    canvas.getActiveObject().clone(function (cloned) {
      setClipboardElement(cloned);
    });
  };

  const copyAlt = () => {
    canvas.getActiveObject().clone(function (clonedObj) {
      canvas.discardActiveObject();
      clonedObj.set({
        lockRotation: clonedObj.type === "textbox" ? true : false,
        lockScalingY: clonedObj.type === "textbox" ? true : false,
        evented: true,
      });

      canvas.setActiveObject(clonedObj);
      canvas.requestRenderAll();

      canvas.add(clonedObj);

      save();
    });
  };

  const paste = () => {
    // clone again, so you can do multiple copies.
    clipboardElement.clone(function (clonedObj) {
      canvas.discardActiveObject();

      clonedObj.set({
        left: clonedObj.left + 10,
        top: clonedObj.top + 10,
        lockRotation: clonedObj.type === "textbox" ? true : false,
        lockScalingY: clonedObj.type === "textbox" ? true : false,
        evented: true,
      });

      canvas.add(clonedObj);

      clipboardElement.top += 10;
      clipboardElement.left += 10;
      canvas.setActiveObject(clonedObj);
      canvas.requestRenderAll();

      save();
    });
  };

  const save = () => {
    setRedo([]);
    setUndo((it) => [...it, JSON.stringify(canvas)]);
  };

  const replay = (status) => {
    let elem = [...redo];
    let last = elem.pop();
    let elemCurrent = null;

    if (status === "undo" && undo.length >= 1) {
      elem = [...undo];
      last = elem.pop();

      elemCurrent = elem.length - 1 < 0 ? undo[0] : elem[elem.length - 1];

      setRedo((it) => [...it, last]);
      setUndo(elem);
    } else if (status === "redo" && redo.length >= 1) {
      elem = [...redo];
      last = elem.pop();
      elemCurrent = elem.length - 1 < 0 ? redo[0] : elem[elem.length - 1];

      setUndo((it) => [...it, last]);
      setRedo(elem);
    }

    canvas.clear();
    canvas.loadFromJSON(elemCurrent, canvas.renderAll.bind(canvas), (o, object) => {
      canvas.renderAll();
    });
  };

  const changeProp = (prop, value, setStateChange = null) => {
    if (prop !== "fontSize") setFontSizeEvent(false);

    if (setStateChange) setStateChange(value);
    const object = canvas.getActiveObject();

    if (!object) return;

    if (prop === "fill") {
      setSyllabusState({
        ...syllabusState,
        color: value,
      });
    }

    if (object.isEditing) {
      /// quando a mudança ocorre somente no texto selecionado
      let style = {};

      style[prop] = value;

      if (prop === "fontWeight") {
        const propValue = object.getSelectionStyles()[0][prop] === "bold" ? "" : "bold";
        style[prop] = propValue;
        setPropStyle({ ...propStyle, [prop]: propValue });
      } else if (prop === "fontStyle") {
        const propValue = object.getSelectionStyles()[0][prop] === "italic" ? "" : "italic";
        style[prop] = propValue;
        setPropStyle({ ...propStyle, [prop]: propValue });
      } else {
        setPropStyle({ ...propStyle, [prop]: value });
      }

      object.setSelectionStyles(style);
      object.setCoords();
      object.set("active", false);
      canvas.renderAll();
    } else {
      /// quando a mudança ocorre na seleçao geral
      if (prop === "fontWeight") {
        const propValue = object.fontWeight === "bold" ? "" : "bold";
        setPropStyle({ ...propStyle, [prop]: propValue });
        object.set(prop, propValue);
      } else if (prop === "fontStyle") {
        const propValue = object.fontStyle === "italic" ? "" : "italic";
        setPropStyle({ ...propStyle, [prop]: propValue });
        object.set(prop, propValue);
      } else {
        setPropStyle({ ...propStyle, [prop]: value });
        object.set(prop, value);
      }
      canvas.renderAll();
    }

    save();
  };

  const deleteActiveObject = () => {
    canvas.getActiveObjects().forEach((object) => {
      if (!object.isEditing) {
        canvas.remove(object);
      }
    });
    save();
  };

  const addText = () => {
    const myText = new fabric.Textbox("Digite seu texto", {
      fontFamily: propStyle.fontFamily,
      fill: propStyle.fill,
      fontSize: propStyle.fontSize,
      padding: 5,
      textAlign: propStyle.textAlign,
      left: 60,
      top: 70,
      width: 1000,
      lockRotation: true,
      lockScalingY: true,
    });

    canvas.add(myText);
    save();
  };

  return {
    addText,
    changeProp,
    replay,
    createImg,
    undo,
    redo,
  };
};
