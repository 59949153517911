import React from "react";
import { ToastContainer as ToastContainerDefault, toast } from "react-toastify";
import './style.scss';

const Toast = (
  text="As configurações foram salvas",
  type="success",
  params={}
) => {
  toast[type](text,{className: "toast-terms-notification", autoClose: 3000, ...params});
}

const ToastContainer = () => {
  return <div className="container-toast-terms-notification"><ToastContainerDefault /></div>
}

export {Toast, ToastContainer};
