import React, { memo } from "react";
import { Tooltip } from "@material-ui/core";
import IcoHistoryVersion from "assets/images/history-version.svg";

const HistoryClickRow = memo(({ data, redirectHistoryTerm }) => (
    <Tooltip title="Visualizar versões" placement="top">
      <span
        className="btn btn-sm float-right icon"
        onClick={() => redirectHistoryTerm(data.idTermOfResponsibility)}
      >
        <img src={IcoHistoryVersion} alt="icon-version" />
      </span>
    </Tooltip>
));

export default HistoryClickRow;
