import React from "react";
import './styles.scss'
import ModalDialog from "../ModalDialog";
const ModalInactive = ({
  showModal,
  closeModal,
  handleClickConfirmation
}) => {
  return (
    <ModalDialog
    showModal={showModal}
    setShowModal={closeModal}
    type={'danger'}
    buttons={[
      {
        className: "button-modal-darger",
        label: "Inativar termo",
        onClick: handleClickConfirmation,
      },
    ]}
  >
    <div className="flex-body-modal flex-body-modal-inactive">
      <div className="title-modal"> Tem certeza que deseja INATIVAR o termo de responsabilidade? </div>
      <div className="title-subtitle">
        Ao clicar em “INATIVAR”, esse termo deixará de ser exibido para todos os usuários.
      </div>
    </div>
  </ModalDialog>
  )
}

export default ModalInactive
