import React, { useContext, useEffect, useRef, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import SaveIcon from "@material-ui/icons/Save";
import CancelButton from "components/CancelButton";
import CmsContentLabel from "components/CmsContentLabel";
import ContentImageUpload from "components/ContentImageUpload";
import "jodit";
import moment from "moment";
import { DatePickerInput } from "rc-datepicker";
import { Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import _ from "underscore";
import Tags from "../../components/Tags";
import { AuthContext } from "../../context/AuthContext";
import { getTags, updateContent, uploadScorm } from "../../services/NexiDigitalAPI";
import AccessTable from "../AccessTable";
import EditableTable from "../EditableTable";

const tableSettings = [
  {
    header: "Titúlo",
    field: "title",
    align: "center",
    editable: true,
    maxLength: 150,
  },
  {
    header: "Rótulo",
    field: "label",
    align: "center",
    editable: true,
    maxLength: 45,
    defaultValue: "Lição",
  },
  {
    header: "Carga horária em minutos",
    field: "workload",
    align: "center",
    editable: true,
    placeholder: "0",
    type: "number",
  },
  {
    header: "Nota de corte",
    field: "masteryScore",
    align: "center",
    editable: false,
  },
];
const Scorm = ({
  data,
  contentTags,
  permissionApproveContent,
  isNode,
  returnNodeData,
  history,
  closeModal,
}) => {
  const { loginData } = useContext(AuthContext);

  let tagsDeConteudo = [];
  for (let i = 0; i < contentTags.length; i++) {
    tagsDeConteudo.push({ id: i, name: contentTags[i].tag });
  }

  const [active, setActive] = useState(data.active || 0);
  const [autor, setAutor] = useState(data.author || "");
  const [autorEmail, setAutorEmail] = useState(data.authorEmail || "");
  const [description, setDescription] = useState(data.description || "");
  const [endDate, setEndDate] = useState(data.endDate === null ? null : new Date(data.endDate));

  const [initDate, setInitDate] = useState(
    data.initDate === null ? new Date() : new Date(data.initDate),
  );
  const [titulo, setTitulo] = useState(data.text || "");
  const [temEndDate, setTemEndDate] = useState(endDate ? true : false);
  const [tags, setTags] = useState(tagsDeConteudo);
  const [label, setLabel] = useState(data.label || "Conteúdo");
  const [lista, setLista] = useState([]);
  const [loadingUpdateContent, setLoadingUpdateContent] = useState(false);
  const [scormData, setScormData] = useState(
    data.scorm
      ? {
          result: {
            ...data.scorm,
            filename: data.scorm.scormPkgName,
            items: data.scorm.scoItens,
          },
        }
      : {},
  );
  const [loadingScorm, setLoadingScorm] = useState(false);
  const [scormInput, setScormInput] = useState(data.scorm ? data.scorm.scormPkgName : "");

  const backConfirmation = data.backConfirmation || "";
  const backgroundColor = data.backgroundColor || "";
  const content = data.content || "";
  const contentLocation = data.contentLocation || "";
  const idContentGroup = data.idContentGroup;
  const idContentItem = data.idContentItem;
  const idContentType = data.idContentType;
  const idSection = data.idSection;
  const imageStyle = data.imageStyle || "";
  const logoutConfirmation = data.logoutConfirmation;
  const referenceLink = data.referenceLink || "";
  const showBottomMenu = data.showBottomMenu;
  const showTopBar = data.showTopBar;

  // Imagem
  const contentImageUploadRef = useRef(null);

  useEffect(() => {
    getTags()
      .then((res) => {
        const tagSearch = res.data.tagsearch;
        const tagsSorted = _.sortBy(tagSearch, "tag");
        let nova = [];

        for (let i = 0; i < tagsSorted.length; i++) {
          nova.push({ id: tagsSorted[i].idTag, name: tagsSorted[i].tag });
        }

        setLista(nova);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const sendData = async (event, newImageLocation) => {
    if (event) {
      event.preventDefault();
    }

    const publishDate = moment().format("YYYY-MM-DD hh:mm:ss.SSS");
    const userId = loginData.userid;
    const tagList = _.map(tags, function (t) {
      return t.name;
    }).join(";");

    //ADICIONANDO REGRA PARA QUANDO O USUÁRIO CLICAR PARA SALVAR NO SALVAR DO FORMULÁRIO.
    setLoadingUpdateContent(true);
    const uploadImageLocation = await contentImageUploadRef.current.uploadImagem();

    if (uploadImageLocation != null) {
      newImageLocation = uploadImageLocation;
    }

    let obj = {
      idContentItem: idContentItem,
      idContentGroup: idContentGroup,
      image: newImageLocation,
      text: titulo,
      description: description,
      contentLocation: contentLocation,
      publishDate: publishDate,
      initDate: moment(initDate).format("YYYY-MM-DD hh:mm:ss.SSS"),
      endDate: endDate === null ? null : moment(endDate).format("YYYY-MM-DD hh:mm:ss.SSS"),
      content: content,
      idUserPublisher: parseInt(userId, 10),
      author: autor,
      authorEmail: autorEmail,
      active: parseInt(active),
      idContentType: idContentType,
      tags: tagList,
      backConfirmation: backConfirmation === 1 ? 1 : 0,
      backgroundColor: backgroundColor,
      idSection: idSection,
      imageStyle: imageStyle,
      logoutConfirmation: logoutConfirmation,
      referenceLink: referenceLink,
      showBottomMenu: showBottomMenu,
      showTopBar: showTopBar,
      label: label,
      scorm: {
        scormPkgName: scormData.result?.filename,
        title: titulo,
        scoItens: scormData.result?.items,
        processScorm: scormData.processScorm,
      },
    };

    updateContent(obj)
      .then((res) => {
        if (res.data.success) {
          if (isNode && returnNodeData) {
            returnNodeData({ ...obj, idContentItem: data.idContentItem });
          } else {
            history.goBack();
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoadingUpdateContent(false);
      });
  };

  const usaDataFim = (op) => {
    setTemEndDate(op);

    if (op === false) {
      setEndDate(null);
    }
  };

  const handleUploadScorm = async (input) => {
    if (input.files[0]) {
      setScormInput(input.files[0].name);
      setLoadingScorm(true);
      const formData = new FormData();
      formData.append("fileData", input.files[0]);
      formData.append("fileName", input.files[0]?.name);

      if (data?.scorm?.idRemoteScorm) {
        formData.append("scormPkgName", data.scorm.scormPkgName);
      }

      try {
        const response = await uploadScorm(formData);
        setScormData({ ...response.data, processScorm: true });
        setScormInput(response.data.result.filename);
      } catch (error) {
        console.log(error);
        setScormData({
          error: true,
        });
      } finally {
        setLoadingScorm(false);
      }
    }
  };

  const scormAlert = () => {
    const alertTypes = {
      empty: {
        color: "#495057",
        message: "Nenhum arquivo selecionado.",
      },
      uploading: {
        color: "#495057",
        message: "Carregando arquivo. Isso pode levar alguns minutos...",
      },
      processing: {
        color: "#495057",
        message: "Processando arquivo. Isso pode levar alguns minutos...",
      },
      pendingSave: {
        color: "#4BB543",
        message: "Arquivo carregado e pronto para ser salvo.",
      },
      alreadySaved: {
        color: "#4BB543",
        message:
          "Antes de subir o novo pacote SCORM, certifique-se de que a quantidade de lições (SCOs) e seus identificadores são os mesmos do pacote original.",
      },
      error: {
        color: "#FF9494",
        message: "Ocorreu um erro inesperado ao processar arquivo.",
      },
    };

    if (!scormInput) return alertTypes.empty;
    if (loadingScorm) return alertTypes.uploading;
    if (scormData.error || scormData.result?.status === "ERROR") return alertTypes.error;
    if (scormData.result?.status === "PENDING") return alertTypes.processing;
    if (scormData.result?.status === "SUCCESS") return alertTypes.alreadySaved;
    return alertTypes.pendingSave;
  };

  return (
    <div>
      {!isNode && (
        <div className="header-title-wrapper">
          <div className="header-title">
            <button
              className="btnGoBack"
              onClick={() => {
                history.goBack();
              }}
            >
              <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
            </button>
            Página de conteúdo
          </div>
        </div>
      )}
      <div className="post-form">
        <form onSubmit={sendData}>
          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">Título</div>
              <div className="card-body template-card-body">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Título
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="titulo"
                    aria-describedby="titulo"
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                  />
                </div>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Descrição
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    aria-describedby="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">Imagem</div>
              <ContentImageUpload
                reference={contentImageUploadRef}
                data={data}
                sendData={sendData}
                defaultPrefixName={"content"}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">Pacote SCORM 1.2</div>
              <div className="card-body template-card-body">
                <div className="row">
                  <div className="input-group col-md-8">
                    <input
                      type="file"
                      className="form-control-file"
                      id="scorm-file"
                      accept=".zip,.rar,.7zip"
                      disabled={loadingScorm || scormData.result?.status === "PENDING"}
                      style={{ display: "none" }}
                      onChange={(e) => handleUploadScorm(e.target)}
                    />
                    <label
                      htmlFor="scorm-file"
                      className={`btn btn-primary ${
                        (loadingScorm || scormData.result?.status === "PENDING") && "disabled"
                      }`}
                    >
                      {loadingScorm ? (
                        <>
                          Carregando arquivo
                          <CircularProgress
                            size={18}
                            style={{
                              margin: "0px 0px -3px 10px",
                              color: "white",
                            }}
                          />
                        </>
                      ) : (
                        "Escolher arquivo"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="scorm-file-name"
                      aria-describedby="scorm-file-name"
                      value={scormInput}
                      style={{ marginLeft: "-3px", zIndex: 1 }}
                      disabled
                    />
                  </div>
                  <strong
                    style={{
                      marginLeft: 15,
                      width: "100%",
                      color: scormAlert().color,
                    }}
                  >
                    {scormAlert().message}
                  </strong>
                </div>
                {scormData.result && (
                  <EditableTable
                    autoIncrement
                    autoFocus
                    loading={loadingScorm}
                    handleChange={(items) =>
                      setScormData((prevData) => ({
                        ...prevData,
                        result: {
                          ...prevData.result,
                          items,
                        },
                      }))
                    }
                    tableData={scormData.result?.items || []}
                    tableSettings={tableSettings}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">Tags</div>
              <div className="card-body template-card-body">
                <div className="row">
                  <div className="col-md-12">
                    <Tags
                      handleNewTags={(newTags) => setTags(newTags)}
                      lista={lista}
                      contentTags={contentTags}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CmsContentLabel label={label} setLabel={setLabel} />

          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">Autor</div>
              <div className="card-body template-card-body">
                <div className="form-row">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="autor">
                        Nome
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={autor}
                      onChange={(e) => setAutor(e.target.value)}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="autor">
                        E-mail
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      value={autorEmail}
                      onChange={(e) => setAutorEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="card overflow-visible">
              <div className="card-header template-card-header">Publicação</div>
              <div className="card-body template-card-body">
                <div className="form-row">
                  {permissionApproveContent && (
                    <div className="col-md-3">
                      <Form.Group className="access-table-select">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          value={active}
                          onChange={(e) => setActive(e.target.value)}
                        >
                          <option value={0}>Avaliação pendente</option>
                          <option value={1}>Aprovado</option>
                          <option value={2}>Cancelado</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                  )}
                  <div className="col-md-3">
                    <Form.Group className="access-table-select">
                      <Form.Label>Data de publicação</Form.Label>
                      <DatePickerInput
                        name="initDate"
                        locale="pt-br"
                        onChange={(date) => setInitDate(date)}
                        value={initDate}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <div className="access-table-select form-group">
                      <label className="form-label" htmlFor="tira-do-ar" style={{ margin: "-3 0" }}>
                        Agendar término publicação?
                      </label>
                      <br />
                      <Switch
                        className="form-group"
                        id="tira-do-ar"
                        color="primary"
                        checked={temEndDate}
                        onChange={(e) => usaDataFim(e.target.checked)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </div>
                  {temEndDate ? (
                    <div className="col-md-3">
                      <Form.Group className="access-table-select">
                        <Form.Label>Data de retirada do conteúdo</Form.Label>
                        <DatePickerInput
                          name="endDate"
                          placeholdertext="Sem prazo"
                          locale="pt-br"
                          onChange={(date) => setEndDate(date)}
                          value={endDate}
                        />
                      </Form.Group>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header template-card-header">Permissões de acesso ao conteúdo</div>
            <div className="card-body template-card-body">
              <AccessTable id={data.idContentItem} />
            </div>
          </div>
          <br />
          <div className="row pr-3" style={{ padding: 5, justifyContent: "flex-end" }}>
            <CancelButton idContentItem={idContentItem} isNode={isNode} closeModal={closeModal} />
            <button type="submit" disabled={loadingScorm} className="btn btn-primary">
              <SaveIcon style={{ height: 18 }} /> Salvar{" "}
              {(loadingUpdateContent || loadingScorm) && (
                <CircularProgress
                  size={18}
                  style={{ margin: "0px 0px -3px 10px", color: "white" }}
                />
              )}
            </button>
          </div>
        </form>
        <br />
      </div>
    </div>
  );
};

export default withRouter(Scorm);
