import "./styles.scss";

import React from "react";
import parse from "html-react-parser";

import { Button, Modal } from "react-bootstrap";

import { GoAlert } from "react-icons/go";

const ModalDialog = ({
  show,
  setShow,
  title = "",
  text = "",
  variantIco = "",
  btnCall,
  btnDenyCall = null,
  className= '',
}) => {
  function handleDeny() {
    setShow(false);

    if (btnDenyCall) {
      btnDenyCall();
    }
  }

  return (
    <Modal
      show={show}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      className={`ModalDilogComponent ${className ? className : ""}`}
      centered
      size="xl"
      onHide={() => {}}
    >
      <div className="modal-support-blur modal-support-blur-warning" style={{ borderRadius: 5 }} />
      <div className="modal-principal-warning">
        <div className="modal-body-warning">
          <div className="form-group form-group-block-02">
            <div className="card">
              <div className="card-body template-card-body">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="containInfo">
                      <div className={`blockAlert ${variantIco}`}>
                        <GoAlert />
                      </div>
                      <div className="containText">
                        {title && <h3>{parse(title)}</h3>}
                        <h4>{parse(text)}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="containerBtn">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 baseBtns">
                {btnCall.alertMode !== true && (
                  <>
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        handleDeny();
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant={btnCall.variantBtn}
                      className={btnCall.className}
                      onClick={() => {
                        btnCall.callEvent();
                        setShow(false);
                      }}
                    >
                      {btnCall.text}
                    </Button>
                  </>
                )}

                {btnCall.alertMode === true && (
                  <>
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        handleDeny();
                      }}
                    >
                      OK
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDialog;
