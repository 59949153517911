import "./style.scss";
import NodeParameters from "components/PeopleCraft/NodeParamenters";
import React, { useContext, useEffect, useRef, useState } from "react";
import { LMCreateContext } from "../../Contexts/LMContext";
import { BlockList } from "components/PeopleCraft/BlockList";
import { IoReturnDownBackSharp } from "react-icons/io5";
import PlaceholderParameters from "./components/PlaceholderParameters";
import { getMenuRoutes } from "utils/Utils";
import InteractionDrawer from "components/InteractionDrawer";

type Props = {
  context?: string;
};

enum StatusDynamic {
  EDIT_BLOCK_PARAMETERS = "EDIT_BLOCK_PARAMETERS",
  EDIT_NODE = "EDIT_NODE",
  CREATE_NODE = "CREATE_NODE",
}

const DynamicParameters = ({ context }: Props) => {
  const {
    isSidebarOpen,
    sidebarChatInteration,
    nodeCurrent,
    editPropsNodes,
    statusDynamic,
    setStatusDynamic,
    setNodeData,
    setLoadingNodeList,
    getInfoChatInteration,
  } = useContext(LMCreateContext);
  const [permissionLeraningPage ,setPermissionLearningPage] = useState(false)
  const dinamicParametersComp = useRef(null);

    /* [Permission Module] - Menu Options */
    const getMenuOption = () => {
      const menuOptionList = getMenuRoutes();
      const objCurrentMenu = menuOptionList.filter((menu) => menu.text === "Gestão de Aprendizagem");

      if (Boolean(objCurrentMenu.length)) {
        const resources = objCurrentMenu.map((menu) => menu.resource);

        getPermissionsPage(resources);
      }
    };

    /* [Permission Module] - Permission attrs in page by login data information */
    const getPermissionsPage = (resources) => {
      try {
        if (
          Boolean(
            resources[0].filter((menu) => menu.name === "Gerenciar páginas de aprendizagem").length,
          )
        )
          setPermissionLearningPage(true);
      } catch (error) {
        console.log("Error setting permission: ", error);
      }
    };

    useEffect(() => {
      getMenuOption();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  useEffect(() => {
    const currentDinamicParametersComp: any = dinamicParametersComp.current;

    if (currentDinamicParametersComp) {
      const panelElement = currentDinamicParametersComp.parentElement;
      panelElement.expand();
    }
  }, [statusDynamic, nodeCurrent, editPropsNodes]);

  if ((!nodeCurrent && !nodeCurrent?.nodeCode) || (!nodeCurrent && !nodeCurrent?.originalNodeCode))
    return <PlaceholderParameters number={10} />;

  return (
    <div ref={dinamicParametersComp} className="dinamicParameters">
      {/* <div className="dinamicParametersHeader"></div> */}

      {statusDynamic === StatusDynamic.EDIT_BLOCK_PARAMETERS ? (
        <>
          <button
            className="returnClassProperties"
            onClick={() => {
              setStatusDynamic("EDIT_NODE");
            }}
          >
            {" "}
            <IoReturnDownBackSharp />
            Propriedades da página.
          </button>

          <BlockList></BlockList>
        </>
      ) : (
        <NodeParameters
          nodeCode={nodeCurrent.nodeCode ? nodeCurrent.nodeCode : nodeCurrent.originalNodeCode}
          editPropsNodes={editPropsNodes}
          editNodeCode={StatusDynamic.EDIT_NODE === statusDynamic}
          setNodeData={setNodeData}
          setLoadingNodeList={setLoadingNodeList}
          permissionLeraningPage={permissionLeraningPage}
        />
      )}
      <InteractionDrawer
        informationNode={getInfoChatInteration()}
        isOpen={isSidebarOpen}
        toggleSidebar={() => sidebarChatInteration(false)}
        editPropsNodes={editPropsNodes}
      />
    </div>
  );
};

export default DynamicParameters;
