import React, { useState, useContext } from "react";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import BreadCrumb from "../BreadCrumb/BreadCrumb";
import "./index.css";
import BlockOptions from "./BlockOptions";
import { BlockList } from "./BlockList";
import {
  createLearningBlock,
  editBlockElementOrder,
  editLearningBlockOrder,
  getLearningNode,
  moveBlockElementOrder,
  removeLearningBlock,
  updateLearningBlock,
} from "../../../services/NexiDigitalAPI";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import Loading from "../../../components/Loading";
import { getMenuRoutes, transformText } from "../../../utils/Utils";
import NodeManagerProvider, { NodeManagerContext } from "./NodeManagerProvider";

const NodeManagerV2 = () => {
  const [errorNode, setErrorNode] = useState(false);
  const [loadingNodeList, setLoadingNodeList] = useState(true);
  const [loadingAddBlock, setLoadingAddBlock] = useState(false);
  const [loadingRemoveBlock, setLoadingRemoveBlock] = useState(false);
  const [loadingMoveBlock, setLoadingMoveBlock] = useState(false);
  const [loadingUpdateBlock, setLoadingUpdateBlock] = useState(false);

  const { nodeData, setNodeData } = useContext(NodeManagerContext);

  const history = useHistory();

  const { nodeCode } = useParams();

  const MOCK_BREADCRUMB_DATA = [
    {
      title: "Informações básicas",
    },
    {
      title: "Gerenciar conteúdo",
    },
  ];
  const _getLearningNode = () => {
    setErrorNode(false);
    setLoadingNodeList(true);
    getLearningNode(nodeCode)
      .then((res) => {
        const nodeDataResult = res.data.result;
        setNodeData(nodeDataResult);
      })
      .catch((err) => {
        console.log("Error getting learning node", err.request);
        setErrorNode(true);
      })
      .finally(() => {
        setLoadingNodeList(false);
      });
  };

  const handleAddBlock = (addNodeData) => {
    setLoadingAddBlock(true);
    const blockInsertData = {
      node: {
        id: nodeData.idNode,
      },
      blockType: addNodeData.blockType,
    };
    createLearningBlock(blockInsertData)
      .then((res) => {
        const idBlock = res.data.result;
        const newBlock = {
          blockType: addNodeData.blockType,
          idBlock,
          idNode: nodeData.idNode,
        };
        let newNodeData = { ...nodeData };
        if (newNodeData.blocks === undefined) {
          newNodeData.blocks = [];
        }
        newNodeData.blocks.push(newBlock);
        setNodeData(newNodeData);
      })
      .catch((err) => {
        console.log("error inserting block", err);
      })
      .finally(() => {
        setLoadingAddBlock(false);
      });
  };

  const handleRemoveBlock = (idBlock) => {
    setLoadingRemoveBlock(idBlock);
    removeLearningBlock(idBlock)
      .then(() => {
        const filteredNodeList = nodeData.blocks.filter((obj) => obj.idBlock !== idBlock);

        let newNodeData = { ...nodeData };
        newNodeData.blocks = filteredNodeList;
        setNodeData(newNodeData);
      })
      .catch((err) => {
        console.log("error deleting block", err);
      })
      .finally(() => {
        setLoadingRemoveBlock(false);
      });
  };

  const handleChangeOrder = (orderData) => {
    setLoadingMoveBlock(true);
    const { idBlock, orderNum } = orderData;
    moveObjectPosition(idBlock, orderNum - 1);

    editLearningBlockOrder(idBlock, orderNum)
      .then(() => {
        console.log("Success moving block");
      })
      .catch((err) => {
        console.log("error moving block", err);
      })
      .finally(() => {
        setLoadingMoveBlock(false);
      });
  };

  const handleElementChangeOrder = (orderData) => {
    const { idBlockElement, orderNum } = orderData;
    moveObjectPosition(idBlockElement, orderNum - 1);
    editBlockElementOrder(idBlockElement, orderNum)
      .then(() => {
        console.log("Success moving element");
      })
      .catch((err) => {
        console.log("error moving element", err);
      });
  };

  const handleElementMove = (moveData) => {
    moveBlockElementOrder(moveData)
      .then(() => {
        console.log("Success moving element trhough block");
      })
      .catch((err) => {
        console.log("Error moving element trhough block", err.request);
      });
  };

  const handleUpdateBlock = (node, event, field) => {
    const value = event.target?.value;
    const newBlockList = nodeData.blocks.map((obj) => {
      if (obj.idBlock === node.idBlock) {
        return { ...obj, [field]: value };
      }
      return obj;
    });
    setNodeData((prevNodeData) => ({
      ...prevNodeData,
      blocks: newBlockList,
    }));
  };

  const handleSaveUpdateBlock = (node, event, field) => {
    setLoadingUpdateBlock(true);
    const value = event.target?.value;
    const updateData = {
      id: node.idBlock,
      node: {
        id: nodeData.idNode,
      },
      blockType: node.blockType,
      [field]: value,
    };
    updateLearningBlock(node.idBlock, updateData)
      .then(() => {
        console.log("Success updating block");
      })
      .catch((err) => {
        console.log("error deleting block", err);
      })
      .finally(() => {
        setLoadingUpdateBlock(false);
      });
  };

  const moveObjectPosition = (idBlock, destinationIndex) => {
    // let newNodeList = [...nodeList]
    let newNodeList = [...nodeData.blocks];
    const objectToMove = newNodeList.find((obj) => obj.idBlock === idBlock);
    const index = newNodeList.indexOf(objectToMove);

    if (index > -1) {
      newNodeList.splice(index, 1);
      newNodeList.splice(destinationIndex, 0, objectToMove);
    }
    let newNodeData = { ...nodeData };
    newNodeData.blocks = newNodeList;
    setNodeData(newNodeData);
    // setNodeList(newNodeList)
  };

  const handleConcluir = () => {
    const menuOptionList = getMenuRoutes();
    const learningManagementMenu = menuOptionList.find((item) => item.idSectionType === 62);

    if (learningManagementMenu?.sectionTo) {
      const learningManagementPath = `/${transformText(learningManagementMenu?.text)}/${
        learningManagementMenu?.sectionTo
      }`;
      history.push(learningManagementPath);
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    _getLearningNode();
  }, []);

  return (
    <div>
      <div className="header-title-wrapper">
        <div className="header-title">
          <button
            className="btnGoBack"
            onClick={() => {
              history.goBack();
            }}
          >
            <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
          </button>
          {nodeData?.nodeLevelName || "Página"}
        </div>
      </div>

      <BreadCrumb index={1} paddingHorizontal="10%" data={MOCK_BREADCRUMB_DATA} />
      <div className={`node-manager-wrapper ${nodeData?.status === "ARCHIVED" ? "disabled" : ""}`}>
        <BlockOptions
          newBlock={handleAddBlock}
          loadingAddBlock={loadingAddBlock || loadingNodeList}
        />
        <div className="node-manager-nodes-container">
          {loadingNodeList ? (
            <Loading>Carregando blocos...</Loading>
          ) : errorNode ? (
            <div className="node-manager-error">Erro ao carregar nós</div>
          ) : (
            <>
              <div className="node-manager-title-container">
                <span style={{ fontWeight: "bold" }}>Página</span>
                <span>-</span>
                <span>{nodeData.title}</span>
                <div className="node-manager-type-text-container">
                  <span>{nodeData?.nodeLevelName}</span>
                </div>
                {(loadingUpdateBlock || loadingMoveBlock) && (
                  <div style={{ marginLeft: 10, fontSize: 14, display: "flex", color: "gray" }}>
                    <CircularProgress size={18} style={{ marginRight: 5 }} />
                    <span>Salvando...</span>
                  </div>
                )}
              </div>
              <BlockList
                blockData={nodeData.blocks}
                baseNodeLevel={nodeData.nodeLevel}
                removeBlock={handleRemoveBlock}
                updateBlock={handleUpdateBlock}
                saveBlock={handleSaveUpdateBlock}
                onChangeOrder={handleChangeOrder}
                onElementChangeOrder={handleElementChangeOrder}
                onElementMove={handleElementMove}
                loadingRemoveBlock={loadingRemoveBlock}
                loadingAddBlock={loadingAddBlock}
                loadingMoveBlock={loadingMoveBlock}
              />
              <div className="node-manager-add-more-container">
                <AddCircleOutlineIcon style={{ fontSize: 30, color: "#eea125", marginBottom: 5 }} />
                Clique no elemento ao lado para adiciona-lo aqui
              </div>
            </>
          )}
        </div>
      </div>
      {!loadingNodeList && (
        <div
          className="row pr-3"
          style={{ padding: 5, justifyContent: "flex-end", maxWidth: 1160 }}
        >
          <button
            type="button"
            onClick={handleConcluir}
            className="btn content-page-button ml-3"
            style={{ backgroundColor: "#feac0e" }}
          >
            Concluir
          </button>
        </div>
      )}
    </div>
  );
};
const ComponentWrapper = () => {
  return (
    <NodeManagerProvider>
      <NodeManagerV2 />
    </NodeManagerProvider>
  );
};

export default ComponentWrapper;
