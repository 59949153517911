import CircularProgress from "@material-ui/core/CircularProgress";
import EditableTable from "containers/EditableTable";
import React, { useState } from "react";
import { uploadScorm } from "../../services/NexiDigitalAPI";

const tableSettings = [
  {
    header: "Titúlo",
    field: "title",
    align: "center",
    editable: true,
    maxLength: 150,
  },
  {
    header: "Rótulo",
    field: "label",
    align: "center",
    editable: true,
    maxLength: 45,
    defaultValue: "Lição",
  },
  {
    header: "Carga horária em minutos",
    field: "workload",
    align: "center",
    editable: true,
    placeholder: "0",
    type: "number",
  },
  {
    header: "Nota de corte",
    field: "masteryScore",
    align: "center",
    editable: false,
  },
];

const CmsScorm = ({ data, scormData, setScormData }) => {
  const [scormInput, setScormInput] = useState(data.scorm ? data.scorm.scormPkgName : "");
  const [loadingScorm, setLoadingScorm] = useState(scormData.result?.status === "PENDING");

  const scormAlert = () => {
    const alertTypes = {
      empty: {
        color: "#495057",
        message: "Nenhum arquivo selecionado.",
      },
      uploading: {
        color: "#495057",
        message: "Carregando arquivo. Isso pode levar alguns minutos...",
      },
      processing: {
        color: "#495057",
        message: "Processando arquivo. Isso pode levar alguns minutos...",
      },
      pendingSave: {
        color: "#4BB543",
        message: "Arquivo carregado e pronto para ser salvo.",
      },
      alreadySaved: {
        color: "#4BB543",
        message:
          "Antes de subir o novo pacote SCORM, certifique-se de que a quantidade de lições (SCOs) e seus identificadores são os mesmos do pacote original.",
      },
      error: {
        color: "#FF9494",
        message: "Ocorreu um erro inesperado ao processar arquivo.",
      },
    };

    if (!scormInput) return alertTypes.empty;
    if (loadingScorm) return alertTypes.uploading;
    if (scormData.error || scormData.result?.status === "ERROR") return alertTypes.error;
    if (scormData.result?.status === "PENDING") return alertTypes.processing;
    if (scormData.result?.status === "SUCCESS") return alertTypes.alreadySaved;
    return alertTypes.pendingSave;
  };

  const handleUploadScorm = async (input) => {
    if (input.files[0]) {
      setScormInput(input.files[0].name);
      setLoadingScorm(true);
      const formData = new FormData();
      formData.append("fileData", input.files[0]);
      formData.append("fileName", input.files[0]?.name);

      if (data?.scorm?.idRemoteScorm) {
        formData.append("scormPkgName", data.scorm.scormPkgName);
      }

      try {
        const response = await uploadScorm(formData);
        setScormData({ ...response.data, processScorm: true });
        setScormInput(response.data.result.filename);
      } catch (error) {
        console.log(error);
        setScormData({
          error: true,
        });
      } finally {
        setLoadingScorm(false);
      }
    }
  };

  return (
    <div className="form-group">
      <div className="card">
        <div className="card-header template-card-header">Pacote SCORM 1.2</div>
        <div className="card-body template-card-body">
          <div className="row">
            <div className="input-group col-md-8">
              <input
                type="file"
                className="form-control-file"
                id="scorm-file"
                accept=".zip,.rar,.7zip"
                disabled={loadingScorm || scormData.result?.status === "PENDING"}
                style={{ display: "none" }}
                onChange={(e) => handleUploadScorm(e.target)}
              />
              <label
                htmlFor="scorm-file"
                className={`btn btn-primary ${
                  (loadingScorm || scormData.result?.status === "PENDING") && "disabled"
                }`}
              >
                {loadingScorm ? (
                  <>
                    Carregando arquivo
                    <CircularProgress
                      size={18}
                      style={{
                        margin: "0px 0px -3px 10px",
                        color: "white",
                      }}
                    />
                  </>
                ) : (
                  "Escolher arquivo"
                )}
              </label>
              <input
                type="text"
                className="form-control"
                id="scorm-file-name"
                aria-describedby="scorm-file-name"
                value={scormInput}
                style={{ marginLeft: "-3px", zIndex: 1 }}
                disabled
              />
            </div>
            <strong
              style={{
                marginLeft: 15,
                width: "100%",
                color: scormAlert().color,
              }}
            >
              {scormAlert().message}
            </strong>
          </div>
          {scormData.result && (
            <EditableTable
              autoIncrement
              autoFocus
              loading={loadingScorm}
              handleChange={(items) =>
                setScormData((prevData) => ({
                  ...prevData,
                  result: {
                    ...prevData.result,
                    items,
                  },
                }))
              }
              tableData={scormData.result?.items || []}
              tableSettings={tableSettings}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CmsScorm;
