import { useState } from "react";
import JoditEditorContent from "components/jodit";
import HelpIcon from "@material-ui/icons/Help";
import { CircularProgress, Tooltip, withStyles } from "@material-ui/core";
import { Form } from "react-bootstrap";
import IcoActive from "svg/Active";
import parse from 'html-react-parser';
import './styles.scss'
import CharacterCounterLimit from "./CharacterCounterLimit";

const ParamsJodit = () => {
  return {
    edit: {
      processPasteHTML: true,
      defaultActionOnPasteFromWord: "insert_clear_html",
      defaultActionOnPaste: 'insert_clear_html',
      askBeforePasteFromWord: false,
      beautifyHTML: true,
      pasteFromWord: {
        enable: true,
        convertUnitsToPixel: true,
        allowedStyleProps: [
          'background',
          'background-color',
          'border',
          'border-.*',
          'color',
          'font-family',
          'font-size',
          'font-style',
          'font-weight',
          'height',
          'line-height',
          'list-style-type',
          'margin',
          'margin-bottom',
          'margin-left',
          'margin-right',
          'margin-top',
          'padding',
          'text-align',
          'text-decoration',
          'text-indent',
          'vertical-align',
          'width'
        ]
      }
    }
  }
}
const FormTerms = (props) => {

  const [carregaImagem, setCarregaImagem] = useState(false);
  return <>

    <div className="col-12">
      <Form.Group className="access-table-select">
        <Form.Label><span style={{ color: "red" }}>*</span> Nome</Form.Label>
        <Form.Control
          as="input"
          type="text"
          maxLength={100}
          value={props.name}
          required={true}
          onChange={(e) => props.setName(e.target.value)}
        />
        <CharacterCounterLimit limit={100} value={props.name} color={"#C7C7C6"} showLimit={true} />
      </Form.Group>
    </div>




    <div className={"col-md-3 formTerms field-active " + (props.active == 1 ? "active" : "")}>
      <Form.Group className="access-table-select">
        <Form.Label>Status</Form.Label>
        {parse(`${IcoActive()}`)}
        <Form.Control
          as="select"
          value={props.active}
          onChange={(e) => props.setActive(Number(e.target.value))}
        >
          <option value={1}>Ativo</option>
          <option value={0}>Inativo</option>
        </Form.Control>
      </Form.Group>
    </div>

    <div className="col-12 mt-4">
      <JoditEditorContent
        content={props.content}
        loading={(e) => setCarregaImagem(e)}
        onBlur={(e) => {
          props.setContent(e);
        }}
        customJodit={ParamsJodit()}
        idContentItem={"10"}
      ></JoditEditorContent>
    </div>
  </>
}
export default FormTerms;
