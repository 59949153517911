import { MdRocketLaunch } from "react-icons/md";
import "./style.scss";
import React, { useEffect, useState } from "react";
import Button from "../Button";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalLoadingInfo from "../Modais/ModalLoadingInfo";
import { openModalUpgrade } from "utils/control-modal";

type Props = {
  text?: string;
  hide?: boolean;
  css?: object;
  className?: string;
  numberRequest: number;
  hideNotification: boolean;
  userIsAdmin: boolean;
  updateLicense?: any;
  hasRequestedUpgrade?: boolean;
  disableRuleIconModal?: boolean;
  callEvent?: React.MouseEventHandler<HTMLButtonElement> | null;
  isUpgradeButtonActive?: Boolean;
};

const UpgradeTrial = ({
  text = "Conheça a LMS/LXP by Afferolab",
  hide = false,
  numberRequest,
  hideNotification = false,
  userIsAdmin = false,
  hasRequestedUpgrade = false,
  disableRuleIconModal = false,
  updateLicense,
  isUpgradeButtonActive,
  callEvent,
  ...props
}: Props) => {
  const modalLoading = useModal("ModalLoadingInfo");
  NiceModal.register("ModalLoadingInfo", ModalLoadingInfo);
  const openModal = () => {
    openModalUpgrade({
      hasRequestedUpgrade,
      userIsAdmin,
      numberRequest,
   });
  };

  const handleClick = (e) => {
    openModal();
    if (callEvent) {
      callEvent(e);
    }
    if (!userIsAdmin) {
      requestUpgrade();
      return;
    }


    openModal();
  };

  const requestUpgrade = async () => {

  };

  // useEffect(() => {
  //   console.log("ddd");
  // }, []);

  return (
    <>
      {isUpgradeButtonActive && !hide && (
        <div className="upgradeTrial">
          <Button
             callEvent={(e) => handleClick(e)}
            className={`button07 buttonDefault  ${
              !disableRuleIconModal && "buttonIconText"
            }`}
            ico={MdRocketLaunch}
            text={"Conheça a LMS/LXP by Afferolab"
            }
          />
        </div>
      )}
    </>
  );
};

export default UpgradeTrial;
