// Basic
import React, { useState } from "react";

import "./styles.scss";
import Overview from "./Overview";
import LearningData from "./LearningData";
import StudentEnrollmentView from "./StudentEnrollmentView";
import Filtro from "./Filtro";
import { getMenuData } from "../../../services/NexiDigitalAPI";

export default function ReportsLearning() {
  const { idCompany } = getMenuData();
  const [idCompanyFilter, setIdCompanyFilter] = useState(idCompany || -1);

  return (
    <div className="reportsLearning">
      <div className="childRL">
        <Filtro actionFilter={setIdCompanyFilter} />
      </div>
      <div className="childRL overview">
        <Overview idCompanySelected={idCompanyFilter} />
      </div>
      <div className="childRL learningData">
        <LearningData idCompanySelected={idCompanyFilter} />
      </div>
      <div className="childRL studentEnrollmentView">
        <StudentEnrollmentView idCompanySelected={idCompanyFilter} />
      </div>
    </div>
  );
}
