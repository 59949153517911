import "./styles.scss";
import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
// import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
// import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import _ from "underscore";
import { Tooltip } from "@material-ui/core";
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";
import { Button, Modal, Form } from "react-bootstrap";
import { getMenuRoutes } from "../../../../../utils/Utils";
import { getUsersSecondary } from "../../../../../services/NexiDigitalAPISecondary";
import {
  updateGroup,
  getGroupUsers,
  linkUserToGroup,
  unlinkUserFromGroup,
  getUsers,
} from "../../../../../services/NexiDigitalAPI";
import DataTable from "react-data-table-component";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { BsFilter } from "react-icons/bs";

import Carousel from "react-bootstrap/Carousel";
import { useHistory } from "react-router-dom";
import { PACreateContext } from "../../Contexts/PAContext";
import ModalDialog from "containers/PeopleCraft/Modals/ModalDialog";

const GroupsManual = (props) => {
  //*
  const { groupId, groupTitle } = useParams();
  // const [searchString, setSearchString] = useState("");
  const inputSearch = useRef(null);
  const [type, setType] = useState("");

  //*

  const history = useHistory();
  const isMountedRef = useRef(null);
  const { setPreloadInit, openConfirmModal } = useContext(PACreateContext);
  const [lista, setLista] = useState([]);
  const [selectedIdUser, setSelectedIdUser] = useState([]);
  const [groupName, setGroupName] = useState(groupTitle);
  const groupNameInputRef = useRef(null);

  /*List Pagination */
  const [pageLimit, setPageLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  /* [Permission Module] - Permissions states */
  const [permissionAddUserIntoGroup, setPermissionAddUserIntoGroup] = useState(false);

  useEffect(() => {
    /* [Permission Module] - Function calls */
    getMenuOption();

    isMountedRef.current = true;
    getAvailableData(0, pageLimit);
    return () => (isMountedRef.current = false);
  }, []);

  /* [Permission Module] - Permission attrs in page by login data information */
  function getPermissionsPage(resources) {
    try {
      if (Boolean(resources[0].filter((menu) => menu.name === "Inserir usuário no grupo").length))
        setPermissionAddUserIntoGroup(true);
    } catch (error) {
      console.log("Falha ao buscar permissões de Grupos");
    }
  }

  /* [Permission Module] - Menu Options */
  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();

    const objCurrentMenu = menuOptionList.filter((menu) => menu.text === "Cadastro de Usuários");

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);

      getPermissionsPage(resources);
    }
  }

  /* Configurações da tabela */
  const paginationComponentOptions = {
    rowsPerPageText: "Conteúdos por página",
    rangeSeparatorText: "de",
  };

  const onChangePage = (page) => {
    const newOffset = (page - 1) * pageLimit;
    getAvailableData(newOffset);
  };

  const onChangeRowsPerPage = (limit, page) => {
    setPageLimit(limit);
    const newOffset = (page - 1) * limit;
    getAvailableData(newOffset, limit);
  };

  /* Recuperando os grupos disponíveis. */
  const getAvailableData = async (newOffset, limit) => {
    const haveNewOffset = newOffset || newOffset === 0;

    if (haveNewOffset) {
      setOffset(newOffset);
    }

    // TODO: Estes parâmetros devem ser passados para o enpoint para o funcionamento dos filtros.
    const usersParameters = {
      searchString: inputSearch.current.value,
      offset: haveNewOffset ? newOffset : offset,
      limit: limit ? limit : pageLimit,
      idGroups: groupId,
    };

    setPreloadInit(true);

    const availableData = await getUsersSecondary(usersParameters).catch((err) => {
      console.log("error", err);
    });

    setPreloadInit(false);

    if (availableData) {
      setLista(_.sortBy(availableData.data.userList, "name"));
      setTotalRecords(availableData.data.totalRecords);
    }
  };

  const columns = [
    {
      name: "",
      selector: "checkbox",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      width: "50px",
      cell: (user) => (
        <div className="ml-1">
          <Form.Check
            name="group1"
            type={"checkbox"}
            id={`inline-question`}
            // checked={selectedIdUser.indexOf(user.idUser) != -1}
            onChange={(e) => {
              // e.target.checked
              //   ? setSelectedIdUser(user.idUser)
              //   : setSelectedIdUser(null);

              let tempUsersArray = selectedIdUser;
              let userIndex = tempUsersArray.indexOf(user.idUser);

              if (userIndex == -1) {
                //ADICIONA AO ARRAY DE INSERÇÃO DOS USUÁRIOS
                tempUsersArray.push(user.idUser);
              } else {
                // REMOVE DO ARRAY DE INSERÇÃO DOS USUÁRIOS
                tempUsersArray.splice(userIndex, 1);
              }

              setSelectedIdUser(tempUsersArray);
            }}
          />
        </div>
      ),
    },
    {
      name: "Código",
      selector: "idUser",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (user) => (
        // <HighlightText value={removeHtmlTags(question.statement)} highlight={searchString} />
        <div>
          <p>{user.idUser}</p>
        </div>
      ),
    },
    {
      name: "Nome",
      sortable: true,
      selector: "name",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (user) => (
        // <HighlightText value={removeHtmlTags(question.statement)} highlight={searchString} />
        <div>
          <p>{user.name}</p>
        </div>
      ),
    },

    {
      name: "E-mail",
      sortable: true,
      selector: "email",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (user) => (
        // <HighlightText value={removeHtmlTags(question.statement)} highlight={searchString} />
        <div>
          <p>{user.email}</p>
        </div>
      ),
    },
  ];

  return (
    <div className="groupsInsertUsers">
      <div className="groupListHeader">
        <div className="filters">
          <div className="filtersOptions">
            <h6>Procurar usuário</h6>
            <div className="searchInfo">
              <AiOutlineSearch />
              <Form.Control
                // value={searchString}
                ref={inputSearch}
                onKeyDown={(input) => {
                  if (input.key === "Enter") {
                    getAvailableData(0, pageLimit);
                  }
                }}
                // onChange={(e) => setSearchString(e.target.value)}
                placeholder={`Pesquisar...`}
              />
              <button
                onClick={() => {
                  // setSearchString("");
                  inputSearch.current.value = "";
                  getAvailableData(0, pageLimit);
                }}
              >
                <AiOutlineClose />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="groupListTableContainer">
        <DataTable
          columns={columns}
          data={lista}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationTotalRows={totalRecords}
          paginationComponentOptions={paginationComponentOptions}
          paginationServer
          noHeader
          fixedHeader
          pagination
        />
      </div>

      <div className="insertFooter">
        {/*  {selectedIdUser.length > 0 && */}
        <button
          className="button-peop button-yellow"
          onClick={(e) => {
            setPreloadInit(true);

            const insertUsersParams = {
              idGroups: groupId,
              idUsers: selectedIdUser,
              inclusionType: "MANUAL",
            };

            linkUserToGroup(insertUsersParams).then(() => {
              // setLista([
              //   ...lista.filter((u) => u.idUser !== selectedIdUser),
              // ]);

              setSelectedIdUser([]);
              setPreloadInit(false);
              getAvailableData(0, pageLimit);
            });
          }}
        >
          Inserir usuário
        </button>
        {/* } */}
      </div>
    </div>
  );
};

export default withRouter(GroupsManual);
