import React, { useState } from "react";
import styles from "./styles.module.scss";
import GenericDashboardCard from "../GenericDashboardCard";
import { getReportSections } from "../../../../../services/NexiDigitalAPI";

const MoreAccessedUser = ({ filter }) => {
  const title = "Usuários com mais acessos";
  const link = "?tabs=usuario&rel=0";

  const [loading, setLoading] = useState(true);
  const [dados, setDados] = useState([]);
  const [max, setMax] = useState(0);

  const actionFilter = async (values) => {
    setLoading(true);

    const fixedValues = {
      dateStart: values.startDate,
      dateFinish: values.endDate,
      idContentItem: 0,
      idSection: 0,
      idGroup: 0,
    };
    if (filter.idGroupGlobal) {
      fixedValues.idGroup = parseInt(filter.idGroupGlobal);
    }
    const reportSections = await getReportSections(fixedValues);
    reportSections.sort(compare);
    const selectedData = reportSections.slice(0, 5);
    setDados(selectedData);
    setMax(Boolean(selectedData.length) ? selectedData[0].qtyVisits : 0);
    setLoading(false);
  };

  function compare(a, b) {
    if (a.qtyVisits > b.qtyVisits) {
      return -1;
    }
    if (a.qtyVisits < b.qtyVisits) {
      return 1;
    }
    return 0;
  }

  const colorArray = ["#642D8F", "#2D99FF", "#FF6C40", "#FFE700", "#2CD8C5"];

  const hasData = Boolean(dados.length);
  const noData = <div className={styles.nenhumDado}>Nenhum dado disponível</div>;

  const data = (
    <div>
      {!hasData
        ? noData
        : dados.map((item, index) => {
            const barSize = (item.qtyVisits / max) * 100;
            const barStyle = {
              width: `${barSize}%`,
              backgroundColor: `${colorArray[index]}`,
            };

            return (
              <div className={styles.dataRow} key={index}>
                <div className={styles.dataIdentity}>
                  <div className={styles.dataColunmInfo}>{item.name}</div>
                  <div className={styles.dataColunmInfo}>{item.qtyVisits}</div>
                </div>
                <div className={styles.dataEmail}>{item.email}</div>
                <div style={barStyle} className={styles.barClass}>
                  &nbsp;
                </div>
              </div>
            );
          })}
    </div>
  );

  return (
    <GenericDashboardCard
      title={title}
      data={data}
      actionFilter={actionFilter}
      link={link}
      loading={loading}
      filter={filter}
    />
  );
};

export default MoreAccessedUser;
