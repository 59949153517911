import React, { useState, useEffect } from "react";

import moment from "moment";
import "moment/locale/pt-br.js";

// Page UI and icons
import { Col, Form } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import FiltroWrapper from "../../../Components/FiltroWrapper";
import styles from "./styles.module.scss";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";

const Filtro = ({ actionFilter, loading }) => {
  const [values, setValues] = useState({
    startDate: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  });

  function handleDateStart(jsDate) {
    let date = moment(jsDate).format("YYYY-MM-DD");
    setValues({
      ...values,
      startDate: date,
    });
  }

  function handleDateFinish(jsDate) {
    let date = moment(jsDate).format("YYYY-MM-DD");
    setValues({
      ...values,
      endDate: date,
    });
  }

  function handleFilter() {
    actionFilter(values);
  }

  useEffect(() => {
    handleFilter();
  }, []);

  return (
    <FiltroWrapper>
      <Form style={{ marginTop: 30, marginBottom: 30 }}>
        <Form.Row className="align-items-end">
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="startDate">Data de Início</Form.Label>
            <DatePickerInput locale="pt-br" onChange={handleDateStart} value={values.startDate} />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="endDate">Data de Término</Form.Label>
            <DatePickerInput locale="pt-br" onChange={handleDateFinish} value={values.endDate} />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Group className="mb-0">
              <ButtonWithLoading
                onClickHandler={handleFilter}
                loading={loading}
                text={
                  <>
                    Aplicar filtro <FaFilter style={{ marginLeft: 5 }} />{" "}
                  </>
                }
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    </FiltroWrapper>
  );
};

export default Filtro;
