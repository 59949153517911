// TagsInput.tsx
import React, { useState, KeyboardEvent } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import './style.scss';

interface TagsInputProps {
  tags: string[],
  handleChange: (data: {[key: string]: string[]  }) => void,
  handleBlur: (data: {[key: string]:  string[] }) => void,
  disabled: boolean
}

const TagsCompoent: React.FC<TagsInputProps> = ({ disabled =false , tags, handleChange, handleBlur }) => {
  const [inputValue, setInputValue] = useState('');

  const isTagInArray = (tags: string[], newTag: string): boolean => tags.includes(newTag);

  const handleTagAction = () => {
    if (isTagInArray(tags, inputValue)) {
      setInputValue('');
      return;
    }

    if (inputValue.trim()) {
      const updatedTags = [...tags, inputValue.trim()];
      handleChange({ tags: updatedTags });
      handleBlur({ tags: updatedTags });
      setInputValue('');
    }
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      handleTagAction();
    }
  };

  const handleInputBlur = () => {
    if (inputValue.trim()) {
      handleTagAction();
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    handleChange({ tags: updatedTags });
    handleBlur({ tags: updatedTags });
  };

  return (
    <div className="tags-component">
      <input
        disabled={disabled}
        className="form-control"
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleInputKeyDown}
        onBlur={handleInputBlur}
        placeholder="Digite e pressione Enter"
      />
      <div className="tags-container">
        {tags.map((tag) => (
          <div key={tag} className="tag">
            {tag}
            <FaRegTrashAlt className="delete-icon" onClick={() => handleRemoveTag(tag)} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TagsCompoent;
