import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { RiSave3Line } from "react-icons/ri";
import { BiArrowBack } from "react-icons/bi";
import { MdEdit } from "react-icons/md";
import queryString from "query-string";
import MaskedFormControl from "react-bootstrap-maskedinput";
import { useHistory } from "react-router-dom";
import { cpf } from "cpf-cnpj-validator";
import ImportManager from "../components/ImportManager";
import {
  editUserSecondary,
  getUserSecondary,
  getProfilesSecondary,
} from "../services/NexiDigitalAPISecondary";
import AutoComplete from "../components/PeopleCraft/Autocomplete";

export default function EditUser(props) {
  const history = useHistory();
  const goBack = useCallback(() => history.push("/user"), [history]);
  let currentAdminValue = 0;
  const [error, setError] = useState("");
  const [errorSave, setErrorSave] = useState("");
  const [errorProfile, setErrorProfile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [wrongPassword, setWrongPassword] = useState(false);
  const [passwordEqualError, setPasswordEqualError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [invalidCpf, setInvalidCpf] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [profileSelected, setProfileSelected] = useState([]);
  const [loadingProfiles, setLoadingProfiles] = useState(true);
  const [selectedManager, setSelectedManager] = useState(null);
  const [userData, setUserData] = useState({
    idUser: 1,
    idCompany: 1,
    email: "",
    username: "",
    name: "",
    phone: "",
    cpf: "",
    userType: 1,
    userLicenceTermsAccepted: 2,
    communicationAccepted: 1,
    guestCompany: "",
    guestCnpj: "",
    admin: 1,
    active: 1,
    photo: "",
    department: "",
    role: "",
    employeeCode: "",
    unit: "",
    password: "",
    branch: ''
  });

  const filterObject = (arrayObjetos, ids) => {
    const idsSet = new Set(ids);
    return arrayObjetos.filter((objeto) => idsSet.has(objeto.idProfile));
  };
  // função para receber os dados do usuário
  function getUserData(id) {
    getUserSecondary(id)
      .then((res) => {
        if (res.data.success === true) {
          const idsProfile = res.data?.profile?.length
            ? res.data?.profile.map((elem) => elem.idProfile)
            : [];
          getAllProfiles(idsProfile);
          setLoading(false);
          setUserData({ ...res.data.user, password: "" });

          currentAdminValue = res.data.user?.admin;

          const manager = {
            idUser: res.data.user?.idManager,
            name: res.data.user?.managerName,
            email: res.data.user?.managerEmail,
          };
          setSelectedManager(res.data.user?.idManager ? manager : null);
        } else {
          setError("Erro ao carregar dados do usuário");
        }
      })
      .catch((err) => {
        setError("Erro ao carregar dados do usuário");
      });
  }

  const getUserDataCallBack = useCallback(() => {
    let search = queryString.parse(props.location.search);
    if (search.id) {
      getUserData(search.id);
    }
  }, [props.location.search]);

  async function getAllProfiles(idProfile) {
    setLoadingProfiles(true);
    const profilesResponse = await getProfilesSecondary();
    const profileList = profilesResponse?.map((profileItem) => ({
      ...profileItem,
      label: profileItem.name,
    }));

    setProfiles(profileList || []);
    selectProfile(profileList, idProfile);
    setLoadingProfiles(false);
  }

  async function handleProfileSelect(event, value) {
    setProfileSelected(value);
    errorProfile && setErrorProfile(false);
  }

  function selectProfile(newProfileList, idProfile) {
    if (!idProfile?.length) return;
    const profileElement = filterObject(newProfileList, idProfile);
    if (profileElement) {
      setProfileSelected(profileElement);
    }
  }

  useEffect(() => {
    getUserDataCallBack();
    window.scrollTo(0, 0);
  }, [getUserDataCallBack]);

  function saveData(event) {
    event.preventDefault();

    if (userData.cpf && !cpf.isValid(userData.cpf)) {
      setInvalidCpf(true);
      return;
    }

    const isPhoneValid = /^\([1-9]{2}\) (?:[2-8]|[1-9][1-9])[0-9]{3}\-[0-9]{4}$/.test(
      userData.phone,
    );
    if (!isPhoneValid && userData.phone !== "") {
      setInvalidPhone(true);
      return;
    }

    // resetar o status de erros
    setWrongPassword(false);
    setPasswordEqualError(false);
    setSuccessMessage("");
    setInvalidCpf(false);
    setErrorSave("");
    setInvalidPhone(false);

    const userId = userData.idUser;

    let newUserData = {
      active: userData.active,
      unit: userData.unit,
      employeeCode: userData.employeeCode,
      admin: userData?.admin === 0 ? 0 : currentAdminValue || 1,
      askPWA: userData.askPWA,
      communicationAccepted: userData.communicationAccepted,
      cpf: userData.cpf,
      department: userData.department,
      email: userData.email,
      guestCnpj: userData.guestCnpj,
      guestCompany: userData.guestCompany,
      name: userData.name,
      username: userData.username,
      phone: userData.phone,
      photo: userData.photo,
      role: userData.role,
      userLicenceTermsAccepted: userData.userLicenceTermsAccepted,
      userType: userData.userType,
      idProfile: profileSelected?.length
        ? profileSelected.map((elem) => elem.idProfile)
        : undefined,
      idManager: userData?.idManager,
      branch: userData?.branch
    };

    // adicionar password caso haja
    if (userData.password.length > 0) {
      newUserData.password = userData.password;
    }

    //validar senha (8 digit and a number)
    let passwordMatch = /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/gm.test(newUserData.password);
    if (!passwordMatch && newUserData.password) {
      setWrongPassword(true);
      return;
    }

    //validar confirmação de senha
    if (newUserData.password && newUserData.password !== confirmPassword) {
      setPasswordEqualError(true);
      return;
    }

    if (parseInt(userData.admin) === 1 && !profileSelected?.length) {
      setErrorProfile(true);
      return;
    }
    // salva os dados do usuário
    setSaving(true);
    editUserSecondary(userId, newUserData)
      .then((res) => {
        setSaving(false);
        if (res.data.success === true) {
          setSuccessMessage("Dados salvos com sucesso!");
        } else {
          setErrorSave("Erro ao gravar dados!");
        }
      })
      .catch((err) => {
        setSaving(false);
        setErrorSave("Erro ao gravar dados!");
      });
  }

  const handleOnClickManager = (manager) => {
    setUserData({ ...userData, idManager: manager.idUser });
    setSelectedManager(manager);
  };

  return (
    <>
      <div class="not-header-title" />
      <div className="user-edit-container" style={{ marginTop: "8%" }}>
        {loading && (
          <div className="user-edit-loading">
            {error.length > 0 ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {error}
                <Button onClick={goBack} variant="secondary">
                  <div>
                    <BiArrowBack size={22} style={{ marginBottom: 3, marginRight: 10 }} />
                    Voltar
                  </div>
                </Button>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className="loader loader-big" style={{ marginBottom: 15 }} />
                Carregando dados do usuário...
              </div>
            )}
          </div>
        )}
        <Form onSubmit={(event) => saveData(event)}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              fontSize: 22,
              marginBottom: 25,
            }}
          >
            <MdEdit style={{ marginTop: 4, marginRight: 10 }} />
            Edição de usuário
          </div>
          <div className="user-edit-inside">
            <div className="user-edit-inside-part">
              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Nome do usuário</Form.Label>
                    <Form.Control
                      required
                      value={userData.name}
                      type="text"
                      placeholder="Digite um nome"
                      onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                    />
                  </Col>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      required
                      value={userData.email}
                      type="email"
                      placeholder="Digite um email"
                      onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                    />
                  </Col>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Login</Form.Label>
                    <Form.Control
                      required
                      value={userData.username}
                      type="text"
                      placeholder="Digite um login"
                      onChange={(e) => setUserData({ ...userData, username: e.target.value })}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>
                      Nova senha <span style={{ color: "gray" }}>(Opcional)</span>
                    </Form.Label>
                    <Form.Control
                      className={wrongPassword ? "border-red" : "border-none"}
                      type="password"
                      autoComplete="new-password"
                      placeholder="Senha"
                      onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                    />
                    <Form.Text className="text-muted">Pelo menos 8 caracteres</Form.Text>
                    <Form.Text className="text-muted">Pelo menos uma letra</Form.Text>
                    <Form.Text className="text-muted">Pelo menos um número</Form.Text>
                  </Col>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Confirmar senha</Form.Label>
                    <Form.Control
                      className={passwordEqualError ? "border-red" : "border-none"}
                      type="password"
                      disabled={userData.password.length === 0}
                      autoComplete="new-password"
                      placeholder="Senha"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <ImportManager
                onClickManager={handleOnClickManager}
                selectedManager={selectedManager}
                idUser={userData.idUser}
              />

              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Cargo</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite um cargo"
                      value={userData.role}
                      onChange={(e) => setUserData({ ...userData, role: e.target.value })}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group>
                <Row>
                  <Col lg="4" md="4" xs="12" sm="7">
                    <Form.Label>Departamento</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite um departamento"
                      value={userData.department}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          department: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="6">
                    <Form.Label>Unidade</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite uma unidade"
                      value={userData.unit}
                      onChange={(e) => setUserData({ ...userData, unit: e.target.value })}
                    />
                  </Col>
                  <Col lg="6" md="6" xs="12" sm="6">
                    <Form.Label>Filial</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite o código da filial"
                      value={userData.branch}
                      onChange={(e) => setUserData({ ...userData, branch: e.target.value })}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="6">
                    <Form.Label>Código de colaborador</Form.Label>
                    <Form.Control
                      type="employeeCode"
                      placeholder="Digite código de colaborador"
                      value={userData.employeeCode}
                      onChange={(e) => setUserData({ ...userData, employeeCode: e.target.value })}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col lg="4" md="4" xs="12" sm="7">
                    <Form.Label>
                      CPF <span style={{ color: "gray" }}>(Opcional)</span>
                    </Form.Label>
                    <MaskedFormControl
                      type="text"
                      name="phoneNumber"
                      mask="111.111.111-11"
                      value={userData.cpf}
                      onChange={(e) => setUserData({ ...userData, cpf: e.target.value })}
                    />
                  </Col>
                  <Col lg="4" md="4" xs="12" sm="7">
                    <Form.Label>Celular</Form.Label>
                    <MaskedFormControl
                      value={userData.phone}
                      mask="(11) 11111-1111"
                      type="tel"
                      placeholder="Digite um Celular"
                      onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group>
                <Row>
                  <Col
                    lg={Boolean(userData.admin) ? 2 : 6}
                    md={Boolean(userData.admin) ? 2 : 6}
                    xs={Boolean(userData.admin) ? 5 : 12}
                    sm="5"
                  >
                    <Form.Label>Admin</Form.Label>
                    <Form.Control
                      as="select"
                      value={Boolean(userData.admin) ? 1 : 0}
                      onChange={(e) => {
                        setUserData({
                          ...userData,
                          admin: parseInt(e.target.value),
                        });
                      }}
                    >
                      <option value={0}>Não</option>
                      <option value={1}>Sim</option>
                    </Form.Control>
                  </Col>
                  {Boolean(userData.admin) && (
                    <Col lg="4" md="4" xs="7" sm="5">
                      <AutoComplete
                        error={parseInt(userData.admin) === 1 && errorProfile}
                        onChange={(e, value) => handleProfileSelect(e, value)}
                        tooltip="Selecione ao menos um perfil de permissão."
                        label="Associar ao perfil"
                        multiple={true}
                        loading={loadingProfiles}
                        value={profileSelected}
                        options={profiles}
                      ></AutoComplete>
                    </Col>
                  )}
                </Row>
              </Form.Group>
            </div>
          </div>

          {/* Botões */}
          <div style={{ display: "flex", marginTop: 30 }}>
            <Button onClick={goBack} variant="secondary" style={{ marginRight: 15 }}>
              <div>
                <BiArrowBack size={22} style={{ marginBottom: 3, marginRight: 10 }} />
                Voltar
              </div>
            </Button>

            <Button variant="success" type="submit">
              {saving ? (
                <div style={{ display: "flex" }}>
                  <RiSave3Line size={22} style={{ marginBottom: 3, marginRight: 10 }} />
                  Salvando <div className="loader loader-white" style={{ marginLeft: 15 }} />
                </div>
              ) : (
                <div>
                  <RiSave3Line size={22} style={{ marginBottom: 3, marginRight: 10 }} />
                  Salvar
                </div>
              )}
            </Button>
          </div>

          {/* Mensagens de erro e sucesso */}
          <div style={{ marginTop: 10 }}>
            <div style={{ color: "green" }}>{successMessage}</div>
            <div style={{ color: "red" }}>
              <p>{errorSave}</p>
              <p>{wrongPassword && "Senha não segue os padrões."}</p>
              <p> {passwordEqualError && "Confirme sua senha corretamente."}</p>
              <p> {invalidCpf && "CPF invalido."}</p>
              <p> {invalidPhone && "Celular invalido."}</p>
              <p> {errorProfile && "Para continuar Associal algum perfil ao usuário."}</p>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}
