import { ChangeEvent } from "react";
export enum Options {
  DEACTIVATED = "DEACTIVATED",
  MANDATORY = "MANDATORY",
  OPTIONAL = "OPTIONAL",
}
export interface Notification{
  description: string;
  idNotification: number;
  idNotificationNode: number;
  active: number;
  status: Options;
  type: string;
}
export interface ContentData {
  id?: number;
  title: string;
  shortTitle: string;
  label: string;
  description: string;
  image: null | string;
  originalNodeCode: any;
  company: {
    id: string;
  };
  nodeLevel: number;
  idNode?: string
  status: StatusTypes;
  nodeCode?: string;
  parentNodeCode: string;
  parentNodes?:any;
  availableToImport?: boolean;
  essential: boolean;
  validity?: number;
  visibility?: number;
  entryPoint: any;
  modality: string;
  selfEnrollment: boolean;
  author: string;
  authorEmail: string;
  tags: string[];
  idCertificate: string | null;
  meetings: any[];
  attendanceListAsApprovalCriteria: number;
  enableCertificateForChildren: boolean,
  hasEnrollmentStarted: boolean,
  hasImpactEvaluation: number,
  hasChildren: boolean
  nodeType?: string
  syllabus?: string
  notifications?: Notification[]
  hasEnrollmentCompleted?: boolean,
  idImpactEvaluation?: number
  workload?:string,
  sideMenu?: number
}

export enum StatusTypes {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  ARCHIVED = "ARCHIVED",
}

export interface ParamsHandle {
  event: ChangeEvent<HTMLInputElement>;
  data: {
    [key: string]: number | string | null
  };
}
