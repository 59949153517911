import React, { useContext, useState } from "react";
import "./styles.scss";

import { useManagerTrial } from "../../context/contextManagerTrial";
import { useModal } from "@ebay/nice-modal-react";
import { DialogModel, PlaneProps, Status } from "../../model/ManagerTrial.model";
import { postRequestBuyPlans, renewLogin } from "services/NexiDigitalAPI";
import Button from "components/PeopleCraft/Button";
import Text from "components/PeopleCraft/Text";
import { dialogContainerModel } from "../../Utils/DialogContainerModel";
import { AuthContext } from "context/AuthContext";
import { formatValueReal } from "utils/Utils";

const DetailPlanUpgrade = (props) => {
  const {
    currentCardUpgrade,
    setUpgradeStatus,
    setDialogPlan,
  } = useManagerTrial();
  const {
    name: planName,
    numberOfEmployees,
    totalMonthlyPrice,
    idPlan,
  } = currentCardUpgrade as PlaneProps;
  const { updateLicense, getLicense, signIn, getDataTrivial } = useContext(AuthContext);
  const [loading, setLoading] = useState(false)
  const modal = useModal();

  const confirm = async () => {
    try {
      setLoading(true)
      const result = await postRequestBuyPlans({
        idPlan,
        numberOfLicences: numberOfEmployees,
        price: totalMonthlyPrice,
      });

      if (!result) {
        modal.remove();
        return;
      }
      const resultRenew  = await renewLogin();
      updateRenewLogin(resultRenew?.data)
      updateLicense({
        ...getLicense(),
        isUpgradeButtonActive: false,
      });
      setLoading(false)

      setUpgradeStatus(Status.dialog);
      setDialogPlan(
        dialogContainerModel({
          type: DialogModel.ConfirmPlan,
          plan: planName,
          buttonConfirm: {
            callEvent: async () => {
              const _menuData = localStorage.getItem("menu-data");
              const { token } = _menuData ? JSON.parse(_menuData) : null;

              const _appDetails = localStorage.getItem("appDetails");
              const { domain } = _appDetails ? JSON.parse(_appDetails) : null;
              window.open(`https://${domain}/#/login?token=${token}`);

              modal.remove();
            },
          },
          buttonCancel: {
            callEvent: async () => {
              updateLicense({
                ...getLicense(),
                isUpgradeButtonActive: false,
              });
              modal.remove();
            },
          },
        }),
      );
    } catch (e) {
      console.error(e);
      modal.remove();
      setLoading(false)

    }
  };

  async function updateRenewLogin(data) {
    if (data.success === true && data.login.active === 1) {
      const menuOption = data?.menu?.[0]?.menuOption || [];
      localStorage.setItem("menu-option", JSON.stringify(menuOption));
      signIn(data.token, data.login);
      updateLicense({
        ...getLicense(),
        isUpgradeButtonActive: false,
      });
    }
  }

  return (
    <>
    {
      loading ? (
        <>
          <div className="loaderAdmin">
            <div className="preloaderIt"></div>
          </div>
        </>
      ) : (
        <div className="pctDetailPlanUpgrade">
        <Text component={"h2"} text={"Detalhes do seu pedido"} />
        <div className="pctDetailPlanUpgradeBase">
          <Text component={"p"} text={"Resumo da contratação:"} />
          <div className="containerInfo">
            <div className="lineInfo">
              <Text component={"p"} text={"Plano contratado:"} />
              <Text component={"p"} className="linePlane" text={`Plano ${planName}`} />
            </div>
            <div className="lineInfo">
              <Text component={"p"} text={"Quantidade de licenças desejadas:"} />
              <Text component={"p"} className="linePlane" text={`${numberOfEmployees}`} />
            </div>
            <div className="lineInfo">
              <Text component={"p"} text={"Valor mensal a ser pago:"} />
              <Text
                component={"p"}
                className="linePlane"
                text={`${formatValueReal(totalMonthlyPrice)}`}
              />
            </div>
          </div>
        </div>

        <div className="buttonsWrapper">
          {<Button callEvent={() => confirm()} className={`button06`} text={`Finalizar compra`} />}
        </div>
      </div>
      )
    }
    </>
  );
};

export default DetailPlanUpgrade;

