import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import ShowImageUploading from "../../../../../components/ShowImageUploading";
import { uploadImageApi } from "../../../../../services/NexiDigitalAPI";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";

const Modal = ({ show, hideModal, info, submitEdit, submitCreate }) => {
  const initialData = {
    title: "",
    imageURL: "",
    description: "",
    content: "",
  };

  const [value, setValue] = useState(initialData);
  const [imageStatus, setImageStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const imageRef = useRef();

  const submitForm = () => {
    if (Object.keys(initialData).length == Object.keys(value).length) {
      submitCreate(value);
    } else {
      submitEdit(value, value.idProduct);
    }
  };

  const changeHandler = (event) => {
    const chave = event.target.getAttribute("name");
    const targetValue = event.target.value;

    setValue({
      ...value,
      [chave]: targetValue,
    });
  };

  const imageChangeHandler = async (event) => {
    setLoading(true);
    let arquivo = event.target.files[0];
    const filename = "thumb_criar_Produto-" + Date.now();
    const data = new FormData();
    if (arquivo) {
      data.append("fileName", filename);
      data.append("fileData", arquivo);

      await uploadImageApi(data)
        .then((response) => {
          if (response.data.error) {
            setImageStatus(2);
          } else {
            setValue({
              ...value,
              imageURL: response.data.location,
            });
            setImageStatus(1);
          }
        })
        .catch((error) => {
          // If another error
          setImageStatus(2);
        });
    }
    setLoading(false);
  };

  const uploadImage = () => imageRef.current.click();

  const colorStyle = { backgroundColor: localStorage.getItem("secondaryColor") };

  useEffect(() => {
    if (show) {
      setValue(info);
    } else {
      setValue(initialData);
    }
  }, [show]);

  const modalHtml = (
    <div className={styles.modalWrapper}>
      <div className={styles.modalTitle} style={colorStyle}>
        Cadastrar novo produto
      </div>
      <div className={styles.modalFormWrapper}>
        <form>
          <label htmlFor="cadastroProdutoModalTitulo" className={styles.modalFormLabel}>
            Título
          </label>
          <input
            id="cadastroProdutoModalTitulo"
            onChange={changeHandler}
            value={value.title}
            className={styles.modalFormInput}
            name="title"
          />
          <label htmlFor="cadastroProdutoModalDescricao" className={styles.modalFormLabel}>
            Descrição
          </label>
          <textarea
            id="cadastroProdutoModalDescricao"
            className={styles.modalFormInput}
            name="description"
            value={value.description}
            onChange={changeHandler}
          ></textarea>
          <label htmlFor="cadastroProdutoModalConteudo" className={styles.modalFormLabel}>
            Conteúdo
          </label>
          <textarea
            id="cadastroProdutoModalConteudo"
            className={styles.modalFormInput}
            name="content"
            value={value.content}
            onChange={changeHandler}
          ></textarea>
          <label className={styles.modalFormLabel}>Imagem</label>
          <div className={styles.modalInputImageWrapper}>
            <input
              type="file"
              id="cadastroProdutoModalImagem"
              className={styles.modalFormInputFile}
              name="imageURL"
              ref={imageRef}
              onChange={imageChangeHandler}
              accept="image/png, image/jpeg, image/gif"
            />
            <ShowImageUploading
              loading={loading}
              status={imageStatus}
              height="100px"
              width="100px"
              image={value.imageURL}
            />
            <button
              onClick={uploadImage}
              type="button"
              className="btn btn-primary"
              style={colorStyle}
            >
              <PublishOutlinedIcon />
            </button>
          </div>
        </form>
      </div>
      <div className={styles.modalButtonWrapper}>
        <button onClick={submitForm} className="btn btn-primary" style={colorStyle}>
          Salvar
        </button>

        <button onClick={hideModal} className="btn btn-primary" style={colorStyle}>
          Cancelar
        </button>
      </div>
    </div>
  );

  return show && modalHtml;
};

export default Modal;
