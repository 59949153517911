import React, { useState, createContext, useReducer, useContext, useRef } from "react";
import PreloaderBlur from "../../../PeopleCraft/PreloaderBlur";
import ModalDialog from "containers/PeopleCraft/Modals/ModalDialog";

export const PAContainerContext = (props) => {
  const { preloadInit, showModalConfirm, setShowModalConfirm, showModalConfirmParamiters } =
    useContext(PACreateContext);

  return (
    <>
      {preloadInit && <PreloaderBlur />}
      {props.children}

      {showModalConfirm && (
        <ModalDialog
          title={showModalConfirmParamiters.current.title}
          text={showModalConfirmParamiters.current.text}
          className={showModalConfirmParamiters.current.className}
          show={showModalConfirm}
          setShow={setShowModalConfirm}
          btnCall={{
            className: "",
            variantBtn: showModalConfirmParamiters.current.variantBtn,
            text: showModalConfirmParamiters.current.textBtn,
            alertMode: showModalConfirmParamiters.current.alertMode,
            callEvent: () => {
              if (showModalConfirmParamiters.current.fnc) {
                showModalConfirmParamiters.current.fnc();
              }
            },
          }}
          btnDenyCall={showModalConfirmParamiters.current.fncDeny}
        />
      )}
    </>
  );
};

export const PACreateContext = createContext();

export const PAContext = ({ children }) => {
  // const [state, dispatch] = useReducer(reducer, initialState);

  const [preloadInit, setPreloadInit] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const showModalConfirmParamiters = useRef({
    text: "",
    variantBtn: "danger",
    textBtn: "",
    className: "",
    fnc: null,
  });
  const [containerRules, setContainerRules] = useState(null);

  const openConfirmModal = (objParamiters) => {
    if (objParamiters) {
      if (!objParamiters.variantBtn) {
        objParamiters.variantBtn = "danger";
      }
      showModalConfirmParamiters.current = objParamiters;
    }

    setShowModalConfirm(true);
  };

  const value = {
    preloadInit,
    setPreloadInit,
    showModalConfirm,
    setShowModalConfirm,
    showModalConfirmParamiters,
    openConfirmModal,
    containerRules,
    setContainerRules,
  };

  return <PACreateContext.Provider value={value}>{children}</PACreateContext.Provider>;
};
