import React, { useState, useEffect, useRef } from "react";
import { getMenuData, loginSecondary } from "../../services/NexiDigitalAPI";
import CompaniesModal from "../CompaniesModal/CompaniesModal";

const SecondaryTokenAuth = ({
  onStart = () => null,
  onStartLogin = () => null,
  onLoaded = () => null,
  onError = () => null,
}) => {
  const [selectedCompany, setSelectedCompany] = useState({});
  const adminLevel = getMenuData()?.admin;
  // const isCompanySelectRef = useRef(false);

  const _loginSecondary = () => {
    onStartLogin();

    const idApp = getMenuData()?.idApp;
    const idChannel = getMenuData()?.idChannel;
    const idAppType = getMenuData()?.idAppType;

    const loginSecondaryData = {
      idCompany: selectedCompany.idCompany,
      idApp,
      idChannel,
      idAppType,
    };
    loginSecondary(loginSecondaryData)
      .then((res) => {
        localStorage.setItem("token-secondary", res.data.token);
      })
      .catch((err) => {
        console.log("err", err);
        onError();
      })
      .finally(() => {
        onLoaded();
      });
  };
  useEffect(() => {
    selectedCompany?.idCompany && _loginSecondary();
  }, [selectedCompany]);

  return (
    <div>
      <div style={{ maxWidth: 620 }}>
        <CompaniesModal
          showInput
          setSelectedCompany={setSelectedCompany}
          selectedCompany={selectedCompany}
          disableSelection={adminLevel <= 1}
        />
      </div>
      <hr />
    </div>
  );
};

export default SecondaryTokenAuth;
