import "./style.scss";
import React, { useEffect, useState, useRef } from "react";
import Slider from "@material-ui/core/Slider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Cropper from "react-easy-crop";
import getCroppedImg from './cropImage'
import { Point, Area } from "react-easy-crop/types";



type Props = {
  className?: string
  label: string
  imageFormatName: string
  onCrop: any
  imageSource: any
  uploadedImage: any
  aspectRatio: any
}

function ImageCrop({  className = "", imageSource, uploadedImage, imageFormatName, label, aspectRatio = 4 / 3, onCrop }: Props ) {

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [currentImage, setCurrentImage] = useState("");
  const baseUrl = useRef("");
  const baseFormat = useRef(".blob");
  const initialLoad = useRef(true);
  const cropperRef = useRef(null);
  



  useEffect(() => {
    
    if(imageSource){
      baseUrl.current = imageSource.split('/').slice(0, -1).join('/');
      const imageFormatUrl = `${baseUrl.current}/${imageFormatName + baseFormat.current}`;

      loadInitialImage(imageFormatUrl);

    }else{

      initialLoad.current = false;
    }
    

  }, []);

  useEffect(() => {
    if(uploadedImage){
      setCurrentImage(uploadedImage);
    }

  }, [uploadedImage]);


  const loadInitialImage = async ( urlVerify : string ) => {
    
    const imgExist : any = await srcSetVerify(urlVerify);
    
    if(imgExist){
      setTimeout(() => {
        setCurrentImage(`${baseUrl.current}/${imageFormatName + baseFormat.current}?${Date.now()}`);
      }, 1000);
    }else{
      initialLoad.current = false;
      setCurrentImage(imageSource);
    }
    
  };

  const srcSetVerify = ( urlVerify : string ) => {

    const promise = new Promise((resolve, reject) => {

        const img = document.createElement("img");
        img.src = `${urlVerify}?${Date.now()}`;
        img.onerror = function () {
          resolve(false);
        };
        img.onload = function () {
          resolve(true);
        };
     
    });

    return promise;
    
  };

  const onCropComplete = async (croppedArea: Area, croppedAreaPixels: Area) => {

    const croppedImage = await getCroppedImg(
      imageFormatName,
      aspectRatio,
      currentImage,
      croppedAreaPixels,
      0,
      { horizontal: false, vertical: false },
      cropperRef
    );

    if(initialLoad.current){
      initialLoad.current = false;
    }else{
      onCrop(croppedImage);
    }

  };


  const uploadCurrentImage = async (arquivo : any) => {
    const imageFile = arquivo.files[0];
    setCurrentImage( window.URL.createObjectURL(imageFile));
  };


  // TODO: FAZER UM LOADING TIPO ESQUELETO.
  // if(currentImage === ""){
  //   return(
  //     <div className="loadingImage">
  //         <CircularProgress
  //             style={{ marginLeft: 10 }}
  //             size={18}
  //           />
  //     </div>
  //   )
  // }

  

  return (

    <div className="pctImageCropRoot">

      <p className="formatLabel"> {label} </p>

      <div className={`pctImageCrop ${className}`} >

        { (currentImage == "") &&

          <div className="loadingImage">
              <CircularProgress
                  style={{ marginBottom: "80px" }}
                  size={18}
                />
          </div>
        
        }
          
        <div className="crop-container">
            <Cropper
              ref={cropperRef}
              image={currentImage}
              crop={crop}
              zoom={zoom}
              aspect={aspectRatio}
              showGrid={true}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
        </div>
        
        <div className="cropFooter">
          
          <div className="controls">
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(Number(zoom))}
              classes={{ root: "slider" }}
            />
          </div>

      
          <label className="content-image-file-button btn">
            CARREGAR NOVA IMAGEM
            <input
              type="file"
              className="form-control-file"
              id="imagem"
              accept="image/png, image/jpeg, image/gif"
              onChange={(e) => uploadCurrentImage(e.target)}
            />
          </label>


        </div>
        
      </div>
    </div>
  )
}

export default ImageCrop
