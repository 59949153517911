import React from "react";
import { Button } from "react-bootstrap";

const ButtonWithLoading = ({ onClickHandler, loading, text, style, disabled }) => {
  const buttonStyle = {
    marginTop: 10,
    backgroundColor: disabled ? "grey" : localStorage.getItem("secondaryColor"),
    borderColor: disabled ? "grey" : localStorage.getItem("secondaryColor"),
    ...style,
  };

  return (
    <Button disabled={disabled} onClick={onClickHandler} style={buttonStyle}>
      {loading ? <>Carregando...</> : <>{text}</>}
    </Button>
  );
};

export default ButtonWithLoading;
