import React from "react";
import "../index.scss";
import { BsFillCollectionFill } from "react-icons/bs";

export const RecommendationCard = () => {
  return (
    <div style={{ flex: 1, padding: "15px 0px" }}>
      <div className="in-progress-card-container">
        <div className="in-progress-card-icon">
          <BsFillCollectionFill style={{ fill: "#eea125", width: 40, height: 40 }} />
        </div>
        <div>
          <div style={{ fontSize: 18, fontWeight: "bold" }}>Recomendações</div>
          <div style={{ color: "rgb(163, 163, 163)" }}>
            Recomendações feitas levam em consideração a frequência de
          </div>
          <div style={{ color: "rgb(163, 163, 163)" }}>
            consumo dos treinamentos a partir das tags selecionadas pelo usuário.
          </div>
        </div>
      </div>
    </div>
  );
};
