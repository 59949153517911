const FormCharacterCounterRemaning = (props) => {
  const { limit, value, color } = props;

  return (
    <span className="float-right" style={{ color }}>
      {limit - value.length}
    </span>
  );
};

export default FormCharacterCounterRemaning;
