import "./style.scss";
import React from "react";

type Props = {
  number: number;
  className?: string;
  init?: boolean;
};

function PlaceholderParameters({ number = 5, className = "", init = false }: Props) {
  let rows: number[] = [];
  let i: number = 0;

  while (++i <= number) {
    rows.push(i);
  }
  const PlaceholderItem = ({ style, className }) => {
    return (
      <div className={`ph-wrapper ${className}`} style={style}>
        <div className="ph-line-base">
          <div className="ph-text short marginBottomG ph-animated"></div>
          
          {/* <div className="infoIn marginBottomG"> 
            <div className="ph-base-b">
              <div className="ph-text shortLabel ph-animated"></div>
              <div className="ph-text short ph-animated"></div>
            </div>
          </div> */}

          <div className="ph-text short marginBottomP ph-animated"></div>
          
          {/* <div className="infoIn marginBottomG"> 
            <div className="ph-base-b">
              <div className="ph-text short ph-animated"></div>
            </div>
          </div> */}

          <div className="ph-text short marginBottomP ph-animated"></div>
          <div className="infoIn marginBottomG"> 
            {/* <div className="ph-base-b">
              <div className="ph-text ph-check ph-animated"></div>
              <div className="ph-text short ph-animated"></div>
            </div> */}
            <div className="ph-base-b">
              <div className="ph-text ph-check ph-animated"></div>
              <div className="ph-text short ph-animated"></div>
            </div>
          </div>

        </div>
      </div>
    );
  };

  return (
    <div className={`pg-grid-placeholder-parameters ${init ? "ph-wrapperInit" : ""}`}>
      {rows.map(function (i) {
        return (
          <PlaceholderItem
            key={i}
            style={{ paddingLeft: Math.round(i % 2 ? i * 20 : 20) + "px" }}
            className={className}
          />
        );
      })}
    </div>
  );
}

export default PlaceholderParameters;
