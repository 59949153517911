import { NiceModalHandler } from "@ebay/nice-modal-react";

export enum Profile {
  admim = "admin",
  client = "client",
}
export enum OriginNavigation {
  header = 'header',
  fluidNavigation = 'fluid navigation'
}

export enum Trial {
  notStarted = 'notStarted',
  started  = 'started',
  finish = 'finish',
}

export enum DialogModel{
  ConfirmTrial = 'ConfirmTrial',
  ConfirmSucess  = 'ConfirmSucess',
  ConfirmPlan = 'ConfirmPlan',
  FinishTrial = 'FinishTrial',
  ExtendTrial = 'ExtendTrial'
}

export interface TextPlansStatus{
  screen?: string,
  element?: string,
  trialStatusPlan ?: Trial ,
  plan?: string,
  deadline?: number,
  trialPlan?: boolean,
  requestsInfo?: number
}

export enum Status {
  upgrade  = 'upgrade',
  feedback = 'feedback',
  close = 'close',
  terms = 'terms',
  trial = "trial",
  enteprise = "enteprise",
  alert = "alert",
  form = 'form',
  selectedPlan = 'selectedPlan',
  calculatorPlan = 'calculatorPlan',
  detail='detail',
  dialog='dialog'
}

export interface ModalUpgradeProps {
  obj: {
    profile?: Profile.admim | Profile.client;
    numberOfClient?: number;
    hasRequestedUpgrade?: boolean,
    originNavigation?:OriginNavigation
  };
};

export interface ManagerTrialProps {
  profile?: Profile.admim | Profile.client;
  modal?: NiceModalHandler;
};

export interface Price {
  idLicense: number;
  idLicensePrice: number;
  maxUsers: number;
  minUsers: number;
  pricePerUser: number;
}

export interface Product {
  description: string;
  idCompany: number;
  idProduct: number;
  name: string;
  nodeCode: string;
}

export interface Feature {
  description: string;
  name: string;
  idFeature: number;
}

export interface planUpgradeProps {
  hasRequestedUpgrade: boolean,
  isUpgradeButtonActive: boolean,
  numberRequest: number,
  userIsAdmin: boolean,
  plansContainer: PlansContainerProps[],
}

export interface PlaneProps {
  description: string,
  name: string,
  idApp: number,
  idPlan: number,
  features: Feature[],
  products: Product[],
  idRef?: string,
  agreementContent?: string,
  token?: string,
  domain?: string
  deadline?: number,
  numberOfEmployees?: number,
  totalMonthlyPrice?: number,
  planUpgrade?: planUpgradeProps
}

export interface CardUpgradeProps {
  active?: boolean;
  elem: PlaneProps;
  showDetail?: Boolean;
};

export interface PlansContainerProps {
  idAppAvailable: number,
  idData: number,
  plans:PlaneProps[],
}

export interface LicenseUpgradeProps {
  hasRequestedUpgrade: boolean,
  isUpgradeButtonActive: boolean,
  numberRequest: number,
  userIsAdmin: boolean,
  plansContainer: PlansContainerProps[],
}


export interface FormProps  {
  idUser: number;
  idCompany: number;
  company: string;
  email: string | null;
  name: string;
  role: string | null;
  department: string | null;
  description: string | null;
  numberOfEmployees: string;
};

export type PropsOpenModal = {
  numberRequest: number
  userIsAdmin: boolean,
  hasRequestedUpgrade?: boolean,
  isUpgradeButtonActive?: boolean,
  originNavigation: OriginNavigation
}

export interface DialogProps{
  type?: DialogModel;
  title?: string,
  subTitle?: string,
  plan?: string,
  agreement?:number,
  buttonCancel ?: {
    text?: string,
    class?: string,
    callEvent ?: () => void;
  },
  buttonConfirm ?: {
    text?: string,
    class?: string,
    callEvent ?: () => void;
  }
}
