import "./style.scss";
import React, { useEffect, useState, useRef } from "react";
import imageCompression from "browser-image-compression";
import CircularProgress from "@material-ui/core/CircularProgress";
import { uploadImageApi } from "services/NexiDigitalAPI";
import ImageCrop from "components/ImageCrop";

type Props = {
  className?: string;
  reference?: any;
  data: any;
  sendData: any;
  defaultPrefixName?: string;
  disabled?: boolean;
  showMessageRequired?: boolean;
  uploadImageFeedback?: (e:  EventTarget & HTMLInputElement) => void
};

function ContentImageUpload({
  className = "",
  reference,
  data,
  sendData,
  defaultPrefixName,
  disabled,
  showMessageRequired = false,
  uploadImageFeedback
}: Props) {
  const imagesToUpload: any = useRef([]);
  const [defaultUploadedImage, setDefaultUploadedImage] = useState(null || "");
  const [carregaImagem, setCarregaImagem] = useState(false);
  const baseImage = useRef(
    data.image !== null && data.image !== ""
      ? data.image
          .split("/")
          .slice(0, data.image.length - 1)
          .splice(-1, 1)
          .join()
          .split(".")[0]
      : defaultPrefixName
      ? `${defaultPrefixName}-${Date.now()}`
      : `image-${Date.now()}`,
  );

  const [saveImageBtn, setSaveImageBtn] = useState(false);
  const [imagemUploadStatus, setImagemUploadStatus] = useState(false);
  const [mensagemImagemUpload, setMensagemImagemUpload] = useState("");
  const editContent = useRef(data.image === null || data.image === "" ? false : true);

  const contentData = useRef(data);
  const urlLocationRef = useRef(null);

  useEffect(() => {
    if (reference) {
      reference.current = {
        uploadImagem,
        onCropImage,
        uploadDefaultImage,
        imagesToUpload,
      };
    }
  }, [reference]);

  const uploadImagem = async (e: any) => {
    setSaveImageBtn(false);

    if (e) {
      e.preventDefault();
    }

    setCarregaImagem(true);
    setMensagemImagemUpload("");

    const options = {
      maxSizeMB: 0.9,
      maxWidthOrHeight: 1920,
      fileType: "image/webp",
    };

    const baseImageObject = imagesToUpload.current.filter(
      (x: any) => x.imageFormatName.indexOf("-format-landscape-large") != -1,
    );

    if (baseImageObject.length > 0) {
      imagesToUpload.current.push({
        imageFormatName: baseImage.current,
        file: baseImageObject[0].file,
        aspectRatio: baseImageObject[0].aspectRatio,
        blob: baseImageObject[0].blob,
      });
    }

    const promise = new Promise(async (resolve, reject) => {
      if (imagesToUpload.current.length == 0) {
        resolve(null);
      }

      while (imagesToUpload.current.length > 0) {
        const currentImageObject = imagesToUpload.current.shift();
        const imageFile = currentImageObject.file;

        try {
          const compressedFile = await imageCompression(imageFile, options);
          const filename = currentImageObject.imageFormatName;
          const dataFormat = new FormData();

          if (compressedFile) {
            dataFormat.append("fileName", filename);
            dataFormat.append("fileData", compressedFile);
            uploadImageApi(dataFormat)
              .then((response) => {
                if (response.data?.error) {
                  setCarregaImagem(false);
                  setImagemUploadStatus(false);
                  setMensagemImagemUpload("Erro ao fazer upload do arquivo");
                } else {
                  if (baseImageObject.length > 0) {
                    if (filename == `${baseImage.current}`) {
                      resolve(response.data?.location);
                    }
                  } else if (imagesToUpload.current.length == 0) {
                    resolve(null);
                  }
                }
              })
              .catch((error) => {
                // If another error
                console.log("error", error);
                setCarregaImagem(false);
                setImagemUploadStatus(false);
                setMensagemImagemUpload("Erro ao fazer upload do arquivo");
              });
          }
        } catch (error) {
          console.log(error);
        }

        if (imagesToUpload.current.length == 0) {
          setCarregaImagem(false);
          setImagemUploadStatus(true);
          setMensagemImagemUpload("Imagens atualizadas com successo.");
        }
      }
    });

    return promise;
  };

  const onCropImage = async (croppedImage: any) => {
    imagesToUpload.current = imagesToUpload.current.filter(
      (x: any) => x.imageFormatName !== croppedImage.imageFormatName,
    );

    imagesToUpload.current.push(croppedImage);

    if (imagesToUpload.current.length > 0) {
      setSaveImageBtn(true);
    }
  };

  const uploadDefaultImage = async (arquivo: any) => {
    const imageFile = arquivo.files[0];
    setDefaultUploadedImage(window.URL.createObjectURL(imageFile));
  };

  return (
    <div className={`pctContentImageUpload ${className}`}>
      <div className="node-images-crop-body">
        <div className="crop-images-header">
          <label className="content-page-file-button">
            Escolher arquivo
            <input
              disabled={disabled}
              type="file"
              className="form-control-file"
              id="imagem"
              accept="image/png, image/jpeg, image/gif"
              onChange={(e) => {
                uploadDefaultImage(e.target);
                if(uploadImageFeedback){
                  uploadImageFeedback(e.target)

                }
              }}
            />
          </label>

          <p className="infoSize">
            Tamanho recomendado para melhor adaptação em todos os formatos:<b>1920 x 900px</b>.
          </p>
          {showMessageRequired && <span className="invalid-feedback">A imagem é obrigatória.</span>}
        </div>

        {/* TODO: EDIT CONTENT */}
        {editContent.current && (
          <p>
            <b>Atenção:</b> Ao selecionar o arquivo todos os formatos serão substituídos pela nova
            imagem selecionada. Se você pretende apenas trocar um dos formatos você pode editar
            diretamente nele. Após qualquer alteração é necessário clicar no botão salvar.
          </p>
        )}

        {carregaImagem ? (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingRight: 20,
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div className="crop-images-container">
            {(contentData.current.image || defaultUploadedImage) && (
              <>
                <ImageCrop
                  imageSource={contentData.current.image}
                  uploadedImage={defaultUploadedImage}
                  label={"Formato paisagem - (card):"}
                  imageFormatName={`${baseImage.current}-format-landscape-xsmall`}
                  aspectRatio={4 / 3}
                  onCrop={onCropImage}
                />
                <ImageCrop
                  imageSource={contentData.current.image}
                  uploadedImage={defaultUploadedImage}
                  label={"Formato quadrado - (card):"}
                  imageFormatName={`${baseImage.current}-format-square`}
                  aspectRatio={1}
                  onCrop={onCropImage}
                />
                <ImageCrop
                  imageSource={contentData.current.image}
                  uploadedImage={defaultUploadedImage}
                  label={"Formato retrato - (card e masterbanner na versão mobile):"}
                  imageFormatName={`${baseImage.current}-format-portrait`}
                  aspectRatio={3 / 4}
                  onCrop={onCropImage}
                />

                <ImageCrop
                  imageSource={contentData.current.image}
                  uploadedImage={defaultUploadedImage}
                  label={"Formato paisagem - (masterbanner grande):"}
                  imageFormatName={`${baseImage.current}-format-landscape-large`}
                  aspectRatio={32 / 15}
                  onCrop={onCropImage}
                />
                <ImageCrop
                  imageSource={contentData.current.image}
                  uploadedImage={defaultUploadedImage}
                  label={"Formato paisagem - (masterbanner médio):"}
                  imageFormatName={`${baseImage.current}-format-landscape-middle`}
                  aspectRatio={64 / 23}
                  onCrop={onCropImage}
                />
                <ImageCrop
                  imageSource={contentData.current.image}
                  uploadedImage={defaultUploadedImage}
                  label={"Formato paisagem - (masterbanner pequeno):"}
                  imageFormatName={`${baseImage.current}-format-landscape-small`}
                  aspectRatio={96 / 25}
                  onCrop={onCropImage}
                />

                {/* TODO: EDIT CONTENT */}
                {/* {editContent.current && saveImageBtn && (
                  <div
                    className="btnSaveImages btn content-page-button"
                    onClick={(e) => {
                      uploadImagem(e);
                    }}
                  >
                    Salvar alterações
                  </div>
                )} */}
              </>
            )}
          </div>
        )}

        <div className="content-page-img-feedback">
          {imagemUploadStatus ? (
            <span style={{ color: "green", marginTop: 5 }}>{mensagemImagemUpload}</span>
          ) : (
            <span style={{ color: "red", marginTop: 5 }}>{mensagemImagemUpload}</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContentImageUpload;
