import React, { useState } from "react";

import flow from "../../../../assets/images/flow.svg";
import page from "../../../../assets/images/page.svg";
import { BsShareFill } from "react-icons/bs";

import MenuActions from "./MenuActions";
import ImageDrop from "./ImageDrop";

export const DropdownSkyn = ({
  onSelectCallback,
  branch,
  children,
  nodeCurrent,
  preload,
  deleteItem,
  viewTeam,
  setIsSidebarOpen
}) => {
  const { id, hasChildNodes, title, isSelected, nodeCode, home, entryPoint, status, nodeType } = branch;
  const [open, setOpen] = useState(isSelected);
  const redirectFromOutsideCompany = branch.redirect === 1 && branch.originCode.split('.')[0] !== branch.nodeCode.split('.')[0];

  return (
    <div
      className={`elemDSkyn ${hasChildNodes === 0 ? "simple" : ""} ${preload ? "preloadSkyn" : ""}`}
    >

      {
        (status != "ARCHIVED") && (
          <button
              className={`expandIcon ${open || home ? "open" : ""}`}
              onClick={(e) => {
                setOpen(home ? home : !open);
                //False para não atualizar a autoria, apenas expandir a árvore.
                onSelectCallback(e, false);
                e.preventDefault();
              }}
            >
          </button>
        )
      }

      <button
        id={id}
        className={`titleList ${open || home ? "open" : ""} ${
          (nodeCurrent && nodeCurrent?.nodeCode === nodeCode) || (nodeCurrent && nodeCurrent?.originalNodeCode === nodeCode) ? "selected" : ""
        } ${home ? "homeTitleList" : ""} ${status}  ${redirectFromOutsideCompany ? "redirectFromOutsideCompany": ''}`}
        onClick={(e) => {
          if(!redirectFromOutsideCompany){
            setOpen(home ? home : !open);
            onSelectCallback(e);
          }
          e.preventDefault();
        }}
      >
        <div className="tiInfo">
          {nodeType === "LEARNING" ? (
            <img src={page} className="icoTiInfo" />
          ) : (
            <img src={flow} className="icoTiInfo" />
          )}{" "}

          {(branch.redirect === 1) &&
            <BsShareFill style={ {
              'font-size': '10px',
              'min-width': '10px',
              'min-height': '10px'
          } }/>
          }

          <div className="titleName"> {title} </div>

          {/* {redirectFromOutsideCompany && (
              <div className="titleName"> (Página externa.) </div>
            )
          } */}

        </div>

      </button>

      <MenuActions branch={branch} deleteItem={deleteItem} viewTeam={viewTeam} setIsSidebarOpen={setIsSidebarOpen}/>

      <div className={`contentList ${open || home ? "open" : ""} ${home ? "homeContentList" : ""}`}>
        {children.length > 0 && (open || home) && children}
      </div>
    </div>
  );
};
