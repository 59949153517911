import React from "react";

function TagsInput({ tags = [], setTags, disabled = false }) {
  const handleInputChange = (event) => {
    const input = event.target.value;
    const newTags = input.split(",").map((tag) => tag.trim());
    setTags(newTags);
  };

  return (
    <div>
      <label htmlFor="tags">Digite tags separadas por virgula</label>
      <input
        disabled={disabled}
        type="text"
        id="tags"
        className="form-control"
        onChange={handleInputChange}
        placeholder="Liderança, Inovação, Marketing Digital"
        value={tags}
      />
    </div>
  );
}

export default TagsInput;
