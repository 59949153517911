import React, { useContext } from "react";
import TitleIcon from "@material-ui/icons/Title";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import { BsFillGridFill } from "react-icons/bs";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import "./index.css";
import { nodeTypes } from "./NodeTypes";
import { MdPanorama } from "react-icons/md";
import { AiFillCalendar } from "react-icons/ai";
import { ReactComponent as ProgressIcon } from "../../../assets/images/bars-progress-solid.svg";
import { NodeManagerContext } from "./NodeManagerProvider";
import { statusTypes } from "../ContentPage/StatusTypes";

const BlockOptions = ({ newBlock, loadingAddBlock }) => {
  const { nodeData } = useContext(NodeManagerContext);
  const nodeType = nodeData?.nodeType;
  const blockOptionStyle = {
    fontSize: 25,
  };
  const allOptions = [
    {
      title: "Título",
      subTitle: "Título",
      type: nodeTypes.TITLE,
      icon: <TitleIcon style={blockOptionStyle} />,
    },
    {
      title: "Texto",
      subTitle: "Texto/Descrição",
      type: nodeTypes.TEXT,
      icon: <TextFormatIcon style={blockOptionStyle} />,
    },
    {
      title: "Lista",
      subTitle: "Lista de conteúdo",
      type: nodeTypes.LIST,
      icon: <ViewListIcon style={blockOptionStyle} />,
    },
    {
      title: "Carrossel",
      subTitle: "Seu conteúdo será visualizado como carrossel",
      type: nodeTypes.CAROUSEL,
      icon: <ViewCarouselIcon style={blockOptionStyle} />,
    },
    {
      title: "Master Banner",
      subTitle: "Master Banner",
      type: nodeTypes.MASTER_BANNER,
      icon: <MdPanorama style={blockOptionStyle} />,
    },
    {
      title: "Catálogo",
      subTitle: "Catálogo",
      type: nodeTypes.CATALOG,
      icon: <ViewWeekIcon style={blockOptionStyle} />,
    },
    {
      title: "Grade",
      subTitle: "Seu conteúdo será visualizado como grade",
      type: nodeTypes.GRID,
      icon: <BsFillGridFill style={blockOptionStyle} />,
    },
    {
      title: "Agenda",
      subTitle: "Agenda",
      type: nodeTypes.CALENDAR,
      icon: <AiFillCalendar style={blockOptionStyle} />,
    },
    {
      title: "Em Progresso",
      subTitle: "Em Progresso",
      type: nodeTypes.IN_PROGRESS,
      icon: <ProgressIcon style={blockOptionStyle} />,
    },
    // {
    //     title: "Avaliações",
    //     subTitle: "Avaliações",
    //     type: nodeTypes.ASSESSMENT,
    //     icon: <AssignmentIcon style={blockOptionStyle} />
    // },
    // {
    //     title: "Enquetes",
    //     subTitle: "Enquetes",
    //     type: nodeTypes.SURVEY,
    //     icon: <RiSurveyLine style={blockOptionStyle} />
    // },
  ];

  const nodeTypeOrganization = [
    nodeTypes.TITLE,
    nodeTypes.TEXT,
    nodeTypes.LIST,
    nodeTypes.CAROUSEL,
    nodeTypes.MASTER_BANNER,
    nodeTypes.CATALOG,
    nodeTypes.GRID,
    nodeTypes.CALENDAR,
    nodeTypes.IN_PROGRESS,
  ];

  const nodeTypeLearning = [
    nodeTypes.TITLE,
    nodeTypes.TEXT,
    nodeTypes.LIST,
    nodeTypes.CAROUSEL,
    nodeTypes.MASTER_BANNER,
    nodeTypes.GRID,
    nodeTypes.CATALOG,
  ];

  const filterOptions = (mustContain) => {
    const newOptions = mustContain.map((item) => {
      const findOption = allOptions.find((option) => option.type === item);
      return findOption;
    });

    return newOptions;
  };

  const blockOptions = () => {
    if (nodeType === "ORGANIZATION") {
      return filterOptions(nodeTypeOrganization);
    }
    if (nodeType === "LEARNING") {
      return filterOptions(nodeTypeLearning);
    }
    return allOptions;
  };

  return (
    <div className="node-options-container">
      {blockOptions().map((blockItem, index) => (
        <div
          key={`node-options-${index}`}
          className="node-options-item"
          style={{
            pointerEvents:
              ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                nodeData?.nodeType !== "ORGANIZATION") ||
              loadingAddBlock
                ? "none"
                : "auto",
          }}
          onClick={() =>
            [statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
            nodeData?.nodeType !== "ORGANIZATION"
              ? null
              : newBlock({
                  title: blockItem.subTitle,
                  blockType: blockItem.type,
                  // id: `${new Date().getTime()}-${String(index)}`
                })
          }
        >
          {blockItem.icon}
          <span className="text-center">{blockItem.title}</span>
        </div>
      ))}
    </div>
  );
};

export default BlockOptions;
