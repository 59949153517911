import React from "react";
import { bool, func, array } from "prop-types";
import { Table } from "react-bootstrap";
import "./AddUser.css";
import MaskInput from "react-maskinput/lib";

const propTypes = {
  handleChange: func.isRequired,
  loading: bool,
  autoIncrement: bool,
  autoFocus: bool,
  tableData: array.isRequired,
  tableSettings: array.isRequired,
};

export default function EditableTable({
  handleChange,
  loading,
  autoIncrement,
  autoFocus,
  tableData,
  tableSettings,
}) {
  const handleChangeTable = (field, value, index) => {
    if (field === "workload") {
      if (isNaN(value) || value < 0) return;
      // transform workload minutes into seconds when inserting
      value = Math.floor(Number(value) * 60);
    }
    let newFormData = [...tableData];

    newFormData[index][field] = value;
    handleChange(newFormData);
  };

  const transformData = (field, data) => {
    if (field === "workload") {
      // transform workload seconds into minutes
      data = Math.floor((data || 0) / 60);
    }

    return data;
  };

  return (
    <div className="mt-3">
      {loading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            border: "1px solid lightgray",
            borderRadius: 5,
            padding: 25,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="loader loader-big" />
          <span>Carregando...</span>
        </div>
      ) : (
        <Table bordered>
          <thead>
            <tr>
              {autoIncrement && <th style={{ textAlign: "center" }}>#</th>}
              {tableSettings.map(({ header, align = "left" }) => (
                <th key={header} style={{ textAlign: align }}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((data, tableIndex) => (
              <tr key={tableIndex}>
                {autoIncrement && <td style={{ textAlign: "center" }}>{tableIndex + 1}</td>}
                {tableSettings.map(
                  (
                    {
                      field,
                      editable,
                      align = "left",
                      maskOptions,
                      maxLength,
                      defaultValue,
                      disabled,
                      style,
                      placeholder,
                      type = "text",
                    },
                    tableSettingsindex,
                  ) => (
                    <td
                      key={tableSettingsindex}
                      style={editable ? { padding: 0 } : { textAlign: align }}
                    >
                      {editable && !maskOptions ? (
                        <>
                          <input
                            placeholder={placeholder}
                            type={type}
                            className="form-control"
                            id={field}
                            aria-describedby={field}
                            autoFocus={autoFocus && tableIndex === 0}
                            maxLength={maxLength}
                            style={
                              style || {
                                all: "unset",
                                margin: "0 auto",
                                width: "97%",
                                textAlign: align,
                              }
                            }
                            defaultValue={transformData(field, data[field]) || defaultValue}
                            onChange={({ target }) =>
                              handleChangeTable(field, target.value, tableIndex)
                            }
                            disabled={disabled}
                          />
                          {(autoFocus = false)}
                        </>
                      ) : editable && maskOptions ? (
                        <>
                          <MaskInput
                            style={
                              style || {
                                all: "unset",
                                margin: "0 auto",
                                width: "97%",
                                textAlign: align,
                              }
                            }
                            alwaysShowMask
                            showMask
                            mask={maskOptions.mask || "00:00"}
                            size={maskOptions.size || 16}
                            maskChar={maskOptions.maskChar || "0"}
                            value={data[field] || ""}
                            onChange={({ target }) =>
                              handleChangeTable(field, target.value, tableIndex)
                            }
                            defaultValue={data[field] || defaultValue}
                            disabled={disabled}
                          />
                          {(autoFocus = false)}
                        </>
                      ) : (
                        data[field] || "-"
                      )}
                    </td>
                  ),
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}

EditableTable.propTypes = propTypes;
