import "./styles.modules.scss";

import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { CircularProgress } from "@material-ui/core";
import ModalWarning from "components/ModalWarning";
import JoditEditorContent from "components/jodit";
import { Button, Modal } from "react-bootstrap";

import Loading from "components/Loading";

import {
  ParametersEvaluationReactionJodit,
  ParametersEvaluationKnowledgeJodit,
  modelEvaluation,
} from "utils/Utils";

import TagsQuestion from "./Components/TagsQuestion";
import CategoriesListQuestion from "./Components/CategoriesListQuestion";
import ManagementReaction from "./Components/ManagementReaction";
import ManagementKnowledge from "./Components/ManagementKnowledge";

import useCreateQuestion from "./Hooks/useCreateQuestion";

const ModalCreateQuestion = ({
  show,
  setShow,
  title,
  setQuestionsList,
  questionsList,
  idQuestion = null,
  evaluationModel,
  returnContentData,
  onCloseModal = null,
}) => {
  const {
    setShowWarningModal,
    showWarningModal,
    handleDeny,
    statement,
    setStatement,
    typeQuestion,
    setTypeQuestion,
    questionScales,
    setQuestionScales,
    questionOptions,
    setQuestionOptions,
    handleAddCategories,
    categories,
    setCategories,
    tagsEnd,
    setTagsEnd,
    sabeBlock,
    loadingUpdateContent,
    loadingUpdateContentRefresh,
    saveQuestion,
    loadingQuestion,
    validateForm,
    setValidateForm
  } = useCreateQuestion(
    setShow,
    show,
    setQuestionsList,
    questionsList,
    evaluationModel,
    idQuestion,
    returnContentData,
  );

  return (
    <>
      <Modal
        show={show}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        className={`ModalCreateQuestion ${
          evaluationModel && evaluationModel.evaluacionType !== modelEvaluation.KNOWLEDGE
            ? "KNOWLEDGE_NOT"
            : ""
        }`}
        centered
        size="xl"
        enforceFocus={false}
        onHide={() => {
          if (onCloseModal) onCloseModal();
        }}
      >
        <div
          className="modal-support-blur modal-support-blur-warning"
          style={{ borderRadius: 10 }}
        />
        <div
          onClick={() => setShowWarningModal(true)}
          className="modal-warning-close-container"
          style={{ margin: 15, padding: 0, backgroundColor: "#6b48ff" }}
        >
          <CloseIcon style={{ color: "white" }} className="modal-warning-close" />
        </div>
        <div className="modal-principal-warning" style={{ minHeight: 400 }}>
          {showWarningModal && (
            <ModalWarning
              description={
                <div style={{ textAlign: "center" }}>
                  Tem certeza que deseja continuar? As informações serão perdidas.
                </div>
              }
              acceptAction={() => {
                handleDeny();
                setShowWarningModal(false);
                if (onCloseModal) onCloseModal();
              }}
              denyAction={() => setShowWarningModal(false)}
              denyText="Cancelar"
            />
          )}
          <Modal.Title style={{ textAlign: "center", fontSize: 18, marginTop: 15 }}>
            {title}
          </Modal.Title>

          {loadingQuestion ? (
            <Loading>Carregando Questão...</Loading>
          ) : (
            <>
              <div className="modal-body-warning" style={{ padding: 20 }}>
                <div className="form-group">
                  <div className="card">
                    <div className="card-header template-card-header">Enunciado da questão</div>
                    <div className="card-body template-card-body">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <JoditEditorContent
                            content={statement}
                            onBlur={(e) => {
                              setStatement(e);
                            }}
                            customJodit={
                              evaluationModel &&
                              evaluationModel.evaluacionType === modelEvaluation.KNOWLEDGE
                                ? ParametersEvaluationKnowledgeJodit()
                                : ParametersEvaluationReactionJodit()
                            }
                            loading={(e) => {}}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {evaluationModel &&
                  (evaluationModel.evaluacionType === modelEvaluation.REACTION ||
                    evaluationModel.evaluacionType === modelEvaluation.IMPACT) && (
                    <ManagementReaction
                      typeQuestion={typeQuestion}
                      setTypeQuestion={setTypeQuestion}
                      questionOptions={questionOptions}
                      setQuestionOptions={setQuestionOptions}
                      questionScales={questionScales}
                      setQuestionScales={setQuestionScales}
                    />
                  )}

                {evaluationModel &&
                  evaluationModel.evaluacionType === modelEvaluation.KNOWLEDGE && (
                    <ManagementKnowledge
                      typeQuestion={typeQuestion}
                      setTypeQuestion={setTypeQuestion}
                      questionOptions={questionOptions}
                      setQuestionOptions={setQuestionOptions}
                      questionScales={questionScales}
                      setQuestionScales={setQuestionScales}
                      validateForm={validateForm}
                      setValidateForm={setValidateForm}
                    />
                  )}

                {evaluationModel && evaluationModel.evaluacionType !== modelEvaluation.IMPACT && (
                  <div className="form-group form-group-block-02">
                    <div className="card">
                      <div className="card-header template-card-header">
                        Categorização por páginas
                      </div>
                      <div className="card-body template-card-body">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <h4>
                              Neste espaço, você pode categorizar a sua avaliação de conhecimento
                              por páginas. Adicione e edite conforme suas preferências.
                            </h4>

                            <CategoriesListQuestion
                              type={"questions"}
                              handleAddCategories={handleAddCategories}
                              isCreating
                              categoriesList={categories}
                              setCategoriesList={setCategories}
                              idQuestion={idQuestion}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="form-group form-group-block-02">
                  <div className="card">
                    <div className="card-header template-card-header">Tags</div>
                    <div className="card-body template-card-body">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <TagsQuestion tagEnd={tagsEnd} setTagEnd={setTagsEnd} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="row pr-3"
                  style={{ padding: 5, justifyContent: "flex-end", gap: 20 }}
                >
                  <Button
                    variant="outline-primary"
                    className={`${sabeBlock ? "btnInativo" : ""} ${
                      loadingUpdateContent ? "btnUpdate" : ""
                    }`}
                    onClick={() => {
                      saveQuestion(true);
                    }}
                    disabled={loadingUpdateContent || loadingUpdateContentRefresh}
                  >
                    Salvar e fechar
                    {loadingUpdateContent && (
                      <CircularProgress
                        size={18}
                        style={{ margin: "0px 0px -3px 10px", color: "blue" }}
                      />
                    )}
                  </Button>
                  {!idQuestion && setQuestionsList && (
                    <Button
                      variant="warning"
                      className={`${sabeBlock ? "btnInativo" : ""} ${
                        loadingUpdateContentRefresh ? "btnUpdate" : ""
                      }`}
                      onClick={() => {
                        saveQuestion(false);
                      }}
                      disabled={loadingUpdateContent || loadingUpdateContentRefresh}
                    >
                      Salvar e adicionar nova questão
                      {loadingUpdateContentRefresh && (
                        <CircularProgress
                          size={18}
                          style={{ margin: "0px 0px -3px 10px", color: "white" }}
                        />
                      )}
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ModalCreateQuestion;
