import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { statusTypes } from "./StatusTypes";

import ContentImageUpload from "components/ContentImageUpload";

import { Tooltip, withStyles } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import Switch from "react-bootstrap/esm/Switch";
import { BiSolidInfoCircle } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";
import Loading from "../../../components/Loading";
import {
  createLearningNode,
  editLearningNode,
  getCertificates,
  getLearningNode,
  getMenuData,
  getNodeLevels,
  setNodeStatusToArchived,
  setNodeStatusToPublished,
} from "../../../services/NexiDigitalAPI";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import ModalV2 from "../ModalV2";
import TagsInput from "../TagsInput/TagsInput";
import "./index.scss";
import { LMCreateContext } from "../LearningManagementNew/Contexts/LMContext";
import PlaceholderParameters from "../LearningManagementNew/Components/DynamicParameters/components/PlaceholderParameters";

const ContentPageV2 = (props) => {
  const { isModal = false, closeModal, returnNodeData } = props;
  const { parentNodeCode, nodeCode, editNodeCode } = useParams();
  const history = useHistory();
  const MOCK_BREADCRUMB_DATA = [
    {
      title: "Informações básicas",
    },
    {
      title: "Gerenciar conteúdo",
    },
  ];
  const defaultContentData = {
    title: "",
    shortTitle: "",
    label: "",
    description: "",
    image: null,
    originalNodeCode: null,
    company: {
      id: getMenuData().idCompany,
    },
    nodeLevel: 0,
    status: "DRAFT",
    parentNodeCode: String(parentNodeCode || nodeCode),
    essential: true,
    entryPoint: true,
    modality: "",
    selfEnrollment: true,
    author: "",
    authorEmail: "",
    tags: [],
    idCertificate: null,
    meetings: [],
    attendanceListAsApprovalCriteria: 0,
  };
  const contentTags = [];
  const [contentData, setContentData] = useState(defaultContentData);
  const [initialContentData, setInitialContentData] = useState(defaultContentData);
  const [certificates, setCertificates] = useState([]);
  const [nodeLevels, setNodeLevels] = useState([]);
  const [workloadHour, setWorkloadHour] = useState(0);
  const [workloadMinute, setWorkloadMinute] = useState(0);
  const [archivedDisabled, setArchivedDisabled] = useState(false);
  const [publishedDisabled, setPublishedDisabled] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showMessageRequiredImage, setShowMessageRequiredImage] = useState(false);
  const [modalProps, setModalProps] = useState({
    title: "",
    subTitle: "",
    acceptButtonAction: () => null,
  });

  // variáveis de auxílio
  let tagsDeConteudo = [];
  for (let i = 0; i < contentTags.length; i++) {
    tagsDeConteudo.push({ id: i, name: contentTags[i].tag });
  }

  const [loadingCreateNode, setLoadingCreateNode] = useState(false);
  const [errorCreateNode, setErrorCreateNode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingNode, setLoadingNode] = useState(true);
  const CustomTooltip = withStyles(() => ({
    tooltip: {
      fontSize: "12px",
    },
  }))(Tooltip);

  // Imagem
  const contentImageUploadRef = useRef(null);

  const _getLearningNode = () => {
    const currentNodeCode = editNodeCode || nodeCode || parentNodeCode;
    setLoadingNode(true);
    getLearningNode(currentNodeCode)
      .then((res) => {
        const nodeDataResult = res.data.result;
        const contentDataParams = {
          ...nodeDataResult,
          entryPoint: Boolean(nodeDataResult.entryPoint),
          essential: Boolean(nodeDataResult.essential),
          selfEnrollment: Boolean(nodeDataResult.selfEnrollment),
          parentNodeCode,
          id: nodeDataResult.idNode,
          company: { id: nodeDataResult.idCompany },
          status: nodeDataResult.status.toUpperCase(),
          attendanceListAsApprovalCriteria:
            nodeDataResult.attendanceListAsApprovalCriteria === null ? false : true,
          meetings: nodeDataResult.hasOwnProperty("meetings") ? nodeDataResult.meetings : [],
          label:
            nodeDataResult.label || nodeDataResult.nodeLevelName,
        };
        // Save initial content do compare for changes on save
        setInitialContentData(contentDataParams);
        setContentData(contentDataParams);
        transformWorkload(Number(nodeDataResult.workload));
      })
      .catch((err) => {
        console.log("Error getting learning node", err.request);
        alert("Erro ao requisitar informações do nó");
      })
      .finally(() => {
        setLoadingNode(false);
      });
  };

  const transformWorkload = (workload) => {
    const hours = Math.floor(workload / 3600);
    workload %= 3600;

    let minutes = Math.floor(workload / 60);
    minutes = Math.min(minutes, 59);

    setWorkloadHour(hours);
    setWorkloadMinute(minutes);
  };

  const _getCertificates = async () => {
    const currentNoneCode = contentData.originalNodeCode || contentData.parentNodeCode;

    if (currentNoneCode) {
      const response = await getCertificates(currentNoneCode.split(".")[0]);
      setCertificates(response.data.result || []);
    }
  };

  const _getNodeLevels = () => {
    // Se estiver editando, pegar o nodeCode do parente. Se estiver criando, pegar o nodeCode (modal) ou parentNodeCode
    const currentNodeCode = editNodeCode ? parentNodeCode : nodeCode || parentNodeCode;
    getNodeLevels(currentNodeCode)
      .then((res) => {
        const nodeLevels = res.data.nodeLevels;
        if (!editNodeCode) {
          setContentData({
            ...contentData,
            nodeLevel: nodeLevels?.[0]?.nodeLevel,
            label: nodeLevels?.[0]?.name,
          });
        }

        setNodeLevels(nodeLevels);
      })
      .catch((err) => {
        console.log("!!!!!!!!!! Error getting node levels", err);
      });
  };

  const _createLearningNode = async () => {
    setLoadingCreateNode(true);
    setErrorCreateNode(false);
    const { current } = contentImageUploadRef;
    if (!current.imagesToUpload?.current.length) {
      setShowMessageRequiredImage(true);
      setLoadingCreateNode(false);

      return;
    }
    //ADICIONANDO REGRA PARA QUANDO O USUÁRIO CLICAR PARA SALVAR NO SALVAR DO FORMULÁRIO.
    const uploadImageLocation = await contentImageUploadRef.current.uploadImagem();
    let newImageLocation = null;
    if (uploadImageLocation != null) {
      newImageLocation = uploadImageLocation;
    }

    const meetingsWithoutId = contentData.meetings.map((item) => ({
      meetingType: item.meetingType,
    }));

    createLearningNode({
      ...contentData,
      selfEnrollment: contentData.modality === "BLENDED" ? 0 : contentData.selfEnrollment,
      meetings: meetingsWithoutId,
      image: newImageLocation,
    })
      .then((res) => {
        const { result } = res.data;
        if (isModal) {
          returnNodeData(result);
        } else {
          // history.push(`/node-manager/${result.nodeCode}`);
          history.goBack();
        }
      })
      .catch((err) => {
        const errorObject = JSON.parse(err?.request?.responseText);
        const errorMessage = errorObject?.message;
        setErrorMessage(errorMessage);
        setErrorCreateNode(true);
      })
      .finally(() => {
        setLoadingCreateNode(false);
      });
  };

  const _editLearningNode = async () => {
    if (contentData.status === "ARCHIVED") {
      history.goBack();
      return true;
    }

    setLoadingCreateNode(true);
    setErrorCreateNode(false);
    const { idNode } = contentData;

    const meetingsWithoutId = contentData.meetings.map((item) => ({
      ...item,
      id: undefined,
    }));

    //ADICIONANDO REGRA PARA QUANDO O USUÁRIO CLICAR PARA SALVAR NO SALVAR DO FORMULÁRIO.
    const uploadImageLocation = await contentImageUploadRef.current.uploadImagem();
    let newImageLocation = null;

    if (uploadImageLocation != null) {
      newImageLocation = uploadImageLocation;
    }

    // Redirct if no changes are made
    if (initialContentData === contentData && !newImageLocation) {
      history.goBack();
      return;
    }

    let payload = newImageLocation
      ? {
          ...contentData,
          image: newImageLocation,
          meetings: meetingsWithoutId,
        }
      : {
          ...contentData,
          meetings: meetingsWithoutId,
        };

    if (!contentData.entryPoint || !contentData.selfEnrollment) {
      payload.idCertificate = null;
      payload.enableCertificateForChildren = false;
    }

    editLearningNode(idNode, payload)
      .then((res) => {
        history.goBack();
      })
      .catch((err) => {
        const errorObject = JSON.parse(err?.request?.responseText);
        const errorMessage = errorObject?.message;
        setErrorMessage(errorMessage);
        setErrorCreateNode(true);
      })
      .finally(() => {
        setLoadingCreateNode(false);
      });
  };

  const sendData = () => {
    if (editNodeCode) {
      _editLearningNode();
    } else {
      _createLearningNode();
    }
  };

  const pulbishChildren = (value) => {
    setContentData({ ...contentData, status: "PUBLISHED" });
    setNodeStatusToPublished(contentData?.idNode, Boolean(value)).catch((err) =>
      console.log("error", err.request),
    );
    setShowStatusModal(false);
  };

  const archiveContent = () => {
    setNodeStatusToArchived(contentData?.idNode)
      .then(_getLearningNode)
      .catch((err) => console.log("error", err.request));

    setShowStatusModal(false);
  };

  const handleStatusChange = (value) => {
    switch (value) {
      case "PUBLISHED":
        setShowStatusModal(true);
        setModalProps({
          title: "Você gostaria de publicar está página e as páginas abaixo?",
          subTitle:
            "Ao publicar, todas as páginas serão visíveis para todos os usuários. Após publicadas, não será possível realizar adições ou exclusão de conteúdos nas páginas.",
          acceptButtonText: "Publicar a página e níveis abaixo",
          acceptButtonAction: () => pulbishChildren(true),
        });
        break;
      case "ARCHIVED":
        setShowStatusModal(true);
        setModalProps({
          title: 'Você tem certeza que deseja arquivar esta página?',
          subTitle:
            'Se prosseguir, a página e todos os níveis abaixo deixarão de ser visíveis e você não poderá mais realizar nenhuma edição dentro dela. Todas as matrículas vigentes serão encerradas e ninguém mais terá acesso à página. Esta ação é irreversível.',
          acceptButtonText: "Sim, Arquivar",
          acceptButtonAction: () => archiveContent(),
        });
        break;
      default:
        return;
    }
  };

  const handleChangeToggle = (entryPoint) => {
    setContentData({
      ...contentData,
      selfEnrollment: entryPoint ? contentData.selfEnrollment : false,
      entryPoint,
    });
  };

  const handleChangeCertificate = (value) => {
    setContentData({
      ...contentData,
      idCertificate: value !== "0" ? value : null,
    });
  };

  useEffect(() => {
    _getNodeLevels();
  }, []);

  useEffect(() => {
    _getCertificates();
  }, [contentData.originalNodeCode]);

  useEffect(() => {
    if (contentData.modality === "") {
      setContentData({
        ...contentData,
        modality: "ASYNCHRONOUS",
      });
    }
  }, [contentData.modality]);

  const handleWorkloadHour = (hour) => {
    if (isNaN(hour) || hour < 0) return;
    setWorkloadHour(hour);
    const hourDuration = moment.duration(hour, "hours");
    const hourAsSeconds = hourDuration.asSeconds();

    const minuteDuration = moment.duration(workloadMinute, "minutes");
    const minuteAsSeconds = minuteDuration.asSeconds();

    const workloadValue = hourAsSeconds + minuteAsSeconds;

    setContentData({ ...contentData, workload: workloadValue });
  };

  const handleWorkloadMinute = (minute) => {
    // limit minute value to 59
    if (isNaN(minute) || minute >= 60 || minute < 0) return;

    setWorkloadMinute(minute);
    const hourDuration = moment.duration(workloadHour, "hours");
    const hourAsSeconds = hourDuration.asSeconds();

    const minuteDuration = moment.duration(minute, "minutes");
    const minuteAsSeconds = minuteDuration.asSeconds();

    const workloadValue = hourAsSeconds + minuteAsSeconds;

    setContentData({ ...contentData, workload: workloadValue });
  };

  const statusDisabler = () => {
    switch (contentData.status) {
      case statusTypes.DRAFT:
        setArchivedDisabled(true);
        setPublishedDisabled(false);
        break;
      case statusTypes.PUBLISHED:
        setArchivedDisabled(false);
        setPublishedDisabled(false);
        break;
      case statusTypes.ARCHIVED:
        setPublishedDisabled(true);
        setArchivedDisabled(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (contentData.status) {
      statusDisabler();
    }
  }, [contentData.status]);

  useEffect(() => {
    if (editNodeCode) {
      _getLearningNode();
    }
  }, [editNodeCode]);

  return editNodeCode && loadingNode ? (
    <PlaceholderParameters number={10}/>
  ) : (
    <div>
      <ModalV2
        showModal={showStatusModal}
        setShowModal={(status) => setShowStatusModal(status)}
        acceptButtonText={modalProps.acceptButtonText}
        acceptButtonAction={modalProps.acceptButtonAction}
        secondaryButtonText={modalProps.secondaryButtonText}
        secondaryButtonAction={modalProps.secondaryButtonAction}
        title={modalProps.title}
        subTitle={modalProps.subTitle}
      />
      {!isModal && (
        <div className="header-title-wrapper">
          <div className="header-title">
            <button
              className="btnGoBack"
              onClick={() => {
                props.history.goBack();
              }}
            >
              <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
            </button>
            Página de conteúdo
          </div>
        </div>
      )}
      <BreadCrumb index={0} paddingHorizontal="10%" data={MOCK_BREADCRUMB_DATA} />
      <div className="post-form">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            editNodeCode ? _editLearningNode() : _createLearningNode();
          }}
        >
          <div className="content-page-form-group">
            <div className="content-page-card">
              <div
                style={{ backgroundColor: "#6b48ff" }}
                className="card-header template-card-header"
              >
                Informações básicas
              </div>
              <div className="card-body template-card-body">
                <div style={{ display: "flex" }}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text"
                        id="basic-addon1"
                        style={{ minWidth: 100 }}
                      >
                        Título *
                      </span>
                    </div>
                    <input
                      required
                      disabled={contentData?.status === statusTypes.ARCHIVED}
                      type="text"
                      className="form-control"
                      id="node-title"
                      aria-describedby="node-title"
                      value={contentData.title || ""}
                      onChange={(e) => setContentData({ ...contentData, title: e.target.value })}
                      maxLength={100}
                    />
                    <div className="character-counter">
                      <small>
                        {contentData.title ? contentData.title?.length : 0}/ 100
                      </small>
                    </div>
                  </div>
                  <div className="input-group mb-3 ml-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        Título curto *
                      </span>
                    </div>
                    <input
                      required
                      disabled={contentData?.status === statusTypes.ARCHIVED}
                      type="text"
                      className="form-control"
                      id="shortTitle"
                      aria-describedby="shortTitle"
                      maxLength={20}
                      value={contentData.shortTitle || ""}
                      onChange={(e) =>
                        setContentData({ ...contentData, shortTitle: e.target.value })
                      }
                    />
                    <div className="character-counter">
                      <small>
                        {contentData.shortTitle ? contentData.shortTitle?.length : 0}/ 20
                      </small>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1" style={{ minWidth: 100 }}>
                      Descrição
                    </span>
                  </div>
                  <textarea
                    rows="4"
                    disabled={contentData?.status === statusTypes.ARCHIVED}
                    type="text"
                    className="form-control"
                    id="description"
                    aria-describedby="description"
                    value={contentData?.description || ""}
                    onChange={(e) =>
                      setContentData({ ...contentData, description: e.target.value })
                    }
                    maxLength={1000}
                  />
                  <div className="character-counter">
                    <small>
                      {contentData.description ? contentData.description?.length : 0}/ 1000
                    </small>
                  </div>
                </div>
                <InputGroup className="mb-4">
                  <InputGroup.Prepend>
                    <InputGroup.Text style={{ minWidth: 200 }}>Nível de conteúdo *</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    disabled={!editNodeCode}
                    as="select"
                    value={contentData?.nodeLevel}
                    onChange={(e) => {
                      const index = e.nativeEvent.target.selectedIndex;
                      const optionText = e.nativeEvent.target[index].text;
                      setContentData({
                        ...contentData,
                        nodeLevel: Number(e.target.value),
                        label: optionText,
                      });
                    }}
                  >
                    {nodeLevels.length > 0 ? (
                      nodeLevels?.map((nodeLevel, index) => (
                        <option key={`node-level-${index}`} value={nodeLevel.nodeLevel}>
                          {nodeLevel.name}
                        </option>
                      ))
                    ) : (
                      <option value={0}>Carregando...</option>
                    )}
                  </Form.Control>
                </InputGroup>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1" style={{ minWidth: 200 }}>
                      Rótulo de conteúdo *
                    </span>
                  </div>
                  <input
                    required
                    disabled={contentData?.status === statusTypes.ARCHIVED}
                    maxLength={15}
                    placeholder="Trilha"
                    type="text"
                    className="form-control"
                    id="node-label"
                    aria-describedby="node-label"
                    value={contentData.label || ""}
                    onChange={(e) => setContentData({ ...contentData, label: e.target.value })}
                  />
                </div>
                {contentData?.entryPoint && (
                  <>
                    <InputGroup className="mb-4">
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{ minWidth: 200 }}>
                          Modalidade da página
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        disabled={[statusTypes.PUBLISHED, statusTypes.ARCHIVED].includes(
                          contentData?.status,
                        )}
                        className="col-md-4"
                        as="select"
                        value={contentData.modality}
                        onChange={(e) => {
                          setContentData({
                            ...contentData,
                            modality: e.target.value,
                          });
                        }}
                      >
                        <option value={"ASYNCHRONOUS"}>Assíncrono</option>
                        <option value={"BLENDED"}>Blended</option>
                      </Form.Control>
                    </InputGroup>
                    <InputGroup className="mb-4" style={{ width: "100%" }}>
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{ minWidth: 200 }}>Carga horária</InputGroup.Text>
                      </InputGroup.Prepend>
                      <InputGroup.Prepend>
                        <InputGroup.Text>horas</InputGroup.Text>
                      </InputGroup.Prepend>
                      <input
                        value={workloadHour}
                        disabled={contentData?.status === statusTypes.ARCHIVED}
                        className="col-md-1 form-control"
                        maxLength={15}
                        placeholder="Horas"
                        type="number"
                        id="node-horas"
                        aria-describedby="node-horas"
                        onChange={(e) => handleWorkloadHour(Number(e.target.value))}
                      />

                      <InputGroup.Prepend>
                        <InputGroup.Text>minutos</InputGroup.Text>
                      </InputGroup.Prepend>
                      <input
                        value={workloadMinute}
                        disabled={contentData?.status === statusTypes.ARCHIVED}
                        className="col-md-1 form-control"
                        maxLength={15}
                        placeholder="Minutos"
                        type="number"
                        id="node-minutos"
                        aria-describedby="node-minutos"
                        onChange={(e) => handleWorkloadMinute(Number(e.target.value))}
                      />
                    </InputGroup>
                  </>
                )}

                <div
                  className="form-row"
                  style={{ alignItems: "center", margin: "25px 0px 25px 5px" }}
                >
                  <div className="mr-4">
                    <div
                      className="access-table-select"
                      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                    >
                      <Switch
                        color="primary"
                        id="entryPoint"
                        checked={contentData.entryPoint}
                        onChange={(e) => handleChangeToggle(e.target.checked, e.target.id)}
                        disabled={editNodeCode}
                      />
                      <label
                        className="form-label"
                        htmlFor="entryPoint"
                        style={{ marginBottom: 0 }}
                      >
                        Permitir matrículas nessa página
                      </label>
                    </div>
                  </div>

                  {contentData.modality !== "BLENDED" && (
                    <div className="mr-4">
                      <div
                        className="access-table-select"
                        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                      >
                        <Switch
                          color="primary"
                          id="selfEnrollment"
                          checked={contentData.selfEnrollment}
                          disabled={!contentData.entryPoint}
                          onChange={(e) =>
                            setContentData({ ...contentData, selfEnrollment: e.target.checked })
                          }
                        />
                        <label
                          className="form-label"
                          htmlFor="selfEnrollment"
                          style={{ marginBottom: 0 }}
                        >
                          Permitir automatrícula sem turma
                        </label>
                      </div>
                    </div>
                  )}
                </div>

                {contentData.selfEnrollment && (
                  <div style={{ width: "100%" }}>
                    <InputGroup className="mb-4">
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{ minWidth: 200 }}>Certificado</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        className="col-md-4"
                        disabled={contentData?.status === statusTypes.ARCHIVED}
                        as="select"
                        value={contentData.idCertificate}
                        onChange={(e) => {
                          handleChangeCertificate(e.target.value);
                        }}
                      >
                        <option value={"0"} selected>
                          {"Sem certificado"}
                        </option>

                        {certificates?.map((certificate) => (
                          <>
                            <option value={certificate.idCertificate}>{certificate.title}</option>
                          </>
                        ))}
                      </Form.Control>
                    </InputGroup>
                    <div className="ml-1">
                      <div
                        className="access-table-select"
                        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                      >
                        <Switch
                          color="primary"
                          disabled={contentData?.status === statusTypes.ARCHIVED}
                          id="enableCertificateForChildren"
                          checked={contentData.enableCertificateForChildren}
                          onChange={(e) =>
                            setContentData({
                              ...contentData,
                              enableCertificateForChildren: e.target.checked,
                            })
                          }
                        />
                        <label
                          className="form-label"
                          htmlFor="enableCertificateForChildren"
                          style={{ marginBottom: 0 }}
                        >
                          Habilitar a geração do certificado para os níveis abaixo dentro da página
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                {/* <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1" style={{ minWidth: 200 }}>
                        Modalidade da página
                      </span>
                    </div>
                    <input
                      required
                      placeholder="Assíncrono"
                      type="text"
                      className="form-control"
                      id="modality"
                      aria-describedby="modality"
                      value={contentData.modality || ""}
                      onChange={(e) => setContentData({ ...contentData, modality: e.target.value })}
                    />
                  </div> */}
              </div>
            </div>
          </div>
          <div className="content-page-form-group">
            <div className="content-page-card">
              <div
                style={{ backgroundColor: "#6b48ff" }}
                className="card-header template-card-header"
              >
                Tags
              </div>
              <div className="card-body template-card-body">
                <div className="row">
                  <div className="col-md-12">
                    <TagsInput
                      disabled={contentData?.status === statusTypes.ARCHIVED}
                      tags={contentData.tags}
                      setTags={(newTags) => setContentData({ ...contentData, tags: newTags })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`content-page-form-group imageEditorBody ${
              contentData?.status === statusTypes.ARCHIVED ? "disabled" : ""
            }`}
          >
            <div className="content-page-card">
              <div
                style={{ backgroundColor: "#6b48ff" }}
                className="card-header template-card-header"
              >
                Imagem *
              </div>
              <ContentImageUpload
                reference={contentImageUploadRef}
                data={contentData}
                sendData={sendData}
                defaultPrefixName="node"
                disabled={contentData?.status === statusTypes.ARCHIVED}
                showMessageRequired={showMessageRequiredImage}
                uploadImageFeedback={(arquivos) =>
                  setShowMessageRequiredImage(!arquivos.files?.length)
                }
              />
            </div>
          </div>

          {contentData.entryPoint && (
            <div className="content-page-form-group">
              <div className="content-page-card">
                <div
                  style={{ backgroundColor: "#6b48ff" }}
                  className="card-header template-card-header"
                >
                  Autor
                </div>
                <div className="card-body template-card-body">
                  <div className="form-row">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="autor">
                          Nome
                        </span>
                      </div>
                      <input
                        disabled={contentData?.status === statusTypes.ARCHIVED}
                        type="text"
                        className="form-control"
                        value={contentData.author || ""}
                        onChange={(e) => setContentData({ ...contentData, author: e.target.value })}
                      />
                    </div>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="autor">
                          E-mail
                        </span>
                      </div>
                      <input
                        disabled={contentData?.status === statusTypes.ARCHIVED}
                        type="email"
                        className="form-control"
                        value={contentData.authorEmail || ""}
                        onChange={(e) =>
                          setContentData({ ...contentData, authorEmail: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {contentData.modality === "BLENDED" && (
            <div className="content-page-form-group">
              <div className="content-page-card">
                <div
                  style={{ backgroundColor: "#6b48ff" }}
                  className="card-header template-card-header"
                >
                  <Tooltip
                    title="Defina aqui um modelo para a quantidade e os tipos de encontros para essa página. Os encontros poderão ser editados na criação da turma."
                    placement="top"
                  >
                    <span>
                      Encontros
                      <InfoIcon style={{ fontSize: 20, marginLeft: 4 }} />
                    </span>
                  </Tooltip>
                </div>
                <div className="card-body template-card-body">
                  {contentData.meetings?.map((meet, index) => (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <InputGroup className="mb-4 col-6 pl-0" key={meet.id}>
                        <InputGroup.Prepend>
                          <InputGroup.Text style={{ minWidth: 140 }}>
                            Encontro {index + 1}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          disabled={contentData?.status === "ARCHIVED"}
                          as="select"
                          value={contentData?.meetings[index].meetingType}
                          onChange={({ target }) => {
                            let oldMeetings = [...contentData.meetings];
                            oldMeetings[index] = {
                              ...oldMeetings[index],
                              meetingType: target.value,
                            };

                            setContentData({
                              ...contentData,
                              meetings: oldMeetings,
                            });
                          }}
                        >
                          <option value="ONLINE">Online</option>
                          <option value="PRESENCIAL">Presencial</option>
                        </Form.Control>
                        {contentData?.status !== "ARCHIVED" && (
                          <div
                            className="node-manager-node-item-actions node-red"
                            style={{ marginLeft: 20 }}
                            onClick={() => {
                              const meetingRemoved = contentData.meetings.filter(
                                (item, indexItem) => index !== indexItem,
                              );
                              setContentData({
                                ...contentData,
                                meetings: meetingRemoved,
                              });
                            }}
                          >
                            <FiTrash2 style={{ fontSize: 22 }} />
                          </div>
                        )}
                      </InputGroup>
                    </div>
                  ))}
                  <button
                    type="button"
                    disabled={contentData?.status === "ARCHIVED"}
                    onClick={() => {
                      setContentData({
                        ...contentData,
                        meetings: [
                          ...contentData.meetings,
                          {
                            id: uuidv4(),
                            meetingType: "ONLINE",
                          },
                        ],
                      });
                    }}
                    className="btn content-page-button"
                    style={{ backgroundColor: "#feac0e" }}
                  >
                    Adicionar novo encontro
                  </button>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <Switch
                      color="primary"
                      id="attendanceListAsApprovalCriteria"
                      checked={!!contentData.attendanceListAsApprovalCriteria}
                      disabled={contentData?.status !== "DRAFT"}
                      onChange={() => {
                        setContentData({
                          ...contentData,
                          attendanceListAsApprovalCriteria:
                            contentData.attendanceListAsApprovalCriteria ? 0 : 1,
                        });
                      }}
                    />
                    <label
                      className="form-label"
                      htmlFor="attendanceListAsApprovalCriteria"
                      style={{ marginBottom: 0 }}
                    >
                      Contabilizar a lista de presença como critério de aprovação
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="content-page-form-group">
            <div className="content-page-card">
              <div
                style={{ backgroundColor: "#6b48ff" }}
                className="card-header template-card-header"
              >
                Configurações adicionais
              </div>
              <div className="card-body template-card-body">
                <div className="form-row" style={{ alignItems: "center" }}>
                  <div className="mr-4" style={{ display: "flex" }}>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Status</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      as="select"
                      value={contentData?.status}
                      disabled={!editNodeCode || contentData?.status === statusTypes.ARCHIVED}
                      onChange={(e) => handleStatusChange(e.target.value)}
                    >
                      <option disabled value={"DRAFT"}>
                        Rascunho
                      </option>
                      <option disabled={publishedDisabled} value={"PUBLISHED"}>
                        Publicado
                      </option>
                      <option disabled={archivedDisabled} value={"ARCHIVED"}>
                        Arquivado
                      </option>
                    </Form.Control>
                  </div>

                  <div className="ml-1 availableToImport">
                    <div
                      className="access-table-select"
                      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                    >
                      <Switch
                        disabled={contentData?.status === statusTypes.ARCHIVED}
                        color="primary"
                        id="availableToImport"
                        checked={contentData.availableToImport}
                        onChange={(e) =>
                          setContentData({
                            ...contentData,
                            availableToImport: e.target.checked,
                          })
                        }
                      />
                      <label
                        className="form-label"
                        htmlFor="availableToImport"
                        style={{ marginBottom: 0 }}
                      >
                        Permitir importação da página.
                      </label>
                    </div>
                    <CustomTooltip
                      title="Permitir que essa página seja importada para ser utilizada em outras estruturas de aprendizagem."
                      placement="top"
                      disableHoverListener={false}
                      arrow
                    >
                      <div className="importInfo">
                        <BiSolidInfoCircle />
                      </div>
                    </CustomTooltip>
                  </div>

                  {/* <div className="mr-3">
                    <div className="access-table-select" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <Switch
                        color="primary"
                        checked={contentData.essential}
                        onChange={(e) => setContentData({ ...contentData, essential: e.target.checked })}
                      />
                      <label
                        className="form-label"
                        style={{ marginBottom: 0 }}
                      >
                        Essencial (Obrigatório)
                      </label>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row pr-3" style={{ padding: 5, justifyContent: "flex-end" }}>
            <button
              type="button"
              className="btn content-page-button"
              style={{ backgroundColor: "white", color: "black", borderColor: "black" }}
              onClick={() => {
                isModal ? closeModal() : props.history.goBack();
              }}
            >
              Voltar
            </button>
            <button
              type="submit"
              className="btn content-page-button ml-3"
              style={{ backgroundColor: "#feac0e" }}
            >
              Avançar{" "}
              {loadingCreateNode && (
                <CircularProgress
                  className="content-page-button-icon"
                  style={{ marginLeft: 10 }}
                  size={18}
                />
              )}
            </button>
          </div>
          {errorCreateNode && (
            <span style={{ display: "flex", justifyContent: "center", color: "indianred" }}>
              Erro: {errorMessage || "Falha ao se comunicar com o servidor"}
            </span>
          )}
        </form>
        <br />
      </div>
    </div>
  );
};

export default withRouter(ContentPageV2);
