
import React from "react";

import TeamItens from "./TeamItens";
import "./styles.scss";

const Content = () => {

  return (
    <div className="teamEnrollmentViewBase">
    <div className="report_container">
      <div className="textLeft">Progresso dos usuários em conteúdos de aprendizagem</div>
      <div className="selectPediodDiv">
        <TeamItens />
      </div>
    </div>
  </div>
  )

}

export default Content;


