import React, { useState, useEffect } from "react";

// Page UI and icons
import { Col, Form } from "react-bootstrap";
import "rc-datepicker/lib/style.css";
import { getMenuData } from "../../../../services/NexiDigitalAPI";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";

import SearchIcon from "@material-ui/icons/Search";

const Filtro = ({ actionFilter, pag, loading, callEditCertificates }) => {
  const [search, setSearch] = useState("");

  function handleFilter() {
    actionFilter({ ...pag, searchString: search });
  }

  return (
    <div className={`filter-container`}>
      <div className="containerFilter">
        <div className="containerFilterBuscar">
          <input
            type="text"
            className="form-control input-default"
            id="groupDetail"
            style={{ marginRight: 8 }}
            aria-describedby="usuario"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Procurar por nome"
          />
          <ButtonWithLoading
            onClickHandler={handleFilter}
            loading={loading}
            text={
              <>
                <SearchIcon style={{ height: 18 }} /> Pesquisar{" "}
              </>
            }
            style={{ width: "100%" }}
          />
        </div>
        <div>
          <ButtonWithLoading
            onClickHandler={callEditCertificates}
            loading={false}
            text={<>Criar novo Certificado</>}
            style={{
              width: "100%",
              marginTop: "0",
              backgroundColor: "#0d6efd",
              borderColor: "#0d6efd",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Filtro;
