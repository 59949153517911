const CharacterCounterLimit = (props) => {
  const { limit, value, color } = props;

  return (
    <span className="float-right" style={{ color }}>
      {value.length + '/' + limit}
    </span>
  );
};

export default CharacterCounterLimit;
