import React from "react";
import "./style.scss";
type Props = {
  characterNumber: number;
  maxLength: number;
};
const CharacterCounter = ({ characterNumber, maxLength = 0 }: Props) => (
  <div className="character-counter">
    <small>
      {characterNumber ? characterNumber : 0}
      {maxLength ? `/ ${maxLength}` : ""}
    </small>
  </div>
);

export default CharacterCounter;
