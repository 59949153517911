import React, { useState, createContext, useReducer, useContext } from "react";
import PreloaderBlur from "../../PreloaderBlur";

const initialState = {
  evaluationSubmit: null,
};

const actions = {
  SUBMIT_EVALUATION: "SUBMIT_EVALUATION",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SUBMIT_EVALUATION: {
      return {
        evaluationSubmit: () => {},
      };
    }

    default:
      return state;
  }
};

export const REContainerContext = (props) => {
  const { preloadInit } = useContext(RECreateContext);

  return (
    <>
      {preloadInit && <PreloaderBlur />}
      {props.children}
    </>
  );
};

export const RECreateContext = createContext();

const REContext = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [preloadInit, setPreloadInit] = useState(true);

  const [evaluationModel, setEvaluationModel] = useState(null);

  const [currentEvaluation, setCurrentEvaluation] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentCatalog, setCurrentCatalog] = useState(null);

  const [imageEvaluation, setImageEvaluation] = useState(null);
  const [titleEvaluation, setTitleEvaluation] = useState(null);
  const [descriptionEvaluation, setDescriptionEvaluation] = useState(null);

  const [contextTagsInit, setContextTagsInit] = useState(null);
  const [contextTagsEnd, setContextTagsEnd] = useState([]);
  const [questionsList, setQuestionsList] = useState([]);

  const value = {
    evaluationSubmit: state.evaluationSubmit,
    submitEvaluation: () => {
      dispatch({ type: actions.SUBMIT_EVALUATION });
    },

    evaluationModel,
    setEvaluationModel,

    questionsList,
    setQuestionsList,

    preloadInit,
    setPreloadInit,

    currentEvaluation,
    setCurrentEvaluation,

    imageEvaluation,
    setImageEvaluation,

    titleEvaluation,
    setTitleEvaluation,

    descriptionEvaluation,
    setDescriptionEvaluation,

    currentQuestion,
    setCurrentQuestion,

    currentCatalog,
    setCurrentCatalog,

    contextTagsInit,
    setContextTagsInit,

    contextTagsEnd,
    setContextTagsEnd,
  };

  return <RECreateContext.Provider value={value}>{children}</RECreateContext.Provider>;
};

export default REContext;
