import React, { useContext, useState } from "react";
import ContentOptionsPopover from "../ContentOptionsPopover";
import "../index.scss";

import { Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { contentTypes, elementTypes, evaluationTypes } from "../NodeTypes";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { ElementCard } from "../ContentTypes/ElementCard";
import ImportContentModal from "../../ImportContentModal";
import {
  linkElementToBlock,
  removeBlockElement,
  getMenuData,
  getNodesBreadcrumb,
  getLearningNode,
} from "../../../../services/NexiDigitalAPI";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory, useParams } from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CatalogModal from "../CatalogModal";
import { statusTypes } from "containers/PeopleCraft/ContentPage/StatusTypes";
import { LMCreateContext } from "../../LearningManagementNew/Contexts/LMContext";

import ModalDialog from "../../../PeopleCraft/Modals/ModalDialog";
import { ContentCard } from "../ContentTypes/ContentCard";
import { useNodesTree } from "containers/PeopleCraft/LearningManagementNew/Components/NodesTree/hooks/useNodesTree";

import {} from ".";

const CardList = React.memo(function CardList({
  cards,
  removeNode,
  loadingDeleteBlockElement,
  permissionLearningPage,
  blockData,
  nodeData,
}) {
  const history = useHistory();
  const params = useParams();
  const { nodeCurrent, nodeSelectItem } = useNodesTree();
  const { idCompany } = getMenuData();
  const blockEditRedirect = !permissionLearningPage || (!!nodeData.redirect && (!nodeData.idCompany || nodeData.idCompany !== idCompany));
  const getParentNodeCode = (nodeCode) => {
    let newNodeCode = nodeCode;
    let index = newNodeCode.lastIndexOf(".");
    if (index !== -1) {
      newNodeCode = newNodeCode.substring(0, index);
    }
    return newNodeCode;
  };

  const [currentElement, setCurrentElement] = useState(null);
  const [showEditRedirect, setShowEditRedirect] = useState(false);

  const originFilter = (it) => {
    let orginTxt = "";

    it.parentNodes.map((el, ind) => {
      if (ind !== 0) {
        orginTxt += " • " + el.title;
      } else {
        orginTxt += el.title;
      }
    });

    return orginTxt;
  };

  const callEditElement = (elementItem) => {
    const { elementType, data } = elementItem;

    if (elementType === "CONTENT") {
      history.push(
        `/cms/content/${elementItem.idElement}?content=${elementItem.data?.idContentType}`,
      );
    } else if (elementType === "EVALUATION") {
      const evaluationBase = evaluationTypes[data.evaluationType]; // REACTION, knowledge

      history.push(`/${evaluationBase.url}/${blockData?.idBlock}/${elementItem.idElement}/1`);
    } else if (elementType === "IMAGE") {
      history.push(
        `/image-banner/${elementItem.idBlockElement}/${elementItem.idElement}/${nodeCurrent.nodeCode}/1`,
      );
    } else {
      const parentNodeCode = getParentNodeCode(data?.originalNodeCode);
      history.push(`/content-page/${parentNodeCode}/${data?.originalNodeCode}`);
    }
  };

  const handleEditElementRedirect = (elementItem) => {
    const { idCompany } = getMenuData();
    const { data } = elementItem;
    const pageShareRedirect = data.redirect === 1 && data.idCompany === idCompany;
    const pageShareOrigin = data.usedAsRedirect === 1 && data.redirect === 0;

    if (pageShareRedirect || pageShareOrigin) {
      const titleEditRedirect = "Atenção, esta é uma página compartilhada!";
      const textEditRedirect = pageShareOrigin
        ? "<p>Esta página foi compartilhada com outra estrutura de aprendizagem, as alterações realizadas aqui serão refletidas em todos os locais em que está página estiver. Deseja continuar com a edição?<p>"
        : "<p>Esta página foi compartilhada de outra estrutura de aprendizagem, as alterações realizadas aqui serão refletidas em todos os locais em que esta página estiver. Deseja continuar com a edição?<p>";

      setCurrentElement({
        elementItem: elementItem,
        title: titleEditRedirect,
        text: textEditRedirect,
      });

      //BreadCrump apenas para páginas que são Redirect
      if (pageShareRedirect) {
        getNodesBreadcrumb(data?.originalNodeCode).then((res) => {
          const originEditRedirect = `${textEditRedirect} <p><b>Origem:</b> ${originFilter(
            res.data,
          )}</p>`;
          setCurrentElement({
            elementItem: elementItem,
            title: titleEditRedirect,
            text: originEditRedirect,
          });
        });
      }

      setShowEditRedirect(true);
    } else {
      // callEditElement(elementItem);
      nodeSelectItem(elementItem.data);
    }
  };

  const noEditPermition = (elementItem, nodeData) => {
    const { idCompany } = getMenuData();
    const redirectFromOutsideCompany = nodeData.usedAsRedirect === 1 && nodeData.idCompany !== idCompany;
    const contentEditable = elementItem.data.contentEditable === true || elementItem.data.contentEditable === null ? true : false;

    if(contentEditable === false){
      return(true);
    }else{
      return (
        ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
          nodeData.nodeType !== "ORGANIZATION" &&
          elementItem.elementType !== "IMAGE") ||
        !permissionLearningPage ||
        redirectFromOutsideCompany
      );
    }

  };

  return cards?.map((elementItem, index) => (
    <Draggable
      draggableId={String(elementItem.idBlockElement)}
      index={index}
      key={String(elementItem.idBlockElement)}
      isDragDisabled={!permissionLearningPage}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="node-manager-content-item-wrapper-v2"
        >
          <ModalDialog
            title={currentElement ? currentElement.title : ""}
            text={currentElement ? currentElement.text : ""}
            show={showEditRedirect}
            setShow={setShowEditRedirect}
            btnCall={{
              className: "",
              variantBtn: "warning",
              text: "Sim, alterar em todos os locais.",
              callEvent: () => {
                nodeSelectItem(currentElement.elementItem.data);
              },
            }}
          />

          <div className="node-manager-node-item-draggable-container" {...provided.dragHandleProps}>
            <div className="node-manager-node-item-draggable-dots">
              <DragIndicatorIcon style={{ fontSize: 35, color: "lightgray", marginBottom: -8 }} />
              <DragIndicatorIcon style={{ fontSize: 35, color: "lightgray" }} />
            </div>
          </div>
          <div className="node-manager-node-item-content-v2">
            {elementItem.elementType === elementTypes.CONTENT ||
            elementItem.elementType === elementTypes.BANNER_IMAGE ||
            elementItem.elementType === "EVALUATION" ? (
              <ContentCard
                element={elementItem}
                permissionLearningPage={permissionLearningPage}
                idBlock={blockData.idBlock}
                noEditPermition={noEditPermition}
                handleEditElementRedirect={callEditElement}
                removeNode={removeNode}
                loadingDeleteBlockElement={loadingDeleteBlockElement}
              />
            ) : (
              <ElementCard
                element={elementItem}
                permissionLearningPage={permissionLearningPage}
                idBlock={blockData.idBlock}
                noEditPermition={noEditPermition}
                noEditRediretct={blockEditRedirect}
                handleEditElementRedirect={handleEditElementRedirect}
                removeNode={removeNode}
                loadingDeleteBlockElement={loadingDeleteBlockElement}
              />
            )}
          </div>
        </div>
      )}
    </Draggable>
  ));
});

export const ElementList = ({
  hasMaxCards,
  blockData,
  baseNodeLevel,
  isCatalog,
  permissionLearningPage,
  index,
}) => {
  const { nodeSelectItem } = useNodesTree();
  const { nodeData, setNodeData, addNode } = useContext(LMCreateContext);
  const { idCompany } = getMenuData();
  const blockEditRedirect = !permissionLearningPage ||  (!!nodeData.redirect && (!nodeData.idCompany || nodeData.idCompany !== idCompany));

  const elementList = nodeData.blocks[index].elements;
  const [showImportContentModal, setShowImportContentModal] = useState(false);
  const [showCatalogModal, setShowCatalogModal] = useState(false);
  const [selectedContentType, setSelectedContentType] = useState("");
  const [loadingLinkData, setLoadingLinkData] = useState(false);
  const [loadingDeleteBlockElement, setLoadingDeleteBlockElement] = useState(false);

  const getElementTypeByContentType = () => {
    switch (selectedContentType) {
      case contentTypes.ARTICLE:
        return "CONTENT";
      case contentTypes.AUDIO:
        return "CONTENT";
      case contentTypes.VIDEO:
        return "CONTENT";
      case contentTypes.HTML_PACKAGE:
        return "CONTENT";
      case contentTypes.NEW_PAGE:
        return "NODE";
      case contentTypes.NEW_PAGE_NAVIGATION:
        return "NODE";
      case contentTypes.NEW_BANNER_IMAGE:
        return "IMAGE";
      default:
        return "CONTENT";
    }
  };

  const linkContentToBlock = (contentData, contentType, status) => {
    if (showImportContentModal) setShowImportContentModal(false);
    const newContentType = contentType || getElementTypeByContentType();
    setLoadingLinkData(blockData.idBlock);
    const linkBlockData = {
      idBlock: blockData.idBlock,
      idElement:
        newContentType === "CONTENT"
          ? contentData.idContentItem
          : newContentType === "EVALUATION"
          ? contentData.idEvaluation
          : newContentType === "NODE"
          ? contentData.idNode
          : newContentType === "IMAGE"
          ? contentData.idImage
          : null,
      elementType: newContentType,
    };
    linkElementToBlock(blockData.idBlock, linkBlockData)
      .then(() => {
        contentData.hasChildNodes = 0;
        contentData.childNodes = [];
        contentData.entryPoint = 1;
        contentData.complete = true;

        const parentNodeCode = nodeData.nodeCode;

        if (newContentType === "NODE") {
          addNode(parentNodeCode, contentData);

          if (status === "IMPORT") {
            // IMPORTA A PÁGINA E CONTINUA NA MESMA PÁGINA.
            getLearningNode(parentNodeCode).then((res) => {
              const nodeDataResult = res.data.result;
              setNodeData(nodeDataResult);
            });
          } else {
            //VAI PARA A PÁGINA DO NÓ
            nodeSelectItem(contentData);
          }
        } else {
          getLearningNode(parentNodeCode).then((res) => {
            const nodeDataResult = res.data.result;
            setNodeData(nodeDataResult);
          });
        }
      })
      .catch((err) => {
        console.log("Error linking", err?.request || err);
      })
      .finally(() => {
        setLoadingLinkData(false);
      });
  };

  const removeNode = (node) => {
    const payload =
      node.elementType === "NODE"
        ? { nodeCode: node.data.nodeCode ? node.data.nodeCode : node.data.originalNodeCode }
        : {};

    setLoadingDeleteBlockElement(node.idBlockElement);

    removeBlockElement(node.idBlockElement, payload)
      .then((res) => {
        const filteredElementList = elementList?.filter(
          (obj) => obj.idBlockElement !== node.idBlockElement,
        );

        let newNodeData = { ...nodeData };

        newNodeData.blocks[index].elements = filteredElementList;

        setNodeData(newNodeData);
      })

      .catch((err) => {
        console.log("error delete", err);
      })

      .finally(() => {
        setLoadingDeleteBlockElement(false);
      });
  };

  return (
    <div style={{ flex: 1, padding: "15px 0px" }}>
      {showCatalogModal && (
        <CatalogModal
          denyAction={() => setShowCatalogModal(false)}
          returnNodeData={(nodeData) => {
            linkContentToBlock(nodeData, "NODE");
            setShowCatalogModal(false);
          }}
        />
      )}
      {showImportContentModal && (
        <ImportContentModal
          nodeData={nodeData}
          denyAction={() => setShowImportContentModal(false)}
          blockData={blockData}
          returnNodeData={(nodeData, status) => linkContentToBlock(nodeData, "NODE", "IMPORT")}
          returnContentData={(contentData, elementType) =>
            linkContentToBlock(contentData, elementType)
          }
        />
      )}
      <Droppable type="element-list" droppableId={`element-list-${index}`}  isDropDisabled ={!permissionLearningPage}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="node-manager-content-list-wrapper"
          >
            <CardList
              cards={elementList}
              removeNode={removeNode}
              loadingDeleteBlockElement={loadingDeleteBlockElement}
              permissionLearningPage={permissionLearningPage}
              blockData={blockData}
              nodeData={nodeData}
            />
            {provided.placeholder}

            {loadingLinkData && (
              <Skeleton
                variant="rect"
                width={600}
                height={90}
                style={{ borderRadius: 10, marginBottom: 20 }}
              />
            )}
            <div className="node-manager-type-list-add-container">
              {isCatalog ? (
                <button
                  disabled={
                    blockEditRedirect || ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                    nodeData?.nodeType !== "ORGANIZATION")
                  }
                  type="submit"
                  className="btn content-page-button"
                  style={{ backgroundColor: "#feac0e" }}
                  onClick={() => setShowCatalogModal(true)}
                >
                  Selecionar Página de Conteúdo{" "}
                  <AddCircleOutlineIcon style={{ marginLeft: 10, marginRight: 0, fontSize: 20 }} />
                </button>
              ) : (
                <>
                  <ContentOptionsPopover
                    nodeData={nodeData}
                    disabled={
                      blockEditRedirect || (
                      [statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                      nodeData?.nodeType !== "ORGANIZATION")
                    }
                    linkContentToBlock={linkContentToBlock}
                    setSelectedContentType={setSelectedContentType}
                    baseNodeLevel={baseNodeLevel}
                    blockData={blockData}
                  />
                  <CloudUploadIcon
                    onClick={() => setShowImportContentModal(true)}
                    style={{ fontSize: 40, marginLeft: 10 }}
                    className={`content-options-add ${
                      blockEditRedirect || ( [statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                      nodeData?.nodeType !== "ORGANIZATION")
                        ? "content-options-disabled"
                        : ""
                    }`}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </Droppable>
    </div>
  );
};
