export function convert(parameterValue, parameter = null, restrictions = null) {
  let res = parseInt(parameterValue);

  if (parameter === "timeLimit") {
    res = parseInt(parameterValue) / 60; // Transformar tempo de Segundo para Min
  }
  if (
    (parameterValue === "1" || parameterValue === "true" || parameterValue === true) &&
    restrictions === "1|0"
  )
    res = true;
  else if (
    (parameterValue === "0" || parameterValue === "false" || parameterValue === false) &&
    restrictions === "1|0"
  )
    res = false;
  else if (parameter === "workload") {
    res = "1";
  } else if (parameter === "closingText") {
    res = parameterValue || "";
  }

  return res;
}

export function getParametersEvaluation(objEvaluation) {
  const parameters = objEvaluation.evaluationParameters
    .filter((it) => it.parameterValue !== null)
    .map((it) => {
      const paramIt = {
        [it.parameter]: convert(it.parameterValue, it.parameter, it.restrictions),
      };
      return paramIt;
    })
    .reduce(function (result, item) {
      var key = Object.keys(item)[0];
      result[key] = item[key];
      return result;
    }, {});

  const obj = {
    title: objEvaluation.title || "",
    description: objEvaluation.description || "",
    image: objEvaluation.image || "",
    author: objEvaluation.author || "",
    authorEmail: objEvaluation.authorEmail || "",
    categories: objEvaluation.categories,
    evaluationTags: objEvaluation.evaluationTags,
    hasQuestionnaire: objEvaluation?.hasQuestionnaire || false,
    ...parameters,
  };
  return obj;
}

export function setParametersEvaluation(objEvaluation, objReturn, evaluationTags, image) {
  const paramenters = objEvaluation.evaluationParameters.map((it) => {
    const filtReturn = objReturn[it.parameter];
    let filt = String(filtReturn);

    if (it.parameter === "timeLimit") {
      filt = String(filtReturn * 60); // Transformar tempo de Min para Segundo

      if (objReturn.noTimeLimit) {
        filt = "0";
      }
    }

    if (it.restrictions === "1|0") {
      filt = objReturn[it.parameter] === true ? "1" : "0";
    }

    if (it.parameter === "workload") {
      filt = "1";
    } else if (it.parameter === "closingText") {
      filt = objReturn[it.parameter] || "";
    }

    const paramIt = {
      ...it,
      parameterValue: filt,
    };

    return paramIt;
  });

  const obj = {
    title: objReturn.title,
    description: objReturn.description.length > 0 ? objReturn.description : null,
    image: image,
    author: objReturn.author.length > 0 ? objReturn.author : null,
    authorEmail: objReturn.authorEmail.length > 0 ? objReturn.authorEmail : null,
    evaluationTags: evaluationTags,
    evaluationParameters: paramenters,
  };

  return {
    ...objEvaluation,
    ...obj,
  };
}
