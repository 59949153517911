import NiceModal from "@ebay/nice-modal-react";
import { Profile } from "components/PeopleCraft/ManagerTrial/model/ManagerTrial.model";
import ModalUpgrade from "components/PeopleCraft/Modais/ModalUpgrade";

export const openModalUpgrade = ({
  userIsAdmin,
  hasRequestedUpgrade = false,
  numberRequest,
}) => {
  NiceModal.show(ModalUpgrade, {
    obj: {
      hasRequestedUpgrade: hasRequestedUpgrade,
      numberOfClient: numberRequest,
      profile: userIsAdmin ? Profile.admim : Profile.client,
    },
  });
};
