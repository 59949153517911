import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { Button, Badge, Modal, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { FiUserPlus } from "react-icons/fi";
import { MdEdit, MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaCheckCircle, FaMinusCircle } from "react-icons/fa";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Loading from "../components/Loading";
import { getMenuRoutes } from "../utils/Utils";
import SecondaryTokenAuth from "../components/SecondaryTokenAuth";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import {
  editUserSecondary,
  forgotEmailSecondary,
  getUsersSecondary,
  sendEmailBoasVindasApiSecondary,
} from "../services/NexiDigitalAPISecondary";
import DataTable from "react-data-table-component";
import NoDataPlaceholder from "../components/NoDataPlaceholder";
import { getAppType } from "../utils/Utils";

const paginationComponentOptions = {
  rowsPerPageText: "Usuários por página",
  rangeSeparatorText: "de",
};

export default function User() {
  const columns = [
    {
      name: "Permissão",
      wrap: true,
      center: true,
      style: { textAlign: "left" },
      maxWidth: "10px",
      reorder: false,
      sortable: false,
      cell: (d) =>
        [1, 2].includes(d.admin) ? (
          <Badge variant="success">ADMIN</Badge>
        ) : (
          <Badge variant="secondary">USUÁRIO</Badge>
        ),
    },
    {
      name: "Nome",
      selector: "name",
      wrap: true,
      sortable: false,
      style: { textAlign: "center" },
      cell: (d) => <>{d.name || "-"}</>,
    },
    {
      name: "Email",
      selector: "email",
      wrap: true,
      sortable: false,
      style: { textAlign: "center" },
      cell: (d) => <>{d.email || "-"}</>,
    },
    {
      name: "Ativo",
      wrap: true,
      sortable: false,
      style: { textAlign: "center" },
      width: "110px",
      center: true,
      cell: (d) =>
        d.active === 1 ? (
          <Badge variant="primary">SIM</Badge>
        ) : (
          <Badge variant="secondary">NÃO</Badge>
        ),
    },
    {
      name: "Ações",
      wrap: true,
      width: "200px",
      center: true,
      style: { textAlign: "center" },
      cell: (item) => {
        return (
          (permissionEditUser || permissionResetUserPassword || permissionUpdateUserStatus) && (
            <div style={{ textAlign: "center" }}>
              {permissionEditUser && (
                <OverlayTrigger placement="top" delay={{ show: 0, hide: 0 }} overlay={editTooltip}>
                  <MdEdit
                    onClick={() => {
                      saveTokenSecondary &&
                        localStorage.setItem("token-secondary", saveTokenSecondary);
                      history.push(`/edit-user?id=${item.idUser}`);
                    }}
                    style={{
                      cursor: "pointer",
                      fontSize: 22,
                    }}
                  />
                </OverlayTrigger>
              )}

              {permissionResetUserPassword && (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 0, hide: 0 }}
                  overlay={redefinirSenha}
                >
                  <RiLockPasswordFill
                    onClick={() => {
                      resetaSenha(item.username);
                      handleShowAlert();
                    }}
                    style={{
                      cursor: "pointer",
                      fontSize: 22,
                    }}
                  />
                </OverlayTrigger>
              )}
              {
                // eslint-disable-next-line eqeqeq
                permissionUpdateUserStatus && meuId != item.idUser ? (
                  item.active === 1 ? (
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 0, hide: 0 }}
                      overlay={desativarUsuario}
                    >
                      <FaMinusCircle
                        onClick={() => {
                          setSelectedUser(item);
                          handleShowDesativa();
                        }}
                        style={{
                          cursor: "pointer",
                          fontSize: 20,
                          color: "red",
                          marginLeft: 3,
                        }}
                      />
                    </OverlayTrigger>
                  ) : licencasRestantes > 0 ? (
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 0, hide: 0 }}
                      overlay={ativarUsuario}
                    >
                      <FaCheckCircle
                        onClick={() => {
                          setSelectedUser(item);
                          handleShowAtiva();
                        }}
                        style={{
                          cursor: "pointer",
                          fontSize: 20,
                          color: "green",
                          marginLeft: 3,
                        }}
                      />
                    </OverlayTrigger>
                  ) : null
                ) : null
              }
              <OverlayTrigger placement="top" delay={{ show: 0, hide: 0 }} overlay={emailTooltip}>
                <MdEmail
                  onClick={() => {
                    sendEmailBoasVindas(item.email);
                    handleShowAlertBoasVindas();
                  }}
                  style={{
                    cursor: "pointer",
                    fontSize: 22,
                  }}
                />
              </OverlayTrigger>
            </div>
          )
        );
      },
    },
  ];
  const history = useHistory();
  const [users, setUsers] = useState("");
  const [company, setCompany] = useState(null);
  const [meuId, setMeuId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [licencasEmUso, setlicencasEmUso] = useState("");
  const [licencasRestantes, setlicencasRestantes] = useState("");
  const [showDesativa, setShowDesativa] = useState(false);
  const [showAtiva, setShowAtiva] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertBoasVindas, setShowAlertBoasVindas] = useState(false);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
    totalRecords: 999,
  });
  const [searchText, setSearchText] = useState(undefined);
  const [selectedUser, setSelectedUser] = useState(null);
  const { loginData } = useContext(AuthContext);

  const handleCloseDesativa = () => setShowDesativa(false);
  const handleShowDesativa = () => setShowDesativa(true);

  const handleCloseAtiva = () => setShowAtiva(false);
  const handleShowAtiva = () => setShowAtiva(true);

  const handleCloseAlert = () => setShowAlert(false);
  const handleShowAlert = () => setShowAlert(true);

  const handleCloseAlertBoasVindas = () => setShowAlertBoasVindas(false);
  const handleShowAlertBoasVindas = () => setShowAlertBoasVindas(true);

  const isMountedRef = useRef(null);

  /* [Permission Module] - Permissions states */
  const [permissionAddUser, setPermissionAddUser] = useState(false);
  const [permissionImportUsers, setPermissionImportUsers] = useState(false);
  const [permissionAddGroup, setPermissionAddGroup] = useState(false);
  const [permissionEditUser, setPermissionEditUser] = useState(false);
  const [permissionResetUserPassword, setPermissionResetUserPassword] = useState(false);
  const [permissionUpdateUserStatus, setPermissionUpdateUserStatus] = useState(false);

  const [saveTokenSecondary, setSaveTokenSecondary] = useState("");

  /* [Permission Module] - Menu Options */
  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();

    const objCurrentMenu = menuOptionList.filter((menu) => menu.text === "Cadastro de Usuários");

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);

      getPermissionsPage(resources);
    }
  }

  /* [Permission Module] - Permission attrs in page by login data information */
  function getPermissionsPage(resources) {
    try {
      if (Boolean(resources[0].filter((menu) => menu.name === "Inserir Usuário").length))
        setPermissionAddUser(true);
      if (Boolean(resources[0].filter((menu) => menu.name === "Envio de Senha").length))
        setPermissionResetUserPassword(true);
      if (Boolean(resources[0].filter((menu) => menu.name === "Edição de Usuários").length))
        setPermissionEditUser(true);
      if (Boolean(resources[0].filter((menu) => menu.name === "Upload de Usuário").length))
        setPermissionImportUsers(true);
      if (Boolean(resources[0].filter((menu) => menu.name === "Criar Grupos").length))
        setPermissionAddGroup(true);
      if (Boolean(resources[0].filter((menu) => menu.name === "Status Usuário").length))
        setPermissionUpdateUserStatus(true);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    /* [Permission Module] - Function calls */
    getMenuOption();

    isMountedRef.current = true;

    // TODO: Controle para chamar apenas na primeira vez


    return () => (isMountedRef.current = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  function handleActiveUser(usuario) {
    const editUserData = {
      email: usuario.email,
      name: usuario.name,
      phone: usuario.phone,
      cpf: usuario.cpf,
      userType: usuario.userType,
      userLicenceTermsAccepted: usuario.userLicenceTermsAccepted,
      communicationAccepted: usuario.communicationAccepted,
      guestCompany: usuario.guestCompany,
      guestCnpj: usuario.guestCnpj,
      admin: usuario.admin,
      active: usuario.active,
      photo: usuario.photo,
    };
    // usuario.idUser
    editUserSecondary(usuario.idUser, editUserData)
      .then(({ data }) => {
        if (data.success) {
          _getUsers();
        }
      })
      .catch((e) => console.log("Falha ao tentar ativar/desativar o id", usuario.idUser));
  }

  function resetaSenha(username) {
    forgotEmailSecondary({ username, idAppType: getAppType() }).catch((e) => console.log("Erro ao resetar a senha", e));
  }

  function sendEmailBoasVindas(email) {
    sendEmailBoasVindasApiSecondary(email);
  }

  function desativaUser() {
    const usuarioParaDesativar = selectedUser;
    usuarioParaDesativar.active = 0;
    handleCloseDesativa();
    handleActiveUser(usuarioParaDesativar);
  }

  function ativaUser() {
    const usuarioParaAtivar = selectedUser;
    usuarioParaAtivar.active = 1;
    handleCloseAtiva();
    handleActiveUser(usuarioParaAtivar);
  }

  const onChangePage = (page) => {
    _getUsers({
      ...pagination,
      offset: page === 1 ? 0 : (page - 1) * pagination.limit,
      searchText: searchText
    });
  };

  const onChangeRowsPerPage = (limit, page) => {
    _getUsers({
      ...pagination,
      limit,
      offset: page === 1 ? 0 : (page - 1) * limit,
      searchText: searchText
    });
  };

  const  _getUsers = useCallback((customPagination, clearSearchText) => {
    setIsLoading(true);
    const queryParams = {
      offset: customPagination?.offset || pagination.offset,
      limit: customPagination?.limit || pagination.limit,
      searchString: clearSearchText ? "" : customPagination?.searchText || searchText,
    };
    getUsersSecondary(queryParams)
      .then(({ data }) => {
        if (isMountedRef.current) {
          if (data.success) {
            const totalRecords = data.totalRecords;

            setPagination({ ...pagination, totalRecords: totalRecords })
            setCompany(data.company);

            const usuarios = data.userList;
            setMeuId(loginData.id);

            // const licencasEmUso = usuarios.filter(function (dado) {
            //   return dado.active === 1;
            // });
            setlicencasEmUso(data.totalActiveLicenses);
            setlicencasRestantes(data.company?.numberOfLicences - data.totalActiveLicenses);
            setUsers(usuarios);
            //
            setSaveTokenSecondary(localStorage.getItem("token-secondary") || data?.newToken);
            localStorage.removeItem("token-secondary");
          } else {
            setUsers([]);

            //
            setSaveTokenSecondary(localStorage.getItem("token-secondary"));
            localStorage.removeItem("token-secondary");
          }
        }
      })
      .catch((e) => console.log("Erro ao listar usuarios", e))
      .finally(() => {
        setIsLoading(false);
      });
  }, [])

  const editTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Editar Usuário
    </Tooltip>
  );

  const emailTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Enviar e-mail de boas-vindas
    </Tooltip>
  );

  const redefinirSenha = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Redefinir Senha
    </Tooltip>
  );

  const desativarUsuario = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Desativar usuário
    </Tooltip>
  );

  const ativarUsuario = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Ativar usuário
    </Tooltip>
  );

  return (
    <div className="User" style={{ paddingBottom: 50 }}>
      <div className="header-title-wrapper">
        <div className="header-title">Cadastro de usuários</div>
      </div>
      <Modal show={showDesativa} onHide={handleCloseDesativa} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Desativar Usuário?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza de que quer desativar o usuário?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDesativa}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={desativaUser}>
            Desativar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAtiva} onHide={handleCloseAtiva} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Desativar Usuário?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza de que quer ativar o usuário?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAtiva}>
            Cancelar
          </Button>
          <Button variant="success" onClick={ativaUser}>
            Ativar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAlert} onHide={handleCloseAlert} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Reset de senha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Um e-mail foi enviado para o usuário com instruções de como trocar a senha.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleCloseAlert}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAlertBoasVindas} onHide={handleCloseAlertBoasVindas} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Boas-Vindas</Modal.Title>
        </Modal.Header>
        <Modal.Body>Um e-mail foi enviado para o usuário com instruções de boas-vindas.</Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleCloseAlertBoasVindas}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        <SecondaryTokenAuth onStartLogin={() => setIsLoading(true)} onLoaded={() =>  _getUsers()} />

        <Table size="sm">
          <Thead>
            <Tr>
              <Th>
                <strong>CNPJ</strong>
              </Th>
              <Th>
                <strong>EMPRESA</strong>
              </Th>
              <Th>
                <strong>LICENÇAS UTILIZADAS</strong>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{company?.cnpj}</Td>
              <Td>{company?.name}</Td>
              <Td>
                {licencasEmUso}/{company?.numberOfLicences}
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <div className="mt-3 mb-3" style={{ display: "flex" }}>
          {permissionAddUser && (
            <button
              disabled={licencasRestantes <= 0 || !saveTokenSecondary}
              className="button-peop button-yellow"
              style={{ marginRight: 10 }}
              onClick={() => {
                saveTokenSecondary && localStorage.setItem("token-secondary", saveTokenSecondary);
                history.push("/user/add");
              }}
            >
              <FiUserPlus className="mr-2" size={22} />
              Adicionar Usuário
            </button>
          )}

          {permissionImportUsers && (
            <button
              className="button-peop button-yellow"
              style={{ marginRight: 10 }}
              onClick={() => {
                saveTokenSecondary && localStorage.setItem("token-secondary", saveTokenSecondary);
                history.push("/import-users");
              }}
            >
              <PublishOutlinedIcon className="mr-2" />
              Importar Usuários
            </button>
          )}

          {permissionAddGroup && (
            <button
              className="button-peop button-purple"
              onClick={() => {
                saveTokenSecondary && localStorage.setItem("token-secondary", saveTokenSecondary);
                history.push("/configuracao/grupos");
              }}
            >
              Gerenciar público-alvo
            </button>
          )}
        </div>
        <div className="content-page-modal-title mt-4">Procurar Usuário</div>
        <div className="editUserSearchInfo mb-3" style={{ maxWidth: 400 }}>
          <AiOutlineSearch style={{ left: 10 }} />
          <Form.Control
            value={searchText}
            onKeyDown={(input) => {
              if (input.key === "Enter") {
                _getUsers({ searchText: input.target.value });
              }
            }}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={`Pesquisar...`}
          />
          <button
            onClick={() => {
              setSearchText("");
              _getUsers({ searchText: "", offset: 0 }, true);
            }}
          >
            <AiOutlineClose />
          </button>
        </div>
        <div style={{ position: "relative" }}>
          {isLoading && users.length > 0 && (
            <div
              style={{
                position: "absolute",
                zIndex: 1,
                width: "102%",
                height: "102%",
                top: -1,
                left: -1,
                display: "flex",
                alignItems: "center",
                backdropFilter: "blur(3px)",
              }}
            >
              <Loading>Carregando Usuários...</Loading>
            </div>
          )}
          <div className="node-manager-import-table-wrapper mb-2">
            <DataTable
              columns={columns}
              data={users}
              progressComponent={
                <Loading containerStyle={{ height: 536 }}>Carregando usuários...</Loading>
              }
              noDataComponent={
                <NoDataPlaceholder text="Ainda não há usuários cadastrados nesta empresa." />
              }
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              paginationTotalRows={pagination.totalRecords}
              paginationComponentOptions={paginationComponentOptions}
              progressPending={isLoading && users?.length === 0}
              paginationServer
              noHeader
              fixedHeader
              pagination
              highlightOnHover
            />
          </div>
        </div>
        <div style={{ flexBasis: "100%" }} />
      </div>
    </div>
  );
}
