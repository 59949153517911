import React, { useEffect } from "react";
import parse from "html-react-parser";

const LikertScale = ({ answer, mandatory = true, indice, onHandleChange }) => {
  const { scales, statement } = answer.question;
  const validateAnswerStatus = () => {
    if (mandatory) answer.answerStatus = answer.answerData.length > 0;
    else answer.answerStatus = true;
  };

  validateAnswerStatus();
  useEffect(() => {
    onHandleChange();
  });

  const changeHandler = (e, item, indice) => {
    if (answer.answerData && answer.answerData.length === 0) {
      const resNew = {
        idQuestionScale: item.idQuestionScale,
        idQuestionOption: null,
      };
      answer.answerData.push(resNew);
    } else {
      answer.answerData[0].idQuestionScale = item.idQuestionScale;
    }

    validateAnswerStatus();
    onHandleChange();
  };

  const checkStatus = (item) => {
    const checkStatusValue =
      answer.answerData.length > 0 &&
      answer.answerData[0].idQuestionScale === item.idQuestionScale
        ? true
        : false;

    return checkStatusValue;
  };

  return (
    <div className="itD likert_scale">
      <div className="titleType">
        <div className="titleIndice">{indice}</div>
        <div className="titleText">{parse(`${statement}`)}</div>
      </div>

      <div className="itDChlidren listScale">
        {scales.map((item, i) => {
          const { title } = item;

          return (
            <div key={i} className="itListScale">
              <input
                type="radio"
                id={`${indice}_likert_${i}`}
                name={`${indice}_likert`}
                value={`${item.idQuestionScale}`}
                defaultChecked={checkStatus(item)}
                onChange={(e) => changeHandler(e, item, i)}
              />
              <label htmlFor={`${indice}_likert_${i}`}>{title}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LikertScale;
