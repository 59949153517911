
import React, { useEffect, useState } from 'react';
import Switch from "react-bootstrap/esm/Switch";

import './style.scss'
import Label from 'components/PeopleCraft/Label';
import { Form } from 'react-bootstrap';
import { DataCampaing } from '../GamificationEdit';
interface ChildEvent {
  idgamification_event: number;
  idParentGamificationEvent: number;
  idContentType: number;
  eventType: string;
  event: string;
  status: number;
  context: string;
}

interface Event {
  idgamification_event: number;
  idParentGamificationEvent: number | null;
  idContentType: number | null;
  eventType: EventGamification ;
  event: string;
  status: number;
  context: string;
  childEvents: ChildEvent[];
}

interface Props {
  data?: Event[];
  label?: string,
  validated?: boolean,
  handleChange?: any,
  dataCampaing?: DataCampaing,
  disabled?: boolean
}

export enum EventGamification {
  FREE_CONTENT_COMPLETED = 'FREE_CONTENT_COMPLETED',
  TRAINING_COMPLETED = 'TRAINING_COMPLETED'
}

const ActionScore: React.FC<Props> = ({ data, label = false, validated = false,handleChange, dataCampaing, disabled = false }) => {
  const [isInvalid, setIsInvalid] = useState<boolean>(false)
  const [active, setActive] = useState<{
    [EventGamification.FREE_CONTENT_COMPLETED]: boolean,
    [EventGamification.TRAINING_COMPLETED]: boolean,
  }>({
    [EventGamification.FREE_CONTENT_COMPLETED]: false,
    [EventGamification.TRAINING_COMPLETED]: dataCampaing?.eventGradeAsMultiplier.gradeAsMultiplier,
  })

  const defineValuesDefault = () => {
    //valores pré definidos   EventGamification.FREE_CONTENT_COMPLETED = 5 e EventGamification.TRAINING_COMPLETED = 6

    data.forEach(element => {
      if(element.eventType === EventGamification.FREE_CONTENT_COMPLETED){
        !dataCampaing[element.idgamification_event] && handleChangeEvent(element.idgamification_event, '5')
        return
      }
      if(element.eventType === EventGamification.TRAINING_COMPLETED){
        !dataCampaing[element.idgamification_event] && handleChangeEvent(element.idgamification_event, '20')
        return
      }
    });
  }

  useEffect(()=> {
    if(dataCampaing?.events && data){
      const _data = data.find(elem => elem.eventType === EventGamification.FREE_CONTENT_COMPLETED)
      const _checkValues = !!_data?.childEvents?.length ? Object.keys(dataCampaing?.events).some((elem) =>
        _data.childEvents.some(item=> item.idgamification_event === parseFloat(elem))
      ) : false

      const getTrainingCompleted = data.find(elem => elem.eventType === EventGamification.TRAINING_COMPLETED)
      handleChange({ eventGradeAsMultiplier: {
        gradeAsMultiplier: dataCampaing?.eventGradeAsMultiplier.gradeAsMultiplier,
        eventType: getTrainingCompleted.idgamification_event
      } })
     !(dataCampaing?.events && Object.keys(dataCampaing?.events).length) && defineValuesDefault();


      setActive((prevState) => ({...prevState,[ EventGamification.FREE_CONTENT_COMPLETED]: _checkValues}))
      setIsInvalid( Object.values(dataCampaing?.events).some(item => !item))

    }
  }, [data, dataCampaing?.events])

  useEffect(()=> {
    if(validated){
      setIsInvalid( !Object.values(dataCampaing?.events).some((item: any) => !!parseInt(item)))

    }
  }, [validated, dataCampaing?.events])

  const validInput = () => {
    setIsInvalid( !Object.values(dataCampaing?.events).some((item: any) => !!parseInt(item)))

  }

  const getTypeName = (typeId: number) => {
    switch (typeId) {
      case 1:
        return "Audio";
      case 2:
        return "Video";
      case 4:
        return "Pacote HTML";
      case 5:
        return "Artigo";
      case 18:
        return "Arquivo";
      case 27:
        return "Link Externo"  
      default:
        return "-";
    }
  }

  const getComponenteEvent = (eventGamification: EventGamification, idEvent: number) => {
    switch (eventGamification) {
      case EventGamification.FREE_CONTENT_COMPLETED:
        return (
          <div className="form-data flex-row">
            <Switch
              disabled={disabled}
              className={disabled && 'opacity'}
              checked={active[eventGamification] || false}
              color="primary"
              id="enableConfig"
              onChange={(e) => {
                e.persist();
                setActive((afterValue) => ({ ...afterValue, [EventGamification.FREE_CONTENT_COMPLETED]: e.target?.checked || false })
                )
              }
              }

            />
            <Label className={"cursor-pointer"} name="Escolha a pontuação específica por item" htmlFor="enableConfig" />
          </div>

        )
      case EventGamification.TRAINING_COMPLETED:
        return (
          <div className="form-data flex-row">
            <Switch

              checked={active[eventGamification] || false}
              color="primary"
              disabled={disabled}
              className={disabled && 'opacity'}
              id={eventGamification}
              onChange={(e) => {
                e.persist();
                setActive((afterValue) => ({ ...afterValue, [EventGamification.TRAINING_COMPLETED]: e.target?.checked || false }))
                handleChange({ eventGradeAsMultiplier: {
                  gradeAsMultiplier: e.target?.checked || false,
                  eventType: idEvent
                }})
              }
              }
            />
            <Label className={"cursor-pointer"} name="Proporcional a nota da avaliação" htmlFor={eventGamification} />
          </div>

        )
      default:
        return <></>
    }
  }

  const  handleChangeEvent = (id: number, value: string) => {
    const events =  dataCampaing.events

    events[id] = value || value === '0' ? value : ''
    handleChange({events: events})
  }

  return (
    <div className='action-score'>
      <label style={{fontSize: 'large'}}>{label}</label>
      {!!data?.length && data.map((event) =>
      (
        <div key={event.idgamification_event}>
            <div  className="form-data line-input">
              <Label name={event.event} htmlFor={event.event} />
              <Form.Control
                disabled={disabled}
                min={0}
                key={event.idgamification_event}
                value={dataCampaing.events[event.idgamification_event] || 0}
                onChange={(e: any) => {
                  handleChangeEvent(event.idgamification_event, e.target.value)
                  validInput();
                }}

                className="form-control"
                type="number"
                isInvalid={validated && isInvalid}
              />
            <Form.Control.Feedback type="invalid">
                Obrigatório valor positivo em 1 campo (conteúdos/treino).
              </Form.Control.Feedback>
            </div>
          {getComponenteEvent(event.eventType, event.idgamification_event)}
          {

            active[event.eventType] && !!event.childEvents.length &&
            <>
              {event.childEvents.map((element) => (
                <div className="form-data line-input" key={element.idgamification_event}>
                  <Label name={getTypeName(element.idContentType)} htmlFor={getTypeName(element.idContentType)} />
                  <Form.Control
                    disabled={disabled}
                     min={0}
                    value={dataCampaing.events[element.idgamification_event]   || ''}
                    key={element.idgamification_event}
                    onChange={(e: any) => {
                      handleChangeEvent(element.idgamification_event, e.target.value)
                      validInput();
                    }}
                    isInvalid={validated && isInvalid}

                    maxLength={100}
                    className="form-control"
                    type="number"
                  />
                    <Form.Control.Feedback type="invalid">
                      Obrigatório valor positivo em 1 campo (conteúdos/treino).
                    </Form.Control.Feedback>
                </div>
              ))}
            </>
          }

        </div>
      ))
      }
    </div >
  );
};

export default ActionScore;
