import React, { useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import ModalRequestDetail from "../../modal/ModalRequestDetail";

import { convertToBrDate } from "utils/Utils";

import { LuFileCheck2 } from "react-icons/lu";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FiEye } from "react-icons/fi";

import { TypeExternalCourseDescriptions, StatusExternalCourseDescriptions } from "../../constants";

const Table = ({ setLoading, loading, actionFilter, pag, resetActionFilter }) => {
  const [dataRequestDetail, setDataRequestDetail] = useState(null);

  const callEdit = (d, statusModal) => {
    setDataRequestDetail({ ...d, statusModal: statusModal });
  };

  const columns = [
    {
      name: "Nome do aluno",
      selector: "name",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center", justifyContent: "center" },
      cell: (d) => d.name || "-",
    },
    {
      name: "Tipo",
      selector: "type",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center", justifyContent: "center" },
      cell: (d) => TypeExternalCourseDescriptions[d.type],
    },
    {
      name: "Nome do curso",
      selector: "title",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center", justifyContent: "center" },
      // cell: (d) => ValidateData(d.title),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center", justifyContent: "center" },
      cell: (res) => (
        <div className={`statusItem ${res.status}`}>{`• ${
          StatusExternalCourseDescriptions[res.status]
        }`}</div>
      ),
    },
    {
      name: "Data validação/rejeição",
      selector: "lastModifiedDate",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center", justifyContent: "center" },
      cell: (res) => (
        <div>{res.status !== "PENDING" ? convertToBrDate(res.lastModifiedDate) : "-"}</div>
      ),
    },
    {
      name: "Ações",
      selector: "action",
      sortable: false,
      style: { textAlign: "center" },
      className: "actionBtns",
      cell: (d) => (
        <>
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                Visualizar mais detalhes
              </Tooltip>
            }
          >
            <button
              onClick={(e) => {
                callEdit(d, "view");
              }}
              className="btn1"
            >
              <FiEye />
            </button>
          </OverlayTrigger>

          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                Validar solicitação
              </Tooltip>
            }
          >
            <button
              onClick={(e) => {
                callEdit(d, "approve");
              }}
              className="btn2"
              disabled={d.status !== "PENDING"}
            >
              <LuFileCheck2 />
            </button>
          </OverlayTrigger>

          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                Rejeitar solicitação
              </Tooltip>
            }
          >
            <button
              onClick={(e) => {
                callEdit(d, "reject");
              }}
              className="btn3"
              disabled={d.status !== "PENDING"}
            >
              <IoCloseCircleOutline />
            </button>
          </OverlayTrigger>
        </>
      ),
    },
  ];

  const customTableStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "16px",
        color: "#fff",
        textAlign: "center",
      },
    },
    cells: {
      style: {},
    },

    header: {
      style: {},
    },
    headRow: {
      style: {
        background: "#6B48FF",
      },
    },
  };

  const tableData = {
    columns: columns,
    data: pag.data,
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Usuários por página",
    rangeSeparatorText: "de",
  };

  const onChangePage = (page) => {
    const offset = --page * pag.limit;
    actionFilter({ ...pag, offset: offset });
  };

  const onChangeRowsPerPage = (limit) => {
    actionFilter({ ...pag, offset: 0, limit: limit });
  };

  const loadingComponent = () => {
    return (
      <div className="tela-login-loading">
        <div className="loader-container" style={{ margin: "300px 0" }}>
          <div
            style={{
              position: "initial",
              margin: "initial",
            }}
            className="loader loader-center loader-big"
          ></div>
          <span
            style={{
              marginTop: 10,
              fontSize: 20,
            }}
          >
            Carregando dados...
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <DataTableExtensions
        filter={false}
        print={false}
        {...tableData}
        exportHeaders={false}
        export={false}
      >
        <DataTable
          columns={columns}
          data={pag.data}
          // className={styles.dataTableFormat}
          customStyles={customTableStyles}
          progressPending={loading}
          progressComponent={loadingComponent()}
          noHeader={true}
          noDataComponent="Não foi encontrado nenhum dado"
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationTotalRows={pag.totalRecords}
          paginationComponentOptions={paginationComponentOptions}
          paginationServer
          pagination
        />
      </DataTableExtensions>
      <ModalRequestDetail
        dataRequestDetail={dataRequestDetail}
        setDataRequestDetail={setDataRequestDetail}
        resetActionFilter={resetActionFilter}
      />
    </>
  );
};

export default Table;
