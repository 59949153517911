import React, { useState } from "react";

import "./styles.scss";

import { FaGear } from "react-icons/fa6";
import { GrFormNext } from "react-icons/gr";

import Switch from "react-bootstrap/esm/Switch";
import { Form } from "react-bootstrap";

const ExternalCourseInclusionAcordeon = ({
  statusRequest,
  setStatusRequest,
  setShowModalDisableRequest,
  setImmediateManager,
  immediateManager,
  startExpanded,
}) => {
  const [expanded, setExpanded] = useState(startExpanded);
  const clickHandler = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className="ExternalCourseInclusion">
        <div onClick={clickHandler} className={"acordeonTitle"}>
          <div className="titleAcordeon">
            <FaGear /> Gerenciar inclusão de cursos externos
          </div>

          <div className={`playNext ${expanded ? "openNext" : "closedNext"}`}>
            <GrFormNext />
          </div>
        </div>
        <div className={`acordeonContent ${!expanded ? "acordeonContentNone" : ""}`}>
          <div className={"acordeonContentElm"}>
            <Switch
              color="primary"
              checked={statusRequest}
              id="statusRequest"
              onChange={(e) => {
                if (!e.target.checked) {
                  setShowModalDisableRequest(true);
                } else {
                  setStatusRequest(true);
                }
              }}
            />
            <div className="acordeonContentElmInfo">
              <p>
                <b>Permitir que alunos solicitem a inclusão de cursos externos.</b>
              </p>
              <p>
                Habilite a funcionalidade para que os alunos possam solicitar a inclusão de cursos
                externos na plataforma. As solicitações podem ser gerenciadas com opções para
                visualizar, validar ou rejeitar.
              </p>
            </div>
          </div>
          <div className={"acordeonContentElm acordeonContentElm2"}>
            <div className="acordeonContentElmInfo">
              <p>
                <b>Perfil de aprovação.</b>
              </p>
              <p>
                Além do administrador, defina quem terá permissão para aprovar solicitações de
                inclusão de cursos externos. Gestores imediatos podem aprovar apenas solicitações
                relacionadas às suas equipes.
              </p>

              <Form.Check
                label="Gestor imediato"
                disabled={!statusRequest}
                onChange={(e) => {
                  setImmediateManager(e.target.checked);
                }}
                type="checkbox"
                checked={immediateManager}
                id="immediateManager"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExternalCourseInclusionAcordeon;
