import React, { useState, useEffect } from "react";

import moment from "moment";
import "moment/locale/pt-br.js";

// Page UI and icons
import { Col, Form } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import FiltroWrapper from "../../../Components/FiltroWrapper";
import styles from ".././../styles.module.scss";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import { useRef } from "react";
import { getContentList, getSectionsList, getGroups } from "../../../../../services/NexiDigitalAPI";

const Filtro = ({ actionFilter, loading }) => {
  const [sectionList, setSectionList] = useState([]);
  const isMountedRef = useRef(null);
  const appDetails = JSON.parse(localStorage.getItem("appDetails")) || null;
  const [contentList, setContentList] = useState([]);
  const [groups, setGroups] = useState([]);

  const [values, setValues] = useState({
    startDate: moment(new Date()).subtract(1, "years").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    idGroup: 0,
  });

  function handleDateStart(jsDate) {
    let date = moment(jsDate).format("YYYY-MM-DD");
    setValues({
      ...values,
      startDate: date,
    });
  }

  function handleDateFinish(jsDate) {
    let date = moment(jsDate).format("YYYY-MM-DD");
    setValues({
      ...values,
      endDate: date,
    });
  }

  function handleSectionSelect(event) {
    setValues({
      ...values,
      idSection: parseInt(event.target.value),
    });
  }

  async function updateListData() {
    const sectionListData = await getSectionsList(appDetails?.idAppType);
    setSectionList(sectionListData);
  }

  async function updateContentList() {
    const contents = await getContentList(values.idSection);
    setContentList(contents);
    if (contents.length === 0) {
      setValues({
        ...values,
        idContentItem: 0,
      });
    }
  }

  function handleContentSelect(event) {
    setValues({
      ...values,
      idContentItem: parseInt(event.target.value),
    });
  }

  function handleGroupSelect(event) {
    setValues({
      ...values,
      idGroup: parseInt(event.target.value),
    });
  }

  function _getGroups() {
    getGroups()
      .then((res) => {
        if (res.data.success == true) {
          setGroups(res.data.groups);
        } else {
        }
      })
      .catch((err) => {
        console.log("error ", err);
      });
  }

  useEffect(() => {
    if (values.idSection !== -1 && values.idSection !== 0) {
      updateContentList();
    } else {
      setValues({
        ...values,
        idContentItem: 0,
        contentList: [],
      });
      setContentList([]);
    }
  }, [values.idSection]);

  useEffect(() => {
    actionFilter(values);
    isMountedRef.current = true;
    updateListData();
    _getGroups();
    return () => (isMountedRef.current = false);
  }, []);

  function handleFilter() {
    actionFilter(values);
  }

  useEffect(() => {
    handleFilter();
  }, []);

  return (
    <FiltroWrapper>
      <Form style={{ marginTop: 30, marginBottom: 30 }}>
        <Form.Row className="align-items-end">
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="startDate">Data de Início</Form.Label>
            <DatePickerInput locale="pt-br" onChange={handleDateStart} value={values.startDate} />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="endDate">Data de Término</Form.Label>
            <DatePickerInput locale="pt-br" onChange={handleDateFinish} value={values.endDate} />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Group controlId="apps" className="mb-0">
              <Form.Label>Seção</Form.Label>
              <Form.Control as="select" onChange={handleSectionSelect} defaultValue={0}>
                <option value={0}>Todas</option>
                <option disabled="disabled">----</option>
                {sectionList &&
                  sectionList.map((section) => (
                    <option value={section.idSection} key={section.idSection}>
                      {section.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Group controlId="apps" className="mb-0">
              <Form.Label>Conteúdos</Form.Label>
              <Form.Control
                as="select"
                disabled={contentList.length === 0}
                onChange={handleContentSelect}
              >
                <option value={0}>Selecione</option>
                {contentList &&
                  contentList.map((content) => (
                    <option value={content.idContentItem} key={content.idContentItem}>
                      {content.text}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Group className="mb-0" onChange={handleGroupSelect}>
              <Form.Label>Grupo</Form.Label>
              <Form.Control as="select">
                <option value={0}>Todos</option>
                {groups.map((item, index) => {
                  return (
                    <option key={index} value={item.idGroups}>
                      {item.title}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Group className="mb-0">
              <ButtonWithLoading
                onClickHandler={handleFilter}
                loading={loading}
                text={
                  <>
                    Aplicar filtro <FaFilter style={{ marginLeft: 5 }} />{" "}
                  </>
                }
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    </FiltroWrapper>
  );
};

export default Filtro;
