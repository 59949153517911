import React, { useState, Fragment } from "react";

import LineItem from "./LineItem";
import RecursiveItens from "./RecursiveItens";
import { FaMinusSquare, FaPlusSquare } from "react-icons/fa";

export default function AccordionItens({
  model,
  item,
  idUser,
  endPoint,
  indKey,
  idEnrollment,
  nodeCode,
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  function toggle(e) {
    isExpanded ? setIsExpanded(false) : setIsExpanded(true);

    e.stopPropagation();
  }

  return (
    <Fragment>
      <div
        className={`${model === "students" ? "titleAccordion" : "titleAccordionRes"}`}
        onClick={(e) => {
          toggle(e);
        }}
      >
        {model === "students" ? (
          <>
            {isExpanded ? <FaMinusSquare /> : <FaPlusSquare />}
            {(item.name + " - " + item.companyName).toLowerCase()}
          </>
        ) : (
          <LineItem item={item} idUser={idUser} arcodeonStatus={isExpanded} />
        )}
      </div>
      {isExpanded && (
        <div className={`collapseAccordion ${model === "students" && "collapseAccordionStudents"}`}>
          {
            <RecursiveItens
              endPoint={endPoint}
              idUser={idUser}
              indice={indKey}
              idEnrollment={idEnrollment}
              nodeCode={nodeCode}
              open={isExpanded}
            />
          }
        </div>
      )}
    </Fragment>
  );
}
