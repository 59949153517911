import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

const SingleOptionComment = ({
  answer,
  mandatory = true,
  indice,
  onHandleChange,
}) => {
  const [inputText, setInputText] = useState(
    answer.answerText ? answer.answerText.length : 0
  );
  const [characterLimit] = useState(4000);

  const { options, statement } = answer.question;
  const validateAnswerStatus = () => {
    if (mandatory) {
      if (answer.answerData.length > 0 && answer.answerText) {
        answer.answerStatus = true;
      } else {
        answer.answerStatus = false;
      }
    } else {
      answer.answerStatus = true;
    }
  };

  validateAnswerStatus();
  useEffect(() => {
    onHandleChange();
  });

  const changeHandler = (e, item) => {
    if (item) {
      if (answer.answerData && answer.answerData.length === 0) {
        const resNew = {
          idQuestionScale: null,
          idQuestionOption: item.idQuestionOption,
        };
        answer.answerData.push(resNew);
      } else {
        answer.answerData[0].idQuestionOption = item.idQuestionOption;
      }
    } else {
      answer.answerText = e.target.value.length > 0 ? e.target.value : null;
      setInputText(e.target.value.length);
    }

    validateAnswerStatus();
    onHandleChange();
  };

  const checkStatus = (item) => {
    const checkStatusValue =
      answer.answerData.length > 0 &&
      answer.answerData[0].idQuestionOption === item.idQuestionOption
        ? true
        : false;

    if (answer.mandatory)
      answer.answerStatus = answer.answerData.length > 0 && answer.answerText;

    return checkStatusValue;
  };

  const checkTextAreaInit = () => {
    return answer.answerText ? answer.answerText : "";
  };

  return (
    <div className="itD single_option_comment">
      <div className="titleType">
        <div className="titleIndice">{indice}</div>
        <div className="titleText">{parse(`${statement}`)}</div>
      </div>

      <div className="itDChlidren listScale">
        {options.map((item, i) => {
          return (
            <div key={i} className="itListScale">
              <input
                type="radio"
                id={`${indice}_likert_${i}`}
                name={`${indice}_likert`}
                value={`${item.idQuestionOption}`}
                defaultChecked={checkStatus(item)}
                onChange={(e) => changeHandler(e, item)}
              />
              <label htmlFor={`${indice}_likert_${i}`}>
                {item.description}
              </label>
            </div>
          );
        })}
      </div>

      <div className="textAreaContainer itDChlidren">
        <textarea
          maxLength={characterLimit}
          onChange={(e) => changeHandler(e, null)}
          placeholder="Escreva aqui a sua resposta"
          defaultValue={checkTextAreaInit()}
        ></textarea>
        <p className="infoTextArea">{`${inputText}/${characterLimit}`}</p>
      </div>
    </div>
  );
};

export default SingleOptionComment;
