import React, { ChangeEvent } from "react";
import { Button, Form } from "react-bootstrap";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { BsFilter } from "react-icons/bs";
import "./styles.scss"
type Props  = {
  textSearch: string,
  handleChangeName: ( event: ChangeEvent<HTMLInputElement>) => void,
  handleAction: () => void,
  cleanSearch: () => void,
  filter: () => void,
}

const HeaderFilter: React.FC<Props> = ({textSearch, cleanSearch,filter, handleChangeName, handleAction}) => (
  <div className="header-filter">
    <div className="filtersOptions">
      <div className="searchInfo">
        <AiOutlineSearch />
        <Form.Control
          id="searchInput"
          value={textSearch}
          onChange={handleChangeName}
          placeholder={`Pesquisar por campanha`}
        />
        {textSearch && <button
          className="cleanSearch"
          onClick={cleanSearch}
        >
          <AiOutlineClose />
        </button>}
      </div>

      <Button className="buttonSearch color" variant="primary" onClick={filter}>
        <BsFilter />
        <span>Filtrar</span>
      </Button>
      <Button  variant="warning" className="button-peop button-yellow end-item" onClick={handleAction}>
            <span>Criar Nova</span>
        </Button>
    </div>
  </div>
)

export default HeaderFilter
