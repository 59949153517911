import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import TextEditor from "components/TextEditor";
import sanitizeHtml from "sanitize-html";
import "./style.scss";
import { getInnerText } from "utils/Utils";

const MAX_CHAR_COUNT = 1000;

const addTargetBlank = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  const links = tempDiv.getElementsByTagName("a");
  for (let i = 0; i < links.length; i++) {
    links[i].setAttribute("target", "_blank");
    links[i].setAttribute("rel", "noopener noreferrer");
  }
  return tempDiv.innerHTML;
};

const SendMessageBox = ({ isReply, handleReplyToggle, handleNewMessage }) => {
  const editorRef = useRef(null);
  const [content, setContent] = useState("");

  const handleSave = () => {
    const htmlContent = editorRef.current.getContent();

    const cleanHtmlContent = sanitizeHtml(htmlContent, {
      allowedTags: ["b", "i", "u", "a", "p", "br"],
      allowedAttributes: {
        a: ["href"],
      },
      allowedSchemes: ["http", "https", "mailto"],
    });

    const finalHtmlContent = addTargetBlank(cleanHtmlContent);

    const truncatedContent = finalHtmlContent.substring(0, MAX_CHAR_COUNT);
    if (Boolean(getInnerText(truncatedContent).trim().length)) {
      handleNewMessage(truncatedContent);
    }
  };

  return (
    <div className="send-message-box">
      <TextEditor
        ref={editorRef}
        placeholder={`Digite sua ${isReply ? "resposta" : "mensagem"}...`}
        maxLength={MAX_CHAR_COUNT}
        onChange={(newContent) => setContent(getInnerText(newContent))}
      />
      <div className="send-message-actions">
        <div>
          <button className="send-message-button send-message-cancel-button" onClick={handleReplyToggle}>
            Cancelar
          </button>
          <button
            className="send-message-button send-message-submit-button"
            onClick={handleSave}
            disabled={!Boolean(content.trim()?.length || 0)}
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
};

SendMessageBox.propTypes = {
  isReply: PropTypes.bool,
  handleReplyToggle: PropTypes.func.isRequired,
  handleNewMessage: PropTypes.func.isRequired,
};

export default SendMessageBox;
