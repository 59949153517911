import "./styles.scss";
import "react-reflex/styles.css";

import React, { useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";

import NodeManagerV2 from "../NodeManagerV2/NodeManagerV2";
import DynamicParameters from "./Components/DynamicParameters";
import NodesTree from "./Components/NodesTree";
import BreadcrumbControl from "./Components/BreadcrumbControl";
import ControlledBlockElement from "./Components/ControlledBlockElement";


import { LMContext } from "./Contexts/LMContext";
import { truncate } from "fs/promises";

const LearningManagementNew = (props) => {

  const defaultPanelsSizes = useRef({
    panelTree: 0.15,
    panelNodeManager: 0.7,
    panelDynamicParameters: 0.15
  });
  const [panelsSize, setPanelsSize] = useState(defaultPanelsSizes.current);


  return (
    <div className="learningManagementNew">
      <div className="header-title-wrapper">
        <div className="header-title">
          <button
            className="btnGoBack"
            onClick={() => {
              props.history.goBack();
            }}
          >
            <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
          </button>
          Gestão de Aprendizagem
        </div>
      </div>
      <LMContext>
        <BreadcrumbControl />
        <ReflexContainer orientation="vertical" windowResizeAware={true}>
          <ReflexElement flex={panelsSize.panelTree} minSize={0} className="nodesTreeBase" onStartResize={(e)=>{

          }}>
            <ControlledBlockElement minPanelSize={0}/>
            <NodesTree />
          </ReflexElement>

          <ReflexSplitter propagate={false} />

          <ReflexElement flex={panelsSize.panelNodeManager} minSize={0} className="authoringSection central-reflex-element">
            <NodeManagerV2 />
          </ReflexElement>

          <ReflexSplitter propagate={false} />

          <ReflexElement flex={panelsSize.panelDynamicParameters} minSize={0} className="dynamicParameters" onStartResize={(e)=>{

          }} >
            <ControlledBlockElement minPanelSize={0} reverseExpandIcon={true}/>
            <DynamicParameters />
          </ReflexElement>
        </ReflexContainer>
      </LMContext>
    </div>
  );
};

export default withRouter(LearningManagementNew);
