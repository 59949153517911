import React, { useState, useEffect } from "react";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import SortIcon from "@material-ui/icons/ArrowDownward";

import moment from "moment";
import { withRouter, useParams } from "react-router-dom";

import {
  getLearningEnrollments,
  editLearningEnrollmentsStatus,
} from "../../../../services/NexiDigitalAPI";
import Loading from "../../../../components/Loading";

// import { BsPencilSquare , BsFillPeopleFill } from "react-icons/bs";

import { Form, InputGroup } from "react-bootstrap";

import "./style.modules.scss";

const StatusStudents = (props) => {
  const { nodeCode, title, idTeam } = useParams();

  const [listM, setListM] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(null);
  const [perPage, setPerPage] = useState(20);
  const [containerStatus] = useState([
    { ref: "NOT_ATTEMPTED", text: "Não iniciado" },
    { ref: "IN_PROGRESS", text: "Em andamento" },
    { ref: "COMPLETED", text: "Concluído" },
    { ref: "PASSED", text: "Aprovado" },
    { ref: "FAILED", text: "Reprovado" },
    { ref: "EXPIRED", text: "Expirado" },
  ]);

  useEffect(() => {
    fetchData(1, 1000);
  }, []);

  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const setStatus = (el, status) => {
    const data = {
      idEnrollment: el.idEnrollment,
      status: status,
    };

    // console.log(data);

    (async (data) => {
      await editLearningEnrollmentsStatus({ data });
    })(data);
  };

  const columns = [
    {
      name: "Nome do aluno",
      selector: "name",
      sortable: true,
    },
    {
      name: "Data da Matrícula",
      selector: "enrollDate",
      sortable: true,
    },
    {
      name: "Nota",
      selector: "grade",
      sortable: true,
    },
    {
      name: "Progresso nos conteúdos",
      selector: "progress",
      sortable: true,
    },
    {
      name: "Status",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <>
          <InputGroup key={d.idUser}>
            <Form.Control
              as="select"
              defaultValue={d.status || "NOT_ATTEMPTED"}
              onChange={(e) => setStatus(d, e.target.value)}
            >
              {containerStatus.map((it, index) => (
                <option key={`containerStatus-${index}`} value={it.ref}>
                  {it.text}
                </option>
              ))}
            </Form.Control>
          </InputGroup>
        </>,
      ],
      cellExport: (d) => containerStatus.find((x) => x.ref === d.status)?.text,
    },
  ];

  const fetchData = async (page, per_page) => {
    const containerApi = await getLearningEnrollments({
      offset: page - 1,
      limit: per_page,
      idTeam: idTeam,
    });
    const containerFilterApi = containerApi.result.map((item) => {
      item.enrollDate = moment(item.enrollDate).utc().format("DD/MM/YYYY");
      item.progress = item.progress > 0 ? item.progress + "%" : 0;
      return item;
    });

    // console.log(containerApi)

    setListM(containerFilterApi);
    setTotalRows(containerFilterApi.length);
  };

  const tableData = {
    columns: columns,
    data: listM,
  };

  return (
    <>
      <div className="header-title-wrapper">
        <div className="header-title">
          <button
            className="btnGoBack"
            onClick={() => {
              props.history.goBack();
            }}
          >
            <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
          </button>
          Gestão de Turmas - {title && title}
        </div>
      </div>

      <div className="post-form">
        <div className="content-page-form-group">
          <div className="content-page-card">
            <div
              style={{ backgroundColor: "#6b48ff" }}
              className="card-header template-card-header"
            >
              Status dos alunos
            </div>

            {totalRows > 0 ? (
              <div className="card-body card-title-body template-card-body">
                <DataTableExtensions
                  filter={true}
                  filterPlaceholder={"filtro"}
                  print={false}
                  {...tableData}
                  exportHeaders={true}
                >
                  <DataTable
                    columns={columns}
                    data={listM}
                    pagination
                    className={""}
                    progressPending={listM.length <= 0 ? true : false}
                    progressComponent={
                      <Loading containerStyle={{ backgroundColor: "transparent" }} />
                    }
                    noHeader={true}
                    noDataComponent="Não foi encontrado nenhum dado"
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    defaultSortField="name"
                    sortIcon={<SortIcon />}
                    // defaultSortAsc={true}
                    dense
                  />
                </DataTableExtensions>
              </div>
            ) : totalRows === 0 ? (
              <p className="statusStudentsAlert">Nenhum aluno matriculado.</p>
            ) : (
              <p className="statusStudentsAlert">Carregando...</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(StatusStudents);
