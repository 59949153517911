import React, { useCallback, useEffect, useMemo, useState } from "react";
import HeaderNavigation from "components/PeopleCraft/HeaderNavigation";
import "./styles.scss";
import { getTerms } from "services/NexiDigitalAPI";
import EmptyRegister from "./components/EmptyRegister";
import TableTerms from "./components/TableTerms/TableTerms";
import { Skeleton } from "@material-ui/lab";
import {Toast, ToastContainer} from "./components/Toast";
import { useHistory } from "react-router-dom";
import "moment/locale/pt-br";
const PlatformTerms = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [loadingTable, setLoadingTable] = useState([]);
  const [firstSearch, setFirstSeach] = useState(true);
  const [totalRecords, setTotalRecords] = useState(999999);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });

  const history  = useHistory();
  const successRoute = history.location.state?.success


  const _getData = useCallback(async () => {
    totalRecords === 999999 ? setLoading(true) :  setLoadingTable(true)
    try {
      const result = await getTerms(pagination);
      setData(result.data.result);
      !Boolean(result.data.result.length) && setFirstSeach(true)
      setTotalRecords(result.data.totalRecords);

    }  finally {
      totalRecords === 999999  ?  setLoading(false) :  setLoadingTable(false);
    }
  }, [ pagination]);

  const onChangePage = (page) => {
    setPagination((prev) => ({ ...prev, offset: page === 1 ? 0 : (page - 1) * prev.limit }));
  };

  const onChangeRowsPerPage = (limit, page) => {
    setPagination((prev) => ({ ...prev, offset: page === 1 ? 0 : (page - 1) * limit , limit}));

  };

  useEffect(() => {
    if( successRoute ){
      Toast()
    }
    _getData();
  }, [_getData]);



  return (
    <div className="PlatformTerms">
      <ToastContainer />
      <HeaderNavigation title={"Termos da plataforma"} goBack={() => history.push('/')}/>
      <h4 className="title">Termos de Responsabilidade</h4>
      <span className="subtitle">
        Nesta área você poderá gerenciar, editar e personalizar os termos de responsabilidade da sua
        plataforma.
      </span>
      {loading ? (
        <Skeleton variant="rect" width={"100%"} height={200} />
      ) : firstSearch && !data.length ? (
        <EmptyRegister />
      ) : (
        <TableTerms
          data={data}
          pagination={pagination}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          loading={loadingTable}
          totalRecords={totalRecords}
          search={_getData}
        />
      )}
    </div>
  );
};

export default PlatformTerms;
