import "./style.modules.scss";

import React, { useState, useEffect } from "react";

import { OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import SortIcon from "@material-ui/icons/ArrowDownward";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import { withRouter, useParams } from "react-router-dom";

import { createTeams, getLearningNode, getlistClass } from "../../../../services/NexiDigitalAPI";
import Loading from "../../../../components/Loading";

import { BsPencilSquare, BsFillPeopleFill, BsListCheck } from "react-icons/bs";

import "./style.modules.scss";
import { CircularProgress } from "@material-ui/core";
import PreloaderBlur from "../../PreloaderBlur";
import { format } from "date-fns";

const ListClass = (props) => {
  const { nodeCode, title } = useParams();

  const [nodeInfo, setNodeInfo] = useState(null);
  const [listM, setListM] = useState(null);
  //const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  // const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10000);
  const [alertshow, setAlert] = useState(0);
  const [loadingCreate, setLoadingCreate] = useState(false);

  // useEffect(() => {
  //   if (isLoaded) fetchData(1, 1000);
  // }, []);

  useEffect(() => {
    let componentMounted = true; // Uma variável local para rastrear se o componente ainda está montado

    const loadInitialData = async () => {
      if (nodeInfo === null) {
        setNodeInfo((await getLearningNode(nodeCode, true)).data.result);
      }

      if (listM === null) {
        getlistClass({
          nodeCode: nodeCode,
        }).then((response) => {
          if (componentMounted) {
            const containerApi = response;
            const containerFilterApi = containerApi.map((item) => {
              item.status = status.filter((st) => st.ref === item.status)[0].text;
              return item;
            });

            setListM(containerFilterApi);

            if (containerFilterApi.length > 0) {
              setAlert(1);
            }
          }
        });
      }
    };

    loadInitialData();

    return () => {
      componentMounted = false; // Marque o componente como desmontado ao sair do useEffect
    };
  }, [listM]);

  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const newClassCreate = async () => {
    const contentDataNew = {
      name: null,
      description: "Descrição da turma",
      initialDate: null,
      finalDate: null,
      duration: 0,
      enrollments: 0,
      allowAccessUponCompletion: false,
      allowReenrollment: false,
      selfEnrollment: false,
      nodeCode,
    };

    setLoadingCreate(true);
    await createTeams({ data: contentDataNew })
      .then((response) => {
        const idTeam = response.data.idTeam;

        if (idTeam) {
          props.history.push(`/create-new-class/${nodeCode}/${title}/${idTeam}`);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoadingCreate(false);
      });
  };

  const editClassCreate = (d) => {
    props.history.push(`/create-new-class/${nodeCode}/${title}/${d.idTeam}`);
  };

  const statusStudents = (d) => {
    props.history.push(`/status-students/${nodeCode}/${title}/${d.idTeam}`);
  };

  const attendanceList = (d) => {
    props.history.push(`/attendance-list/${d.idTeam}/${title}`);
  };

  const gotoLM = () => {
    props.history.goBack();
  };

  const columns = [
    {
      name: "Nome da turma",
      selector: "name",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    // {
    //   name: "Data da última atualização",
    //   selector: "finalDate",
    //   sortable: true
    // },
    {
      name: "Quantidade de usuário",
      selector: "enrollments",
      sortable: true,
    },
    {
      name: "Ações",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <div key={d.idTeam} className="listBtn">
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip id={`tooltip-top`}> Editar turma </Tooltip>}
          >
            <button
              type="button"
              onClick={() => {
                editClassCreate(d);
              }}
            >
              <BsPencilSquare />
            </button>
          </OverlayTrigger>

          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip id={`tooltip-top`}> Status dos alunos </Tooltip>}
          >
            <button
              type="button"
              onClick={() => {
                statusStudents(d);
              }}
            >
              <BsFillPeopleFill />
            </button>
          </OverlayTrigger>

          {d.meetings.length > 0 && (
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip id={`tooltip-top`}> Lista de presença </Tooltip>}
            >
              <button
                type="button"
                onClick={() => {
                  attendanceList(d);
                }}
              >
                <BsListCheck />
              </button>
            </OverlayTrigger>
          )}
        </div>,
      ],
    },
  ];

  const status = [
    { ref: "NOT_STARTED", text: "Não iniciada" },
    { ref: "STARTED", text: "Em andamento" },
    { ref: "FINISHED", text: "Concluída" },
  ];

  const fetchData = async (page, per_page) => {
    const containerApi = await getlistClass({
      offset: page - 1,
      limit: per_page,
      nodeCode: nodeCode,
    });
    const containerFilterApi = containerApi.map((item) => {
      item.status = status.filter((st) => st.ref === item.status)[0].text;
      return item;
    });

    setListM(containerFilterApi);

    if (containerFilterApi.length > 0) {
      setAlert(1);
    }

    setIsLoaded(false);

    //console.log(containerFilterApi);
    // setTotalRows(containerFilterApi.length);
  };

  const tableData = {
    columns: columns,
    data: listM,
  };

  return (
    <>
      {listM === null && <PreloaderBlur />}

      <div className="header-title-wrapper">
        <div className="header-title">
          <button
            className="btnGoBack"
            onClick={() => {
              props.history.goBack();
            }}
          >
            <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
          </button>
          Gestão de Turmas - {nodeInfo?.title}
        </div>
      </div>

      <div className="post-form">
        <div className="content-page-form-group">
          <div className="content-page-card">
            <div
              style={{ backgroundColor: "#6b48ff" }}
              className="card-header template-card-header"
            >
              Lista de turmas
            </div>

            <div style={{ padding: "20px" }}>
              {nodeInfo?.status === "PUBLISHED" && (
                <>
                  <Button variant="success" onClick={newClassCreate}>
                    Criar Turma
                    {loadingCreate && (
                      <CircularProgress
                        className="content-page-button-icon ml-2"
                        size={16}
                        style={{ color: "white" }}
                      />
                    )}
                  </Button>
                </>
              )}
            </div>

            <div className="card-body card-title-body template-card-body">
              {alertshow === 1 && (
                <DataTableExtensions
                  filter={true}
                  filterPlaceholder={"filtro"}
                  print={false}
                  {...tableData}
                  exportHeaders={true}
                >
                  <DataTable
                    columns={columns}
                    data={listM}
                    pagination
                    className={""}
                    progressPending={listM.length <= 0 ? true : false}
                    progressComponent={
                      <Loading containerStyle={{ backgroundColor: "transparent" }} />
                    }
                    noHeader={true}
                    noDataComponent="Não foi encontrado nenhum dado"
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    defaultSortField="name"
                    sortIcon={<SortIcon />}
                    // defaultSortAsc={true}
                    dense
                  />
                </DataTableExtensions>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <Modal show={ (alertshow === 1) ? true : false } onHide={()=>{gotoLM()}}>
          <Modal.Header style={{ backgroundColor: "#6b48ff" }} closeButton>
            <Modal.Title style={{ color: "#fff", fontWeight: 900 }}>
              {
                <h5 style={{ color: "#fff", fontWeight: 900 }}>
                  Nenhuma turma Cadastrada!
                </h5>
              }

            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {
              (alertshow === 1)&&(
                <>

                  <div style={{ display:'flex', justifyContent:'center' }}>
                    <button
                      type="button"
                      className="btn content-page-button prop-4"
                      style={{ backgroundColor: "#0c8b5f" }}
                      onClick={()=>{gotoLM()}}
                    >
                      Confirmar
                    </button>
                  </div>
                </>
              )
            }



          </Modal.Body>
        </Modal> */}
    </>
  );
};

export default withRouter(ListClass);
