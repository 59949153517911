import React from "react";
import { PiCheckCircleFill, PiCheck } from "react-icons/pi";

import "./style.scss";
import { Feature, Product } from "../../model/ManagerTrial.model";

type Props = {
  text?: string;
  className?: string;
  features: Feature[];
  products: Product[];
  showDetail?: Boolean;
};

const CheckListUpgrade = ({
  className = "",
  features = [],
  products = [],
  showDetail = false,
}: Props) => {
  const RowCheckCircleWithLabel = ({
    label = "",
    products = [],
  }: {
    label: string;
    products?: Product[];
  }) => (
    <div className="columnList">
      <div className="rowCheck checkCircleWithLabel">
        <PiCheckCircleFill />
        <span>{label}</span>
      </div>
      {showDetail &&
        products?.map((elem, index) => (
          <RowCheckWithLabel key={`benefits-${index}`} text={elem.name} />
        ))}
    </div>
  );
  const RowCheckWithLabel = ({ text = "" }: { text: string }) => (
    <div className=" rowCheck checkWithLabel">
      <PiCheck />
      <span>{text}</span>
    </div>
  );

  return (
    <div className="pctCheckList">
      {features.map((item, index) => (
        <RowCheckCircleWithLabel
          key={`row-check-${index}`}
          label={item.description}
        />
      ))}
      {products.length > 0 && (
        <RowCheckCircleWithLabel
          label={`Tenha acesso as bibliotecas`}
          products={products}
        />
      )}
    </div>
  );
};

export default CheckListUpgrade;
