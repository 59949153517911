import React, { useState, useEffect } from "react";
import ResumeBlock from "../ResumeBlock";
import NexiDigitalAPI from "../../../../../services/NexiDigitalAPI";
import "moment/locale/pt-br.js";

const ResumeCards = ({ dateStart, dateFinish, idGroupGlobal }) => {
  const [loading, setLoading] = useState(false);
  const [contentSelected, setContentSelected] = useState(0);
  const [sectionSelected, setSectionSelected] = useState(0);
  const [groupSelected, setGroupSelected] = useState(0);
  const [reportStatistics, setReportStatistics] = useState({
    avgSessionTime: 0,
    avgSessionsByUser: 0,
    totalSessions: 0,
    totalUniqueSessions: 0,
  });

  const refreshData = () => {
    const filterParams = {
      dateStart: dateStart,
      dateFinish: dateFinish,
      idContentItem: contentSelected,
      idSection: sectionSelected,
      idGroup: groupSelected,
    };
    if (idGroupGlobal) {
      filterParams.idGroup = parseInt(idGroupGlobal);
    }
    NexiDigitalAPI.post(`/reports/statisticsaccess`, filterParams)
      .then((response) => {
        setLoading(false);
        if (response.data.success === true) {
          const { success, ...statistics } = response.data;
          setReportStatistics(statistics);
        }
      })
      .catch((err) => {
        setLoading(false);
        throw err;
      });
  };

  useEffect(() => {
    refreshData();
  }, []);
  useEffect(() => {
    refreshData();
  }, [dateStart, dateFinish]);

  const loadingMinimalComponent = () => (
    <div style={{ height: 60, paddingTop: 10 }}>
      <div
        style={{
          borderTopColor: "white",
          position: "initial",
        }}
        className="loader loader-medium"
      ></div>
    </div>
  );

  return (
    <>
      <ResumeBlock
        label="Usuários"
        data={reportStatistics.totalUniqueSessions.toString() || loadingMinimalComponent()}
        percentage={reportStatistics.percenttotalUniqueSession || 0}
      />
      <ResumeBlock
        label="Visitas"
        data={reportStatistics.totalSessions.toString() || loadingMinimalComponent()}
        percentage={reportStatistics.percentTotalSessions || 0}
      />
      <ResumeBlock
        label="Duração da visita"
        data={reportStatistics.avgSessionTime.toString() || loadingMinimalComponent()}
        percentage={reportStatistics.percentavgSessionTime || 0}
      />
      <ResumeBlock
        label="Duração média de usuário"
        data={
          reportStatistics.avgSessionsByUser.toString()
            ? reportStatistics.avgSessionsByUser.toFixed(2)
            : loadingMinimalComponent()
        }
        percentage={reportStatistics.percentavgSessionsByUser || 0}
      />
    </>
  );
};

export default ResumeCards;
