import { useState } from "react";

import { ModelHeader } from "../Utils/ModelHeader";
import { AttendanceListRecursive } from "../Utils/AttendanceListRecursive";
import { CreatePDF } from "../Utils/CreatePDF";

const useListBlenderPDF = (props) => {
  const { turmaTitle, pageTitle, currentMeeting, idTeam, idMeeting } = props;

  const [preloaderListBlender, setPreloaderListBlender] = useState(false);

  const createListBlender = () => {
    setPreloaderListBlender(true);
    AttendanceListRecursive(idTeam, idMeeting, (list) => {
      const modelHeader = ModelHeader(turmaTitle, pageTitle, currentMeeting);
      CreatePDF(modelHeader, list);
      setTimeout(() => {
        setPreloaderListBlender(false);
      }, 1000 * 0.2);
    });
  };

  return { createListBlender, preloaderListBlender };
};

export default useListBlenderPDF;
