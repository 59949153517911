import "./styles.modules.scss";

import { CircularProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Loading from "components/Loading";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { BsFilter } from "react-icons/bs";
import { useParams } from "react-router";
import {
  addQuestionToEvaluation,
  getEvaluationQuestions,
  getLearningQuestions,
} from "services/NexiDigitalAPI";

import { removeHtmlTags, getQuestionTypeName } from "utils/Utils";

import Options from "./Components/Options";

const NoData = () => {
  return <div className="noData">Nenhum registro encontrado</div>;
};

const HighlightText = ({ highlight, value }) => {
  return <div>{getHighlightedText(value, highlight)}</div>;
};

function getHighlightedText(text, highlight) {
  // Split text on highlight term, include term itself into parts, ignore case
  var parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return parts.map((part, index) => (
    <React.Fragment key={index}>
      {part.toLowerCase() === highlight.toLowerCase() ? (
        <b style={{ backgroundColor: "#e8bb49" }}>{part}</b>
      ) : (
        part
      )}
    </React.Fragment>
  ));
}

const customTableStyles = {
  rows: {
    style: {
      minHeight: "auto",
      padding: "5px 0px",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      textAlign: "center",
      fontSize: "14px",
      fontWeight: "bold",
    },
  },
};

const paginationComponentOptions = {
  rowsPerPageText: "Conteúdos por página",
  rangeSeparatorText: "de",
};

const KEModalQuestionBank = ({ show, setShow, setQuestionsList, evaluacionType }) => {
  const { idEvaluation, evaluationType } = useParams();
  const [availableQuestionsList, setAvailableQuestionsList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [type, setType] = useState("");
  const [selectedIdQuestion, setSelectedIdQuestion] = useState(null);
  const [loadingImport, setLoadingImport] = useState(false);
  const [loadingAvailableQuestionList, setLoadingAvailableQuestionList] = useState(false);
  const [importError, setImportError] = useState("");
  const [offset, setOffset] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const columns = [
    {
      name: "",
      selector: "checkbox",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      width: "50px",
      cell: (question) => (
        <div className="ml-1">
          <Form.Check
            name="group1"
            type={"radio"}
            id={`inline-question`}
            checked={question.idQuestion === selectedIdQuestion}
            onChange={(e) => {
              e.target.checked
                ? setSelectedIdQuestion(question.idQuestion)
                : setSelectedIdQuestion(null);
            }}
          />
        </div>
      ),
    },
    {
      name: "Enunciado",
      selector: "statement",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (question) => (
        <HighlightText value={removeHtmlTags(question.statement)} highlight={searchString} />
      ),
    },
    {
      name: "Categorização",
      sortable: false,
      selector: "categories",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (question) => (
        <div className="categItns">
          {question.categories?.map((category, index) => (
            <span key={index}>{category.title}</span>
          ))}
        </div>
      ),
    },
    {
      name: "Tags",
      sortable: false,
      selector: "tags",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (question) => (
        <div>
          {question.tags?.map((tag, index) => (
            <div className="tagItns">
              <span key={index}>{tag.tag}</span>
            </div>
          ))}
        </div>
      ),
    },
    {
      name: "Tipo da questão",
      sortable: false,
      selector: "questionType",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (question) => <div>{getQuestionTypeName(question.questionType)}</div>,
    },
  ];

  const getAvailableQuestions = async (newOffset, limit) => {
    setSelectedIdQuestion("");
    setLoadingAvailableQuestionList(true);

    const haveNewOffset = newOffset || newOffset === 0;
    if (haveNewOffset) {
      setOffset(newOffset);
    }

    const questionsParameters = {
      evaluationType,
      idEvaluation,
      searchString,
      offset: haveNewOffset ? newOffset : offset,
      limit: limit ? limit : pageLimit,
      type,
    };
    const questionsData = await getLearningQuestions(questionsParameters).catch((err) => {
      console.log("error", err);
    });
    setLoadingAvailableQuestionList(false);
    if (questionsData) {
      setAvailableQuestionsList(questionsData?.result);
      setTotalRecords(questionsData.totalRecords);
    }
  };

  const importQuestions = async () => {
    setImportError("");
    setLoadingImport(true);
    const importQuestionsParameters = {
      idEvaluation,
      idQuestion: selectedIdQuestion,
    };

    await addQuestionToEvaluation(importQuestionsParameters)
      .then(async () => {
        let questionsData = await getEvaluationQuestions(idEvaluation);

        if (questionsData.data) {
          const formattedQuestionData = questionsData.data.map((question) => ({
            ...question,
            statement: removeHtmlTags(question.statement),
          }));

          console.log("formattedQuestionData", formattedQuestionData);

          setQuestionsList(formattedQuestionData);
        }

        setSelectedIdQuestion(null);
        handleDeny();
      })
      .catch((err) => {
        console.log("error", err.response?.data?.message);
        setImportError("Erro: Não foi possivel importar a questão.");
      });

    setLoadingImport(false);
  };

  const onChangePage = (page) => {
    const newOffset = (page - 1) * pageLimit;
    getAvailableQuestions(newOffset);
  };

  const onChangeRowsPerPage = (limit, page) => {
    setPageLimit(limit);
    const newOffset = (page - 1) * limit;
    getAvailableQuestions(newOffset, limit);
  };

  useEffect(() => {
    if (show) {
      getAvailableQuestions(0, 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleDeny = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      className="ModalQuestionBank"
      centered
      size="xl"
      onHide={() => {}}
    >
      <div className="modal-support-blur modal-support-blur-warning" style={{ borderRadius: 10 }} />
      <div
        onClick={() => handleDeny()}
        className="modal-warning-close-container"
        style={{ margin: 15, padding: 0, backgroundColor: "#6b48ff" }}
      >
        <CloseIcon style={{ color: "white" }} className="modal-warning-close" />
      </div>
      <Modal.Title style={{ textAlign: "left", fontSize: 18, marginTop: 15, marginLeft: 20 }}>
        Banco de questões
      </Modal.Title>
      <div className="modal-principal-warning">
        <div className="modal-body-warning">
          <div className="form-group form-group-block-02">
            <div className="card">
              <div className="card-body template-card-body">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="containInfo">
                      <div className="searchInfo">
                        <AiOutlineSearch />
                        <Form.Control
                          value={searchString}
                          onKeyDown={(input) => {
                            if (input.key === "Enter") {
                              getAvailableQuestions();
                            }
                          }}
                          onChange={(e) => setSearchString(e.target.value)}
                          placeholder={`Pesquisar...`}
                        />
                        <button onClick={() => setSearchString("")}>
                          <AiOutlineClose />
                        </button>
                      </div>
                      <div className="typeQuestion">
                        <Form.Control
                          className=""
                          as="select"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        >
                          <Options evaluacionType={evaluacionType} />
                        </Form.Control>
                      </div>
                      <Button variant="primary" onClick={() => getAvailableQuestions()}>
                        <BsFilter />
                        Filtrar
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group form-group-block-02">
            <div className="card">
              <div className="card-body template-card-body">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="node-manager-import-table-wrapper">
                      {loadingAvailableQuestionList && availableQuestionsList?.length > 0 && (
                        <div className="KELoadingBackdrop">
                          <Loading>Carregando questões...</Loading>
                        </div>
                      )}
                      <DataTable
                        columns={columns}
                        data={availableQuestionsList}
                        customStyles={customTableStyles}
                        progressComponent={
                          <Loading containerStyle={{ height: 400 }}>Carregando questões...</Loading>
                        }
                        noDataComponent={<NoData />}
                        onChangePage={onChangePage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        paginationTotalRows={totalRecords}
                        paginationComponentOptions={paginationComponentOptions}
                        progressPending={
                          loadingAvailableQuestionList && availableQuestionsList?.length === 0
                        }
                        paginationServer
                        noHeader
                        fixedHeader
                        pagination
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="containerBtn">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 baseBtns">
                <div className="errorText">{importError}</div>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    handleDeny();
                  }}
                  style={{ zIndex: 10 }}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={!Boolean(selectedIdQuestion)}
                  variant="outline-primary"
                  onClick={importQuestions}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Importar {loadingImport && <CircularProgress size={15} className="ml-1" />}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default KEModalQuestionBank;
