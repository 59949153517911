import React from "react";
import { getZoomUrl } from "../../utils/Utils";

const ZoomMeetings = (props) => {
  const zoom_auth_url = getZoomUrl();
  //const [zoom_auth_url, setZom_auth_url] = useState( process.env.ZOOM_AUTH_URL );

  return (
    <div>
      <div className="template-header">
        <h3>Meetings</h3>
      </div>

      <div className="card">
        <div className="card-header template-card-header">
          <h4>Zoom</h4>
        </div>
        <div className="card-body template-card-body">
          <h5>Quer usar o zoom para agendar reuniões no nosso app?</h5>
          <p>Você será redirecionado para o site do Zoom para fazer login</p>
        </div>
        <div className="card-footer template-card-header">
          <a className="btn btn-lg btn-primary" href={zoom_auth_url}>
            Login no Zoom
          </a>
        </div>
      </div>
    </div>
  );
};

export default ZoomMeetings;
