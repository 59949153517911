import React from "react";
import { Select, MenuItem } from "@material-ui/core";

const withoutLms = [
  {
    name: "Áudio",
    value: "1",
  },
  {
    name: "Vídeo",
    value: "2",
  },
  {
    name: "Post",
    value: "3",
  },
  {
    name: "Youtube Playlist",
    value: "7",
  },
  {
    name: "Arquivo",
    value: "18",
  },
];
const withLms = [
  {
    name: "Áudio",
    value: "1",
  },
  {
    name: "Vídeo",
    value: "2",
  },
  {
    name: "Pacote HTML",
    value: "4",
  },
  {
    name: "Artigo",
    value: "5",
  },
  {
    name: "Arquivo",
    value: "18",
  },
  {
    name: "SCORM",
    value: "26",
  },
  {
    name: "Link Externo",
    value: "27",
  },
];

const CmsType = ({ contentType, setContentType, isLms }) => {
  return (
    <div className="form-group">
      <div className="card">
        <div className="card-header template-card-header">Tipo de formato</div>
        <div className="card-body template-card-body">
          <div className="row">
            <div className="col-md-12">
              <Select
                label="Tipo de formato"
                onChange={(e) => {
                  setContentType(e.target.value);
                }}
                value={`${contentType}`}
                variant="filled"
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  border: "1px solid rgba(0, 0, 0, 0.125)",
                }}
                placeholder="Opções"
              >
                <MenuItem selected value={"19"} disabled>
                  <span style={{ color: "rgba(0,0,0,0.5)" }}>Opções</span>
                </MenuItem>
                {isLms
                  ? withLms.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.name}
                      </MenuItem>
                    ))
                  : withoutLms.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CmsType;
