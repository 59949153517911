import moment from "moment";
import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import styles from "./styles.module.scss";

const Table = ({ dados, loading, totalRecords, actionFilter, trainingsValue }) => {
  const statusFormater = (status) => {
    switch (status) {
      case "NOT_ATTEMPTED":
        return "Não iniciado";
      case "IN_PROGRESS":
        return "Em progresso";
      case "COMPLETED":
        return "Concluído";
      case "PASSED":
        return "Aprovado";
      case "FAILED":
        return "Reprovado";
      case "EXPIRED":
        return "Expirado";
      case "APPROVED":
        return "Aprovado";
      case "DRAFT":
        return "Rascunho";
      case "ARCHIVED":
        return "Arquivado";
      case "REJECTED":
        return "Reprovado";
      case "DISAPPROVED":
        return "Reprovado";
      case "PUBLISHED":
        return "Publicado";
      default:
    }
  };

  const ValidateData = (_status, res = null) => {
    if (_status) {
      return <span>{res ? res : _status}</span>;
    }

    return <span> - </span>;
  };

  const columns = [
    {
      name: "Título da página",
      selector: "title",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.title),
    },
    {
      name: "Data de criação da página",
      selector: "creationDate",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.creationDate, moment(d.creationDate).format("DD/MM/YYYY")),
    },
    {
      name: "Nível",
      selector: "nodeLevel",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.nodeLevel),
    },
    {
      name: "Rótulo",
      selector: "label",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.label),
    },
    {
      name: "Tags",
      selector: "tags",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.tags),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.status, statusFormater(d.status)),
    },
    {
      name: "Data de alteração do status (quando foi publicado ou arquivado)",
      selector: "statusDate",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.statusDate, moment(d.statusDate).format("DD/MM/YYYY")),
    },
    {
      name: "# Páginas filhas",
      selector: "totalTeams",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.totalTeams),
    },

    {
      name: "Título página pai",
      selector: "parentTitle",
      sortable: true,
      center: true,
      cell: (d) => ValidateData(d.parentTitle),
    },
    {
      name: "Carga horária",
      selector: "workload",
      sortable: true,
      center: true,
      cell: (d) => (
        <span>
          {d.workload > 0
            ? new Date(d.workload * 1000).toISOString().substring(11, 16)
            : d.workload || ""}
        </span>
      ),
    },
    {
      name: "Certificado",
      selector: "idCertificate",
      sortable: true,
      center: true,
      cell: (d) => ValidateData(d.idCertificate),
    },
    {
      name: "Permite matrículas nessa página",
      selector: "entryPoint",
      sortable: true,
      center: true,
      cell: (d) => ValidateData(d.entryPoint, Boolean(d.entryPoint) ? "SIM" : "NÃO"),
    },
    {
      name: "Permite automatrícula sem turma",
      selector: "selfEnrollment",
      sortable: true,
      center: true,
      cell: (d) => ValidateData(d.selfEnrollment, Boolean(d.selfEnrollment) ? "SIM" : "NÃO"),
    },
    {
      name: "Quantidade de turmas",
      selector: "totalTeams",
      sortable: true,
      center: true,
      cell: (d) => ValidateData(d.totalTeams),
    },
  ];

  const customTableStyles = {
    rows: {
      style: {
        paddingTop: "10px",
        paddingBottom: "10px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        paddingTop: "10px",
        paddingBottom: "10px",
        textAlign: "center",
        width: "150px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        textAlign: "center",
      },
    },
    header: {
      style: {
        fontSize: "22px",
      },
    },
  };

  const tableData = {
    columns: columns,
    data: dados,
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Usuários por página",
    rangeSeparatorText: "de",
  };

  const onChangePage = (page) => {
    //setPagination((prev) => ({ ...prev, offset: --page * prev.limit }));

    const offset = --page * trainingsValue.limit;
    actionFilter({ ...trainingsValue, offset: offset });
  };

  const onChangeRowsPerPage = (limit) => {
    actionFilter({ ...trainingsValue, offset: 0, limit: limit });
    //setPagination((prev) => ({ ...prev, limit, offset: 0 }));
  };

  const loadingComponent = () => {
    return (
      <div className="tela-login-loading">
        <div className="loader-container" style={{ margin: "300px 0" }}>
          <div
            style={{
              position: "initial",
              margin: "initial",
            }}
            className="loader loader-center loader-big"
          ></div>
          <span
            style={{
              marginTop: 10,
              fontSize: 20,
            }}
          >
            Carregando dados...
          </span>
        </div>
      </div>
    );
  };

  return (
    <DataTableExtensions
      filter={false}
      print={false}
      {...tableData}
      exportHeaders={false}
      export={false}
    >
      <DataTable
        columns={columns}
        data={dados}
        className={styles.dataTableFormat}
        customStyles={customTableStyles}
        progressPending={loading}
        progressComponent={loadingComponent()}
        noHeader={true}
        noDataComponent="Não foi encontrado nenhum dado"
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        paginationTotalRows={totalRecords}
        paginationComponentOptions={paginationComponentOptions}
        paginationServer
        pagination
      />
    </DataTableExtensions>
  );
};

export default Table;
