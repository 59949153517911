import { deleteCategoriesService, getCategoriesService, postCategoriesService } from "services/NexiDigitalAPI";

export function postCategoriesPromises(path, objNode, pathGet, call) {
  // Defining our first promise
  let firstPromise = () => {
    return new Promise((resolve, reject) => {
      resolve(postCategoriesService(path,  objNode));
    });
  };

  // Defining our second promise
  let secondPromise = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(getCategoriesService(pathGet)), 1000);
    });
  };

  // Async function to perform execution of all promise
  (async () => {
    try {
      let promise = await Promise.all([firstPromise(), secondPromise()]);
      call(promise);
    } catch (e) {
      console.log("Error no POST do Categories: " +  e);
    }
  })();
}

export function deleteCategoriesPromises(path,pathGet,call) {
  // Defining our first promise
  let firstPromise = () => {
    return new Promise((resolve, reject) => {
      resolve(deleteCategoriesService(path));
    });
  };

  // Defining our second promise
  let secondPromise = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(getCategoriesService(pathGet)), 1000);
    });
  };

  // Async function to perform execution of all promise
  (async () => {
    try {
      let promise = await Promise.all([firstPromise(), secondPromise()]);
      call(promise);
    } catch (e) {
      console.log("Error no DELETE do Categories: " , e);
    }
  })();


}

export function getCategories(path,call) {


  // Async function to perform execution of all promise
  (async () => {
    try {
      let promise = await getCategoriesService(path);
      call(promise);
    } catch (e) {
      console.log("Error ao Buscar do Categories: " , e);
    }
  })();
}
