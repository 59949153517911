import JoditEditorContent from "components/jodit";
import React from "react";

const CmsSinglePage = ({ idContentItem, setContent }) => {
  return (
    <div className="form-group">
      <div className="card">
        <div className="card-header template-card-header" children="Conteúdo" />
        <div className="card-body template-card-body">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <JoditEditorContent
                content={""}
                onChange={(e) => setContent(e)}
                loading={() => {}}
                idContentItem={idContentItem}
              ></JoditEditorContent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CmsSinglePage;
