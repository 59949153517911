import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { PAContainerContext, PAContext } from "./Contexts/PAContext";
import GroupsCreator from "./Components/GroupsCreator";
import GroupsEditor from "./Components/GroupsEditor";
import GroupsRules from "./Components/GroupsRules";

const Groups = (props) => {
  const { currentComponentType } = props;

  return (
    <PAContext>
      <PAContainerContext>
        <div className="groups">
          {currentComponentType === "default" && <GroupsCreator />}

          {currentComponentType === "edit" && <GroupsEditor />}

          {currentComponentType === "rules" && <GroupsRules />}
        </div>
      </PAContainerContext>
    </PAContext>
  );
};

export default Groups;
