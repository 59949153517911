import React, { useState, useEffect } from "react";

// Page UI and icons
import { Col, Form } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import "rc-datepicker/lib/style.css";
import { getMenuData } from "../../../../../services/NexiDigitalAPI";
import FiltroWrapper from "../../../Components/FiltroWrapper";
import styles from "./styles.module.scss";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import CompaniesModal from "../../../../../components/CompaniesModal/CompaniesModal";

const Filtro = ({ actionFilter, loading, downloadEvent , expanded}) => {
  const adminLevel = getMenuData()?.admin;
  const initialFormStates = {
    idCompany: getMenuData().idCompany,
    offset: 0,
    limit: 10,
  };
  const [selectedCompany, setSelectedCompany] = useState({});
  const [formData, setFormData] = useState(initialFormStates);

  function handleFilter() {
    if (selectedCompany) {
      return actionFilter({
        ...formData,
        idTeam: formData.idTeam === "all" ? undefined : formData.idTeam,
        idCompany: selectedCompany.idCompany === "all" ? undefined : selectedCompany.idCompany,
      });
    }
  }

  function isAdminSecondDegree() {
    return adminLevel >= 2;
  }

  function updateStates() {
    setFormData(initialFormStates);
  }

  useEffect(() => {
    if(expanded){
      updateStates();
      selectedCompany && handleFilter();
    }

  }, [selectedCompany, expanded]);

  return (
    <FiltroWrapper>
      <Form style={{ marginTop: 30, marginBottom: 30 }}>
        <Form.Row className="align-items-end">
          <Form.Row className="col-md-12" style={{ padding: 5 }}>
            <CompaniesModal
              showAllCompaniesOption
              showInput={isAdminSecondDegree()}
              setSelectedCompany={setSelectedCompany}
              selectedCompany={selectedCompany}
            />
          </Form.Row>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Group className="mb-0">
              <ButtonWithLoading
                onClickHandler={handleFilter}
                loading={loading}
                text={
                  <>
                    Aplicar filtro <FaFilter style={{ marginLeft: 5 }} />{" "}
                  </>
                }
                style={{ width: "100%" }}
              />
            </Form.Group>
          </Col><Col xs="12" md="4" lg="3" className={styles.formSpace}>
            <Form.Group className="mb-0">
              <ButtonWithLoading
                onClickHandler={downloadEvent}
                loading={loading}
                text={<>Download da planilha</>}
                style={{ width: "100%", backgroundColor: "#0d6efd", borderColor: "#0d6efd" }}
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    </FiltroWrapper>
  );
};

export default Filtro;
