import React, { useState } from "react";
import "../index.css";

import { Form, InputGroup } from "react-bootstrap";
import { editLearningBlockParameter } from "../../../../services/NexiDigitalAPI";

import { ReactComponent as ProgressIcon } from "../../../../assets/images/bars-progress-solid.svg";

export const InProgressCard = ({ hasMaxCards, blockData }) => {
  const [maxCards, setMaxCards] = useState(blockData?.parameters?.displayColumnsElements || "5");

  const handleChangeParameter = (value, parameterName) => {
    setMaxCards(value);
    const editParameterData = {
      name: parameterName,
      value,
    };
    editLearningBlockParameter(blockData.idBlock, editParameterData).catch((err) => {
      console.log("Error edit parameter", err);
    });
  };

  return (
    <div style={{ flex: 1, padding: "15px 0px" }}>
      {/* {hasMaxCards && (
        <InputGroup className="mb-3" style={{ width: 330 }}>
          <InputGroup.Prepend>
            <InputGroup.Text style={{ color: "white", backgroundColor: "#6b48ff" }}>
              Qtde. máxima por tela
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            as="select"
            value={maxCards}
            onChange={(e) => handleChangeParameter(e.target.value, "displayColumnsElements")}
          >
            {Array(5)
              .fill()
              .map((item, index) => (
                <option key={`node-manager-page-${index}`} value={String(index + 1)}>
                  {index + 1} Card{index >= 1 && "s"}
                </option>
              ))}
          </Form.Control>
        </InputGroup>
      )} */}
      <div className="in-progress-card-container">
        <div className="in-progress-card-icon">
          <ProgressIcon style={{ fill: "#eea125", width: 40, height: 40 }} />
        </div>
        <div>
          <div style={{ fontSize: 18, fontWeight: "bold" }}>Em andamento</div>
          <div style={{ color: "rgb(163, 163, 163)" }}>
            Habilita a visualização de todos os treinamentos em progresso na plataforma.
          </div>
          <div style={{ color: "rgb(163, 163, 163)" }}>
            Aproveite a funcionalidade para otimizar a experiência de aprendizagem.
          </div>
        </div>
      </div>
    </div>
  );
};
