import React, {   useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { ContentData, StatusTypes } from "../../nodeParamenters.model";
import CharacterCounter from "components/PeopleCraft/CharacterCounter";
import Switch from "react-bootstrap/esm/Switch";
import moment from "moment";
import Label from "../../../Label";

import { ParametersJoditSyllabus } from "utils/Utils";
import JoditEditorContent from "components/jodit";

interface BasicInformationProps {
  contentData: ContentData;
  handleChange: (data: { [key: string]: number | string | string[] | boolean }) => void;
  handleBlur: (
    data: { [key: string]: number | string | string[] | boolean },
    required?: boolean,
  ) => void;
  editNodeCode: boolean;
  certificates: any[];
  nodeLevels: any[];
  disabled: boolean
}

const BasicInformation: React.FC<BasicInformationProps> = ({
  contentData,
  handleChange,
  handleBlur,
  editNodeCode,
  certificates,
  nodeLevels,
  disabled = false
}) => {
  const [workloadHour, setWorkloadHour] = useState<number>(null);
  const [workloadMinute, setWorkloadMinute] = useState<number>(null);
  const [contentDataChange, setContentDataChange] = useState<ContentData>(contentData);

  const parentContentData = contentData.parentNodes ? contentData?.parentNodes[contentData.parentNodes?.length - 1] as ContentData : contentData;
  const firstUpdate = useRef(true)

  const handleWorkloadHour = (
    hour: number,
  ) => {
    setWorkloadHour(hour);
  };

  const _handleBlur = (hour, minute) => {
    const workloadValue = calculateWorkloadValue(hour, minute);
    handleBlur({ workload: workloadValue });
  }

  const handleWorkloadMinute = (
    minute: number,
  ) => {
    setWorkloadMinute(minute);
  };

  const updateChange = (payload) => {
    setContentDataChange((prevState) => ({...prevState,...payload }))
  }

  const calculateWorkloadValue = (hour: number, minute: number) => {
    const hourDuration = moment.duration(hour, "hours").asSeconds();
    const minuteDuration = moment.duration(minute, "minutes").asSeconds();
    return hourDuration + minuteDuration;
  };

  const transformWorkload = (workload) => {
    const hours = Math.floor(workload / 3600);
    workload %= 3600;
    let minutes = Math.floor(workload / 60);
    minutes = Math.min(minutes, 59);
    setWorkloadHour(hours);
    setWorkloadMinute(minutes);
  };

  const renderNodeLevelsOptions = () => {
    if (nodeLevels?.length === 0) {
      return <option value={0}>Carregando...</option>;
    }

    return nodeLevels.map((nodeLevel, index) => (
      <option key={`node-level-${index}`} value={nodeLevel.nodeLevel}>
        {nodeLevel.name}
      </option>
    ));
  };

  const renderCertificatesOptions = () => {
    return (
      <>
        <option key="certificate-0" value={"0"}>
          {"Sem certificado"}
        </option>

        {certificates?.map((certificate) => (
          <option key={certificate.idCertificate} value={certificate.idCertificate}>
            {certificate.title}
          </option>
        ))}
      </>
    );
  };

  useEffect(() => {
    if(contentData.workload && firstUpdate.current){
      firstUpdate.current = false
      transformWorkload(contentData.workload)
    }
    return () => {}
  }, [contentData.workload])

  return (
    <div className="flex-column">
      <div className="form-data">
        <Label required={true} name="Título" htmlFor="title" />
        <input
          key={"titulo"}
          placeholder="Título"
          required
          value={contentDataChange.title || null}

          disabled={disabled || contentData?.status === StatusTypes.ARCHIVED}
          onChange={(e) => updateChange({ title: e.target.value })}
          onBlur={(e: any) => {
            handleBlur({ title: e.target.value }, true);
          }}
          id="title"
          maxLength={100}
          className="form-control"
          type="text"
        />
        <CharacterCounter characterNumber={contentDataChange.title?.length} maxLength={100} />
      </div>
      <div className="form-data">
        <Label required={true} name="Título curto" htmlFor="shortTitle" />
        <input
          required
          disabled={ disabled ||  contentData?.status === StatusTypes.ARCHIVED}
          onBlur={(e) => {
            handleBlur({ shortTitle: e.target.value }, true);
          }}
          onChange={(e: any) => {
            updateChange({ shortTitle: e.target.value });
          }}
          value={contentDataChange.shortTitle || null}
          maxLength={20}
          className="form-control"
          type="text"
        />
        <CharacterCounter characterNumber={contentDataChange.shortTitle?.length} maxLength={20} />
      </div>

      <div className="form-data">
        <Label name="Descrição" htmlFor="description" />
        <textarea
          rows={4}
          id="description"
          aria-describedby="description"
          disabled={ disabled ||  contentDataChange?.status === StatusTypes.ARCHIVED}
          onBlur={(e) => {
            handleBlur({ description: e.target.value });
          }}
          onChange={(e: any) => {

            updateChange({ description: e.target.value });
          }}
          value={contentDataChange.description || ""}
          maxLength={1000}
          className="form-control"
        />
        <CharacterCounter characterNumber={contentDataChange.description?.length} maxLength={1000} />
      </div>

      {contentData.nodeType === "LEARNING" && (
        <div className="form-data form-data-syllabus">
          <Label name="Ementa" htmlFor="syllabus" />
          <JoditEditorContent
          disabled={disabled}
            content={contentData.syllabus|| null}
            onBlur={(richText, e) => {
              handleBlur({ syllabus:richText})
            }}
            customJodit={ParametersJoditSyllabus()}
            className={"joditEditorContent"}
          />
          {/* <CharacterCounter characterNumber={contentData.syllabus?.length} maxLength={null} /> */}
        </div>
      )}

      <div className="form-data">
        <Label required={true} name="Nível de conteúdo" htmlFor="title" />
        <Form.Control
          disabled={disabled || (contentData?.hasChildren && editNodeCode)}
          as="select"
          value={contentData?.nodeLevel}

          onChange={(e: any) => {
            handleBlur({
              nodeLevel: e.target.value
            });
          }}
        >
          {renderNodeLevelsOptions()}
        </Form.Control>
      </div>
      <div className="form-data">
        <Label required={true} name="Rótulo de conteúdo" htmlFor="label" />
        <input
          key={"label"}
          placeholder="Rótulo"
          required
          value={contentDataChange?.label || ""}
          disabled={disabled || contentDataChange?.status === StatusTypes.ARCHIVED}
          onBlur={(e: any) => {
            handleBlur({ label: e.target.value }, true);
          }}
          onChange={(e: any) => {
            updateChange({ label: e.target.value });
          }}
          maxLength={30}
          className="form-control"
          type="text"
        />

        <CharacterCounter characterNumber={contentDataChange.label?.length} maxLength={30} />
      </div>
      {(contentData.nodeType === "LEARNING") && (
        <>
          <div className="form-data">
            <Label name="Modalidade da página" htmlFor="modality" />
            <Form.Control
              disabled={disabled}
              as="select"
              value={contentData.modality}
              onChange={(e: any) => {
                handleBlur({ modality: e.target.value });
              }}
            >
              <option value={"ASYNCHRONOUS"}>Assíncrono</option>
              <option value={"BLENDED"}>Blended</option>
            </Form.Control>
          </div>
          <div className="form-data row">
            <div className="col-12">
              <Label name="Carga Horária" htmlFor="modality" />
            </div>
            <div className="form-group col-6">
              <input
                value={workloadHour || ""}
                disabled={disabled || contentData?.status === StatusTypes.ARCHIVED}
                className="form-control"
                maxLength={2}
                placeholder="Horas"
                type="number"
                id="node-horas"
                aria-describedby="node-horas"
                onChange={(e) => handleWorkloadHour(Number(e.target.value))}
                onBlur={(e) => _handleBlur(Number(e.target.value), Number(workloadMinute))}

              />
            </div>
            <div className="form-group col-6">
              <input
                value={workloadMinute || ""}
                disabled={disabled || contentData?.status === StatusTypes.ARCHIVED}
                className="form-control"
                maxLength={15}
                placeholder="Minutos"
                type="number"
                id="node-horas"
                aria-describedby="node-minutes"
                onChange={(e) => handleWorkloadMinute(Number(e.target.value))}
                onBlur={(e) => _handleBlur( Number(workloadHour),Number(e.target.value))}
              />
            </div>
          </div>
        </>
      )}

    {(contentData.nodeType === "LEARNING") && (
      <div className={`form-data flex-row ${contentData?.status === StatusTypes.PUBLISHED || contentData?.status === StatusTypes.ARCHIVED || parentContentData.nodeType !== "LEARNING" ? 'switch-disabled': ''}`}>
        <Switch
          color="primary"
          id="entryPoint"
          checked={contentData.entryPoint}

          onChange={(e: any) => {
            handleBlur({
              selfEnrollment: e.target.checked ? contentData.selfEnrollment : false,
              entryPoint: e.target.checked ? 1 : 0,
            });
          }}
          disabled={disabled || contentData?.status === StatusTypes.PUBLISHED || contentData?.status === StatusTypes.ARCHIVED || parentContentData.nodeType !== "LEARNING"}
        />
        <Label className={"cursor-pointer "} name="Permitir matrículas nessa página" htmlFor="entryPoint" />
      </div>
    )}

      {(contentData.modality !== "BLENDED" && contentData.nodeType === "LEARNING") && (
        <div className={`form-data flex-row ${!contentData.entryPoint ? 'switch-disabled' : ''}` }>
          <Switch
            color="primary"
            id="selfEnrollment"
            checked={contentData.selfEnrollment}
            disabled={disabled || !contentData.entryPoint}

            onChange={(e) =>
              handleBlur({
                selfEnrollment: e.target.checked,
              })
            }
          />
          <Label
            className={"cursor-pointer"}
            name="Permitir automatrícula sem turma"
            htmlFor="selfEnrollment"
          />
        </div>
      )}

      {contentData.selfEnrollment && (
        <>
          <div className="form-data">
            <Label name="Certificado" htmlFor="idCertificate" />
            <Form.Control
              disabled={disabled || contentData?.status === StatusTypes.ARCHIVED}
              as="select"
              value={contentData.idCertificate || "0"}
              onChange={(e) => {
                handleBlur({ idCertificate: e.target.value !== "0" ? e.target.value : null });
              }}
            >
              {renderCertificatesOptions()}
            </Form.Control>
          </div>
          <div className={`form-data flex-row ${contentData?.status === StatusTypes.ARCHIVED ? 'switch-disabled' : ''}`}>
            <Switch
              color="primary"
              id="enableCertificateForChildren"
              checked={contentData.enableCertificateForChildren}
              onChange={(e: any) => {
                handleBlur({ enableCertificateForChildren: e.target.checked });
              }}
              disabled={disabled || contentData?.status === StatusTypes.ARCHIVED}
            />
            <Label
              className={"cursor-pointer"}
              name="Habilitar a geração do certificado para os níveis abaixo dentro da página"
              htmlFor="enableCertificateForChildren"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default BasicInformation;
