import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { getColors } from "../utils/Utils";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "assets/images/close-icon-menu.svg";
import { AuthContext } from "context/AuthContext";

type Props = {
  title?: string;
  icon?: React.ReactNode;
  description?: React.ReactNode | string;
  acceptText?: string;
  acceptAction?: false | (() => void);
  denyAction?: false | (() => void);
  denyText?: string;
  noDenyButton?: boolean;
};

function ModalWarning({
  title,
  icon,
  description,
  acceptText,
  acceptAction = false,
  denyAction = false,
  denyText,
  noDenyButton = false
}: Props) {
  const { loginData } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(true);

  function handleDeny() {
    if (denyAction) {
      denyAction();
    }
    setShowModal(false);
  }

  function handleAccept() {
    if (acceptAction) {
      acceptAction();
    }
    setShowModal(false);
  }

  return (
    <Modal
      show={showModal}
      onHide={() => handleDeny()}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      style={{zIndex: 1101}}
    >
      <div className="modal-support-blur modal-support-blur-warning" />
      <Modal.Header
        style={{
          backgroundColor: loginData?.primaryColor ?? getColors("menu").thirdColor,
        }}
        className="modal-header-warning"
      >
        <div onClick={() => handleDeny()} className="modal-warning-close-container">
          <img src={CloseIcon} className="modal-warning-close" alt="img" />
        </div>
        {icon || <InfoIcon style={{ color: "white", fontSize: 50 }} />}
      </Modal.Header>
      <div className="modal-principal-warning" style={{ padding: "30px 0px" }}>
        <Modal.Title style={{ textAlign: "center", fontSize: 18 }}>{title}</Modal.Title>
        <div className="modal-body-warning" style={{ margin: "20px 0" }}>
          {description}
        </div>
      </div>
      <Modal.Footer className="modal-footer-warning">
        {!noDenyButton && 
          <Button style={{ flex: 1 }} variant="secondary" onClick={() => handleDeny()}>
            {denyText || "Sair"}
          </Button>
        }
        <Button
          style={{
            flex: 1,
            backgroundColor: loginData?.primaryColor ?? getColors("menu").thirdColor,
            border: "none",
          }}
          className="btn botao-login"
          onClick={() => handleAccept()}
        >
          {acceptText || "Continuar"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalWarning;
