import "./styles.scss";

import React, { useState } from "react";

import { Button, Form, Modal, FormGroup } from "react-bootstrap";

import { GoAlert } from "react-icons/go";

const ModalDeleteSingle = ({ show, setShow, deleteEvent }) => {
  function handleDeny() {
    setShow(false);
  }

  return (
    <Modal
      show={show}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      className={`ModalDilogComponent`}
      centered
      size="xl"
      onHide={() => {}}
    >
      <div className="modal-support-blur modal-support-blur-warning" style={{ borderRadius: 5 }} />
      <div className="modal-principal-warning">
        <div className="modal-body-warning">
          <div className="form-group form-group-block-02">
            <div className="card">
              <div className="card-body template-card-body">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="containInfo">
                      <div className="blockAlert">
                        <GoAlert />
                      </div>
                      <div className="containText">
                        <h3>Tem certeza que deseja excluir o certificado?</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="containerBtn">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 baseBtns">
                <>
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      handleDeny();
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="danger"
                    // className={btnCall.className}
                    onClick={() => {
                      deleteEvent();
                      setShow(false);
                    }}
                  >
                    Excluir
                  </Button>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDeleteSingle;
