import React, { useState, useEffect } from "react";

import moment from "moment";
import "moment/locale/pt-br.js";

// Page UI and icons
import { Col, Form } from "react-bootstrap";
import { FaFilter, FaSearch } from "react-icons/fa";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import { getMenuData, getEntryPoints, getlistClass } from "../../../../../services/NexiDigitalAPI";
import FiltroWrapper from "../../../Components/FiltroWrapper";
import styles from "./styles.module.scss";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import CompaniesModal from "../../../../../components/CompaniesModal/CompaniesModal";
import CatalogModal from "containers/PeopleCraft/NodeManagerV2/CatalogModal";

const Filtro = ({ actionFilter, loading, expanded, downloadEvent }) => {
  const [values, setValues] = useState({
    startDate: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
    finishDate: moment(new Date()).format("YYYY-MM-DD"),
    idCompany: getMenuData().idCompany,
    name: "",
    offset: 0,
    limit: 10,
    idTeam: 'all',
    nodeCode: ''
  });

  const [selectedCompany, setSelectedCompany] = useState({});
  const adminLevel = getMenuData()?.admin;

  function handleFilter() {
    if (selectedCompany) {
      return actionFilter({
        ...values,
        idCompany: selectedCompany.idCompany === "all" ? undefined : selectedCompany.idCompany,
        idTeam: values.idTeam === "all" ? undefined : values.idTeam,
      });
    }
    actionFilter(values);
  }

  function handleDateStart(jsDate) {
    let date = moment(jsDate).format("YYYY-MM-DD");
    setValues({
      ...values,
      startDate: date,
    });
  }

  function handleDateFinish(jsDate) {
    let date = moment(jsDate).format("YYYY-MM-DD");
    setValues({
      ...values,
      finishDate: date,
    });
  }

  const [selectedNode, setSelectedNode] = useState();
  const handleSelectedNode = async (node) => {
    setSelectedNode(node);
    setValues((prevData) => ({ ...prevData, nodeCode: node.nodeCode, }));
    getLearnings({ nodeCode: node.nodeCode });
  }

  const handleChangeSelect = async ({ target }) => {
    const { name, value } = target;
    if (name === "trainings") {
      setValues((prevData) => ({
        ...prevData,
        nodeCode: value,
      }));
      if (value) {
        getLearnings({ nodeCode: value });
      }
    }
    if (name === "learnings") {
      setValues((prevData) => ({
        ...prevData,
        idTeam: value,
      }));
    }
  };

  function isAdminSecondDegree() {
    return adminLevel >= 2;
  }

  const [learnings, setLearnings] = useState([{ value: "all", label: "Todas" }]);

  async function getLearnings(nodeCode) {
    try {
      const learningRes = await getlistClass(nodeCode);

      if (learningRes.length) {
        const formatedData = learningRes.map(({ idTeam, name }) => {
          return {
            value: idTeam,
            label: name,
          };
        });
        return setLearnings(formatedData);
      }
      return setLearnings([]);
    } catch (err) {
      console.log(err);
    } finally {
    }
  }

  const [showCatalog, setShowCatalog] = useState(false);
  return (
    <FiltroWrapper>
      <Form style={{ marginTop: 30, marginBottom: 30 }}>
        <Form.Row className="align-items-end">
          <Form.Row className="col-md-12 mb-2" style={{ padding: 5 }}>
            <CompaniesModal
              showAllCompaniesOption
              showInput={isAdminSecondDegree()}
              setSelectedCompany={setSelectedCompany}
              selectedCompany={selectedCompany}
            />
          </Form.Row>
          <Col xs="6" md="6" lg="6" className={[styles.formSpace, 'mb-2']}>
            <Form.Label htmlFor="learnings">Treinamento*</Form.Label>
            <div className="input-group" style={{ minWidth: "350px", display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                className="form-control"
                id="trainning-input-name"
                aria-describedby="trainning-input-name"
                value={
                  selectedNode
                    ? `${selectedNode.nodeCode} - ${selectedNode.title}`
                    : "Selecionar treinamento"
                }
                style={{ marginLeft: "-3px", zIndex: 1, cursor: 'pointer' }}
                disabled
              />
              <FaSearch style={{ fontSize: '20px', marginLeft: 5 }}  onClick={() => setShowCatalog(true)}/>
            </div>
            {showCatalog && <CatalogModal
              denyAction={() => setShowCatalog(false)}
              returnNodeData={handleSelectedNode}
              isSimpleSelect={true}
              nodeType={"LEARNING"}
              onlyEntrypoints={true}
            />}
          </Col>
          <Col xs="12" md="6" lg="6" className={[styles.formSpace, 'mb-2']}>
            <Form.Label htmlFor="learnings">Turma</Form.Label>
            <Form.Control
              id="learnings"
              name="learnings"
              as="select"
              value={values.idTeam}
              onChange={handleChangeSelect}
            >
              <option value={"all"}>Todas</option>
              {learnings.map(({ label, value }) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col xs="12" md="4" lg="3" className={styles.formSpace}>
            <Form.Label htmlFor="startDate">Data de Início</Form.Label>
            <DatePickerInput locale="pt-br" onChange={handleDateStart} value={values.startDate} />
          </Col>
          <Col xs="12" md="4" lg="3" className={styles.formSpace}>
            <Form.Label htmlFor="endDate">Data de Término</Form.Label>
            <DatePickerInput locale="pt-br" onChange={handleDateFinish} value={values.finishDate} />
          </Col>
          <Col xs="12" md="4" lg="3" className={styles.formSpace}>
            <Form.Group className="mb-0">
              <ButtonWithLoading
                disabled={!selectedNode}
                onClickHandler={handleFilter}
                loading={loading}
                text={
                  <>
                    Aplicar filtro <FaFilter style={{ marginLeft: 5 }} />{" "}
                  </>
                }
                style={{ width: "100%" }}
              />
            </Form.Group>
          </Col>
          <Col xs="12" md="4" lg="3" className={styles.formSpace}>
            <Form.Group className="mb-0">
              <ButtonWithLoading
                disabled={!selectedNode}
                onClickHandler={downloadEvent}
                loading={loading}
                text={<>Download da planilha</>}
                style={{ width: "100%", backgroundColor: "#0d6efd", borderColor: "#0d6efd" }}
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    </FiltroWrapper>
  );
};

export default Filtro;
