import FormCharacterCounterRemaning from "components/FormCharacterCounterRemaning";
import { AuthContext } from "context/AuthContext";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { updateContactAndSupportEmailTemplate } from "services/NexiDigitalAPI";
import "./styles.scss";

const ContactAndSupportModal = (props) => {
  const { show, hide, emailTemplate } = props;
  const { loginData } = useContext(AuthContext);
  const secondaryColor = loginData.secondaryColor;

  const [formData, setFormData] = useState({ ...emailTemplate });
  const [validated, setValidated] = useState(false);

  const formRef = useRef(null);

  const onCancel = () => {
    hide();
    setFormData({ ...emailTemplate });
    setValidated(false);
  };

  const onSave = async () => {
    await updateContactAndSupportEmailTemplate(formData);
    hide();
    setValidated(false);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      onSave();
    }
  };

  useEffect(() => {
    setFormData({ ...emailTemplate });
    setValidated(false);
  }, [emailTemplate]);

  return (
    <Modal
      show={show}
      onHide={onCancel}
      animation={false}
      size="lg"
      className="contact-and-support-modal"
    >
      <Modal.Body>
        <div className="card">
          <div
            className="card-header template-card-header"
            style={{ color: "#000", backgroundColor: secondaryColor }}
          >
            Personalização e Configuração de Contato e Suporte
          </div>

          <div className="card-body template-card-body">
            <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef}>
              <Form.Label className="form-section">Página de contato e suporte</Form.Label>
              <Form.Group controlId="title">
                <Form.Label>Título exibido</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Fale conosco"
                  required
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  maxLength="100"
                />
                <FormCharacterCounterRemaning
                  limit={100}
                  value={formData.title}
                  color={secondaryColor}
                />
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Olá! Se você precisa entrar em contato conosco sobre nossa plataforma LMS, estamos aqui para ajudar. Por favor, preencha o formulário abaixo com suas perguntas, comentários ou preocupações, e entraremos em contato com você o mais breve possível."
                  required
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  maxLength="255"
                />
                <FormCharacterCounterRemaning
                  limit={255}
                  value={formData.description}
                  color={secondaryColor}
                />
              </Form.Group>
              <Form.Group controlId="feedback">
                <Form.Label>Mensagem final</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Obrigado pela sua mensagem! Nossa equipe de especialistas entrará em contato com você em breve."
                  required
                  value={formData.feedbackMessage}
                  onChange={(e) => setFormData({ ...formData, feedbackMessage: e.target.value })}
                  maxLength="255"
                />
                <FormCharacterCounterRemaning
                  limit={255}
                  value={formData.feedbackMessage}
                  color={secondaryColor}
                />
              </Form.Group>

              <Form.Label className="form-section">Configurações do sistema</Form.Label>
              <Form.Group controlId="mailto">
                <Form.Label>Endereço de e-mail do destinatário</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="suporte@lerninghub.com"
                  required
                  value={formData.to}
                  onChange={(e) => setFormData({ ...formData, to: e.target.value })}
                  maxLength="100"
                />
                <FormCharacterCounterRemaning
                  limit={100}
                  value={formData.to}
                  color={secondaryColor}
                />
              </Form.Group>
              <Form.Group controlId="subject">
                <Form.Label>Assunto</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Fale com a Learning Hub"
                  required
                  value={formData.subject}
                  onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                  maxLength="100"
                />
                <FormCharacterCounterRemaning
                  limit={100}
                  value={formData.subject}
                  color={secondaryColor}
                />
              </Form.Group>
            </Form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel} size="sm">
          Cancelar
        </Button>
        <Button
          style={{
            borderColor: secondaryColor,
            backgroundColor: secondaryColor,
            color: "#000",
          }}
          onClick={() => formRef?.current?.requestSubmit()}
          size="sm"
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactAndSupportModal;
