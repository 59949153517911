import React from "react"
import { ChangeEvent, FC } from "react"
import { Form } from "react-bootstrap"
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai"

import "./styles.scss"
type Props = {
  textSearch: string,
  handleChangeName: (event: ChangeEvent<HTMLInputElement>) => void,
  cleanSearch: () => void,
}
const InputFilter: FC<Props> = ({ textSearch, handleChangeName, cleanSearch }) => {
  return (
    <div className="input-filter">
      <div className="searchInfo">
        <AiOutlineSearch />
        <Form.Control
          id="searchInput"
          value={textSearch}
          onChange={handleChangeName}
          placeholder={`Pesquisar por nome...`}
        />
        {textSearch && <button
          className="cleanSearch"
          onClick={cleanSearch}
        >
          <AiOutlineClose />
        </button>}
      </div>
    </div>
  )
}

export default InputFilter
