import "./styles.modules.scss";

import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";

import ModalDialog from "../../../Modals/ModalDialog";
import { RECreateContext } from "../../Contexts/REContext";

const Footer = (props) => {
  const { submitEvaluation } = useContext(RECreateContext);

  const [showOut, setShowOut] = useState(false);

  return (
    <div className="KEFooter">
      <Button
        variant="outline-secondary"
        className=""
        onClick={(e) => {
          setShowOut(true);
        }}
      >
        Voltar
      </Button>
      <Button
        variant="warning"
        className=""
        onClick={(e) => {
          submitEvaluation();
        }}
      >
        Salvar e fechar
      </Button>

      <ModalDialog
        text={
          "Se sair agora, todas as alterações realizadas serão perdidas. Tem certeza que deseja sair?"
        }
        show={showOut}
        setShow={setShowOut}
        btnCall={{
          className: "",
          variantBtn: "warning",
          text: "Sair sem salvar",
          callEvent: () => {
            props.history.goBack();
          },
        }}
      />
    </div>
  );
};

export default withRouter(Footer);
