import React from "react";
import "./styles.scss";

import parse from "html-react-parser";

import { useManagerTrial } from "../../context/contextManagerTrial";
import Button from "components/PeopleCraft/Button";
import { DialogProps } from "../../model/ManagerTrial.model";


const DialogUpgrade = ({}) => {
  const { dialogPlan } = useManagerTrial();
  const { title, subTitle, buttonCancel, buttonConfirm } =
    dialogPlan as DialogProps;

  return (
    <div className="dialogBase">
      <div className="baseInfoTitle">
        {title ? <h3>{parse(title)}</h3> : <></>}
        {subTitle ? <p>{parse(subTitle)}</p> : <></>}
      </div>
      <div
        className={`buttonsWrapper ${
          buttonCancel && buttonConfirm ? "buttonsWrapperRight" : ""
        }`}
      >
        {buttonCancel && (
          <Button
            callEvent={buttonCancel.callEvent}
            className={`button01 ${buttonCancel?.class}`}
            text={`${buttonCancel.text}`}
          />
        )}

        {buttonConfirm && (
          <Button
            callEvent={buttonConfirm.callEvent}
            className={`button06 ${buttonConfirm?.class}`}
            text={`${buttonConfirm.text}`}
          />
        )}
      </div>
    </div>
  );
};

export default DialogUpgrade;
