import "./styles.scss";

import React, { useContext } from "react";
import { withRouter } from "react-router-dom";

import { Field, ErrorMessage } from "formik";
import { RECreateContext } from "../../Contexts/REContext";

const GeneralSettingsImpact = (props) => {
  const { evaluationForm } = props;
  const { currentEvaluation, setCurrentEvaluation } = useContext(RECreateContext);

  const disableEdit = currentEvaluation?.hasQuestionnaire;

  const handleChange = React.useCallback((event) => {
    evaluationForm.current?.handleChange(event);

    const currentTarget = event.currentTarget;
    const target = event.target;
    setTimeout(() => {
      if (currentTarget.value) {
        setCurrentEvaluation((it) => ({
          ...it,
          [target.name]: currentTarget.value,
        }));
      }
    }, 1000 * 0.1);
  }, []);

  return (
    <div className={`generalSettingsImpact ${disableEdit ? "disableEdit" : ""}`}>
      <div className="KEForm KEFormConfig">
        <div className="mr-3">
          <div className="kEInfoLabel">
            <label>
              Quantidade de dias, após o término do curso, para habilitação da avaliação:
            </label>
          </div>

          <Field
            type="number"
            min={1}
            max={60}
            className="form-control"
            aria-describedby="Quantidade de dias, após o término do curso, para habilitação da avaliação."
            placeholder="30"
            name="availableAfterDays"
            form="novalidatedform"
            onChange={handleChange}
            disabled={disableEdit}
          />
          <ErrorMessage
            name="availableAfterDays"
            render={(msg) => <div className={"errorMessage"}>{msg}</div>}
          />
        </div>

        <div className="mr-3">
          <div className="kEInfoLabel">
            <label>
              Quantidade de dias que a avaliação ficará disponível para o gestor após o término do
              curso:
            </label>
          </div>

          <Field
            type="number"
            min={1}
            max={30}
            className="form-control"
            aria-describedby="Quantidade de dias que a avaliação ficará disponível para o gestor após o término do
              curso"
            placeholder="1"
            name="availableUntilDays"
            form="novalidatedform"
            onChange={handleChange}
            disabled={disableEdit}
          />
          <ErrorMessage
            name="availableUntilDays"
            render={(msg) => <div className={"errorMessage"}>{msg}</div>}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(GeneralSettingsImpact);
