export function filterElemType(item) {
  let elemType = "";

  if (item.mod === 0) {
    elemType = "INIT";
  } else if (item.elementType && item.elementType.toUpperCase() === "NODE") {
    elemType = "NODE";
  } else if (item.elementType && item.elementType.toUpperCase() === "CONTENT") {
    elemType = "CONTENT";
  } else if (item.elementType && item.elementType.toUpperCase() === "EVALUATION") {
    elemType = "EVALUATION";
  } else if (item.nodeType && item.nodeType.toUpperCase() === "LEARNING") {
    elemType = "LEARNING";
  }

  return elemType;
}
