import React from "react";
import "./style.scss";

const MovementStatus = ({ title }) => {
  let colors = {
    "Em andamento": "emAndamento",
    "Pendente": "pendente",
    "Completo": "completo",
    "Completo com erros": "completoComErros",
    "Erro": "erro",
    "Criado": "criado",
    "-": "none",
  };
  return <div className={"movementStatusItem " + colors[title]}>{title}</div>;
};

export default MovementStatus;
