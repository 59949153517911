import React, { FC } from "react"
import { PiWarningFill } from "react-icons/pi"
import "./styles.scss"
type Props = {
  message:string
  click: (event: any) => void
}

const NotificationTeam: FC<Props> = ({message, click}) => {

  return (
    <div className="notification" onClick={click}>
      <PiWarningFill />
      <div className="message-container">
        <span>{message}</span>
        <span className="more-details">  Mais detalhes</span>
      </div>

    </div>
  )
}

export default NotificationTeam
