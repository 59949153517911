import React from "react";

const CmsYoutube = ({ contentLocation, setContentLocation }) => {
  return (
    <div className="form-group">
      <div className="card">
        <div className="card-header template-card-header">Youtube</div>
        <div className="card-body template-card-body">
          <div className="form-row">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="autor">
                  Link para o Conteúdo
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                value={contentLocation}
                onChange={(e) => setContentLocation(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CmsYoutube;
