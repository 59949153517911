import "./styles.modules.scss";

import React from "react";
import { withRouter } from "react-router-dom";

import ManagerBase from "./Components/ManagerBase";

import REContext from "./Contexts/REContext";
import { REContainerContext } from "./Contexts/REContext";

const EvaluationManager = (props: any) => {
  return (
    <>
      <div className="ReactionEvaluation">
        <REContext>
          <REContainerContext>
            <ManagerBase />
          </REContainerContext>
        </REContext>
      </div>
    </>
  );
};

export default withRouter(EvaluationManager);
