import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@material-ui/icons/Close";

import TemplateSelector from "../../cms/TemplateSelector";
import { deleteContent } from "services/NexiDigitalAPI";
import ModalWarning from "components/ModalWarning";

function ContentModal({ title, denyAction = false, idContentItem, returnContentData }) {
  const [showModal, setShowModal] = useState(true);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const handleNodeData = (nodeData) => {
    returnContentData(nodeData);
    setShowModal(false);
  };

  function handleDeny() {
    if (denyAction) {
      denyAction();
      return;
    }
    setShowModal(false);
  }

  function _deleteContent() {
    deleteContent(idContentItem);
    handleDeny();
  }

  const closeModal = () => {
    handleDeny();
  };

  return (
    <Modal
      show={showModal}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="xl"
      enforceFocus={false}
      onHide={() => {}}
    >
      <div className="modal-support-blur modal-support-blur-warning" style={{ borderRadius: 10 }} />
      <div
        onClick={() => setShowWarningModal(true)}
        className="modal-warning-close-container"
        style={{ margin: 15, padding: 0, backgroundColor: "#6b48ff" }}
      >
        <CloseIcon style={{ color: "white" }} className="modal-warning-close" />
      </div>
      <div className="modal-principal-warning" style={{ minHeight: 400 }}>
        {showWarningModal && (
          <ModalWarning
            description={
              <div style={{ textAlign: "center" }}>
                Tem certeza que deseja continuar? As informações serão perdidas.
              </div>
            }
            acceptAction={() => {
              _deleteContent();
              setShowWarningModal(false);
            }}
            denyAction={() => setShowWarningModal(false)}
            denyText="Cancelar"
          />
        )}
        <Modal.Title style={{ textAlign: "center", fontSize: 18, marginTop: 15 }}>
          {title}
        </Modal.Title>
        <div className="modal-body-warning" style={{ padding: 20 }}>
          <TemplateSelector
            idContentItem={idContentItem}
            isNode
            returnNodeData={handleNodeData}
            closeModal={closeModal}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ContentModal;
