import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import styles from "./styles.module.scss";
import moment from "moment";

const Table = ({ dados, loading, actionFilter, totalRecords  }) => {
  const columns = [
    {
      name: "Usuário",
      selector: "name",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Login",
      selector: "login",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "E-mail corporativo",
      selector: "email",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Código do colaborador",
      selector: "employeeCode",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Usuário ativo",
      selector: "active",
      sortable: true,
      center: true,
      cell: (d) => <span>{Boolean(d.active) ? "SIM" : "NÃO"}</span>,
    },
    { name: "Empresa", selector: "company", sortable: true, center: true },
    {
      name: "Login do Gestor",
      selector: "managerLogin",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    { name: "Departamento", selector: "department", sortable: true, center: true },
    { name: "Unidade", selector: "unit", sortable: true, center: true },
    { name: "Cargo", selector: "role", sortable: true, center: true },
    {
      name: "Data de criação",
      selector: "creationDate",
      sortable: true,
      center: true,
      cell: (d) => <span>{d.creationDate ? moment(d.creationDate).format("DD/MM/YYYY") : '-'}</span>,
    },
    {
      name: "Data e hora do ultimo acesso",
      selector: "lastAccess",
      sortable: true,
      center: true,
      cell: (d) => <span>{d.lastAccess ? moment(d.lastAccess).format('DD/MM/YYYY HH:mm') : '-'}</span>,
    },
    {
      name: "Administrador",
      selector: "admin",
      sortable: true,
      center: true,
      cell: (d) => <span>{Boolean(d.admin) ? "SIM" : "NÃO"}</span>,
    },
    {
      name: "Aceite aos termos",
      selector: "userLicenceTermsAccepted",
      sortable: true,
      center: true,
      cell: (d) => <span>{Boolean(d.userLicenceTermsAccepted) ? "SIM" : "NÃO"}</span>,
    },
    {
      name: "Aceite a comunicação",
      selector: "communicationAccepted",
      sortable: true,
      center: true,
      cell: (d) => <span>{Boolean(d.communicationAccepted) ? "SIM" : "NÃO"}</span>,
    },
  ];

  const customTableStyles = {
    rows: {
      style: {
        minHeight: "60px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "30px", // override the cell padding for head cells
        textAlign: "center",
      },
    },
    cells: {
      style: {
        paddingLeft: "30px", // override the cell padding for data cells
      },
    },
    header: {
      style: {
        fontSize: "22px",
        minHeight: "56px",
        paddingLeft: "28px",
        paddingRight: "8px",
        paddingTop: "30px",
        paddingBottom: "15px",
      },
    },
  };

  const tableData = {
    columns: columns,
    data: dados,
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Usuários por página",
    rangeSeparatorText: "de",
  };

  const onChangePage = (page, totalRows) => {
    actionFilter({ offset: --page * dados.length });
  };

  const onChangeRowsPerPage = (limit) => {
    actionFilter({ limit, offset: 0 });
  };

  const loadingComponent = () => {
    return (
      <div className="tela-login-loading">
        <div className="loader-container" style={{ margin: "300px 0" }}>
          <div
            style={{
              position: "initial",
              margin: "initial",
            }}
            className="loader loader-center loader-big"
          ></div>
          <span
            style={{
              marginTop: 10,
              fontSize: 20,
            }}
          >
            Carregando dados...
          </span>
        </div>
      </div>
    );
  };

  return (
    <DataTableExtensions filter={false} print={false} {...tableData} exportHeaders={false} export={false}>
      <DataTable
        columns={columns}
        data={dados}
        className={styles.dataTableFormat}
        customStyles={customTableStyles}
        progressPending={loading}
        progressComponent={loadingComponent()}
        noHeader={true}
        noDataComponent="Não foi encontrado nenhum dado"
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        paginationTotalRows={totalRecords}
        paginationComponentOptions={paginationComponentOptions}
        paginationServer
        pagination
      />
    </DataTableExtensions>
  );
};

export default Table;
