import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { ContentData, Notification, Options } from "../../nodeParamenters.model";
import Switch from "react-bootstrap/esm/Switch";
import Label from "components/PeopleCraft/Label";
import { Form } from "react-bootstrap";
import CustomTooltip from "components/CustomTooltip";
import { BiSolidInfoCircle } from "react-icons/bi";
import {
  getImpactEvaluationParams,
  getLearningEvaluations,
  getLearningEvaluationsAll,
  putImpactEvaluationParams,
  putLearningConfiguration,
} from "services/NexiDigitalAPI";
import PlaceholderParameters from "containers/PeopleCraft/LearningManagementNew/Components/DynamicParameters/components/PlaceholderParameters";
type Props = {
  contentData: ContentData;
  handleChange: (data: { [key: string]: number | string | string[] | boolean }) => void;
  handleBlur: (data: { [key: string]: number | string | string[] | boolean }) => void;
};

type Item = {
  idEvaluationTypeParameter: number;
  parameterValue: string | number;
  restrictions: string;
};

const Input = ({ item, key, values, disabled, handleChangeLimit, putImpactEvaluation }) => {
  return (
    <div className="form-data col-12" key={key}>
      <Label name={item?.label} htmlFor={key} />
      <input
        value={values[item.idEvaluationTypeParameter]?.parameterValue}
        disabled={disabled}
        className="form-control col-4"
        maxLength={2}
        type="number"
        id={item.idEvaluationTypeParameter}
        onChange={(e) => handleChangeLimit(e, item)}
        onBlur={(e) => putImpactEvaluation(item)}
      />
    </div>
  );
};

const ImpactEvaluation: FC<Props> = ({ contentData, handleChange, handleBlur }) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [learningEvaluations, setLearningEvaluations] = useState([]);
  const [impactLabelEvaluation, setLabelImpactEvaluation] = useState(null);
  const [loadingInput, setLoadingInput] = useState<boolean>(false);;
  const [values, setValues] = useState<{
    [key: string]: Item;
  }>();
  const [valuesBeforeChange, setValuesBeforeChange] = useState<{
    [key: string]: Item;
  }>();
  const [impactEvaluationParams, setImpactEvaluationParams] = useState<Item[]>([]);
  const [valuesConfiguration, setValuesConfiguration] = useState({
    visibility: null,
    idImpactEvaluation: null,
  });

  const handleChangeLimit = (e: ChangeEvent<HTMLInputElement>, item: Item) => {
    const restrictionSplit = item?.restrictions.split("-");
    const restrictionsMax = restrictionSplit.length > 1 ? Number(restrictionSplit[1]) : 1000;
    const restrictionsMin = restrictionSplit.length > 1 ? Number(restrictionSplit[0]) : 1;
    const inputValue = e.target.value;
    const regexString = new RegExp(
      `^(?:${Array.from(
        { length: restrictionsMax - restrictionsMin + 1 },
        (_, i) => restrictionsMin + i,
      ).join("|")})$`,
    );
    if (regexString.test(inputValue) || inputValue === "") {
      setValues((prevState) => ({
        ...prevState,
        [item.idEvaluationTypeParameter]: {
          ...prevState[item.idEvaluationTypeParameter],
          parameterValue: inputValue,
        },
      }));
    }
  };

  const putImpactEvaluation = async (item: Item) => {
    if (
      values[item.idEvaluationTypeParameter].parameterValue ===
      valuesBeforeChange[item.idEvaluationTypeParameter].parameterValue
    ) {
      return;
    }
    const object = {
      idEvaluationTypeParameter: item.idEvaluationTypeParameter,
      parameterValue: values[item.idEvaluationTypeParameter].parameterValue,
    };
    setValuesBeforeChange(values);
    try {
      await putImpactEvaluationParams(contentData.originalNodeCode, object);
    } catch (e) {
      console.log(e);
    }
  };

  const updateImpact = async (item, type: 'radio' | 'select' = 'radio') => {
    try {
      setLoadingInput(true);;
      setValuesConfiguration((prevState) => ({ ...prevState, ...item }));

      await putLearningConfiguration(item, contentData.originalNodeCode);
      if (!impactEvaluationParams.length && type === "select") {
        await _getImpactEvaluationParams();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingInput(false);
    }
  };

  const createObjectValue = (item) => {
    const values = item.reduce((resultado, item) => {
      resultado[item.idEvaluationTypeParameter] = item;
      return resultado;
    }, {});

    setValues(values);
    setValuesBeforeChange(values);
  };

  const listImpactEvaluation = useCallback(async () => {
    if (disabled) {
      const response = await getLearningEvaluations(contentData.idImpactEvaluation);
      setLabelImpactEvaluation(response.data.title);
    } else {
      const params = { evaluationType: "IMPACT", orderBy: "TITLE", sort: "ASC" };
      const response = await getLearningEvaluationsAll(params);
      const { result } = response.data;
      const filter = result.length ? result.filter((elem) => !!elem.title.trim()) : [];
      setLearningEvaluations(filter);
    }
  }, [disabled]);

  const _getImpactEvaluationParams = useCallback(async () => {
    if (contentData.originalNodeCode) {
      const response = await getImpactEvaluationParams(contentData.originalNodeCode);
      createObjectValue(response.data);
      setImpactEvaluationParams(response.data);
    }
  }, [contentData.originalNodeCode]);

  useEffect(() => {
    setValuesConfiguration({
      visibility: contentData?.hasImpactEvaluation === 1 ? true : false,
      idImpactEvaluation: contentData.idImpactEvaluation,
    });
    setDisabled(!!(contentData.hasEnrollmentCompleted && contentData.idImpactEvaluation));
  }, [contentData.hasEnrollmentCompleted]);

  useEffect(() => {
    listImpactEvaluation();
  }, [listImpactEvaluation]);

  useEffect(() => {
    _getImpactEvaluationParams();
  }, [_getImpactEvaluationParams]);

  const renderImpactEvaluationOptions = () => {
    return (
      <>
        <option value={""}>Selecione uma avaliação</option>

        {learningEvaluations?.map((item) => (
          <option key={item.idEvaluation} value={item.idEvaluation}>
            {item.title}
          </option>
        ))}
      </>
    );
  };

  return (
    <div className="notification-page">
      <div className="form-data flex-row">
        <Switch
          id="hasImpactEvaluation"
          color="primary"
          checked={valuesConfiguration.visibility}
          disabled={!contentData.entryPoint}
          onChange={(e) => {
            const _checked = e.target.checked;
            setValuesConfiguration((prevState) => ({ ...prevState, visibility: _checked ? 1 : 0 }));
            updateImpact({
              idImpactEvaluation: _checked ? valuesConfiguration.idImpactEvaluation : null,
            });
          }}
        />
        <Label
          className={"cursor-pointer"}
          name="Habilitar avaliação de impacto para o gestor"
          htmlFor="handleActiveImpactEvaluation"
        />
      </div>
      {!!valuesConfiguration.visibility && (
        <>
          <div className="form-data">
            {disabled ? (
              <div className="header-form-data">
                <CustomTooltip
                  title="Essa configuração não pode ser mais alteradada pois um ou mais alunos já concluiram o treinamento."
                  placement="top"
                  disableHoverListener={false}
                  arrow
                >
                  <div className="importInfo">
                    <BiSolidInfoCircle />
                  </div>
                </CustomTooltip>
                <Label name={impactLabelEvaluation} htmlFor="impactLabelEvaluation" />
              </div>
            ) : (
              <Form.Control
                disabled={disabled}
                as="select"
                // placeholder="Selecione uma avaliação"
                value={valuesConfiguration.idImpactEvaluation || null}
                onChange={(e) => {
                  updateImpact({ idImpactEvaluation: e.target.value || null }, "select");
                }}
              >
                {renderImpactEvaluationOptions()}
              </Form.Control>
            )}
          </div>
          {impactEvaluationParams.map((elem, index) => (
            <Input
              disabled={disabled}
              item={elem}
              key={index}
              handleChangeLimit={handleChangeLimit}
              putImpactEvaluation={() => putImpactEvaluation(elem)}
              values={values}
            />
          ))}
          {!impactEvaluationParams.length && loadingInput && <PlaceholderParameters number={2} />}
        </>
      )}
    </div>
  );
};
export default ImpactEvaluation;
