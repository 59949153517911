import React, {  useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import "./styles.scss";
import { Divider } from "antd";
import PreloaderBlur from "containers/PeopleCraft/PreloaderBlur";
import Label from "components/PeopleCraft/Label";
import AccordionComponent from "components/PeopleCraft/AccordionComponent";
import DataTable from "react-data-table-component";
import { Tooltip } from "@material-ui/core";
import { RiDeleteBin5Line } from "react-icons/ri";
import { postcampaignuserpoints } from "services/NexiDigitalAPI";

interface TableDate {
  text: string;
  status: number;
  pointsLabel: number;
  idEntity: number;
  points: number;
  id: number;
}

const EditScore = ({ data, denyAction=null }) => {
  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [addNewScore, setAddNewScore] = useState<{
    event: string;
    points: number;
  }>({
    event: "",
    points: null,
  });
  const [dataTable, setDataTable] = useState<TableDate[]>([]);

  const columns = [
    {
      name: "Nome (Descrição)",
      selector: "text",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Status",
      sortable: false,
      selector: "name",
      center: true,
      wrap: true,
      style: { textAlign: "center" },

      cell: (data, index) => (
        <div className={` border-status ${data.status === 0 ? "status-remove" : "status-add"}`}>
          <span>{data.status === 0 ? "REMOVIDO" : "ADICIONADO"}</span>
        </div>
      ),
    },
    {
      name: "Pontuação",
      sortable: false,
      selector: "pointsLabel",
      center: true,
      wrap: true,
      width: "100px",
      style: { textAlign: "center" },
      cell: (data, index) => (

        <div key={data.points + index}>
        <p>{data.points} pts</p>
      </div>
      ),
    },
    {
      name: "Ação",
      sortable: false,
      center: true,
      wrap: true,
      width: "100px",
      style: { textAlign: "center" },
      cell: (data, index) => (
        <Tooltip title="Deletar" placement="top">
        <span
          className="btn btn-sm float-right icon-delete"
          onClick={() => {
            deleteTable(data)
          }}
        >
          <RiDeleteBin5Line />
        </span>
      </Tooltip>
      ),
    },
  ];


  const CustomNoDataComponent = () => (
    <div className="noContent" style={{ textAlign: "center", border: 'unset' }}>
      <p>Nenhum ponto adicionado</p>
    </div>
  );

  const handleChange = (data) => setAddNewScore((afterValue) => ({ ...afterValue, ...data }));
  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, status: number) => {
    event.stopPropagation();
    setDataTable((afterValue) => ([
        ...afterValue,
        {
          text: addNewScore.event,
          id: afterValue.length + 1,
          points: status === 0 ? addNewScore.points * -1 : addNewScore.points,
          pointsLabel: addNewScore.points,
          idEntity: data.idUser,
          status,
        },
      ])
    )

    setAddNewScore((afterValue ) => ({
      event: "",
      points: null
    }))
  };

  const deleteTable = (data) => {
    const _data = dataTable.filter(elem => elem.id !== data.id)
    setDataTable(_data);
  }

  const handleDeny = () => {
    if (denyAction) {
      denyAction();
      return;
    }
    setShowModal(false);
  }
  const handleSubmit = async (event) => {
    event.stopPropagation();

    setLoading(true)
    try{
      await postcampaignuserpoints(data,dataTable)
      if (denyAction) {
        const mensage =   `Pontuação adicionada com sucesso!`
        denyAction({text: mensage, type: 'success'});
        return;
      }
    }catch(e){
      console.log(e)
    }finally{
      setLoading(false)

    }

  }


  return (
    <Modal
      show={showModal}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <div className="modal-support-blur modal-support-blur-warning" style={{ borderRadius: 10 }} />

      <div className="modal-principal-warning" style={{ minHeight: 400 }}>
        <Modal.Title style={{ textAlign: "left", fontSize: 20, marginTop: 20, marginLeft: 20 }}>
          <h5>Pontuação</h5>
          <span className="subtitle">
            Nesta tela você administrador, terá a possibilidade de adicionar ou remover a pontuação
            referente a campanha selecionada na tela anterior.
          </span>
          <Divider className="divider-edit-score" />
        </Modal.Title>
        <Modal.Body className="modal-body-warning">
          <div className="edit-score">
            {loading ? (
              <PreloaderBlur />
            ) : (
              <Form>
                <div className="flex-column">
                  <div className="form-data">
                    <Label name="Aluno" htmlFor="student" />
                    <Form.Control
                      disabled={true}
                      key={"student"}
                      placeholder="Nome"
                      defaultValue={data.name}
                      maxLength={100}
                      className="form-control"
                      type="text"
                    />
                  </div>
                  <div className="form-data">
                    <Label name="Campanha" htmlFor="campaign" />
                    <Form.Control
                      disabled={true}
                      key={"campaign"}
                      placeholder="Nome"
                      defaultValue={data?.campaign}
                      maxLength={100}
                      className="form-control"
                      type="text"
                    />
                  </div>
                  <AccordionComponent
                    title="Justifique sua Pontuação"
                    removeIcon={true}
                    disableExpand={true}
                  >
                    <div className="flex-column">
                      <div className="flex-row line-group">
                        <Form.Control
                          placeholder="Nome (Descrição)"
                          key={"event"}
                          value={addNewScore.event}
                          maxLength={100}
                          className="form-control"
                          type="text"
                          onChange={(e) => handleChange({ event: e.target.value })}
                        />
                        <Form.Control
                          placeholder="Pontos"
                          key={"points"}
                          value={addNewScore?.points || ''}
                          maxLength={100}
                          min={0}
                          className="form-control"
                          style={{ width: "6rem" }}
                          type="number"
                          onChange={(e) =>{console.log(e); handleChange({ points: parseInt(e.target.value) })}}
                        />
                        <Button
                          variant="danger"
                          disabled={!(addNewScore.event && addNewScore.points)}
                          onClick={(e) => handleClick(e, 0)}
                          className="remove-border"
                        >
                          Remover
                        </Button>
                        <Button
                          disabled={!(addNewScore.event && addNewScore.points)}
                          variant="primary"
                          onClick={(e) => handleClick(e, 1)}
                          className="remove-border"

                        >
                          Adicionar
                        </Button>
                      </div>
                      <div className="ranking-table-container">
                        <DataTable
                          progressPending={loading}
                          columns={columns}
                          data={dataTable}
                          noHeader
                          fixedHeader
                          noDataComponent={<CustomNoDataComponent />}
                        />
                      </div>
                    </div>
                  </AccordionComponent>
                </div>
              </Form>
            )}
          </div>
        </Modal.Body>
      </div>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => handleDeny()}>
          Fechar
        </Button>
        <Button variant="warning" disabled={!dataTable?.length} onClick={handleSubmit}>Salvar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditScore;
