import React, { useCallback, useState } from "react";

import "rc-datepicker/lib/style.css";
import ButtonWithLoading from "components/ButtonWithLoading";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import SearchIcon from "@material-ui/icons/Search";

import { StatusExternalCourse, StatusExternalCourseDescriptions } from "../../constants";

import { PiDownloadSimple } from "react-icons/pi";

import Checkbox from "@material-ui/core/Checkbox";
import { getDownloadReport, getMenuData, postDownloadReport } from "services/NexiDigitalAPI";

const FilterExternalCourse = ({ actionFilter, pag, loading, showDownloadBtn }) => {
  const idReport = 10;
  const [search, setSearch] = useState("");
  const [donwloadInProgress, setDownloadInProgress] = useState(false);

  function handleFilter() {
    actionFilter({ ...pag, searchString: search });
  }

  const handleChange = (event) => {
    if (event.target.name === "ALL") {
      // Definir os status possíveis
      const allStatuses = Object.values(StatusExternalCourse);
      const res = Object.fromEntries(allStatuses.map((status) => [status, event.target.checked]));
      actionFilter({ ...pag, status: res, offset: 0 });
    } else {
      actionFilter({
        ...pag,
        offset: 0,
        status: { ...pag.status, [event.target.name]: event.target.checked },
      });
    }
  };

  const requestDownloadReport = useCallback(async () => {
    const response = await getDownloadReport(idReport);
    const { status, fileUrl } = response?.data || {};

    if (status === "Waiting") {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return requestDownloadReport();
    }

    if (status === "Done" && fileUrl) {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.click();

      setDownloadInProgress(false);
    }
  }, []);

  const downloadReportHandler = useCallback(async () => {
    if (donwloadInProgress) {
      return;
    }
    setDownloadInProgress(true);

    const { idCompany } = getMenuData();
    await postDownloadReport(idReport, { idCompany });
    await requestDownloadReport();
  }, [donwloadInProgress, requestDownloadReport]);

  return (
    <div className={`filter-container`}>
      <div className="containerFilter">
        <div className="containerFilterBuscar">
          <input
            type="text"
            className="form-control input-default"
            id="groupDetail"
            style={{ marginRight: 8 }}
            aria-describedby="usuario"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Digite o nome do curso ou aluno"
          />
          <ButtonWithLoading
            onClickHandler={handleFilter}
            loading={loading}
            text={
              <>
                <SearchIcon style={{ height: 18 }} /> Pesquisar{" "}
              </>
            }
          />
        </div>

        <div className="filterRequestRegisteredBase">
          {showDownloadBtn && (
            <ButtonWithLoading
              onClickHandler={downloadReportHandler}
              loading={donwloadInProgress}
              text={
                <>
                  {" "}
                  <PiDownloadSimple style={{ height: 18 }} /> Baixar relatório
                </>
              }
            />
          )}
          <div className={"filterRequestRegistered"}>
            {Object.values(StatusExternalCourse).map((st) => (
              <FormControlLabel
                key={st}
                control={<Checkbox checked={pag.status[st]} onChange={handleChange} name={st} />}
                label={StatusExternalCourseDescriptions[st]}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterExternalCourse;
