import { modelQuestion } from "utils/Utils";

import { Form, FormGroup } from "react-bootstrap";

import LikertScale from "../LikertScale";
import MultiLikertScale from "../MultiLikertScale";
import SingleOptionComment from "../SingleOptionComment";

import React from "react";

const ManagementReaction = ({
  typeQuestion,
  setTypeQuestion,
  questionOptions,
  setQuestionOptions,
  questionScales,
  setQuestionScales,
}) => {
  return (
    <>
      <div className="form-group form-group-block-02">
        <div className="card">
          <div className="card-header template-card-header">Tipo de questão</div>
          <div className="card-body template-card-body">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <FormGroup className="questionType" controlId="formBasicCheckbox">
                  <Form.Check
                    inline
                    label="Escala linear"
                    name="group1"
                    type={"radio"}
                    id={`inline-1`}
                    checked={typeQuestion === modelQuestion.LIKERT_SCALE}
                    onChange={(e) => setTypeQuestion(modelQuestion.LIKERT_SCALE)}
                  />
                  <Form.Check
                    inline
                    label="Escala múltipla"
                    name="group1"
                    type={"radio"}
                    id={`inline-2`}
                    checked={typeQuestion === modelQuestion.MULTI_LIKERT_SCALE}
                    onChange={(e) => setTypeQuestion(modelQuestion.MULTI_LIKERT_SCALE)}
                  />
                  <Form.Check
                    inline
                    label="Alternativas + texto livre"
                    name="group1"
                    type={"radio"}
                    id={`inline-3`}
                    checked={typeQuestion === modelQuestion.SINGLE_OPTION_COMMENT}
                    onChange={(e) => setTypeQuestion(modelQuestion.SINGLE_OPTION_COMMENT)}
                  />
                  <Form.Check
                    inline
                    label="Texto livre"
                    name="group1"
                    type={"radio"}
                    id={`inline-4`}
                    checked={typeQuestion === modelQuestion.COMMENT}
                    onChange={(e) => setTypeQuestion(modelQuestion.COMMENT)}
                  />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </div>

      {typeQuestion === modelQuestion.LIKERT_SCALE && (
        <LikertScale questionScales={questionScales} setQuestionScales={setQuestionScales} />
      )}

      {typeQuestion === modelQuestion.MULTI_LIKERT_SCALE && (
        <MultiLikertScale
          questionOptions={questionOptions}
          setQuestionOptions={setQuestionOptions}
          questionScales={questionScales}
          setQuestionScales={setQuestionScales}
        />
      )}

      {typeQuestion === modelQuestion.SINGLE_OPTION_COMMENT && (
        <SingleOptionComment
          questionOptions={questionOptions}
          setQuestionOptions={setQuestionOptions}
        />
      )}
      {/* {typeQuestion === 'COMMENT' && <div>COMMENT</div>} */}
    </>
  );
};

export default ManagementReaction;
