import React, { useState, useRef, useEffect } from "react";
import {
  FaRegComment,
  FaExclamationTriangle,
  FaRegEye,
  FaEyeSlash,
  FaRegTrashAlt,
} from "react-icons/fa";
import Markdown from "markdown-to-jsx";
import MessageMenu from "components/MessageMenu";
import SendMessageBox from "components/SendMessageBox";
import EditMessageBox from "components/EditMessageBox";
import { Modal, Divider } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { MdReportProblem, MdOutlineEdit } from "react-icons/md";
import DeleteIcon from "assets/images/ico-delete.svg";
import HideIcon from "assets/images/hide-ico.svg";
import "./style.scss";
import { getInnerText } from "utils/Utils";

const MessageItem = ({
  index,
  idComment,
  idParentComment,
  photo,
  name,
  time,
  text = "",
  commentsCount,
  warningsCount,
  warningMessage,
  modeAuthor = null,
  displayDivider = false,
  onResize = () => {},
  replies = [],
  showHide = false,
  editButton = false,
  removeButton = false,
  reportButton = false,
  handleEditMessage = () => {},
  handleRemoveMessage = () => {},
  handleReportMessage = () => {},
  handleReply = () => {},
  isReply = false,
  hidden = false,
  hiddenBy = false,
  deleted = false,
  setShowNewMessageBox = () => {},
  showNewMessageBox = false,
  handleNewReplyMessageSubmit = () => {},
  handleEditReplyMessage = () => {},
  handleRemoveReplyMessage = () => {},
  handleHideMessage = () => {},
  handleFetchReplies = () => {},
  parentHandleReplyToggle = undefined,
  loading = false,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [newReply, setNewReply] = useState("");
  const [messageReplies, setMessageReplies] = useState(replies);
  const [visibleReplies, setVisibleReplies] = useState(5);
  const [showMenu, setShowMenu] = useState(false);
  const [showHideModal, setShowHideModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [_hidden, setHidden] = useState(hidden);
  const [_hiddenBy, setHiddenBy] = useState(hiddenBy);
  const [_deleted, setDeleted] = useState(deleted);
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(text);
  const [_commentsCount, setCommentsCount] = useState(commentsCount);
  const [availableOptions, setAvailableOptions] = useState([]);
  const [displayRepliesButton, setDisplayRepliesButton] = useState(false);
  const [loadingReplies, setLoadingReplies] = useState(false);
  const messageTextRef = useRef();
  const messageRef = useRef();
  const menuRef = useRef();

  const MAX_CHAR_COUNT = 200;

  useEffect(() => {
    setHidden(hidden);
    setHiddenBy(hiddenBy);
  }, [hidden, hiddenBy]);

  useEffect(() => {
    setDeleted(deleted);
  }, [deleted]);

  useEffect(() => {
    setCommentsCount(commentsCount);
  }, [commentsCount]);

  useEffect(() => {
    if (displayRepliesButton) {
      setMessageReplies(replies);
    }
  }, [displayRepliesButton, replies]);

  useEffect(() => {
    let availableOptions = [];

    if (editButton) {
      availableOptions = [
        ...availableOptions,
        {
          label: "Editar",
          labelProps: {
            style: {
              marginLeft: 8,
            },
          },
          icon: MdOutlineEdit,
          iconProps: {
            size: 18,
          },
          onClick: () => {
            setIsEditing(true);
            setShowNewMessageBox(false);
            setShowReplyBox(false);
          },
        },
      ];
    }

    if (removeButton) {
      availableOptions = [
        ...availableOptions,
        {
          label: "Excluir",
          labelProps: {
            style: {
              color: "#FF5353",
              marginLeft: 8,
            },
          },
          icon: FaRegTrashAlt,
          iconProps: {
            size: 18,
            color: "#FF5353",
          },
          onClick: () => setShowDeleteModal(true),
        },
      ];
    }

    if (reportButton) {
      availableOptions = [
        ...availableOptions,
        {
          label: "Denunciar",
          labelProps: {
            style: {
              color: "#FF5353",
              marginLeft: 8,
            },
          },
          icon: MdReportProblem,
          iconProps: {
            size: 18,
            color: "#FF5353",
          },
          onClick: handleReportMessage,
        },
      ];
    }

    setAvailableOptions(availableOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editButton, removeButton, reportButton]);

  useEffect(() => {
    if ((getInnerText(text)?.length || 0) > MAX_CHAR_COUNT) {
      setShowMoreButton(true);
    } else {
      setShowMoreButton(false);
    }
  }, [text]);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
    setTimeout(() => {
      scrollToComponent(
        `${isReply ? ".reply-toggle" : ".message-toggle"}.p${idParentComment}.c${idComment}`,
      );
    }, 500);
  };

  const handleReplyToggle = () => {
    setShowNewMessageBox(false);

    if (!displayRepliesButton) {
      displayReplies();
    }

    setShowReplyBox(!showReplyBox);
    setTimeout(() => {
      scrollToComponent(`.searchBox.p${idParentComment}.c${idComment}`);
    }, 500);
  };

  const handleNewMessage = async (newText) => {
    setNewReply("");
    setShowReplyBox(false);
    await handleNewReplyMessageSubmit(newText);
    setDisplayRepliesButton(true);
    await handleFetchReplies(0, _commentsCount + 1, 0, true);
    setVisibleReplies(_commentsCount + 1);
    setCommentsCount(_commentsCount + 1);
  };

  const handleMouseEnter = () => {
    setShowMenu(true);
  };

  const handleMouseLeave = (e) => {
    const relatedTarget = e.relatedTarget;
    if (
      !menuRef.current ||
      !relatedTarget ||
      !(relatedTarget instanceof Node) ||
      (!messageRef.current.contains(relatedTarget) && !menuRef.current.contains(relatedTarget))
    ) {
      setShowMenu(false);
    }
  };

  const openHideModal = () => {
    setShowHideModal(true);
  };

  const closeHideModal = () => {
    setShowHideModal(false);
  };

  const confirmHide = () => {
    setHidden(!_hidden);
    setShowHideModal(false);
    handleHideMessage(idComment, !_hidden);
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDelete = () => {
    handleRemoveMessage(idComment);
    setShowDeleteModal(false);
  };

  const scrollToComponent = (elementId, behavior = "smooth", block = "center") => {
    const component = document.querySelector(elementId);
    if (component) {
      component.scrollIntoView({ behavior, block });
    }
  };

  const showMoreReplies = async () => {
    setLoadingReplies(true);
    setDisplayRepliesButton(true);
    try {
      await handleFetchReplies(visibleReplies, 5);
      setVisibleReplies(visibleReplies + 5);
      setLoadingReplies(false);
    } catch {
      setLoadingReplies(false);
    }
  };

  const hideReplies = async () => {
    setLoadingReplies(false);
    setDisplayRepliesButton(false);
    try {
      await handleFetchReplies(0, 0, 0, true, true);
      setVisibleReplies(5);
      setLoadingReplies(false);
    } catch {
      setLoadingReplies(false);
    }
  };

  const displayReplies = async () => {
    if (displayRepliesButton) {
      hideReplies();
      return;
    }

    setLoadingReplies(true);
    setDisplayRepliesButton((prev) => !prev);

    try {
      await handleFetchReplies(0, 5, 0, true);
      setLoadingReplies(false);
    } catch {
      setLoadingReplies(false);
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleEditSubmit = (editedText) => {
    handleEditMessage(editedText);
    setIsEditing(false);
  };

  const DeletedMessage = (_isReply = false) => {
    return (
      <div className="deleted-message-container">
        <FaEyeSlash className="deleted-message-icon" />
        <p className="deleted-message-text">
          {`A visualização desta ${
            _isReply ? "resposta" : "mensagem"
          } não está mais disponível, uma vez que foi excluída pelo próprio usuário/autor.`}
        </p>
      </div>
    );
  };

  const HiddenMessage = (_isReply = false) => {
    return (
      <div className="hidden-message-container">
        <p className="hidden-message-text">
          {`A visualização desta ${
            _isReply ? "resposta" : "mensagem"
          } está oculta, para reverter clique em desocultar.`}
        </p>
        <Markdown
          options={{
            forceBlock: true,
            forceWrapper: true,
            overrides: {
              p: {
                component: "span",
                props: {
                  style: {
                    display: "inline",
                    color: "#707070",
                    opacity: 0.65,
                  },
                },
              },
              br: {
                props: { display: "none" },
              },
            },
          }}
        >
          {`"*${text}*"`}
        </Markdown>
      </div>
    );
  };

  const MessageContainer = (_isReply = false) => {
    return (
      <div>
        <div className="regular-message-container">
          <p className="regular-message-text">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  expanded || getInnerText(text).trim().length < MAX_CHAR_COUNT
                    ? text
                    : `${text.trim().slice(0, MAX_CHAR_COUNT)}...`,
              }}
            />
          </p>
        </div>
      </div>
    );
  };

  const RenderSkeleton = ({ key }) => (
    <div key={key} lassName={isReply ? "reply" : "message"}>
      <Skeleton variant="circle" width={30} height={30} className="reply-avatar" />
      <div className="reply-content">
        <div className="reply-author-time">
          <Skeleton width={100} height={20} />
          <Skeleton width={50} height={20} />
        </div>
        <div className="reply-text-container">
          <Skeleton width="100%" height={60} />
        </div>
        <div className="reply-icons">
          <Skeleton width={20} height={20} />
          <Skeleton width={20} height={20} />
          <Skeleton width={20} height={20} />
        </div>
      </div>
    </div>
  );

  const firstLetter = name ? name.substr(0, 1) : "";

  return (
    <div
      className={isReply ? `reply-wrapper p${idParentComment} c${idComment}` : "message-wrapper"}
    >
      {loading ? (
        <RenderSkeleton key={index} />
      ) : _deleted ? (
        <DeletedMessage _isReply={isReply} />
      ) : (
        <div className={isReply ? "reply" : "message"}>
          <div className="pctAvatar avatarImg avatarHrader">
            <div className={`avtContainer ${isReply ? "reply-avatar" : "message-avatar"}`}>
              {photo ? (
                <img src={photo} className="avatarImg" alt={name} />
              ) : (
                <div className="avatarTxt">{firstLetter}</div>
              )}
            </div>
          </div>
          <div className={isReply ? "reply-content" : "message-content"}>
            <div ref={messageRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <div className={isReply ? "reply-author-time" : "message-author-time"}>
                <span className={isReply ? "reply-author" : "message-author"}>{name}</span>
                {Boolean(modeAuthor) && <span className={"message-mod"}>{modeAuthor}</span>}
                <div className={isReply ? "reply-time-container" : "message-time-container"}>
                  <span className={isReply ? "reply-time" : "message-time"}>{time}</span>
                  {Boolean(warningMessage) && (
                    <span className={isReply ? "reply-warning-message" : "message-warning-message"}>
                      {warningMessage}
                    </span>
                  )}
                </div>
                {!_deleted && !_hidden && showMenu && availableOptions.length > 0 && (
                  <div ref={menuRef}>
                    <MessageMenu
                      menuOptions={availableOptions}
                      menuExtraAction={() => {
                        setNewReply("");
                        setShowReplyBox(false);
                        setShowNewMessageBox(false);
                      }}
                      isReply={isReply}
                    />
                  </div>
                )}
              </div>
              <div className={isReply ? "reply-text-container" : "message-text-container"}>
                {isEditing && !_hidden ? (
                  <EditMessageBox
                    editContent={editText}
                    setEditContent={setEditText}
                    handleEditToggle={handleEditToggle}
                    handleEditSubmit={handleEditSubmit}
                  />
                ) : (
                  <div
                    className={`${isReply ? "reply-text" : "message-text"} ${
                      expanded ? "expanded" : "collapsed"
                    }`}
                    style={{ marginBottom: expanded ? 10 : 0 }}
                    ref={messageTextRef}
                  >
                    {_hidden ? (
                      <HiddenMessage _text={text} _isReply={isReply} />
                    ) : (
                      <MessageContainer _isReply={isReply} />
                    )}
                  </div>
                )}
                {!_hidden && !_deleted && showMoreButton && (
                  <button
                    onClick={handleToggleExpand}
                    className={`${
                      isReply ? "reply-toggle" : "message-toggle"
                    } p${idParentComment} c${idComment}`}
                  >
                    {expanded ? "Ver menos" : "Ver mais"}
                  </button>
                )}
              </div>
              <div
                className={isReply ? "reply-icons" : "message-icons"}
                style={{
                  paddingTop: showMoreButton
                    ? expanded
                      ? 0
                      : isEditing
                      ? 170
                      : isReply
                      ? 10
                      : 0
                    : _hidden
                    ? 20
                    : isEditing
                    ? isReply
                      ? 10
                      : 190
                    : isReply
                    ? 0
                    : (getInnerText(text).match(/\n/g) || []).length >= 1
                    ? 5
                    : 20,
                }}
              >
                {!isEditing && (
                  <>
                    {!isReply && _commentsCount > 0 && (
                      <button
                        className="comment-icon"
                        onClick={() => displayReplies()}
                        // disabled={displayRepliesButton}
                      >
                        <FaRegComment /> {_commentsCount}
                      </button>
                    )}
                    {warningsCount > 0 && (
                      <span className="icon">
                        <FaExclamationTriangle style={{ color: "red" }} /> {warningsCount}
                      </span>
                    )}
                    <button
                      className={_hidden ? "message-reply-disabled" : "message-reply"}
                      style={{
                        right:
                          _commentsCount === 0 && warningsCount === 0
                            ? 4
                            : isReply && warningsCount === 0
                            ? 4
                            : 0,
                      }}
                      onClick={
                        !_hidden
                          ? parentHandleReplyToggle
                            ? () => parentHandleReplyToggle()
                            : () => handleReplyToggle()
                          : () => {}
                      }
                      disabled={_hidden}
                    >
                      Responder
                    </button>
                    {!_deleted && showHide && (
                      <span
                        className={isReply ? "reply-hide" : "message-hide"}
                        onClick={openHideModal}
                      >
                        {_hidden ? <FaEyeSlash /> : <FaRegEye />}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
            {!isEditing && !showNewMessageBox && !_hidden && showReplyBox && (
              <div className={`searchBox p${idParentComment} c${idComment}`}>
                <SendMessageBox
                  isReply={isReply}
                  handleNewMessage={handleNewMessage}
                  handleReplyToggle={handleReplyToggle}
                />
              </div>
            )}
            {displayRepliesButton && !_hidden && (
              <div className="replies">
                {!loadingReplies ? (
                  <>
                    {messageReplies.map((reply, _index) => (
                      <MessageItem
                        {...reply}
                        key={reply.idComment}
                        isReply={true}
                        handleReply={handleReply}
                        handleEditMessage={handleEditReplyMessage(reply.idComment)}
                        handleRemoveMessage={handleRemoveReplyMessage}
                        handleEditReplyMessage={handleEditReplyMessage(reply.idComment)}
                        handleHideMessage={handleHideMessage}
                        handleNewReplyMessageSubmit={handleNewReplyMessageSubmit}
                        parentHandleReplyToggle={handleReplyToggle}
                        loading={loading}
                      />
                    ))}
                    {Boolean(_commentsCount) && (
                      <>
                        {_commentsCount > visibleReplies ? (
                          <div
                            className={`message-view-more-container p${idParentComment} c${idComment}`}
                          >
                            <div className="message-view-more-line"></div>
                            <button className="message-view-more" onClick={showMoreReplies}>
                              Ver mais respostas ({_commentsCount - visibleReplies})
                            </button>
                          </div>
                        ) : (
                          <div
                            className={`message-view-more-container p${idParentComment} c${idComment}`}
                          >
                            <div className="message-view-more-line"></div>
                            <button className="message-view-more" onClick={hideReplies}>
                              Ocultar respostas
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <div className="replies">
                    {commentsCount > 0 &&
                      Array.from(new Array(3)).map((_, index) => <RenderSkeleton key={index} />)}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {displayDivider && !isReply && <Divider className="message-divider" />}

      <Modal
        open={showHideModal}
        onClose={closeHideModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="interaction-dialog">
          <div className="interaction-dialog-circle">
            <img
              src={HideIcon}
              style={{ height: 48, width: 48, color: "#FFA500" }}
              alt="icon-ocultar"
            />
          </div>
          <div className="interaction-dialog-columnItem">
            <h3>
              Tem certeza que deseja {_hidden ? "desocultar" : "ocultar"}{" "}
              {isReply ? "a resposta" : "a mensagem"}?
            </h3>
          </div>
          <div className="interaction-dialog-subtitle">
            Esta ação poderá ser revertida quando desejar.
          </div>
          <div className="interaction-dialog-footer">
            <button onClick={closeHideModal} className="hide-message-button cancel-button">
              <strong>Cancelar</strong>
            </button>
            <button
              onClick={confirmHide}
              className="hide-message-button button-yellow button-default confirm-button"
            >
              <strong>{_hidden ? "Desocultar" : "Ocultar"}</strong>
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="interaction-dialog interaction-dialog-delete">
          <div className="interaction-dialog-circle">
            <img src={DeleteIcon} style={{ height: 48, width: 48 }} alt="icon-remoção" />
          </div>
          <div className="interaction-dialog-columnItem">
            <h3>
              Tem certeza que deseja excluir permanentemente {isReply ? "a resposta" : "a mensagem"}
              ?
            </h3>
          </div>
          <div className="interaction-dialog-subtitle">Atenção! Esta é uma ação irreversível.</div>
          <div className="interaction-dialog-footer">
            <button onClick={closeDeleteModal} className="modal-message-button cancel-button">
              <strong>Cancelar</strong>
            </button>
            <button
              onClick={confirmDelete}
              className="modal-message-button button-yellow confirm-button"
            >
              <strong>Excluir</strong>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MessageItem;
