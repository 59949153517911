import "./style.scss";

import React, { useContext, useEffect, useState } from "react";

import { NiceModalHandler } from "@ebay/nice-modal-react";

import {  Status, Trial } from "../../model/ManagerTrial.model";
import { useManagerTrial } from "../../context/contextManagerTrial";
import { IoMdClose } from "react-icons/io";
import { licenseUpgradeConcact } from "../../Utils/Filters";
import {AuthContext} from "context/AuthContext";
import FormUpgrade from "../FormUpgrade";
import RequestUpgrade from "../RequestUpgrade";
import FeedbackUpgrade from "../FeedbackUpdate";
import TermUpgrade from "../TermUpgrade";
import ModalDialog from "containers/PeopleCraft/Modals/ModalDialog";
import SelectedPlanUpgrade from "../SelectedPlanUpgrade";
import CalculatorPlanUpgrade from "../CalculationPlanUpgrade";
import DetailPlanUpgrade from "../DetailPlanUpgrade";
import DialogUpgrade from "../DialogUpgrade";



const AdminUpgrade = ({
  modal,
}: {
  modal?: NiceModalHandler;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { getLicense} = useContext(AuthContext);
  const [modalErro, setModalErro] = useState<boolean>(false);
  const [messageErro, setMessageErro] = useState<string>('');
  const { upgradeStatus, setTrialStatusPlan,setUpgradeStatus, setlistPlans, setCurrentCardUpgrade } = useManagerTrial();
  const { getAvailablePlans } = useContext(AuthContext);
  const init = async () => {
    setLoading(true);
    const license =getLicense()
    if (license) {
      const licenseUpgradeConcactRes = licenseUpgradeConcact(license);
      const _getAvailablePlans = await getAvailablePlans();
      const flatPlans = _getAvailablePlans.flatMap(({ plans }) => plans);
      if(license.activePlan?.trial){
        const currentPlan = flatPlans.find((plan) => plan.idPlan === license.activePlan.idPlan);
        setCurrentCardUpgrade({ ...license.activePlan, ...currentPlan, planUpgrade: license.plansContainer } ?? null);
        setUpgradeStatus(Status.selectedPlan);
        setTrialStatusPlan(Trial.started);
      }
      setlistPlans(licenseUpgradeConcactRes);

    }
    setLoading(false);
};

  useEffect(() => {
    init()
  }, []);

  const closed = (messageErro?) => {
    document.body.classList.remove("bodyOverflowHidden");
    if(messageErro){
      setMessageErro(messageErro)
      setModalErro(true)
      return
    }
    if (upgradeStatus === Status.terms || upgradeStatus === Status.form) {
      setUpgradeStatus(Status.upgrade);
      return;
    }

    modal?.remove();
  };
  return (
    <>
      {
        !modalErro ? (
          <>
          {modal && (
            <button className="closeBtn" onClick={() => closed()}>
              <IoMdClose />
            </button>
          )}
          <div className={`pctAdminUpgrade ${upgradeStatus}`}>
            {loading ? (
              <div className="loaderAdmin">
                <div className="preloaderIt"></div>
              </div>
            ) : (
              {
                [Status.terms]: <TermUpgrade />,
                [Status.upgrade]: <RequestUpgrade />,
                [Status.form]: <FormUpgrade closed={closed}/>,
                [Status.feedback]: <FeedbackUpgrade />,
                [Status.selectedPlan]: <SelectedPlanUpgrade />,
                [Status.detail]: <DetailPlanUpgrade  />,

                [Status.calculatorPlan]: <CalculatorPlanUpgrade />,
                [Status.dialog]: <DialogUpgrade />,
              }[upgradeStatus]
            )}
          </div>
          </>
        ) : (
          <ModalDialog
            title={"O trial já foi ativado"}
            text={messageErro}
            show={modalErro}
            setShow={setModalErro}
            btnDenyCall={() => {

              closed();
            }}
            btnCall={{alertMode: true}}
          />
        )
      }


    </>
  );
};

export default AdminUpgrade;
