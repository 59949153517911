import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import UserForm from "../UserForm";
import { appDetails, checkQrCodeEnable } from "../../services/NexiDigitalAPI";
import QrModal from "./components/QrModal";

export default function QRAddUser() {
  const urlParams = new URLSearchParams(useLocation().search);
  const token = urlParams.get("token");
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const url =
    window.location.origin === "https://devadmin.blast.etc.br"
      ? window.location.origin.replace(/admin/g, "")
      : window.location.origin.replace(/admin./g, "");

  const [appName, setAppName] = useState("Aplicativo");
  const [baseUrl, setBaseUrl] = useState(url);

  function getAppName() {
    let appUrl =
      window.location.origin === "http://localhost:3000"
        ? "devadmin.blast.etc.br"
        : window.location.origin.replace(/https:\/\/|http:\/\//g, "");

    appDetails(appUrl)
      .then((res) => {
        if (res.data.app.length !== 0) {
          setAppName(res.data.app[0].name);
          setBaseUrl(`https://${res.data.app[0].bucket}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const hasPermission = async () => {
    let permited = await checkQrCodeEnable(token);

    setShowErrorModal(!permited);
  };

  useEffect(() => {
    getAppName();
    hasPermission();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const submitResult = () => {
    setShowModal(true);
  };

  return (
    <div style={{ padding: "50px", background: "#f7f8fc", height: "100%" }}>
      <QrModal
        showModal={showErrorModal}
        baseUrl={baseUrl}
        title="Cadastro de usuários"
        body={`Não há mais licenças disponéveis, favor entrar em contato com seu administrador.`}
      />
      {!showErrorModal && (
        <>
          <UserForm submitResult={submitResult} token={token} notAuto={false} />
          <QrModal
            showModal={showModal}
            baseUrl={baseUrl}
            title="Confirmação de Cadastro"
            body={`O seu usuário foi criado com sucesso!. Você será redirecionado em breve para acessar o ${appName}`}
          />
        </>
      )}
    </div>
  );
}
