import React, { useState } from "react";
import "./style.scss";
import { ContentData, StatusTypes } from "../../nodeParamenters.model";
import Label from "../../../Label";
import CharacterCounter from "components/PeopleCraft/CharacterCounter";

type Props = {
  contentData: ContentData;
  handleBlur: (data: { [key: string]: number | string | string[] | boolean }) => void;
  disabled: boolean
};
const AuthorComponent: React.FC<Props> = ({ contentData,  handleBlur, disabled= false }) => {
  const [contentDataChange, setContentDataChange] = useState<{
    author: string,
    authorEmail: string
  }>({
    author: contentData.author,
    authorEmail: contentData.authorEmail
  });
  const handleChange = (payload) => {
    setContentDataChange(prevState => ({...prevState, ...payload}))
  }
  return (
    <div className="flex-column">
      <div className="form-data">
        <Label name="Nome" htmlFor="author" />
        <input
          key={"nome"}
          placeholder="Nome"
          value={contentDataChange?.author || null}
          disabled={disabled || contentData?.status === StatusTypes.ARCHIVED}
          onBlur={(e: any) => {
            handleBlur({ author: e.target.value || null});
          }}
          onChange={(e: any) => {
            handleChange({ author: e.target.value || null });
          }}
          maxLength={30}
          className="form-control"
          type="text"
        />
        <CharacterCounter characterNumber={contentDataChange.author?.length} maxLength={30} />
      </div>
      <div className="form-data">
        <Label name="E-mail" htmlFor="authorEmail" />
        <input
          placeholder="Digite o e-mail"
          value={contentDataChange?.authorEmail || null}
          disabled={disabled || contentData?.status === StatusTypes.ARCHIVED}
          onBlur={(e: any) => {
            handleBlur({ authorEmail: e.target.value });
          }}
          onChange={(e: any) => {
            handleChange({ authorEmail: e.target.value });
          }}
          maxLength={100}
          className="form-control"
          type="email"
        />
        <CharacterCounter characterNumber={contentDataChange.authorEmail?.length} maxLength={100} />
      </div>
    </div>

  );
};

export default AuthorComponent;
