import "./style.modules.scss";

import React, { useState, useEffect, useRef, useContext } from "react";

import { withRouter, useParams } from "react-router-dom";

import { Button } from "react-bootstrap";

import {
  getCertificate,
  createCertificate,
  editCertificate,
} from "../../../../../services/NexiDigitalAPI";

import { fabric } from "fabric";
import CEPanelBar from "../CEPanelBar";
import CESyllabus from "../CESyllabus";

import { convectSVG } from "../../Utils/convectSVG";

import { CECreateContext } from "../../Contexts/CEContext";

import CEModalDialog from "../CEModalDialog";

/// json com o template customizada
import template from "../../Template/modeloInit.json";
import moment from "moment";
/// json com o template customizada

const CECanvas = (props) => {
  const { idCertificate, idCompany } = useParams();

  const container = useRef(null);
  const {
    setCanvasCertificates,
    canvasCertificates,
    setPreloadInit,
    preloadInit,
    filterCertificates,
    nameCertificates,
    setNameCertificates,
    setTempJson,
    syllabus,
    setSyllabus,
    typeView,
    setTypeView,
    setBackgroundSyllabus,
    syllabusState,
    setSyllabusState,
  } = useContext(CECreateContext);

  const [modalShow, setModalShow] = useState(false);
  const [erroShow, setErroShow] = useState("");
  const [outScreen, setOutScreen] = useState(false);

  const addTemplate = (newCanvas, temp) => {
    setTempJson(temp);

    if (newCanvas) {
      newCanvas.clear();

      newCanvas.loadFromJSON(temp, newCanvas.renderAll.bind(newCanvas), function (o, object) {
        if (object.type === "textbox") {
          object.set("lockRotation", true);
          object.set("lockScalingY", true);
        }
      });
    }
  };

  const newCertificates = (temp) => {
    if (canvasCertificates === null) {
      const { clientHeight, clientWidth } = container.current;

      const newCanvas = new fabric.Canvas("canvas", {
        backgroundColor: "#fff",
        height: clientHeight,
        width: clientWidth,
        preserveObjectStacking: true,
      });

      // setPreloadInit(false);
      setCanvasCertificates(newCanvas);
      addTemplate(newCanvas, temp);
    }
  };

  useEffect(() => {
    let componentMounted = true;

    if (idCertificate) {
      setPreloadInit(true);

      getCertificate(idCertificate)
        .then((res) => {
          if (componentMounted) {
            const config = JSON.parse(res.data.config);

            const background =
              config.backgroundImage && config.backgroundImage.src
                ? config.backgroundImage.src
                : config.background;

            setBackgroundSyllabus({
              result: background,
              type: config.backgroundImage && config.backgroundImage.src ? "image" : "color",
            });

            newCertificates(config);
            setNameCertificates(res.data.title);
            setSyllabus(res.data.syllabus);

            if (config.syllabusState) setSyllabusState(config.syllabusState);

            setTimeout(() => {
              setPreloadInit(false);
            }, 1000 * 2);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      newCertificates(template);
    }

    return () => {
      componentMounted = false;
    };
  }, [idCertificate]);

  const saveCerficate = (svg, json) => {
    const jsonSyllabus = syllabus ? { ...json, syllabusState: syllabusState } : json;
    const jsonCerti = JSON.stringify(jsonSyllabus);
    const svgCerti = svg;

    if (nameCertificates === "") {
      setErroShow(`Preencha o nome do certificado.`);
      setModalShow(true);

      return false;
    }

    const data = {
      title: nameCertificates,
      html: svgCerti,
      config: jsonCerti,
      syllabus: syllabus,
    };

    setPreloadInit(true);

    if (idCertificate) {
      const editData = {
        ...data,
        idCertificate: idCertificate,
        idCompany: idCompany,
      };
      editCertificate(idCertificate, editData).then((res) => {
        setPreloadInit(false);
        props.history.push("/certificates-manager/");
      });
    } else {
      createCertificate(data).then((res) => {
        setPreloadInit(false);
        if (res?.data?.idCertificate) {
          props.history.push("/certificates-manager/");
        }
        else {
          setErroShow(`Não foi possível completar a operação.<br />Data e hora: ${moment().format("DD/MM/YYYY HH:mm:ss")}`);
          setModalShow(true);
        }
      });
    }
  };

  const saveSVGEvent = (e) => {
    // const svg = canvasCertificates.toSVG({
    //   width: "auto",
    //   height: "auto",
    // });
    const json = canvasCertificates.toJSON();

    convectSVG(json, filterCertificates, syllabus, syllabusState, (obj, erro = null) => {
      //Sucesso
      if (!erro) {
        saveCerficate(obj, json);
      } else {
        setErroShow(
          `Identificamos uma inconsistência na formatação e/ou na indentação do campo dinâmico seguinte: <b>${obj}</b>. Para resolver, sugerimos que o exclua e digite novamente.`,
        );
        setModalShow(true);
      }
    });
  };

  return (
    <div className={`canvasBaseX ${preloadInit ? "canvasOpacity" : ""}`}>
      <div
        style={{
          display: typeView === "certificate" ? "block" : "none",
        }}
      >
        <CEPanelBar canvas={canvasCertificates} />
        <canvas
          id="canvas"
          style={{
            width: "1124px",
            height: "794px",
            border: "2px solid black",
            margin: "auto",
          }}
          ref={container}
        />
      </div>

      {typeView === "syllabus" && <CESyllabus />}

      <div className="controlBtn">
        <div className="controlBtnSub1">
          <Button
            variant={typeView === "certificate" ? "success" : "outline-secondary"}
            className=""
            onClick={(e) => setTypeView("certificate")}
          >
            Certificado
          </Button>
          <Button
            variant={typeView === "syllabus" ? "success" : "outline-secondary"}
            disabled={!syllabus}
            className=""
            onClick={(e) => setTypeView("syllabus")}
          >
            Ementa
          </Button>
        </div>
        <div className="controlBtnSub2">
          <Button
            variant="outline-secondary"
            className="download"
            onClick={(e) => setOutScreen(true)}
          >
            Cancelar
          </Button>
          <Button variant="warning" onClick={(e) => saveSVGEvent(e)}>
            {idCertificate ? "Editar" : "Criar"} certificado
          </Button>
        </div>
      </div>

      <CEModalDialog
        show={modalShow}
        setShow={setModalShow}
        text={erroShow}
        btnCall={{
          variantBtn: "danger",
          text: "Confirmar",
          callEvent: () => {
            setModalShow(false);
          },
        }}
      />

      <CEModalDialog
        show={outScreen}
        setShow={setOutScreen}
        text={`Tem certeza de que deseja sair da <b>${
          idCertificate ? "Editação" : "Criação"
        } do Certificado</b>?`}
        btnCall={{
          variantBtn: "danger",
          text: "Confirmar",
          callEvent: () => {
            props.history.push("/certificates-manager/");
          },
        }}
      />
    </div>
  );
};

export default withRouter(CECanvas);
