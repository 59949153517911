import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./styles.module.scss";
import WeekAccess from "../WeekAccess";
import ResumeCards from "../ResumeCards";
import moment from "moment";
import "moment/locale/pt-br.js";
import { DatePicker, ConfigProvider } from "antd";
import ptBR from "antd/es/locale/pt_BR";
import { getGroups } from "../../../../../services/NexiDigitalAPI";
import _ from "underscore";
import { AuthContext } from "../../../../../context/AuthContext";
const { RangePicker } = DatePicker;

const FilterDashboard = ({ changePeriodFilterGlobal, changeDateGlobal, changeIdGroupGlobal }) => {
  const [firstElem, setFirstElem] = useState({
    date: moment(new Date()).subtract(6, "days").format("YYYY-MM-DD"),
    day: moment(new Date()).subtract(6, "days").format("dddd"),
  });
  const [lastElem, setLastElem] = useState({
    date: moment(new Date()).format("YYYY-MM-DD"),
    day: moment(new Date()).format("dddd"),
  });
  const [hideData, setHideData] = useState(true);
  const [dates, setDates] = useState([]);

  const [value, setValue] = useState([
    moment(firstElem.date, ["YYYY-MM-DD"]),
    moment(new Date(), ["YYYY-MM-DD"]),
  ]);

  const disabledDate = (current) => {
    return current && current > moment();
  };

  const nullfyDate = () => {
    setLastElem({
      date: null,
      day: null,
    });
    setLastElem({
      date: null,
      day: null,
    });
  };

  function handleFilter(val = false) {
    let _value = val || value;
    if (_value && _value[0] && _value[1]) {
      changeDateGlobal(_value);
      setFirstElem({
        date: moment(_value[0]).format("YYYY-MM-DD"),
        day: moment(_value[0]).format("dddd"),
      });
      setLastElem({
        date: moment(_value[1]).format("YYYY-MM-DD"),
        day: moment(_value[1]).format("dddd"),
      });
    } else {
      nullfyDate();
    }
  }

  const editData = (start, end) => {
    setFirstElem({
      date: start.format("YYYY-MM-DD"),
      day: start.format("dddd"),
    });
    setLastElem({
      date: end.format("YYYY-MM-DD"),
      day: end.format("dddd"),
    });
    changeDateGlobal(new Array(start, end));
  };

  const styleData = hideData ? { display: "none" } : { display: "inline-block", marginLeft: 15 };

  const defaultValue = "0";
  const defaultValueGroup = "";
  const changePeriod = (value) => {
    changePeriodFilterGlobal(value);
    const eventValue = value;
    switch (eventValue) {
      case "0":
        setHideData(true);
        editData(moment(new Date()).subtract(6, "days"), moment(new Date()));
        break;
      case "1":
        setHideData(true);
        editData(moment(new Date()).subtract(29, "days"), moment(new Date()));
        break;
      case "2":
        setHideData(false);
        break;
      default:
        break;
    }
  };

  const isMountedRef = useRef(null);
  const [lista, setLista] = useState([]);
  const [loadingGroups, setLoadingGroups] = useState(true);
  const { loginData } = useContext(AuthContext);
  const secondaryColor = loginData.secondaryColor;

  useEffect(() => {
    isMountedRef.current = true;
    getGroups()
      .then((res) => {
        if (isMountedRef.current) {
          setLista(_.sortBy(res.data.groups, "title"));
          setLoadingGroups(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => (isMountedRef.current = false);
  }, []);

  return (
    <div className={styles.report_container} style={{ marginBottom: "20px", display: "block" }}>
      <div className={styles.report_graph}>
        <div className={styles.report_title}>
          <div className={styles.textLeft} style={{ paddingBottom: "10px" }}>
            Filtros
          </div>
        </div>
        <div className={styles.report_title}></div>
      </div>

      <div className={styles.container_filter}>
        <div className={styles.container_filter_itens}>
          <div className={styles.textLeft} style={{ fontSize: "15px" }}>
            Período
          </div>
          <div className={styles.selectPediodDiv} style={{ marginBottom: "10px" }}>
            <select
              className={styles.selectPediodSelection}
              defaultValue={defaultValue}
              onChange={(event) => changePeriod(event.target.value)}
            >
              <option key={0} value="0">
                Últimos 7 dias
              </option>
              <option key={1} value="1">
                Últimos 30 dias
              </option>
              <option key={2} value="2">
                Qualquer período
              </option>
            </select>
          </div>
          <div style={styleData} className={styles.dynamicPeriod}>
            <ConfigProvider locale={ptBR}>
              <RangePicker
                value={value}
                disabledDate={disabledDate}
                onCalendarChange={(val) => {
                  if (val && val.length === 2 && moment(val[1]).diff(moment(val[0]), "days") > 6) {
                    //val[1] = moment(val[0]).add(6, "days");
                  }
                  setDates(val);
                  if (!val) {
                    nullfyDate();
                  } else {
                    handleFilter(val);
                  }
                }}
                defaultValue={[
                  moment(firstElem.date, ["YYYY-MM-DD"]),
                  moment(lastElem.date, ["YYYY-MM-DD"]),
                ]}
                onChange={(val) => setValue(val)}
                format={["YYYY-MM-DD", "DD/MM/YY", "MM/YY"]}
                size={"large"}
              />
            </ConfigProvider>
          </div>
        </div>
        <div className={styles.container_filter_itens}>
          <div className={styles.textLeft} style={{ fontSize: "15px" }}>
            Grupo
          </div>
          <div className={""}>
            <select
              className={styles.selectPediodSelection}
              defaultValue={defaultValueGroup}
              onChange={(event) => changeIdGroupGlobal(event.target.value)}
              disabled={loadingGroups}
              style={loadingGroups ? { opacity: "0.3" } : {}}
            >
              <option value="">Todos</option>
              {lista.map((item, index) => {
                return (
                  <option key={index} value={item.idGroups}>
                    {item.title}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterDashboard;
