export const updateNodeProperty = (listNode, nodeCode, payload) => {
  const recurseListNodes = (re) => {
    return re.map((it) => {
      if ((it.nodeCode ? it.nodeCode : it.originalNodeCode) === nodeCode) {
        Object.keys(payload).forEach((key) => (it[key] = payload[key]));
      }

      if (it.childNodes && it.childNodes.length > 0) {
        recurseListNodes(it.childNodes);
      }
      return it;
    });
  };

  return recurseListNodes(listNode);
};

export const createNode = (listNode, parentNodeCode, newNode) => {
  const addItem = (re) => {
    return re.map((it) => {
      if ((it.nodeCode ? it.nodeCode : it.originalNodeCode) === parentNodeCode) {
        it.childNodes = [...it.childNodes, newNode];
        it.hasChildNodes = 1;
      }

      if (it.childNodes && it.childNodes.length > 0) {
        addItem(it.childNodes);
      }
      return it;
    });
  };

  return addItem(listNode);
};

export const removeNode = (listNode, nodeCode) => {
  const removeItem = (arr, nodeCode) => {
    return arr
      .filter((el) => (el.nodeCode ? el.nodeCode : el.originalNodeCode) !== nodeCode)
      .map((el) => {
        if (!el.childNodes || !Array.isArray(el.childNodes)) return el;
        el.childNodes = removeItem(el.childNodes, nodeCode);
        return el;
      });
  };

  return removeItem(listNode, nodeCode);
};

export const stringify = (obj) => {
  let cache = [];
  let str = JSON.stringify(obj, function(key, value) {
    if (typeof value === "object" && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference found, discard key
        return;
      }
      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
  cache = null; // reset the cache
  return str;
}
