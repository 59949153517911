import React, { useState, useEffect } from "react";
import Loading from "../../components/Loading";
import { getMenuList } from "../../services/NexiDigitalAPI";
import { useHistory } from "react-router-dom";
import { TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const MenuDesignList = () => {
  const [loading, setLoading] = useState(true);
  const [sectionList, setSectionList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const history = useHistory();

  const _getSectionsList = async () => {
    setLoading(true);
    try {
      const result = await getMenuList();
      if (result.data.success === true) {
        setSectionList(result.data.menu);
        setFilteredList(result.data.menu);
      } else {
        console.log("Erro na chamada de API");
      }
    } catch (err) {
      console.log("Erro na chamada de API.", err);
    } finally {
      setLoading(false);
    }
  };

  const normalize = (text) => {
    return text
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };

  const filterByName = (filterValue) => {
    setFilteredList([
      ...sectionList.filter((item) => normalize(item.name).includes(normalize(filterValue))),
    ]);
  };

  useEffect(() => {
    _getSectionsList();
  }, []);

  return (
    <div className="section-design-tree-container">
      {loading ? (
        <div style={{ height: 300 }}>
          <Loading center> Carregando lista de menus... </Loading>
        </div>
      ) : (
        <div>
          <div style={{ marginBottom: 10 }}>
            <TextField
              onChange={(event) => filterByName(event.target.value)}
              variant="outlined"
              placeholder="Procurar por nome"
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="section-design-header">
            <div>Nome</div>
            <div>ID Seção</div>
            <div>Tipo</div>
            <div>ID Tipo de Seção</div>
          </div>
          {filteredList?.map((item, index) => (
            <div
              className="section-design-item-container"
              key={index}
              onClick={() => history.push(`/menu-design/${item.idSection}`)}
            >
              <div className="section-design-item-value">{item.name}</div>
              <div className="section-design-item-value">{item.idSection}</div>
              <div className="section-design-item-value">{item.sectionTypeName}</div>
              <div className="section-design-item-value">{item.idSectionType}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuDesignList;
