import React, { FC } from "react";
import "./styles.scss"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import { useHistory } from "react-router-dom";
const HeaderCrumb: FC<{
  crumb: string
}> = ({crumb}) => {
  const history = useHistory()
  return (
    <div className="header-crumb-container">
      <div className="header-crumb--title">
        <button className="btnGoBack" onClick={() => history.goBack()}>
          <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
        </button>
        {crumb}
      </div>
    </div>
  )
}

export default HeaderCrumb;
