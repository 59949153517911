import React, { FC, useCallback, useEffect, useState, MouseEvent } from "react";
import "./styles.scss";
import { Button, Form } from "react-bootstrap";
import { departmentscampaign, getListCampaigns } from "services/NexiDigitalAPI";
type Props = {
  dataFilter: {
    campaign: number;
    department: string;
    positions: number;
  };
  handleChange: (data: { [key: string]: number | string }) => void;
  downloadEvent?: (event: MouseEvent<HTMLButtonElement>) => void;
  disabledButtonDownload?: boolean;
};
const HeaderFilterRanking: FC<Props> = ({
  dataFilter,
  handleChange,
  downloadEvent = null,
  disabledButtonDownload = false,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [listCampaign, setListCampaign] = useState<any>([]);
  const [listDepartments, setListDepartments] = useState<any>([]);
  const renderSelectCampaign = () =>
    listCampaign.map((elem, key) => (
      <option key={key} value={elem.idgamification_campaign}>
        {elem.name}
      </option>
    ));

  const renderSelectDerpartments = () =>
    listDepartments.map((elem, key) => (
      <option key={key} value={elem.name}>
        {elem.name}
      </option>
    ));
  const ListCampaing = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getListCampaigns();
      const _order = result.sort((a, b) => b.idgamification_campaign - a.idgamification_campaign);
      setListCampaign(_order);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, []);

  const ListDepartments = useCallback(async () => {
    if (dataFilter.campaign) {
      try {
        const result = await departmentscampaign(dataFilter.campaign);
        const { data } = result;
        setListDepartments(data.result);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    }
  }, [dataFilter.campaign]);
  useEffect(() => {
    ListCampaing();
  }, [ListCampaing]);
  useEffect(() => {
    ListDepartments();
  }, [ListDepartments]);

  return (
    <div className="header-filter-ranking">
      <Form.Group controlId="selectRanking">
        <Form.Control
          disabled={loading}
          as="select"
          className="select-ranking "
          // placeholder="Seleção de Campanha"
          value={dataFilter.campaign}
          onChange={(e) => handleChange({ department: 0, campaign: parseInt(e.target.value) })}
        >
          <option value={0}>{loading ? "carregando..." : "Seleção de Campanha"}</option>
          {renderSelectCampaign()}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="selectRanking">
        <Form.Control
          as="select"
          className="select-ranking"
          disabled={!dataFilter.campaign}
          value={dataFilter.department}
          onChange={(e) => {
            handleChange({ department: parseInt(e.target.value) === 0 ? 0 : e.target.value });
          }}
        >
          <option value={0}>Departamentos</option>
          {renderSelectDerpartments()}
        </Form.Control>
      </Form.Group>
      <Button
        variant="warning"
        className="button-peop button-yellow end-item"
        onClick={downloadEvent}
        disabled={disabledButtonDownload}
      >
        <span>Baixar Lista</span>
      </Button>
    </div>
  );
};

export default HeaderFilterRanking;
