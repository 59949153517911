import Loading from "components/Loading";
import { memo, useCallback, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import NewTermButton from "../NewTermButton";
import { Tooltip } from "@material-ui/core";
import IcoHistoryVersion from "assets/images/history-version.svg";
import ModalDialog from "../ModalDialog";
import { useHistory, useLocation } from "react-router-dom";
import ModalInactive from "../Dialogs/ModalInactive";
import EditRow from "./EditRow";
import DeleteRow from "./DeleteRow";
import ChangeStatusRow from "./ChangeStatusRow";
import ModalActive from "../Dialogs/ModalActive";
import HistoryClickRow from "./HistoryClickRow";
import ModalDelete from "../Dialogs/ModalDelete";
import { changeStatusTerms, deleteTerms } from "services/NexiDigitalAPI";
import SnackComponent from "components/SnackComponent";
import PreloaderBlur from "containers/PeopleCraft/PreloaderBlur";
import { Toast } from "../Toast";
const NoDataComponent = memo(() => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 400,
        width: "100%",
        color: "gray",
      }}
    >
      Não foi encontrado nenhum dado
    </div>
  );
});

const TableTerms = ({
  data,
  onChangePage,
  onChangeRowsPerPage,
  pagination,
  loading,
  totalRecords,
  search,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [loadingAction, setLoadingAction] = useState(false);
  const [controlModals, setControlModals] = useState({
    showModals: {
      edit: false,
      inactive: false,
      active: false,
      delete: false,
    },
    id: 0,
  });

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackInfo, setSnackInfo] = useState({
    message: "",
    severity: "success",
  });

  const redirectEditTerm = useCallback(
    (id) => {
      history.push(`${location.pathname}cadastrar-termo/${id}`);
    },
    [history, location.pathname],
  );

  const redirectHistoryTerm = useCallback(
    (id) => {
      history.push(`${location.pathname}historico-termo/${id}`);
    },
    [history, location.pathname],
  );

  const _deleteTerms = async (id) => {
    setLoadingAction(true);
    try {
      await deleteTerms(id);
      await search();
      Toast()
    } catch (e) {
      messageError(e?.response?.data?.message);
    } finally {
      setLoadingAction(false);
      closeModal("delete");
    }
  };

  const _changeStatusTerms = async (data) => {
    setLoadingAction(true);
    try {
      await changeStatusTerms(data);
      await search();
      Toast()
    } catch (e) {
      messageError(e?.response?.data?.message);
    } finally {
      setLoadingAction(false);
      closeModal(controlModals.showModals.active ? "active" : "inactive");
    }
  };

  const closeModal = (key) => {
    setControlModals((prev) => ({
      ...prev,
      showModals: {
        ...prev,
        [key]: false,
      },
    }));
  };

  const columns = useMemo(
    () => [
      {
        name: "Nome",
        selector: "name",
        wrap: true,
        style: { textAlign: "left" },
        reorder: false,
        sortable: false,
      },
      {
        name: "Data da última atualização",
        selector: "lastModifiedDate",
        wrap: true,
        style: { textAlign: "left" },
        reorder: false,
        sortable: false,
        cell: (data, index) =>
          data?.lastModifiedDate || data?.creationDate
            ? new Date(data?.lastModifiedDate || data?.creationDate).toLocaleDateString()
            : "",
      },
      {
        name: "Versão",
        selector: "versionNumber",
        wrap: true,
        style: { textAlign: "left" },
        reorder: false,
        sortable: false,
        cell: (data, index) => (data?.versionNumber ? `V${data?.versionNumber}` : ""),
      },
      {
        name: "Status",
        selector: "active",
        wrap: true,
        style: { textAlign: "left" },
        reorder: false,
        sortable: false,
        cell: (data, index) => (
          <div className={` button-status ${Boolean(data.active) ? "active" : "inactive"}`}>
            <span>{Boolean(data.active) ? "Ativo" : "Inativo"}</span>
          </div>
        ),
      },
      {
        name: "",
        wrap: true,
        width: "200px",
        style: { textAlign: "center" },
        reorder: false,
        sortable: false,
        cell: (data, index) => (
          <div style={{ display: "flex", gap: 10 }}>
            <EditRow
              setControlModals={setControlModals}
              data={data}
              redirectEditTerm={redirectEditTerm}
            />
            <DeleteRow
              data={data}
              handleClick={() => {
                if (Number(data?.versionNumber) > 1 || data.alreadySigned) {
                  return;
                }
                setControlModals((prev) => ({
                  showModals: {
                    ...prev,
                    delete: true,
                  },
                  id: data.idTermOfResponsibility,
                }));
              }}
            />
            <HistoryClickRow redirectHistoryTerm={redirectHistoryTerm} data={data} />
            <ChangeStatusRow data={data} setControlModals={setControlModals} />
          </div>
        ),
      },
    ],
    [redirectEditTerm, redirectHistoryTerm],
  );

  const messageError = (message) => {
    setSnackInfo({
      message: message || "Falha no sistema",
      severity: "error",
    });
    setOpenSnackBar(true);
  };

  const messageSuccess = (message) => {
    setSnackInfo({
      message: message || "Operação realizada com sucesso",
      severity: "Success",
    });
    setOpenSnackBar(true);
  };
  return (
    <div className="div-table">
      {loadingAction && <PreloaderBlur />}
      <SnackComponent
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        message={snackInfo.message}
        severity={snackInfo.severity}
      />
      <NewTermButton />
      <div style={{ width: "100%" }}>
        <DataTable
          columns={columns}
          customStyles={customTableStyles}
          data={data}
          progressComponent={<Loading containerStyle={{ height: 400 }}>Buscando termos...</Loading>}
          noDataComponent={<NoDataComponent />}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationTotalRows={totalRecords}
          paginationComponentOptions={paginationComponentOptions}
          progressPending={loading && data?.length === 0}
          paginationServer
          noHeader
          fixedHeader
          pagination
          highlightOnHover
        />
      </div>
      <ModalDialog
        showModal={controlModals.showModals.edit}
        setShowModal={() => {
          closeModal("edit");
        }}
        buttons={[
          {
            className: "button-modal-warning",
            label: "Continuar",
            onClick: () => {
              redirectEditTerm(controlModals.id);
            },
          },
        ]}
      >
        <div className="flex-body-modal">
          <div className="title-modal"> ATENÇÃO </div>
          <div className="title-subtitle">
            <strong>Este termo já foi aceito por alguns usuários.</strong> Ao seguir com a edição,
            uma nova versão será criada. Deseja continuar?
          </div>
        </div>
      </ModalDialog>
      <ModalInactive
        showModal={controlModals.showModals.inactive}
        closeModal={() => {
          closeModal("inactive");
        }}
        handleClickConfirmation={() =>
          _changeStatusTerms({
            idTermOfResponsibility: controlModals.id,
            active: 0,
          })
        }
      />
      <ModalActive
        showModal={controlModals.showModals.active}
        closeModal={() => {
          closeModal("active");
        }}
        handleClickConfirmation={(value) =>
          _changeStatusTerms({
            idTermOfResponsibility: controlModals.id,
            active: 1,
            availabilityMode: value,
          })
        }
      />
      <ModalDelete
        showModal={controlModals.showModals.delete}
        closeModal={() => {
          closeModal("delete");
        }}
        handleClickConfirmation={(value) => _deleteTerms(controlModals.id)}
      />
    </div>
  );
};

export default TableTerms;

const customTableStyles = {
  table: {
    style: {
      maxHeight: "70vh",
      overflow: "auto",
    },
  },
  headCells: {
    style: {
      backgroundColor: "#6b48ff",
      color: "white",
      paddingLeft: "30px",
      ">*": {
        ":focus": {
          color: "white !important",
          backgroundColor: "#6b48ff",
        },
        ":hover": {
          color: "white !important",
          backgroundColor: "#6b48ff",
        },
        ":active": {
          color: "white !important",
          backgroundColor: "#6b48ff",
        },
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "30px",
    },
  },
};

const paginationComponentOptions = {
  rowsPerPageText: "Termos por página",
  rangeSeparatorText: "de",
};
