import React, { createContext, useState } from "react";

export const NodeManagerContext = createContext({});

export const NodeManagerProvider = ({ children }) => {
  const [nodeData, setNodeData] = useState(null);

  return (
    <NodeManagerContext.Provider value={{ nodeData, setNodeData }}>
      {children}
    </NodeManagerContext.Provider>
  );
};

export default NodeManagerProvider;
