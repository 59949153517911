import "./styles.scss";

import React, { useState, useEffect } from "react";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import { Button, Modal } from "react-bootstrap";

import { AiOutlineClose } from "react-icons/ai";
import { GoAlert } from "react-icons/go";
import SearchIcon from "@material-ui/icons/Search";

import { getCertificatesList, replaceCertificate } from "../../../../../services/NexiDigitalAPI";

import ButtonWithLoading from "../../../../../components/ButtonWithLoading";

const ModalDeleteReplace = ({
  show,
  setShow,
  selectCertificate,
  loadingComponent,
  deleteEvent,
}) => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
    totalRecords: 999,
    searchString: "",
    data: [],
  });

  useEffect(() => {
    let componentMounted = true;

    if (loading && show) {
      getCertificatesList(
        pagination.limit,
        pagination.offset,
        pagination.searchString,
        selectCertificate.idCertificate,
      ).then((res) => {
        if (componentMounted) {
          setPagination((prev) => ({
            ...prev,
            totalRecords: res.totalRecords,
            data: res.result,
          }));
          setLoading(false);
        }
      });
    }

    return () => {
      componentMounted = false;
    };
  }, [pagination, show]);

  const handleFilter = (values) => {
    setLoading(true);
    setPagination(values);
  };

  function handleFilterText() {
    handleFilter({ ...pagination, searchString: search });
  }

  const onChangePage = (page) => {
    const offset = --page * pagination.limit;
    handleFilter({ ...pagination, offset: offset });
  };

  const onChangeRowsPerPage = (limit) => {
    handleFilter({ ...pagination, offset: 0, limit: limit });
  };

  const replaceCertificateEvent = (it) => {
    // console.log(it);
    setLoading(true);

    const obj = {
      idCurrentCertificate: selectCertificate.idCertificate,
      idNewCertificate: it.idCertificate,
    };

    replaceCertificate(selectCertificate.idCertificate, obj).then((res) => {
      setLoading(true);
      setTimeout(() => {
        deleteEvent();
        setShow(false);
        console.log("ddd");
      }, 1000 * 1);
    });
  };

  const columns = [
    {
      name: "Nome",
      selector: "title",
      sortable: true,
      center: true,
      wrap: true,
      width: "70%",
      style: { textAlign: "left", justifyContent: "flex-start" },
      // cell: (d) => ValidateData(d.title),
    },
    {
      name: "Ações",
      selector: "action",
      sortable: false,
      width: "30%",
      style: { textAlign: "center" },
      className: "actionBtns",
      cell: (d) => (
        <>
          <Button
            variant="danger"
            onClick={(e) => {
              replaceCertificateEvent(d);
            }}
            className={"btnEdit"}
          >
            Selecionar substítuto e excluir
          </Button>
        </>
      ),
    },
  ];

  const customTableStyles = {
    col: {
      style: {
        paddingTop: "10px",
        paddingBottom: "10px",
        textAlign: "left",
        justifyContent: "flex-start",
        // textAlign: "center",
      },
    },
    rows: {
      style: {
        paddingTop: "10px",
        paddingBottom: "10px",
        textAlign: "left",
        justifyContent: "flex-start",
        // textAlign: "center",
      },
    },
    headCells: {
      style: {
        paddingTop: "10px",
        paddingBottom: "10px",
        // textAlign: "center",
        width: "150px",
        fontWeight: "bold",
        fontSize: "20px",
        justifyContent: "flex-start !important",
        textAlign: "left",
      },
    },
    cells: {
      style: {
        textAlign: "left",
        justifyContent: "flex-start",
        // "&:not(:last-of-type)": {
        //   borderRightStyle: "solid",
        //   borderRightWidth: "1px",
        //   borderRightColor: "rgba(0, 0, 0, 0.57);",
        // },
      },
    },

    header: {
      style: {
        fontSize: "22px",
        justifyContent: "flex-start !important",
        textAlign: "left",
      },
    },
    headRow: {
      style: {
        fontSize: "22px",
        justifyContent: "flex-start",
        textAlign: "left",
        borderTopColor: "#000",
      },
    },
  };

  const tableData = {
    columns: columns,
    data: pagination.data,
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Usuários por página",
    rangeSeparatorText: "de",
  };

  return (
    <Modal
      show={show}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      className={`ModalDilogComponent`}
      centered
      size="xl"
      onHide={() => {}}
    >
      <div className="modal-support-blur modal-support-blur-warning" style={{ borderRadius: 5 }} />

      <Modal.Header className="modal-header-warning">
        <div
          onClick={() => {
            setShow(false);
            setLoading(true);
          }}
          className="modal-warning-close-container"
        >
          <AiOutlineClose style={{ fontSize: "30", color: "#7b8590" }} />
        </div>
      </Modal.Header>

      <div className="modal-principal-warning">
        <div className="modal-body-warning">
          <div className="form-group form-group-block-02">
            <div className="card">
              <div className="card-body template-card-body">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="containInfo">
                      <div className="blockAlert">
                        <GoAlert />
                      </div>
                      <div className="containText">
                        <h4>
                          O <b>{selectCertificate.title}</b> está vinculado a turmas com matrículas
                          ativas, para excluí-lo é preciso selecionar um substituto para ele.
                        </h4>
                      </div>
                    </div>

                    <div className="containerFilterBuscar">
                      <input
                        type="text"
                        className="form-control input-default"
                        id="groupDetail"
                        style={{ marginRight: 8 }}
                        aria-describedby="usuario"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Procurar por nome"
                      />
                      <ButtonWithLoading
                        onClickHandler={handleFilterText}
                        loading={loading}
                        text={
                          <>
                            <SearchIcon style={{ height: 18 }} /> Pesquisar{" "}
                          </>
                        }
                        style={{ width: "100%" }}
                      />
                    </div>

                    <DataTableExtensions
                      filter={false}
                      print={false}
                      {...tableData}
                      exportHeaders={false}
                      export={false}
                    >
                      <DataTable
                        columns={columns}
                        data={pagination.data}
                        // className={styles.dataTableFormat}
                        customStyles={customTableStyles}
                        progressPending={loading}
                        progressComponent={loadingComponent()}
                        noHeader={true}
                        noDataComponent="Não foi encontrado nenhum dado"
                        onChangePage={onChangePage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        paginationTotalRows={pagination.totalRecords}
                        paginationComponentOptions={paginationComponentOptions}
                        paginationServer
                        pagination
                      />
                    </DataTableExtensions>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDeleteReplace;
