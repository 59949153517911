import React, { useState, useEffect, useRef, useContext, Fragment } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Button } from "react-bootstrap";
import { RiDeleteBin5Line } from "react-icons/ri";

import CreateNewCondiciones from "./CreateNewCondiciones";

import { PACreateContext } from "../../../Contexts/PAContext";

const CreateNewRules = ({ rule, indice }) => {
  const { containerRules, setContainerRules, openConfirmModal } = useContext(PACreateContext);
  // const [itemsCondiciones, setItemsCondiciones] = useState([]);

  const removeRule = () => {
    openConfirmModal({
      text: `Você tem certeza que deseja remover essa regra?`,
      variantBtn: "danger",
      textBtn: "Remover regra",
      fnc: () => {
        const removeFilter = containerRules.filter((it) => it !== rule);
        setContainerRules(removeFilter);
      },
    });
  };

  const addCondiciones = () => {
    const newCondicione = {
      method: "CONTAINS",
    };

    const atualizarRules = containerRules.map((it) => {
      if (it === rule) {
        it.items = [...it.items, newCondicione];
      }

      return it;
    });

    setContainerRules(atualizarRules);
  };

  return (
    <div className="createNewRules">
      {indice > 1 && <div className={"newRulesOU"}>OU</div>}

      <div className="titleNewRules">
        <h4 className="titleRule">REGRA {indice}</h4>
        <OverlayTrigger
          placement={"top"}
          overlay={
            <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
              {"Excluir Regra"}
            </Tooltip>
          }
        >
          <button type="button" onClick={removeRule}>
            <RiDeleteBin5Line />
          </button>
        </OverlayTrigger>
      </div>

      <div className="listCreateNewCondicoes">
        {containerRules &&
          containerRules.length > 0 &&
          containerRules
            .filter((it) => it === rule)[0]
            .items.map((item, indice) => {
              return (
                <Fragment key={"condi_" + indice}>
                  <CreateNewCondiciones rule={rule} condicione={item} />
                </Fragment>
              );
            })}
      </div>
      <Button variant="warning" onClick={addCondiciones}>
        + Adicionar condição
      </Button>
    </div>
  );
};

export default CreateNewRules;
