import React, { useContext, useMemo } from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import { transformText, modelEvaluation } from "utils/Utils";
import { AuthContext } from "../../context/AuthContext";
import MenuBook from "@material-ui/icons/MenuBook";
import { FormOutlined, SmileOutlined } from "@ant-design/icons";
import HeaderCrumb from "./Components/HeaderCrumb";

const EvaluationManagement = () => {
  const { loginData } = useContext(AuthContext);
  const primaryColor = loginData?.primaryColor;
  const getMenuIcon = (icon) => {
    const IconComponent = icon;

    return <IconComponent style={{ color: "white", fontSize: 50 }} />;
  };

  const getRoutes = [
    {
      text: "Avaliação de conhecimento",
      type: modelEvaluation.KNOWLEDGE,
      icon: FormOutlined,
    },
    {
      text: "Avaliação de reação",
      type: modelEvaluation.REACTION,
      icon: SmileOutlined,
    },
    {
      text: "Avaliação de impacto",
      type: modelEvaluation.IMPACT,
      icon: MenuBook,
    },
  ];

  const HeaderCrumbLink = () => {
    return "Gestão de Avaliações";
    // return <Link to={`/evaluation-management`}>Gestão de Avaliações</Link>;
  };

  return (
    <div className="evaluationManagement-container">
      <HeaderCrumb>
        <>
          Gestão de Avaliações
        </>
      </HeaderCrumb>

      <div className="cockpit-cards-container">
        {getRoutes?.map((item, index) => {
          return (
            <Link
              to={`/gestao-de-avaliacoes/evaluation-control/${item.type}`}
              className="cockpit-card"
              key={index}
            >
              <div className="cockpit-card-header" style={{ backgroundColor: primaryColor }}>
                {getMenuIcon(item.icon)}
              </div>
              <div className="cockpit-card-content">{item.text}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default EvaluationManagement;
