import React, { ReactNode, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Divider, Tooltip } from "@material-ui/core";
import { MdExpandLess } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";
import "./style.scss";
const AccordionComponent: React.FC<{
  title: string;
  children: ReactNode;
  expanded?: boolean;
  tooltip?: string;
  removeIcon?: boolean;
  disableExpand?: boolean;
  hideDivider?: boolean
}> = ({
  title,
  children,
  expanded = true,
  tooltip = "",
  removeIcon = false,
  disableExpand = false,
  hideDivider = false
}) => {
  const [_expanded, setExpanded] = useState<boolean>(expanded);

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      className={`accordionComponent ${disableExpand && 'disabledExpand'}`}
      expanded={_expanded}
      onChange={(e) => !disableExpand && setExpanded(!_expanded)}
    >
      <AccordionSummary expandIcon={removeIcon ? null : <MdExpandLess />} className="summary">
        <span className="title-accordion">
          {title}
          {Boolean(tooltip) && (
            <Tooltip title={tooltip} placement="top-start">
              <div className="tootltip">
                <IoMdInformationCircleOutline />
              </div>
            </Tooltip>
          )}
        </span>
      {!hideDivider &&  <Divider />}
      </AccordionSummary>

      <AccordionDetails> {children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionComponent;
