export const nodeTypes = {
  TITLE: "TitleSection",
  TEXT: "TextSection",
  LIST: "List",
  CAROUSEL: "Carrousel",
  GRID: "Grid",
  CATALOG: "Catalog",
  MASTER_BANNER: "MasterBanner",
  CALENDAR: "Calendar",
  IN_PROGRESS: "EnrollmentsInProgress",
  MEETINGS: "Meeting",
  SHOW_OTHERS: "ShowOthers",
  RECOMMENDATION: "Recommendation",
  CAMPAIGN: "Campaign",
};

export const learningNodeTypes = {
  ORGANIZATION: "ORGANIZATION",
  LEARNING: "LEARNING",
};

export const contentTypes = {
  EXTERNAL_LINK: 'external-link',
  ARTICLE: "article",
  VIDEO: "video",
  AUDIO: "audio",
  HTML_PACKAGE: "html-package",
  NEW_PAGE: "new-page",
  NEW_PAGE_NAVIGATION: "new-page-navigation",
  NEW_BANNER_IMAGE: "new-banner-image",
  SCORM: "scorm",
  FILE: "file",
  KNOWLEDGE_ASSESMENT: "knowledge-assessment",
  REACTION_ASSESMENT: "reaction-assessment",
};

export const evaluationTypes = {
  REACTION: { indice: "1", url: "evaluation-manager/reaction" },
  KNOWLEDGE: { indice: "2", url: "evaluation-manager/knowledge" },
};

export const elementTypes = {
  NODE: "NODE",
  BANNER_IMAGE: "IMAGE",
  CONTENT: "CONTENT",
  REDIRECT: "REDIRECT",
};
