import React from "react";
import { Button, Modal } from "react-bootstrap";
import { PiWarning } from "react-icons/pi";
import "./index.scss";

type Props = {
  showModal: boolean;
  setShowModal: (status: boolean) => void;
  title?: string;
  subTitle?: string;
  acceptButtonText?: string;
  acceptButtonAction?: () => void;
  secondaryButtonText?: string;
  secondaryButtonAction?: () => void;
  type?: "default" | "warning";
  size?: 'sm' |  'lg' | 'xl'
};

const ModalV2 = ({
  showModal,
  setShowModal,
  title,
  subTitle,
  acceptButtonAction,
  acceptButtonText,
  secondaryButtonText,
  secondaryButtonAction,
  type = "default",
  size = 'lg'
}: Props) => {
  return (
    <Modal size={size} centered show={showModal} onHide={() => setShowModal(false)} backdrop>
      <Modal.Body className="content-page-modal-body">
        <div
          className="content-page-modal-icon"
          style={{
            backgroundColor: type === "default" ? "#ffedcc" : "#fee2e2",
          }}
        >
          <PiWarning size={40} color={type === "default" ? "#feac0e" : "#ff0027"} />
        </div>
        <div className="content-page-modal-text-container">
          <div className="content-page-modal-title" dangerouslySetInnerHTML={{  __html: title}}></div>
          <div className="content-page-modal-description" dangerouslySetInnerHTML={{  __html: subTitle}}></div>
        </div>
      </Modal.Body>
      <Modal.Footer className="content-page-modal-footer">
        <Button
          type="submit"
          className="btn content-page-button"
          style={{
            backgroundColor: type === "default" ? 'auto' : "#ff0027",
            fontWeight: "bold",
            color: type === "default" ? '#ffc107' : "white",
          }}
          variant={`${type === "default"  && 'outline-warning'}`}

          onClick={() => acceptButtonAction && acceptButtonAction()}
        >
          {acceptButtonText}
        </Button>
        {secondaryButtonText && (
          <Button
            type="submit"
            style={{
              fontWeight: "bold",
              color: type === "default" ? '#ffc107' : "white",
            }}
            className="btn content-page-button ml-2"
            variant={`${type === "default" && 'outline-warning'}`}
            onClick={() => secondaryButtonAction && secondaryButtonAction()}
          >
            {secondaryButtonText}
          </Button>
        )}
        <Button
          type="submit"
          variant="outline-secondary"
          className="btn content-page-button ml-2"
          onClick={() => setShowModal(false)}
        >
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalV2;
