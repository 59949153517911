import React from "react";
import { Button, Modal } from "react-bootstrap";
import { PiWarning } from "react-icons/pi";
import './styles.scss'
const ModalDialog = ({
  ico = null,
  showModal = false,
  setShowModal = false,
  size = 'xl',
  type = 'default',
  children = null,
  cancelButtonName = 'Cancelar',
  buttons = []
}) => {
  return (
    <Modal  centered show={showModal} onHide={() => setShowModal(false)} backdrop>
        <Modal.Body className="content-page-modal-body">
          <div
            className="content-page-modal-icon"
            style={{
              backgroundColor: type === "default" ? "#ffedcc" : "#fee2e2",
            }}
          >
          { ico ? ico : <PiWarning size={40} color={type === "default" ? "#feac0e" : "#ff0027"} />  }
          </div>
          {children}
        </Modal.Body>
        <Modal.Footer className="content-page-modal-footer">
          <Button
            type="submit"
            variant="outline-secondary"
            className="btn content-page-button ml-2"
            onClick={() => setShowModal(false)}
          >
            {cancelButtonName}
          </Button>
          {
            Boolean(buttons.length) &&
            buttons.map( (elem, index) =>
              <Button
                key={`button-${index}`}
                type="submit"
                className={`btn content-page-button ${elem?.className}`}
                variant={`${elem?.variant}`}

                onClick={() => elem.onClick &&  elem.onClick()}
              >
                {elem.label}
              </Button>
            )
          }
        </Modal.Footer>
    </Modal>
  )
}

export default ModalDialog
