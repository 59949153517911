import React, { useEffect, useState, Fragment } from "react";
import FilterResults from "react-filter-search";

import { getUsersWorkTeam } from "../../../../../services/NexiDigitalAPI";
import { getProfileInfo } from "../../../../../utils/Utils";

import AccordionItens from "./AccordionItens";
import _ from "underscore";
import { Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";

const TeamItens = ({idUser}) => {

  let user = getProfileInfo();

  const [searchText, setSearchText] = useState(undefined);
  const [onlyDirects, setOnlyDirects] = useState(undefined);
  const [itens, setItens] = useState();
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
    totalRecords: 999,
  });

  const getData = async () => {
    setLoading(true);
    const containerApi = await getUsersWorkTeam({
      limit: idUser ? 999 : pagination.limit,
      offset: pagination.offset,
      idUser: idUser || user.idUser,
      searchText,
      onlyDirects: searchText ? onlyDirects : '1'
    });
    setItens(containerApi.result);
    setPagination((prev) => ({ ...prev, totalRecords: containerApi.totalRecords }));
    setLoading(false);
  };

  const subimitSearch = (e) => {
    e.preventDefault();
    if (pagination.offset === 0) {
      getData();
    } else {
      setPagination((prev) => ({ ...prev, offset: 0 }));
    }
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Usuários por página",
    rangeSeparatorText: "de",
  };

  const onChangePage = (page) => {
    setPagination((prev) => ({ ...prev, offset: --page * prev.limit }));
  };

  const onChangeRowsPerPage = (limit) => {
    setPagination((prev) => ({ ...prev, limit, offset: 0 }));
  };

  useEffect(() => {
      getData();
  }, [pagination.offset, pagination.limit]);

  return itens === undefined ? (
    "Carregando..."
  ) : (
    <>
     { !idUser ?
      <div className="form-group">
        <div className="card">
          <div className="card-body template-card-body">
            <div className="input-container">
              <Form
                onSubmit={subimitSearch}
                className="input-group col-md-8"
                style={{ alignItens: "center", padding: 0 }}
              >
                  <input
                    type="text"
                    className="form-control input-default"
                  id="groupDetail"
                  aria-describedby="search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Pesquisar nome"
                />
                <select
                  disabled={Boolean(!searchText)}
                  className="form-control input-default select"
                  value={searchText ? onlyDirects : 1}
                  onChange={(e) => setOnlyDirects(e.target.value)}
                >
                  <option value="1">Diretos</option>
                  <option value="0">Todos</option>
                </select>

                <button type="submit" disabled={loading} className="btn btn-primary">
                  <SearchIcon style={{ height: 18 }} /> Filtrar{" "}
                  {loading && (
                    <CircularProgress
                      size={18}
                      style={{ margin: "0px 0px -3px 10px", color: "white" }}
                    />
                  )}
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
      : ''}
      <div className={"accordionTeam" + (idUser ? ' subItemTeam ' : '')}>
        <FilterResults
          value=""
          data={itens}
          renderResults={(results) => (
            <>
              {results.length === 0 && !loading && !idUser && <p>Não foi encontrado nenhum dado</p>}
              {results.map((item, i) => {
                return (
                  <Fragment key={item.idUser}>
                    <AccordionItens
                      model={"userTeam"}
                      item={item}
                      idUser={item.idUser}
                      indKey={item.idUser}
                      endPoint={0}
                      nodeCode={0}
                      idEnrollment={item.idEnrollment}
                    />
                  </Fragment>
                );
              })}
              { !idUser ?
              <DataTable
                className="d-none"
                data={itens}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                paginationTotalRows={pagination.totalRecords}
                paginationComponentOptions={paginationComponentOptions}
                paginationServer
                pagination
              />
              : '' }
            </>
          )}
        />
      </div>
    </>
  );
};

export default TeamItens;
