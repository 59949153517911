import React, { useContext, useState } from "react";
import ContentOptionsPopover from "../ContentOptionsPopover";
import "../index.css";

import { Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { FaRegEdit } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import { CircularProgress } from "@material-ui/core";
import { contentTypes } from "../NodeTypes";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { ElementCard } from "../ContentTypes/ElementCard";
import ImportContentModal from "../../ImportContentModal";
import {
  linkElementToBlock,
  removeBlockElement,
  getMenuData,
  getLearningNode,
  getNodesBreadcrumb,
} from "../../../../services/NexiDigitalAPI";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory, useParams } from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CatalogModal from "../CatalogModal";
import { NodeManagerContext } from "../NodeManagerProvider";
import { statusTypes } from "containers/PeopleCraft/ContentPage/StatusTypes";

import ModalDialog from "../../../PeopleCraft/Modals/ModalDialog";

const CardList = React.memo(function CardList({
  cards,
  removeNode,
  loadingDeleteBlockElement,
  permissionLearningPage,
  blockData,
  nodeData,
}) {
  const history = useHistory();
  const params = useParams();
  const getParentNodeCode = (nodeCode) => {
    let newNodeCode = nodeCode;
    let index = newNodeCode.lastIndexOf(".");
    if (index !== -1) {
      newNodeCode = newNodeCode.substring(0, index);
    }
    return newNodeCode;
  };

  const [currentElement, setCurrentElement] = useState(null);
  const [showEditRedirect, setShowEditRedirect] = useState(false);

  const originFilter = (it) => {
    let orginTxt = "";

    it.parentNodes.map((el, ind) => {
      if (ind !== 0) {
        orginTxt += " • " + el.title;
      } else {
        orginTxt += el.title;
      }
    });

    return orginTxt;
  };

  const callEditElenent = (elementItem) => {
    const { elementType, data } = elementItem;

    if (elementType === "CONTENT") {
      history.push(
        `/cms/content/${elementItem.idElement}?content=${elementItem.data?.idContentType}`,
      );
    } else if (elementType === "EVALUATION") {
      const urlEval =
        data.evaluationType === "REACTION" ? "reaction-evaluation" : "knowledge-evaluation";

      history.push(
        `/${urlEval}/${params?.nodeCode}/${blockData?.idBlock}/${elementItem.idElement}/1`,
      );
    } else if (elementType === "IMAGE") {
      history.push(
        `/image-banner/${elementItem.idBlockElement}/${elementItem.idElement}/${params.nodeCode}/1`,
      );
    } else {
      const parentNodeCode = getParentNodeCode(data?.originalNodeCode);
      history.push(`/content-page/${parentNodeCode}/${data?.originalNodeCode}`);
    }
  };

  const handleEditElementRedirect = (elementItem) => {
    const { idCompany } = getMenuData();
    const { data } = elementItem;
    const pageShareRedirect = data.redirect === 1 && data.idCompany === idCompany;
    const pageShareOrigin = data.usedAsRedirect === 1 && data.redirect === 0;

    // console.log(data);

    if (pageShareRedirect || pageShareOrigin) {
      const titleEditRedirect = "Atenção, esta é uma página compartilhada?";
      const textEditRedirect = pageShareOrigin
        ? "<p>Esta página foi compartilhada com outra estrutura de aprendizagem, as alterações realizadas aqui serão refletidas em todos os locais em que está página estiver. Deseja continuar com a edição?<p>"
        : "<p>Esta página foi compartilhada de outra estrutura de aprendizagem, as alterações realizadas aqui serão refletidas em todos os locais em que esta página estiver. Deseja continuar com a edição?<p>";

      setCurrentElement({
        elementItem: elementItem,
        title: titleEditRedirect,
        text: textEditRedirect,
      });

      //BreadCrump apenas para páginas que são Redirect
      if (pageShareRedirect) {
        getNodesBreadcrumb(data?.originalNodeCode).then((res) => {
          const originEditRedirect = `${textEditRedirect} <p><b>Origem:</b> ${originFilter(
            res.data,
          )}</p>`;
          setCurrentElement({
            elementItem: elementItem,
            title: titleEditRedirect,
            text: originEditRedirect,
          });
        });
      }

      setShowEditRedirect(true);
    } else {
      callEditElenent(elementItem);
    }
  };

  const editPermition = (elementItem, nodeData) => {
    return (
      ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
        nodeData.nodeType !== "ORGANIZATION" &&
        elementItem.elementType !== "IMAGE") ||
      !permissionLearningPage
      // || elementItem.data.redirect
    );
  };

  return cards?.map((elementItem, index) => (
    <Draggable
      draggableId={String(elementItem.idBlockElement)}
      index={index}
      key={String(elementItem.idBlockElement)}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="node-manager-content-item-wrapper"
        >
          <ModalDialog
            title={currentElement ? currentElement.title : ""}
            text={currentElement ? currentElement.text : ""}
            show={showEditRedirect}
            setShow={setShowEditRedirect}
            btnCall={{
              className: "",
              variantBtn: "warning",
              text: "Sim, alterar em todos os locais.",
              callEvent: () => {
                callEditElenent(currentElement.elementItem);
              },
            }}
          />

          <div className="node-manager-node-item-draggable-container" {...provided.dragHandleProps}>
            <div className="node-manager-node-item-draggable-dots">
              <DragIndicatorIcon style={{ fontSize: 35, color: "lightgray", marginBottom: -8 }} />
              <DragIndicatorIcon style={{ fontSize: 35, color: "lightgray" }} />
            </div>
          </div>
          <div className="node-manager-node-item-content">
            <ElementCard
              element={elementItem}
              permissionLearningPage={permissionLearningPage}
              idBlock={blockData.idBlock}
            />
          </div>
          <div className="node-manager-node-item-actions-container">
            <div
              className={`node-manager-node-item-actions ${
                editPermition(elementItem, nodeData) ? "" : "node-purple"
              }`}
              onClick={() => handleEditElementRedirect(elementItem, nodeData)}
              style={{
                pointerEvents: editPermition(elementItem, nodeData) ? "none" : "auto",
              }}
            >
              <span style={{ color: editPermition(elementItem, nodeData) ? "grey" : "black" }}>
                Editar
              </span>
              <FaRegEdit style={{ fontSize: 22 }} />
            </div>
            <div
              className={`node-manager-node-item-actions ${
                ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                  nodeData?.nodeType !== "ORGANIZATION") ||
                !permissionLearningPage
                  ? ""
                  : "node-red"
              }`}
              onClick={() => removeNode(elementItem)}
              style={{
                pointerEvents:
                  ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                    nodeData?.nodeType !== "ORGANIZATION") ||
                  !permissionLearningPage ||
                  loadingDeleteBlockElement
                    ? "none"
                    : "auto",
              }}
            >
              <span
                style={{
                  color:
                    ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                      nodeData?.nodeType !== "ORGANIZATION") ||
                    !permissionLearningPage
                      ? "grey"
                      : "black",
                }}
              >
                Remover
              </span>
              {loadingDeleteBlockElement === elementItem.idBlockElement ? (
                <CircularProgress size={22} />
              ) : (
                <FiTrash2 style={{ fontSize: 22 }} />
              )}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  ));
});

export const ElementList = ({
  hasMaxCards,
  blockData,
  baseNodeLevel,
  isCatalog,
  permissionLearningPage,
  index,
}) => {
  const { nodeData, setNodeData } = useContext(NodeManagerContext);
  const elementList = nodeData.blocks[index].elements;
  const [showImportContentModal, setShowImportContentModal] = useState(false);
  const [showCatalogModal, setShowCatalogModal] = useState(false);
  const [selectedContentType, setSelectedContentType] = useState("");
  const [loadingLinkData, setLoadingLinkData] = useState(false);
  const [loadingDeleteBlockElement, setLoadingDeleteBlockElement] = useState(false);

  const getElementTypeByContentType = () => {
    switch (selectedContentType) {
      case contentTypes.ARTICLE:
        return "CONTENT";
      case contentTypes.AUDIO:
        return "CONTENT";
      case contentTypes.VIDEO:
        return "CONTENT";
      case contentTypes.HTML_PACKAGE:
        return "CONTENT";
      case contentTypes.NEW_PAGE:
        return "NODE";
      case contentTypes.NEW_BANNER_IMAGE:
        return "IMAGE";
      default:
        return "CONTENT";
    }
  };

  const linkContentToBlock = (contentData, contentType) => {
    if (showImportContentModal) setShowImportContentModal(false);
    const newContentType = contentType || getElementTypeByContentType();
    setLoadingLinkData(blockData.idBlock);
    const linkBlockData = {
      idBlock: blockData.idBlock,
      idElement:
        newContentType === "CONTENT"
          ? contentData.idContentItem
          : newContentType === "EVALUATION"
          ? contentData.idEvaluation
          : newContentType === "NODE"
          ? contentData.idNode
          : newContentType === "IMAGE"
          ? contentData.idImage
          : null,
      elementType: newContentType,
    };
    linkElementToBlock(blockData.idBlock, linkBlockData)
      .then((res) => {
        // const idBlockElement = res.data.idBlockElement;
        // const { title, text } = contentData;
        // // console.log("contentData", contentData);
        // const newElement = {
        //   data: {
        //     ...contentData,
        //     title: title || text,
        //     mandatory: newContentType === "CONTENT" ? 1 : 0,
        //   },
        //   elementType: newContentType,
        //   idBlockElement,
        //   idElement: contentData.idContentItem || contentData.idEvaluation,
        // };

        // let newNodeData = { ...nodeData };
        // if (newNodeData.blocks[index].elements === undefined) {
        //   newNodeData.blocks[index].elements = [];
        // }
        // newNodeData.blocks[index].elements.push(newElement); // push new element to element list
        // setNodeData(newNodeData);

        getLearningNode(nodeData.nodeCode).then((res) => {
          const nodeDataResult = res.data.result;

          setNodeData(nodeDataResult);
        });
      })
      .catch((err) => {
        console.log("Error linking", err?.request || err);
      })
      .finally(() => {
        setLoadingLinkData(false);
      });
  };

  const removeNode = (node) => {
    const payload = node.elementType === "NODE" ? { nodeCode: node.data.nodeCode } : {};

    setLoadingDeleteBlockElement(node.idBlockElement);

    removeBlockElement(node.idBlockElement, payload)
      .then((res) => {
        const filteredElementList = elementList?.filter(
          (obj) => obj.idBlockElement !== node.idBlockElement,
        );

        let newNodeData = { ...nodeData };

        newNodeData.blocks[index].elements = filteredElementList;

        setNodeData(newNodeData);
      })

      .catch((err) => {
        console.log("error delete", err);
      })

      .finally(() => {
        setLoadingDeleteBlockElement(false);
      });
  };

  return (
    <div style={{ flex: 1, padding: "15px 0px" }}>
      {showCatalogModal && (
        <CatalogModal
          denyAction={() => setShowCatalogModal(false)}
          returnNodeData={(nodeData) => {
            linkContentToBlock(nodeData, "NODE");
            setShowCatalogModal(false);
          }}
        />
      )}
      {showImportContentModal && (
        <ImportContentModal
          nodeData={nodeData}
          denyAction={() => setShowImportContentModal(false)}
          blockData={blockData}
          returnNodeData={(nodeData) => linkContentToBlock(nodeData, "NODE")}
          returnContentData={(contentData, elementType) =>
            linkContentToBlock(contentData, elementType)
          }
        />
      )}
      <Droppable type="element-list" droppableId={`element-list-${index}`}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="node-manager-content-list-wrapper"
          >
            <CardList
              cards={elementList}
              removeNode={removeNode}
              loadingDeleteBlockElement={loadingDeleteBlockElement}
              permissionLearningPage={permissionLearningPage}
              blockData={blockData}
              nodeData={nodeData}
            />
            {provided.placeholder}

            {loadingLinkData && (
              <Skeleton
                variant="rect"
                width={600}
                height={90}
                style={{ borderRadius: 10, marginBottom: 20 }}
              />
            )}
            <div className="node-manager-type-list-add-container">
              {isCatalog ? (
                <button
                  disabled={
                    [statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                    nodeData?.nodeType !== "ORGANIZATION"
                  }
                  type="submit"
                  className="btn content-page-button"
                  style={{ backgroundColor: "#feac0e" }}
                  onClick={() => setShowCatalogModal(true)}
                >
                  Selecionar Página de Conteúdo{" "}
                  <AddCircleOutlineIcon style={{ marginLeft: 10, marginRight: 0, fontSize: 20 }} />
                </button>
              ) : (
                <>
                  <ContentOptionsPopover
                    nodeData={nodeData}
                    disabled={
                      [statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                      nodeData?.nodeType !== "ORGANIZATION"
                    }
                    linkContentToBlock={linkContentToBlock}
                    setSelectedContentType={setSelectedContentType}
                    baseNodeLevel={baseNodeLevel}
                    blockData={blockData}
                  />
                  <CloudUploadIcon
                    onClick={() => setShowImportContentModal(true)}
                    style={{ fontSize: 40, marginLeft: 10 }}
                    className={`content-options-add ${
                      [statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                      nodeData?.nodeType !== "ORGANIZATION"
                        ? "content-options-disabled"
                        : ""
                    }`}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </Droppable>
    </div>
  );
};
