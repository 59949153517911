import "./styles.modules.scss";

import React, { useState, useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";

import ModalCreateQuestion from "../../../Modals/ModalCreateQuestion";
import KEModalQuestionBank from "../../../Modals/ModalQuestionBank";

import { RECreateContext } from "../../Contexts/REContext";
import BoxListQuestion from "../BoxListQuestion";

const Box = (props) => {
  const [showCreateQuestion, setShowCreateQuestion] = useState(false);
  const [showQuestionBank, setShowQuestionBank] = useState(false);

  const infoInit = {
    title: "Título da sua avaliação",
    description: "",
  };
  const [boxInit, seBoxInit] = useState(null);

  const {
    preloadInit,
    imageEvaluation,
    titleEvaluation,
    descriptionEvaluation,
    setQuestionsList,
    questionsList,
    contextTagsInit,
    evaluationModel,
  } = useContext(RECreateContext);

  useEffect(() => {
    if (boxInit === null && titleEvaluation && titleEvaluation.length > 0) {
      seBoxInit(true);
    }
  }, [titleEvaluation]);

  return (
    <>
      {preloadInit ? (
        <div className="KEBoxPreloader" />
      ) : (
        <div className="KEBox">
          <div className="KEBoxTop">
            {imageEvaluation ? (
              <div className="KEImg">
                <img src={imageEvaluation} alt="Imagem" className="" />
              </div>
            ) : (
              <div className="KEico"></div>
            )}
            <div className="KEtxt">
              <h4>{boxInit ? titleEvaluation : infoInit.title}</h4>
              <p>{descriptionEvaluation || infoInit.description}</p>
            </div>
          </div>
          <div className="KEBoxMed">
            <h3>Quadro de questões</h3>

            <BoxListQuestion />
          </div>

          <div className="KEBoxFooter">
            <Button
              variant="warning"
              className=""
              onClick={(e) => {
                setShowCreateQuestion(true);
              }}
            >
              Adicionar questões
            </Button>
            <Button
              variant="primary"
              className=""
              onClick={(e) => {
                setShowQuestionBank(true);
              }}
            >
              Importar do banco de questões
            </Button>
          </div>

          <ModalCreateQuestion
            title="Adicionar questões"
            show={showCreateQuestion}
            setShow={setShowCreateQuestion}
            returnContentData={null}
            setQuestionsList={setQuestionsList}
            questionsList={questionsList}
            contextTagsInit={contextTagsInit}
            evaluationModel={evaluationModel}
          />

          {evaluationModel && (
            <KEModalQuestionBank
              show={showQuestionBank}
              setShow={setShowQuestionBank}
              setQuestionsList={setQuestionsList}
              evaluacionType={evaluationModel.evaluacionType}
            />
          )}
        </div>
      )}
    </>
  );
};

export default withRouter(Box);
