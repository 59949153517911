import "./style.scss";
import React, { useEffect, useState, useRef } from "react";
import imageCompression from "browser-image-compression";
import CircularProgress from "@material-ui/core/CircularProgress";
import { uploadImageApi } from "services/NexiDigitalAPI";
import ImageCrop from "components/ImageCrop";

type Props = {
  className?: string;
  reference: any;
  data: any;
  defaultPrefixName?: string;
};

function ContentImageUploadV2({ className = "", data, defaultPrefixName, reference }: Props) {
  const imagesToUpload: any = useRef([]);
  const [defaultUploadedImage, setDefaultUploadedImage] = useState(null || "");
  const [carregaImagem, setCarregaImagem] = useState(false);
  const baseImage = useRef(
    data.image !== null && data.image !== ""
      ? data.image
          .split("/")
          .slice(0, data.image.length - 1)
          .splice(-1, 1)
          .join()
          .split(".")[0]
      : defaultPrefixName
      ? `${defaultPrefixName}-${Date.now()}`
      : `image-${Date.now()}`,
  );

  const [saveImageBtn, setSaveImageBtn] = useState(false);
  const [imagemUploadStatus, setImagemUploadStatus] = useState(false);
  const [mensagemImagemUpload, setMensagemImagemUpload] = useState("");
  // const editContent = useRef(data.image === null || data.image === "" ? false : true);

  const contentData = useRef(data);
  const urlLocationRef = useRef(null);

  useEffect(() => {
    if (reference) {
      reference.current = {
        uploadImagem,
        onCropImage,
        uploadDefaultImage,
        imagesToUpload,
      };
    }
  }, [reference]);

  const uploadImagem = async (e: any) => {
    setSaveImageBtn(false);

    if (e) {
      e.preventDefault();
    }


    setMensagemImagemUpload("");

    const options = {
      maxSizeMB: 0.9,
      maxWidthOrHeight: 1920,
      fileType: "image/webp",
    };

    const baseImageObject = imagesToUpload.current.filter(
      (x: any) => x.imageFormatName.indexOf("-format-landscape-large") != -1,
    );

    if (baseImageObject.length > 0) {
      imagesToUpload.current.push({
        imageFormatName: baseImage.current,
        file: baseImageObject[0].file,
        aspectRatio: baseImageObject[0].aspectRatio,
        blob: baseImageObject[0].blob,
      });
    }

    const promise = new Promise(async (resolve, reject) => {
      setCarregaImagem(true);
      if (imagesToUpload.current.length == 0) {
        resolve(null);
      }

      while (imagesToUpload.current.length > 0) {
        const currentImageObject = imagesToUpload.current.shift();
        const imageFile = currentImageObject.file;

        try {
          const compressedFile = await imageCompression(imageFile, options);
          const filename = currentImageObject.imageFormatName;
          const dataFormat = new FormData();
          if (compressedFile) {
            dataFormat.append("fileName", filename);
            dataFormat.append("fileData", compressedFile);

            const response = await uploadImageApi(dataFormat)
            if(response){
              if (response.data?.error) {
                setCarregaImagem(false);
                setImagemUploadStatus(false);
                setMensagemImagemUpload("Erro ao fazer upload do arquivo");
              } else {
                if (baseImageObject.length >= 0) {
                  if(filename === baseImage.current){
                    resolve(response.data?.location);
                    successImagemAll();
                    return
                  }

                }
                if (imagesToUpload.current.length === 0) {
                  resolve(null)
                }

              }
            }else{
              setCarregaImagem(false);
              setImagemUploadStatus(false);
              setMensagemImagemUpload("Erro ao fazer upload do arquivo");
            }
          }
        } catch (error) {
          console.log(error);
        }

        // if (imagesToUpload.current.length == 0) {
        //   successImagemAll();
        // }
      }
    });
    promise.finally(() =>  setCarregaImagem(false))
    return promise;
  };

  const successImagemAll = () => {
    setCarregaImagem(false);
    setImagemUploadStatus(true);
    setMensagemImagemUpload("Imagens atualizadas com successo.");
  };

  const onCropImage = async (croppedImage: any) => {
    imagesToUpload.current = imagesToUpload.current.filter(
      (x: any) => x.imageFormatName !== croppedImage.imageFormatName,
    );

    imagesToUpload.current.push(croppedImage);

    if (imagesToUpload.current.length > 0) {
      setSaveImageBtn(true);
    }
  };

  const uploadDefaultImage = async (arquivo: any) => {
    const imageFile = arquivo.files[0];
    setDefaultUploadedImage(window.URL.createObjectURL(imageFile));
  };

  return (
    <div className={`pctContentImageNodeUpload ${className}`}>
      <div className="node-images-crop-body">
        {!carregaImagem && (
          <label className="content-page-file-button btn">
            {contentData.current.image || defaultUploadedImage
              ? "SUBSTITUIR TODAS AS IMAGENS"
              : "ADICIONAR IMAGEM"}
            <input
              type="file"
              className="form-control-file"
              id="imagem"
              accept="image/png, image/jpeg, image/gif"
              onChange={(e) => {
                uploadDefaultImage(e.target);
              }}
            />
          </label>
        )}

        {carregaImagem ? (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingRight: 20,
            }}
          >
            <div className="savingImages">
              <CircularProgress />
              <p>Aguarde, atualizando as imagens...</p>
            </div>
          </div>
        ) : (
          <div className="crop-images-container">
            {(contentData.current.image || defaultUploadedImage) && (
              <>
                <ImageCrop
                  imageSource={contentData.current.image}
                  uploadedImage={defaultUploadedImage}
                  label={"Paisagem (1920x900px)"}
                  imageFormatName={`${baseImage.current}-format-landscape-large`}
                  aspectRatio={32 / 15}
                  onCrop={onCropImage}
                />

                <ImageCrop
                  imageSource={contentData.current.image}
                  uploadedImage={defaultUploadedImage}
                  label={"Paisagem (1920x690px)"}
                  imageFormatName={`${baseImage.current}-format-landscape-middle`}
                  aspectRatio={64 / 23}
                  onCrop={onCropImage}
                />

                <ImageCrop
                  imageSource={contentData.current.image}
                  uploadedImage={defaultUploadedImage}
                  label={"Paisagem (1920x500px)"}
                  imageFormatName={`${baseImage.current}-format-landscape-small`}
                  aspectRatio={96 / 25}
                  onCrop={onCropImage}
                />

                <ImageCrop
                  imageSource={contentData.current.image}
                  uploadedImage={defaultUploadedImage}
                  label={"Paisagem (1920x1440px)"}
                  imageFormatName={`${baseImage.current}-format-landscape-xsmall`}
                  aspectRatio={4 / 3}
                  onCrop={onCropImage}
                />

                <ImageCrop
                  imageSource={contentData.current.image}
                  uploadedImage={defaultUploadedImage}
                  label={"Quadrado (1920x1920px)"}
                  imageFormatName={`${baseImage.current}-format-square`}
                  aspectRatio={1}
                  onCrop={onCropImage}
                />

                <ImageCrop
                  imageSource={contentData.current.image}
                  uploadedImage={defaultUploadedImage}
                  label={"Retrato (1440x1920px)"}
                  imageFormatName={`${baseImage.current}-format-portrait`}
                  aspectRatio={3 / 4}
                  onCrop={onCropImage}
                />
              </>
            )}
          </div>
        )}

        <div className="content-page-img-feedback">
          {imagemUploadStatus ? (
            <span style={{ color: "green", marginTop: 5 }}>{mensagemImagemUpload}</span>
          ) : (
            <span style={{ color: "red", marginTop: 5 }}>{mensagemImagemUpload}</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContentImageUploadV2;
