import React, { useState, createContext, useRef } from "react";

export const CreateEvaluationImpactContext = createContext();

export const ContextEvaluationImpact = ({ children }) => {
  
  // const evaluationGoToPrevQuestion = () => {
   
  // };

  
  const value = {

    // evaluationGoToPrevQuestion,

  };

  return (
    <CreateEvaluationImpactContext.Provider value={value}>
      {children}
    </CreateEvaluationImpactContext.Provider>
  );
};
