import Loading from "components/Loading";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import "./styles.scss";
import { getUsersSecondary } from "services/NexiDigitalAPISecondary";
import SemImagemSmall from "../../assets/images/sem-imagem-small.jpg";

const NoDataComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 400,
        width: "100%",
        color: "gray",
      }}
    >
      Não foi encontrado nenhum dado
    </div>
  );
};

const customTableStyles = {
  table: {
    style: {
      maxHeight: "70vh",
      overflow: "auto",
    },
  },
  headCells: {
    style: {
      backgroundColor: "#6b48ff",
      color: "white",
      paddingLeft: "30px",
    },
  },
  cells: {
    style: {
      paddingLeft: "30px",
    },
  },
};

const paginationComponentOptions = {
  rowsPerPageText: "Usuários por página",
  rangeSeparatorText: "de",
};

const ImportManager = ({ onClickManager, selectedManager, idUser }) => {
  const concatManager = `${selectedManager?.name} - ${selectedManager?.email}`;
  const [showManagerModal, setShowManagerModal] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [searchText, setSearchText] = useState(undefined);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
    totalRecords: 999,
  });

  const columns = [
    {
      name: "Nome",
      selector: "name",
      wrap: true,
      style: { textAlign: "left" },
      reorder: false,
      sortable: false,
      cell: (d) => (
        <div className="manager-profile-container">
          <img alt="manager-profile" className="manager-profile" src={d.photo || SemImagemSmall} />
          <bold>{d.name || "-"}</bold>
        </div>
      ),
    },
    {
      name: "E-mail",
      selector: "email",
      wrap: true,
      sortable: false,
      style: { textAlign: "center" },
      cell: (d) => <>{d.email || "-"}</>,
    },
    {
      name: "Cargo",
      selector: "role",
      wrap: true,
      sortable: false,
      style: { textAlign: "center" },
      cell: (d) => <>{d.role || "-"}</>,
    },
    {
      name: "",
      wrap: true,
      sortable: false,
      style: { textAlign: "center" },
      cell: (manager) => (
        <div
          className="button-peop button-yellow"
          style={{ textWrap: "nowrap", height: 30 }}
          onClick={() => handleSelectManager(manager)}
        >
          Selecionar
        </div>
      ),
    },
  ];

  function _getUsers(customPagination, clearSearchText) {
    setLoadingUsers(true);
    const queryParams = {
      offset: customPagination?.offset || pagination.offset,
      limit: customPagination?.limit || pagination.limit,
      searchString: clearSearchText ? "" : customPagination?.searchText || searchText,
      excludeIds: idUser
    };

    getUsersSecondary(queryParams)
      .then(({ data }) => {
        if (data.success) {
          const usuarios = data.userList;
          const totalRecords = data.totalRecords;

          setPagination({ ...pagination, totalRecords: totalRecords });
          setUsersList(usuarios);
        }
      })
      .catch((e) => console.log("Erro ao listar usuarios", e))
      .finally(() => {
        setLoadingUsers(false);
      });
  }

  const handleSelectManager = (manager) => {
    setShowManagerModal(false);
    onClickManager(manager);
  };

  const onChangePage = (page) => {
    _getUsers({
      ...pagination,
      offset: page === 1 ? 0 : (page - 1) * pagination.limit,
    });
  };

  const onChangeRowsPerPage = (limit, page) => {
    _getUsers({
      ...pagination,
      limit,
      offset: page === 1 ? 0 : (page - 1) * limit,
    });
  };

  useEffect(() => {
    if (showManagerModal) {
      _getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showManagerModal]);

  return (
    <>
      <Modal
        size="xl"
        centered
        show={showManagerModal}
        onHide={() => setShowManagerModal(false)}
        backdrop
      >
        <Modal.Body className="user-manager-modal-body">
          <div className="user-manager-close-icon" onClick={() => setShowManagerModal(false)}>
            <AiOutlineClose style={{ fontSize: 20 }} />
          </div>
          <div className="user-manager-text-container">
            <div className="user-manager-title">
              <div className="content-page-modal-title">Selecionar Gestor imediato</div>
              <div className="editUserSearchInfo">
                <AiOutlineSearch style={{ left: 10 }} />
                <Form.Control
                  value={searchText}
                  onKeyDown={(input) => {
                    if (input.key === "Enter") {
                      _getUsers({ searchText: input.target.value });
                    }
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder={`Pesquisar...`}
                />
                <button
                  onClick={() => {
                    setSearchText("");
                    _getUsers({ searchText: "", offset: 0 }, true);
                  }}
                >
                  <AiOutlineClose />
                </button>
              </div>
            </div>

            <div style={{ position: "relative" }}>
              {loadingUsers && usersList.length > 0 && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    backdropFilter: "blur(3px)",
                  }}
                >
                  <Loading>Carregando Usuários...</Loading>
                </div>
              )}
              <DataTable
                columns={columns}
                data={usersList}
                customStyles={customTableStyles}
                progressComponent={
                  <Loading containerStyle={{ height: 400 }}>Carregando usuários...</Loading>
                }
                noDataComponent={<NoDataComponent />}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                paginationTotalRows={pagination.totalRecords}
                paginationComponentOptions={paginationComponentOptions}
                progressPending={loadingUsers && usersList?.length === 0}
                paginationServer
                noHeader
                fixedHeader
                pagination
                highlightOnHover
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Form.Group>
        <Row>
          <Col lg="7" md="7" xs="12" sm="7" className="mb-5">
            <Form.Label>
              Gestor imediato <span style={{ color: "gray" }}>(Opcional)</span>
            </Form.Label>
            <div style={{ display: "flex" }}>
              <Form.Control
                type="text"
                placeholder="Selecionar Gerente..."
                disabled
                value={selectedManager ? concatManager : null}
              />
              <div
                className="button-peop button-yellow ml-3"
                style={{ textWrap: "nowrap" }}
                onClick={() => setShowManagerModal(true)}
              >
                Selecionar gestor
              </div>
            </div>
          </Col>
        </Row>
      </Form.Group>
    </>
  );
};

export default ImportManager;
