import React, { useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import moment from "moment";

const Workload = ({
  workload,
  setWorkload,
  hideTitle = false,
  hideHour = false,
  disabled = false,
}) => {
  const [workloadHour, setWorkloadHour] = useState(0);
  const [workloadMinute, setWorkloadMinute] = useState(0);

  const transformWorkload = (workloadValue) => {
    if (hideHour) {
      const minutes = Math.floor(workloadValue / 60);
      setWorkloadMinute(minutes);
    } else {
      const hours = Math.floor(workloadValue / 3600);
      const secondsRemaining = workloadValue % 3600;

      let minutes = Math.floor(secondsRemaining / 60);
      minutes = Math.min(minutes, 59);

      setWorkloadHour(hours);
      setWorkloadMinute(minutes);
    }
  };

  const handleWorkloadHour = (hour) => {
    if (isNaN(hour) || hour < 0) return;
    setWorkloadHour(hour);
    const hourDuration = moment.duration(hour, "hours");
    const hourAsSeconds = hourDuration.asSeconds();

    const minuteDuration = moment.duration(workloadMinute, "minutes");
    const minuteAsSeconds = minuteDuration.asSeconds();

    const workloadValue = hourAsSeconds + minuteAsSeconds;

    setWorkload(workloadValue);
  };

  const handleWorkloadMinute = (minute) => {
    // limit minute value to 59 if hour field is enabled
    if (!hideHour && (isNaN(minute) || minute >= 60)) return;
    if (minute < 0) return;
    setWorkloadMinute(minute);
    const hourDuration = moment.duration(workloadHour, "hours");
    const hourAsSeconds = hourDuration.asSeconds();

    const minuteDuration = moment.duration(minute, "minutes");
    const minuteAsSeconds = minuteDuration.asSeconds();

    const workloadValue = hourAsSeconds + minuteAsSeconds;

    setWorkload(workloadValue);
  };

  const handleWorkloadSeconds = (seconds) => {
    // limit seconds value to 59 if hour field is enabled
    if (!hideHour && (isNaN(seconds) || seconds >= 60 || seconds < 0)) return;

    setWorkload(seconds);
  };

  const hasOnlySeconds = workload > 0 && workload < 60;

  useEffect(() => {
    transformWorkload(workload);
  }, [workload]);

  return (
    <InputGroup style={{ width: "100%" }}>
      {!hideTitle && (
        <InputGroup.Prepend>
          <InputGroup.Text style={{ minWidth: 200 }}>Carga horária</InputGroup.Text>
        </InputGroup.Prepend>
      )}

      {!hideHour && (
        <>
          <InputGroup.Prepend>
            <InputGroup.Text>Horas</InputGroup.Text>
          </InputGroup.Prepend>
          <input
            value={workloadHour}
            className="col-md-1 form-control"
            maxLength={15}
            placeholder="Horas"
            type="number"
            id="node-horas"
            aria-describedby="node-horas"
            onChange={(e) => handleWorkloadHour(Number(e.target.value))}
            disabled={disabled}
          />
        </>
      )}

      <InputGroup.Prepend>
        <InputGroup.Text>Minutos</InputGroup.Text>
      </InputGroup.Prepend>
      <input
        value={workloadMinute}
        className="col-md-1 form-control"
        maxLength={15}
        placeholder="Minutos"
        type="number"
        id="node-minutos"
        aria-describedby="node-minutos"
        onChange={(e) => handleWorkloadMinute(Number(e.target.value))}
        disabled={disabled}
      />
      {hasOnlySeconds && (
        <>
          <InputGroup.Prepend>
            <InputGroup.Text>Segundos</InputGroup.Text>
          </InputGroup.Prepend>
          <input
            value={Math.floor(workload)}
            className="col-md-1 form-control"
            maxLength={15}
            placeholder="Segundos"
            type="number"
            id="node-seconds"
            aria-describedby="node-seconds"
            onChange={(e) => handleWorkloadSeconds(Number(e.target.value))}
            disabled
          />
        </>
      )}
    </InputGroup>
  );
};

export default Workload;
