import React, { useState } from "react";
import { Col, Form, Button } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import FilterWrapper from "../../../../../components/FilterWrapper";
import styles from "./styles.module.scss";

const Filtro = ({ actionFilter, loading }) => {
  const [value, setValue] = useState({
    idPurchase: "",
    status: 1,
  });

  const submitFilter = () => {
    actionFilter(value);
  };

  const idChangeHandler = (event) => {
    const idValue = event.target.value;
    setValue({
      ...value,
      idPurchase: idValue,
    });
  };

  const statusChangeHandler = (event) => {
    const statusValue = event.target.value;
    setValue({
      ...value,
      status: statusValue,
    });
  };

  return (
    <FilterWrapper>
      <Form style={{ marginTop: 30, marginBottom: 30 }}>
        <Form.Row className="align-items-end" style={{ justifyContent: "space-between" }}>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="dateStart">Número da compra</Form.Label>
            <input
              type="number"
              name="idPurchase"
              onChange={idChangeHandler}
              value={value.idPurchase}
              style={{ width: "100%" }}
              min="1"
            />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="dateStart">Número da compra</Form.Label>
            <select
              name="status"
              onChange={statusChangeHandler}
              value={value.status}
              style={{ width: "100%" }}
            >
              <option value="1"> Ativa </option>
              <option value="0"> Cancelado </option>
            </select>
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Group className="mb-0">
              <Button
                onClick={submitFilter}
                style={{
                  marginTop: 10,
                  backgroundColor: localStorage.getItem("secondaryColor"),
                }}
              >
                {loading ? (
                  <>Carregando...</>
                ) : (
                  <>
                    Aplicar filtro <FaFilter style={{ marginLeft: 5 }} />
                  </>
                )}
              </Button>
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    </FilterWrapper>
  );
};

export default Filtro;
