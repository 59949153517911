import React, {  useContext, useMemo } from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import { transformText, getMenuIconTypes } from "utils/Utils";
import { AuthContext } from "../../context/AuthContext";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import BarChartIcon from '@material-ui/icons/BarChart';
import HeaderCrumb from "./Components/HeaderCrumb";

const Gamification = () => {

  const { loginData } = useContext(AuthContext);
  const primaryColor = loginData?.primaryColor;
  const  getMenuIcon = (icon) => {
    const IconComponent = icon

    return <IconComponent style={{ color: "white", fontSize: 50 }} />;
  }

  const getRoutes = useMemo(
    () =>{
      return [
          {
            text: 'Gestão de Campanhas',
            icon: EmojiEventsIcon
          },
          {
            text: 'Ranking',
            icon:  BarChartIcon
          }
      ]
    },
    []
  );

  return (

    <div className="gamification-container">
      <HeaderCrumb  crumb="Gamificação"/>

      <div className="cockpit-cards-container">
      {getRoutes?.map((item, index) => {
          return (
            <Link
              to={`${transformText(item.text)}`}
              className="cockpit-card"
              key={index}
            >
              <div className="cockpit-card-header" style={{ backgroundColor: primaryColor }}>
              {getMenuIcon(item.icon)}
              </div>
              <div className="cockpit-card-content">{item.text}</div>
            </Link>
          );
        })}

      </div>


    </div>
  );
};

export default Gamification;
