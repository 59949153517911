import HeaderNavigation from "components/PeopleCraft/HeaderNavigation";
import PreloaderBlur from "containers/PeopleCraft/PreloaderBlur";
import ToggleSwitch from "containers/Profiles/Components/ToggleSwitch";
import React, { FC, Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  getEmailSenders,
  getNotificationsConfigurations,
  postEmailSenders,
  postNotificationsConfigurations,
  toggleCustomEmailSenders,
} from "services/NexiDigitalAPI";
import Radios from "./components/Radios";
import "./styles.scss";
import { AuthContext } from "../../../context/AuthContext";
import { Button, Col, Form, Row } from "react-bootstrap";
import { RiSave3Line } from "react-icons/ri";

export enum Section {
  SYSTEM = "SYSTEM",
  LEARNING = "LEARNING",
  TEAM = "TEAM",
}

export enum Options {
  DEACTIVATED = "DEACTIVATED",
  MANDATORY = "MANDATORY",
  OPTIONAL = "OPTIONAL",
}
type Props = {
  optionOne: Options;
  optionTwo: Options;
};

enum AuthorizationStatus {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  FAILED = "FAILED",
  VERIFIED = "VERIFIED",
  DEFAULT = "",
}

type EmailSender = {
  name: string;
  email: string;
  authorizationStatus: AuthorizationStatus;
  active: number;
  dateCreation: string;
  updated: boolean;
};

export type NotificationConfig = {
  description: string;
  idApp: number;
  idCompany: string;
  idNotification: number;
  idNotificationcompanyapp: number;
  mandatory: number;
  status: Options | string;
  type: string;
};
const Notification: FC<Props> = () => {
  const { loginData } = useContext(AuthContext);
  const primaryColor = loginData.primaryColor;

  const [loading, setLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<{
    [key in Section]: NotificationConfig[];
  }>({
    [Section.SYSTEM]: [],
    [Section.LEARNING]: [],
    [Section.TEAM]: [],
  });

  const _getConfigurations = useCallback(async () => {
    setLoading(true);

    getNotificationsConfigurations()
      .then((res) => {
        const { data } = res;
        setNotifications(groupBySection(data.result));
      })
      .catch((err) => {
        setNotifications({
          [Section.SYSTEM]: [],
          [Section.LEARNING]: [],
          [Section.TEAM]: [],
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const groupBySection = (notifications) => {
    return notifications.reduce((result, notification) => {
      const { section, ...rest } = notification;

      result[section] = result[section] || [];
      result[section].push({ ...rest });

      return result;
    }, {});
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>, item, section) => {
    setLoading(true);

    try {
      const result = await postNotificationsConfigurations([item]);
      const _section = notifications[section].map((elem) => {
        if (elem.type === item.type) {
          elem = item;
        }
        return elem;
      });
      setNotifications((prev) => ({
        ...prev,
        [section]: _section,
      }));
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const [customEmailSenderEnabled, setCustomEmailSenderEnabled] = useState<boolean>(false);
  const defaultEmailSender: EmailSender = {
    name: "",
    email: "",
    active: 0,
    authorizationStatus: AuthorizationStatus.DRAFT,
    dateCreation: "",
    updated: false,
  };
  const [emailSender, setEmailSender] = useState<EmailSender>(defaultEmailSender);
  const [originalEmailSender, setOriginalEmailSender] = useState<EmailSender>(defaultEmailSender);
  const [savingEmailSender, setSavingEmailSender] = useState<boolean>(false);

  const findCurrentEmailSender = (emailSenders: EmailSender[]): EmailSender => {
    if (emailSenders.length === 0) {
      return defaultEmailSender;
    }

    return emailSenders.sort((a, b) => (a.dateCreation > b.dateCreation ? -1 : 1))[0];
  };

  const _getEmailSenders = useCallback(async () => {
    setLoading(true);

    getEmailSenders()
      .then((res) => {
        const { data } = res;
        const _currentEmailSender = findCurrentEmailSender(data.result);
        setEmailSender(_currentEmailSender);
        setOriginalEmailSender(Object.assign({}, _currentEmailSender));
        setCustomEmailSenderEnabled(Boolean(_currentEmailSender.active));
      })
      .catch((err) => {
        setEmailSender(defaultEmailSender);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    _getConfigurations();
  }, [_getConfigurations, _getEmailSenders]);

  useEffect(() => {
    _getEmailSenders();
  }, [_getEmailSenders]);

  const cancelEmailSender = () => {
    setEmailSender(Object.assign({}, originalEmailSender));
  };

  const saveEmailSender = (event) => {
    event.preventDefault();

    setSavingEmailSender(true);
    postEmailSenders({
      email: emailSender.email,
      name: emailSender.name,
    })
      .then((res) => {
        const { data } = res;
        const _createdEmailSender = data;
        setEmailSender(_createdEmailSender);
        setOriginalEmailSender(Object.assign({}, _createdEmailSender));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSavingEmailSender(false);
      });
  };

  const toggleCustomEmailSenderEnabled = (active: boolean) => {
    setLoading(true);

    toggleCustomEmailSenders({ active }).finally(() => {
      setLoading(false);
    });
  };

  const renderStatus = (status: AuthorizationStatus) => {
    switch (status) {
      case AuthorizationStatus.DRAFT:
        return "Em criação";
      case AuthorizationStatus.PENDING:
        return "Pendente de Validação";
      case AuthorizationStatus.FAILED:
        return "Falha na Validação";
      case AuthorizationStatus.VERIFIED:
        return "Ativo";
      default:
        return "";
    }
  };

  const NotificationsArray = useMemo(() => {
    return [
      {
        title: "Atividades de treinamentos",
        subtitle: "Enviar notificações sobre as atividades de treinamento quando:",
        type: Section.LEARNING,
      },
      {
        title: "Atividades de turmas",
        subtitle: " Enviar notificações sobre as atividades de turmas quando:",
        type: Section.TEAM,
      },
      {
        title: "Atividades gerais na plataforma",
        subtitle: " Enviar notificações sobre as atividades gerais quando:",
        type: Section.SYSTEM,
      },

    ];
  }, []);

  return (
    <div className="notificationWraper">
      <HeaderNavigation title={"Notificações"} />
      {loading && <PreloaderBlur />}
      <div className="notification-container">
        <div className="box-notification">
          {NotificationsArray.map((elem, index) => (
            <Fragment key={`${elem.title}-${index}`}>
              <p className="title-notification">{elem.title}</p>
              <p className="subtitle-notification">
              {elem.subtitle}
              </p>
              <Radios
                notifications={notifications[elem.type]}
                section={elem.type}
                updateOption={handleChange}
              />
            </Fragment>
          ))}


          <hr style={{ margin: "30px 0" }} />
          <p className="title-notification">Configurações adicionais</p>
          <p className="subtitle-notification">
            <ToggleSwitch
              onChange={() => {
                setCustomEmailSenderEnabled((prevState) => !prevState);
                toggleCustomEmailSenderEnabled(!customEmailSenderEnabled);
              }}
              checked={customEmailSenderEnabled}
              color={primaryColor}
            />
            <span>
              <strong>Habilitar endereço personalizado de e-mail</strong>
            </span>
          </p>
          {customEmailSenderEnabled && (
            <>
              <p className="subtitle-notification">
                Ao ativar a funcionalidade, será enviado para o e-mail cadastrado uma solicitação de
                autorização da Amazon Webservices para ativar o serviço. A funcionalidade ficará
                ativa somente após essa confirmação. Cheque a caixa de spam, caso não localize o
                e-mail na caixa de entrada.
              </p>
              <p className="subtitle-notification">
                <Form onSubmit={(event) => saveEmailSender(event)}>
                  <Form.Group>
                    <Row>
                      <Col lg="12" md="12" xs="12" sm="12">
                        <Form.Label>Nome do remetente*</Form.Label>
                        <Form.Control
                          required
                          value={emailSender.name}
                          disabled={savingEmailSender}
                          type="text"
                          placeholder="Digite um nome"
                          maxLength={100}
                          onChange={(e) =>
                            setEmailSender({
                              ...emailSender,
                              name: e.target.value,
                              authorizationStatus: AuthorizationStatus.DRAFT,
                              updated: true,
                            })
                          }
                        />
                      </Col>
                      <Col lg="12" md="12" xs="12" sm="12">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control
                          required
                          value={emailSender.email}
                          disabled={savingEmailSender}
                          type="email"
                          placeholder="Digite um e-mail"
                          maxLength={100}
                          onChange={(e) =>
                            setEmailSender({
                              ...emailSender,
                              email: e.target.value,
                              authorizationStatus: AuthorizationStatus.DRAFT,
                              updated: true,
                            })
                          }
                        />
                      </Col>
                      <Col lg="12" md="12" xs="12" sm="12">
                        <Form.Label>Status</Form.Label>
                        <Form.Text>{renderStatus(emailSender.authorizationStatus)}</Form.Text>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Button
                    disabled={!emailSender.updated}
                    onClick={cancelEmailSender}
                    variant="secondary"
                    style={{ marginRight: 15 }}
                  >
                    <div>Cancelar</div>
                  </Button>

                  <Button disabled={!emailSender.updated} variant="success" type="submit">
                    {savingEmailSender ? (
                      <div style={{ display: "flex" }}>
                        <RiSave3Line size={22} style={{ marginBottom: 3, marginRight: 10 }} />
                        Salvando <div className="loader loader-white" style={{ marginLeft: 15 }} />
                      </div>
                    ) : (
                      <div>
                        <RiSave3Line size={22} style={{ marginBottom: 3, marginRight: 10 }} />
                        Salvar
                      </div>
                    )}
                  </Button>
                </Form>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;
