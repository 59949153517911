import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useContext, useEffect, useRef, useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "../../assets/css/ContentListGroup.css";
import { addNewContent, contentItensGroups } from "../../services/NexiDigitalAPI";

import Popover from "@material-ui/core/Popover";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import moment from "moment";
import _ from "underscore";
import ZoomIcon from "../../assets/images/zoom-icon.png";
import { AuthContext } from "../../context/AuthContext";

import { Form, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { RxMagnifyingGlass } from "react-icons/rx";
import "../../assets/css/ContentListGroup.css";
import Loading from "../../components/Loading";
import { capitalizeFirstLetter, getMenuRoutes, getZoomUrl } from "../../utils/Utils";
import Filtro from "./Filtro";

const tipoStatus = [
  {
    nome: "Avaliação pendente",
    background: "#ffc107",
    color: "#212529",
  },
  {
    nome: "Aprovado",
    background: "#28a745",
    color: "#fff",
  },
  {
    nome: "Cancelado",
    background: "#de6767",
    color: "#fff",
  },
];

const NoDataComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 400,
        width: "100%",
        color: "gray",
      }}
    >
      Não foi encontrado nenhum dado
    </div>
  );
};

const contentTypes = [
  { name: "Artigo", id: 5, originalName: "single page" },
  { name: "Vídeo", id: 2, originalName: "video" },
  { name: "Audio", id: 1, originalName: "audio" },
  { name: "Pacote Html", id: 4, originalName: "site" },
  { name: "Arquivo", id: 18, originalName: "File" },
  { name: "SCORM", id: 26, originalName: "SCORM" },
  { name: "Link Externo", id: 27, originalName: "Link" },
];

function ContentListGroup(props) {
  const [data, setData] = useState([]);
  const [dataState, setDataState] = useState(false);
  const zoom_auth_url = getZoomUrl();
  const zoom_token = localStorage.getItem("zoom_token");
  const [loading, setLoading] = useState(true);
  const [idContentType, setIdContentType] = useState(props.item.idContentType);
  const [idContentGroup, setIdContentGroup] = useState(props.item.idContentGroup);
  const [contentName, setContentName] = useState(props.item.name);
  const isMountedRef = useRef(null);
  const [showLoginZoom, setShowLoginZoom] = useState(idContentType === 8 && zoom_token === null);
  const [textFilter, setTextFilter] = useState("");
  const [idContentTypeFilter, setIdContentTypeFilter] = useState();
  /* [Permission Module] - Permissions states */
  const [permissionCreateContent, setPermissionCreateContent] = useState(false);
  const [permissionUpdateContent, setPermissionUpdateContent] = useState(false);

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });

  const [totalRecords, setTotalRecords] = useState(0);

  const isMeeting = idContentType === 8;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { loginData } = useContext(AuthContext);
  const secondaryColor = loginData.secondaryColor;

  async function _getContentItensGroups() {
    await contentItensGroups(
      props.item.idContentGroup,
      "",
      pagination.offset,
      pagination.limit,
      textFilter,
      idContentTypeFilter,
    )
      .then((res) => {
        if (isMountedRef.current) {
          const dados = res.data.content;
          const sortByTitle = _.sortBy(dados, "text");
          const sortByStatus = _.sortBy(sortByTitle, "active");
          const filtered = sortByStatus.filter((item) => {
            if (
              item.idContentType != 16 &&
              item.idContentType != 17 &&
              item.idContentType != 23 &&
              item.idContentType != 24
            ) {
              // removendo capitulos e paginas de jornadinhas/impact da listagem
              return item;
            }
          });

          setTotalRecords(res.data.totalRecords);
          setData(filtered); // antes estava assim: setData(sortByStatus)
          setDataState(res.data.success);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  /* [Permission Module] - Menu Options */
  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();

    const objCurrentMenu = menuOptionList.filter((menu) => menu.text === "Gestão de Conteúdo");

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);

      getPermissionsPage(resources);
    }
  }

  /* [Permission Module] - Permission attrs in page by login data information */
  function getPermissionsPage(resources) {
    try {
      if (Boolean(resources[0].filter((menu) => menu.name === "Criar Contéudo").length))
        setPermissionCreateContent(true);
      if (Boolean(resources[0].filter((menu) => menu.name === "Editar Conteúdo").length))
        setPermissionUpdateContent(true);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    /* [Permission Module] - Function calls */
    getMenuOption();

    isMountedRef.current = true;
    if (isMountedRef) {
      _getContentItensGroups();
    }

    return () => (isMountedRef.current = false);
  }, []);

  const hasZoomUrl = (url) => {
    return url?.includes("zoom.us");
  };

  const handleNovoConteudo = (noZoomAuth = false) => {
    addNewContent({
      text: "",
      description: "",
      idContentType: `${idContentType}`,
      idContentGroup: `${idContentGroup}`,
    }).then((res) => {
      if (res.data.success) {
        // Se conseguiu criar o conteúdo,  redireciona para o editor deste conteúdo
        props.history.push(
          `/cms/content/${res.data.idContentItem}${
            noZoomAuth ? "?noZoomAuth=true&isCreating=true" : "?isCreating=true"
          }`,
        );
      }
    });
  };

  const actionFilter = async (values) => {
    //aqui vai entrar o filtro quando a Bianka resolver o problema da AIP
  };

  const onChangePage = (page) => {
    setPagination((prev) => ({ ...prev, offset: --page * prev.limit }));
  };

  const onChangeRowsPerPage = (limit) => {
    setTotalRecords(pagination.limit);
    setPagination((prev) => ({ ...prev, limit, offset: 0 }));
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Conteúdos por página",
    rangeSeparatorText: "de",
  };

  const doFilter = (e) => {
    e.preventDefault();

    if (pagination.offset > 0) {
      setTotalRecords(pagination.limit);
      setPagination((prev) => ({ ...prev, offset: 0 }));
    } else {
      _getContentItensGroups();
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      _getContentItensGroups();
    }
  }, [pagination.offset, pagination.limit]);

  return (
    <div>
      <div className="header-title-wrapper">
        <div className="header-title">{contentName}</div>
      </div>
      <div align="right" style={{ padding: "10px 0px" }}>
        <Filtro actionFilter={""} loading={""} />
        {permissionCreateContent && (
          <button
            type="button"
            className="btn btn-secondary"
            onClick={(e) => {
              isMeeting ? setAnchorEl(e.currentTarget) : handleNovoConteudo();
            }}
          >
            <AddCircleIcon /> Novo(a) {contentName}
          </button>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className="meeting-popover-container">
            <div
              className="meeting-popover-item"
              onClick={() =>
                !showLoginZoom ? window.open(zoom_auth_url, "_self") : handleNovoConteudo()
              }
            >
              <img src={ZoomIcon} style={{ height: 25, width: 25, marginRight: 10 }} />
              Zoom{" "}
              {showLoginZoom && <span style={{ color: "gray", fontSize: 12 }}>(Autenticar)</span>}
            </div>
            <div className="meeting-popover-item" onClick={() => handleNovoConteudo(true)}>
              <MoreHorizIcon style={{ height: 25, width: 25, marginRight: 5 }} />
              Outro
            </div>
          </div>
        </Popover>
      </div>

      <Form style={{ display: "flex" }} onSubmit={doFilter}>
        <div className="input-group mb-3" style={{ width: 400 }}>
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              <RxMagnifyingGlass style={{ width: 20, height: 20 }} />
            </span>
          </div>
          <input
            type="text"
            className="form-control mr-3"
            id="node-find"
            aria-describedby="node-find"
            placeholder="Procurar por título ou descrição"
            value={textFilter || ""}
            onChange={(e) => setTextFilter(e.target.value)}
            // onKeyDown={input => {
            //   if (input.key === 'Enter') {
            //     setFilter(true);
            //   }
            // }}
          />
        </div>
        <div className="input-group mb-3 mr-3" style={{ width: 400 }}>
          <InputGroup.Prepend>
            <InputGroup.Text>Tipo de conteúdo</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            as="select"
            value={idContentTypeFilter}
            onChange={(e) => {
              const value = Number(e.target.value);
              setIdContentTypeFilter(value === 0 ? undefined : value);
            }}
          >
            <option value={""}>Todos</option>
            {contentTypes.map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
          </Form.Control>
        </div>
        <button
          type="submit"
          className="btn content-page-button"
          style={{
            backgroundColor: "rgb(107, 72, 255)",
            color: "white",
          }}
        >
          Filtrar
        </button>
      </Form>

      {loading ? (
        <Loading center> Carregando... </Loading>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <Thead style={{ backgroundColor: secondaryColor }}>
                <Tr>
                  <Th align="center" style={{ color: "#FFF" }}>
                    Imagem
                  </Th>
                  <Th align="center" style={{ color: "#FFF" }}>
                    Titulo
                  </Th>
                  <Th align="center" style={{ color: "#FFF" }}>
                    Tipo
                  </Th>
                  <Th align="center" style={{ color: "#FFF" }}>
                    Tags
                  </Th>
                  <Th align="center" style={{ color: "#FFF" }}>
                    Data de publicação
                  </Th>
                  <Th align="center" style={{ color: "#FFF" }}>
                    Data de fim da publicação
                  </Th>
                  <Th align="center" style={{ color: "#FFF" }}>
                    Status
                  </Th>
                  {permissionUpdateContent && (
                    <Th align="center" style={{ color: "#FFF" }}>
                      Ação
                    </Th>
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {data &&
                  data.map((row) => (
                    <Tr key={row.idContentItem}>
                      <Td align="center">
                        <div
                          className="image-card"
                          style={{
                            //backgroundImage: `url( ${ ( (row.image).indexOf('http') !== -1 ? row.image : 'https://'+JSON.parse(localStorage.getItem('appDetails')).adminBucket+'/common/app0000002/'+row.image ) } )`, //'https://bedigital.net.br/common/app0000002/'
                            backgroundImage: `url( ${row.image} )`, //'https://bedigital.net.br/common/app0000002/'
                            height: 130,
                            width: 150,
                            color: "#fff",
                            display: "inline-grid",
                          }}
                        >
                          {" "}
                          {!row.image ? "Sem imagem" : ""}
                          {hasZoomUrl(row.referenceLink) && (
                            <img src={ZoomIcon} className="has-zoom-icon" />
                          )}
                        </div>
                      </Td>
                      <Td align="center" style={{ minWidth: "35%" }}>
                        {!row.text ? (
                          <span style={{ color: "gray" }}>Sem título</span>
                        ) : (
                          <span>{row.text}</span>
                        )}
                      </Td>
                      <Td align="center" style={{ minWidth: "10%" }}>
                        {!row.name ? (
                          <span style={{ color: "gray" }}>Sem tipo</span>
                        ) : (
                          <span>{capitalizeFirstLetter(row?.name)}</span>
                        )}
                      </Td>
                      <Td align="center" style={{ minWidth: "35%" }}>
                        {!row.tags ? (
                          <span style={{ color: "gray" }}>Sem tags</span>
                        ) : (
                          <span>{row.tags}</span>
                        )}
                      </Td>
                      <Td align="center" style={{ minWidth: "20%" }}>
                        {moment(row.initDate).format("DD/MM/YYYY")}
                      </Td>
                      <Td align="center" style={{ minWidth: "20%" }}>
                        {row.endDate === null ? (
                          <span style={{ color: "gray" }}>Sem Prazo</span>
                        ) : (
                          <span>{moment(row.endDate).format("DD/MM/YYYY")}</span>
                        )}
                      </Td>
                      <Td align="center">
                        <span
                          className="badge badge-pill badge-render"
                          style={{
                            background: tipoStatus[row.active]?.background,
                            color: tipoStatus[row.active]?.color,
                          }}
                        >
                          {tipoStatus[row.active]?.nome}
                        </span>
                      </Td>
                      {permissionUpdateContent && (
                        <Td align="center">
                          <div>
                            <NavLink
                              style={{ backgroundColor: secondaryColor }}
                              key={row.id}
                              to={`/cms/content/${row.idContentItem}${
                                idContentType === 8 && !hasZoomUrl(row.referenceLink)
                                  ? `?noZoomAuth=true&content=${idContentType}`
                                  : `?content=${idContentType}`
                              }&isEditing=true`}
                              className={`mt-auto btn btn-primary ${
                                showLoginZoom && hasZoomUrl(row.referenceLink) && "disabled"
                              }`}
                            >
                              Editar
                            </NavLink>
                            &nbsp;
                          </div>
                        </Td>
                      )}
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>

          <DataTable
            className="d-none"
            data={data}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            paginationTotalRows={totalRecords}
            paginationComponentOptions={paginationComponentOptions}
            paginationServer
            pagination
            page={pagination.page}
            noDataComponent={<NoDataComponent />}
            progressComponent={<Loading>Carregando conteúdos...</Loading>}
            progressPending={loading && data.length === 0}
            disabled={loading}
          />
        </>
      )}
    </div>
  );
}

export default withRouter(ContentListGroup);
