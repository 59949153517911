import "./styles.scss";
import React from "react";
import { Form } from "react-bootstrap"; 
import { Search as SearchIcon } from "@material-ui/icons";
import DataTable from "react-data-table-component";
import Loading from "components/Loading";

const TableComponent = ({
  onSubmit = () => {},
  onRowClicked = () => {},
  onChangePage = () => {},
  onChangeRowsPerPage = () => {},
  paginationComponentOptions = {},
  loading = false,
  tableColumn = {},
  tableData = [], 
  NoDataComponent = null,
  pagination = {},
  loadingDataFallBackText = '',
  searchPlaceholder = '',
  setSearchText = () => {},
  searchText = '',
  header = true,
  extraTableProps = {}
}) => { 
  return (
    <>
      {header &&
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "20px 0",
          }}
        >
          <Form
            onSubmit={() => onSubmit()}
            className="input-group col-md-8"
            style={{ display: "flex",   padding: 0 }}
          >
            <div style={{ position: "relative", flex: "0 0 70%", marginRight: "7px", height: 38 }}>
              <SearchIcon
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "10px",
                  transform: "translateY(-50%)",
                  height: 18,
                }}
              />
              <input
                type="text"
                className="form-control"
                id="company-name"
                style={{ paddingLeft: "40px", width: "100%" }}
                aria-describedby="company-name"
                onChange={(e) => setSearchText(e.target.value)}
                placeholder={searchPlaceholder}
                value={searchText}
              />
            </div>
            <button className="search-button button-default">
              <span className="mr-1">Pesquisar</span>
            </button>
          </Form>
        </div>
      }
      <div className="node-manager-import-table-wrapper mb-2">
        <DataTable
          columns={tableColumn}
          data={tableData}
          className="custom-table" 
          progressComponent={
            <Loading containerStyle={{ height: 400 }}>{loadingDataFallBackText}</Loading>
          }
          noDataComponent={NoDataComponent}
          onRowClicked={onRowClicked}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationTotalRows={pagination.totalPage}
          paginationComponentOptions={paginationComponentOptions}
          progressPending={loading && tableData?.length === 0}
          paginationServer
          noHeader
          fixedHeader
          pagination
          pointerOnHover
          highlightOnHover
          {...extraTableProps}
        />
      </div>
    </>
  );
};

export default TableComponent;
