import React, { useRef, useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import { uploadImageApi } from "../../services/NexiDigitalAPI";

import { getVideoDuration } from "services/youTubeApi";
import { getVideo } from "services/wistiaApi";
import YouTube from "react-youtube";
import ReactPlayer from "react-player";

const CmsVideoUpload = ({
  contentLocation,
  setContentLocation,
  setVideoDuration,
  idContentItem,
  videoOriginal,
}) => {
  const [carregaVideo, setCarregaVideo] = useState(false);
  const [videoUploadStatus, setVideoUploadStatus] = useState(false);
  const [mensagemVideoUpload, setMensagemVideoUpload] = useState("");
  const [videoPlay, setVideoPlay] = useState("");
  const [youtubeId, setYoutubeId] = useState("");
  const [whistiaId, setWhistiaId] = useState("");

  const videoRef = useRef();

  const matchYoutubeUrl = (url) => {
    var p =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    return url.match(p) ? RegExp.$1 : false;
  };

  const matchWistiaUrl = (url) => {
    let broken = url.split("/");
    return broken.find((item) => item === "be-br.wistia.com") ? broken[broken.length - 1] : null;
  };

  const updateVideoDuration = () => {
    const hasVideo = Boolean(videoRef.current);
    if (hasVideo) {
      const duration = Boolean(videoRef.current.duration) ? videoRef.current.duration : 0;
      setVideoDuration(duration);
      return;
    }

    if (contentLocation === "") {
      setVideoDuration(0);
      return;
    }

    return setTimeout(updateVideoDuration, 500);
  };

  const configUpdateVideoDuration = async () => {
    const hasVideo = Boolean(videoRef.current);

    const idYoutube = matchYoutubeUrl(contentLocation);
    const idWistia = matchWistiaUrl(contentLocation);
    if (idYoutube) {
      setVideoPlay("youtube");
      setYoutubeId(idYoutube);
      const info = await getVideoDuration(idYoutube);
      setVideoDuration(info);
      return;
    }
    // whistia
    else if (idWistia) {
      setWhistiaId(idWistia);
      setVideoPlay("whistia");
      const info = await getVideo(idWistia);
      setVideoDuration(info.data.duration);
      return;
    } else {
      setVideoPlay("");
      if (hasVideo) {
        const hasDuration = Boolean(videoRef.current.duration);
        if (hasDuration) {
          updateVideoDuration();
        } else {
          videoRef.current.addEventListener("loadedmetadata", updateVideoDuration);
        }
      } else {
        setTimeout(configUpdateVideoDuration, 500);
      }
    }
  };

  useEffect(() => {
    configUpdateVideoDuration();
  }, [contentLocation]);

  const uploadVideo = (arquivo) => {
    setCarregaVideo(true);
    let selectedVideoFile = arquivo.files[0];
    const filename = "video" + idContentItem + "-" + Date.now();

    const data = new FormData(); // If file selected
    if (selectedVideoFile) {
      data.append("fileName", filename);
      data.append("fileData", selectedVideoFile);
      // data.append("profileImage", newVideofile, newVideofile.name);
      uploadImageApi(data)
        .then((response) => {
          if (response.data.error) {
            setContentLocation(videoOriginal);
            setCarregaVideo(false);
            setVideoUploadStatus(false);
            setMensagemVideoUpload("Erro ao fazer upload do arquivo");
          } else {
            setContentLocation(response.data.location);
            setCarregaVideo(false);
            setVideoUploadStatus(true);
            setMensagemVideoUpload("Video atualizado com successo");
          }
        })
        .catch((error) => {
          // If another error
          console.log("error", error);
          setContentLocation(videoOriginal);
          setCarregaVideo(false);
          setVideoUploadStatus(false);
          setMensagemVideoUpload("Erro ao fazer upload do arquivo");
        });
    } else {
      setContentLocation(videoOriginal);
      setCarregaVideo(false);
    }
  };

  return (
    <div className="form-group">
      <div className="card">
        <div className="card-header template-card-header">Vídeo</div>
        <div className="card-body template-card-body" style={{ minHeight: "260px" }}>
          <div className="row">
            <div className="col-md-6">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="urVideodd">
                    url
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={contentLocation}
                  onChange={(e) => {
                    var Video = e.target.value;
                    setCarregaVideo(true);
                    window.setTimeout(function () {
                      setContentLocation(Video);
                      setCarregaVideo(false);
                      setVideoUploadStatus(true);
                      setMensagemVideoUpload("Video atualizado com successo");
                    }, 1);
                  }}
                />
              </div>
              <input
                type="file"
                className="form-control-file"
                id="video"
                accept=".mp4"
                onChange={(e) => uploadVideo(e.target)}
              />
            </div>
            {carregaVideo ? (
              <div className="col-md-6">
                <CircularProgress />
              </div>
            ) : (
              <div className="col-md-6">
                {videoPlay === "" && (
                  <video ref={videoRef} id="teste" width="320" controls>
                    <source src={contentLocation} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
                {videoPlay === "youtube" && <YouTube opts={{ width: "400" }} videoId={youtubeId} />}
                {videoPlay === "whistia" && (
                  <ReactPlayer url={`https://be-br.wistia.com/medias/${whistiaId}`} />
                )}
                <br />
                {videoUploadStatus ? (
                  <span style={{ color: "green" }}>{mensagemVideoUpload}</span>
                ) : (
                  <span style={{ color: "red" }}>{mensagemVideoUpload}</span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CmsVideoUpload;
