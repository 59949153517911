import React from "react";
import "./style.modules.scss";
import { BsCart2 } from "react-icons/bs";

const ProductsEmptyState = ({
  text = "Ainda não existem produtos adicionados para esta empresa.",
}) => {
  return (
    <div className="products-management-empty-state">
      <BsCart2 style={{ width: 30, height: 30, marginBottom: 10 }} />
      {text}
    </div>
  );
};

export default ProductsEmptyState;
