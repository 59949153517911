import { Tooltip, withStyles } from "@material-ui/core";
import React from "react";
import HelpIcon from "@material-ui/icons/Help";
import FormCharacterCounterRemaning from "components/FormCharacterCounterRemaning";

type Props = {
  label: string;
  setLabel: (value: string) => void;
};

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "14px",
  },
}))(Tooltip);

const TooltipText =
  "O texto incluído neste campo é o que será exibido para a pessoa usuária. Sugestão: escolha um termo de fácil compreensão.";

const CmsContentLabel = ({ label, setLabel }: Props) => {
  return (
    <div className="form-group">
      <div className="card">
        <div className="card-header template-card-header">
          Rótulo do conteúdo único *
          <label className="ml-2 mb-0">
            <CustomTooltip title={TooltipText} placement="top">
              <HelpIcon style={{ fontSize: 20, cursor: "pointer" }} />
            </CustomTooltip>
          </label>
        </div>
        <div className="card-body template-card-body">
          <input
            required
            maxLength={15}
            type="text"
            className="form-control"
            id="node-label"
            aria-describedby="node-label"
            value={label || ""}
            onChange={(e) => setLabel(e.target.value)}
          />
          <FormCharacterCounterRemaning limit={15} value={label} color={"#6b48ff"} />
        </div>
      </div>
    </div>
  );
};

export default CmsContentLabel;
