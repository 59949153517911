import {
  getLearningEvaluations,
  editLearningEvaluations,
  getLearningCategories,
  postLearningCategories,
  deleteLearningCategories,
  getTags,
} from "services/NexiDigitalAPI";

export function getLearnEvaluationInitPromises(idEvaluation, type, call) {
  // Defining our first promise
  let firstPromise = () => {
    return new Promise((resolve, reject) => {
      resolve(getLearningEvaluations(idEvaluation));
    });
  };

  // Defining our second promise
  let secondPromise = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(getLearningCategories(type, idEvaluation)), 1000);
    });
  };

  // Defining our third promise
  let thirdPromise = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(getTags()), 500);
    });
  };

  // Async function to perform execution of all promise
  (async () => {
    try {
      let promise = await Promise.all([firstPromise(), secondPromise(), thirdPromise()]);
      call(promise);
    } catch (e) {
      console.log("Error no GET do Learning Evaluations e/ou GET Categorias", e);
    }
  })();
}

export function putLearnEvaluation(idEvaluation, obj, call) {
  (async () => {
    try {
      const res = await editLearningEvaluations(idEvaluation, obj);
      call(res);
    } catch (e) {
      console.log("Error no PUT do Learning Evaluations", e);
    }
  })();
}

export function postCategoriesPromises(type, idEvaluation, objNode, call) {
  // Defining our first promise
  let firstPromise = () => {
    return new Promise((resolve, reject) => {
      resolve(postLearningCategories(type, idEvaluation, objNode));
    });
  };

  // Defining our second promise
  let secondPromise = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(getLearningCategories(type, idEvaluation)), 1000);
    });
  };

  // Async function to perform execution of all promise
  (async () => {
    try {
      let promise = await Promise.all([firstPromise(), secondPromise()]);
      call(promise);
    } catch (e) {
      console.log("Error no POST do Categories: " + type, e);
    }
  })();
}

export function deleteCategoriesPromises(type, idEvaluation, idCategory, call) {
  // Defining our first promise
  let firstPromise = () => {
    return new Promise((resolve, reject) => {
      resolve(deleteLearningCategories(type, idEvaluation, idCategory));
    });
  };

  // Defining our second promise
  let secondPromise = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(getLearningCategories(type, idEvaluation)), 1000);
    });
  };

  // Async function to perform execution of all promise
  (async () => {
    try {
      let promise = await Promise.all([firstPromise(), secondPromise()]);
      call(promise);
    } catch (e) {
      console.log("Error no DELETE do Categories: " + type, e);
    }
  })();
}
