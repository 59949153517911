import React, { useState, createContext, ReactNode, useContext } from "react";
import { DialogModel, DialogProps, PlaneProps, Status, Trial } from "../model/ManagerTrial.model";
import { dialogContainerModel } from "../Utils/DialogContainerModel";



interface ContextType {
  upgradeStatus: Status;
  setUpgradeStatus: React.Dispatch<React.SetStateAction<Status>>;
  currentCardUpgrade: PlaneProps | null;
  setCurrentCardUpgrade: React.Dispatch<
    React.SetStateAction<PlaneProps | null>
  >;
  listPlans: any;
  setlistPlans: React.Dispatch<React.SetStateAction<any>>;
  trialStatusPlan: Trial | null;
  setTrialStatusPlan: React.Dispatch<React.SetStateAction<any>>;
  dialogPlan: DialogProps | null;
  setDialogPlan: React.Dispatch<React.SetStateAction<DialogProps | null>>;
}

interface ProviderProps {
  children: ReactNode;
}

export const CreateManagerTrial = createContext<ContextType | undefined>(
  undefined
);

export const ContextManagerTrial = ({ children }: ProviderProps) => {
  const [upgradeStatus, setUpgradeStatus] = useState<Status>(Status.upgrade);
  const [currentCardUpgrade, setCurrentCardUpgrade] =
    useState<PlaneProps | null>(null);
  const [listPlans, setlistPlans] = useState<PlaneProps[] | null>(null);
  const [trialStatusPlan, setTrialStatusPlan] = useState<Trial>(
    Trial.notStarted
  );
  const [dialogPlan, setDialogPlan] = useState<DialogProps | null>(
    dialogContainerModel({
      type: DialogModel.ConfirmTrial,
      plan: "Light",
      buttonConfirm: {
        callEvent: () => {},
      },
    })
  );
  const value = {
    upgradeStatus,
    setUpgradeStatus,
    currentCardUpgrade,
    setCurrentCardUpgrade,
    listPlans,
    setlistPlans,
    trialStatusPlan,
    setTrialStatusPlan,
    dialogPlan,
    setDialogPlan
  };

  return (
    <CreateManagerTrial.Provider value={value}>
      {children}
    </CreateManagerTrial.Provider>
  );
};

export const useManagerTrial = (): ContextType => {
  const context = useContext(CreateManagerTrial);
  if (!context) {
    throw new Error("Deve ser usado dentro ContextManagerTrial");
  }
  return context;
};
