import React, { useState } from "react";
import './styles.scss'
import ModalDialog from "../ModalDialog";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";


const ModalActive = ({
  showModal,
  closeModal,
  handleClickConfirmation,
  defaultValue = null
}) => {
  const [value, setValue] = useState(defaultValue)
  return (
    <ModalDialog
    showModal={showModal}
    setShowModal={closeModal}
    type={'danger'}
    buttons={[
      {
        className: "button-modal-darger",
        label: "Confirmar",
        onClick: () =>  {handleClickConfirmation(value)},
      },
    ]}
  >
    <div className="flex-body-modal flex-body-modal-active">
      <div className="title-modal"> O termo de responsabilidade deve ser visualizado por quais usuários? </div>
      <div className="radios">
      <RadioGroup
            value={value}
        >
          <FormControlLabel value="NEW_USERS" control={<Radio onChange={() => setValue('NEW_USERS')} />} label={<span><strong>Apenas novos</strong> usuários</span>} />
          <FormControlLabel value="ALL_USERS" control={<Radio onChange={() => setValue('ALL_USERS')}/>}  label={<span><strong>Todos</strong> os usuários</span>}/>
        </RadioGroup>
      </div>
    </div>
  </ModalDialog>
  )
}

export default ModalActive
