import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import styles from "./styles.module.scss";
import moment from "moment";

const Table = ({ dados, loading, actionFilter, totalRecords }) => {
  const columns = [
    {
      name: "Nome da empresa",
      selector: "name",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "CNPJ",
      selector: "cnpj",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Responsável",
      selector: "responsibleName",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "E-mail do responsável",
      selector: "responsibleEmail",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Telefone do reponsável",
      selector: "responsiblePhone",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Num. de licenças contratadas",
      selector: "numberOfLicences",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Num. de licenças utilizadas",
      selector: "totalLicensesInUse",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Origem",
      selector: "origin",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Data de criação",
      selector: "creationDate",
      sortable: true,
      center: true,
      cell: (d) => <span>{moment(d.creationDate).format("DD/MM/YYYY")}</span>,
    },
    {
      name: "Data de ativação",
      selector: "activationDate",
      sortable: true,
      center: true,
      cell: (d) => <span>{moment(d.lastAccess).format("DD/MM/YYYY HH:mm")}</span>,
    },
    {
      name: "Aceite aos termos",
      selector: "companyLicenceTermsAccepted",
      sortable: true,
      center: true,
      cell: (d) => <span>{Boolean(d.userLicenceTermsAccepted) ? "SIM" : "NÃO"}</span>,
    },
  ];

  const customTableStyles = {
    rows: {
      style: {
        minHeight: "60px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "30px", // override the cell padding for head cells
        textAlign: "center",
      },
    },
    cells: {
      style: {
        paddingLeft: "30px", // override the cell padding for data cells
      },
    },
    header: {
      style: {
        fontSize: "22px",
        minHeight: "56px",
        paddingLeft: "28px",
        paddingRight: "8px",
        paddingTop: "30px",
        paddingBottom: "15px",
      },
    },
  };

  const tableData = {
    columns: columns,
    data: dados,
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Empresas por página",
    rangeSeparatorText: "de",
  };

  const onChangePage = (page, totalRows) => {
    actionFilter({ offset: --page * dados.length });
  };

  const onChangeRowsPerPage = (limit) => {
    actionFilter({ limit, offset: 0 });
  };

  const loadingComponent = () => {
    return (
      <div className="tela-login-loading">
        <div className="loader-container" style={{ margin: "300px 0" }}>
          <div
            style={{
              position: "initial",
              margin: "initial",
            }}
            className="loader loader-center loader-big"
          ></div>
          <span
            style={{
              marginTop: 10,
              fontSize: 20,
            }}
          >
            Carregando dados...
          </span>
        </div>
      </div>
    );
  };

  return (
    <DataTableExtensions filter={false} print={false} {...tableData} exportHeaders={true} export={false}>
      <DataTable
        columns={columns}
        data={dados}
        className={styles.dataTableFormat}
        customStyles={customTableStyles}
        progressPending={loading}
        progressComponent={loadingComponent()}
        noHeader={true}
        noDataComponent="Não foi encontrado nenhum dado"
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        paginationTotalRows={totalRecords}
        paginationComponentOptions={paginationComponentOptions}
        paginationServer
        pagination
      />
    </DataTableExtensions>
  );
};

export default Table;
