import "./styles.scss";

import React, { useState, useEffect,  Fragment, useCallback } from "react";
import { Button } from "react-bootstrap";


import ModalDialog from "containers/PeopleCraft/Modals/ModalDialog";
import { deleteCategoriesPromises, getCategories, postCategoriesPromises } from "../Fetch/CategoriesAPI";
import PreloaderBlur from "containers/PeopleCraft/PreloaderBlur";
import BreadCrumb from "../BreadCrumb";
import ModalAddSegment from "../Modal/ModalAddSegment";
import { deleteCategoriesService } from "services/NexiDigitalAPI";

interface Props    {
  path: string,
  pathGet: string,
  disabled?: boolean
  clearAll?: boolean,
  setClearAll?: any
}

const ListMig = (props: Props) => {
  const [showAddSegment, setShowAddSegment] = useState<boolean>(false);
  const [showModalThash, setShowModalThash] = useState<boolean>(false);
  const [thashItem, setThashItem] = useState(null);
  const [isPreloader, setPreloader] = useState(false);
  const [isCatalog, setCatalog] = useState([]);

  useEffect(() => {
    setPreloader(true);
    if(props.pathGet){
      getCategories(props.pathGet, (res) => {
        setCatalog(res.data);
        setPreloader(false);
      })
    }

  }, [props.pathGet])

  const clearAll =  useCallback( async () => {
    setPreloader(true);

    try{
      for (let index = 0; index < isCatalog.length; index++) {
        const _path = `${props.path}/${isCatalog[index].idCategory}`
        await deleteCategoriesService(_path)
      }
      getCategories(props.pathGet, (res) => {
        setCatalog(res.data);
        setPreloader(false);
      })
    }catch(e){

    }finally{
      setPreloader(false);
    }
  }
  , [isCatalog, props])

  useEffect(() => {
    if(props.clearAll){
      setPreloader(true);
      clearAll()
      props?.setClearAll(false)

    }

  }, [props, clearAll])



  const addEvent = (item) => {
    const objNode = {
      nodeCode: item.nodeCode,
    };

    setPreloader(true);
    postCategoriesPromises(props.path, objNode, props.pathGet, (res) => {
      setCatalog(res[1].data);
      setPreloader(false);
    });
  };

  const trashEvent = (item) => {
    setThashItem(item);
    setShowModalThash(true);
  };

  const trashConfirmEvent = () => {

    const idCategory = thashItem.idCategory;
    const _path = `${props.path}/${idCategory}`
    setShowModalThash(false);
    setPreloader(true);

    deleteCategoriesPromises( _path, props.pathGet,(res) => {
      setCatalog(res[1].data);
      setPreloader(false);
    });
  };

  return (
    <>
      {isPreloader && <PreloaderBlur />}
      <div className="KEForm KEFormMig">
        <label>Migalha de página atual</label>

        {/* {isCatalog.length >= 1 && <BreadCrumb edit={false} catalog={isCatalog[0]}    disabled={props.disabled}/>} */}

        {isCatalog.length >= 1 && (
          <div className="listMig">
            {isCatalog.map((item, indice) => {
              // if (indice > 0) {}
              return (
                <Fragment key={indice}>
                  <BreadCrumb
                    disabled={props.disabled}
                    catalog={item}
                    trashEvent={(item) => {
                      trashEvent(item);
                    }}
                  />
                </Fragment>
              );
            })}
          </div>
        )}

        <div className="btnSeguimentacao">
          <Button
            disabled={props?.disabled}
            variant="warning"
            className=""
            onClick={() => {
              setShowAddSegment(true);
            }}
          >
            Adicionar categoria
          </Button>
        </div>
      </div>
      {showAddSegment && (
        <ModalAddSegment
          denyAction={() => setShowAddSegment(false)}
          blockData={isCatalog}
          returnNodeData={addEvent}
        />
      )}

      <ModalDialog
        text={"Tem certeza de que deseja deletar essa categoria?"}
        show={showModalThash}
        setShow={setShowModalThash}
        btnCall={{
          className: "",
          variantBtn: "danger",
          text: "Deletar",
          callEvent: () => {
            trashConfirmEvent();
          },
        }}
      />
    </>
  );
};

export default ListMig;
