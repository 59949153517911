import React, { FC, useCallback, useEffect, useState } from "react";

import "./styles.scss";
import HeaderCrumb from "containers/Gamification/Components/HeaderCrumb";
import HeaderFilterRanking from "containers/Gamification/Components/HeaderFilterRanking";
import { getDownloadReport, listcampaignpointssummary, postDownloadReport } from "services/NexiDigitalAPI";
import { Tooltip } from "@material-ui/core";
import { FiEdit } from "react-icons/fi";
import { IoTrophyOutline } from "react-icons/io5";
import DataTable from "react-data-table-component";
import EditScore from "containers/Gamification/Components/EditScore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewScore from "containers/Gamification/Components/ViewScore";
import PreloaderBlur from "containers/PeopleCraft/PreloaderBlur";

const Ranking = () => {
  const [dataFilter, setDataFilter] = useState<{
    campaign: number;
    department: string;
    positions: number;
  }>({
    campaign: 0,
    department: null,
    positions: 0,
  });
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState<boolean>();
  const [loadingFile, setLoadingFile] = useState<boolean>();
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });

  const [editScore, setEditScore] = useState(null)
  const [viewScore, setViewScore] = useState(null)
  const columns = [
    {
      name: "Posições",
      selector: "index",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (data, index) => (
        <div>
          <p>{index + 1}º</p>
        </div>
      ),
    },
    {
      name: "Usuários",
      sortable: false,
      selector: "name",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Pontuação",
      sortable: false,
      selector: "points",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Ação",
      sortable: false,
      selector: "idgamification_campaign",
      center: true,
      wrap: true,
      width: "200px",
      style: { textAlign: "center" },
      cell: (group) => (
        <div className="groupActions" key={group?.idgamification_campaign}>


          <Tooltip title="Editar" placement="top" onClick={() => setEditScore({ ...group, idCampaign: dataFilter.campaign })}>
            <span className="btn btn-sm float-right icon-edit">
              <FiEdit />
            </span>
          </Tooltip>
          <Tooltip title="Pontos" placement="top">
            <span
              className="btn btn-sm float-right icon-trophy"
              onClick={() => {
                setViewScore({ ...group, idCampaign: dataFilter.campaign })
              }}
            >
              <IoTrophyOutline />
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];



  const handleChangeFilter = (data) => setDataFilter((after) => ({ ...after, ...data }));


  const getDataTable = useCallback(async () => {
    setLoading(true);
    try {
      if (dataFilter.campaign) {
        const params: any = pagination
        if (!!dataFilter.department) {
          params.department = dataFilter.department
        } else {
          delete params.department
        }
        const result = await listcampaignpointssummary(dataFilter.campaign, params);
        const _order = result.sort((a, b) => b.points - a.points);
        setDataTable(_order);
      }
    } finally {
      setLoading(false);
    }
  }, [dataFilter.campaign, dataFilter.department, pagination]);

  const onChangePage = (page) => {
    setPagination((prev) => ({ ...prev, offset: --page * prev.limit }));
  }

  const onChangeRowsPerPage = (limit, page) => {
    const newOffset = (page - 1) * limit;
    setPagination((prev) => ({ ...prev, limit, offset: newOffset }));
  };

  useEffect(() => {
    getDataTable();
  }, [getDataTable]);

  const EmptySelect: FC = () => (
    <div className="emptySelect">
      <span>
        Selecione entre campanhas, departamentos ou cargos para que seja renderizado o ranking de
        resultados
      </span>
    </div>
  );

  const CustomNoDataComponent = () => (
    <div className="noContent" style={{ textAlign: "center" }}>
      <p>Sem itens encontrados</p>
    </div>
  );

  const paginationComponentOptions = {
    rowsPerPageText: "Conteúdos por página",
    rangeSeparatorText: "de",
  };

  const message = ({ text, type, position = 'top-right' }: { text: string, type: string, position?: string }) => {
    toast[type](text, {
      position: position,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  const hidenModel = (_message = null) => {
    setEditScore(null)
    setViewScore(null)
    if (_message) {
      getDataTable()
      message(_message);
    }
  }

  const downloadEvent = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setLoadingFile(true)
    const ID_REPORT_POINTS = 2
    event.stopPropagation()
    const params: any = { idCampaign: dataFilter.campaign }
    if (!!dataFilter.department) {
      params.department = dataFilter.department
    } else {
      delete params.department
    }
    try {
      await postDownloadReport(ID_REPORT_POINTS, params);
      requestDownloadReport(ID_REPORT_POINTS);
      //await  getDownloadReport();
    } catch (e) {
      message({ text: e.response?.data?.message, type: 'error' })
      setLoadingFile(false)

    }
  }

  const requestDownloadReport = async (idReport: number) => {

    try {
      const response: any = await getDownloadReport(idReport);
      const {status, fileUrl} = response?.data
      if (status === 'Waiting') {
        await new Promise(resolve => setTimeout(resolve, 1000));
        return requestDownloadReport(idReport);
      }
      if (status === 'Done' && fileUrl) {
        getFileReport(fileUrl);
      }
    } catch (error) {
      message({ text: error.response?.data?.message, type: 'error' })

    }finally{
      setLoadingFile(false)

    }
  }

  const getFileReport = (fileUrl: string) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.click();
  }

  return (
    <>
      <div className="ranking-container">
        {loadingFile ? (
          <PreloaderBlur />
        ) : (
          <>
            <HeaderCrumb crumb="Gamificação / Gestão de Campanhas" />
            <HeaderFilterRanking dataFilter={dataFilter} handleChange={handleChangeFilter} downloadEvent={downloadEvent} disabledButtonDownload={!dataTable?.length}/>
            {!dataFilter.campaign ? (
              <EmptySelect />

            ) : (
              <div className="ranking-table-container">
                <DataTable
                  progressPending={loading}
                  columns={columns}
                  data={dataTable}
                  noHeader
                  fixedHeader
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}

                  noDataComponent={<CustomNoDataComponent />}
                  paginationComponentOptions={paginationComponentOptions}
                  paginationTotalRows={dataTable.length}
                  pagination
                />
              </div>
            )}
          </>
        )}


      </div>
      {editScore && (
        <EditScore data={editScore} denyAction={hidenModel} />
      )}
      {viewScore && (
        <ViewScore data={viewScore} denyAction={hidenModel} />
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Ranking;
