import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Button, Form, Table } from "react-bootstrap";
import "./AddUser.css";
import { FaPlus } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import {
  getPermissions,
  getGroups,
  getCompany,
  getChannel,
  getApp,
  getUsers,
  addPermission,
  deletePermission,
  getMenuData,
} from "../services/NexiDigitalAPI";
import Select from "react-select";

const api_url = process.env.REACT_APP_BACKEND_API;

export default function AccessTable(props) {
  const { id } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const [isLoadingCompany, setIsLoadingCompany] = useState(false);
  const [errorGet, setErrorGet] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [channels, setChannels] = useState([]);
  const [apps, setApps] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [permissionsAdd, setPermissionsAdd] = useState({
    idContentItem: id,
    idChannel: getMenuData().idChannel,
    idAppType: getMenuData().idAppType,
    idCompany: getMenuData().idCompany,
    idGroup: 0,
    idUser: 0,
    appTypeName: "Todos",
    channelName: "Todos",
    companyName: "Todos",
    userName: "Todos",
    groupName: "Todos",
  });

  useEffect(() => {
    _getPermissions(id);
    _getChannel();
    _getApp();
    _getGroups();
    _getUsers();
  }, [id]);

  function _getPermissions(id) {
    getPermissions(id)
      .then((res) => {
        if (res.data.success == true) {
          setPermissions(res.data.permissions);
        } else {
          setErrorGet(true);
        }
      })
      .catch((err) => {
        console.log("error ", err);
        setErrorGet(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function _getChannel() {
    getChannel()
      .then((res) => {
        if (res.data.success == true) {
          setChannels(res.data.channel);
        } else {
        }
      })
      .catch((err) => {
        console.log("error ", err);
      });
  }

  function _getApp() {
    getApp()
      .then((res) => {
        if (res.data.success == true) {
          setApps(res.data.app);
          _getCompany(res.data.app[0].idApp);
        } else {
        }
      })
      .catch((err) => {
        console.log("error ", err);
      });
  }

  function _getCompany(appId) {
    setIsLoadingCompany(true);
    getCompany(appId)
      .then((res) => {
        if (res.data.success == true) {
          setCompanies(res.data.companies);
        } else {
        }
      })
      .catch((err) => {
        console.log("error ", err);
      })
      .finally(() => {
        setIsLoadingCompany(false);
      });
  }

  function _getGroups() {
    getGroups()
      .then((res) => {
        if (res.data.success == true) {
          setGroups(res.data.groups);
        } else {
        }
      })
      .catch((err) => {
        console.log("error ", err);
      });
  }

  function _getUsers() {
    getUsers()
      .then((res) => {
        if (res.data.success == true) {
          let data = res.data.userList;
          let selectList = [{ value: 0, label: "Todos" }];

          if (data.length > 0) {
            data.forEach((item) => {
              selectList.push({
                value: item.idUser,
                label: item.name,
              });
            });
          }

          setUsers(selectList);
        } else {
        }
      })
      .catch((err) => {
        console.log("error ", err);
      });
  }

  function _addPermission() {
    setIsLoadingAdd(true);
    const permissionParams = Object.assign({}, permissionsAdd);
    const currentPermission = Object.assign({}, permissionsAdd);

    // delete unecessary objects for API
    delete permissionParams.appTypeName;
    delete permissionParams.channelName;
    delete permissionParams.companyName;
    delete permissionParams.userName;
    delete permissionParams.groupName;

    addPermission(permissionParams)
      .then((res) => {
        if (res.data.success == true) {
          setPermissions([
            ...permissions,
            { ...currentPermission, idPermission: res.data.permission },
          ]);
        }
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setIsLoadingAdd(false);
      });
  }

  function _deletePermission(id, index) {
    // deletar o objeto do array para refletir na tabela (front)
    let newPermission = Object.assign([], permissions);
    newPermission.splice(index, 1);
    setPermissions(newPermission);

    // deletar permissao
    deletePermission(id)
      .then((res) => {})
      .catch((err) => {
        console.log("error", err);
      });
  }

  return (
    <div>
      <div className="access-table-select-container">
        <Form.Group style={{ display: "none" }} className="access-table-select">
          <Form.Label>Canal</Form.Label>
          <Form.Control
            value={permissionsAdd.idChannel}
            onChange={(e) =>
              setPermissionsAdd({
                ...permissionsAdd,
                idChannel: e.target.value,
                channelName: e.target.options[e.target.selectedIndex].text,
              })
            }
            as="select"
          >
            <option value={0}>Todos</option>
            {channels.map((item, index) => {
              return (
                <option key={index} value={item.idChannel}>
                  {item.nome}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group
          style={{ display: "none" }}
          onChange={(e) =>
            setPermissionsAdd({
              ...permissionsAdd,
              idAppType: e.target.value,
              appTypeName: e.target.options[e.target.selectedIndex].text,
            })
          }
          className="access-table-select"
        >
          <Form.Label>App</Form.Label>
          <Form.Control
            value={permissionsAdd.idAppType}
            onChange={(e) => _getCompany(e.target.value)}
            as="select"
          >
            <option value={0}>Todos</option>
            {apps.map((item, index) => {
              return (
                <option key={index} value={item.idApp}>
                  {item.name}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group
          style={{ display: "none" }}
          onChange={(e) =>
            setPermissionsAdd({
              ...permissionsAdd,
              idCompany: e.target.value,
              companyName: e.target.options[e.target.selectedIndex].text,
            })
          }
          className="access-table-select"
        >
          <Form.Label>Empresa</Form.Label>
          <Form.Control as="select" value={permissionsAdd.idCompany}>
            <option value={0}>Todos</option>
            {isLoadingCompany ? (
              <option disabled value={0}>
                Carregando empresas...
              </option>
            ) : (
              companies.map((item, index) => {
                return (
                  <option key={index} value={item.idCompany}>
                    {item.name}
                  </option>
                );
              })
            )}
          </Form.Control>
        </Form.Group>
        <Form.Group
          style={{ marginLeft: 0 }}
          onChange={(e) =>
            setPermissionsAdd({
              ...permissionsAdd,
              idGroup: e.target.value,
              groupName: e.target.options[e.target.selectedIndex].text,
            })
          }
          className="access-table-select"
        >
          <Form.Label>Grupo</Form.Label>
          <Form.Control as="select">
            <option value={0}>Todos</option>
            {groups.map((item, index) => {
              return (
                <option key={index} value={item.idGroups}>
                  {item.title}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group className="access-table-select" style={{ flex: 1.5 }}>
          <Form.Label>Usuário</Form.Label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            defaultValue={users[0]}
            isLoading={isLoading}
            name="color"
            options={users}
            onChange={(e) =>
              setPermissionsAdd({ ...permissionsAdd, idUser: e.value, userName: e.label })
            }
            placeholder={"Todos"}
          />
        </Form.Group>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <Button
          disabled={isLoadingAdd}
          onClick={() => _addPermission()}
          style={{ display: "flex", alignItems: "center", marginBottom: 25 }}
        >
          <FaPlus style={{ marginRight: 10 }} /> {isLoadingAdd ? "Adicionando..." : "Adicionar"}
        </Button>
      </div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            border: "1px solid lightgray",
            borderRadius: 5,
            padding: 25,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="loader loader-big" />
          <span>Carregando permissões...</span>
        </div>
      ) : (
        <Table bordered>
          <thead>
            <tr>
              <th>#</th>
              <th style={{ display: "none" }}>Canal</th>
              <th style={{ display: "none" }}>App</th>
              <th style={{ display: "none" }}>Empresa</th>
              <th>Grupo</th>
              <th>Usuário</th>
              <th style={{ textAlign: "center", width: 50 }}>Ação</th>
            </tr>
          </thead>
          <tbody>
            {permissions.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td style={{ display: "none" }}>{item.channelName}</td>
                  <td style={{ display: "none" }}>{item.appTypeName}</td>
                  <td style={{ display: "none" }}>{item.companyName}</td>
                  <td>{item.groupName}</td>
                  <td>{item.userName}</td>
                  <td style={{ textAlign: "center" }}>
                    {" "}
                    <BsTrash
                      onClick={() => _deletePermission(item.idPermission, index)}
                      style={{ color: "red", cursor: "pointer" }}
                    />{" "}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}
