import { LicenseUpgradeProps, PlaneProps } from "../model/ManagerTrial.model";

export const licenseUpgradeConcact = (licenseUpgrade: LicenseUpgradeProps ) => {

    let planCont: PlaneProps[] = [];
    licenseUpgrade?.plansContainer.map((it) => {
        it.plans.map((el) => {
        el.idRef = el.idApp + "_" + el.idPlan;
        return el;
        });

        planCont = [...planCont, ...it.plans];
        return it;
    });

    return planCont
}
