import "./styles.scss";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { TreeItem, TreeView } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import { getLearningManagementNodeCode } from "../../../../../services/NexiDigitalAPI.js";
import Loading from "../../../../../components/Loading.js";
import { getProfileInfo } from "../../../../../utils/Utils.js";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: "#f2f1fc",
    padding: 15,
  },
});

const RenderTree = ({ nodes, setSelectedNode, listNodesSelect }) => {
  return nodes?.map((node, index) => {
    const filterNodeCode = listNodesSelect?.filter((it) => it.nodeCode === node.nodeCode);
    const filterNodeCodeStatus = filterNodeCode?.length > 0;

    return (
      <TreeItem
        className={`catalog-modal-tree-item ${filterNodeCodeStatus ? "treeSelect" : ""}`}
        key={index}
        nodeId={`${node.nodeCode}`}
        label={
          <>
            <span>{node.title}</span>
            <button
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                setSelectedNode(node);
              }}
              className={`btn catalog-modal-button ${filterNodeCodeStatus ? "nodeCodeSelect" : ""}`}
            >
              {filterNodeCodeStatus ? "Selecionado" : "Selecionar"}
            </button>
          </>
        }
      >
        {Array.isArray(node.childNodes) && node.childNodes.length > 0 ? (
          <RenderTree
            nodes={node.childNodes}
            setSelectedNode={setSelectedNode}
            listNodesSelect={listNodesSelect}
          />
        ) : null}
      </TreeItem>
    );
  });
};

export default function ModalAddSegment({ denyAction = null, returnNodeData, blockData }) {
  const [showModal, setShowModal] = useState(true);
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [nodesData, setNodesData] = useState([]);
  const [loadingNodes, setLoadingNodes] = useState(true);
  const callToken = getProfileInfo();
  const { idCompany } = callToken;

  const _getLearningManagementNodeCode = (nodeCode) => {
    setLoadingNodes(true);
    getLearningManagementNodeCode(nodeCode)
      .then((res) => {
        let transformedData = res;
        transformedData[0].childNodes = setChildPlaceholder(res[0].childNodes);
        setNodesData(transformedData);
        const firstNodeTreeId = res?.[0]?.nodeCode;
        setExpanded([String(firstNodeTreeId)]);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoadingNodes(false);
      });
  };

  const getChildNodes = (nodeCode) => {
    getLearningManagementNodeCode(nodeCode)
      .then((res) => {
        let newNodesData = [...nodesData];
        const childData = setChildPlaceholder(res[0].childNodes);
        const result = updateChildNodes(newNodesData, nodeCode, childData);
        setNodesData(result ? newNodesData : nodesData);
      })
      .catch((err) => {
        console.log("error getting child trees", err);
      })
      .finally(() => {
        if (loadingNodes === true) {
          setLoadingNodes(false);
        }
      });
  };

  const setChildPlaceholder = (data) => {
    data.forEach((obj) => {
      if (obj.hasChildNodes === 1) {
        obj.childNodes.push({
          idProduct: 0,
          idTargetCompany: 0,
          nodeCode: "0",
          title: "Carregando...",
          enabled: 0,
          hasChildNodes: 0,
          childNodes: [],
        });
      }
    });

    return data || [];
  };

  function updateChildNodes(array, nodeCode, newChildNodes) {
    for (let i = 0; i < array.length; i++) {
      const obj = array[i];
      if (obj.nodeCode === nodeCode) {
        obj.childNodes = newChildNodes;
        return true; // Return true to indicate that the object was found and updated
      }
      if (obj.hasChildNodes && obj.childNodes.length > 0) {
        const childUpdated = updateChildNodes(obj.childNodes, nodeCode, newChildNodes);
        if (childUpdated) {
          return true; // Return true if the child object was found and updated
        }
      }
    }
    return false; // Return false if the object was not found
  }

  const PlusIcon = () => <AddIcon className="catalog-modal-icon" id="expand-id" />;

  const MinusIcon = () => <RemoveIcon className="catalog-modal-icon" id="minimize-id" />;

  const handleToggle = (event, nodeIds) => {
    const isCollapsing = event.currentTarget.querySelector("#expand-id") === null;

    const clickedNodeCode = nodeIds[0];
    setExpanded(nodeIds);

    // check if if any of the child node has been expanded
    const isChildExpanded = nodeIds?.some((item) => item.includes(clickedNodeCode + "."));

    // dont call endpoint again when collpsing
    if (!isCollapsing && !isChildExpanded) {
      getChildNodes(clickedNodeCode);
    }
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
    // setShowModal(false);
  };

  const handleNodeData = (nodeData) => {
    returnNodeData(nodeData);
    setShowModal(false);
    denyAction();
  };

  function handleDeny() {
    if (denyAction) {
      denyAction();
      return;
    }
    setShowModal(false);
  }

  useEffect(() => {
    _getLearningManagementNodeCode(idCompany);
  }, []);

  return (
    <Modal
      show={showModal}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      className="ModalAddSegment"
      centered
      size="xl"
      onHide={() => {}}
    >
      <div className="modal-support-blur modal-support-blur-warning" style={{ borderRadius: 10 }} />
      <div
        onClick={() => handleDeny()}
        className="modal-warning-close-container"
        style={{ margin: 15, padding: 0, backgroundColor: "#6b48ff" }}
      >
        <CloseIcon style={{ color: "white" }} className="modal-warning-close" />
      </div>
      <div className="modal-principal-warning" style={{ minHeight: 400 }}>
        <div className="modal-body-warning" style={{ padding: 20 }}>
          {loadingNodes ? (
            <Loading>Carregando lista de nós...</Loading>
          ) : (
            <>
              <Modal.Title
                style={{ textAlign: "center", fontSize: 22, margin: "15px 0px", color: "#807b7b" }}
              >
                Selecione a página de conteúdo para categorizar
              </Modal.Title>
              <TreeView
                className={classes.root}
                defaultExpandIcon={<PlusIcon />}
                defaultCollapseIcon={<MinusIcon />}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
              >
                {
                  <RenderTree
                    setSelectedNode={(node) => {
                      // setSelectedNode
                      handleNodeData(node);
                    }}
                    nodes={nodesData}
                    listNodesSelect={blockData}
                  />
                }
              </TreeView>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}
