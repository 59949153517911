import "./styles.modules.scss";

import React, { useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";

import BreadCrumbCategories from "../../../../BreadCrumbCategories";
import PreloaderBlur from "../../../../PreloaderBlur";
import ModalAddSegment from "../../../ModalAddSegment";
import ModalDialog from "../../../ModalDialog";
import {
  deleteLearningCategories,
  postLearningCategories,
  getLearningCategories,
} from "services/NexiDigitalAPI";

const postCategoriesPromises = (type, idEvaluation, objNode, call) => {
  // Defining our first promise
  let firstPromise = () => {
    return new Promise((resolve, reject) => {
      resolve(postLearningCategories(type, idEvaluation, objNode));
    });
  };

  // Defining our second promise
  let secondPromise = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(getLearningCategories(type, idEvaluation)), 1000);
    });
  };

  // Async function to perform execution of all promise
  (async () => {
    try {
      let promise = await Promise.all([firstPromise(), secondPromise()]);
      call(promise);
    } catch (e) {
      console.log("Error no POST do Categories: " + type, e);
    }
  })();
};

const CategoriesListQuestion = ({ isCreating, idQuestion, categoriesList, setCategoriesList }) => {
  const [showAddSegment, setShowAddSegment] = useState(false);
  const [showModalThash, setShowModalThash] = useState(false);
  const [thashItem, setThashItem] = useState(null);
  const [isPreloader, setPreloader] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const addEvent = (item) => {
    const objNode = {
      nodeCode: item.nodeCode,
    };

    setPreloader(true);
    if (!idQuestion) {
      const newQuestionCategory = {
        nodeCode: item.nodeCode,
        title: item.title,
        entityType: "QUESTION",
        parentNodes: [],
      };

      setCategoriesList((prevCategories) => [...prevCategories, newQuestionCategory]);
      setPreloader(false);
      return;
    }
    postCategoriesPromises("questions", idQuestion, objNode, (res) => {
      setCategoriesList(res[1].data);
      setPreloader(false);
    });
  };

  const trashEvent = (item) => {
    if (!idQuestion) {
      const newCatalog = categoriesList?.filter((catalog) => catalog.nodeCode !== item.nodeCode);
      setCategoriesList(newCatalog);
    } else {
      setThashItem(item);
      setShowModalThash(true);
    }
  };

  const trashConfirmEvent = async () => {
    const idCategory = thashItem.idCategory;
    setShowModalThash(false);
    setLoadingDelete(idCategory);
    deleteLearningCategories("questions", idQuestion, idCategory)
      .then(() => {
        const newCatalog = categoriesList?.filter((catalog) => catalog.idCategory !== idCategory);
        setCategoriesList(newCatalog);
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setLoadingDelete(false);
      });
  };

  return (
    <>
      {isPreloader && <PreloaderBlur />}
      <div className="KEForm KEFormMig">
        <div className="KECategoriesQuestionlist">
          {categoriesList?.map((item, indice) => {
            return (
              <Fragment key={indice}>
                <BreadCrumbCategories
                  catalog={item}
                  trashEvent={(item) => {
                    trashEvent(item);
                  }}
                  loading={loadingDelete === item.idCategory}
                />
              </Fragment>
            );
          })}
        </div>

        <div className="btnSeguimentacao">
          <Button
            variant="warning"
            className=""
            onClick={() => {
              setShowAddSegment(true);
            }}
          >
            Adicionar categoria
          </Button>
        </div>
      </div>
      {showAddSegment && (
        <ModalAddSegment
          denyAction={() => setShowAddSegment(false)}
          blockData={categoriesList}
          returnNodeData={addEvent}
        />
      )}

      <ModalDialog
        text={"Tem certeza de que deseja deletar essa categoria?"}
        show={showModalThash}
        setShow={setShowModalThash}
        btnCall={{
          className: "",
          variantBtn: "danger",
          text: "Deletar",
          callEvent: () => {
            trashConfirmEvent();
          },
        }}
      />
    </>
  );
};

export default withRouter(CategoriesListQuestion);
