import "./styles.scss";
import React from "react";

const StepComponent = ({
  numberOfSteps = 0,
  index,
  label,
  isActive,
  children,
  outsideComponent,
}) => {
  return (
    <div className={`${!isActive ? "step-disabled" : ""}`}>
      {index <= numberOfSteps - 1 && <hr style={{ margin: "20px 0" }} />}
      <div className="step-badge-container">
        <div className="step-badge" style={{ backgroundColor: isActive ? "#6b48ff" : "gray" }}>
          {index + 1}
        </div>
        <div>{label}</div>
        {children}
      </div>
      <div>{outsideComponent}</div>
      {index <= numberOfSteps - 1 && <hr style={{ margin: "20px 0" }} />}
    </div>
  );
};

export default StepComponent;
