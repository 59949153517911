import React, { useState } from "react";
import './styles.scss'
import ModalDialog from "../ModalDialog";
import Delete from "assets/images/deletar.svg";

const ModalDelete = ({
  showModal,
  closeModal,
  handleClickConfirmation
}) => {
  return (
    <ModalDialog
    ico={   <img src={Delete}  size={40} alt="icon-delete" />}
    showModal={showModal}
    setShowModal={closeModal}
    type={'danger'}
    buttons={[
      {
        className: "button-modal-darger",
        label: "Excluir",
        onClick: () =>  {handleClickConfirmation()},
      },
    ]}
  >
    <div className="flex-body-modal flex-body-modal-active">
      <div className="title-modal"> Tem certeza que deseja excluir esse termo de responsabilidade ? </div>

    </div>
  </ModalDialog>
  )
}

export default ModalDelete
