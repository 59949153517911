import React, { useContext, useState } from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import Switch from "react-bootstrap/esm/Switch";
import { Modal } from "react-bootstrap";

import SemImagemSmall from "../../../../assets/images/sem-imagem.jpg";
import {
  toggleBlockMandatory,
  toggleBlockSequential,
  getMenuData,
} from "../../../../services/NexiDigitalAPI";
import { statusTypes } from "containers/PeopleCraft/ContentPage/StatusTypes";
import { BsShareFill } from "react-icons/bs";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { LuEye } from "react-icons/lu";
import { LMCreateContext } from "containers/PeopleCraft/LearningManagementNew/Contexts/LMContext";
import { FiTrash2 } from "react-icons/fi";
import { CircularProgress } from "@material-ui/core";
import LinkGroupsVisibility from "components/PeopleCraft/NodeParamenters/components/LinkGroupsVisibility";
// import { useNodesTree } from "containers/PeopleCraft/LearningManagementNew/Components/NodesTree/hooks/useNodesTree";

export const ElementCard = ({
  element,
  permissionLearningPage,
  idBlock,
  noEditPermition,
  noEditRediretct,
  handleEditElementRedirect,
  loadingDeleteBlockElement,
  removeNode,
}) => {
  const { nodeData, setNodeData } = useContext(LMCreateContext);
  const { elementType } = element;
  // const { nodeSelectItem } = useNodesTree();
  const { idCompany } = getMenuData();
  const [linkGroupModal, setlinkGroupModal] = useState(false);
  const internalRedirect = Boolean(element.data.redirect && element.data.idCompany === idCompany);
  const externalRedirect = Boolean(element.data.redirect && element.data.idCompany !== idCompany);
  const usedAsRedirect = Boolean(element.data.usedAsRedirect);

  const hasDadEntryPoint = () => {
    let tempHasDadEntryPoint = false;

    if(nodeData?.parentNodes){
      tempHasDadEntryPoint = nodeData.parentNodes[nodeData.parentNodes.length - 1].entryPoint == 1;
    }

    return tempHasDadEntryPoint
  }

  const handleToggleEssential = (value) => {
    const blockIndex = nodeData.blocks.findIndex((item) => item.idBlock === idBlock);
    const elementIndex = nodeData.blocks[blockIndex].elements.findIndex(
      (item) => item.idBlockElement === element.idBlockElement,
    );

    let newNodeData = { ...nodeData };
    newNodeData.blocks[blockIndex].elements[elementIndex].data.mandatory = Boolean(value) ? 1 : 0;
    setNodeData(newNodeData);
    toggleBlockMandatory(element.idBlockElement)
      .then(() => {
        console.log("Success toggleBlockMandatory");
      })
      .catch((err) => {
        console.log("Error toggleBlockSequential", err);
      });
  };

  const handleToggleSequential = (value) => {
    const blockIndex = nodeData.blocks.findIndex((item) => item.idBlock === idBlock);
    const elementIndex = nodeData.blocks[blockIndex].elements.findIndex(
      (item) => item.idBlockElement === element.idBlockElement,
    );
    let newNodeData = { ...nodeData };
    newNodeData.blocks[blockIndex].elements[elementIndex].data.sequential = Boolean(value) ? 1 : 0;
    setNodeData(newNodeData);

    toggleBlockSequential(element.idBlockElement)
      .then(() => {
        console.log("Success toggleBlockSequential");
      })
      .catch((err) => {
        console.log("Error toggleBlockSequential", err);
      });
  };

  const getNameByContentId = (idContentType) => {
    switch (idContentType) {
      case 1:
        return "Audio";
      case 2:
        return "Video";
      case 3:
        return "Post";
      case 4:
        return "Jornada";
      case 5:
        return "Artigo";
      case 6:
        return "Live";
      case 7:
        return "Página";
      case 8:
        return "Meeting";
      case 9:
        return "Video";
      case 18:
        return "Arquivo";
      case 26:
        return "SCORM";
      default:
        return "Conteúdo";
    }
  };
  return (
    <>
      <Modal
        show={linkGroupModal}
        className={"modalLinkGroup"}
        onHide={() => {
          setlinkGroupModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Configurações de visibilidade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LinkGroupsVisibility
              setContentData={setNodeData}
              nodeCode={element?.data?.nodeCode}
          />
        </Modal.Body>
      </Modal>

      <div className="node-manager-content-type-article-wrapper-v2">
        <div className="node-manager-content-type-article-container-v2">
          {elementType === "CONTENT" ? (
            <div style={{ display: "flex" }}>
              <DescriptionIcon style={{ color: "#6b48ff", fontSize: 22, marginRight: 5 }} />
              <span style={{ color: "#a3a3a3", marginTop: 2 }}>
                {getNameByContentId(element?.data?.idContentType)}
              </span>
            </div>
          ) : elementType === "EVALUATION" ? (
            <div style={{ display: "flex" }}>
              <DescriptionIcon style={{ color: "#6b48ff", fontSize: 22, marginRight: 5 }} />
              Avaliação de {element?.data?.evaluationType === "REACTION" ? "Reação" : "Conhecimento"}{" "}
            </div>
          ) : (
            <div className="node-manager-type-text-container-v2 node-manager-type-text-container2">
              <div>{element?.data?.nodeLevelName ? element?.data?.nodeLevelName : "Imagem"}</div>
              { usedAsRedirect  && (
                <div className={"pageShareInfo"}>
                  <BsShareFill /> {internalRedirect  ? 'Página compartilhada' : 'Página externa'}
                </div>
              )}
            </div>
          )}
          <div
            style={{
              fontSize: 18,
              fontWeight: "bold",
              marginBottom: 20,
            }}
          >
            {element.data?.title}
          </div>
          {element.elementType !== "IMAGE" && (
            <div className="node-manager-switch-v2">
              <Switch
                id={`essential-${String(element?.idBlockElement)}`}
                color="primary"
                checked={Boolean(element?.data?.mandatory)}
                onChange={(e) => handleToggleEssential(e.target.checked)}
                disabled={
                  noEditRediretct ||
                  !permissionLearningPage ||
                  ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                    nodeData?.nodeType !== "ORGANIZATION")
                }
              />

              <label
                className="form-label mr-4"
                htmlFor={`essential-${String(element?.idBlockElement)}`}
                style={{ marginBottom: 0 }}
              >
                Obrigatório
              </label>
              <Switch
                id={`sequential-${String(element?.idBlockElement)}`}
                color="primary"
                checked={Boolean(element?.data?.sequential)}
                onChange={(e) => handleToggleSequential(e.target.checked)}
                disabled={
                  noEditRediretct ||
                  !permissionLearningPage ||
                  ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                    nodeData?.nodeType !== "ORGANIZATION")
                }
              />
              <label
                className="form-label"
                htmlFor={`sequential-${String(element?.idBlockElement)}`}
                style={{ marginBottom: 0 }}
              >
                Sequencial
              </label>
            </div>
          )}
          <div className="node-manager-node-item-actions-container">

            {!externalRedirect  &&
              <div
                onClick={() => {
                    if(!permissionLearningPage){
                      return
                    }
                    handleEditElementRedirect(element, nodeData)
                    // nodeSelectItem(element.data);
                  }
                }
                className={`node-manager-node-item-actions-v2 ${!permissionLearningPage && 'disabled'}`}
              >
                <span>Ir para a página</span>
                <div className="node-manager-node-item-edit-icon">
                  <ExitToAppIcon style={{ fontSize: 25 }} />
                </div>
              </div>
            }

            {(externalRedirect && !hasDadEntryPoint()) &&
              <div
                onClick={() => {
                    if(!permissionLearningPage){
                      return
                    }
                    setlinkGroupModal(true);
                  }
                }
                className={`node-manager-node-item-actions-v2 ${!permissionLearningPage && 'disabled'}`}
              >
                <span>Visibilidade da página</span>
                <div className="node-manager-node-item-edit-icon">
                  <LuEye style={{ fontSize: 25 }} />
                </div>
              </div>
            }


          </div>
        </div>
        <div
          className={`node-manager-content-type-article-image-wrapper ${
            ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
              nodeData?.nodeType !== "ORGANIZATION") ||
            !permissionLearningPage ||
            noEditRediretct
              ? "node-actions-disabled"
              : ""
          }`}
        >
          <div style={{ position: "relative" }}>
            <div
              className="node-manager-actions-delete-container"
              onClick={() => removeNode(element)}
            >
              {loadingDeleteBlockElement === element.idBlockElement ? (
                <CircularProgress style={{ width: 18, height: 18 }} />
              ) : (
                <FiTrash2 style={{ fontSize: 18 }} />
              )}
            </div>
            <img
              alt="article"
              className="node-manager-content-type-article-image-v2"
              src={element.data?.image || SemImagemSmall}
            />
          </div>
        </div>
      </div>
    </>
  );
};
