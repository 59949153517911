import "./styles.scss";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { withRouter, useParams } from "react-router-dom";
import _ from "underscore";
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";
import { Button, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { BsFilter } from "react-icons/bs";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { useHistory } from "react-router-dom";
import ModalDialog from "containers/PeopleCraft/Modals/ModalDialog";
import {
  getTeams,
  getAttendanceList,
  saveAttendanceList,
  finishAttendanceList,
  uploadAttendanceListApi,
  saveAttendanceListPath,
} from "../../../../services/NexiDigitalAPI";
import Loading from "../../../../components/Loading";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { FaCloudUploadAlt } from "react-icons/fa";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { FaFile } from "react-icons/fa";



import useListBlenderPDF from "./Hooks/useListBlenderPDF";

const AttendanceList = () => {
  const history = useHistory();
  const isMountedRef = useRef(null);

  /*Modal*/
  const [modalDilogParamiters, setModalDilogParamiters] = useState({});
  const [showModalDialog, setShowModalDialog] = useState(false);
  const [showModalSignedList, setShowModalSignedList] = useState(false);
  ///

  /*Loading */
  const [loadindData, setLoadindData] = useState(true);

  /*List Pagination and table*/
  const [pageLimit, setPageLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const currentTablePage = useRef(1);
  ///

  const [meetings, setMeetings] = useState([]);
  const [currentMeeting, setCurrentMeeting] = useState({});
  const [lista, setLista] = useState([]);
  const [selectAllAttendance, setSelectAllAttendance] = useState(false);
  const [selectAllNonAttendance, setSelectAllNonAttendance] = useState(false);
  const attendanceListEnabled = useRef(null);
  const [selectAll, setSelectAll] = useState(false);
  const [turmaTitle, setTurmaTitle] = useState("");
  const { idTeam, pageTitle } = useParams();
  const idMeeting = useRef(null);
  const inputSearch = useRef(null);
  const inputSearchRef = useRef(null);
  const [uploadingData, setUploadingData] = useState(false);

  useEffect(() => {
    isMountedRef.current = true;
    getAvailableTeamMeetings();

    return () => (isMountedRef.current = false);
  }, []);

  /* Recuperando a lista de encontros para apresentar a lista de presença dos mesmos. */
  const getAvailableTeamMeetings = async () => {
    setSelectAllAttendance(false);
    setSelectAllNonAttendance(false);
    setSelectAll(false);

    getTeams({ idTeam })
      .then((response) => {
        setMeetings(response.data.meetings);
        setTurmaTitle(response.data.name);
        setLoadindData(false);
      })
      .catch((err) => {
        console.log("Error", err);
        setLoadindData(false);
      });
  };

  /* Recuperando a lista de presença de acordo com os filtros. */
  const getAvailableData = async (newOffset, limit, resetStates = false) => {
    setLoadindData(true);

    if (resetStates) {
      setSelectAllAttendance(false);
      setSelectAllNonAttendance(false);
      setSelectAll(false);
    }

    //TODO: QUANDO O newOffset === 0 É NECESSÁRIO QUE A TABELA RETORNE PARA A PÁGINA 1.
    if (newOffset === 0) {
      currentTablePage.current = 1;
    }

    const haveNewOffset = newOffset || newOffset === 0;

    if (haveNewOffset) {
      setOffset(newOffset);
    }

    const listParameters = {
      searchString: inputSearch.current ? inputSearch.current : null,
      offset: haveNewOffset ? newOffset : offset,
      limit: limit ? limit : pageLimit,
      idTeam: idTeam,
      idMeeting: idMeeting.current,
    };

    const availableData = await getAttendanceList(listParameters).catch((err) => {
      console.log("error", err);
    });

    if (availableData) {
      const totalUsersRecord = availableData.totalRecords;

      const usersList = availableData.attendanceList.map((obj) => {
        if (obj) {
          if (obj.hasOwnProperty("idAttendanceList")) {
            if (obj.idAttendanceList === null) {
              delete obj.idAttendanceList;
            }
          }
          return { ...obj };
        }

        return obj;
      });

      if (selectAllNonAttendance && selectAll && !resetStates) {
        const newListNonAttendance = usersList.map((obj) => {
          if (obj) {
            return { ...obj, present: 0 };
          }

          return obj;
        });

        setLista(_.sortBy(newListNonAttendance, "name"));
      } else if (selectAllAttendance && selectAll && !resetStates) {
        const newListAttendance = usersList.map((obj) => {
          if (obj) {
            return { ...obj, present: 1 };
          }

          return obj;
        });

        setLista(_.sortBy(newListAttendance, "name"));
      } else {
        setLista(_.sortBy(usersList, "name"));
      }

      setTotalRecords(totalUsersRecord);
      setLoadindData(false);
    }
  };

  /* Configurações da tabela */

  const onChangePage = (page) => {
    currentTablePage.current = page;
    const newOffset = (page - 1) * pageLimit;
    getAvailableData(newOffset, null, selectAll ? false : true);
  };

  const onChangeTable = (a, b, c, d) => {
    console.log(a, b, c, d);
  };

  const onChangeRowsPerPage = (limit, page) => {
    setPageLimit(limit);
    const newOffset = (page - 1) * limit;
    getAvailableData(newOffset, limit);
  };

  /* Colunas apresentadas na tabela */
  const columns = [
    {
      name: "Nome",
      sortable: true,
      selector: "name",
      center: false,
      wrap: true,
      width: "30%",
      style: { textAlign: "left" },
      cell: (user) => (
        <div>
          <p>{user.name}</p>
        </div>
      ),
    },
    {
      name: (
        <div className="collumHeader">
          <p>Presente</p>
          <div className="selectAll">
            <Form.Check
              name="group2"
              type={"checkbox"}
              checked={selectAllAttendance}
              onChange={async (e) => {
                setSelectAllAttendance(e.target.checked);
                setSelectAllNonAttendance(false);
                setSelectAll(false);

                if (e.target.checked) {
                  const newList = lista.map((obj) => {
                    if (obj) {
                      return { ...obj, present: 1 };
                    }

                    return obj;
                  });

                  setLista(newList);
                } else {
                  //Envio o offset como null para continuar na mesma página.
                  getAvailableData(null, pageLimit, true);
                }
              }}
            />
          </div>
        </div>
      ),
      selector: "checkbox",
      sortable: false,
      center: false,
      wrap: true,
      width: "150px",
      style: { textAlign: "left" },
      cell: (user) => (
        <div className="ml-1">
          <Form.Check
            name="group1"
            type={"checkbox"}
            checked={user.present == 1}
            onChange={async (e) => {
              setSelectAllAttendance(false);
              setSelectAllNonAttendance(false);
              setSelectAll(false);

              const newList = lista.map((obj) => {
                if (obj === user) {
                  return { ...obj, present: 1 };
                }

                return obj;
              });

              setLista(newList);
            }}
          />
        </div>
      ),
    },

    {
      name: (
        <div className="collumHeader">
          <p>Ausente</p>
          <div className="selectAll">
            <Form.Check
              name="group2"
              type={"checkbox"}
              checked={selectAllNonAttendance}
              onChange={async (e) => {
                setSelectAllNonAttendance(e.target.checked);
                setSelectAllAttendance(false);
                setSelectAll(false);

                if (e.target.checked) {
                  const newList = lista.map((obj) => {
                    if (obj) {
                      return { ...obj, present: 0 };
                    }

                    return obj;
                  });

                  setLista(newList);
                } else {
                  //Envio o offset como null para continuar na mesma página.
                  getAvailableData(null, pageLimit, true);
                }
              }}
            />
          </div>
        </div>
      ),
      selector: "checkbox",
      sortable: false,
      center: false,
      wrap: true,
      width: "150px",
      style: { textAlign: "left" },
      cell: (user) => (
        <div className="ml-1">
          <Form.Check
            name="group3"
            type={"checkbox"}
            checked={user.present == 0}
            onChange={async (e) => {
              setSelectAllAttendance(false);
              setSelectAllNonAttendance(false);
              setSelectAll(false);

              const newList = lista.map((obj) => {
                if (obj === user) {
                  obj.present = 0;
                }

                return obj;
              });

              setLista(newList);
            }}
          />
        </div>
      ),
    },
  ];

  /* Metodos de save e finalização da lista */

  const getAttendanceUsers = () => {
    return lista.filter((obj) => obj.present == 1);
  };

  const getNonAttendanceUsers = () => {
    return lista.filter((obj) => obj.present == 0);
  };

  const getNullAttendanceUsers = () => {
    return lista.filter((obj) => obj.present == null);
  };

  const getAttendanceAndNonAttendanceUsers = () => {
    return lista.filter((obj) => obj.present != null);
  };

  const saveAttendanceListFn = async (e) => {
    if (e) {
      if (e.hasOwnProperty("saveAttendanceListFn")) {
        e.preventDefault();
      }
    }

    setLoadindData(true);

    // const saveAttendanceUsersParams = {
    //   idTeam: idTeam,
    //   idMeeting: idMeeting.current,
    //   users: getAttendanceUsers(),
    //   present: 1
    // };

    // const saveNonAttendanceUsersParams = {
    //   idTeam: idTeam,
    //   idMeeting: idMeeting.current,
    //   users: getNonAttendanceUsers(),
    //   present: 0
    // };

    const saveAttendanceAndNonAttendanceUsers = {
      idTeam: idTeam,
      idMeeting: idMeeting.current,
      users: getAttendanceAndNonAttendanceUsers(),
    };

    const savePromise = new Promise((resolve, reject) => {
      saveAttendanceList(
        saveAttendanceAndNonAttendanceUsers,
        selectAll !== true ? null : selectAllAttendance ? 1 : 0,
      )
        .then((response) => {
          resolve(true);
          setLoadindData(false);

          if (e) {
            history.goBack();
          }
        })
        .catch((err) => {
          console.log("Error", err);
          setLoadindData(false);
        });
    });

    return savePromise;
  };

  const finishAttendanceListFn = (e) => {
    if (e) {
      if (e.hasOwnProperty("saveAttendanceListFn")) {
        e.preventDefault();
      }
    }

    setLoadindData(true);

    setModalDilogParamiters({
      text: `Você tem certeza que deseja encerrar a lista de presença? Após finalizada a lista não poderá ser editada.`,
      variantBtn: "danger",
      textBtn: "Encerrar lista de presença",
      fnc: async () => {
        //Antes de finalizar a lista de presença é necessário salvar as possíveis alterações.
        await saveAttendanceListFn();

        const finishAttendanceListParams = {
          idTeam: idTeam,
          idMeeting: idMeeting.current,
        };

        //Finalizando a lista.
        finishAttendanceList(finishAttendanceListParams)
          .then((response) => {
            setLoadindData(false);
            history.goBack();
          })
          .catch((err) => {
            console.log("Error", err);
            setLoadindData(false);
          });
      },
    });

    setShowModalDialog(true);
  };

  const { createListBlender, preloaderListBlender } = useListBlenderPDF({
    turmaTitle,
    pageTitle,
    currentMeeting,
    idTeam,
    idMeeting: idMeeting.current,
  });

  const openSignedListModal = useCallback(() => {
    if (currentMeeting?.idMeeting) {
      setShowModalSignedList(true);
    }
  }, [currentMeeting?.idMeeting]);


  const uploadSignedList = useCallback((file) => {
    setUploadingData(true);
    const selectedFile = file.files[0];

    if (selectedFile) {
      const filename = selectedFile.name.split(".");
      filename.pop();

      const data = new FormData();
      data.append("fileData", selectedFile);
      data.append("fileName", filename.join());
      data.append("idMeeting", currentMeeting?.idMeeting);

      uploadAttendanceListApi(data)
        .then(async (response) => {
          if (response.data.success) {
            await saveAttendanceListPath(
              idTeam,
              currentMeeting?.idMeeting,
              response.data.attendanceList
            );
            setCurrentMeeting(prev => ({
              ...prev,
              signedAttendanceListPath: response.data.attendanceList,
            }));
          }
        })
        .catch((e) => {
          alert(`Não fi possível processar o arquivo '${selectedFile.name}'.`);
        })
        .finally(() => {
          setUploadingData(false);
        });
    } else {
      setUploadingData(false);
    }
  }, [currentMeeting?.idMeeting, idTeam]);

  const clearInputPath = () => {
    let element = document.getElementById("upload-button-file");
    element.value = "";
  };

  const downloadSignedList = useCallback(() => {
    if (currentMeeting?.signedAttendanceListPath) {
      const { domain } = JSON.parse(localStorage.getItem("appDetails"));
      const link = document.createElement("a");
      link.href = `https://${domain}/${currentMeeting.signedAttendanceListPath}`;
      link.target = "_blank";
      link.click();
    }
  }, [currentMeeting?.signedAttendanceListPath]);

  return (
    <div className="meetingsInsertUsers">
      {(loadindData || preloaderListBlender) && <Loading className={"loadindData"} />}

      {showModalDialog && (
        <ModalDialog
          title={modalDilogParamiters.title}
          text={modalDilogParamiters.text}
          show={showModalDialog}
          setShow={setShowModalDialog}
          btnCall={{
            className: "",
            variantBtn: modalDilogParamiters.variantBtn,
            text: modalDilogParamiters.textBtn,
            alertMode: modalDilogParamiters.alertMode,
            callEvent: () => {
              if (modalDilogParamiters.fnc) {
                modalDilogParamiters.fnc();
              }
            },
          }}
          btnDenyCall={modalDilogParamiters.fncDeny}
        />
      )}

      <Modal
        className="modal-signed-list"
        size="lg"
        onHide={() => setShowModalSignedList(false)}
        show={showModalSignedList}
        centered
      >
        <Modal.Header className="modal-signed-list-header">
          <div>Lista de presença assinada</div>
          <div
            onClick={() => setShowModalSignedList(false)}
            className="modal-warning-close-container"
            style={{ margin: 15, padding: 0, backgroundColor: "#6b48ff" }}
          >
            <CloseIcon style={{ color: "white" }} className="modal-warning-close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modal-signed-list-body">
          <p>
            <strong>Nome da turma:</strong> {turmaTitle}
          </p>
          <p>
            <strong>Encontro:</strong>{" "}
            {`[${new Date(currentMeeting.startDate).toLocaleDateString("pt-BR")} - ${new Date(
              currentMeeting.finishDate
            ).toLocaleDateString("pt-BR")}] ${currentMeeting.meetingType} - ${
              currentMeeting.speaker
            }`}
          </p>
          <div className="modal-signed-list-upload-container">
            <div className="file-info">
              <div>Faça upload de arquivo</div>
              <div>
                PDF, JPEG, PNG (<strong>10MB</strong> limite de tamanho)
              </div>
            </div>
            <div className="file-upload">
              <label htmlFor="upload-button-file">
                <div className="btn btn-primary">
                  <FaCloudUploadAlt /> {uploadingData ? "Uploading..." : "Carregar lista assinada"}
                  <input
                    disabled={uploadingData}
                    accept=".pdf,.jpg,.jpeg,.png"
                    id="upload-button-file"
                    type="file"
                    hidden
                    onChange={(e) => uploadSignedList(e.target)}
                    onClick={() => clearInputPath()}
                  />
                </div>
              </label>
            </div>
          </div>
          <div className="modal-signed-list-result-container">
            {currentMeeting?.signedAttendanceListPath ? (
              <div className="result-info-file">
                <div>{currentMeeting?.signedAttendanceListPath.split("/").pop()}</div>
                <div className="btn-download" onClick={() => downloadSignedList()}>
                  <FaArrowAltCircleDown />
                </div>
              </div>
            ) : (
              <div className="result-info-empty">
                <div>
                  <FaFile />
                </div>
                <div>Não há arquivos para serem exibidos</div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <div className="PA-header-title-wrapper">
        <div className="PA-header-title">
          <button
            className="btnGoBack"
            onClick={() => {
              history.goBack();
              // history.push('/');
            }}
          >
            <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
          </button>
          Gestão de turmas / <b>Lista de presença</b>
        </div>
      </div>

      <div className="meetingListHeader">
        <h5>
          Lista de presença {pageTitle} - {turmaTitle}
        </h5>

        <div className="filters">
          <div className="meetingChoice">
            <h6>Encontro:</h6>
            <Form.Control
              className=""
              as="select"
              value={null}
              onChange={(e) => {
                if (e.target.value === "Selecione um encontro") {
                  setCurrentMeeting({});
                  setSelectAllAttendance(false);
                  setSelectAllNonAttendance(false);
                  setSelectAll(false);
                  setLista([]);
                  return;
                }
                const selectedMeeting = JSON.parse(e.target.value);
                setCurrentMeeting(selectedMeeting);
                idMeeting.current = selectedMeeting.idMeeting;
                attendanceListEnabled.current = selectedMeeting.attendanceListEnabled;
                getAvailableData(0, pageLimit, true);
              }}
              placeholder={`Selecione um encontro`}
            >
              <option value={null}>{"Selecione um encontro"}</option>
              {meetings ? (
                meetings.map((meeting, i) => {
                  return (
                    <option key={i} value={JSON.stringify(meeting)}>
                      {meeting.startDate
                        ? `[ ${new Date(meeting.startDate).toLocaleDateString(
                            "pt-BR"
                          )} - ${new Date(meeting.finishDate).toLocaleDateString("pt-BR")} ] ${
                            meeting.meetingType
                          } - ${meeting.speaker}`
                        : `Encontro ${i + 1}`}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
            </Form.Control>
          </div>

          <div className={`filtersOptions ${lista.length === 0 ? "disabled" : ""}`}>
            <h6>Procurar aluno:</h6>
            <div className="searchInfo">
              <AiOutlineSearch />
              <Form.Control
                ref={inputSearchRef}
                onKeyDown={(input) => {
                  if (input.key === "Enter") {
                    getAvailableData(0, pageLimit, true);
                  }
                }}
                onChange={(e) => {
                  inputSearch.current = e.target.value;
                }}
                placeholder={`Procurar por nome ou e-mail`}
              />
              <button
                onClick={() => {
                  inputSearchRef.current.value = "";
                  inputSearch.current = "";
                  getAvailableData(0, pageLimit, true);
                }}
              >
                <AiOutlineClose />
              </button>
            </div>
          </div>

          <div className="btnList">
            <Button
              variant="primary"
              className={`filterUsersBtn ${lista.length === 0 ? "disabled" : ""}`}
              onClick={() => getAvailableData(0, pageLimit, true)}
            >
              <BsFilter />
              Filtrar
            </Button>

            <div>
              <Button
                variant="primary"
                className={`${lista.length === 0 ? "disabled" : ""}`}
                onClick={createListBlender}
              >
                Baixar PDF da Lista
              </Button>

              <Button
                variant="warning"
                className={`${lista.length === 0 ? "disabled" : ""}`}
                onClick={openSignedListModal}
              >
                {currentMeeting?.signedAttendanceListPath
                  ? "Visualizar lista assinada"
                  : "Anexar lista assinada"}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="listBody">
        {lista.length > 0 && (
          <>
            <div className="selectAll">
              {(selectAllAttendance === true || selectAllNonAttendance === true) && (
                <p className="selectAllMsg">
                  {" "}
                  <b>{selectAll ? totalRecords : lista.length} alunos</b> foram marcados como{" "}
                  {selectAllAttendance ? "presentes" : "ausentes"}.
                  {selectAll === false ? (
                    <spam
                      className="btnSelectAll"
                      onClick={(e) => {
                        setSelectAll(true);

                        setModalDilogParamiters({
                          text: `Atenção! Todos os participantes do encontro foram marcados como ${
                            selectAllAttendance ? "presentes" : "ausentes"
                          }. Para salvar as alterações é necessário clicar no botão "salvar e fechar".`,
                          variantBtn: "danger",
                          alertMode: true,
                        });

                        setShowModalDialog(true);
                      }}
                    >
                      Marcar todos os {totalRecords} alunos do encontro como{" "}
                      {selectAllAttendance ? "presentes" : "ausentes"}.
                    </spam>
                  ) : (
                    <spam
                      className="btnSelectAll"
                      onClick={(e) => {
                        //Envio o offset como null para continuar na mesma página.
                        getAvailableData(null, pageLimit, true);
                      }}
                    >
                      Desmarcar todos os {totalRecords} alunos do encontro como{" "}
                      {selectAllAttendance ? "presentes" : "ausentes"}.
                    </spam>
                  )}
                </p>
              )}
            </div>

            {lista.length > 0 && attendanceListEnabled.current == 0 && (
              <div className="listCompleted">
                <h6>*Atenção! Esta lista de presença já foi encerrada e não pode ser editada.</h6>
              </div>
            )}
            <div className="meetingListTableContainer">
              <DataTable
                disabled={attendanceListEnabled.current == 0}
                columns={columns}
                data={lista}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                paginationTotalRows={totalRecords}
                paginationComponentOptions={{
                  rowsPerPageText: "Conteúdos por página",
                  rangeSeparatorText: "de",
                }}
                responsive={true}
                paginationDefaultPage={currentTablePage.current}
                paginationResetDefaultPage={true}
                paginationRowsPerPageOptions={[10, 30, 50, 70, 100]}
                paginationServer
                noHeader
                fixedHeader
                pagination
              />
            </div>
          </>
        )}
      </div>

      {lista.length > 0 && attendanceListEnabled.current == 1 && (
        <div className="userListFooter">
          <p className="listFinishInfo">
            Atenção! Você terá até 10 dias corridos após a data fim da turma para finalizar as
            listas de presença dos encontros. Após essa data a lista será finalizada
            automaticamente.
          </p>
          <button
            className="button-peop saveBtn"
            onClick={(e) => {
              saveAttendanceListFn(e);
            }}
          >
            Salvar e fechar
          </button>
          <button
            className="button-peop button-yellow finishBtn"
            onClick={(e) => {
              finishAttendanceListFn(e);
            }}
          >
            Encerrar lista de presença
          </button>
        </div>
      )}
    </div>
  );
};

export default withRouter(AttendanceList);
