import React, { memo } from "react";
import { Tooltip } from "@material-ui/core";
import IcoEdit from "assets/images/ico-edit.svg";

const EditRow = memo(({ setControlModals, data, redirectEditTerm }) => (
  <Tooltip title="Editar" placement="top">
    <span
      className="btn btn-sm float-right icon"
      onClick={() => {
        if (data?.alreadySigned) {
          setControlModals(prev => ({
            showModals: { ...prev.showModals, edit: true },
            id: data.idTermOfResponsibility,
          }));
        } else {
          redirectEditTerm(data.idTermOfResponsibility);
        }
      }}
    >
      <img src={IcoEdit} alt="icon-edicao" />
    </span>
  </Tooltip>
));

export default EditRow;
