import React, { useState, useEffect } from "react";

import moment from "moment";
import "moment/locale/pt-br.js";

// Page UI and icons
import { Col, Form } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import { getUsersApi } from "../../../../../services/NexiDigitalAPI";
import FiltroWrapper from "../../../Components/FiltroWrapper";
import styles from "./styles.module.scss";
import Select from "react-select";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";

const Filtro = ({ actionFilter, loading }) => {
  const [name, setName] = useState("");

  const initialSelected = {
    value: 0,
    label: "",
  };

  const [userList, setUserList] = useState([]);
  const [selected, setSelected] = useState(initialSelected);
  const [options, setOptions] = useState([]);

  async function startUsers() {
    const usersApi = await getUsersApi();
    setUserList(usersApi);
    const treatedOptions = usersApi.map((item) => {
      return { value: item.idUser, label: item.name };
    });
    setOptions(treatedOptions);
  }

  function handleFilter() {
    actionFilter(name);
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      borderRadius: "0 0 1px 1px",
      border: "1px solid #ced4da",
    }),
  };

  function handleChangeName(e) {
    const name = e.target.value;
    if (name && name !== undefined && name != null && name !== "") setName(e.target.value);
    else setName("");
  }
  useEffect(() => {
    startUsers();
  }, []);

  return (
    <FiltroWrapper>
      <Form style={{ marginTop: 30, marginBottom: 30 }}>
        <Form.Row className="align-items-end">
          <Col xs="12" md="8" lg="8" className={styles.formSpace}>
            <Form.Label htmlFor="dateStart">Campanha</Form.Label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => {
                handleChangeName(e);
              }}
              placeholder={"nome da campanha"}
            />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Group className="mb-0">
              <ButtonWithLoading
                onClickHandler={handleFilter}
                loading={loading}
                text={
                  <>
                    Aplicar filtro <FaFilter style={{ marginLeft: 5 }} />{" "}
                  </>
                }
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    </FiltroWrapper>
  );
};

export default Filtro;
