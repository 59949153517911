import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { ButtonGroup, Button, Menu, MenuItem, TextField } from "@material-ui/core";
import { FaBold, FaItalic, FaUnderline, FaLink } from "react-icons/fa";
import "./styles.scss";
import { getInnerText } from "utils/Utils";
import { toast } from "react-toastify";

const TextEditor = forwardRef(
  (
    { initialContent = "", placeholder, className = "", maxLength = 500, onChange, ...extraProps },
    ref,
  ) => {
    const editorRef = useRef(null);
    const urlRef = useRef(null);
    const textRef = useRef(null);
    const menuRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [linkURL, setLinkURL] = useState("");
    const [linkText, setLinkText] = useState("");
    const [content, setContent] = useState(initialContent);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [savedSelection, setSavedSelection] = useState(null);

    useEffect(() => {
      return () => {
        setContent("");
        setIsBold(false);
        setIsItalic(false);
        setIsUnderline(false);
      };
    }, []);

    useImperativeHandle(ref, () => ({
      getContent: () => {
        return editorRef.current.innerHTML;
      },
    }));

    const saveSelection = () => {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        setSavedSelection(range);
      }
    };

    const restoreSelection = () => {
      if (savedSelection) {
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(savedSelection);
      }
    };

    const applyStyle = (style) => {
      restoreSelection();
      document.execCommand(style);
      saveSelection();
      handleChange();
    };

    const toggleBold = () => {
      setIsBold(!isBold);
      applyStyle("bold");
    };

    const toggleItalic = () => {
      setIsItalic(!isItalic);
      applyStyle("italic");
    };

    const toggleUnderline = () => {
      setIsUnderline(!isUnderline);
      applyStyle("underline");
    };

    const isValidURL = (url) => {
      const pattern = /^(https?:\/\/|www\.)\S+/i;
      return pattern.test(url);
  };

    const insertLink = (url, text) => {
      if (!isValidURL(url)) {
        console.log('Formato de url inválido')
        toast.error('Formato de url inválido');
        return;
      }
      restoreSelection();
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const linkHTML = `<a href="${url}" target="_blank" rel="noopener noreferrer">${text}</a>`;
        range.deleteContents();
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = linkHTML;
        const frag = document.createDocumentFragment();
        let child;
        while ((child = tempDiv.firstChild)) {
          frag.appendChild(child);
        }
        editorRef.current?.id === selection?.focusNode?.id
          ? range.insertNode(frag)
          : editorRef.current?.appendChild(frag);

        saveSelection();
      }
      handleChange();
    };

    const handleLink = (event) => {
      saveSelection();
      setAnchorEl(event.currentTarget);
    };

    const handleLinkSubmit = () => {
      if (linkURL) {
        insertLink(linkURL, linkText || linkURL);
      }
      handleLinkClose();
    };

    const handleLinkClose = () => {
      setAnchorEl(null);
      setLinkURL("");
      setLinkText("");
    };

    const handleChange = () => {
      if (editorRef.current) {
        const newContent = editorRef.current.innerHTML;
        setContent(newContent);
        if (onChange) {
          onChange(newContent);
        }

        if (getInnerText(newContent).trim().length === 0) {
          setIsBold(false);
          setIsItalic(false);
          setIsUnderline(false);
        }
      }
    };

    const handleInput = () => {
      handleChange();
    };

    const handleEnterPress = (event) => {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        if (document.activeElement === urlRef.current) {
          textRef.current?.focus();
        } else {
          urlRef.current?.focus();
        }
      }
    };

    return (
      <div>
        <div
          contentEditable
          ref={editorRef}
          dangerouslySetInnerHTML={{
            __html: initialContent,
          }}
          onInput={handleInput}
          onBlur={saveSelection}
          className={`form-control ${className}`}
          style={{
            minHeight: "100px",
            overflow: "auto",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
          placeholder={placeholder}
          {...extraProps}
          suppressContentEditableWarning={true}
        />
        <div
          className="editor-toolbar"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10px",
            marginBottom: 10,
          }}
        >
          <ButtonGroup>
            <Button
              onClick={toggleBold}
              style={{ backgroundColor: isBold ? "#d3d3d3" : "transparent", border: "none" }}
            >
              <FaBold />
            </Button>
            <Button
              onClick={toggleItalic}
              style={{ backgroundColor: isItalic ? "#d3d3d3" : "transparent", border: "none" }}
            >
              <FaItalic />
            </Button>
            <Button
              onClick={toggleUnderline}
              style={{ backgroundColor: isUnderline ? "#d3d3d3" : "transparent", border: "none" }}
            >
              <FaUnderline />
            </Button>
            <Button onClick={handleLink} style={{ border: "none", marginLeft: 20 }}>
              <FaLink />
            </Button>
          </ButtonGroup>
          <span style={{ marginLeft: "auto" }}>
            {getInnerText(content)?.length || 0}/{maxLength}
          </span>
        </div>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleLinkClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          keepMounted
          ref={menuRef}
          getContentAnchorEl={null}
          disableScrollLock
        >
          <MenuItem>
            <TextField
              key="url"
              type="text"
              placeholder="URL"
              inputRef={urlRef}
              onKeyDown={handleEnterPress}
              value={linkURL}
              variant="outlined"
              onChange={(e) => setLinkURL(e.target.value)}
              autoFocus
            />
          </MenuItem>
          <MenuItem>
            <TextField
              key="texto"
              type="text"
              placeholder="Texto"
              inputRef={textRef}
              onKeyDown={handleEnterPress}
              value={linkText}
              onChange={(e) => setLinkText(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </MenuItem>
          <MenuItem>
            <Button disabled={!isValidURL(linkURL)} onClick={handleLinkSubmit} color="primary" variant="contained">
              Inserir
            </Button>
          </MenuItem>
        </Menu>
      </div>
    );
  },
);

export default TextEditor;
