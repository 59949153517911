import React, { useState, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import FilterWrapper from "../../../../../components/FilterWrapper";
import styles from "./styles.module.scss";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import { DatePickerInput } from "rc-datepicker";
import moment from "moment";
import "moment/locale/pt-br.js";
import Select from "react-select";
import { getProductList } from "../../../../../services/NexiDigitalAPI";
import DataTableExtensions from "react-data-table-component-extensions";

const Filter = ({ actionFilter, initialInfo, loading }) => {
  const initialSelected = {
    value: 0,
    label: "",
  };

  const [value, setValue] = useState(initialInfo);
  const [products, setProducts] = useState([]);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(initialSelected);

  const findProduct = (idProduct) => {
    return products.filter((obj) => obj.idProduct === idProduct);
  };

  const changeProduct = async (product) => {
    setSelected(product);
    const productInfo = await findProduct(product.value);
    const hasProductInfo = Boolean(productInfo.length);
    if (hasProductInfo) {
      setValue({
        ...value,
        idProduct: product.value,
        title: productInfo[0].title,
        imageURL: productInfo[0].imageURL,
        idApp: productInfo[0].idApp,
      });
    }
  };

  const submitFilter = () => {
    actionFilter(value);
  };

  const changeStartData = (jsDate) => {
    setValue({
      ...value,
      startDate: moment(jsDate).format("YYYY-MM-DD"),
    });
  };

  const changeEndData = (jsDate) => {
    setValue({
      ...value,
      endDate: moment(jsDate).format("YYYY-MM-DD"),
    });
  };

  const getProducts = async () => {
    const productList = await getProductList();
    setProducts(productList);
    const treatedOptions = productList.map((item) => {
      return { value: item.idProduct, label: item.title };
    });
    setOptions(treatedOptions);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      borderRadius: "0 0 1px 1px",
      border: "1px solid #ced4da",
    }),
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <FilterWrapper>
      <Form style={{ marginTop: 30, marginBottom: 30 }}>
        <Form.Row className="align-items-end" style={{ justifyContent: "space-between" }}>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="dateStart">Produto</Form.Label>
            <Select
              options={options}
              id="cadastroProdutoModalProduto"
              value={selected}
              onChange={changeProduct}
              name="idProduct"
              styles={customStyles}
            />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="dateStart">Data inicial</Form.Label>
            <DatePickerInput
              name="dateStart"
              locale="pt-br"
              onChange={changeStartData}
              value={value.startDate}
            />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="dateStart">Data final</Form.Label>
            <DatePickerInput
              name="dateStart"
              locale="pt-br"
              onChange={changeEndData}
              value={value.endDate}
            />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Group className="mb-0">
              <ButtonWithLoading
                onClickHandler={submitFilter}
                loading={loading}
                text={
                  <>
                    {" "}
                    Aplicar filtro <FaFilter style={{ marginLeft: 5 }} />{" "}
                  </>
                }
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    </FilterWrapper>
  );
};

export default Filter;
