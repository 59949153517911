import "./style.modules.scss";

import React, { useState, useContext, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

import { getCertificatePlaceholders } from "../../../../../services/NexiDigitalAPI";

import ColorPicker from "material-ui-color-picker";

import Switch from "react-bootstrap/esm/Switch";

import { FaImage } from "react-icons/fa";
import { CgColorBucket } from "react-icons/cg";

import { CECreateContext } from "../../Contexts/CEContext";

import { imgBASE64 } from "../../Utils/imgBASE64";

const CEMenu = (props) => {
  const {
    setPreloadInit,
    filterCertificates,
    setFilterCertificates,
    nameCertificates,
    setNameCertificates,
    canvasCertificates,
    propStyle,
    setPropStyle,
    setBgChange,
    setInputActive,
    syllabus,
    setSyllabus,
    setBackgroundSyllabus,
    setTypeView,
  } = useContext(CECreateContext);

  const inputImageRef = useRef(null);

  useEffect(() => {
    let componentMounted = true;

    if (filterCertificates === null) {
      setPreloadInit(true);
      getCertificatePlaceholders()
        .then((res) => {
          if (componentMounted) {
            setPreloadInit(false);

            let filterArray = [];
            res.map((it) => {
              const el = [`{${it.label}}`, it.placeholder, it.label];
              filterArray = [...filterArray, el];
              return it;
            });

            setFilterCertificates(filterArray);
          }
        })
        .catch((error) => {
          // Lidar com erros, se necessário
        });
    }

    return () => {
      componentMounted = false;
    };
  }, [filterCertificates]);

  const onColorChange = (color) => {
    if (canvasCertificates) {
      if (canvasCertificates.backgroundImage) {
        canvasCertificates.setBackgroundImage(null);
        canvasCertificates.renderAll();
      }

      setPropStyle({ ...propStyle, backgroundColor: color });
      canvasCertificates.backgroundColor = color;
      setBackgroundSyllabus({ result: color, type: "color" });
      // setBgChange(color);
      canvasCertificates.renderAll();
    }
  };

  return (
    <div className="CEMenu">
      <h4 className="CeMenuTitle">Editar certificado</h4>
      <div className="input-txt-base">
        <p>Nome</p>
        <Form.Control
          type="text"
          className="form-control"
          id="name"
          aria-describedby="name"
          placeholder="Nome do Certificado"
          value={nameCertificates}
          onChange={(e) => setNameCertificates(e.target.value)}
          maxLength={50}
          onFocus={(e) => {
            setInputActive(true);
            // if (canvasCertificates) {
            //   // Quando você quiser tirar o foco do canvas (desselecionar qualquer objeto):
            //   canvasCertificates.discardActiveObject().renderAll();
            // }
          }}
          onBlur={(e) => {
            setInputActive(false);
          }}
        />
      </div>
      <div className="tagsExemple">
        <div>
          <p className="titleTags">
            <b>Campos dinâmicos</b>
          </p>
          <p>
            Digite os campos nos formatos abaixo para que eles apareçam dinamicamente no
            certificado.
          </p>
        </div>
        <div className="tagslists">
          {filterCertificates &&
            filterCertificates.map((it, indice) => {
              return <p key={indice}>{it[0]}</p>;
            })}
        </div>
      </div>

      <div className="bgCanvas">
        <div className="syllabusContainer">
          <Switch
            color="primary"
            id="syllabus"
            checked={!!syllabus}
            onChange={(e) => {
              setSyllabus(e.target.checked ? 1 : 0);

              if (!e.target.checked) {
                setTypeView("certificate");
              }
            }}
          />
          <label className="form-label" htmlFor="syllabus">
            Incluir ementa cadastrada da página no certificado.
          </label>
        </div>
        <div className="titCan">Adicionar uma imagem ou cor de fundo</div>
        <div className="titData">
          <p>Tamanho recomendado: 1124px x 794px</p>
          <p>Suporta imagens (JPG e PNG)</p>
        </div>
        <div className="controlBg">
          <Button
            variant="success"
            className="btnCarregar"
            onClick={() => {
              inputImageRef.current.click();
            }}
          >
            <FaImage />
            Carregar imagem
          </Button>
          <Button variant="info" className="btnColor">
            <CgColorBucket />
            <ColorPicker
              onChange={(e) => {
                onColorChange(e);
              }}
              value={propStyle.backgroundColor}
            />
          </Button>
          <input
            ref={inputImageRef}
            type="file"
            id="imgObj"
            name="imgObj"
            accept="image/png, image/jpeg"
            onChange={(e) =>
              imgBASE64(e, "background", canvasCertificates, (it, result) => {
                setBackgroundSyllabus({ result: result, type: "image" });
                setBgChange(it);
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(CEMenu);
