import "./styles.scss";

import React from "react";

const CEPreloaderBlur = (props) => {
  return (
    <div className="CEPreloader">
      <div className="NMPreloaderFundo"></div>
      <div className="loader loader-big" />
    </div>
  );
};

export default CEPreloaderBlur;
