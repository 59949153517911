import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import styles from "./styles.module.scss";
import moment from "moment";

const Table = ({ dados, loading, totalRecords, actionFilter, trainingsValue }) => {
  const statusFormater = (status) => {
    switch (status) {
      case "NOT_ATTEMPTED":
        return "Não iniciado";
      case "IN_PROGRESS":
        return "Em progresso";
      case "COMPLETED":
        return "Concluído";
      case "PASSED":
        return "Aprovado";
      case "FAILED":
        return "Reprovado";
      case "EXPIRED":
        return "Expirado";
      case "APPROVED":
        return "Aprovado";
      case "DRAFT":
        return "Rascunho";
      case "ARCHIVED":
        return "Arquivado";
      case "REJECTED":
        return "Reprovado";
      case "DISAPPROVED":
        return "Reprovado";
      case "PUBLISHED":
        return "Publicado";
      default:
    }
  };

  const ValidateCertificteStatusData = (expired) => {
    let certificateStatus;
    if (expired) {
      certificateStatus = 'Expirado';
    } else {
      certificateStatus = 'Válido';
    }
    return <span>{certificateStatus}</span>;
  };

  const ValidateData = (_status, res = null) => {
    if (_status) {
      return <span>{res ? res : _status}</span>;
    }

    return <span> - </span>;
  };

  const formatValidity = (validity) => {
    if(validity){
      return `${(Math.floor((validity || 0) / 12))}
      ${(Math.floor((validity || 0) / 12)) === 1 ? ' ano' : ' anos'}
      e ${(validity || 0) % 12}${(validity || 0) % 12 === 1 ? ' mês' : ' meses'}
      `
    }
    return "-";
  }

  const columns = [
    {
      name: "Nome do usuário",
      selector: "name",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.name),
    },
    {
      name: "E-mail",
      selector: "email",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.email),
    },
    {
      name: "Código do colaborador",
      selector: "employeeCode",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.employeeCode),
    },
    {
      name: "Empresa",
      selector: "company",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.company),
    },
    {
      name: "Cargo",
      selector: "role",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.role),
    },
    {
      name: "Departamento",
      selector: "department",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.department),
    },
    {
      name: "Unidade",
      selector: "unit",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.unit),
    },
    {
      name: "Gestor imediato",
      selector: "manager",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.manager),
    },
    {
      name: "Nome do treinamento",
      selector: "title",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.title),
    },
    {
      name: "Progresso",
      selector: "progress",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => ValidateData(d.progress),
    },

    {
      name: "Status",
      selector: "status",
      sortable: true,
      center: true,
      cell: (d) => ValidateData(d.status, statusFormater(d.status)),
    },
    {
      name: "Data da inscrição",
      selector: "enrollDate",
      sortable: true,
      center: true,
      cell: (d) => ValidateData(d.enrollDate, moment(d.enrollDate).format("DD/MM/YYYY")),
    },
    {
      name: "Data do primeiro acesso",
      selector: "startDate",
      sortable: true,
      center: true,
      cell: (d) => ValidateData(d.startDate, moment(d.startDate).format("DD/MM/YYYY")),
    },
    {
      name: "Data de finalização",
      selector: "finishDate",
      sortable: true,
      center: true,
      cell: (d) => ValidateData(d.finishDate, moment(d.finishDate).format("DD/MM/YYYY")),
    },
    {
      name: "Validade do treinamento",
      selector: "validity",
      sortable: true,
      center: true,
      cell: (d) => ValidateData(d.validity, formatValidity(d.validity))
    },
    {
      name: "Status do certificado",
      selector: 'expired',
      sortable: true,
      center: true,
      cell: (d) => ValidateCertificteStatusData(Boolean(d.expired)),
    },
    {
      name: "Data de expiração",
      selector: "expiresIn",
      sortable: true,
      center: true,
      cell: (d) => ValidateData(d.expiresIn, moment(d.expiresIn).format("DD/MM/YYYY")),
    },
    {
      name: "Gerou o certificado?",
      selector: "generatedCertificate",
      sortable: true,
      center: true,
      cell: (d) => ValidateData(d.generatedCertificate),
    },
  ];

  const customTableStyles = {
    rows: {
      style: {
        paddingTop: "10px",
        paddingBottom: "10px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        paddingTop: "10px",
        paddingBottom: "10px",
        textAlign: "center",
        width: "150px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        textAlign: "center",
      },
    },
    header: {
      style: {
        fontSize: "22px",
      },
    },
  };

  const tableData = {
    columns: columns,
    data: dados,
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Usuários por página",
    rangeSeparatorText: "de",
  };

  const onChangePage = (page) => {
    //setPagination((prev) => ({ ...prev, offset: --page * prev.limit }));

    const offset = --page * trainingsValue.limit;
    actionFilter({ ...trainingsValue, offset: offset });
  };

  const onChangeRowsPerPage = (limit) => {
    actionFilter({ ...trainingsValue, offset: 0, limit: limit });
    //setPagination((prev) => ({ ...prev, limit, offset: 0 }));
  };

  const loadingComponent = () => {
    return (
      <div className="tela-login-loading">
        <div className="loader-container" style={{ margin: "300px 0" }}>
          <div
            style={{
              position: "initial",
              margin: "initial",
            }}
            className="loader loader-center loader-big"
          ></div>
          <span
            style={{
              marginTop: 10,
              fontSize: 20,
            }}
          >
            Carregando dados...
          </span>
        </div>
      </div>
    );
  };

  return (
    <DataTableExtensions
      filter={false}
      print={false}
      {...tableData}
      exportHeaders={false}
      export={false}
    >
      <DataTable
        columns={columns}
        data={dados}
        className={styles.dataTableFormat}
        customStyles={customTableStyles}
        progressPending={loading}
        progressComponent={loadingComponent()}
        noHeader={true}
        noDataComponent="Não foi encontrado nenhum dado"
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        paginationTotalRows={totalRecords}
        paginationComponentOptions={paginationComponentOptions}
        paginationServer
        pagination
      />
    </DataTableExtensions>
  );
};

export default Table;
