import "./styles.modules.scss";

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { getTags } from "services/NexiDigitalAPI";

import Tags from "components/Tags";
import _ from "underscore";

const TagsQuestion = (props) => {
  const { tagEnd, setTagEnd } = props;

  const [listRegistered, setListRegistered] = useState(null);

  useEffect(() => {
    if (!listRegistered) {
      getTags().then((res) => {
        const tagSearch = res.data.tagsearch;
        const tagsSorted = _.sortBy(tagSearch, "tag");
        const objListTags = tagsSorted.map((item) => {
          return { id: item.idTag, name: item.tag };
        });
        setListRegistered(objListTags);
      });
    }
  }, []);

  return (
    <Tags
      handleNewTags={(newTags) => {
        setTagEnd(newTags);
      }}
      lista={listRegistered || []}
      contentTags={tagEnd}
    />
  );
};

export default withRouter(TagsQuestion);
