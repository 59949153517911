import React from "react";
import styles from "./styles.module.scss";
import { MdEdit } from "react-icons/md";
import { FaMinusCircle } from "react-icons/fa";
import StandartDataTable from "../../../../../components/StandartDataTable";

const Table = ({ List, editProduct, RemoveList, loading }) => {
  const columns = [
    { name: "IMAGEM", selector: "image", sortable: true },
    { name: "NOME", selector: "title", sortable: true },
    { name: "AÇÕES", selector: "action", sortable: false },
  ];

  const treatedList = List.map((item) => {
    return {
      ...item,
      image: <img src={item.imageURL} alt="" height="75" width="75" style={{ margin: "5px 0" }} />,
      action: (
        <>
          <button className={styles.actionButton} onClick={() => editProduct(item)}>
            <MdEdit className={styles.actionIcon} />
          </button>
          {false && (
            <button className={styles.actionButton}>
              <FaMinusCircle className={styles.actionIcon} />
            </button>
          )}
        </>
      ),
    };
  });

  return (
    <div className="report-table-container">
      <StandartDataTable columns={columns} data={treatedList} loading={loading} />
    </div>
  );
};

export default Table;
