import React, { useState, useEffect } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import InfoIcon from "@material-ui/icons/Info";

import "./style.scss";
import { listNotificationSchedule, updateNotificationSchedule } from "services/NexiDigitalAPI";
import { Button, Form, InputGroup } from "react-bootstrap";
import { BiTrash } from "react-icons/bi";

interface ModalNotificationConfigProps {
  type: string;
  description: string;
  idNotification: number;
  idTeam: number;
}

const ModalNotificationConfig = NiceModal.create((props: ModalNotificationConfigProps) => {
  const [schedules, setSchedule] = useState([{ days: null, progress: null }]);
  const [errors, setErrors] = useState<string[]>([]);
  const isTeamProgress = props.type === "TEAM_PROGRESS";

  useEffect(() => {
    (async () => {
      const { data } = await listNotificationSchedule(props.idTeam, props.idNotification); 
      setSchedule(data);
    })();
  }, [props]);

  const handleAdd = () => {
    setErrors([...errors, ""]);
    if (isTeamProgress) {
      setSchedule([...schedules, { days: null, progress: 0 }]);
      return;
    }

    setSchedule([...schedules, { days: 0, progress: null }]);
  };

  const addDays = (e, index) => {
    const value = e.target.value;
    const newSchedules = [...schedules];
    const newErrors = [...errors];

    if (isNaN(+value)) {
      newErrors[index] = "Por favor, insira um número válido.";
    } else {
      newErrors[index] = "";
      const _newSchedule = isTeamProgress
        ? { days: null, progress: value }
        : { days: value, progress: null };

      newSchedules[index] = _newSchedule;
    }

    setSchedule(newSchedules);
    setErrors(newErrors);
  };

  const handleRemove = (index) => {
    const newSchedules = [...schedules];
    const newErrors = [...errors];
    newSchedules.splice(index, 1);
    newErrors.splice(index, 1);
    setSchedule(newSchedules);
    setErrors(newErrors);
  };

  const checkConditions = (schedule) =>
    isTeamProgress
      ? schedule.progress.toString() === "" || isNaN(schedule.progress)
      : schedule.days.toString() === "" || isNaN(schedule.days);

  const _handleSave = () => {
    if (isTeamProgress && schedules.some((schedule) => schedule.progress > 100)) {
      alert("O período não pode ultrapassar 100%");
      return;
    }

    if (schedules.some(checkConditions)) {
      alert("Apenas números são validos.");
      return;
    }

    updateNotificationSchedule(props.idTeam, props.idNotification, schedules);
    modal.remove();
  };

  const modal = useModal();

  return (
    <div className="ModalNotificationConfig">
      <div className={`modal-body-pct`}>
        <div>
          <div className="notification-modal-header">
            <span>Configurar {props.description?.toLowerCase()}</span>
          </div>
          <div className="notification-modal-body">
            {props.type !== "TEAM_PROGRESS" ? (
              <>
                <p className="notification-modal-description">
                  Configure abaixo o envio de {props.description?.toLowerCase()}. É necessário
                  configurar pelo menos uma.
                </p>
                <p className="notification-dialog-info">
                  <InfoIcon
                    style={{
                      color: "#6b48ff",
                      fontSize: 20,
                      marginRight: 10,
                    }}
                  />
                  <small className="notification-dialog-alert">
                    O número de dias pode ser negativo (notificação será enviada antes da data de
                    início configurada na turma), zero (notificação enviada na data exata de início
                    da turma) ou positivo (notificação será enviada após a data de início
                    configurada na turma).
                  </small>
                </p>
              </>
            ) : (
              <p className="notification-modal-description progress-description">
                Configure abaixo o envio de notificações de andamento de turma para alunos com status Não iniciado ou Em andamento. É necessário configurar pelo menos uma.
              </p>
            )}
            <div className="date-container">
              <span className="notification-dialog-date-label">
                {props.type !== "TEAM_PROGRESS" ? "Dias para envio" : "% do período da turma"}
              </span>
              {schedules.map((schedule, index) => (
                <InputGroup className="mb-3 mt-3" key={index}>
                  <Form.Control
                    type="number"
                    style={{
                      maxWidth: 85,
                      maxHeight: 35,
                    }}
                    value={isTeamProgress ? schedule.progress : schedule.days}
                    onChange={(e) => addDays(e, index)}
                    isInvalid={!!errors[index]}
                  />
                  <Form.Control.Feedback type="invalid">{errors[index]}</Form.Control.Feedback>
                  <InputGroup.Append>
                    <div className="date-trash" onClick={() => handleRemove(index)}>
                      <BiTrash color="#dc3545" size={18} />
                    </div>
                  </InputGroup.Append>
                </InputGroup>
              ))}
              <div>
                <span className="add-new-input-date" onClick={handleAdd}>
                  + Adicionar novo
                </span>
              </div>
            </div>
          </div>
          <div className="notification-modal-footer">
            <Button
              variant="secondary"
              className="notification-dialog-cancel-btn"
              onClick={() => modal.remove()}
            >
              Cancelar
            </Button>
            <Button
              variant="warning"
              className="notification-dialog-save-btn"
              onClick={_handleSave}
            >
              Salvar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModalNotificationConfig;
