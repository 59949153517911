import "./style.modules.scss";

import React, { useState, useRef, useContext } from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { usePanelBar } from "../../Hooks/usePanelBar";

import { imgBASE64 } from "../../Utils/imgBASE64";
import { CECreateContext } from "../../Contexts/CEContext";

import ColorPicker from "material-ui-color-picker";

import { TbTextPlus } from "react-icons/tb";
import { MdOutlineColorLens } from "react-icons/md";
import {
  FaTextHeight,
  FaBold,
  FaItalic,
  FaUnderline,
  FaStrikethrough,
  FaImage,
} from "react-icons/fa";
import { BsTextCenter, BsTextLeft, BsTextRight } from "react-icons/bs";
import { CiTextAlignJustify } from "react-icons/ci";
import { LuUndo, LuRedo } from "react-icons/lu";

const PanelBar = (props) => {
  const { canvas } = props;

  const [fontSizeEvent, setFontSizeEvent] = useState(false);

  const inputImageRef = useRef(null);

  const {
    propStyle,
    setPropStyle,
    bgChange,
    tempJson,
    inputActive,
    setInputActive,
    syllabusState,
    setSyllabusState,
  } = useContext(CECreateContext);

  const { addText, changeProp, replay, createImg, undo, redo } = usePanelBar(
    canvas,
    setFontSizeEvent,
    propStyle,
    setPropStyle,
    bgChange,
    tempJson,
    inputActive,
    syllabusState,
    setSyllabusState,
  );

  return (
    <div className="cePainelBar">
      <div className="toolbar">
        <div className="blockBtn">
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                Criar caixa de texto
              </Tooltip>
            }
          >
            <button onClick={addText}>
              <TbTextPlus />
            </button>
          </OverlayTrigger>
        </div>

        <div className="blockBtn">
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                Cor da fonte
              </Tooltip>
            }
          >
            <button className="colorTxt">
              <MdOutlineColorLens />
              <ColorPicker
                onChange={(e) => {
                  changeProp("fill", e);
                  // console.log("change");
                }}
                value={propStyle.fill}
              />
            </button>
          </OverlayTrigger>
        </div>

        <div className="blockBtn">
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                Tamanho da fonte
              </Tooltip>
            }
          >
            <button
              className="fontSize"
              onClick={() => {
                setFontSizeEvent(!fontSizeEvent);
              }}
            >
              <FaTextHeight />
            </button>
          </OverlayTrigger>

          {fontSizeEvent && (
            <div className="rangeFont">
              <input
                type="range"
                min="1"
                max="120"
                step="1"
                value={propStyle.fontSize}
                onChange={(e) => {
                  changeProp("fontSize", e.target.value);
                }}
                onBlur={(e) => {
                  setFontSizeEvent(false);
                }}
              />
            </div>
          )}
        </div>

        <div className="blockBtn">
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                Negrito
              </Tooltip>
            }
          >
            <button
              className=""
              onClick={(e) => {
                changeProp("fontWeight", "bold");
              }}
            >
              <FaBold />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                Itálico
              </Tooltip>
            }
          >
            <button
              className="fontSize"
              onClick={(e) => {
                changeProp("fontStyle", "italic");
              }}
            >
              <FaItalic />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                Sublinhado
              </Tooltip>
            }
          >
            <button
              className="fontSize"
              onClick={(e) => {
                changeProp("underline", !propStyle.underline);
              }}
            >
              <FaUnderline />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                Risco
              </Tooltip>
            }
          >
            <button
              className="fontSize"
              onClick={(e) => {
                changeProp("linethrough", !propStyle.linethrough);
              }}
            >
              <FaStrikethrough />
            </button>
          </OverlayTrigger>
        </div>

        <div className="blockBtn">
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                Alinhar ao centro
              </Tooltip>
            }
          >
            <button
              className="fontSize"
              onClick={(e) => {
                changeProp("textAlign", "center");
              }}
            >
              <BsTextCenter />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                Alinhar à esquerda
              </Tooltip>
            }
          >
            <button
              className="fontSize"
              onClick={(e) => {
                changeProp("textAlign", "left");
              }}
            >
              <BsTextLeft />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                Alinhar à direita
              </Tooltip>
            }
          >
            <button
              className="fontSize"
              onClick={(e) => {
                changeProp("textAlign", "right");
              }}
            >
              <BsTextRight />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                Justificar
              </Tooltip>
            }
          >
            <button
              className="fontSize"
              onClick={(e) => {
                changeProp("textAlign", "justify");
              }}
            >
              <CiTextAlignJustify />
            </button>
          </OverlayTrigger>
        </div>

        <div className="blockBtn blockBtnInput">
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                Imagem
              </Tooltip>
            }
          >
            <button
              onClick={() => {
                inputImageRef.current.click();
              }}
            >
              <FaImage />
            </button>
          </OverlayTrigger>

          <input
            ref={inputImageRef}
            type="file"
            id="imgObj"
            name="imgObj"
            accept="image/png, image/jpeg"
            onChange={(e) => createImg(e)}
          />
        </div>

        <div className="blockBtn blockBtnInput">
          {
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                  Desfazer
                </Tooltip>
              }
            >
              <button
                onClick={() => {
                  replay("undo");
                }}
                className={undo.length <= 1 ? "inativeBtn" : ""}
              >
                <LuUndo />
              </button>
            </OverlayTrigger>
          }
          {
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                  Refazer
                </Tooltip>
              }
            >
              <button
                onClick={() => {
                  replay("redo");
                }}
                className={redo.length === 0 ? "inativeBtn" : ""}
              >
                <LuRedo />
              </button>
            </OverlayTrigger>
          }
        </div>
      </div>
    </div>
  );
};

export default PanelBar;
