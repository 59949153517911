import React, { useRef, useEffect } from "react";
import { Form } from "react-bootstrap";
import "./styles.scss";

const AutoResizeTextarea = ({
  onChange,
  placeholder,
  maxLength,
  className = "",
  defaultValue = '',
  ...extraProps
}) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    autoResizeTextarea(textareaRef.current);
  }, [defaultValue]);

  const autoResizeTextarea = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleChange = (e) => {
    onChange(e);
    autoResizeTextarea(e.currentTarget);
  };

  return (
    <>
      <Form.Control
        as="textarea"
        rows={1} 
        onChange={handleChange}
        placeholder={placeholder}
        maxLength={maxLength}
        className={className}
        ref={textareaRef} 
        defaultValue={defaultValue}
        {...extraProps}
      /> 
    </>
  );
};

export default AutoResizeTextarea;
