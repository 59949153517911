import React, { FC } from "react";

import "./styles.scss"
import { ContentData, Notification, Options } from "../../nodeParamenters.model";
import Switch from "react-bootstrap/esm/Switch";
import Label from "components/PeopleCraft/Label";
import { postNotificationsConfigurationsLearning } from "services/NexiDigitalAPI";
type Props =  {
  contentData: ContentData;
  handleChange: (data: { [key: string]:  Notification[]},) => void;

}


const NotificationPage: FC<Props> = ({handleChange, contentData}) => {

  const _handleChange= async (event, notification: Notification) => {
     const value = event.target.checked
    const _notifications = contentData.notifications.map( elem =>
      {
        if(elem.type === notification.type){
          elem.active = value
        }
        return elem
      })
    handleChange({notifications: _notifications})
    try{
     await postNotificationsConfigurationsLearning([{...notification, active :value}], contentData.originalNodeCode)

    }catch(e){
      console.log(e)
    }

  }

  const getValueActiveStatus = (status: Options, active: number) => {
    if(status === Options.MANDATORY ){
      return true
    }
    if(status === Options.DEACTIVATED){
      return false
    }

    return !!active
  }
  return (
    <div className="notification-page">
      <span className="info">Enviar notificações sobre as atividades abaixo quando</span>
      {contentData.notifications.map(elem =>
         <div  className={`form-data flex-row ${elem.status !== Options.OPTIONAL ? 'switch-disabled' : ''}`}>
         <Switch
             color="primary"
             disabled={elem.status !== Options.OPTIONAL}
             id={elem.type}
             checked={getValueActiveStatus(elem.status, elem.active) }
             onChange={(e: any) => {
              _handleChange(e, elem)
             }}
           />
           <Label className={`cursor-pointer  ${elem.status !== Options.OPTIONAL && 'disabled-label'}`} name={elem.description} htmlFor={elem.type} />
         </div>

      )}


    </div>
  )
}
export default NotificationPage;
