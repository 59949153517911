import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker, ConfigProvider } from "antd";
import "./styles.scss";
import ptBR from "antd/es/locale/pt_BR";
import { Line } from "react-chartjs-2";
import { getDashboardsLearningData } from "../../../../services/NexiDigitalAPI";
import { TableContainer } from "@material-ui/core";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Loading from "../../../../components/Loading";

export default function LearningData({ idCompanySelected }) {
  const [apiInfo, setApiInfo] = useState({
    infoLabel: [],
  });
  const [valuePeriod, setValuePeriod] = useState();
  const [hideData, setHideData] = useState(true);
  const [loadingChart, setLoadingChart] = useState(true);
  const [values, setValues] = useState({
    startDate: moment(new Date()).subtract(3, "months").startOf("month").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  });

  const { RangePicker } = DatePicker;
  const styleData = hideData ? { display: "none" } : { display: "inline-block", marginLeft: 15 };

  const convertMinToDuration = (min) => {
    const duration = moment.duration(min, "minutes");
    // const years = duration.years();
    // const months = duration.months();
    // const weeks = duration.weeks();
    // const days = duration.days();
    const hours = duration.hours();
    // const minutes = duration.minutes();
    // const seconds = duration.seconds();
    return `${hours}h`;
  };
  const convertSecToDuration = (value) => {
    const duration = moment.duration(value, "seconds");
    const minutes = Math.floor(duration.minutes());
    const hours = Math.floor(duration.hours());

    return `${hours}h${minutes}m`;
  };

  const getLineChartData = async () => {
    try {
      setLoadingChart(true);
      const containerApi = await getDashboardsLearningData(
        values.startDate,
        values.endDate,
        idCompanySelected,
      );
      const infoLabel = containerApi.graphEnrollmentsConclusions?.map((item) => item.Month);
      const conclusions = containerApi.graphEnrollmentsConclusions?.map((item) => item.Conclusions);
      const enrollments = containerApi.graphEnrollmentsConclusions?.map((item) => item.Enrollments);
      setApiInfo({
        ...containerApi,
        infoLabel,
        lineData: {
          conclusions,
          enrollments,
        },
      });
    } finally {
      setLoadingChart(false);
    }
  };

  useEffect(() => {
    if (idCompanySelected === -1) {
      return;
    }
    getLineChartData();
  }, [values, idCompanySelected]);

  function handleFilter(val = false) {
    let _value = val || [values.startDate, values.endDate];
    if (_value && _value[0] && _value[1]) {
      setValues({
        startDate: moment(val[0]).format("YYYY-MM-DD"),
        endDate: moment(val[1]).format("YYYY-MM-DD"),
      });
    }
  }

  const disabledDate = (current) => {
    return current && current > moment();
  };

  const changePeriod = (value) => {
    setValuePeriod(value);
    const eventValue = value;
    switch (eventValue) {
      case "0":
        setHideData(true);
        setValues({
          startDate: moment(new Date()).subtract(3, "months").startOf("month").format("YYYY-MM-DD"),
          endDate: moment(new Date()).format("YYYY-MM-DD"),
        });
        break;
      case "1":
        setHideData(true);
        setValues({
          startDate: moment(new Date()).subtract(6, "months").startOf("month").format("YYYY-MM-DD"),
          endDate: moment(new Date()).format("YYYY-MM-DD"),
        });
        break;
      case "2":
        setHideData(true);
        setValues({
          startDate: moment(new Date())
            .subtract(12, "months")
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: moment(new Date()).format("YYYY-MM-DD"),
        });
        break;
      case "3":
        setHideData(false);
        break;
      default:
        break;
    }
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
    elements: {
      point: {
        hitRadius: 10,
      },
    },
  };

  const info = {
    labels: apiInfo.infoLabel,
    datasets: [
      {
        label: "Inscrições",
        data: apiInfo.lineData?.enrollments,
        borderColor: "rgb(65,75,178)",
        backgroundColor: "rgba(65,75,178, 0.5)",
      },
      {
        label: "Conclusões",
        data: apiInfo.lineData?.conclusions,
        borderColor: "rgb(45,155,240)",
        backgroundColor: "rgba(45,155,240, 0.5)",
      },
    ],
  };

  const hasData = Boolean(apiInfo.graphEnrollmentsConclusions?.length);
  const noData = (
    <div
      className={"nenhumDado"}
      style={{ display: "flex", justifyContent: "center", paddingTop: 75 }}
    >
      Nenhum dado disponível
    </div>
  );

  const LineWrapper = () => {
    return (
      <div className={"lineWrapper"}>
        {!hasData ? noData : <Line data={info} options={options} />}
      </div>
    );
  };

  const ReportItem = ({ label, data }) => {
    return (
      <div className={"report_item"}>
        <div className={"report_item_data"}>{data || 0}</div>
        <div className={"report_item_text"}>{label}</div>
      </div>
    );
  };

  return (
    <div className="learningDataBase">
      <div className="report_container">
        <div className="textLeft">Consumo de aprendizagem por período</div>
        <div className={"container_filter_itens"}>
          <div className="textLeft" style={{ fontSize: "15px", marginRight: 15 }}>
            Período
          </div>
          <div className={"selectDiv"}>
            <select
              value={valuePeriod}
              className={"selectPediodSelection"}
              onChange={(event) => changePeriod(event.target.value)}
            >
              <option value="0">Últimos 3 meses</option>
              <option value="1">Últimos 6 meses</option>
              <option value="2">Últimos 12 meses</option>
              <option value="3">Qualquer período</option>
            </select>
          </div>

          <div className={"dateDiv"} style={styleData}>
            <ConfigProvider locale={ptBR}>
              <RangePicker
                value={[moment(values.startDate), moment(values.endDate)]}
                disabledDate={disabledDate}
                onCalendarChange={(dates, dateStrings) => {
                  if (dates && dates.length === 2 && moment(dates[1]) > moment()) {
                    dates[1] = moment();
                  }
                  if (dates) {
                    handleFilter(dates);
                  }
                }}
                defaultValue={[values.startDate, values.endDate]}
                format={["DD/MM/YYYY", "DD/MM/YY", "MM/YY"]}
                size={"large"}
              />
            </ConfigProvider>
          </div>
        </div>
        {loadingChart ? (
          <div>
            {" "}
            <Loading>Carregando dados...</Loading>{" "}
          </div>
        ) : (
          <div className="selectPediodDiv">
            <div className="report_base">
              <div className="reportLeft" style={{ marginBottom: 10 }}>
                <div className="reportTitle">Inscrições x conclusões</div>
                <LineWrapper />
              </div>
              <div className="reportLeft">
                <div className="reportTitle">Top 10 treinamentos</div>
                {apiInfo?.top10Trainings?.length ? (
                  <TableContainer>
                    <Table>
                      <Thead>
                        <Tr>
                          <Th align="center">Treinamentos</Th>
                          <Th align="center">Qtd de inscrições</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {apiInfo?.top10Trainings?.map((row, index) => (
                          <Tr key={index}>
                            <Td align="center" style={{ minWidth: "35%" }}>
                              {row.title}
                            </Td>
                            <Td align="center" style={{ minWidth: "20%" }}>
                              {row.enrollments}
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                ) : (
                  noData
                )}
              </div>
            </div>
            <div className="report_resume">
              <ReportItem label="Inscrições" data={apiInfo.enrollments} />
              <ReportItem label="Conclusões" data={apiInfo.conclusions} />
              <ReportItem label="Progresso médio" data={`${apiInfo.averageProgress}%`} />
              <ReportItem
                label="Carga horária média"
                data={convertSecToDuration(apiInfo.averageWorkload)}
              />
              <ReportItem
                label="Carga horária total"
                data={convertSecToDuration(apiInfo.workload)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
