import "./style.scss";
import React, { useEffect, useRef, useState } from "react";
import {
  IoIosArrowDroprightCircle,
  IoIosArrowDropleftCircle,
} from "react-icons/io";
import { BsArrowLeftSquareFill, BsArrowRightSquareFill } from "react-icons/bs";
import CustomTooltip from "components/CustomTooltip";

type Props = {
  reverseExpandIcon?: boolean;
  minPanelSize: number;
};

const ControlledBlockElement = ({ reverseExpandIcon = false, minPanelSize }: Props) => {
  const [expandIcon, setExpandIcon] = useState("close");
  const btnExpand = useRef(null);

  useEffect(() => {
    const currentBtnExpand: any = btnExpand.current;

    if (currentBtnExpand) {
      const panelElement = currentBtnExpand.parentElement.parentElement;

      // panelElement["setExpandIcon"] = setExpandIcon;
      panelElement["expand"] = () => {
        if (panelElement.getAttribute("btnState") != "open") {
          expandControl(panelElement);
        }
      };
      panelElement["withdraw"] = () => {
        if (panelElement.getAttribute("btnState") != "close") {
          expandControl(panelElement);
        }
      };

      new ResizeObserver(() => {
        if(!reverseExpandIcon){
          currentBtnExpand.style.left = `${panelElement.offsetWidth + 17}px`;
        }else{
          currentBtnExpand.style.right = `${panelElement.offsetWidth + 17}px`;
        }

        if(panelElement.offsetWidth <= (minPanelSize + 3)){
            panelElement.setAttribute("btnState", "close");
            setExpandIcon('open');
            
        }else{
            panelElement.setAttribute("btnState", "open");
            setExpandIcon('close');
        }

        const elementsControlObject = getElementsControlObject(panelElement);
        
        if(elementsControlObject.totalWidthFlexElements < 1){

          panelElement.style.flex = `${elementsControlObject.difFlexExpand + Number(panelElement.style.flex.split(' ')[0])} 1 0%`;

        }
        

      }).observe(panelElement);
      

    }
  }, [btnExpand]);

  const expandControl = (panelElement) => {
    let openedNum = 0;
    let totalNum = 0;

    panelElement.parentElement.childNodes.forEach(function (element: any) {
      if (element === panelElement) {
        totalNum++;

        if (
          element.getAttribute("btnState") === "open" ||
          element.getAttribute("btnState") === null
        ) {
          element.setAttribute("btnState", "close");
          // setExpandIcon("open");
        } else {
          element.setAttribute("btnState", "open");
          // setExpandIcon("close");
          openedNum++;
        }
      } else if (element.classList.contains("reflex-element")) {
        totalNum++;

        if (
          element.getAttribute("btnState") === "open" ||
          element.getAttribute("btnState") === null
        ) {
          element.setAttribute("btnState", "open");
          openedNum++;
        }
      }
    });

    // panelElement.parentElement.childNodes.forEach(function (element: any) {
    //   if (element.classList.contains("reflex-element")) {
    //     if (element.getAttribute("btnState") === "close") {
    //       element.style.flex = `${minPanelSize} 1 0%`;
    //     } else if (element.getAttribute("btnState") === "open") { 
    //       // console.log(element.classList.contains('authoringSection'));
    //       // element.classList.contains('authoringSection')

    //       element.style.flex = `${1 / openedNum} 1 0%`;
    //     }
    //   }
    // });

    panelElement.parentElement.childNodes.forEach(function (element: any) {

      const currentAuthoringSectionFlexSize = element.parentElement.getElementsByClassName('authoringSection')[0].style.flex.split(" ")[0];
      // console.log(currentAuthoringSectionFlexSize);

      if (element.classList.contains("reflex-element")) {
        if (element.getAttribute("btnState") === "close") {
          element.style.flex = `${minPanelSize} 1 0%`;
        } else if (element.getAttribute("btnState") === "open") {
          // console.log(element.classList.contains('authoringSection'));
          // element.classList.contains('authoringSection')

          if(!element.classList.contains('authoringSection')){
            element.style.flex = `${ currentAuthoringSectionFlexSize <= 0.15 ? (1 - (currentAuthoringSectionFlexSize)) / (openedNum - 1) : (1 - (0.7)) / (openedNum - 1)} 1 0%`;
          }

          if(element.classList.contains('authoringSection') && (openedNum - 1) === 0){
            element.style.flex = `${1} 1 0%`;
          }

          // element.style.flex = `${1 / openedNum} 1 0%`;
        }
      }
    });

  };

  const getElementsControlObject = (panelElement) => {

    let elementsControlObject = {
      allElements:[],
      openElements:[],
      closeElements:[],
      centralElement:null,
      totalWidthFlexElements:0,
      totalWidthFlexCentralElement:0,
      difFlexExpand:0,
    }

    panelElement.parentElement.childNodes.forEach(function (element: any) {
      if (element.classList.contains("reflex-element")) {

        elementsControlObject.allElements.push(element);
        elementsControlObject.totalWidthFlexElements += Number(element.style.flex.split(' ')[0]);

        if (element.getAttribute("btnState") === "open" || element.getAttribute("btnState") === null) {
          elementsControlObject.openElements.push(element);
        }else if(element.getAttribute("btnState") === "close"){
          elementsControlObject.closeElements.push(element);
        }

        if(element.classList.contains("central-reflex-element")){
          elementsControlObject.centralElement = element;
          elementsControlObject.totalWidthFlexCentralElement = Number(element.style.flex.split(' ')[0]);
        }

      }
      
    });

    elementsControlObject.difFlexExpand = 1 - elementsControlObject.totalWidthFlexElements;

    return elementsControlObject;

  }

  return (
    <div className="controlledBlockElement">
      {/* <CustomTooltip
        title={expandIcon === "open" ? "Expandir" : "Reduzir"}
        placement={reverseExpandIcon ? "left" : "right"}
        arrow
      > */}
        <div
          ref={btnExpand}
          className={`btnExpand ${reverseExpandIcon ? "reverse" : ""}`}
          onClick={(e) => {
            const panelElement = e.currentTarget.parentElement.parentElement;
            expandControl(panelElement);
          }}
        >
          {expandIcon === "open" ? (
            !reverseExpandIcon ? (
              <IoIosArrowDroprightCircle />
            ) : (
              <IoIosArrowDropleftCircle />
            )
          ) : !reverseExpandIcon ? (
            <IoIosArrowDropleftCircle />
          ) : (
            <IoIosArrowDroprightCircle />
          )}
        </div>
      {/* </CustomTooltip> */}
    </div>
  );
};

export default ControlledBlockElement;
