import React, { useState, useEffect } from "react";
import LojaWrapper from "../Components/LojaWrapper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Modal from "./Components/Modal";
import {
  getAdminOfferList,
  postCreateOffer,
  postEditOffer,
} from "../../../services/NexiDigitalAPI";
import Table from "./Components/Table";

const CadastroOferta = () => {
  const initInfo = {
    idProduct: "",
    title: "",
    imageURL: "",
    startDate: "",
    endDate: "",
    price: "",
    idApp: "",
  };

  const [info, setInfo] = useState(initInfo);
  const [show, setShow] = useState(false);
  const [OfferList, setOfferList] = useState([]);

  const showModal = () => setShow(true);
  const hideModal = () => {
    setInfo(initInfo);
    setShow(false);
  };

  const editOffer = (itemInfo) => {
    setInfo(itemInfo);
    setShow(true);
  };

  const createOffer = async (info) => {
    const submit = await postCreateOffer(info);
    if (submit) {
      offers();
      hideModal();
    }
  };

  const submitEditOffer = async (info, id) => {
    const submit = await postEditOffer(info, id);
    if (submit) {
      offers();
      hideModal();
    }
  };

  const colorStyle = { backgroundColor: localStorage.getItem("secondaryColor") };

  const offers = async () => {
    const offer = await getAdminOfferList();
    setOfferList(offer);
  };

  useEffect(() => {
    offers();
  }, []);

  return (
    <LojaWrapper>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
        <button className="blast-button btn btn-primary" onClick={showModal} style={colorStyle}>
          <AddCircleIcon style={{ height: "26px", width: "26px" }} /> Cadastrar ofertas
        </button>
      </div>
      <Table List={OfferList} editOffer={editOffer} />

      <Modal
        show={show}
        hideModal={hideModal}
        info={info}
        submitEdit={submitEditOffer}
        submitCreate={createOffer}
      />
    </LojaWrapper>
  );
};

export default CadastroOferta;
