import "./styles.scss";
import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
// import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
// import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import _ from "underscore";
import { Tooltip } from "@material-ui/core";
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";
import { Button, Modal, Form } from "react-bootstrap";
import { getMenuRoutes } from "../../../../../utils/Utils";
import {
  createGroupSecondary,
  getGroupsSecondary,
  removeGroupSecondary
} from "../../../../../services/NexiDigitalAPISecondary";
import {
  updateGroup,
  getGroupUsers,
  linkUserToGroup,
  unlinkUserFromGroup,
  getUsers,
} from "../../../../../services/NexiDigitalAPI";
import DataTable from "react-data-table-component";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { BsFilter } from "react-icons/bs";

import Carousel from "react-bootstrap/Carousel";
import { useHistory } from "react-router-dom";
import { PACreateContext } from "../../Contexts/PAContext";
import ModalDialog from "containers/PeopleCraft/Modals/ModalDialog";
import GroupsManual from "../GroupsManual";

const GroupsEditor = (props) => {

  const { groupId, groupTitle, editType } = useParams();
  // const [searchString, setSearchString] = useState("");
  const searchString = useRef("");
  const inputSearch = useRef(null);
  const type = useRef("");
  const [showAddUsersModal, setShowAddUsersModal] = useState(editType === "manual");

  const history = useHistory();
  const isMountedRef = useRef(null);
  const { setPreloadInit, openConfirmModal } = useContext(PACreateContext);
  const [lista, setLista] = useState([]);
  const [groupName, setGroupName] = useState(groupTitle);
  const groupNameInputRef = useRef(null);
  const groupCurrentName = useRef(groupTitle);

  /*List Pagination */
  const [pageLimit, setPageLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  
  /* [Permission Module] - Permissions states */
  const [permissionAddUserIntoGroup, setPermissionAddUserIntoGroup] = useState(false);

  useEffect(() => {
    /* [Permission Module] - Function calls */
    getMenuOption();

    isMountedRef.current = true;
    getAvailableData(0, pageLimit);
    return () => (isMountedRef.current = false);
  }, []);

  /* [Permission Module] - Permission attrs in page by login data information */
  function getPermissionsPage(resources) {
    try {
      if (
        Boolean(
          resources[0].filter(
            (menu) => menu.name === "Inserir usuário no grupo"
          ).length
        )
      )
        setPermissionAddUserIntoGroup(true);
    } catch (error) {
      console.log("Falha ao buscar permissões de Grupos");
    }
  }

  /* [Permission Module] - Menu Options */
  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();

    const objCurrentMenu = menuOptionList.filter(
      (menu) => menu.text === "Cadastro de Usuários"
    );

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);

      getPermissionsPage(resources);
    }
  }

  /* Metodos para remover um usuário do grupos */
  const handleDel = (user) => {
    openConfirmModal({
                      text:`Você tem certeza que deseja remover o usuário ${user.name} do grupo ${groupName}?`,
                      variantBtn:"danger", 
                      textBtn:"Remover usuário", 
                      fnc:() => {
                        setPreloadInit(true);
                        
                        unlinkUserFromGroup(groupId, user.idUser).then((res) => {
                          if (res.data.success) {
                            setLista(lista.filter((g) => g.idUser !== user.idUser));
                          }

                          setPreloadInit(false);
                        });
                      }
                    });
    
  };

  /* Configurações da tabela */
  const paginationComponentOptions = {
    rowsPerPageText: "Conteúdos por página",
    rangeSeparatorText: "de",
  };

  const onChangePage = (page) => {
    const newOffset = (page - 1) * pageLimit;
    getAvailableData(newOffset);
  };

  const onChangeRowsPerPage = (limit, page) => {
    setPageLimit(limit);
    const newOffset = (page - 1) * limit;
    getAvailableData(newOffset, limit);
  };

  /* Recuperando os grupos disponíveis. */
  const getAvailableData = async (newOffset, limit) => {
    const haveNewOffset = newOffset || newOffset === 0;
    if (haveNewOffset) {
      setOffset(newOffset);
    }

    // TODO: Estes parâmetros devem ser passados para o enpoint para o funcionamento dos filtros.
    const groupUsersParameters = {
      searchString: searchString.current,
      offset: haveNewOffset ? newOffset : offset,
      limit: limit ? limit : pageLimit,
      inclusionType: type.current
    };

    setPreloadInit(true);

    const availableData = await getGroupUsers(groupId, groupUsersParameters).catch((err) => {
      console.log("error", err);
    });

    setPreloadInit(false);

    

    if (availableData) {
      setLista(_.sortBy(availableData.data.users, "name"));
      setTotalRecords(availableData.data.totalRecords);
    }
  };


  /* Colunas da tabela dos grupos já criados */
  const columns = [
    {
      name: "Código",
      selector: "idUser",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (user) => (
        // <HighlightText value={removeHtmlTags(question.statement)} highlight={searchString} />
        <div>
          <p>{user.idUser}</p>
        </div>
      ),
    },
    {
      name: "Nome",
      sortable: true,
      selector: "name",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (user) => (
        // <HighlightText value={removeHtmlTags(question.statement)} highlight={searchString} />
        <div>
          <p>{user.name}</p>
        </div>
      ),
    },

    {
      name: "E-mail",
      sortable: true,
      selector: "email",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (user) => (
        // <HighlightText value={removeHtmlTags(question.statement)} highlight={searchString} />
        <div>
          <p>{user.email}</p>
        </div>
      ),
    },

    {
      name: "Origem",
      sortable: true,
      selector: "inclusionType",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (user) => (
        // <HighlightText value={removeHtmlTags(question.statement)} highlight={searchString} />
        <div>
          <p>{user.inclusionType === "AUTOMATIC" ? "AUTOMÁTICA" : user.inclusionType}</p>
        </div>
      ),
    },

    {
      name: "Ação",
      sortable: false,
      selector: "actions",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (user) => (
        // <HighlightText value={removeHtmlTags(question.statement)} highlight={searchString} />
        // <div>
        //   <p>{group.title}</p>
        // </div>
        <div className="groupActions" key={user.idUser}>
          <Tooltip title="Remover usuário do grupo." placement="top">
            <span
              className="btn btn-sm float-right"
              onClick={() => handleDel(user)}
            >
              <RiDeleteBin5Line />
            </span>
          </Tooltip>
          
        </div>
      ),
    },
  ];

  return (
    <div className="groupsEditorContainerAll">
    
      <div className="PA-header-title-wrapper">
        <div className="PA-header-title">
          <button
            className="btnGoBack"
            onClick={() => {
              // props.history.goBack();
              history.push(
                `/configuracao/grupos/`
              );
            }}
          >
            <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
          </button>
          Cadastro de usuário / Gerenciar público-alvo / <b>Editar público-alvo</b>
        </div>
      </div>

      <div className="usersInsertModal">
          <Modal
            show={showAddUsersModal}
            onHide={() => {
              setShowAddUsersModal(false);
              getAvailableData(0, pageLimit);
            }}
            animation={false}
            className="groupEditorModal"
          >
              <Modal.Header closeButton>
                <h5>Inserir usuários manualmente</h5>
              </Modal.Header>
              <Modal.Body>
                  <GroupsManual {...props} />
              </Modal.Body>

              <Modal.Footer></Modal.Footer>
          </Modal>
      </div>

      <div className="groupListHeader">
        <div className="infos">
          <h6>Nome do público-alvo:</h6>
          <div className="nameEditor">
            <input
                  className="form-control input-default"
                  ref={groupNameInputRef}
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  onBlur={(e) => {
                      {
                        if(groupCurrentName.current != groupName){
                            openConfirmModal({
                              text:`Você tem certeza que deseja alterar o nome do público-alvo de ${groupCurrentName.current} para ${groupName}?`,
                              variantBtn:"danger", 
                              textBtn:"Alterar nome do público-alvo", 
                              fnc:() => {
                                  updateGroup(groupId, groupName).then((res) => {
                                      if (res.data.success) {
                                        groupCurrentName.current = groupName;
                                        history.push(
                                          `/configuracao/grupos/editar/${groupId}/${groupName}`
                                        );
                                        
                                      }else{
                                        setGroupName(groupCurrentName.current);
                                      }
                                  });
                              },
                              fncDeny:() => {
                                setGroupName(groupCurrentName.current);
                              }
                            });
                        }

                        
                      }
                  }}
                />
                <FiEdit />
          </div>
        </div>

        <h6>Usuários inseridos no público-alvo:</h6>

        <div className="filters">
            
            <div className="filtersOptions">
                <div className="searchInfo">
                  <AiOutlineSearch />
                  <Form.Control
                    ref={inputSearch}
                    id="searchInput"
                    onKeyDown={(input) => {
                      if (input.key === "Enter") {
                        getAvailableData(0, pageLimit);
                      }
                    }}
                    onChange={(e) => {searchString.current = e.target.value;}}
                    placeholder={`Pesquisar...`}
                  />
                  <button 
                      onClick={() => {
                        inputSearch.current.value = "";
                        searchString.current = "";
                        getAvailableData(0, pageLimit);
                      } 
                      }
                    >
                    <AiOutlineClose />
                  </button>
                </div>

                <div className="typeInsert">
                        <Form.Control
                          className=""
                          as="select"
                          value={type.current}
                          onChange={(e) => {
                            type.current = e.target.value;
                            getAvailableData(0, pageLimit);
                          }}
                        >
                          <option value={""}>{"Todas as origens"}</option>
                          <option value={"MANUAL"}>{"Manual"}</option>
                          <option value={"AUTOMATIC"}>{"Automática"}</option>
                        </Form.Control>
                      </div>
                      <Button 
                        variant="primary" 
                          onClick={() => getAvailableData(0, pageLimit)}
                        >
                        <BsFilter />
                        Filtrar
                      </Button>
            </div>
            <div className="buttonsUsersInsertOptions">
              <button
                className="button-peop button-purple"
                onClick={() => setShowAddUsersModal(true)}
              >
                Inserir manualmente
              </button>

              <button
                className="button-peop button-yellow"
                onClick={() =>{
                  history.push(`/configuracao/grupos/regras/${groupId}/${groupName}`);
                }}
              >
                Editar regras
              </button>
            </div>
        </div>

       
      </div>

      <div className="groupListTableContainer">
        <DataTable
          columns={columns}
          data={lista}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationTotalRows={totalRecords}
          paginationComponentOptions={paginationComponentOptions}
          paginationServer
          noHeader
          fixedHeader
          pagination
        />
      </div>
    </div>
  );
};

export default withRouter(GroupsEditor);
