import React, { useState, useContext, useRef, useCallback } from "react";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import "./index.scss";
import BlockOptions from "./BlockOptions";
import { BlockList } from "./BlockList";
import {
  createLearningBlock,
  editBlockElementOrder,
  editLearningBlockOrder,
  getLearningNode,
  moveBlockElementOrder,
  removeLearningBlock,
  updateLearningBlock,
} from "../../../services/NexiDigitalAPI";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../../components/Loading";
import { transformText, getMenuRoutes} from "../../../utils/Utils";
import NodeManagerProvider from "./NodeManagerProvider";
import { LMCreateContext } from "../LearningManagementNew/Contexts/LMContext";
import PlaceholderBlocks from "./components/PlaceholderBlocks";


const NodeManagerV2 = ({ nodeManagerReflexWidth }) => {
  const [errorNode, setErrorNode] = useState(false);
  const [loadingAddBlock, setLoadingAddBlock] = useState(false);
  const [loadingRemoveBlock, setLoadingRemoveBlock] = useState(false);
  const [loadingMoveBlock, setLoadingMoveBlock] = useState(false);
  const [loadingUpdateBlock, setLoadingUpdateBlock] = useState(false);
  const [permissionLearningPage,setPermissionLearningPage] = useState(false);
  const nodeManagerElement = useRef(null);

  const history = useHistory();

  const MOCK_BREADCRUMB_DATA = [
    {
      title: "Informações básicas",
    },
    {
      title: "Gerenciar conteúdo",
    },
  ];
  const { nodeCurrent, nodeData, setNodeData, nodeList, currentEditPayload, setLoadingNodeList, loadingNodeList } = useContext(LMCreateContext);

  /* [Permission Module] - Menu Options */
  const getMenuOption = () => {
    const menuOptionList = getMenuRoutes();
    const objCurrentMenu = menuOptionList.filter((menu) => menu.text === "Gestão de Aprendizagem");

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu) => menu.resource);

      getPermissionsPage(resources);
    }
  };

  /* [Permission Module] - Permission attrs in page by login data information */
  const getPermissionsPage = (resources) => {
    try {
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Gerenciar páginas de aprendizagem").length,
        )
      )
        setPermissionLearningPage(true);
    } catch (error) {
      console.log("Error setting permission: ", error);
    }
  };


  useEffect(() => {
    getMenuOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const _getLearningNode = useCallback(() => {
    setErrorNode(false);
    setLoadingNodeList(true);
    if(nodeCurrent?.nodeCode){
      getLearningNode(nodeCurrent?.nodeCode)
      .then((res) => {
       const nodeDataResult = res.data.result;
        setNodeData(nodeDataResult);
      })
      .catch((err) => {
        console.log("Error getting learning node", err.request);
        setErrorNode(true);
      })
      .finally(() => {
        setLoadingNodeList(false);
      });
    }

  }, [nodeCurrent?.nodeCode]);

  const handleAddBlock = (addNodeData) => {
    setLoadingAddBlock(true);


    nodeManagerElement.current.parentElement.scrollTo({ left: 0, top: nodeManagerElement.current.parentElement.scrollHeight + 500, behavior: "smooth" });


    const blockInsertData = {
      node: {
        id: nodeData?.idNode,
      },
      blockType: addNodeData.blockType,
    };
    createLearningBlock(blockInsertData)
      .then((res) => {
        const idBlock = res.data.result;
        const newBlock = {
          blockType: addNodeData.blockType,
          idBlock,
          idNode: nodeData?.idNode,
        };
        let newNodeData = { ...nodeData };
        if (newNodeData.blocks === undefined) {
          newNodeData.blocks = [];
        }
        newNodeData.blocks.push(newBlock);
        setNodeData(newNodeData);
        nodeManagerElement.current.parentElement.scrollTo({ left: 0, top: nodeManagerElement.current.parentElement.scrollHeight + 500, behavior: "smooth" });
      })
      .catch((err) => {
        console.log("error inserting block", err);
      })
      .finally(() => {
        setLoadingAddBlock(false);
      });
  };

  const handleRemoveBlock = (idBlock) => {
    setLoadingRemoveBlock(idBlock);
    removeLearningBlock(idBlock)
      .then(() => {
        const filteredNodeList = nodeData.blocks.filter((obj) => obj.idBlock !== idBlock);

        let newNodeData = { ...nodeData };
        newNodeData.blocks = filteredNodeList;
        setNodeData(newNodeData);
      })
      .catch((err) => {
        console.log("error deleting block", err);
      })
      .finally(() => {
        setLoadingRemoveBlock(false);
      });
  };

  const handleChangeOrder = (orderData) => {
    setLoadingMoveBlock(true);
    const { idBlock, orderNum } = orderData;
    moveObjectPosition(idBlock, orderNum - 1);

    editLearningBlockOrder(idBlock, orderNum)
      .then(() => {
        console.log("Success moving block");
      })
      .catch((err) => {
        console.log("error moving block", err);
      })
      .finally(() => {
        setLoadingMoveBlock(false);
      });
  };

  const handleElementChangeOrder = (orderData) => {
    const { idBlockElement, orderNum } = orderData;
    moveObjectPosition(idBlockElement, orderNum - 1);
    editBlockElementOrder(idBlockElement, orderNum)
      .then(() => {
        console.log("Success moving element");
      })
      .catch((err) => {
        console.log("error moving element", err);
      });
  };

  const handleElementMove = (moveData) => {
    moveBlockElementOrder(moveData)
      .then(() => {
        console.log("Success moving element trhough block");
      })
      .catch((err) => {
        console.log("Error moving element trhough block", err.request);
      });
  };

  const handleUpdateBlock = (node, event, field) => {
    const value = event.target?.value;
    const newBlockList = nodeData?.blocks.map((obj) => {
      if (obj.idBlock === node.idBlock) {
        return { ...obj, [field]: value };
      }
      return obj;
    });
    setNodeData((prevNodeData) => ({
      ...prevNodeData,
      blocks: newBlockList,
    }));
  };



  const handleSaveUpdateBlock = (node, event, field) => {
    setLoadingUpdateBlock(true);
    const value = event.target?.value;
    const updateData = {
      id: node.idBlock,
      node: {
        id: nodeData.idNode,
      },
      blockType: node.blockType,
      [field]: value,
    };
    updateLearningBlock(node.idBlock, updateData)
      .then(() => {
        console.log("Success updating block");
      })
      .catch((err) => {
        console.log("error deleting block", err);
      })
      .finally(() => {
        setLoadingUpdateBlock(false);
      });
  };

  const moveObjectPosition = (idBlock, destinationIndex) => {
    // let newNodeList = [...nodeList]
    let newNodeList = [...nodeData.blocks];
    const objectToMove = newNodeList.find((obj) => obj.idBlock === idBlock);
    const index = newNodeList.indexOf(objectToMove);

    if (index > -1) {
      newNodeList.splice(index, 1);
      newNodeList.splice(destinationIndex, 0, objectToMove);
    }
    let newNodeData = { ...nodeData };
    newNodeData.blocks = newNodeList;
    setNodeData(newNodeData);
    // setNodeList(newNodeList)
  };

  const handleConcluir = () => {
    const menuOptionList = JSON.parse(localStorage.getItem("menu-option"));
    const learningManagementMenu = menuOptionList.find((item) => item.idSectionType === 62);

    if (learningManagementMenu?.sectionTo) {
      const learningManagementPath = `/${transformText(learningManagementMenu?.text)}/${
        learningManagementMenu?.sectionTo
      }`;
      history.push(learningManagementPath);
    } else {
      history.push("/");
    }
  };


  // VERIFICAÇÃO PARA VERIFICAR SE EXISTE A NECESSIDADE DE ATUALIZAR OS BLOCOS COM UMA NOVA CHAMADA DA API.
  useEffect(() => {

      if(currentEditPayload?.modality || currentEditPayload?.status){
        _getLearningNode();
      }
  }, [currentEditPayload?.modality,  currentEditPayload?.status, _getLearningNode]);


  return (
    <div ref={nodeManagerElement}>
      <BlockOptions
      permissionLearningPage={permissionLearningPage}
        newBlock={handleAddBlock}
        loadingAddBlock={loadingAddBlock || loadingNodeList}
      />
      <div
        className={`node-manager-wrapper-v2 ${nodeData?.status === "ARCHIVED" ? "disabled" : ""}`}
      >
        <div className="node-manager-nodes-container">
          {loadingNodeList ? (
            <PlaceholderBlocks number={3}/>
          ) : errorNode ? (
            <div className="node-manager-error">Erro ao carregar nós</div>
          ) : (
            <>
              { nodeData?.blocks?.length === 0 && (
                <div className="node-manager-add-more-container-v2">
                  <AddCircleOutlineIcon style={{ fontSize: 30, color: "#eea125", marginBottom: 5 }} />
                  Clique no componente acima para adiciona-lo aqui.
                </div>)
              }
              <BlockList
                blockData={nodeData.blocks}
                baseNodeLevel={nodeData.nodeLevel}
                removeBlock={handleRemoveBlock}
                updateBlock={handleUpdateBlock}
                saveBlock={handleSaveUpdateBlock}
                onChangeOrder={handleChangeOrder}
                onElementChangeOrder={handleElementChangeOrder}
                onElementMove={handleElementMove}
                loadingRemoveBlock={loadingRemoveBlock}
                loadingAddBlock={loadingAddBlock}
                loadingMoveBlock={loadingMoveBlock}
              />
            </>
          )}
        </div>
      </div>
      {!loadingNodeList && (
        <div
          className="row pr-3"
          style={{ padding: 5, justifyContent: "flex-end", maxWidth: 1160, width: "100%" }}
        >
          {/* <button
            type="button"
            onClick={handleConcluir}
            className="btn content-page-button ml-3"
            style={{ backgroundColor: "#feac0e" }}
          >
            Concluir
          </button> */}
        </div>
      )}
    </div>
  );
};

const ComponentWrapper = () => {
  return (
    <NodeManagerProvider>
      <NodeManagerV2 />
    </NodeManagerProvider>
  );
};

export default ComponentWrapper;
