import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { isAuthenticated } from "./services/NexiDigitalAPI";
import Login from "./containers/Login";
import AtivaCadastro from "./containers/AtivaCadastro";
import Termos from "./containers/Termos";
import Registro from "./containers/Registro";
import Cockpit from "./containers/Cockpit";
import User from "./containers/User";
import Obrigado from "./containers/Obrigado";
import AddUser from "./containers/AddUser";
import Reports from "./containers/Reports";
import SideBar from "./containers/SideBar";
import EditUser from "./containers/EditUser";
import ImportUsers from "./containers/ImportUsers";
import Header from "./containers/Header";
import MoveUsersCompanies from "./containers/MoveUsersCompanies";

import { transformText, getMenuRoutes } from "./utils/Utils";

//CMS
import MenuSelector from "./containers/MenuSelector";
import GestaoConteudo from "./containers/cms/GestaoConteudo";
import TelaConfiguracao from "./containers/configuracao/Configuracao";
import LayoutManager from "./containers/LayoutManager/LayoutManager.js";
import ContentListGroup from "./containers/cms/ContentListGroup";
import TemplateSelector from "./containers/cms/TemplateSelector";
import ZoomMeetings from "./containers/configuracao/ZoomMeetings";
import ZoomAuth from "./containers/configuracao/ZoomAuth";
import Tags from "./containers/configuracao/Tags";
import Groups from "./containers/configuracao/Groups";
import { AuthContext } from "./context/AuthContext";
import Upload from "./containers/cms/UploadContent";
import Biblioteca from "./containers/Biblioteca";
import Profiles from "./containers/Profiles";
import updateProfile from "./containers/Profiles/updateProfile";
import Loja from "./containers/Loja";
import Campaigns from "./containers/Gamification/Campaigns";
import CampaignManagement from "./containers/Gamification/Page/CampaignManagement";
import Ranking from "./containers/Gamification/Page/Ranking";
import ReportsGamification from "./containers/Gamification/Reports/";
import Calendario from "./containers/Calendario";
import CompanyManager from "./containers/CompanyManager/CompanyManager";
import AddCompany from "./containers/CompanyManager/AddCompany";
import EditCompany from "./containers/CompanyManager/EditCompany";
import AppColorManager from "./containers/AppColorManager/";
import QrcodeManager from "./containers/QrcodeManager";
import QrAddUser from "./containers/QrAddUser";
import SectionDesign from "./containers/LayoutManager/SectionDesign";
import MenuDesign from "./containers/LayoutManager/MenuDesign";

import ContentPage from "./containers/PeopleCraft/ContentPage/ContentPage.jsx";
import ContentPageV2 from "./containers/PeopleCraft/ContentPage/ContentPageV2.jsx";
import NodeManager from "./containers/PeopleCraft/NodeManager/NodeManager";
import ListClass from "./containers/PeopleCraft/ClassManagement/ListClass";
import AttendanceList from "./containers/PeopleCraft/ClassManagement/AttendanceList";
import CreateNewClass from "./containers/PeopleCraft/ClassManagement/CreateNewClass";
import StatusStudents from "./containers/PeopleCraft/ClassManagement/StatusStudents";
import ListRegistration from "./containers/PeopleCraft/ClassManagement/ListRegistration";
import ListRegistrationBatch from "./containers/PeopleCraft/ClassManagement/ListRegistrationBatch";

import EvaluationManager from "./containers/PeopleCraft/EvaluationManager";

import CalendarCategoryManager from "./containers/cms/CalendarCategoryManager";
import SideMenuManager from "./containers/configuracao/SideMenuManager";
import PlatformTerms from "./containers/configuracao/PlatformTerms";
import NewTerms from "./containers/configuracao/PlatformTerms/pages/NewTerms";
import HistoryTerms from "./containers/configuracao/PlatformTerms/pages/HistoryTerms";
import Notification from "./containers/configuracao/Notification";
import CertificatesEdit from "./containers/PeopleCraft/CertificatesEdit";
import CertificatesManager from "./containers/PeopleCraft/CertificatesManager";
import Evaluations from "./containers/PeopleCraft/Evaluations";
import ImpactAssessmentPage from "./containers/PeopleCraft/MyTeam/page/ImpactAssessmentPage";
import ExternalCoursePage from "./containers/PeopleCraft/MyTeam/page/ExternalCoursePage";

import { useStyles } from "./hooks/useThemes"; ///PTC
import ContentBannerImage from "./containers/PeopleCraft/ContentBannerImage/ContentBannerImage";

import EvaluationManagement from "./containers/EvaluationManagement";
import EvaluationControl from "./containers/EvaluationManagement/Pages/EvaluationControl";
import BankEvaluations from "./containers/EvaluationManagement/Pages/BankEvaluations";
import BankQuestions from "./containers/EvaluationManagement/Pages/BankQuestions";
import EquivalenceManagement from "./containers/PeopleCraft/EquivalenceManagement";

function Routes({ location }) {
  const { loginData } = useContext(AuthContext);
  const isAdmin = loginData?.isAdmin || 0;

  useEffect(() => {
    if (isAdmin) {
      const applyStyles = useStyles;
      applyStyles([]);
    }
  }, [isAdmin]);

  useEffect(() => {
    if (location) {
      const pathsToIgnore = [
        "/user",
        "/user/add",
        "/import-users",
        "/edit-users",
        "/configuracao/grupos",
      ];
      if (!pathsToIgnore.includes(location.pathname)) {
        const menuData = JSON.parse(localStorage.getItem("menu-data"));
        if (menuData?.idCompanyLogin && menuData.idCompany !== menuData.idCompanyLogin) {
          menuData.idCompany = menuData.idCompanyLogin;
          localStorage.setItem("menu-data", JSON.stringify(menuData));
        }
      }
    }
  }, [location]);

  const PrivateRoute = useCallback(
    ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated() ? (
            <div className="route-section-content">
              <Component {...props} />
            </div>
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
          )
        }
      />
    ),
    [],
  );

  const showSideBar = useCallback((location) => {
    const noSidebarRoutes = ["/login", "/termos", "/registro", "/obrigado", "/selfregister"];
    return !noSidebarRoutes.includes(location.pathname);
  }, []);

  const getRoutes = useCallback(() => {
    let routes = JSON.parse(localStorage.getItem("contentGroups"));

    if (routes && routes.length > 0) {
      return routes;
    } else {
      return [];
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated()) {
      const appDetails = JSON.parse(localStorage.getItem("appDetails"));
      if (appDetails) {
        document.title = appDetails.name;
        const favicon = document.getElementById("favicon");
        if (favicon) {
          favicon.href = appDetails.favIcon;
        }
      }
    }
  }, []);

  const adminRoutes = useMemo(
    () =>
      isAdmin >= 1 && (
        <>
          <PrivateRoute exact path="/user" component={User} />
          <PrivateRoute
            exact
            path="/movimentar-usuarios-entre-empresas"
            component={MoveUsersCompanies}
          />
          <PrivateRoute exact path="/reports" component={Reports} />
          <PrivateRoute exact path="/configuracao" component={TelaConfiguracao} />
          <PrivateRoute path="/configuracao/zoom_auth" component={ZoomAuth} />
          <PrivateRoute path="/configuracao/zoom" component={ZoomMeetings} />
          <PrivateRoute path="/configuracao/tags" component={Tags} />
          <PrivateRoute path="/configuracao/gestao-de-cor-e-logo/" component={AppColorManager} />
          <PrivateRoute path="/configuracao/menu-lateral/" component={SideMenuManager} />
          <PrivateRoute path="/configuracao/notificacoes/" component={Notification} />
          <PrivateRoute
            exact
            path="/configuracao/termos-da-plataforma/"
            component={PlatformTerms}
          />
          <PrivateRoute
            exact
            path="/configuracao/termos-da-plataforma/cadastrar-termo"
            component={NewTerms}
          />
          <PrivateRoute
            exact
            path="/configuracao/termos-da-plataforma/cadastrar-termo/:id"
            component={NewTerms}
          />
          <PrivateRoute
            exact
            path="/configuracao/termos-da-plataforma/historico-termo/:id"
            component={HistoryTerms}
          />
          <PrivateRoute
            exact
            path={`/configuracao/grupos`}
            component={(props) => <Groups {...props} currentComponentType="default" />}
          />

          <PrivateRoute
            exact
            path="/configuracao/grupos/editar/:groupId/:groupTitle?/:editType?"
            component={(props) => <Groups {...props} currentComponentType="edit" />}
          />
          {/* <PrivateRoute exact
          path="/configuracao/grupos/editar/:groupId/:groupTitle"
          component={GroupDetail}
        /> */}
          <PrivateRoute
            exact
            path={`/configuracao/grupos/regras/:groupId/:groupTitle`}
            component={(props) => <Groups {...props} currentComponentType="rules" />}
          />
        </>
      ),
    [isAdmin],
  );

  const renderContentRoutes = useMemo(
    () => (
      <>
        <PrivateRoute exact path="/" component={Cockpit} />
        <PrivateRoute path="/layout-manager" component={LayoutManager} />
        <PrivateRoute path="/section-design/:sectionId" component={SectionDesign} />
        <PrivateRoute path="/menu-design/:sectionId" component={MenuDesign} />
        <PrivateRoute path="/biblioteca/:sectionto" component={() => <Biblioteca />} />
        <PrivateRoute path="/upload/:id" component={(props) => <Upload {...props} />} />
        <PrivateRoute exact path="/loja" component={Loja} />
        <PrivateRoute exact path="/gamificacao/campaigns" component={Campaigns} />
        <PrivateRoute
          exact
          path="/gamificacao/gestao-de-campanhas"
          component={CampaignManagement}
        />
        <PrivateRoute exact path="/gamificacao/ranking" component={Ranking} />
        <PrivateRoute exact path="/gamification/reports/:id" component={ReportsGamification} />
        <PrivateRoute exact path="/cms" component={GestaoConteudo} />
        <PrivateRoute exact path="/calendario" component={Calendario} />
        <PrivateRoute path="/import-users" component={ImportUsers} />
        <PrivateRoute path="/user/add" component={AddUser} />
        <PrivateRoute path="/add-company" component={AddCompany} />
        <PrivateRoute path="/edit-company" component={EditCompany} />
        <PrivateRoute path="/company-manager" component={CompanyManager} />
        <PrivateRoute exact path="/edit-user" component={EditUser} />
        <PrivateRoute path="/cms/content/:id" component={() => <TemplateSelector />} />
        <PrivateRoute path="/profiles" component={Profiles} />
        <PrivateRoute path="/updateprofile/:idProfile" component={updateProfile} />
        <PrivateRoute path="/app-color-manager/" component={AppColorManager} />
        <PrivateRoute path="/qrcode-manager/" component={QrcodeManager} />
        <PrivateRoute path="/calendar-category-manager" component={CalendarCategoryManager} />
        <PrivateRoute path="/content-page/:parentNodeCode/:editNodeCode?" component={ContentPage} />
        <PrivateRoute
          path="/content-page-v2/:parentNodeCode/:editNodeCode?"
          component={ContentPageV2}
        />
        <PrivateRoute
          path="/image-banner/:idBlockElement/:idElement/:nodeCode/:editNodeCode"
          component={ContentBannerImage}
        />
        <PrivateRoute path="/node-manager/:nodeCode" component={NodeManager} />
        <PrivateRoute path="/class-list/:nodeCode/:title?" component={ListClass} />
        <PrivateRoute path="/attendance-list/:idTeam?/:pageTitle?" component={AttendanceList} />
        <PrivateRoute
          path="/create-new-class/:nodeCode/:title?/:idTeam?"
          component={CreateNewClass}
        />
        <PrivateRoute
          path="/status-students/:nodeCode/:title?/:idTeam?"
          component={StatusStudents}
        />
        <PrivateRoute
          path="/list-registration/:nodeCode/:title?/:idTeam?"
          component={ListRegistration}
        />
        <PrivateRoute
          path="/list-registration-batch/:nodeCode/:title?/:idTeam?"
          component={ListRegistrationBatch}
        />
        <PrivateRoute
          path="/evaluation-manager/:evaluationType/:idBlock?/:idEvaluation?/:isEditing?"
          component={EvaluationManager}
        />
        <PrivateRoute
          path="/impact-evaluation/:idQuestionnaire/:idManager?"
          component={Evaluations}
        />
        <PrivateRoute path="/certificates-manager/" component={CertificatesManager} />
        <PrivateRoute path="/gestao-de-aprendizagem/equivalencia-entre-paginas/:idNode" component={EquivalenceManagement} />
        <PrivateRoute
          path="/edit-certificates/:idCertificate?/:idCompany?"
          component={CertificatesEdit}
        />
        <PrivateRoute path="/minha-equipe/avaliacao-impacto" component={ImpactAssessmentPage} />
        <PrivateRoute exact path="/minha-equipe/cursos-externos" component={ExternalCoursePage} />
        <PrivateRoute exact path="/evaluation-management" component={EvaluationManagement} />
        <PrivateRoute
          exact
          path="/gestao-de-avaliacoes/evaluation-control/:evaluationType?"
          component={EvaluationControl}
        />
        <PrivateRoute
          exact
          path="/gestao-de-avaliacoes/bank-evaluations/:evaluationType?"
          component={BankEvaluations}
        />
        <PrivateRoute
          exact
          path="/gestao-de-avaliacoes/bank-questions/:evaluationType?"
          component={BankQuestions}
        />
      </>
    ),
    [],
  );

  return (
    <div style={{ height: "100%", display: "flex" }}>
      {showSideBar(location) && (
        <>
          <SideBar />
          <Header />
        </>
      )}
      <section className="route-section">
        <Route exact path="/login" component={Login} />
        <Route exact path="/ativa" component={AtivaCadastro} />
        <Route exact path="/termos" component={Termos} />
        <Route exact path="/registro" component={Registro} />
        <Route exact path="/selfregister" component={QrAddUser} />
        <Route exact path="/obrigado" component={Obrigado} />
        {adminRoutes}
        {getRoutes()
          .filter((item) => item.text !== "")
          .map((item, index) => (
            <PrivateRoute
              key={index}
              path={`/gestao-de-conteudo/${transformText(item.name)}/`}
              component={() => <ContentListGroup item={item} />}
            />
          ))}
        {getMenuRoutes()
          .filter((item) => item.text !== "")
          .map((item, index) => (
            <PrivateRoute
              key={index}
              path={`/${transformText(item.text)}/${item.sectionTo}`}
              component={() => <MenuSelector />}
            />
          ))}
        {renderContentRoutes}
      </section>
    </div>
  );
}

export default withRouter(Routes);
