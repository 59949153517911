import React, { useState, createContext } from "react";

import { updateNodeProperty, createNode, removeNode } from "../Utils/filterNode";
import { stringify } from "utils/Utils";
export const LMCreateContext = createContext();

export const LMContext = ({ children }) => {
  const [preload, setPreload] = useState(true);
  const [nodeList, setNodeList] = useState([]);
  const [nodeCurrent, setNodeCurrent] = useState(null);
  const [statusDynamic, setStatusDynamic] = useState("EDIT_NODE");
  const [nodeData, setNodeData] = useState(null);
  const [cardIdBlock, setCardIdBlock] = useState(null);
  const [loadingNodeList, setLoadingNodeList] = useState(true);
  const [currentEditPayload, setCurrentEditPayload] = useState(null);
  const [infomationNodeChat, setInfomationNodeChat] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const editPropsNodes = (payload, node = null) => {
    const editNodelist = updateNodeProperty(nodeList,node ? node :  (nodeCurrent.nodeCode ? nodeCurrent.nodeCode : nodeCurrent.originalNodeCode), payload);
    setCurrentEditPayload(payload);
    setNodeList(editNodelist);
    setNodeData((prev) => ({...prev, ...payload}));
  };

  const addNode = (parentNodeCode, newNode) => {
    const nodeListUpdated = createNode(nodeList, parentNodeCode, newNode);
    setNodeList(nodeListUpdated);
    // setNodeCurrent(newNode);
  };

  const deleteNode = (nodeCode) => {
    const nodeListUpdated = removeNode(nodeList, nodeCode);

    setNodeList(nodeListUpdated);
    setNodeCurrent(nodeListUpdated[0]);
    localStorage.setItem("currentNodeManagement", stringify(nodeListUpdated[0]));
  };

  const sidebarChatInteration = (state, current) => {
      setInfomationNodeChat(state ? current : null)
    setIsSidebarOpen(state)

  }

  const getInfoChatInteration = () => {
    return infomationNodeChat
  }

  const value = {
    preload,
    setPreload,

    nodeList,
    setNodeList,

    nodeCurrent,
    setNodeCurrent,

    statusDynamic,
    setStatusDynamic,

    editPropsNodes,
    addNode,
    deleteNode,
    nodeData,
    setNodeData,
    cardIdBlock,
    setCardIdBlock,
    currentEditPayload,

    setLoadingNodeList,
    loadingNodeList,

    isSidebarOpen,
    sidebarChatInteration,

    getInfoChatInteration
  };

  return <LMCreateContext.Provider value={value}>{children}</LMCreateContext.Provider>;
};
