import "./styles.scss";
import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { withRouter, useParams } from "react-router-dom";
import _ from "underscore";

import { Form, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Button, FormGroup } from "react-bootstrap";

import { FormControlLabel, Switch, Tooltip } from "@material-ui/core";
import ModalDialog from "containers/PeopleCraft/Modals/ModalDialog";
import PreloaderBlur from "containers/PeopleCraft/PreloaderBlur";
import { getGroups,
  getLinkedGroupsToNode,
  linkGroupToNode,
  unlinkGroupFromNode,
  setNodeVisibility,
  getLearningNode,
  putLearningConfiguration
} from "services/NexiDigitalAPI";
import { BiSolidInfoCircle } from "react-icons/bi";
import CustomTooltip from "components/CustomTooltip";
import Label from "components/PeopleCraft/Label";
import { LMCreateContext } from "containers/PeopleCraft/LearningManagementNew/Contexts/LMContext";




interface LinkGroupsVisibilityProps {
  contentData?:any;
  setContentData?:any;
  nodeCode?:any;
}


const LinkGroupsVisibility: React.FC<LinkGroupsVisibilityProps> = ({
  contentData,
  setContentData,
  nodeCode
}) => {
  const [hideMenu, setHideMenu] = useState(0)
  const [preloadInit, setPreloadInit] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [currentSelectedData, setCurrentSelectedData] = useState(contentData);
 const { editPropsNodes} = useContext(LMCreateContext)

  const showModalConfirmParamiters = useRef({
    title:"",
    text: "",
    variantBtn: "danger",
    textBtn: "",
    className: "",
    fnc: null,
  });

  const [list, setList] = useState(null);
  const [listSelect, setListSelect] = useState([]);

  const openConfirmModal = (objParamiters) => {
    showModalConfirmParamiters.current = objParamiters;
    setShowModalConfirm(true);
  };

  const NoPublic = () => {
    return <div className="noPublicTarget">Sem público-alvo</div>;
  };

  const filterListInit = (listAllGroups, listRemoveGroups) => {
    for (var i = listAllGroups.length - 1; i >= 0; i--) {
      for (var j = 0; j < listRemoveGroups.length; j++) {
        if (listAllGroups[i] && listAllGroups[i].idGroups === listRemoveGroups[j].idGroups) {
          listAllGroups.splice(i, 1);
        }
      }
    }

    setList(_.sortBy(listAllGroups, "title"));

  };

  useEffect(() => {

    setPreloadInit(true);

    if(currentSelectedData){
      setHideMenu(currentSelectedData.sideMenu);
      getData();
    }else{
      //RESGATA O DATA PELO NODECODE
      getLearningNode(nodeCode)
        .then((res) => {
          const {result } = res.data
          setCurrentSelectedData(result);
          setHideMenu(result?.sideMenu || 0);
          getData();
        })
        .catch((err) => {
          console.log("error ", err);
        });
    }

  }, []);
  const _nodeCode = useMemo(() => {
    return  currentSelectedData ? currentSelectedData.nodeCode : nodeCode
  }, [currentSelectedData, nodeCode])

  let idGroups = 0;
  const handleChangeGroups = (idGr) => {
    idGroups = parseInt(idGr);
  };

  const getData = () => {

    let listAllGroups = [];
    let listRemoveGroups = [];

    getLinkedGroupsToNode(_nodeCode)
    .then((res) => {

      listRemoveGroups = res.data.result;
      setListSelect(res.data.result);

      getGroups()
        .then((res) => {
          if (res.data.success == true) {
            setList(res.data.groups);
          }

          listAllGroups = res.data.groups;
          filterListInit(listAllGroups, listRemoveGroups);
          setPreloadInit(false);

        })
        .catch((err) => {
          console.log("error ", err);
        });
    })
    .catch((err) => {
      console.log("error ", err);
    });
  }

  const paginationComponentOptions = {
    rowsPerPageText: "Conteúdos por página",
    rangeSeparatorText: "de",
  };

  const addGroupsTarget = () => {
    if (idGroups !== 0) {
      setPreloadInit(true);
      const idGr = list.find((el) => el.idGroups === idGroups);
      setListSelect([...listSelect, idGr]);

      const listInit = list.filter((el) => el.idGroups !== idGroups);
      setList(listInit);

      linkGroupToNode(_nodeCode, {idGroups: idGr.idGroups,})
        .then((res) => {
          setPreloadInit(false);
        })
        .catch((err) => {
          console.log("error ", err);
        });
    }
  };



  const removeGroupsTarget = (group) => {
    openConfirmModal({
      text: `Você tem certeza que deseja remover o público-alvo: <span>${group.title}</span>?`,
      variantBtn: "danger",
      textBtn: "Remover público-alvo",
      className: "subModalAutoRegistration",
      fnc: () => {
        setPreloadInit(true);
        setList([...list, group]);
        const idGr = listSelect.filter((el) => el.idGroups !== group.idGroups);
        setListSelect(idGr);

        unlinkGroupFromNode(_nodeCode, group.idGroups)
          .then((res) => {
            setPreloadInit(false);
          })
          .catch((err) => {
            console.log("error ", err);
          });
      },
    });
  };

  const handleVisibilityChange = (value) => {

    setCurrentSelectedData({...currentSelectedData, "visibility": value === "1" ? true : false});
    setNodeVisibility(currentSelectedData.nodeCode, { visibility:  value === "1" ? true : false });

    if(setContentData && contentData){
      setContentData({...contentData, "visibility": value});
    }
    editPropsNodes({ "visibility": value}, currentSelectedData)
  }

  const updateHideMenu = async (item) => {
    try {
      await putLearningConfiguration(item,_nodeCode);
     editPropsNodes(item, currentSelectedData)

    } catch (e) {
      console.log(e);
    }
  };

  /* Colunas da tabela dos grupos já criados */
  const columns = [
    {
      name: "Código",
      selector: "idGroups",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (group) => (
        <div>
          <p>{group.idGroups}</p>
        </div>
      ),
    },
    {
      name: "Público-alvo",
      sortable: false,
      selector: "title",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (group) => (
        <div>
          <p>{group.title}</p>
        </div>
      ),
    },

    {
      name: "Ação",
      sortable: false,
      selector: "actions",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (group) => (
        <div className="groupActions" key={group.idGroups}>
          <Tooltip title="Remover público-alvo" placement="top">
            <span className="btn btn-sm float-right" onClick={() => removeGroupsTarget(group)}>
              <RiDeleteBin5Line />
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];


  if(!currentSelectedData) return <PreloaderBlur />

  return (
    <>
      {preloadInit && <PreloaderBlur />}

      {showModalConfirm && (
        <ModalDialog
          title={showModalConfirmParamiters.current.title}
          text={showModalConfirmParamiters.current.text}
          className={showModalConfirmParamiters.current.className}
          show={showModalConfirm}
          setShow={setShowModalConfirm}
          btnCall={{
            className: "",
            variantBtn: showModalConfirmParamiters.current.variantBtn,
            text: showModalConfirmParamiters.current.textBtn,
            callEvent: () => {
              if (showModalConfirmParamiters.current.fnc) {
                showModalConfirmParamiters.current.fnc();
              }
            },
          }}
        />
      )}

      <div className="groupsContainerVisibility">
        <div className="form-data flex-column ">
          <div className="header-form-data">
            <CustomTooltip
              title="Ao habilitar, a página será exibida no menu lateral na área do aluno."
              placement="top"
              disableHoverListener={false}
              arrow
            >
              <div className="importInfo">
                <BiSolidInfoCircle />
              </div>
            </CustomTooltip>
            <Switch
              color="primary"
              id="sideMenu"
              checked={!!hideMenu}
              onChange={(e) => {
                const value = e.target.checked ? 1 : 0;
                setHideMenu(value);
                updateHideMenu({
                  sideMenu: value,
                });
              }}
            />
            <Label name="Exibir no menu lateral" className="label-show-menu" htmlFor="sideMenu" />
          </div>
        </div>
        < hr className="hr-linkGroups"/>

        <div className="groupListHeader">
          <div>
            <h5>Definir visibilidade da página</h5>
          </div>
           <hr className="hr-linkGroups"/>

          <div className="infos">
            <h6>
              Selecione a visibilidade da página:
            </h6>
          </div>
        </div>

        <div>
            <FormGroup>
              <Form.Check
                inline
                label="Pública"
                name="visibilityGroup"
                type="radio"
                value={1}
                checked={currentSelectedData.visibility == 1}
                onChange={(e) => {
                  handleVisibilityChange( e.target.value );
                }}
              />

              <Form.Check
                inline
                label="Restrita"
                name="visibilityGroup"
                type="radio"
                value={0}
                checked={currentSelectedData.visibility == 0}
                onChange={(e) => {
                  handleVisibilityChange( e.target.value );
                }}
              />
            </FormGroup>
        </div>

        {(currentSelectedData.visibility == 0) &&
          <>
            <div className="groupListHeader">
              <div className="infos">
                <h6>
                  Selecione o(os) público(s)-alvo que poderão visualizar esta página:
                </h6>
              </div>
            </div>

            <div className={"targetAdd"}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Público-alvo</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  // value={currentSelectedData.idGroups}
                  onChange={(e) => {
                    handleChangeGroups(e.target.value);
                  }}
                >
                  <option>Selecione um público-alvo</option>
                  {list &&
                    list.map((it) => (
                      <option key={it.idGroups} value={it.idGroups}>
                        {it.title}
                      </option>
                    ))}
                </Form.Control>
              </InputGroup>

              <Button variant="warning" onClick={addGroupsTarget}>
                Adicionar
              </Button>
            </div>

            <div className="groupListTableContainer">
              <DataTable
                columns={columns}
                data={listSelect}
                paginationComponentOptions={paginationComponentOptions}
                noDataComponent={<NoPublic />}
                paginationServer
                noHeader
                fixedHeader
              />
            </div>
          </>
        }
      </div>
    </>
  );
};

export default LinkGroupsVisibility;
