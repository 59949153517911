// Basic
import React, { useContext, useState, useEffect } from "react";

// UI and icons
import "./../Cockpit.css";
import { AuthContext } from "../../context/AuthContext";
import Acordeon from "./Components/Acordeon";
import Switch from "@material-ui/core/Switch";
import { Redirect, useParams } from "react-router-dom";
import ToggleSwitch from "./Components/ToggleSwitch";
import { Person, HighlightOff, DeleteForever } from "@material-ui/icons";
import { FiUserPlus } from "react-icons/fi";
import Button from "@material-ui/core/Button";
import { Modal, Col, Form } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import {
  addProfilePermissionApiSecondary,
  addProfileResourcesApiSecondary,
  addUserIntoProfileSecondary,
  deleteProfileSecondary,
  deleteUserOfProfileSecondary,
  getPermissionResourcesSecondary,
  getProfilePermissionsSecondary,
  getProfileResourcesApiSecondary,
  getProfileUsersSecondary,
  getUsersAdminSecondary,
  removeProfilePermissionApiSecondary,
  removeProfileResourcesApiSecondary,
  updateProfileApiSecondary,
} from "../../services/NexiDigitalAPISecondary";
import Select from "react-select";
import { getMenuData } from "../../services/NexiDigitalAPI";
import { Skeleton } from "@material-ui/lab";

export default function Cockpit() {
  const { loginData } = useContext(AuthContext);
  const [redirect, setRedirect] = useState(false);
  const primaryColor = loginData?.primaryColor;
  const [menuOption, setMenuOption] = useState([]);
  const [resources, setResources] = useState([]);
  const [profileResources, setProfileResources] = useState([]);
  const [loadingProfileResources, setLoadingProfileResources] = useState(true);
  const { idProfile } = useParams();
  const [profileNameTitle, setProfileNameTitle] = useState("");
  const [profileName, setProfileName] = useState("");
  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const [userSelected, setUserSelected] = useState("");
  const [userList, setUserList] = useState([]);
  const [profileUsers, setProfileUsers] = useState([]);
  const [loadingProfileUsers, setLoadingProfileUsers] = useState(true);
  const [loadingDeleteUser, setLoadingDeleteUser] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [isLoadingAddUser, setIsLoadingAddUser] = useState(false);
  const [isLoadingUpdateProfile, setIsLoadingUpdateProfile] = useState(false);
  const adminLevel = getMenuData()?.admin;

  const useStyles = makeStyles((theme) => ({
    switch_track: {
      backgroundColor: primaryColor,
    },
    switch_base: {
      color: "#FFF",
      "&.Mui-disabled": {
        color: "#FFF",
      },
      "&.Mui-checked": {
        color: primaryColor,
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: primaryColor,
      },
    },
    switch_primary: {
      "&.Mui-checked": {
        color: primaryColor,
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: primaryColor,
      },
    },
  }));

  const classes = useStyles();

  function handleChangeProfileName(e) {
    const name = e.target.value;
    if (name && name != undefined && name != null && name != "") setProfileName(e.target.value);
    else setProfileName("");
  }

  const updateProfile = async () => {
    if (profileName && profileName != undefined && profileName != null && profileName != "") {
      setIsLoadingUpdateProfile(true);
      const result = await updateProfileApiSecondary(idProfile, profileName).finally(() => {
        setIsLoadingUpdateProfile(false);
      });

      if (result) {
        setProfileNameTitle(profileName);
      }
    }
  };

  async function getMenuOption() {
    const menuOptionList = await JSON.parse(localStorage.getItem("menu-option"));
    _getProfilePermissions(menuOptionList);
  }

  async function getResourcesApi() {
    const resources = await getPermissionResourcesSecondary();
    setResources(resources);
  }

  async function getProfileResources() {
    setLoadingProfileResources(true);
    const profileResourcesResponse = await getProfileResourcesApiSecondary(idProfile);
    const profileNameResponse = profileResourcesResponse?.profile?.name;
    setProfileName(profileNameResponse);
    setProfileResources(profileResourcesResponse.listaPerfil);
    setLoadingProfileResources(false);
  }

  const changeUniqueResource = async (idResource, checked) => {
    if (checked === true) {
      await addProfileResourcesApiSecondary(idProfile, idResource);
    } else {
      await removeProfileResourcesApiSecondary(idProfile, idResource);
    }
  };

  async function confirmDeleteProfile() {
    const returnDelete = await deleteProfileSecondary(idProfile);

    if (returnDelete) setRedirect(true);
  }

  function handleCloseAlertDelete() {
    setShowAlertDelete(false);
  }

  function handleOpenAlertDelete() {
    setShowAlertDelete(true);
  }

  function getAllUsers() {
    setIsLoadingUsers(true);
    getUsersAdminSecondary(idProfile)
      .then((result) => {
        if (result.data.success === true) {
          // filter admin users
          let adminUsers = result?.data?.userAdminList;

          // map select labels
          adminUsers = adminUsers.map((adminItem) => ({
            ...adminItem,
            value: adminItem.idUser,
            label: adminItem.name,
          }));

          setUserList(adminUsers);
          setUserSelected(adminUsers?.[0] || null);
        }
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setIsLoadingUsers(false);
      });
  }

  function handleUserSelect(selectedUserId) {
    setUserSelected(selectedUserId);
  }

  async function addUserIntoProfileBtn() {
    setIsLoadingAddUser(true);
    const result = await addUserIntoProfileSecondary(idProfile, userSelected.idUser).finally(() => {
      setIsLoadingAddUser(false);
    });

    if (result) {
      getUsersOfProfile();
    }
  }

  async function getUsersOfProfile() {
    setLoadingProfileUsers(true);
    const result = await getProfileUsersSecondary(idProfile).finally(() => {
      setLoadingProfileUsers(false);
    });

    if (result) {
      setProfileUsers(result);
    }
  }

  async function deleteUserOfProfileHandle(idUser) {
    setLoadingDeleteUser(idUser);
    await deleteUserOfProfileSecondary(idProfile, idUser)
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoadingDeleteUser(false);
      });
    getUsersOfProfile();
  }

  const _getProfilePermissions = (menuOptionList) => {
    getProfilePermissionsSecondary(idProfile)
      .then((res) => {
        if (res.data.success) {
          const returnedPermissions = res.data.permissions;
          let newMenuOption = [...menuOptionList];

          newMenuOption.forEach((menuOptionItem, index) => {
            const hasMatchingSection = returnedPermissions.some(
              (item) => item.idSection === menuOptionItem.sectionTo,
            );
            const isDefaultPermission = returnedPermissions.some(
              (item) => item.idSection === menuOptionItem.sectionTo && item.idCompany === 0,
            );
            if (hasMatchingSection) {
              newMenuOption[index].hasPermission = true;
              if (isDefaultPermission) {
                newMenuOption[index].isDefaultPermission = true;
              }
            }
          });
          setMenuOption(newMenuOption);
        } else {
          setMenuOption(menuOptionList);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handlePermissionChange = (sectionTo, checked, index) => {
    let newMenuOption = [...menuOption];
    newMenuOption[index].hasPermission = checked;
    setMenuOption(newMenuOption);
    if (checked) {
      addProfilePermissionApiSecondary(Number(sectionTo), Number(idProfile)).catch((err) => {
        console.log("addProfilePermissionApiSecondary err", err);
      });
    } else {
      removeProfilePermissionApiSecondary(Number(sectionTo), Number(idProfile)).catch((err) => {
        console.log("removeProfilePermissionApiSecondary err", err);
      });
    }
  };

  const getAcordeonListHeight = () => {
    const menuOptionList = JSON.parse(localStorage.getItem("menu-option"));
    const menuLength = menuOptionList?.length || 5;

    const menuHeight = menuLength * 40;
    return menuHeight;
  };

  useEffect(() => {
    getMenuOption();
    getResourcesApi();
    getProfileResources();
    getAllUsers();
    getUsersOfProfile();
  }, []);

  if (redirect) {
    return <Redirect to="/profiles" />;
  }

  return (
    <div className="Cockpit">
      <div className="header-title-wrapper">
        <div className="header-title">
          <Person style={{ fontSize: 30, marginRight: 10 }} />
          {loadingProfileResources ? (
            <Skeleton variant="rect" width={150} height={30} style={{ borderRadius: 5 }} />
          ) : (
            <span>{profileName}</span>
          )}
        </div>
      </div>
      <div align="right">
        <Button
          className="blast-button"
          style={{ backgroundColor: primaryColor, marginRight: "10px", color: "white" }}
          href="/#/profiles"
        >
          Voltar
        </Button>
      </div>
      Alterar Perfil <br />
      <br />
      <div>
        <form className="formUpdateProfile" autoComplete="off">
          <div className="form-row">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="labelProfiileName">
                  Nome
                </span>
              </div>
              <Col xs={8} sm={8} style={{ paddingLeft: 0 }}>
                <input
                  type="text"
                  className="form-control"
                  value={profileName}
                  placeholder="Inserir novo nome"
                  onChange={(e) => handleChangeProfileName(e)}
                />
              </Col>
              <Col xs={12} sm={2} style={{ paddingLeft: 0 }}>
                <Button
                  onClick={updateProfile}
                  className="blast-button"
                  style={{
                    backgroundColor: primaryColor,
                    marginRight: "10px",
                    color: "#FFF",
                    padding: "5px",
                  }}
                >
                  {isLoadingUpdateProfile ? "Salvando..." : "Salvar"}
                </Button>
              </Col>
            </div>
          </div>
        </form>
      </div>
      <div align="right" style={{ float: "right" }}>
        {adminLevel >= 1 && (
          <Button
            onClick={handleOpenAlertDelete}
            style={{
              backgroundColor: primaryColor,
              marginLeft: "100px",
              color: "white",
              marginTop: -3,
            }}
          >
            <DeleteForever size={22} style={{ marginRight: 6, color: "white" }} />
            Excluir Perfil
          </Button>
        )}
      </div>
      <hr />
      Associar Recursos
      <br />
      <br />
      <div className="form-group" style={{ minHeight: getAcordeonListHeight(), display: "flex" }}>
        {loadingProfileResources ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <CircularProgress size={18} style={{ marginRight: 10 }} />
            Carregando recursos...
          </div>
        ) : (
          <div style={{ flex: 1 }}>
            {menuOption.map((menuOptionItem, index) => {
              let treatedResources = [];

              // add resources Switches
              resources.forEach((resourcesItem) => {
                let isFromOption = resourcesItem.idSectionType === menuOptionItem.idSectionType;
                const isChecked = Boolean(
                  profileResources.filter((obj) => {
                    return obj.idResource === resourcesItem.idResource;
                  }).length,
                );

                if (isFromOption) {
                  const isReport = resourcesItem.idSectionType === 28;

                  if (isReport) {
                    const isLearningHubReport = [44, 45].includes(resourcesItem.idResource);

                    if (!isLearningHubReport) {
                      return;
                    }
                  }

                  resourcesItem = {
                    ...resourcesItem,
                    checked: isChecked,
                  };
                  
                  treatedResources.push(resourcesItem);
                }
              });

              const name = (
                <>
                  <Switch
                    onClick={(e) => e.stopPropagation()}
                    onChange={(event) =>
                      handlePermissionChange(menuOptionItem.sectionTo, event.target.checked, index)
                    }
                    checked={menuOptionItem.hasPermission}
                    disabled={menuOptionItem.isDefaultPermission}
                    classes={{
                      track: classes.switch_track,
                      switchBase: classes.switch_base,
                      colorPrimary: classes.switch_primary,
                    }}
                    style={{ zIndex: 1 }}
                  />
                  <span style={{ marginTop: 1 }}>{menuOptionItem.text}</span>
                  {menuOptionItem.isDefaultPermission && (
                    <span style={{ color: "gray", marginLeft: 10 }}>(Habilitado por padrão)</span>
                  )}
                  <span style={{ color: "gray", position: "absolute", right: 10 }}>
                    {treatedResources?.length} Configurações
                  </span>
                </>
              );
              return (
                <Acordeon key={index} position={index} name={name}>
                  {treatedResources.map((resource, keyChildren) => {
                    return (
                      <div key={resource.idResource}>
                        <ToggleSwitch
                          key={keyChildren}
                          onChange={(e) =>
                            changeUniqueResource(resource.idResource, e.target.checked)
                          }
                          checked={resource.checked}
                          color={primaryColor}
                        />
                        {resource.name}
                        <br />
                      </div>
                    );
                  })}
                </Acordeon>
              );
            })}
          </div>
        )}
      </div>
      <hr />
      <div className="form-group">
        <br />
        Associar Usuários
        <br />
        <br />
        <div className="form-row">
          <div className="mr-4" style={{ display: "flex" }}>
            <Form.Group style={{ width: 300 }}>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isLoading={isLoadingUsers}
                name="update-profile"
                options={userList}
                onChange={(e) => handleUserSelect(e)}
                value={userSelected}
                placeholder={"Selecionar Usuário"}
              />
            </Form.Group>
          </div>
          <div className="col-sm-6 col-md-6 col-5">
            <Button
              onClick={addUserIntoProfileBtn}
              className="blast-button"
              style={{
                backgroundColor: primaryColor,
                marginRight: "10px",
                color: "#FFF",
                marginTop: "2px",
              }}
            >
              {isLoadingAddUser ? (
                <CircularProgress size={22} style={{ marginRight: 6 }} />
              ) : (
                <FiUserPlus color="white" size={22} style={{ marginRight: 6 }} />
              )}

              {isLoadingAddUser ? "Associando..." : "Associar"}
            </Button>
          </div>
        </div>
      </div>
      <div className="form-row">
        {loadingProfileUsers ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <CircularProgress size={22} style={{ marginRight: 10 }} />
            <span>Carregando...</span>
          </div>
        ) : profileUsers?.length === 0 ? (
          <div style={{ color: "gray" }}>Nenhum perfil adicionado.</div>
        ) : (
          profileUsers?.map((item, index) => {
            let bg;
            if (index % 2 === 0) bg = "rgba(101,45,144,0.22)";
            else bg = "rgba(101,45,144,0.14)";
            return (
              <>
                <div
                  className="col-12"
                  style={{
                    height: 40,
                    verticalAlign: "middle",
                    padding: 8,
                    borderBottom: "1px solid white",
                    backgroundColor: bg,
                    marginRight: 5,
                  }}
                >
                  <Person style={{ color: primaryColor, fontSize: 25, marginRight: 5 }} />
                  <span>
                    <span style={{ marginBottom: -3 }}>{item.name}</span>
                    {loadingDeleteUser === item.idUser ? (
                      <CircularProgress size={18} style={{ marginLeft: 5 }} />
                    ) : (
                      <HighlightOff
                        id={item.id}
                        onClick={(e) => deleteUserOfProfileHandle(item.idUser)}
                        style={{
                          color: primaryColor,
                          marginTop: -1,
                          marginLeft: 5,
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </span>
                </div>
              </>
            );
          })
        )}
      </div>
      <br />
      <br />
      <br />
      <Modal show={showAlertDelete} onHide={handleCloseAlertDelete} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Perfil a ser excluído</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Person style={{ color: "#999999", fontSize: 30 }} /> {profileNameTitle}
          <hr />
          Ao optar pela exclusão, automaticamente o sistema removerá{" "}
          <span style={{ color: primaryColor }}>todos</span> os recursos associados e os vínculos
          dos usuários neste perfil. Podendo impactar em seus logins e permissões de acesso. <br />
          <br />
          Tem certeza que deseja excluir{" "}
          <span style={{ color: primaryColor }}>permanentemente</span> este perfil? <br />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseAlertDelete}
            style={{ backgroundColor: "#6c757d", color: "#FFF" }}
          >
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={confirmDeleteProfile}
            style={{ backgroundColor: primaryColor, color: "#FFF", marginLeft: 10 }}
          >
            Sim! Desejo excluir.
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
