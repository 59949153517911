import { Tooltip } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import HelpIcon from '@material-ui/icons/Help';
import { DataGrid } from "@material-ui/data-grid";
import { Button, ButtonGroup } from "react-bootstrap";
import { AuthContext } from "../context/AuthContext";
import { useInterval } from "react-interval-hook";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { colors } from "../utils/Colors";
import Loading from "../components/Loading";
import "./ImportUsers.scss";
import {  checkImportStatus } from "../services/NexiDigitalAPI";
import {  getImportId } from "../services/NexiDigitalAPISecondary";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { getGroupsSecondary, uploadCsvApiSecondary } from "../services/NexiDigitalAPISecondary";
import { getMenuRoutes } from "../utils/Utils";
import { Modal } from "react-bootstrap";

const ImportUsers = () => {
  const [uploadingData, setUploadingData] = useState(false);
  const [fileImportId, setFileImportId] = useState(null);
  const [isChecking, setIsChecking] = useState(false);
  const [checkingTries, setCheckingTries] = useState(0);
  const [feedback, setFeedback] = useState(null);
  const [groups, setGroups] = useState([]);
  const [displayGroups, setDisplayGroups] = useState([]);
  const [fixedList, setFixedList] = useState([]);
  const [helpModal, setHelpModal] = useState(false);

  /* [Permission Module] - Permissions states */
  const [permissionAddUserIntoGroup, setPermissionAddUserIntoGroup] = useState(false);

  /* [Permission Module] - Menu Options */
  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();

    const objCurrentMenu = menuOptionList.filter((menu) => menu.text === "Cadastro de Usuários");

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);

      getPermissionsPage(resources);
    }
  }

  /* [Permission Module] - Permission attrs in page by login data information */
  function getPermissionsPage(resources) {
    try {
      if (Boolean(resources[0].filter((menu) => menu.name === "Inserir usuário no grupo").length))
        setPermissionAddUserIntoGroup(true);
    } catch (error) {
      console.log("Falha ao buscar permissões de Grupos");
    }
  }

  const columns = [
    { field: "id", headerName: "Linha", width: 100 },
    { field: "message", headerName: "Mensagem", width: 400 },
  ];

  const rows = feedback?.feedback?.map((item, index) => {
    return {
      id: item.line,
      message: item.message,
    };
  });

  const { start, stop } = useInterval(() => {
    _checkImportStatus();
  }, 3000);

  const uploadFile = (file) => {
    setUploadingData(true);
    setIsChecking(false);
    getImportId()
      .then((res) => {
        setFeedback(null);
        if (res.data.error) {
          console.log("ERROR", res);
          setUploadingData(false);
          return;
        }
        let selectedFile = file.files[0];
        const fileNameId = res.data.importId;
        const data = new FormData();
        setFileImportId(fileNameId);
        if (selectedFile) {
          data.append("fileData", selectedFile);
          data.append("requestId", fileNameId);
          data.append("groups", groups); // array of selected group id's
          uploadCsvApiSecondary(data)
            .then((response) => {
              if (response.data.error) {
                console.log("ERROR", response);
              } else {
                start();
                setIsChecking(true);
              }
            })
            .catch((error) => {
              console.log("error", error);
            })
            .finally(() => {
              setUploadingData(false);
            });
        } else {
          setUploadingData(false);
          console.log("Nenhum arquivo selecionado");
        }
      })
      .catch((err) => {
        setUploadingData(false);
        console.log("error", err);
      });
  };

  function getCsvLink() {
    const appDetails = JSON.parse(localStorage.getItem("appDetails")) || null;
    const privateBaseUrl = appDetails.privateBaseUrl;

    const URL = privateBaseUrl + "content/files/template_carga_usuarios.csv";
    return URL;
  }

  function _checkImportStatus() {
    if (isChecking && checkingTries <= 5) {
      checkImportStatus(fileImportId)
        .then((res) => {
          if (res.data.feedback) {
            setFeedback(JSON.parse(res.data.feedback));
            stop();
          }
        })
        .catch((err) => {
          console.log("err", err);
          setCheckingTries(checkingTries + 1);
        });
    }
  }

  function clearInputPath() {
    let element = document.getElementById("upload-button-file");
    element.value = "";
  }

  const handleChange = (event) => {
    setDisplayGroups(event.target.value);
    let selectedItems = [];
    fixedList.map((item) => {
      event.target.value.map((selectedItem) => {
        if (item.title == selectedItem) {
          selectedItems.push(item.idGroups); // storing only the group id's
        }
      });
    });
    setGroups(selectedItems);
  };

  function _getGroups() {
    getGroupsSecondary()
      .then((res) => {
        if (res.data.success == true) {
          setFixedList(res.data.groups); // stores the original response group list
          let titlesList = [];
          fixedList.map((item) => {
            titlesList.push(item.title);
          });
          setDisplayGroups(titlesList); // stores list of titles
        }
      })
      .catch((err) => {
        console.log("error ", err);
      });
  }

  useEffect(() => {
    getMenuOption();
    _getGroups();
    return () => {
      stop();
    };
  }, []);

  return (

    <div className="import-users">

     <Modal
          show={helpModal}
          className={"modalUsersImportHelp"}
          onHide={() => {
            setHelpModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title> <HelpIcon style={{ marginRight: 10 }} /> Guia de preenchimento dos campos de importação de usuário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table>
              <tr>
                <th class="tableHeader">Nome do campo</th>
                <th class="tableHeader">Obrigatório</th>
                <th class="tableHeader">Dado único por usuário</th>
                <th class="tableHeader">Tipo</th>
                <th class="tableHeader">Tamanho</th>
                <th class="tableHeader">Observações</th>
              </tr>
              <tr>
                <td>E-mail</td>
                <td class="aliginCenter">Sim</td>
                <td class="aliginCenter">Sim</td>
                <td class="aliginCenter">Texto</td>
                <td class="aliginCenter">-</td>
                <td>E-mail deve estar no formato padrão "nome@email.com..."</td>
              </tr>

              <tr>
                <td>Nome do usuário</td>
                <td class="aliginCenter">Sim</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Texto</td>
                <td class="aliginCenter">-</td>
                <td>Campo de texto livre</td>
              </tr>

              <tr>
                <td>Login</td>
                <td class="aliginCenter">Sim </td>
                <td class="aliginCenter">Sim</td>
                <td class="aliginCenter">Texto</td>
                <td class="aliginCenter">-</td>
                <td>Campo de texto livre</td>
              </tr>

              <tr>
                <td>Código do colaborador</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Texto</td>
                <td class="aliginCenter">-</td>
                <td>Campo de texto livre</td>
              </tr>

              <tr>
                <td>Celular</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Número</td>
                <td class="aliginCenter">11</td>
                <td>Campo deve ser preenchido com separadores no formato "(11) 99999-9999"</td>
              </tr>

              <tr>
                <td>CPF</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Número</td>
                <td class="aliginCenter">11</td>
                <td>Campo pode ser preenchido com ou sem separadores nos formatos "12345678910" ou "123.456.789-10"</td>
              </tr>

              <tr>
                <td>Departamento</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Texto</td>
                <td class="aliginCenter">-</td>
                <td>Campo de texto livre</td>
              </tr>

              <tr>
                <td>Unidade</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Texto</td>
                <td class="aliginCenter">-</td>
                <td>Campo de texto livre</td>
              </tr>

              <tr>
                <td>Filial</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Texto</td>
                <td class="aliginCenter">-</td>
                <td>Campo de texto livre</td>
              </tr>

              <tr>
                <td>Cargo</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Texto</td>
                <td class="aliginCenter">-</td>
                <td>Campo de texto livre</td>
              </tr>

              <tr>
                <td>E-mail do gestor imediato</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Texto</td>
                <td class="aliginCenter">-</td>
                <td>E-mail deve estar no formato padrão "nome@email.com..."</td>
              </tr>

              <tr>
                <td>Senha</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Texto</td>
                <td class="aliginCenter">-</td>
                <td>Campo deve possuir pelo menos 8 caracteres, pelo menos uma letra e pelo menos um número. Ao não preencher a senha, o usuário cadastrado receberá um e-mail de boas-vindas para que ela seja definida.</td>
              </tr>

              <tr>
                <td>Status</td>
                <td class="aliginCenter">Sim</td>
                <td class="aliginCenter">Não</td>
                <td class="aliginCenter">Texto</td>
                <td class="aliginCenter">-</td>
                <td>Preencher com o status do usuário "Ativo" ou "Inativo"</td>
              </tr>

            </table>
          </Modal.Body>
      </Modal>

      <div className="header-title-wrapper">
        <div className="header-title"> Importar usuários </div>
      </div>
      <div className="card">
        <div className="card-body template-card-body">
          <h3 className="instructions-title">Instruções</h3>
          <ul className="import-users-instructions">
            <li>
              <span>
                1) Baixar o Modelo CSV <CloudDownloadOutlinedIcon />
              </span>
            </li>
            <li>
              <span>
                2) Preencher o modelo, sendo cada linha um usuário (atenção para todos os campos)
              </span>
            </li>
            <li>
              <span>
                3) Salvar o modelo e fazer upload <CloudUploadOutlinedIcon />
              </span>
            </li>
            <li>
              <span>4) Enviar (se um ou mais campos falharem nenhum usuário será adicionado)</span>
            </li>
          </ul>

          {/* {permissionAddUserIntoGroup && (
            <FormControl style={{ flex: 1, marginBottom: 15 }} variant="outlined">
              <InputLabel
                style={{ backgroundColor: "white", top: displayGroups.length ? 0 : -5 }}
                id="mutiple-checkbox-label"
              >
                Selecionar grupos
              </InputLabel>
              <Select
                style={{ width: 400, marginBottom: 30 }}
                labelId="mutiple-checkbox-label"
                id="mutiple-checkbox"
                placeholder="Selecione um grupo"
                multiple
                value={displayGroups}
                onChange={handleChange}
                variant="outlined"
                renderValue={(selected) => selected.join(", ")}
              >
                {fixedList.map((item, index) => (
                  <MenuItem key={index} value={item.title}>
                    <Checkbox checked={displayGroups.indexOf(item.title) > -1} />
                    <ListItemText primary={item.title} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )} */}

          <div className="import-buttons">
            <Tooltip title="Baixar planilha" placement="top">
              <a href={getCsvLink()} download>
                <button variant="secondary" className="button-peop button-yellow mr-3 mb-2">
                  <CloudDownloadOutlinedIcon style={{ marginRight: 10 }} />
                  Modelo CSV
                </button>
              </a>
            </Tooltip>

            <label htmlFor="upload-button-file">
              <Tooltip title="Enviar planilha" placement="top">
                <div className="mb-2">
                  <div className="button-peop button-yellow">
                    <CloudUploadOutlinedIcon style={{ marginRight: 10 }} />
                    {uploadingData ? "Uploading..." : "Upload"}
                    <input
                      disabled={uploadingData}
                      accept=".csv"
                      id="upload-button-file"
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => uploadFile(e.target)}
                      onClick={() => clearInputPath()}
                    />
                  </div>
                </div>
              </Tooltip>
            </label>

            <label htmlFor="help-button">
              <Tooltip title="Abrir guia" placement="top">
                <div className="mb-2">
                  <div className="button-peop button-yellow"
                       style={{ marginLeft: 15 }}
                       onClick={()=>{
                        setHelpModal(true);
                       }}>
                    <HelpIcon style={{ marginRight: 10 }} />
                    Guia dos campos de importação

                  </div>
                </div>
              </Tooltip>
            </label>

          </div>
          <hr />
          <h3 className="result-title">{isChecking && "Resultado"}</h3>
          <div style={{ width: "100%" }}>
            {isChecking && (
              <div>
                {!feedback ? (
                  <Loading containerStyle={{ fontSize: 25 }}>
                    Processando arquivo, por favor aguarde...
                  </Loading>
                ) : (
                  <div>
                    <div style={{ flex: 1, alignItems: "center" }}>
                      <CheckCircleIcon
                        style={{ color: colors.green, fontSize: 50, marginRight: 5 }}
                      />
                      <span className="import-number">{feedback.success}</span>
                      linha{feedback.success > 1 && "s"} processada{feedback.success > 1 && "s"} com
                      sucesso
                    </div>
                    {feedback?.errors > 0 && (
                      <>
                        <div style={{ flex: 1, alignItems: "center" }}>
                          <ErrorIcon style={{ color: colors.red, fontSize: 50, marginRight: 5 }} />
                          <span className="import-number">{feedback?.errors}</span>
                          linha{feedback?.errors > 1 && "s"} processada{feedback?.errors > 1 && "s"}{" "}
                          com erro{feedback.errors > 1 && "s"}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
            {feedback?.feedback?.length > 0 && (
              <div style={{ height: 500, marginTop: 30 }}>
                <DataGrid rows={rows} columns={columns} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportUsers;
