import React, { useState, useEffect } from "react";
import { Col, Form, Button } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import moment from "moment";
import "moment/locale/pt-br.js";
import { DatePickerInput } from "rc-datepicker";
import { getSectionsList } from "../../services/NexiDigitalAPI";
import styles from "./Filtro.styles.module.scss";

const Filtro = ({ actionFilter, loading }) => {
  const [values, setValues] = useState({
    startDate: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  });

  const [sectionList, setSectionList] = useState([]);
  const [SectiFiltroWrapperonListName, setSectionListName] = useState([]);
  const appDetails = JSON.parse(localStorage.getItem("appDetails")) || null;

  function changeDateStart(jsDate) {
    setValues({
      ...values,
      startDate: moment(jsDate).format("YYYY-MM-DD"),
    });
  }

  function changeDateFinish(jsDate) {
    setValues({
      ...values,
      endDate: moment(jsDate).format("YYYY-MM-DD"),
    });
  }

  const submitFilter = () => {
    actionFilter(values);
  };

  function handleSectionSelect(event) {
    setValues({
      ...values,
      idSection: parseInt(event.target.value),
    });
  }

  useEffect(() => {
    async function getIdsSectionList() {
      const sectionListData = await getSectionsList(appDetails?.idAppType);
      setSectionList(sectionListData);
    }
    getIdsSectionList();
  }, []);

  return (
    <div className="containerFiltro" style={{ marginBottom: "-70px", display: "none" }}>
      <Form style={{ marginTop: 30, marginBottom: 30 }}>
        <Form.Row className="align-items-end">
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="dateStart">Data de Início</Form.Label>
            <DatePickerInput
              name="dateStart"
              locale="pt-br"
              onChange={changeDateStart}
              value={values.startDate}
            />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="dateStart">Data de Término</Form.Label>
            <DatePickerInput
              name="dateFinish"
              locale="pt-br"
              onChange={changeDateFinish}
              value={values.endDate}
            />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="titulo">Título</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Título"
              value={""}
              //onChange={e => setTitulo(e.target.value)}
            />
          </Col>

          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={""}
              //onChange={(e) => setActive(e.target.value)}
            >
              <option value={""}>Selecione</option>
              <option value={0}>Avaliação pendente</option>
              <option value={1}>Aprovado</option>
              <option value={2}>Cancelado</option>
            </Form.Control>
          </Col>

          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Group className="mb-0">
              <Button
                onClick={submitFilter}
                style={{
                  marginTop: 10,
                  backgroundColor: localStorage.getItem("secondaryColor"),
                }}
              >
                {loading ? (
                  <>Carregando...</>
                ) : (
                  <>
                    Aplicar filtro <FaFilter style={{ marginLeft: 5 }} />
                  </>
                )}
              </Button>
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    </div>
  );
};

export default Filtro;
