import "./styles.scss";
import React, { useState, useEffect, useCallback } from "react";
import { CircularProgress } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import Loading from "components/Loading";
import { Form } from "react-bootstrap";
import TableComponent from "../../../Components/TableComponent";
import SemImagemSmall from "../../../../../assets/images/sem-imagem-small.jpg";

const paginationUserComponentOptions = {
  rowsPerPageText: "Usuários por página",
  rangeSeparatorText: "de",
};

const StepComponentSecond = ({
  numberOfSteps = 0,
  index,
  label,
  isActive,
  onSubmit,
  setActiveStep,
  onChangePage,
  onChangeRowsPerPage,
  userPagination,
  loadingUsers,
  tableData,
  NoDataComponent,
  setSelectedRowsData = () => {},
  selectedRowsData = {},
  companyTarget = {},
  getPaginationRowsPerPageOptions = () => {},
}) => {
  const [searchText, setSearchText] = useState(userPagination.searchText || "");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [tableKey, setTableKey] = useState(0);

  const [currentPage, setCurrentPage] = useState(userPagination.currentPage || 1);
  const [rowsPerPage, setRowsPerPage] = useState(userPagination.limit || 10);
  const [offset, setOffset] = useState(userPagination.offset || 0);

  const resetState = () => {
    setSearchText("");
    setSelectedRows([]);
    setSelectAll(false);
    setSelectedRowsId([]);
    setTableKey(0);
    setCurrentPage(1);
    setRowsPerPage(10);
    setOffset(0);
  };

  useEffect(() => {
    const allCurrentRowsSelected =
      tableData.length > 0 &&
      tableData.every((row) =>
        selectedRows.some((selectedRow) => selectedRow.idUser === row.idUser),
      );
    setSelectAll(allCurrentRowsSelected);
  }, [tableData, selectedRows]);

  useEffect(() => {
    setSelectedRowsId((selectedRowsData?.selectedRows || []).map((row) => row.idUser));
  }, [selectedRowsData]);

  useEffect(() => {
    if (!isActive) {
      resetState();
    }
  }, [isActive]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  const handleSelectAllClick = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    if (newSelectAll) {
      const allCurrentRows = tableData.filter(
        (row) => !selectedRows.some((selectedRow) => selectedRow.idUser === row.idUser),
      );
      const updatedSelectedRows = [...selectedRows, ...allCurrentRows];

      setSelectedRows(updatedSelectedRows);
      setSelectedRowsData({
        selectedCount: updatedSelectedRows.length,
        selectedRows: updatedSelectedRows,
      });
      setSelectedRowsId(updatedSelectedRows.map((row) => row.idUser));
      const step = updatedSelectedRows.length > 0 ? (companyTarget?.idCompany ? 3 : 2) : 1;
      setActiveStep(step);
      setTableKey((prevKey) => prevKey + 1);
    } else {
      const updatedSelectedRows = selectedRows.filter(
        (selectedRow) => !tableData.some((row) => row.idUser === selectedRow.idUser),
      );

      setSelectedRows(updatedSelectedRows);
      setSelectedRowsData({
        selectedCount: updatedSelectedRows.length,
        selectedRows: updatedSelectedRows,
      });
      setSelectedRowsId(updatedSelectedRows.map((row) => row.idUser));
      const step = updatedSelectedRows.length > 0 ? (companyTarget?.idCompany ? 3 : 2) : 1;
      setActiveStep(step);
      setTableKey((prevKey) => prevKey + 1);
    }
  };

  const handleRowSelected = useCallback(
    (row) => {
      const isSelected = selectedRowsId.includes(row.idUser);
      let newSelectedRows = [];

      if (isSelected) {
        newSelectedRows = selectedRows.filter((selectedRow) => selectedRow.idUser !== row.idUser);
      } else {
        newSelectedRows = [...selectedRows, row];
      }

      setSelectedRows(newSelectedRows);
      setSelectedRowsData({
        selectedCount: newSelectedRows.length,
        selectedRows: newSelectedRows,
      });

      const newSelectedRowsId = newSelectedRows.map((row) => row.idUser);
      setSelectedRowsId(newSelectedRowsId);
      setTableKey((prevKey) => prevKey + 1);

      const step = newSelectedRowsId.length > 0 ? (companyTarget?.idCompany ? 3 : 2) : 1;
      setActiveStep(step);
    },
    [selectedRows, selectedRowsId, setSelectedRowsData, companyTarget?.idCompany, setActiveStep],
  );

  const columns = [
    {
      name: (
        <input
          type="checkbox"
          className="select-all-checkbox"
          checked={selectAll}
          onChange={handleSelectAllClick}
        />
      ),
      selector: "checkbox",
      center: true,
      width: "50px",
      cell: (data) => (
        <input
          type="checkbox"
          checked={selectedRowsId.includes(data.idUser)}
          onChange={() => handleRowSelected(data)}
          className="row-checkbox"
        />
      ),
    },
    {
      name: "Nome",
      selector: "name",
      sortable: true,
      center: true,
      wrap: true,
      grow: 0.3,
      style: { textAlign: "center" },
      cell: (data) => (
        <div className="profile-container">
          <img alt="avatar" className="avatar" src={data.photo || SemImagemSmall} />
          <p className="profile-name">{data.name || "-"}</p>
        </div>
      ),
    },
    {
      name: "E-mail",
      sortable: false,
      selector: "email",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (data) => <p>{data.email || "-"}</p>,
    },
    {
      name: "Cargo",
      sortable: false,
      selector: "role",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (data) => <p>{data.role || "-"}</p>,
    },
    {
      name: "Ativo",
      sortable: false,
      selector: "ativo",
      center: true,
      wrap: true,
      width: "200px",
      style: { textAlign: "center" },
      cell: (data) => <p>{data.ativo || "-"}</p>,
    },
  ];

  const handleChangePage = (page) => {
    setCurrentPage(page);
    const newOffset = (page - 1) * rowsPerPage;
    setOffset(newOffset);
    onChangePage(page);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    const newOffset = 0;
    setOffset(newOffset);
    onChangeRowsPerPage(newRowsPerPage, 1);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const updatedPagination = {
      ...userPagination,
      offset: 0,
      limit: rowsPerPage,
      searchText: searchText,
    };
    onSubmit(updatedPagination, false, searchText);
    setCurrentPage(1);
    setOffset(0);
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "56px",
        overflow: "hidden",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        color: "#3e4246",
      },
    },
    checkbox: {
      style: {
        backgroundColor: isActive ? "#6b48ff" : "#3e4246",
        borderRadius: "50%",
        border: `2px solid ${isActive ? "#6b48ff" : "#3e4246"}`,
      },
    },
  };

  return (
    <div className={`${!isActive ? "step-disabled" : ""} step-component-second`}>
      {index <= numberOfSteps - 1 && <hr style={{ margin: "21px 0" }} />}
      <div className="step-container-buttons">
        <div className="step-buttons">
          <div
            className="step-badge"
            style={{
              backgroundColor: isActive ? "#6b48ff" : "gray",
            }}
          >
            {index + 1}
          </div>
          <strong>{label}</strong>
          {isActive && (
            <Form
              onSubmit={handleSearchSubmit}
              className="input-group col-md-8 form-group search-container"
            >
              <div className="search-input-align">
                <SearchIcon className="search-icon" />
                <input
                  type="text"
                  className="form-control"
                  id="input-users"
                  aria-describedby="input-users"
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Pesquisar por nome"
                  value={searchText}
                />
              </div>
              <button className="search-button">
                {!loadingUsers && <span className="mr-1">Pesquisar</span>}
                {loadingUsers && <CircularProgress size={18} style={{ color: "white" }} />}
              </button>
            </Form>
          )}
        </div>
        {isActive && (
          <div className="selected-count-container">
            {selectedRowsData.selectedCount > 0 && (
              <span className="selected-count">
                {selectedRowsData.selectedCount}{" "}
                {selectedRowsData.selectedCount === 1
                  ? "usuário selecionado"
                  : "usuários selecionados"}
              </span>
            )}
          </div>
        )}
      </div>

      {isActive && (
        <div className="node-manager-import-table-wrapper mb-2">
          <TableComponent
            key={tableKey}
            header={false}
            extraTableProps={{
              keyField: "StepComponentSecondTable",
              columns: columns,
              data: tableData,
              className: "custom-table",
              progressComponent: (
                <Loading containerStyle={{ height: 400 }}>Carregando usuários...</Loading>
              ),
              noDataComponent: NoDataComponent,
              onChangePage: handleChangePage,
              onChangeRowsPerPage: handleChangeRowsPerPage,
              paginationTotalRows: userPagination.totalPage,
              progressPending: loadingUsers && tableData?.length === 0,
              paginationComponentOptions: paginationUserComponentOptions,
              paginationServer: true,
              noHeader: true,
              fixedHeader: true,
              pagination: true,
              pointerOnHover: true,
              highlightOnHover: true,
              selectableRowsHighlight: true,
              customStyles: customStyles,
              selectableRowSelected: (row) => selectedRowsId.includes(row.idUser),
              paginationRowsPerPageOptions: getPaginationRowsPerPageOptions(
                userPagination.totalPage,
              ),
              paginationDefaultPage: currentPage,
              paginationPerPage: rowsPerPage,
              paginationServerOptions: {
                persistSelectedOnSort: true,
                persistSelectedOnPageChange: true,
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default StepComponentSecond;
