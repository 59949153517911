import React, { useContext, useState } from "react";
import "../index.scss";
import { FiTrash2 } from "react-icons/fi";
import { CircularProgress } from "@material-ui/core";
import { editLearningBlockParameter } from "../../../../services/NexiDigitalAPI";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CatalogModal from "../CatalogModal";
import Switch from "react-bootstrap/esm/Switch";
import { FaRegEdit } from "react-icons/fa";

import SemImagemSmall from "../../../../assets/images/sem-imagem-small.jpg";
import { getDepthName } from "../../../../utils/Utils";
import { NodeManagerContext } from "../NodeManagerProvider";
import { statusTypes } from "containers/PeopleCraft/ContentPage/StatusTypes";
import { LMCreateContext } from "../../LearningManagementNew/Contexts/LMContext";

export const CatalogCard = ({ blockData }) => {
  const [element, setElement] = useState(blockData?.elements?.[0]);
  const [textSearch, setTextSearch] = useState(Boolean(Number(blockData?.parameters?.textSearch)));
  const [tagsFilter, setTagsFilter] = useState(Boolean(Number(blockData?.parameters?.tagsFilter)));
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showCatalogModal, setShowCatalogModal] = useState(false);
  const [isCatalogEdit, setIsCatalogEdit] = useState(false);

  const { nodeData } = useContext(LMCreateContext);

  const handleChangeBooleanParameter = (value, parameterName) => {
    const newValue = Boolean(value) ? "1" : "0";
    const editParameterData = {
      name: parameterName,
      value: newValue,
    };
    editLearningBlockParameter(blockData.idBlock, editParameterData)
      .then((res) => {
        console.log("Success edit parameter!");
      })
      .catch((err) => {
        console.log("Error edit parameter", err.request);
      });
  };

  const handleAddParameter = (nodeData) => {
    nodeData.childNodes = null;
    // default tags and text flag when adding
    if (!isCatalogEdit) {
      setTagsFilter(true);
      setTextSearch(true);
    }
    setElement(nodeData);
    setShowCatalogModal(false);
  };

  const handleRemoveParameter = () => {
    setLoadingDelete(true);
    const editNodeCodeData = {
      name: "nodeCode",
      value: null,
    };
    editLearningBlockParameter(blockData.idBlock, editNodeCodeData)
      .then((res) => {
        console.log("success delete");
        setElement(null);
      })
      .catch((err) => {
        console.log("error delete", err.request);
      })
      .finally(() => {
        setLoadingDelete(false);
      });
  };

  return (
    <div style={{ flex: 1, padding: "15px 0px" }}>
      {showCatalogModal && (
        <CatalogModal
          denyAction={() => setShowCatalogModal(false)}
          blockData={blockData}
          returnNodeData={handleAddParameter}
          isCatalogEdit={isCatalogEdit}
        />
      )}
      {Boolean(element) && (
        <div className="node-manager-content-item-wrapper-v2">
          <div className="node-manager-node-item-content-v2">
            <div className="node-manager-content-type-article-wrapper" style={{ paddingLeft: 10 }}>
              <img
                alt="article"
                className="node-manager-content-type-article-image"
                src={element?.image || SemImagemSmall}
              />
              <div
                className="node-manager-content-type-article-container-v2"
                style={{ marginRight: 10 }}
              >
                <div className="node-manager-content-type-article-title">{element?.title}</div>
                <div className="node-manager-type-text-container">
                  <span>
                    {getDepthName(Number(element?.depth || blockData?.parameters?.depth))}
                  </span>
                </div>
              </div>

              <div className="node-manager-switch-wrapper">
                <div className="node-manager-switch-container">
                  <div className="node-manager-switch">
                    <Switch
                      id={`textSearch-${blockData.idBlock}`}
                      color="primary"
                      checked={Boolean(textSearch)}
                      onChange={(e) => {
                        handleChangeBooleanParameter(e.target.checked, "textSearch");
                        setTextSearch(!Boolean(textSearch));
                      }}
                      disabled={[statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(
                        nodeData?.status,
                      )}
                    />
                    <label
                      className="form-label"
                      htmlFor={`textSearch-${blockData.idBlock}`}
                      style={{ marginBottom: 0 }}
                    >
                      Pesquisa
                    </label>
                  </div>
                  <div className="node-manager-switch">
                    <Switch
                      id={`tagsFilter-${blockData.idBlock}`}
                      color="primary"
                      checked={Boolean(tagsFilter)}
                      onChange={(e) => {
                        handleChangeBooleanParameter(e.target.checked, "tagsFilter");
                        setTagsFilter(!Boolean(tagsFilter));
                      }}
                      disabled={[statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(
                        nodeData?.status,
                      )}
                    />
                    <label
                      className="form-label"
                      htmlFor={`tagsFilter-${blockData.idBlock}`}
                      style={{ marginBottom: 0 }}
                    >
                      Tags
                    </label>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="node-manager-node-item-actions-container">
            <div
              className="node-manager-node-item-actions node-purple"
              onClick={() => {
                setIsCatalogEdit(true);
                setShowCatalogModal(true);
              }}
            >
              <span style={{ color: "black" }}>Editar</span>
              <FaRegEdit style={{ fontSize: 22 }} />
            </div>
            <div
              className={`node-manager-node-item-actions ${
                [statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData.status) &&
                nodeData?.nodeType !== "ORGANIZATION"
                  ? ""
                  : "node-red"
              }`}
              onClick={handleRemoveParameter}
              style={{
                pointerEvents:
                  ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData.status) &&
                    nodeData?.nodeType !== "ORGANIZATION") ||
                  loadingDelete
                    ? "none"
                    : "auto",
              }}
            >
              <span style={{ color: "black" }}>Remover</span>
              {loadingDelete ? (
                <CircularProgress size={22} />
              ) : (
                <FiTrash2 style={{ fontSize: 22 }} />
              )}
            </div>
          </div>
        </div>
      )}
      {!Boolean(element) && (
        <div className="node-manager-type-list-add-container">
          <button
            type="submit"
            disabled={
              [statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData.status) &&
              nodeData?.nodeType !== "ORGANIZATION"
            }
            className="btn content-page-button"
            style={{ backgroundColor: "#feac0e" }}
            onClick={() => {
              setIsCatalogEdit(false);
              setShowCatalogModal(true);
            }}
          >
            Selecionar Página de Conteúdo{" "}
            <AddCircleOutlineIcon style={{ marginLeft: 10, marginRight: 0, fontSize: 20 }} />
          </button>
        </div>
      )}
    </div>
  );
};
