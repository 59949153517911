import { modelQuestion } from "utils/Utils";
import { Form, FormGroup } from "react-bootstrap";
import AlternativesInputs from "../AlternativesInputs";
import React, { useEffect } from "react";

const CURRENT__KNOWLEDGE_OPTIONS = [
  modelQuestion.MULTI_OPTION, 
  modelQuestion.SINGLE_OPTION, 
  modelQuestion.TRUE_OR_FALSE,
  modelQuestion.CORRELATE
];

const ManagementKnowledge = ({
  typeQuestion,
  setTypeQuestion,
  questionOptions,
  setQuestionOptions,
  validateForm,
  setValidateForm,
}) => {
  useEffect(() => {
    if(!CURRENT__KNOWLEDGE_OPTIONS.includes(typeQuestion))
    setTypeQuestion(modelQuestion.MULTI_OPTION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeTypeQuestion = (checkType) => {
    if (typeQuestion === modelQuestion.TRUE_OR_FALSE && checkType !== modelQuestion.TRUE_OR_FALSE)
      setQuestionOptions([]);

    // if (checkType !== modelQuestion.CORRELATE && questionOptions.some(q => q.questionOptionAnswer)) {
    //   setQuestionOptions(questionOptions.map(q => ({ description: q.description, isCorrect: q.isCorrect, idQuestionOption: q.idQuestionOption })));
    // }

    setTypeQuestion(checkType);
  };

  return (
    <>
      <div className="form-group form-group-block-02">
        <div className="card">
          <div className="card-header template-card-header">Tipo de questão</div>
          <div className="card-body template-card-body">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <p>Escolha o tipo de questão de múltipla escolha:</p>
                <FormGroup className="questionType" controlId="formBasicCheckbox">
                  <Form.Check
                    inline
                    label="Múltiplas corretas"
                    name="group1"
                    type={"radio"}
                    id={`inline-1`}
                    checked={typeQuestion === modelQuestion.MULTI_OPTION ? true : false}
                    onChange={(e) =>
                      changeTypeQuestion(
                        e.target.checked ? modelQuestion.MULTI_OPTION : modelQuestion.SINGLE_OPTION,
                      )
                    }
                  />
                  <Form.Check
                    inline
                    label="Resposta única correta"
                    name="group1"
                    type={"radio"}
                    id={`inline-2`}
                    checked={typeQuestion === modelQuestion.SINGLE_OPTION ? true : false}
                    onChange={(e) =>
                      changeTypeQuestion(
                        e.target.checked ? modelQuestion.SINGLE_OPTION : modelQuestion.MULTI_OPTION,
                      )
                    }
                  />
                  <Form.Check
                    inline
                    label="Verdadeiro ou Falso"
                    name="group1"
                    type={"radio"}
                    id={`inline-3`}
                    checked={typeQuestion === modelQuestion.TRUE_OR_FALSE ? true : false}
                    onChange={(e) =>
                      changeTypeQuestion(
                        e.target.checked
                          ? modelQuestion.TRUE_OR_FALSE
                          : modelQuestion.SINGLE_OPTION,
                      )
                    }
                  />
                  <Form.Check
                    inline
                    label="Correlacionar"
                    name="group1"
                    type={"radio"}
                    id={`inline-4`}
                    checked={typeQuestion === modelQuestion.CORRELATE ? true : false}
                    onChange={(e) =>
                      changeTypeQuestion(
                        e.target.checked ? modelQuestion.CORRELATE : modelQuestion.SINGLE_OPTION,
                      )
                    }
                  />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlternativesInputs
        typeQuestion={typeQuestion}
        questionOptions={questionOptions}
        setQuestionOptions={setQuestionOptions}
        validateForm={validateForm}
        setValidateForm={setValidateForm}
      />
    </>
  );
};

export default ManagementKnowledge;
