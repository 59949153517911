import { useEffect } from "react";
export const useKeyUp = (callback, keys, inputActive, canvas) => {
  const onKeyUp = (event) => {
    const object = canvas.getActiveObject();

    if (!object) {
      return;
    }

    if (object && object.isEditing) {
      return;
    }

    const wasAnyKeyPressed = keys.some((key) => event.key === key);
    if (wasAnyKeyPressed && !inputActive) {
      event.preventDefault();
      callback(event);
    }
  };
  useEffect(() => {
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keyup", onKeyUp);
    };
  }, [onKeyUp]);
};

export const useKeyDown = (callback, keys, inputActive, canvas) => {
  const onKeyDown = (event) => {
    const object = canvas.getActiveObject();

    if (!object) {
      return;
    }

    if (object && object.isEditing) {
      return;
    }

    const wasAnyKeyPressed = keys.some((key) => event.key === key);
    const currentKey = keys.filter((key) => event.key === key)[0];

    if (wasAnyKeyPressed && !inputActive) {
      event.preventDefault();
      callback(event, currentKey);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);
};

// Example usage:
// useKeyUp(() => {
//   someCallback();
// }, ["Escape"]);
