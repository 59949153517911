import React, { useState, useContext, useRef } from "react";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import _ from "underscore";
import moment from "moment";
import { Form } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import { DatePickerInput } from "rc-datepicker";
import SaveIcon from "@material-ui/icons/Save";
import { updateContent } from "../../services/NexiDigitalAPI";

import AccessTable from "../AccessTable";
import { AuthContext } from "../../context/AuthContext";
import CmsAutor from "../../components/CmsAutor";
import CmsTitulo from "../../components/CmsTitulo";
import CmsTags from "../../components/CmsTags";
import CmsFile from "../../components/CmsFile";
import { CircularProgress } from "@material-ui/core";
import CancelButton from "components/CancelButton";
import Workload from "components/Workload";
import CmsContentLabel from "components/CmsContentLabel";
import ContentImageUpload from "components/ContentImageUpload";

const idVideo = "2";
const idAudio = "1";

const MultiFormat = (props) => {
  const { data, contentTags, permissionApproveContent, isNode, returnNodeData, closeModal } = props;
  // Lista de variáveis em ordem alfabética (para bater com a lista recebida do json)
  const [active, setActive] = useState(data.active || 0);
  const [autor, setAutor] = useState(data.author || "");
  const [autorEmail, setAutorEmail] = useState(data.authorEmail || "");
  const [backConfirmation, setBackConfirmation] = useState(data.backConfirmation || "");
  const [backgroundColor, setBackgroundColor] = useState(data.backgroundColor || "");
  const [content, setContent] = useState(data.content || "");
  const [contentLocation, setContentLocation] = useState(data.contentLocation || "");
  const [description, setDescription] = useState(data.description || "");
  const [endDate, setEndDate] = useState(data.endDate === null ? null : new Date(data.endDate));
  const [idContentGroup, setIdContentGroup] = useState(data.idContentGroup);
  const [idContentItem, setIdContentItem] = useState(data.idContentItem);
  const [idContentType, setIdContentType] = useState(data.idContentType);
  const [idSection, setIdSection] = useState(data.idSection);
  const [imageStyle, setImageStyle] = useState(data.imageStyle || "");
  const [initDate, setInitDate] = useState(
    data.initDate === null ? new Date() : new Date(data.initDate),
  );
  const [logoutConfirmation, setLogoutConfirmation] = useState(data.logoutConfirmation);
  const [referenceLink, setReferenceLink] = useState(data.referenceLink || "");
  const [showBottomMenu, setShowBottomMenu] = useState(data.showBottomMenu);
  const [showTopBar, setShowTopBar] = useState(data.showTopBar);
  const [titulo, setTitulo] = useState(data.text || "");
  const [workload, setWorkload] = useState(Number(data.duration));

  const { loginData } = useContext(AuthContext);

  // variáveis de auxílio
  let tagsDeConteudo = [];
  for (let i = 0; i < contentTags.length; i++) {
    tagsDeConteudo.push({ id: i, name: contentTags[i].tag });
  }

  const [temEndDate, setTemEndDate] = useState(endDate ? true : false);
  const [tags, setTags] = useState(tagsDeConteudo);
  const [label, setLabel] = useState(data.label || "Arquivo");
  const [loadingUpdateContent, setLoadingUpdateContent] = useState(false);
  
  // Imagem
  const contentImageUploadRef = useRef(null);

  /*********** */
  const [videoDuration, setVideoDuration] = useState(0);

  const [audioDuration, setAudioDuration] = useState(0);

  /**** */

  const sendData = async (event, newImageLocation) => {
    
    if (event) {
      event.preventDefault();
    }

    const publishDate = moment().format("YYYY-MM-DD hh:mm:ss.SSS");
    const userId = loginData.userid; // Usuário logado
    const tagList = _.map(tags, function (t) {
      return t.name;
    }).join(";");

    //ADICIONANDO REGRA PARA QUANDO O USUÁRIO CLICAR PARA SALVAR NO SALVAR DO FORMULÁRIO.
    setLoadingUpdateContent(true);
    const uploadImageLocation = await contentImageUploadRef.current.uploadImagem();

    if(uploadImageLocation != null){
      newImageLocation = uploadImageLocation;
    }

    let obj = {
      idContentItem: idContentItem,
      idContentGroup: idContentGroup,
      image: newImageLocation,
      text: titulo,
      description: description,
      contentLocation: contentLocation,
      publishDate: publishDate,
      initDate: moment(initDate).format("YYYY-MM-DD hh:mm:ss.SSS"),
      endDate: endDate === null ? null : moment(endDate).format("YYYY-MM-DD hh:mm:ss.SSS"),
      content: content,
      idUserPublisher: parseInt(userId, 10),
      author: autor,
      authorEmail: autorEmail,
      active: parseInt(active),
      idContentType: idContentType,
      tags: tagList,
      backConfirmation: backConfirmation === 1 ? 1 : 0,
      backgroundColor: backgroundColor,
      idSection: idSection,
      imageStyle: imageStyle,
      logoutConfirmation: logoutConfirmation,
      referenceLink: referenceLink,
      showBottomMenu: showBottomMenu,
      showTopBar: showTopBar,
      duration: workload,
      label: label,
    };
    if (idContentType === idAudio) {
      obj.duration = audioDuration;
    } else if (idContentType === idVideo) {
      obj.duration = videoDuration;
    }
    
    updateContent(obj)
      .then((res) => {
        if (res.data.success) {
          if (isNode && returnNodeData) {
            returnNodeData({ ...obj, idContentItem: data.idContentItem });
          } else {
            props.history.goBack();
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoadingUpdateContent(false);
      });
  };

  const usaDataFim = (op) => {
    setTemEndDate(op);

    if (op === false) {
      setEndDate(null);
    }
  };

  const checkContent = (id) => {
    let hash = window.location.hash;
    hash = hash.replace(/.*\?/, "?");
    const queryParams = new URLSearchParams(hash);
    const term = queryParams.get("content");

    return parseInt(term) === id;
  };

  const getTitleName = () => {
    switch (idContentType) {
      case 25:
        return "PDF List";
      default:
        return "Arquivo";
    }
  };

  return (
    <div>
      {!isNode && (
        <div className="header-title-wrapper">
          <div className="header-title">
            {getTitleName()}
            {checkContent(19) && "(Multiformato)"}
          </div>
        </div>
      )}
      <div className="Video-form">
        <form onSubmit={sendData}>
          <CmsTitulo
            titulo={titulo}
            setTitulo={setTitulo}
            description={description}
            setDescription={setDescription}
          />
          <CmsTags setTags={setTags} contentTags={contentTags} />
          <CmsContentLabel label={label} setLabel={setLabel} />
            <div className="card-header template-card-header">Imagem</div>
            <ContentImageUpload reference={contentImageUploadRef} data={data} sendData={sendData} defaultPrefixName={"content"}/>
          <CmsFile contentLocation={contentLocation} setContentLocation={setContentLocation} />
          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">Carga horária</div>
              <div className="card-body template-card-body">
                <div className="form-row">
                  <Workload workload={workload} setWorkload={setWorkload} hideTitle hideHour />
                </div>
              </div>
            </div>
          </div>
          <CmsAutor
            autor={autor}
            setAutor={setAutor}
            autorEmail={autorEmail}
            setAutorEmail={setAutorEmail}
          />
          <div className="form-group">
            <div className="card overflow-visible">
              <div className="card-header template-card-header">Publicação</div>
              <div className="card-body template-card-body">
                <div className="form-row">
                  {permissionApproveContent && (
                    <div className="col-md-3">
                      <Form.Group className="access-table-select">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          value={active}
                          onChange={(e) => setActive(e.target.value)}
                        >
                          <option value={0}>Avaliação pendente</option>
                          <option value={1}>Aprovado</option>
                          <option value={2}>Cancelado</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                  )}
                  <div className="col-md-3">
                    <Form.Group className="access-table-select">
                      <Form.Label>Data de publicação</Form.Label>
                      <DatePickerInput
                        name="initDate"
                        locale="pt-br"
                        onChange={(date) => setInitDate(date)}
                        value={initDate}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <div className="access-table-select form-group">
                      <label className="form-label" htmlFor="tira-do-ar" style={{ margin: "-3 0" }}>
                        Agendar término publicação?
                      </label>
                      <br />
                      <Switch
                        className="form-group"
                        id="tira-do-ar"
                        color="primary"
                        checked={temEndDate}
                        onChange={(e) => usaDataFim(e.target.checked)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </div>
                  {temEndDate ? (
                    <div className="col-md-3">
                      <Form.Group className="access-table-select">
                        <Form.Label>Data de retirada do conteúdo</Form.Label>
                        <DatePickerInput
                          name="endDate"
                          placeholderText="Sem prazo"
                          locale="pt-br"
                          onChange={(date) => setEndDate(date)}
                          value={endDate}
                        />
                      </Form.Group>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header template-card-header">Permissões de acesso ao conteúdo</div>
            <div className="card-body template-card-body">
              <AccessTable id={data.idContentItem} />
            </div>
          </div>
          <br />
          <div className="row" style={{ padding: 5 }}>
            <div className="col-md-8"></div>
            <div className="col-md-4">
              <button type="submit" className="btn btn-primary">
                <SaveIcon /> Salvar{" "}
                {loadingUpdateContent && (
                  <CircularProgress
                    size={18}
                    style={{ margin: "0px 0px -3px 10px", color: "white" }}
                  />
                )}
              </button>
              <CancelButton idContentItem={idContentItem} isNode={isNode} closeModal={closeModal} />
            </div>
          </div>
        </form>
        <br />
      </div>
    </div>
  );
};

export default withRouter(MultiFormat);
