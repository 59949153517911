import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import GenericDashboardCard from "../GenericDashboardCard";
import { getReportListtopcontentaccess } from "../../../../../services/NexiDigitalAPI";
import { Pie } from "react-chartjs-2";
import { style } from "dom-helpers";

const ContentAccess = (props) => {
  const title = "Conteúdo mais acessado";
  const link = "?tabs=conteudo&rel=0";

  const [loading, setLoading] = useState(true);
  const [apiInfo, setApiInfo] = useState({
    infoLabel: [],
    infoContent: [],
  });
  const refChart = useRef(null);

  const actionFilter = async (values) => {
    setLoading(true);
    const responseData = await getReportListtopcontentaccess(values);

    responseData.sort(compare);
    const selectedData = responseData.slice(0, 5);

    const _infoLabel = selectedData.map((item) => {
      return item.contentName;
    });
    const _infoContent = selectedData.map((item) => {
      return item.countAcess;
    });

    setApiInfo({
      infoLabel: _infoLabel,
      infoContent: _infoContent,
    });
    setLoading(false);
  };

  const options = {
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          boxHeight: 8,
          boxWidth: 8,
        },
      },
    },
  };

  function compare(a, b) {
    if (a.countAcess > b.countAcess) {
      return -1;
    }
    if (a.countAcess < b.countAcess) {
      return 1;
    }
    return 0;
  }

  const colorArray = ["#642D8F", "#2D99FF", "#FF6C40", "#FFE700", "#2CD8C5"];

  const info = {
    labels: apiInfo.infoLabel,
    datasets: [
      {
        data: apiInfo.infoContent,
        backgroundColor: colorArray,
        borderColor: ["#756bb1"],
        borderWidth: 1,
        pointBackgroundColor: "#fff",
        datalabels: {
          color: ["#fff"],
        },
      },
    ],
  };

  const hasData = Boolean(apiInfo.infoContent.length);
  const noData = <div className={styles.nenhumDado}>Nenhum dado disponível</div>;

  const data = (
    <div className={styles.pieWrapper}>
      {!hasData ? noData : <Pie ref={refChart} width={250} data={info} options={options} />}
    </div>
  );

  return (
    <GenericDashboardCard
      filter={props.filter}
      title={title}
      data={data}
      actionFilter={actionFilter}
      link={link}
      loading={loading}
      classItem="contentAccess"
    />
  );
};

export default ContentAccess;
