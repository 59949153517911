import React, { FC } from "react";
import "./styles.scss";
import { FormControlLabel, Grid, Radio, Tooltip, Typography } from "@material-ui/core";
import { NotificationConfig, Options, Section } from "../..";

type Props = {
  notifications: NotificationConfig[];
  section: Section;
  updateOption: (
    event: React.ChangeEvent<HTMLInputElement>,
    item: NotificationConfig,
    section: string,
  ) => void;
};
const Radios: FC<Props> = ({ notifications, updateOption, section }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, item: NotificationConfig) => {
    updateOption(event, { ...item, status: event.target.value }, section);
  };

  return (
    <div className="container-radios">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography className="header">Obrigatório</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className="header">Opcional</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className="header">Desabilitado</Typography>
        </Grid>
      </Grid>

      {notifications.map((notification) => (
        <>
          <Grid container spacing={2} className="radio-row" key={notification.type}>
            <Grid item xs={4}>
              <Tooltip title="Essa opção não pode ser editada por ser uma definição geral do sistema"
               disableHoverListener ={!notification.mandatory}  >
                <FormControlLabel
                  value={Options.MANDATORY}
                  control={
                    <Radio
                      onChange={(e) => handleChange(e, notification)}
                      checked={notification.status === Options.MANDATORY}
                      name={notification.type}
                      disabled={!!notification.mandatory}
                    />
                  }
                  label={""}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip 
                title="Essa opção não pode ser editada por ser uma definição geral do sistema" 
                disableHoverListener ={!notification.mandatory} 
               >
                <FormControlLabel
                  value={Options.OPTIONAL}
                  label={""}
                  control={ 
                  <Radio
                    onChange={(e) => handleChange(e, notification)}
                    checked={notification.status === Options.OPTIONAL}
                    name={notification.type}
                    disabled={!!notification.mandatory}
                  />
                }
                />
             </Tooltip>
             
            </Grid>
            <Grid item xs={4}>
            <Tooltip 
                title="Essa opção não pode ser editada por ser uma definição geral do sistema" 
                disableHoverListener ={!notification.mandatory} 
               >
                <FormControlLabel
                  value={Options.DEACTIVATED}
                  label={""}
                  control={ 
                    <Radio
                    onChange={(e) => handleChange(e, notification)}
                    checked={notification.status === Options.DEACTIVATED}
                    name={notification.type}
                    disabled={!!notification.mandatory}
                  />
                }
                />
             </Tooltip>
             
            </Grid>
            <Grid item xs={12} className="full-width">
              <Typography>{notification.description}</Typography>
            </Grid>
          </Grid>
        </>
      ))}
    </div>
  );
};

export default Radios;
