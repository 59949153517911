import React, { useContext, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@material-ui/icons/Close";

import ContentPage from "../ContentPage/ContentPage.jsx";

function NodeModal({ title, denyAction = false, returnNodeData }) {
  const [showModal, setShowModal] = useState(true);

  const handleNodeData = (nodeData) => {
    returnNodeData(nodeData);
    setShowModal(false);
  };

  function handleDeny() {
    if (denyAction) {
      denyAction();
      return;
    }
    setShowModal(false);
  }

  return (
    <Modal
      show={showModal}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="xl"
      onHide={() => {}}
    >
      <div className="modal-support-blur modal-support-blur-warning" style={{ borderRadius: 10 }} />
      <div
        onClick={() => handleDeny()}
        className="modal-warning-close-container"
        style={{ margin: 15, padding: 0, backgroundColor: "#6b48ff" }}
      >
        <CloseIcon style={{ color: "white" }} className="modal-warning-close" />
      </div>
      <div className="modal-principal-warning" style={{ minHeight: 400 }}>
        <Modal.Title style={{ textAlign: "center", fontSize: 18, marginTop: 15 }}>
          {title}
        </Modal.Title>
        <div className="modal-body-warning" style={{ padding: 20 }}>
          <ContentPage isModal closeModal={handleDeny} returnNodeData={handleNodeData} />
        </div>
      </div>
    </Modal>
  );
}

export default NodeModal;
