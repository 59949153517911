import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import { DatePicker, ConfigProvider } from "antd";
import { Col, Form, Button } from "react-bootstrap";
import "../Components/Charts/styles.scss";
import "antd/dist/antd.css";
import ptBR from "antd/es/locale/pt_BR";
import { FaFilter } from "react-icons/fa";
import ChartsDayWeek from "../Components/Charts/ChartsDayWeek";
import { GetUtilizationPerWeekDay } from "../../../services/NexiDigitalAPI";
const { RangePicker } = DatePicker;

const ReportDayWeek = ({ rel }) => {
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [firstElem, setFirstElem] = useState({
    date: moment(new Date()).subtract(6, "days").format("DD/MM/YYYY"),
    day: moment(new Date()).subtract(6, "days").format("dddd"),
  });
  const [lastElem, setLastElem] = useState({
    date: moment(new Date()).format("DD/MM/YYYY"),
    day: moment(new Date()).format("dddd"),
  });
  const [dates, setDates] = useState([]);
  const [value, setValue] = useState([
    moment(firstElem.date, ["DD/MM/YYYY"]),
    moment(new Date(), ["DD/MM/YYYY"]),
  ]);
  const [data, setData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return current && current > moment();
    }

    const tooLate = dates[0] && current && current.diff(dates[0], "days") > 6;
    return tooLate;
  };

  const memorizedCallback = useCallback(async () => {
    if (!firstElem.date || !lastElem.date) {
      setData([0, 0, 0, 0, 0, 0, 0]);
      setLoadingFilter(false);
      return false;
    }
    setLoadingFilter(true);

    const filterParams = {
      startDate: moment(firstElem.date).format("YYYY/MM/DD"),
      endDate: moment(lastElem.date).format("YYYY/MM/DD"),
    };
    let responseData = await GetUtilizationPerWeekDay(filterParams);
    if (responseData) {
      let _values = [0, 0, 0, 0, 0, 0, 0];
      let Period = responseData;
      let index = 0;
      for (let elem in Period) {
        index = elem;
        if (elem === "0") {
          index = 7;
        }
        _values[index - 1] = Period[elem].totalTime;
      }
      setData(_values);
      setLoadingFilter(false);
      setLoading(false);
    } else {
      setLastElem({
        date: null,
        day: null,
      });
      setLastElem({
        date: null,
        day: null,
      });
    }
  }, [firstElem, lastElem]);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      memorizedCallback();
    }
    return () => (isMounted = false);
  }, [memorizedCallback]);

  function handleFilter(val = false) {
    setLoading(true);

    setLoadingFilter(true);
    let _value = val || value;
    if (_value && _value[0] && _value[1]) {
      setFirstElem({
        date: moment(_value[0]).format("DD/MM/YYYY"),
        day: moment(_value[0]).format("dddd"),
      });
      setLastElem({
        date: moment(_value[1]).format("DD/MM/YYYY"),
        day: moment(_value[1]).format("dddd"),
      });
    } else {
      setFirstElem({
        date: null,
        day: null,
      });
      setLastElem({
        date: null,
        day: null,
      });
    }
  }
  return (
    <div className="Reports">
      <div
        className="report-filter-container"
        style={{
          maxHeight: 250,
          border: "1px solid lightgray",
          overflow: "inherit",
        }}
      >
        <Form style={{ marginTop: 30, marginBottom: 30 }}>
          <Form.Row className="align-items-end">
            <Col xs="14" md="10" lg="4">
              <Form.Label htmlFor="dateStart">Intervalo de busca</Form.Label>

              <ConfigProvider locale={ptBR}>
                <RangePicker
                  value={value}
                  disabledDate={disabledDate}
                  onCalendarChange={(val) => {
                    if (
                      val &&
                      val.length === 2 &&
                      moment(val[1]).diff(moment(val[0]), "days") > 6
                    ) {
                      val[1] = moment(val[0]).add(6, "days");
                    }
                    setDates(val);
                    if (!val) {
                      setFirstElem({
                        date: null,
                        day: null,
                      });
                      setLastElem({
                        date: null,
                        day: null,
                      });
                    } else {
                      handleFilter(val);
                    }
                  }}
                  defaultValue={[
                    moment(firstElem.date, ["DD/MM/YYYY"]),
                    moment(lastElem.date, ["DD/MM/YYYY"]),
                  ]}
                  onChange={(val) => {
                    setValue(val);
                  }}
                  format={["DD/MM/YYYY", "DD/MM/YY", "MM/YY"]}
                  size={"large"}
                />
              </ConfigProvider>
            </Col>

            <Col xs="12" md="4" lg="2">
              <Form.Group className="mb-0">
                <Button
                  onClick={() => {
                    handleFilter();
                  }}
                  style={{
                    marginTop: 10,
                    backgroundColor: localStorage.getItem("secondaryColor"),
                  }}
                >
                  {loading ? (
                    <>Carregando...</>
                  ) : (
                    <>
                      Aplicar filtro
                      <FaFilter style={{ marginLeft: 5 }} />
                    </>
                  )}
                </Button>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
      </div>
      {loadingFilter ? (
        <div className="chart-wrapper">
          <div className="loader loader-big" />
          <span>Aplicando filtro...</span>
        </div>
      ) : (
        <ChartsDayWeek
          data={data}
          Period={
            firstElem.day && lastElem.day
              ? `Período: ${firstElem.day} ${firstElem.date}  à  ${lastElem.day} ${lastElem.date} `
              : `Período de busca não definido`
          }
        />
      )}
    </div>
  );
};

export default ReportDayWeek;
