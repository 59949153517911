import React, { useState } from "react";
import Filtro from "./Filtro";
import Table from "./Table";
import ReportAcordeon from "../../../Components/ReportAcordeon";
import { reportChatContent } from "../../../../../services/NexiDigitalAPI";

const Content = ({ index, expanded }) => {
  const name = "Histórico de mensagens";
  const description = "Apresenta o histórico das mensagens enviadas nas salas de chat.";

  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);

  const actionFilter = async (values) => {
    setLoading(true);
    const data = await reportChatContent(values);
    setDados(data);
    setLoading(false);
  };

  const filter = <Filtro actionFilter={actionFilter} loading={loading} />;

  const result = <Table dados={dados} loading={loading} />;

  return (
    <ReportAcordeon
      name={name}
      description={description}
      filter={filter}
      result={result}
      index={index}
      expanded={expanded}
    />
  );
};

export default Content;
