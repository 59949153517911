import React, { useState, useEffect } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import "./style.modules.scss";
import {
  assignLearningProduct,
  getCompanyLearningProducts,
  getLearningProducts,
  getMenuData,
  unassignLearningProduct,
} from "../../../services/NexiDigitalAPI";
import Loading from "../../../components/Loading";
import { BsCart2 } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { RxMagnifyingGlass } from "react-icons/rx";
import { MdOutlineClose } from "react-icons/md";
import ProductsEmptyState from "./ProductsEmptyState";
import CompaniesModal from "../../../components/CompaniesModal/CompaniesModal";

import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory } from "react-router-dom";
import { InputGroup, FormControl } from "react-bootstrap";

const ProductsManagement = () => {
  const [productList, setProductList] = useState([]);
  const [assignedProductList, setAssignedProductList] = useState([]);
  const [isLoadingProductList, setIsLoadingProductList] = useState(true);
  const [isLoadingCompanyProductList, setIsLoadingCompanyProductList] = useState(true);
  const [isAssigningProduct, setIsAssigningProduct] = useState(false);
  const [productsFilter, setProductsFilter] = useState("");
  const [selectedCompany, setSelectedCompany] = useState({});
  const adminLevel = getMenuData()?.admin;

  const history = useHistory();

  const _getLearningProducts = () => {
    setIsLoadingProductList(true);
    getLearningProducts()
      .then((res) => {
        setProductList(res.data);
      })
      .catch((err) => {
        console.log("err", err.request);
      })
      .finally(() => {
        setIsLoadingProductList(false);
      });
  };

  const _getCompanyLearningProducts = () => {
    setIsLoadingCompanyProductList(true);
    getCompanyLearningProducts(selectedCompany?.idCompany || getMenuData().idCompany)
      .then((res) => {
        setAssignedProductList(res.data);
      })
      .catch((err) => {
        console.log("assignedList err", err.request);
      })
      .finally(() => {
        setIsLoadingCompanyProductList(false);
      });
  };

  const _assignLearningProduct = (productItem) => {
    setIsAssigningProduct(true);
    const assignData = {
      idProduct: productItem.idProduct,
      idTargetCompany: selectedCompany?.idCompany || getMenuData().idCompany,
    };
    assignLearningProduct(assignData)
      .then((res) => {
        setAssignedProductList([
          ...assignedProductList,
          {
            idProduct: productItem.idProduct,
            idCompany: selectedCompany?.idCompany || getMenuData().idCompany,
            name: productItem.name,
            description: productItem.description,
            nodeCode: productItem.nodeCode,
          },
        ]);
      })
      .catch((err) => {
        console.log("err assignData", err.request);
      })
      .finally(() => {
        setIsAssigningProduct(false);
      });
  };

  const _unassignLearningProduct = (productItem) => {
    const filteredList = assignedProductList.filter(
      (obj) => obj.idProduct !== productItem.idProduct,
    );
    setAssignedProductList(filteredList);
    unassignLearningProduct(
      productItem.idProduct,
      selectedCompany?.idCompany || getMenuData().idCompany,
    )
      .then((res) => {})
      .catch((err) => {
        console.log("err unassignData", err.request);
      });
  };

  useEffect(() => {
    _getLearningProducts();
  }, []);

  useEffect(() => {
    selectedCompany && _getCompanyLearningProducts();
  }, [selectedCompany]);

  return (
    <div className="products-management-wrapper">
      <div className="header-title-wrapper">
        <div className="header-title">
          <button
            className="btnGoBack"
            onClick={() => {
              history.goBack();
            }}
          >
            <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
          </button>
          Gestão de Produtos
        </div>
      </div>
      <div className="products-management-container-wrapper">
        <div className="products-management-container">
          <div className="products-management-panel">
            <div className="products-management-header">
              <CompaniesModal
                showInput={adminLevel >= 1}
                setSelectedCompany={setSelectedCompany}
                selectedCompany={selectedCompany}
              />
            </div>
            <div className="products-management-panel-content">
              <span className="products-management-title" style={{ marginBottom: 20 }}>
                Produtos
                <span style={{ color: "#00c267", marginLeft: 5 }}>
                  adicionados para a empresa
                  <BsFillCheckCircleFill style={{ width: 18, height: 18, marginLeft: 5 }} />
                </span>
              </span>

              {isLoadingCompanyProductList ? (
                <Loading containerStyle={{ height: "100%" }}>
                  Carregando produtos da empresa...
                </Loading>
              ) : assignedProductList.length === 0 && !isAssigningProduct ? (
                <ProductsEmptyState />
              ) : (
                assignedProductList.map((productItem, index) => (
                  <div className="products-management-card" key={index}>
                    <div className="products-management-image-placeholder">
                      <BsCart2 style={{ height: 30, width: 30 }} />
                    </div>
                    <div>
                      <div
                        style={{
                          color: "#484848",
                          fontSize: 16,
                          fontWeight: "bold",
                          marginBottom: 5,
                        }}
                      >
                        {productItem.name}
                      </div>
                      <div className="products-management-card-description">
                        {productItem.description}
                      </div>
                    </div>
                    <div
                      className="products-management-card-button red"
                      onClick={() => _unassignLearningProduct(productItem)}
                    >
                      <MdOutlineClose style={{ width: 16, height: 16, marginRight: 5 }} />
                      Remover
                    </div>
                  </div>
                ))
              )}
              {isAssigningProduct && (
                <Skeleton
                  variant="rect"
                  height={100}
                  style={{ borderRadius: 10, marginBottom: 20 }}
                />
              )}
            </div>
          </div>
          <div className="products-management-panel">
            <div className="products-management-header">
              <span className="products-management-title mr-3">Disponível para a sua empresa</span>
              <InputGroup>
                <InputGroup.Text
                  style={{
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: 20,
                    padding: "6px 8px 6px 12px",
                  }}
                >
                  <RxMagnifyingGlass style={{ width: 20, height: 20 }} />
                </InputGroup.Text>
                <FormControl
                  onChange={(e) => setProductsFilter(e.target.value)}
                  placeholder="Pesquisar por título, descrição ou código"
                  style={{ borderTopRightRadius: 20, borderBottomRightRadius: 20 }}
                />
              </InputGroup>
            </div>
            <div className="products-management-panel-content" style={{ paddingTop: 70 }}>
              {isLoadingProductList ? (
                <Loading containerStyle={{ height: "100%" }}>Carregando produtos...</Loading>
              ) : productList.length === 0 ? (
                <ProductsEmptyState text="Ainda não existem produtos cadastrados." />
              ) : (
                productList
                  .filter((productItem) =>
                    productItem.name?.toLowerCase().includes(productsFilter.toLowerCase()),
                  )
                  .map((productItem, index) => (
                    <div className="products-management-card" key={index}>
                      <div className="products-management-image-placeholder">
                        <BsCart2 style={{ height: 30, width: 30 }} />
                      </div>
                      <div>
                        <div
                          style={{
                            color: "#484848",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 5,
                          }}
                        >
                          {productItem.name}
                        </div>
                        <div className="products-management-card-description">
                          {productItem.description}
                        </div>
                      </div>
                      <div
                        className="products-management-card-button green"
                        onClick={() => _assignLearningProduct(productItem)}
                        style={{
                          pointerEvents:
                            isAssigningProduct || isLoadingCompanyProductList ? "none" : "auto",
                        }}
                      >
                        <FaPlus style={{ width: 16, height: 16, marginRight: 5 }} />
                        Adicionar
                      </div>
                    </div>
                  ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsManagement;
