import "./style.modules.scss";
import React, { useState, useEffect, useCallback, useRef, useContext } from "react";

import { RouteComponentProps, withRouter } from "react-router-dom";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import { getExternalCourses } from "../../../services/NexiDigitalAPI";

import Table from "./components/Table";
import Filter from "./components/Filter";
import ExternalCourseInclusion from "./components/ExternalCourseInclusion";

import { PaginationInit, StatusExternalCourse } from "./constants";
import { AuthContext } from "context/AuthContext";

interface Props extends RouteComponentProps {
  showHeader?: boolean;
  showExternalCourseConfigPanel?: boolean;
  showExportReportBtn?: boolean;
  onlyStudentsFromManager?: boolean;
}

const ExternalCourseManager = ({
  history,
  showHeader = true,
  showExternalCourseConfigPanel = true,
  showExportReportBtn = true,
  onlyStudentsFromManager = false,
}: Props) => {
  const { loginData } = useContext(AuthContext);
  
  const [loading, setLoading] = useState(true);
  const componentMounted = useRef(false);

  const [pagination, setPagination] = useState(PaginationInit);

  const callExternalCouses = useCallback(() => {
    const concatenateStatus = () => {
      // Definir os status possíveis
      const allStatuses = Object.values(StatusExternalCourse);

      // Se ALL ou nenhum status for selecionado, retornar todos os status
      if (pagination.status.ALL || !pagination.status) {
        return allStatuses.join(",");
      }

      // Filtrar os status ativos
      const activeStatuses = allStatuses.filter((statusKey) => pagination.status[statusKey]);

      // Retornar a string concatenada
      return activeStatuses.join(",");
    };

    setLoading(true);

    const params = {
      offset: pagination.offset,
      limit: pagination.limit,
      statuses: concatenateStatus(),
      searchString: pagination.searchString ? pagination.searchString : undefined,
      idManager: onlyStudentsFromManager ? loginData.userid : undefined
    };

    getExternalCourses(params)
      .then((res) => {
        if (componentMounted.current) {
          setPagination((prev) => ({
            ...prev,
            totalRecords: res.data.totalRecords,
            data: res.data.result,
          }));
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [loginData.userid, onlyStudentsFromManager, pagination.limit, pagination.offset, pagination.searchString, pagination.status]);

  useEffect(() => {
    componentMounted.current = true;

    if (loading) {
      callExternalCouses();
    }

    return () => {
      componentMounted.current = false;
    };
  }, [pagination, callExternalCouses, loading]);

  const actionFilter = (values) => {
    setLoading(true);
    setPagination(values);
  };

  const resetActionFilter = (values) => {
    setPagination(PaginationInit);
    setLoading(true);
  };

  return (
    <div className="ptcExternalCourseManager">
      {showHeader && (
        <div className="header-title-wrapper">
          <div className="header-title">
            <button
              className="btnGoBack"
              onClick={() => {
                history.goBack();
              }}
            >
              <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
            </button>
            Cursos externos
          </div>
        </div>
      )}

      <div className="ptcManagerBase">
        {showExternalCourseConfigPanel && <ExternalCourseInclusion />}

        <Filter actionFilter={actionFilter} loading={loading} pag={pagination} showDownloadBtn={showExportReportBtn} />

        <Table
          actionFilter={actionFilter}
          loading={loading}
          setLoading={setLoading}
          pag={pagination}
          resetActionFilter={resetActionFilter}
        />
      </div>
    </div>
  );
};

export default withRouter<Props, any>(ExternalCourseManager);
