import React, { useEffect, useContext, useState } from "react";
import { nodeTypes } from "../../../containers/PeopleCraft/NodeManagerV2/NodeTypes";

import { editLearningBlockParameter, getLearningBlock } from "services/NexiDigitalAPI";
import { getParametersTranslation } from "../../../utils/Utils";
import Loading from "components/Loading";

import { Form } from "react-bootstrap";
import { Switch } from "antd";
import { LMCreateContext } from "../../../containers/PeopleCraft/LearningManagementNew/Contexts/LMContext";
import PlaceholderParameters from "containers/PeopleCraft/LearningManagementNew/Components/DynamicParameters/components/PlaceholderParameters";

const getDrawerSubtitleByBlockType = (blockType) => {
  switch (blockType) {
    case nodeTypes.TITLE:
      return "Título";
    case nodeTypes.TEXT:
      return "Texto/Descrição";
    case nodeTypes.LIST:
      return "Lista de conteúdo";
    case nodeTypes.MASTER_BANNER:
      return "Master Banner";
    case nodeTypes.CAROUSEL:
      return "Carrossel";
    case nodeTypes.GRID:
      return "Grade";
    case nodeTypes.CATALOG:
      return "Catálogo";
    case nodeTypes.CALENDAR:
      return "Agenda";
    case nodeTypes.IN_PROGRESS:
      return "Em Andamento";
    case nodeTypes.CAMPAIGN:
      return "Banner promocional";
    default:
      return blockType || "";
  }
};

export const BlockList = ({ }) => {
  const { nodeData, setNodeData, cardIdBlock } = useContext(LMCreateContext);
  const [loadingBlockParameters, setLoadingBlockParameters] = useState(false);
  const [blockData, setBlockData] = useState(null);
  const [blockDataError, setBlockDataError] = useState(null);
  const getBlockData = (idBlock) => {
    console.log(idBlock);
    setLoadingBlockParameters(true);
    setBlockDataError(false);
    getLearningBlock(idBlock)
      .then((res) => {
        console.log(res.data);
        setBlockData(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        setBlockData(true);
      })
      .finally(() => {
        setLoadingBlockParameters(false);
      });
  };

  const changeBlockDepth = (idBlock, depth) => {
    const blockIndex = nodeData?.blocks?.findIndex((node) => node.idBlock === idBlock);
    const newNodeData = { ...nodeData };

    if (blockIndex >= 0) {
      newNodeData.blocks[blockIndex].parameters.depth = Number(depth) || "";
      setNodeData(newNodeData);
    }
  };

  const editParameter = (newData, currentdata) => {
    const parameterData = { name: currentdata.name, value: newData };

    editLearningBlockParameter(currentdata.idBlock, parameterData).catch((err) => {
      console.log("error editing", err);
    });
    if (currentdata.name === "depth") {
      changeBlockDepth(currentdata.idBlock, newData);
    }
  };

  useEffect(() => {
    if (cardIdBlock) {
      getBlockData(cardIdBlock);
    }
  }, [cardIdBlock]);

  return (
    <>
      <div className="node-manager-drawer-container" style={{ width: "100%" }}>
        {loadingBlockParameters ? (
          // <Loading center>Carregando parametros...</Loading>
          <PlaceholderParameters number={10}/>
        ) : blockDataError ? (
          <div className="node-manager-error">Erro ao carregar dados do bloco</div>
        ) : (
          blockData && (
            <>
              <div className="node-manager-drawer-header">
                {getDrawerSubtitleByBlockType(blockData?.blockType)}
              </div>
              {blockData?.parameters
                ?.filter((parameter) => parameter.hidden !== 1)
                ?.map((parameter, index) => (
                  <div className="node-manager-drawer-item" key={index}>
                    {parameter.label}
                    {parameter.type === "LIST" ? (
                      <Form.Control
                        as="select"
                        defaultValue={parameter.value}
                        onChange={(e) => {
                          editParameter(e.target.value, parameter);
                        }}
                      >
                        {parameter?.restrictions?.split("|").map((restriction, indexR) => (
                          <option value={restriction} key={indexR}>
                            {getParametersTranslation(restriction)}
                          </option>
                        ))}
                      </Form.Control>
                    ) : parameter.type === "BOOLEAN" ? (
                      <div style={{ with: 70 }}>
                        <Switch
                          color="primary"
                          className="custom-switch"
                          defaultChecked={Boolean(Number(parameter.value))}
                          onChange={(checked) => editParameter(checked ? "1" : "0", parameter)}
                        />
                      </div>
                    ) : !isNaN(Number(parameter.restrictions)) &&
                      Number(parameter.restrictions) > 200 ? (
                      <textarea
                        defaultValue={parameter.value}
                        onBlur={(e) => {
                          const valueTextArea = e.target.value.length > 0 ? e.target.value : null;

                          editParameter(valueTextArea, parameter);
                        }}
                        maxLength={
                          Number(parameter.restrictions)
                            ? Number(parameter.restrictions)
                            : undefined
                        }
                        rows={5}
                        className="form-control"
                        type="text"
                      />
                    ) : (
                      <input
                        defaultValue={parameter.value}
                        onBlur={(e) => {
                          const valueTextArea = e.target.value.length > 0 ? e.target.value : null;

                          editParameter(valueTextArea, parameter);
                        }}
                        maxLength={
                          Number(parameter.restrictions)
                            ? Number(parameter.restrictions)
                            : undefined
                        }
                        rows={5}
                        className="form-control"
                        type="text"
                      />
                    )}
                  </div>
                ))}
            </>
          )
        )}
      </div>
    </>
  );
};
