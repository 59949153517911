import React from "react";
import { Form, Card, Image, Row, Col } from "react-bootstrap";
import styles from "./ActiveAccount/styles.module.scss";
import "./Obrigado.css";
import BreadCrumbPhase4 from "../assets/images/breadcrumb04.png";

export default function Obrigado() {
  return (
    <div className={styles.activeAccountPage}>
      <Row className={styles.rowCentered}>
        <Col lg="6" md="6" xs="12">
          <Card>
            <Card.Body>
              <Form>
                <Form.Group>
                  <Form.Text className={styles.titleText}>Ativação de Contrato Realizada</Form.Text>
                  <center>
                    <Image src={BreadCrumbPhase4} className={styles.imageStyle} fluid />
                  </center>
                  <Form.Text className={styles.normalText}>
                    <br></br>
                    <h5>Parabéns! Seu contrato foi ativado com sucesso!</h5>
                    <br></br>
                    Você receberá um email para a cadastramento de sua senha, o que permitirá que
                    você realize o acesso ao App BeDigital e também ao Painel de Controle.<br></br>
                    Ao receber o email, siga os passos informados e realize o acesso ao App e ao
                    painel de controle com os links abaixo.<br></br>
                    <br></br>
                    <b>BeDigital App</b>
                    <br></br>
                    <a href="http://bedigital.net.br">http://bedigital.net.br</a> <br></br>
                    <br></br>
                    <b>Be Digital Painel de Controle</b>
                    <br></br>
                    <a href="https://admin.bedigital.net.br">https://admin.bedigital.net.br</a>
                    <br></br>
                  </Form.Text>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
