import React, { useState } from "react";
import styles from "./styles.module.scss";
import GenericDashboardCard from "../GenericDashboardCard";
import { getReportListlestcontentaccess } from "../../../../../services/NexiDigitalAPI";

const LessContentAccess = ({ filter }) => {
  const title = "Conteúdo menos acessado";
  const link = "?tabs=conteudo&rel=3";

  const [loading, setLoading] = useState(true);
  const [dados, setDados] = useState([]);

  const actionFilter = async (values) => {
    setLoading(true);
    const responseData = await getReportListlestcontentaccess(values);
    const selectedData = responseData.slice(0, 5);
    setDados(selectedData);
    setLoading(false);
  };

  const hasData = Boolean(dados.length);
  const noData = <div className={styles.nenhumDado}>Nenhum dado disponível</div>;

  const data = (
    <div>
      <div className={styles.dataRow}>
        <div className={styles.dataColunmTitle}>Nome</div>
        <div className={styles.dataColunmTitle}>Quantidade</div>
      </div>
      {!hasData
        ? noData
        : dados.map((item, index) => {
            return (
              <div className={styles.dataRow} key={index}>
                <div className={styles.dataColunmInfo}>{item.contentName}</div>
                <div className={styles.dataColunmInfo}>{item.countAcess}</div>
              </div>
            );
          })}
    </div>
  );

  return (
    <GenericDashboardCard
      title={title}
      data={data}
      actionFilter={actionFilter}
      link={link}
      loading={loading}
      filter={filter}
    />
  );
};

export default LessContentAccess;
