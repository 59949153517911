import "./styles.modules.scss";

import React, { useState, useRef, useEffect } from "react";
import { withStyles } from "@material-ui/styles";

import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "14px",
  },
}))(Tooltip);

const Chevron = (props) => {
  return (
    <svg
      className={props.className}
      height={props.height}
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path
        // fill={props.fill}
        d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
      />
    </svg>
  );
};

function Accordion(props) {
  const [openInit, setOpenInit] = useState(true);
  const [setErro, setErroState] = useState(null);
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content = useRef(null);

  const callErro = () => {
    if (setActive === "") {
      setActiveState("active");
      setHeightState(`${content.current.scrollHeight}px`);
      setRotateState("accordion__icon rotate");
    }

    scrollToBottom();
    setErroState(true);
  };

  const boolErro = (mod, ind) => {
    return props.erroStatus && props.erroStatus[mod] && props.indice === ind && setErro === null;
  };

  useEffect(() => {
    if (props.indice === 1 && openInit) {
      toggleAccordion();
      setOpenInit(false);
    }

    if (boolErro("title", 1)) {
      callErro();
    } else if (boolErro("authorEmail", 2)) {
      callErro();
    }
  }, [props.erroStatus]);

  const scrollToBottom = () => {
    const anchor = content.current;

    setTimeout(() => {
      // anchor.scrollTop = anchor.scrollHeight;
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    }, 1000 * 0.3);
  };

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(setActive === "active" ? "0px" : `${content.current.scrollHeight}px`);
    setRotateState(setActive === "active" ? "accordion__icon" : "accordion__icon rotate");

    scrollToBottom();
  }

  return (
    <div className="KEaccordion__section" id={`ke_accordion__section_${props.indice}`}>
      <div className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <p className="accordion__title">
          {props.title}

          {props.disableEdit && (
            <CustomTooltip
              title={
                "Caso a avaliação já tenha sido iniciada em alguma turma as configurações não poderão ser editadas"
              }
              placement="top"
            >
              <InfoIcon
                style={{ fontSize: 20, cursor: "pointer", marginLeft: 10 }}
                className="KEAccordionInfoIcon"
              />
            </CustomTooltip>
          )}
        </p>
        <Chevron className={`${setRotate}`} width={10} />
      </div>
      <div ref={content} style={{ maxHeight: `${setHeight}` }} className="accordion__content">
        <div className="accordion__text">{props.children}</div>
      </div>
    </div>
  );
}

export default Accordion;
