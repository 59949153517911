import React, { useState, useEffect } from "react";

// Page UI and icons
import { Col, Form } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import "rc-datepicker/lib/style.css";
import { getMenuData, getTrainingsApi, getlistClass } from "../../../../../services/NexiDigitalAPI";
import FiltroWrapper from "../../../Components/FiltroWrapper";
import styles from "./styles.module.scss";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import CompaniesModal from "../../../../../components/CompaniesModal/CompaniesModal";


const Filtro = ({ actionFilter, loading, expanded, downloadEvent }) => {
  const initialStateSelect = [
    {
      value: "all",
      label: "Todas",
    },
  ];
  const adminLevel = getMenuData()?.admin;
  const initialFormStates = {
    idTeam: "all",
    nodeCode: "",
    idCompany: getMenuData().idCompany,
    offset: 0,
    limit: 10,
  };
  const [selectedCompany, setSelectedCompany] = useState({});
  const [formData, setFormData] = useState(initialFormStates);
  const [trainings, setTrainings] = useState([]);
  const [learnings, setLearnings] = useState(initialStateSelect);

  async function getTrainings() {
    try {
      const trainingRes = await getTrainingsApi(
        selectedCompany.idCompany || getMenuData().idCompany,
      );

      if (trainingRes.length) {
        const formatedData = trainingRes.map(({ nodeCode, title }) => {
          return {
            value: nodeCode,
            label: title,
          };
        });
        setTrainings(formatedData);
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  }

  async function getLearnings(nodeCode) {
    try {
      const learningRes = await getlistClass(nodeCode);

      if (learningRes.length) {
        const formatedData = learningRes.map(({ idTeam, name }) => {
          return {
            value: idTeam,
            label: name,
          };
        });
        return setLearnings(formatedData);
      }
      return setLearnings([]);
    } catch (err) {
      console.log(err);
    } finally {
    }
  }

  function handleFilter() {
    if (selectedCompany) {
      return actionFilter({
        ...formData,
        idTeam: formData.idTeam === "all" ? undefined : formData.idTeam,
        idCompany: selectedCompany.idCompany === "all" ? undefined : selectedCompany.idCompany,
      });
    }
  }

  const handleChangeSelect = async ({ target }) => {
    const { name, value } = target;
    if (name === "trainings") {
      setFormData((prevData) => ({
        ...prevData,
        nodeCode: value,
      }));
      if (value) {
        getLearnings({ nodeCode: value });
      }
    }
    if (name === "learnings") {
      setFormData((prevData) => ({
        ...prevData,
        idTeam: value,
      }));
    }
  };

  function isAdminSecondDegree() {
    return adminLevel >= 2;
  }

  function updateStates() {
    setFormData(initialFormStates);
    getTrainings();
    setLearnings([]);
  }

  useEffect(() => {
    if(expanded){
      !trainings.length && getTrainings();
      updateStates();
    }

  }, [selectedCompany,expanded ]);

  return (
    <FiltroWrapper>
      <Form style={{ marginTop: 30, marginBottom: 30 }}>
        <Form.Row className="align-items-end">
          <Form.Row className="col-md-12" style={{ padding: 5 }}>
            <CompaniesModal
              showAllCompaniesOption
              showInput={isAdminSecondDegree()}
              setSelectedCompany={setSelectedCompany}
              selectedCompany={selectedCompany}
            />
          </Form.Row>
          <Col xs="12" md="4" lg="3" className={styles.formSpace}>
            <Form.Label htmlFor="trainings">Treinamento*</Form.Label>
            <Form.Control
              id="trainings"
              name="trainings"
              as="select"
              value={formData.nodeCode}
              onChange={handleChangeSelect}
            >
              {!formData.nodeCode && <option value={null}>Selecionar</option>}
              {trainings.map(({ label, value }) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col xs="12" md="4" lg="3" className={styles.formSpace}>
            <Form.Label htmlFor="learnings">Turma</Form.Label>
            <Form.Control
              id="learnings"
              name="learnings"
              as="select"
              value={formData.idTeam}
              onChange={handleChangeSelect}
            >
              <option value={"all"}>Todas</option>
              {learnings.map(({ label, value }) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Group className="mb-0">
              <ButtonWithLoading
                disabled={!formData.nodeCode}
                onClickHandler={handleFilter}
                loading={loading}
                text={
                  <>
                    Aplicar filtro <FaFilter style={{ marginLeft: 5 }} />{" "}
                  </>
                }
                style={{ width: "100%" }}
              />
            </Form.Group>
          </Col>
          <Col xs="12" md="4" lg="3" className={styles.formSpace}>
            <Form.Group className="mb-0">
              <ButtonWithLoading
                disabled={!formData.nodeCode}
                onClickHandler={downloadEvent}
                loading={loading}
                text={<>Download da planilha</>}
                style={{ width: "100%", backgroundColor: "#0d6efd", borderColor: "#0d6efd" }}
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    </FiltroWrapper>
  );
};

export default Filtro;
