import React, { useState } from "react";
import "moment/locale/pt-br.js";
import Filtro from "./Filtro";
import Table from "./Table";
import ReportAcordeon from "../../../Components/ReportAcordeon";
import { getCompanyRegister, getDownloadReport, postDownloadReport } from "../../../../../services/NexiDigitalAPI";

const CompanyRegister = ({ index, expanded: _expanded }) => {
  const idReport = 8;
  const name = "Relatório Empresas";
  const description = "Detalhes sobre relatório de empresas.";
  const [expanded, setExpanded] = useState(_expanded);
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [requestFilters, setRequestFilters] = useState({});

  const onClickExpanded = (value) => {
    setExpanded(value);
  };

  const actionFilter = async (values) => {
    if(expanded){
      setLoading(true);

      const filter = { ...requestFilters, ...values };
      setRequestFilters(filter);

      getCompanyRegister(filter)
        .then((response) => {
          const responseData = response.data;
          setTotalRecords(responseData.totalRecords);
          setDados(responseData.result);
        })
        .finally(() => setLoading(false));
    }
  };

  const downloadEvent = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    const { startDate, finishDate, idCompany } = requestFilters;
    await postDownloadReport(idReport, { startDate, finishDate, idCompany });
    await requestDownloadReport();
  };

  const requestDownloadReport = async () => {
      const response = await getDownloadReport(idReport);
      const { status, fileUrl } = response?.data || {};

      if (status === "Waiting") {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return requestDownloadReport();
      }

      if (status === "Done" && fileUrl) {
        getFileReport(fileUrl);
        setLoading(false);
      }
  };

  const getFileReport = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.click();
  };

  const filter = <Filtro actionFilter={actionFilter} loading={loading} expanded={expanded} downloadEvent={downloadEvent} />;

  const result = <Table dados={dados} loading={loading} actionFilter={actionFilter} totalRecords={totalRecords} />;

  return (
    <ReportAcordeon
      onClickExpanded={onClickExpanded}
      name={name}
      description={description}
      filter={filter}
      result={result}
      index={index}
      expanded={expanded}
    />
  );
};

export default CompanyRegister;
