// Basic
import React, { useContext, useState, useEffect } from "react";

// UI and icons
import "./../Cockpit.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { contentGroups, getMenuData, loginSecondary } from "../../services/NexiDigitalAPI";
import PersonIcon from "@material-ui/icons/Person";
import { FiUserPlus } from "react-icons/fi";
import Button from "@material-ui/core/Button";
import { Col } from "react-bootstrap";
import {
  addProfileApiSecondary,
  getProfilesSecondary,
} from "../../services/NexiDigitalAPISecondary";
import Loading from "../../components/Loading";
import CompaniesModal from "../../components/CompaniesModal/CompaniesModal";

export default function Cockpit() {
  const { loginData } = useContext(AuthContext);
  const primaryColor = loginData?.primaryColor;
  const [profiles, setProfiles] = useState([]);
  const [profileName, setProfileName] = useState("");
  const [loadingProfiles, setLoadingProfiles] = useState(true);
  const [loadingAddProfiles, setLoadingAddProfiles] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});

  const adminLevel = getMenuData()?.admin;

  async function _getProfilesSecondary() {
    setLoadingProfiles(true);
    const profilesResponse = await getProfilesSecondary().finally(() => {
      setLoadingProfiles(false);
    });
    setProfiles(profilesResponse);
  }

  function handleChangeProfileName(e) {
    const name = e.target.value;
    if (name && name !== undefined && name != null && name !== "") setProfileName(e.target.value);
    else setProfileName("");
  }

  const addProfileForm = async () => {
    if (profileName && profileName !== undefined && profileName != null && profileName !== "") {
      setLoadingAddProfiles(true);
      const result = await addProfileApiSecondary(profileName).finally(() => {
        setLoadingAddProfiles(false);
      });

      if (result) {
        setProfileName("");
        _getProfilesSecondary();
      }
    }
  };

  const _loginSecondary = () => {
    setLoadingProfiles(true);

    const idApp = getMenuData()?.idApp;
    const idChannel = getMenuData()?.idChannel;
    const idAppType = getMenuData()?.idAppType;

    const loginSecondaryData = {
      idCompany: selectedCompany.idCompany,
      idApp,
      idChannel,
      idAppType,
    };
    loginSecondary(loginSecondaryData)
      .then((res) => {
        localStorage.setItem("token-secondary", res.data.token);
        _getProfilesSecondary();
      })
      .catch((err) => {
        console.log("err", err);
        setLoadingProfiles(false);
        setProfiles([]);
      });
  };

  useEffect(() => {
    contentGroups()
      .then((res) => {
        let array = res.data.contentGroups;
        localStorage.setItem("contentGroups", JSON.stringify(array));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    selectedCompany?.idCompany && _loginSecondary();
  }, [selectedCompany]);

  return (
    <div className="Cockpit">
      <div className="header-title-wrapper">
        <div className="header-title"> Perfis e Recursos </div>
      </div>
      <hr />
      <div style={{ maxWidth: 620 }}>
        <CompaniesModal
          showAllCompaniesOption
          showInput
          setSelectedCompany={setSelectedCompany}
          selectedCompany={selectedCompany}
          disableSelection={adminLevel <= 1}
        />
      </div>
      <hr />
      {selectedCompany?.idCompany === null || selectedCompany?.idCompany === undefined ? null : (
        <>
          {adminLevel >= 1 && (
            <div>
              <form className="formAddProfile" noValidate autoComplete="off">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="labelProfiileName">
                      Nome
                    </span>
                  </div>
                  <Col xs={8} sm={6} style={{ paddingLeft: 0 }}>
                    <input
                      type="text"
                      className="form-control"
                      value={profileName}
                      onChange={(e) => handleChangeProfileName(e)}
                      style={{ maxWidth: 550 }}
                    />
                  </Col>
                  <Col xs={12} sm={4} style={{ paddingLeft: 0 }}>
                    <Button
                      onClick={addProfileForm}
                      className="blast-button"
                      style={{ backgroundColor: primaryColor, marginRight: "10px", color: "#FFF" }}
                    >
                      <FiUserPlus color="white" size={22} style={{ marginRight: 5 }} />
                      {loadingAddProfiles ? "Adicionando Perfil..." : "Adicionar Perfil"}
                    </Button>
                  </Col>
                </div>
              </form>
            </div>
          )}

          <br />

          <div className="cockpit-cards-container">
            {loadingProfiles ? (
              <Loading>Carregando perfis</Loading>
            ) : profiles.length === 0 ? (
              <div style={{ color: "gray" }}>Nenhum perfil criado para essa empresa.</div>
            ) : (
              profiles?.map((item, index) => {
                return (
                  <Link
                    to={`/updateprofile/${item.idProfile}`}
                    className="cockpit-card"
                    key={index}
                  >
                    <div className="cockpit-card-header" style={{ backgroundColor: primaryColor }}>
                      <PersonIcon style={{ color: "white", fontSize: 50 }} />
                    </div>
                    <div className="cockpit-card-content">{item.name}</div>
                  </Link>
                );
              })
            )}
          </div>
        </>
      )}
    </div>
  );
}
