import axios from "axios";
import moment from "moment";

const api_key = "AIzaSyD4MJDu22MFh0BlX9JEYI2N0heahEI2S2Q"; // substituir pela api key deles

export const getVideoDuration = (idVideo) => {
  const urlGetVideoInfoYouTube = `https://www.googleapis.com/youtube/v3/videos?id=${idVideo.toString()}&part=snippet,contentDetails&fields=items(etag,id,contentDetails(duration))&key=${api_key}`;
  return axios
    .get(urlGetVideoInfoYouTube)
    .then(({ data }) => {
      let newDuration = 0;
      data.items.forEach((item) => {
        newDuration += moment.duration(item.contentDetails.duration).asSeconds();
      });
      return newDuration;
    })
    .catch((e) => {
      return false;
    });
};
