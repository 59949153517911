import jwtDecode from "jwt-decode";
import { getMenuData, getToken } from "../services/NexiDigitalAPI";

import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AssessmentIcon from "@material-ui/icons/Assessment";
import BallotIcon from "@material-ui/icons/Ballot";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CreateIcon from "@material-ui/icons/Create";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListIcon from "@material-ui/icons/List";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import PublishIcon from "@material-ui/icons/Publish";
import SettingsIcon from "@material-ui/icons/Settings";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import StoreIcon from "@material-ui/icons/Store";
import { BsBookHalf, BsCart2, BsFillFilterCircleFill, BsUiChecks } from "react-icons/bs";
import { GoPeople } from "react-icons/go";
import { LiaCertificateSolid } from "react-icons/lia";
import { PiUserSwitchThin } from "react-icons/pi";
import { LuBookUp2 } from "react-icons/lu";

export function getMenuIconTypes(idSectionType) {
  switch (idSectionType) {
    case 26:
      return BallotIcon;
    case 27:
      return PersonIcon;
    case 28:
      return AssessmentIcon;
    case 29:
      return DashboardIcon;
    case 30:
      return SettingsIcon;
    case 35:
      return PublishIcon;
    case 37:
      return AccountBalanceIcon;
    case 39:
      return PeopleIcon;
    case 40:
      return CalendarTodayIcon;
    case 41:
      return StoreIcon;
    case 42:
      return SportsEsportsIcon;
    case 46:
      return CreateIcon;
    case 47:
      return LockOpenIcon;
    case 50:
      return ListIcon;
    case 62:
      return BsBookHalf;
    case 63:
      return BsFillFilterCircleFill;
    case 64:
      return BsCart2;
    case 68:
      return LiaCertificateSolid;
    case 69:
      return GoPeople;
    case 70:
      return BsUiChecks;
    case 71:
      return PiUserSwitchThin;
    case 73:
      return LuBookUp2;
    default:
      return NewReleasesIcon;
  }
}

export function desabilitaConsoleLog() {
  console.log = function () {};
  console.warn = function () {};
  console.info = function () {};
}

function parseJwtToken(token) {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
}

export function getProfileInfo() {
  let token = {};
  try {
    token = jwtDecode(getToken());
  } catch {
    console.log("error on token get");
  }
  return token;
}

export { parseJwtToken };

export function transformText(text) {
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

export function capitalizeSentence(str) {
  return str.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
}

export function capitalizeFirstLetter(str) {
  if (typeof str !== "string") return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function convertToCapitalFirst(inputString) {
  if (typeof inputString !== "string") {
    return inputString;
  }

  return inputString.toLowerCase().charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
}

export function urlExtension(url) {
  return url.split(".").pop().split(/\#|\?/)[0];
}

export function getMenuImage(image) {
  let allMenu = getMenuData();

  if (!allMenu) {
    return [];
  }
  allMenu = allMenu.menu;
  let getUrlImage = "";

  allMenu.forEach((element) => {
    element.menuOption.forEach((elem) => {
      if (transformText(elem.text) === transformText(image)) {
        getUrlImage = elem.image;
      }
    });
  });
  return getUrlImage;
}

export function getTypeName(typeId) {
  switch (typeId) {
    case 1:
      return "Audio";
    case 2:
      return "Video";
    case 3:
      return "Post";
    case 4:
      return "Site";
    case 5:
      return "SinglePage";
    case 6:
      return "YoutubeLive";
    case 7:
      return "YTPlaylist";
    case 8:
      return "Meeting";
    case 14:
      return "Multi Formato";
    case 15:
      return "Jornadinha";
    case 18:
      return "Arquivo";
    case 19:
      return "Multi Formato";
    case 22:
      return "Jornada IMPACT";
    case 25:
      return "Arquivo";
    case 26:
      return "SCORM";
    case "tags":
      return "Tags";
    case "zoom":
      return "Meeting";
    case "grupos":
      return "Grupos";
    case "gestao-de-cor-e-logo":
      return "GestaoCorELogo";
    case "notificacoes":
      return "Notificacoes";
    case "menu-lateral":
      return "MenuLateral";
    case "termos-da-plataforma":
      return "TermosDaPlataforma";
    default:
      return "-";
  }
}

export function getMenuRoutes() {
  const HIDDEN_LIST = [
    72, // acesso suporte
  ];

  const menuOptionList = JSON.parse(localStorage.getItem("menu-option"));

  if (menuOptionList && menuOptionList.length > 0) {
    return menuOptionList.filter((menu) => !HIDDEN_LIST.includes(menu.idSectionType));
  } else {
    return [];
  }
}

function removeAccents(text) {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function getMenuOption(key) {
  if (!key) {
    return;
  }
  const menuOptionList = getMenuRoutes();

  const objCurrentMenu = menuOptionList.find(
    (menu) => removeAccents(menu.text).toLowerCase() === key.toLowerCase() // Use the name of the menu-option that is coming to your module
  );
  if (!!Object.keys(objCurrentMenu).length) {
    return objCurrentMenu;
  }
  return null;
}

export function getPermissionsPage(module, page) {
  try {
    const objModule = getMenuOption(module);
    return objModule
      ? objModule.resource.some(
          (menu) => removeAccents(menu.name).toLowerCase() === page.toLowerCase()
        )
      : false;
  } catch (error) {
    return false;
  }
}

export function getDepthName(depth) {
  switch (depth) {
    case 1:
      return "1 Nível abaixo";
    case 2:
      return "2 Níveis abaixo";
    case 3:
      return "3 Níveis abaixo";
    case 4:
      return "4 Níveis abaixo";
    case 5:
      return "5 Níveis abaixo";
    default:
      return "Todos os níveis abaixo";
  }
}

export function getDesignBySectionType(idType) {
  let menuData = JSON.parse(localStorage.getItem("menu-data"));

  // return empty object if theres no data
  if (!menuData) {
    return {};
  }
  if (!menuData.design) {
    return {};
  }

  let section = menuData.design.filter((obj) => {
    return obj.idSectionType === parseInt(idType);
  })[0];

  // return empty object if theres no data on section
  if (!section) {
    return {};
  }

  return section.format;
}

export function getColors(type) {
  let design = getDesignBySectionType(1);
  if (design) {
    switch (type) {
      case "image":
        if (design.backgroundImage) {
          return design.backgroundImage;
        } else {
          return {
            image: "https://blast.etc.br/common/app0000001/section/backGroudBlast.jpg",
            showImage: true,
          };
        }
      case "footer":
        if (design.footer) {
          return design.footer;
        } else {
          return {
            color: "#933939",
            showfooter: false,
          };
        }

      case "header":
        if (design.header) {
          return design.header;
        } else {
          return {
            color: "#861414",
          };
        }

      case "home":
        if (design.home) {
          return design.home;
        } else {
          return { homeType: null };
        }

      case "statusBar":
        if (design.statusBar) {
          return design.statusBar;
        } else {
          return {
            color: "#000",
          };
        }

      case "menu":
        if (design.menu) {
          return design.menu;
        } else {
          // return default bedigital colors in case none is set
          return {
            firstColor: "#620606",
            fourthColor: "#f2f2f2",
            secondColor: "#861414",
            thirdColor: "#861414",
          };
        }

      case "menuItem":
        if (design.menuItem) {
          return design.menuItem;
        } else {
          // return default bedigital colors in case none is set
          return {};
        }

      default:
        return {};
    }
  } else {
    return {};
  }
}

export function getAppType() {
  const appDetails = JSON.parse(localStorage.getItem("appDetails"));
  return appDetails?.idAppType;
}

// return zoom authorize domain based on URL
export function getZoomUrl() {
  const appDetails = JSON.parse(localStorage.getItem("appDetails"));
  const domain = `https://${appDetails?.adminBucket}`;

  return `https://zoom.us/oauth/authorize?response_type=code&client_id=oSTRh9L0Sm6y0dkde3DEA&redirect_uri=${domain}/%23/configuracao/zoom_auth`;
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function getPermission(array, name) {
  return Boolean(array.filter((menu) => menu.name === name).length);
}

export function getUrlParam(paramName) {
  let hash = window.location.hash;
  hash = hash.replace(/.*\?/, "?");
  const queryParams = new URLSearchParams(hash);
  return queryParams.get(paramName);
}

export function getParametersTranslation(parameterName) {
  switch (parameterName) {
    case 1:
      return "Sim";
    case 0:
      return "Não";
    case "none":
      return "Nenhum";
    case "shadow":
      return "Sombreamento";
    case "s":
      return "Pequeno";
    case "m":
      return "Médio";
    case "l":
      return "Grande";
    case "cardStandard":
      return "Padrão";
    case "cardAvatar":
      return "Avatar";
    case "button":
      return "Botão";
    case "cardNavigation":
      return "Navegação";
    case "single":
      return "Único";
    case "slider":
      return "Slider";
    case "square":
      return "Quadrado";
    case "portrait":
      return "Retrato";
    case "landscapeXsmall":
      return "Paisagem";
    case "0":
      return "Sem Rotação";
    case "1":
      return "1 seg";
    case "2.5":
      return "2,5 seg";
    case "5":
      return "5 seg";
    default:
      return parameterName;
  }
}

export function removeHtmlTags(input, removeNBSP = false) {
  let result = input.replace(/<[^>]*>/g, "");

  if (removeNBSP) {
    return result.replace(/&nbsp;/g, "");
  }

  return result;
}

export function scrollToTop(className) {
  const element = document.querySelector(className);
  if (element) {
    element.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
}

export const compareTwoArrays = (arr1, arr2, arr1PROP = "id", arr2PROP = "id") => {
  arr1?.map((it) => {
    it.idData = it[arr1PROP];
    return it;
  });

  arr2?.map((it) => {
    it.idData = it[arr2PROP];
    return it;
  });

  // Extrair os IDs dos elementos do segundo array
  const arr2Ids = arr2.map((item) => item.idData);

  // Filtrar os IDs que não estão presentes no primeiro array
  const differentIds = arr2Ids.filter((idData) => !arr1?.some((item) => item.idData === idData));

  // Retornar os elementos correspondentes aos IDs diferentes
  return arr2.filter((item) => differentIds.includes(item.idData));
};

export function convertToBrDate(dataInput) {
  if (dataInput === null) return "-";

  let data = new Date(dataInput);
  let formatedDate = data.toLocaleDateString("pt-BR", { timeZone: "UTC" });
  return formatedDate;
}

export const formatValueReal = (valor) => {
  return valor.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
};

export const modelEvaluation = {
  REACTION: "REACTION",
  KNOWLEDGE: "KNOWLEDGE",
  IMPACT: "IMPACT",
};

export const getEvaluationTypeName = (evaluationType) => {
  switch (evaluationType) {
    case modelEvaluation.REACTION:
      return "Reação";
    case modelEvaluation.KNOWLEDGE:
      return "Conhecimento";
    case modelEvaluation.IMPACT:
      return "Impacto";
    default:
      return "--";
  }
};

export function dataEvaluation(currentItem) {
  const elem = {
    REACTION: { name: "reação", evaluacionType: "REACTION", idEvaluationType: 1 },
    KNOWLEDGE: { name: "conhecimento", evaluacionType: "KNOWLEDGE", idEvaluationType: 2 },
    IMPACT: { name: "impacto", evaluacionType: "IMPACT", idEvaluationType: 3 },
  };

  return elem[String(currentItem).toUpperCase()] || elem["REACTION"];
}

export const modelQuestion = {
  LIKERT_SCALE: "LIKERT_SCALE",
  MULTI_LIKERT_SCALE: "MULTI_LIKERT_SCALE",
  SINGLE_OPTION_COMMENT: "SINGLE_OPTION_COMMENT",
  MULTI_OPTION: "MULTI_OPTION",
  SINGLE_OPTION: "SINGLE_OPTION",
  TRUE_OR_FALSE: "TRUE_OR_FALSE",
  COMMENT: "COMMENT",
  CORRELATE: "CORRELATE",
};

export const getQuestionTypeName = (questionType) => {
  switch (questionType) {
    case modelQuestion.LIKERT_SCALE:
      return "Escala linear";
    case modelQuestion.MULTI_LIKERT_SCALE:
      return "Escala múltipla";
    case modelQuestion.SINGLE_OPTION_COMMENT:
      return "Alternativas + texto livre";
    case modelQuestion.COMMENT:
      return "Texto livre";
    case modelQuestion.MULTI_OPTION:
      return "Múltiplas corretas";
    case modelQuestion.SINGLE_OPTION:
      return "Resposta única correta";
    case modelQuestion.TRUE_OR_FALSE:
      return "Verdadeiro ou Falso";
    case modelQuestion.CORRELATE:
      return "Correlacionar";
    default:
      return "--";
  }
};

export const validateQuestions = (statement, typeQuestion, questionScales, questionOptions) => {
  if (!statement || statement.length === 0) {
    alert("Preencha o Enunciado da questãos.");
    return false;
  }

  if (
    typeQuestion === modelQuestion.LIKERT_SCALE ||
    typeQuestion === modelQuestion.MULTI_LIKERT_SCALE
  ) {
    const confQuestionScalesText = questionScales.filter((it) => it.title === "");

    if (questionScales.length === 0) {
      alert("Crie alternativas.");
      return false;
    }

    if (confQuestionScalesText.length > 0) {
      alert("Preencha os textos de todas as alternativas criadas.");
      return false;
    }
  }

  if (
    typeQuestion === modelQuestion.SINGLE_OPTION_COMMENT ||
    typeQuestion === modelQuestion.MULTI_LIKERT_SCALE
  ) {
    const confQuestionOptionsText = questionOptions.filter((it) => it.description === "");

    if (questionOptions.length === 0) {
      alert("Crie linhas.");
      return false;
    }

    if (confQuestionOptionsText.length > 0) {
      alert("Preencha todas as linhas criadas.");
      return false;
    }
  }

  if (
    typeQuestion === modelQuestion.MULTI_OPTION ||
    typeQuestion === modelQuestion.SINGLE_OPTION ||
    typeQuestion === modelQuestion.TRUE_OR_FALSE
  ) {
    const confQuestionOptionsCheck = questionOptions.filter((it) => it.isCorrect === 1);
    const confQuestionOptionsText = questionOptions.filter((it) => it.description === "");
    if (confQuestionOptionsCheck.length === 0) {
      alert(
        typeQuestion === modelQuestion.MULTI_OPTION
          ? "Selecione, pelos menos, uma das alternativas criadas."
          : "Selecione uma das alternativas criadas."
      );
      return false;
    }

    if (confQuestionOptionsText.length > 0) {
      alert("Preencha os textos de todas as alternativas criadas.");
      return false;
    }
  }

  if (typeQuestion === modelQuestion.CORRELATE) {
    if (questionOptions.length === 0) {
      alert("Crie pares de alternativas e respostas.");
      return false;
    }

    const emptyDescription = questionOptions.filter((it) => it.description === "");
    const emptyAnswerDescription = questionOptions.filter(
      (it) => it.questionOptionAnswer.description === ""
    );

    if (emptyDescription.length > 0) {
      alert("Preencha todas as alternativas.");
      return false;
    }

    if (emptyAnswerDescription.length > 0) {
      alert("Preencha todas as respostas.");
      return false;
    }
  }

  return true;
};

export const ParametersEvaluationReactionJodit = () => {
  const customJodit = {
    edit: {
      style: {
        fontFamily: "SF Pro Display",
        fontSize: 16,
        minHeight: 200,
        maxHeight: 200,
      },
      language: "pt_br",
      beautifyHTML: true,
      defaultActionOnPaste: "insert_only_text",
      defaultActionOnPasteFromWord: "insert_only_text",
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      toolbarAdaptive: false,
      autofocus: true,
      cursorAfterAutofocus: "start", // 'end';
      saveSelectionOnBlur: true,
    },
    buttons: [
      {
        group: "KE_style",
        buttons: ["bold", "italic", "underline", "strikethrough"],
      },
    ],
  };

  return customJodit;
};

export const ParametersEvaluationKnowledgeJodit = () => {
  const customJodit = {
    edit: {
      style: {
        fontFamily: "SF Pro Display",
        fontSize: 16,
        minHeight: 200,
        maxHeight: 200,
      },
      language: "pt_br",
      beautifyHTML: true,
      defaultActionOnPaste: "insert_only_text",
      defaultActionOnPasteFromWord: "insert_only_text",
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      toolbarAdaptive: false,
      autofocus: true,
      cursorAfterAutofocus: "start", // 'end';
      saveSelectionOnBlur: true,
    },
    buttons: [
      {
        group: "KE_style",
        buttons: ["bold", "italic", "underline", "strikethrough", "link"],
      },
      {
        group: "KE_font",
        buttons: [
          {
            name: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" class="jodit-icon_fontsize jodit-icon"> <path d="M1744 1408q33 0 42 18.5t-11 44.5l-126 162q-20 26-49 26t-49-26l-126-162q-20-26-11-44.5t42-18.5h80v-1024h-80q-33 0-42-18.5t11-44.5l126-162q20-26 49-26t49 26l126 162q20 26 11 44.5t-42 18.5h-80v1024h80zm-1663-1279l54 27q12 5 211 5 44 0 132-2t132-2q36 0 107.5.5t107.5.5h293q6 0 21 .5t20.5 0 16-3 17.5-9 15-17.5l42-1q4 0 14 .5t14 .5q2 112 2 336 0 80-5 109-39 14-68 18-25-44-54-128-3-9-11-48t-14.5-73.5-7.5-35.5q-6-8-12-12.5t-15.5-6-13-2.5-18-.5-16.5.5q-17 0-66.5-.5t-74.5-.5-64 2-71 6q-9 81-8 136 0 94 2 388t2 455q0 16-2.5 71.5t0 91.5 12.5 69q40 21 124 42.5t120 37.5q5 40 5 50 0 14-3 29l-34 1q-76 2-218-8t-207-10q-50 0-151 9t-152 9q-3-51-3-52v-9q17-27 61.5-43t98.5-29 78-27q19-42 19-383 0-101-3-303t-3-303v-117q0-2 .5-15.5t.5-25-1-25.5-3-24-5-14q-11-12-162-12-33 0-93 12t-80 26q-19 13-34 72.5t-31.5 111-42.5 53.5q-42-26-56-44v-383z"></path> </svg>`,
            icon: "fontsize",
            command: "fontSize",
            list: ["14", "16", "18", "20", "22", "24", "26", "30", "32"],
            template: (editor, key, value) => value,
            tooltip: "Font size",
            isActiveChild: (editor, control) => {
              const fontSize = editor.selection.getHTML().match(/font-size: (\w+);/) || [
                "font-size: 16px",
                "16px",
              ];

              return `${control.args[1]}px` === fontSize[1];
            },
          },
          // "paragraph",
          "lineHeight",
        ],
      },
      {
        group: "indent",
        buttons: [],
      },
      {
        group: "KE_list",
        buttons: ["ul", "ol"],
      },
      {
        group: "script",
        buttons: [],
      },
      {
        group: "state",
        buttons: [],
      },
      // {
      //   group: "ke_insert",
      //   buttons: ["table", "hr"],
      // },
      {
        group: "form",
        buttons: [],
      },
      {
        group: "history",
        buttons: ["find"],
      },
    ],
  };

  return customJodit;
};

export const ParametersJoditSyllabus = () => {
  const customJodit = {
    edit: {
      style: {
        fontFamily: "SF Pro Display",
        fontSize: 16,
        maxHeight: 159,
      },
      language: "pt_br",
      beautifyHTML: true,
      defaultActionOnPaste: "insert_only_text",
      defaultActionOnPasteFromWord: "insert_only_text",
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      toolbarAdaptive: false,
      // autofocus: true,
      // cursorAfterAutofocus: "start",
      // saveSelectionOnBlur: true,
    },
    buttons: [
      {
        group: "KE_style",
        buttons: ["bold", "italic", "underline", "strikethrough", "link", "ul", "ol"],
      },
    ],
  };

  return customJodit;
};

export const stringify = (obj) => {
  let cache = [];
  let str = JSON.stringify(obj, function (key, value) {
    if (typeof value === "object" && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference found, discard key
        return;
      }
      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
  cache = null; // reset the cache
  return str;
};

export const getInnerText = (htmlContent) => {
  const htmlWithNewlines = htmlContent.replace(/<br\s*\/?>/gi, "\n");
  const doc = new DOMParser().parseFromString(htmlWithNewlines, "text/html");
  const innerText = doc.body.innerText;
  return innerText || "";
};
