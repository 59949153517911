import { Tooltip } from "@material-ui/core";
import { withRouter, useParams } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import { DataGrid } from "@material-ui/data-grid";
import { Button, ButtonGroup } from "react-bootstrap";
import { useInterval } from "react-interval-hook";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import {
  uploadEnrollmentCsvApi,
  getImportId,
  checkImportStatus,
  getGroups,
} from "../../../../services/NexiDigitalAPI";
import { AuthContext } from "../../../../context/AuthContext";
import Loading from "../../../../components/Loading";
import { colors } from "../../../../utils/Colors";
import "./style.modules.scss";

const ListRegistrationBatch = (props) => {
  const { nodeCode, title, idTeam } = useParams();

  const { loginData } = useContext(AuthContext);
  const [uploadingData, setUploadingData] = useState(false);
  const [fileImportId, setFileImportId] = useState(null);
  const [isChecking, setIsChecking] = useState(false);
  const [checkingTries, setCheckingTries] = useState(0);
  const [feedback, setFeedback] = useState(null);
  const [groups, setGroups] = useState([]);
  const [displayGroups, setDisplayGroups] = useState([]);
  const [fixedList, setFixedList] = useState([]);
  const secondaryColor = loginData.secondaryColor;
  const fourthColor = loginData.fourthColor;
  let arrayOfTitles = [];

  const columns = [
    { field: "id", headerName: "Linha", width: 100 },
    { field: "message", headerName: "Mensagem", width: 400 },
  ];

  const rows = feedback?.feedback?.map((item, index) => {
    return {
      id: item.line,
      message: item.message,
    };
  });

  const { start, stop } = useInterval(() => {
    _checkImportStatus();
  }, 3000);

  const uploadFile = (file) => {
    setUploadingData(true);
    setIsChecking(false);
    getImportId()
      .then((res) => {
        setFeedback(null);
        if (res.data.error) {
          console.log("ERROR", res);
          setUploadingData(false);
          return;
        }
        let selectedFile = file.files[0];
        const fileNameId = res.data.importId;
        const data = new FormData();
        setFileImportId(fileNameId);
        if (selectedFile) {
          data.append("fileData", selectedFile);
          data.append("requestId", fileNameId);

          console.log(idTeam);

          data.append("teamId", idTeam); // array of selected group id's
          uploadEnrollmentCsvApi(data)
            .then((response) => {
              if (response.data.error) {
                console.log("ERROR", response);
              } else {
                start();
                setIsChecking(true);
              }
            })
            .catch((error) => {
              console.log("error", error);
            })
            .finally(() => {
              setUploadingData(false);
            });
        } else {
          setUploadingData(false);
          console.log("Nenhum arquivo selecionado");
        }
      })
      .catch((err) => {
        setUploadingData(false);
        console.log("error", err);
      });
  };

  function getCsvLink() {
    const appDetails = JSON.parse(localStorage.getItem("appDetails")) || null;
    const privateBaseUrl = appDetails.privateBaseUrl;

    const URL = privateBaseUrl + "content/files/template_carga_matriculas.csv";
    return URL;
  }

  function _checkImportStatus() {
    if (isChecking && checkingTries <= 5) {
      checkImportStatus(fileImportId)
        .then((res) => {
          if (res.data.feedback) {
            setFeedback(JSON.parse(res.data.feedback));
            stop();
          }
        })
        .catch((err) => {
          console.log("err", err);
          setCheckingTries(checkingTries + 1);
        });
    }
  }

  function clearInputPath() {
    let element = document.getElementById("upload-button-file");
    element.value = "";
  }

  const handleChange = (event) => {
    setDisplayGroups(event.target.value);
    let selectedItems = [];
    fixedList.map((item) => {
      event.target.value.map((selectedItem) => {
        if (item.title === selectedItem) {
          selectedItems.push(item.idGroups); // storing only the group id's
        }
      });
    });
    setGroups(selectedItems);
  };

  function _getGroups() {
    getGroups()
      .then((res) => {
        if (res.data.success == true) {
          setFixedList(res.data.groups); // stores the original response group list
          let titlesList = [];
          fixedList.map((item) => {
            titlesList.push(item.title);
          });
          setDisplayGroups(titlesList); // stores list of titles
        }
      })
      .catch((err) => {
        console.log("error ", err);
      });
  }

  useEffect(() => {
    _getGroups();
    return () => {
      stop();
    };
  }, []);

  return (
    <div className="import-users">
      <div className="header-title-wrapper">
        <div className="header-title">
          <button
            className="btnGoBack"
            onClick={() => {
              props.history.goBack();
            }}
          >
            <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
          </button>
          Gestão de Turmas - {title}
        </div>
      </div>
      <div className="card">
        <div className="card-body template-card-body">
          <h3 className="instructions-title">Matricular em lote</h3>
          {/* <h4>Por importação de usuários</h4> */}
          <ul className="import-users-instructions">
            <li>
              <span>
                1) Baixar o Modelo CSV <CloudDownloadOutlinedIcon />
              </span>
            </li>
            <li>
              <span>
                2) Preencher o modelo, sendo cada linha um usuário (atenção para todos os campos)
              </span>
            </li>
            <li>
              <span>
                3) Salvar o modelo e fazer upload <CloudUploadOutlinedIcon />
              </span>
            </li>
            <li>
              <span>4) Enviar (se um ou mais campos falharem nenhum usuário será adicionado)</span>
            </li>
          </ul>

          {/* <FormControl style={{ flex: 1,  marginBottom: 15 }} variant="outlined">
            <InputLabel style={{ backgroundColor: 'white', top: displayGroups.length ? 0 : -5 }} id="mutiple-checkbox-label">Selecionar grupos</InputLabel>
            <Select
              style={{ width: 400, marginBottom: 30 }}
              labelId="mutiple-checkbox-label"
              id="mutiple-checkbox"
              placeholder="Selecione um grupo"
              multiple
              value={displayGroups}
              onChange={handleChange}
              variant="outlined"
              renderValue={(selected) => selected.join(', ')}
            >
            {fixedList.map((item, index) => (
              <MenuItem key={index} value={item.title}>
                <Checkbox checked={displayGroups.indexOf(item.title) > -1} />
                <ListItemText primary={item.title} />
              </MenuItem>
            ))}
            </Select>
          </FormControl> */}

          <div className="import-buttons">
            <Tooltip title="Baixar planilha" placement="top">
              <a href={getCsvLink()} download>
                <ButtonGroup className="mb-2" style={{ marginRight: 15 }}>
                  <Button
                    variant="secondary"
                    className="blast-button"
                    style={{ backgroundColor: "#0c8b5f" }}
                  >
                    <CloudDownloadOutlinedIcon style={{ marginRight: 15, marginBottom: 5 }} />
                    Modelo CSV
                  </Button>
                </ButtonGroup>
              </a>
            </Tooltip>
            {
              <label htmlFor="upload-button-file">
                <Tooltip title="Enviar planilha" placement="top">
                  <div className="mb-2">
                    <div className="btn btn-secondary" style={{ backgroundColor: "#0c8b5f" }}>
                      <CloudUploadOutlinedIcon style={{ marginRight: 15, marginBottom: 5 }} />
                      {uploadingData ? "Uploading..." : "Upload"}
                      <input
                        disabled={uploadingData}
                        accept=".csv"
                        id="upload-button-file"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => uploadFile(e.target)}
                        onClick={() => clearInputPath()}
                      />
                    </div>
                  </div>
                </Tooltip>
              </label>
            }
          </div>
          <hr />
          <h3 className="result-title">{isChecking && "Resultado"}</h3>
          <div style={{ width: "100%" }}>
            {isChecking && (
              <div>
                {!feedback ? (
                  <Loading containerStyle={{ fontSize: 25 }}>
                    Processando arquivo, por favor aguarde...
                  </Loading>
                ) : (
                  <div>
                    <div style={{ flex: 1, alignItems: "center" }}>
                      <CheckCircleIcon
                        style={{ color: colors.green, fontSize: 50, marginRight: 5 }}
                      />
                      <span className="import-number">{feedback.success}</span>
                      linha{feedback.success > 1 && "s"} processada{feedback.success > 1 && "s"} com
                      sucesso
                    </div>
                    {feedback?.errors > 0 && (
                      <>
                        <div style={{ flex: 1, alignItems: "center" }}>
                          <ErrorIcon style={{ color: colors.red, fontSize: 50, marginRight: 5 }} />
                          <span className="import-number">{feedback?.errors}</span>
                          linha{feedback?.errors > 1 && "s"} processada{feedback?.errors > 1 && "s"}{" "}
                          com erro{feedback.errors > 1 && "s"}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
            {feedback?.feedback?.length > 0 && (
              <div style={{ height: 500, marginTop: 30 }}>
                <DataGrid rows={rows} columns={columns} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ListRegistrationBatch);
