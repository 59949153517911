import * as Yup from "yup";

const schemaInit = {
  REACTION: {
    title: "",
    description: "",
    closingText: "",
    image: "",
    author: "",
    authorEmail: "",
  },
  KNOWLEDGE: {
    title: "",
    description: "",
    image: "",
    questionAmount: 1,
    showResponseSummary: false,
    lockAfterApproval: false,
    shuffleQuestions: false,
    saveHighestScore: false,
    maxTries: 1,
    weight: 1,
    masteryScore: 10,
    timeLimit: 1,
    noTimeLimit: false,
    timerStoppable: false,
    lockNavigation: false,
    author: "",
    authorEmail: "",
  },
  IMPACT: {
    title: "",
    description: "",
    author: "",
    authorEmail: "",
    availableAfterDays: 30,
    availableUntilDays: 30,
  },
};

const schemaObj = {
  REACTION: {
    title: Yup.string()
      .min(2, "No mínimo 2 caracteres")
      .max(50, "No maxímo 50 caracteres")
      .required("Preenchimento do campo obrigatório"),
    description: Yup.string().max(150, "No maxímo 150 caracteres").nullable(true),
    image: Yup.string().nullable(true),
    closingText: Yup.string().max(250, "No maxímo 250 caracteres").nullable(true),
    author: Yup.string()
      .min(2, "No mínimo 2 caracteres")
      .max(50, "No maxímo 50 caracteres")
      .nullable(true),
    authorEmail: Yup.string().email("Valor inválido para email.").nullable(true),
  },
  KNOWLEDGE: {
    title: Yup.string()
      .min(2, "No mínimo 2 caracteres")
      .max(50, "No maxímo 50 caracteres")
      .required("Preenchimento do campo obrigatório"),
    description: Yup.string().max(150, "No maxímo 150 caracteres").nullable(true),
    image: Yup.string().nullable(true),
    questionAmount: Yup.number().integer().nullable(true).min(1, "No mínimo 1"),
    showResponseSummary: Yup.boolean().nullable(true),
    lockAfterApproval: Yup.boolean().nullable(true),
    shuffleQuestions: Yup.boolean().nullable(true),
    saveHighestScore: Yup.boolean().nullable(true),
    maxTries: Yup.number().integer().nullable(true).min(1, "No mínimo 1"),
    weight: Yup.number().integer().nullable(true).min(1, "No mínimo 1"),
    masteryScore: Yup.number().integer().nullable(true).min(10, "No mínimo 10"),
    timeLimit: Yup.number().integer().nullable(true),
    noTimeLimit: Yup.boolean().nullable(true),
    timerStoppable: Yup.boolean().nullable(true),
    lockNavigation: Yup.boolean().nullable(true),
    author: Yup.string()
      .min(2, "No mínimo 2 caracteres")
      .max(50, "No maxímo 50 caracteres")
      .nullable(true),
    authorEmail: Yup.string().email("Valor inválido para email.").nullable(true),
  },
  IMPACT: {
    title: Yup.string()
      .min(2, "No mínimo 2 caracteres")
      .max(50, "No maxímo 50 caracteres")
      .required("Preenchimento do campo obrigatório"),
    description: Yup.string().max(150, "No maxímo 150 caracteres").nullable(true),
    author: Yup.string()
      .min(2, "No mínimo 2 caracteres")
      .max(50, "No maxímo 50 caracteres")
      .nullable(true),
    authorEmail: Yup.string().email("Valor inválido para email.").nullable(true),
    availableAfterDays: Yup.number()
      .integer()
      .nullable(true)
      .min(1, "No mínimo 1 dia")
      .max(60, "No maxímo 60 dias"),
    availableUntilDays: Yup.number()
      .integer()
      .nullable(true)
      .min(1, "No mínimo 1 dia")
      .max(30, "No maxímo 30 dias"),
  },
};

export const schemaFormInit = (typeEvaluation) => {
  return schemaInit[typeEvaluation];
};

export const schemaEvaluation = (typeEvaluation) => {
  return Yup.object().shape(schemaObj[typeEvaluation]);
};
