import "./style.modules.scss";
import React, { useState, useEffect, forwardRef, fragment } from "react";

import { withRouter, useParams } from "react-router-dom";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import { getCertificatesList } from "../../../services/NexiDigitalAPI";

import Table from "./components/Table";
import Filtro from "./components/Filtro";

const CertificatesManager = (props) => {
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
    totalRecords: 999,
    searchString: "",
    data: [],
  });

  useEffect(() => {
    let componentMounted = true;

    if (loading) {
      getCertificatesList(pagination.limit, pagination.offset, pagination.searchString).then(
        (res) => {
          if (componentMounted) {
            setPagination((prev) => ({
              ...prev,
              totalRecords: res.totalRecords,
              data: res.result,
            }));
            setLoading(false);
          }
        },
      );
    }

    return () => {
      componentMounted = false;
    };
  }, [pagination]);

  const actionFilter = (values) => {
    setLoading(true);
    setPagination(values);
  };

  const callEditCertificates = (idCompany = null, idCertificate = null) => {
    props.history.push(
      idCertificate ? `/edit-certificates/${idCertificate}/${idCompany}` : "/edit-certificates/",
    );
  };

  return (
    <div className="ptcCertificatesManager">
      <div className="header-title-wrapper">
        <div className="header-title">
          <button
            className="btnGoBack"
            onClick={() => {
              props.history.goBack();
            }}
          >
            <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
          </button>
          Gestão de Certificados
        </div>
      </div>

      <div className="ptcCertificatesManagerBase">
        <Filtro
          actionFilter={actionFilter}
          loading={loading}
          pag={pagination}
          callEditCertificates={callEditCertificates}
        />

        <Table
          actionFilter={actionFilter}
          loading={loading}
          setLoading={setLoading}
          pag={pagination}
          setPagination={setPagination}
          callEditCertificates={callEditCertificates}
        />
      </div>
    </div>
  );
};

export default withRouter(CertificatesManager);
