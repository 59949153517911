import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import "./styles.scss";
import { Tooltip } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";


import DataTable from "react-data-table-component";
import { deleteCampaing, getListCampaigns, insertCampaigns } from "services/NexiDigitalAPI";
import moment from "moment";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import ModalDialog from "containers/PeopleCraft/Modals/ModalDialog";
import HeaderFilter from "../../Components/HeaderFilter";

import GamificationEdit from "../../Components/GamificationEdit";
import HeaderCrumb from "containers/Gamification/Components/HeaderCrumb";
const CampaignManagement = () => {
  const [textSearch, setTextSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<any>([]);
  const [filteredItems, setFilteredItems] = useState<any>([]);
  const [editNo, setEditNo] = useState<any>(null);
  const [showModalFeedback, setShowModalFeedback] = useState<boolean>(false);
  const [idCampaign, setIdCampaign] = useState<boolean>(false);

  const paginationComponentOptions = {
    rowsPerPageText: '',
    rangeSeparatorText: '',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };
  const _getListCampaigns = useCallback(async () => {
    setLoading(true);

    getListCampaigns()
      .then((res) => {
        const _order = res.sort((a, b) => b.idgamification_campaign - a.idgamification_campaign)
        setList(_order);
        setFilteredItems(_order);
        setLoading(false);
      })
      .catch((err) => {
        setList([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }
    , [])

  const filter = () => {
    if (!textSearch) {
      setFilteredItems(list)
    }
    const _filter = list.filter(elem => elem.name.toLowerCase().indexOf(textSearch.toLowerCase()) > -1)

    setFilteredItems(_filter)
  }

  useEffect(() => {
    _getListCampaigns();
  }, [_getListCampaigns])

  const CustomNoDataComponent = () => (
    <div className="noContent" style={{ textAlign: "center" }}>
      <p>Sem itens encontrados</p>
    </div>
  );
  const columns = [
    {
      name: "Nome da Campanha",
      selector: "name",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },

    },
    {
      name: "Data Início",
      sortable: false,
      selector: "startDate",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (data) => (
        <div>
          <p>{data.startDate ? moment(data.startDate).format("DD/MM/YYYY") : ''}</p>
        </div>
      ),
    },
    {
      name: "Data Fim",
      sortable: false,
      selector: "endDate",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (data) => (
        <div>
          <p>{data.endDate ? moment(data.endDate).format("DD/MM/YYYY") : ''}</p>
        </div>
      ),
    },
    {
      name: "Ação",
      sortable: false,
      selector: "idgamification_campaign",
      center: true,
      wrap: true,
      width: '200px',
      style: { textAlign: "center" },
      cell: (group) => (
        // <HighlightText value={removeHtmlTags(question.statement)} highlight={searchString} />
        // <div>
        //   <p>{group.title}</p>
        // </div>

        <div className="groupActions" key={group?.idgamification_campaign}>
          <Tooltip title="Remover campanha" placement="top">
            <span className="btn btn-sm float-right icon-delete" onClick={() => {
              setIdCampaign(group?.idgamification_campaign);
              setShowModalFeedback(true);
            }
            }>
              <RiDeleteBin5Line />
            </span>
          </Tooltip>
          {true && (
            <Tooltip title="Editar campanha" placement="top" onClick={() => setEditNo(group)}>
              <span className="btn btn-sm float-right icon-edit">
                <FiEdit />
              </span>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setTextSearch(event.target?.value);
  };


  const hidenModel = (paramsMessage = null, error = false) => {
    console.log(error)
    !error && setEditNo(null)
    if(paramsMessage){
      !error && _getListCampaigns()
      message(paramsMessage);
    }
  }
  const cleanSearch = () => {
    setTextSearch("");
  };

  const message = ({text, type, position = 'top-right'}: {text: string, type: string, position?: string}) => {
    toast[type](text, {
      position: position,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  const handleClickRemove = async () => {
    setLoading(true)
    try {
      await deleteCampaing(idCampaign)
      message({text: 'Campanha deletada com sucesso!', type: 'success'});

      _getListCampaigns()


    } catch (e) {
      message({text: e?.response?.data?.message, type: 'error'});
    } finally {
      setLoading(false)

    }
  }

  const handleAction = async () => {
    try {
      const result: any = await insertCampaigns({
        name: '',
        description: '',
        endDate: null,
        startDate: null,
        status: 0,
        events: [],
      })
      if (result) {
        setEditNo({ idgamification_campaign: result.idCampaign, newCampaing: true })
      }
    } catch (e) {
      message({text: e?.response?.data?.message, type: 'error'});
    } finally {

    }



  }

  return (
    <>
      <div className="gamification-container">
        <HeaderCrumb crumb="Gamificação / Gestão de Campanhas"/>

        <div className="gamification-header">
          <HeaderFilter
            textSearch={textSearch}
            handleChangeName={handleChangeName}
            handleAction={handleAction}
            cleanSearch={cleanSearch}
            filter={filter}
          />
        </div>
        <div className="gamification-table-container">
          <DataTable
            progressPending={loading}
            columns={columns}
            noDataComponent={<CustomNoDataComponent />}
            data={filteredItems}
            noHeader
            fixedHeader
            pagination
            paginationComponentOptions={paginationComponentOptions}
          />
        </div>
        {(editNo) && (
          <GamificationEdit
            dataNode={editNo}
            denyAction={hidenModel}
            title="Configuração de Campanha de Gamificação"
          ></GamificationEdit>

        )}
        <ModalDialog
          text={"Tem certeza de que deseja deletar essa Campanha?"}
          show={showModalFeedback}
          setShow={setShowModalFeedback}
          btnCall={{
            className: "",
            variantBtn: "danger",
            text: "Deletar",
            callEvent: () => {
              handleClickRemove();
            },
          }}
        />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default CampaignManagement;
