import PreloaderBlur from "containers/PeopleCraft/PreloaderBlur";
import React, { ChangeEvent, Fragment, useCallback, useContext, useEffect, useState } from "react";
import InputFilter from "../../components/InputFilter";
import { getUserWorkTeam } from "services/NexiDigitalAPI";
import { AuthContext } from "context/AuthContext";
import FilterResults from "react-filter-search";
import AccordionTable from "../../components/AccordionTable";
import Header from "../../components/header";

const ImpactAssessmentPage = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [textSearch, setTextSearch] = useState<string>("");
  const { loginData }: { loginData: any } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setTextSearch(e.target?.value)
  }

  const cleanSearch = () => {
    setTextSearch('')
  }

  const _getUserWorkTeam = useCallback(async () => {
    setLoading(true);
    getUserWorkTeam(loginData.userid, { onlyWithPendingEvaluation:true})
      .then((res: any) => {
        setUsers(res?.data?.result)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loginData.userid])

  useEffect(() => {
    _getUserWorkTeam();
  }, [_getUserWorkTeam])

  return (
    <>
      {loading && <PreloaderBlur />}
      <div className="MyTeam">
        <Header title={'Minha Equipe - Avaliação de Impacto'} />
        <InputFilter textSearch={textSearch} handleChangeName={handleChangeName} cleanSearch={cleanSearch} />
        <div className="accordionTeam" style={{ marginTop: 10 }}>
          <FilterResults
            value={textSearch}
            data={!!textSearch ?  users.filter(item => item.name.toLowerCase().indexOf(textSearch) > -1 ) : users }
            renderResults={(results) => (
              <>
                {results.length === 0 && !loading && <p>Não foi encontrado nenhum dado</p>}
                {results.map((item, i) => {
                  return (
                    <Fragment key={item.idUser}>
                      <AccordionTable item={item} idUser={item.idUser} />
                    </Fragment>
                  );
                })}
              </>
            )}
          />
        </div>
      </div>
    </>
  )
}

export default ImpactAssessmentPage;
