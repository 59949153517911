import React, { useContext, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaMinusSquare, FaPlusSquare } from "react-icons/fa";
import { GrCertificate } from "react-icons/gr";

import moment from "moment";
import "moment/locale/pt-br.js";

import { getCertificateEnrollments } from "../../../../../services/NexiDigitalAPI";

import { AuthContext } from "../../../../../context/AuthContext";
import { filterContent } from "../filters/filterContent";
import { filterElemType } from "../filters/filterElemType";

const filterStatus = (st, progress) => {

  const statusObj ={
    "IN_PROGRESS": `Em andamento ${Math.round(progress)}%`,
    "COMPLETED": "Concluído",
    "PASSED": "Aprovado",
    "FAILED":"Reprovado",
    "EXPIRED": "Expirado"
  }

  return statusObj[st] || "Não iniciado";
}

const LineItem = ({ item, idUser, arcodeonStatus = false }) => {
  const [certicationTxt, setCerticationTxt] = useState(null);
  const { loginData } = useContext(AuthContext);
  const secondaryColor = loginData.secondaryColor;

  const PreloaderCertification = () => {
    return (
      <div className="preloaderCertification">
        <div className="loaderPreloaderCertification"></div>
        <p>{certicationTxt}</p>
      </div>
    );
  };

  let labelSrt = null;
  let titleSrt = null;
  let initialDateSrt = null;
  let finalDateSrt = null;
  let progressSrt = null;
  let progressStatus = false;
  let gradeSrt = null;
  let certificateObj = null;

  const filterEl = filterElemType(item);
  const itsChildrens = filterEl === "INIT" || filterEl === "NODE" || filterEl === "LEARNING";

  // console.log(item);
  if (filterEl === "INIT" || filterEl === "NODE") {
    const itemBase = filterEl === "NODE" ? item?.data : item;
    const { label, title } = filterEl === "NODE" ? itemBase : itemBase?.node;

    const { enrollment, team, user, idNodeEnrollment } = itemBase?.nodeEnrollment;

    const { status, progress, grade, startDate, finishDate, idUser, idEnrollment } =
      itemBase?.enrollment ? itemBase?.enrollment : itemBase?.nodeEnrollment;

    labelSrt = label ? label : null;
    titleSrt = title ? title : "curso";
    initialDateSrt = startDate ? startDate : null;
    finalDateSrt = finishDate ? finishDate : null;

    progressSrt = filterStatus(status,progress);

    gradeSrt = grade ? Math.round(grade) : null;
    progressStatus = status === "COMPLETED" || status === "PASSED"  ? true : false;

    const hasTeamCertificate = team && team?.idCertificate;
    const hasCertificateForInternalNode = hasTeamCertificate && team?.enableCertificateForChildren;
    const isInternalNode = itemBase?.nodeEnrollment?.idParentNodeEnrollment !== null;

    certificateObj =
      (hasTeamCertificate && !isInternalNode) || (isInternalNode && hasCertificateForInternalNode)
        ? {
            idUser: filterEl === "NODE" ? user?.idUser : idUser,
            idEnrollment: filterEl === "NODE" ? enrollment?.idEnrollment : idEnrollment,
            title: titleSrt,
            idNodeEnrollment,
            isInternalNode,
          }
        : null;
  } else if (filterEl === "CONTENT") {
    const { title, idContentType, label, contentEnrollment } = item?.data;
    const { startDate, finishDate, status, progress, grade } =
      contentEnrollment;

    titleSrt = title ? title : "";
    labelSrt = !label ? filterContent(idContentType) : label;
    initialDateSrt = startDate ? startDate : null;
    finalDateSrt = finishDate ? finishDate : null;
    progressSrt = filterStatus(status,progress);

    gradeSrt = grade ? Math.round(grade) : null;
    progressStatus = status === "COMPLETED" || status === "PASSED" ? true : false;

    certificateObj =  null;
  } else if (filterEl === "EVALUATION") {
    const { label, title, questionnaire } = item?.data;

    labelSrt = label ? label : "Avaliação";
    titleSrt = title ? title : null;
    initialDateSrt = questionnaire?.startDate ? questionnaire?.startDate : null;
    finalDateSrt = questionnaire?.finishDate ? questionnaire?.finishDate : null;
    progressSrt =
      questionnaire?.startDate && questionnaire?.finishDate
        ? "Concluído"
        : questionnaire?.startDate
        ? `Em andamento 50%`
        : "Não iniciado";

    gradeSrt = questionnaire?.grade ? Math.round(questionnaire?.grade) : null;

    certificateObj = null;
  } else if (filterEl === "LEARNING") {
    titleSrt = item?.title ? item.title : "";
    labelSrt = "Catálogo";
    initialDateSrt = null;
    finalDateSrt = null;
    progressSrt = null;
    gradeSrt = null;
    certificateObj = null;
  }

  const dataItem = {
    ico: itsChildrens,
    label: labelSrt,
    title: titleSrt,
    status: progressSrt,
    nota: gradeSrt,
    initialDate: initialDateSrt,
    finalDate: finalDateSrt,
    certificate: certificateObj,
  };

  const handleCertificateMouseEvent = (e) => {
    const { idUser, idEnrollment, title, idNodeEnrollment, isInternalNode } = dataItem?.certificate;
    setCerticationTxt("Aguarde, gerando e baixando o certificado!");

    const objCertificate = {
      idUser: idUser,
      idEnrollment: idEnrollment,
      idNodeEnrollment: isInternalNode ? idNodeEnrollment : null
    };

    getCertificateEnrollments(objCertificate)
      .then((response) => {
        setTimeout(() => {
          setCerticationTxt(null);
        }, 2000);

        // const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
        const link = document.createElement("a");
        // link.href = url;
        link.href = "data:application/octet-stream;base64," + response.data;
        link.download = `${title?.replace(/[^\w]/g, "")}_certificate.pdf`;
        link.click();
      })
      .catch((error) => {
        setCerticationTxt(
          "Ocorreu um erro na geração do certificado, tente novamente em alguns minutos.",
        );

        setTimeout(() => {
          setCerticationTxt(null);
        }, 4000);

        console.log(error);
      });

    e.stopPropagation();
  };
  return (
    <div className="lineTitle">
      <div className="lineTitleNameIt">
        {dataItem.ico && (
          <div className="lineTitleStatus">
            {arcodeonStatus ? (
              <FaMinusSquare style={{ color: secondaryColor }} />
            ) : (
              <FaPlusSquare style={{ color: "#2b2b2b" }} />
            )}
          </div>
        )}

        <div className={`lineTitleName ${filterEl === "LEARNING" ? "lineTitleCatalogo" : ""}`}>
          <div
            className="titlName"
            style={{
              color: itsChildrens ? secondaryColor : "#2b2b2b",
            }}
          >
            {dataItem.title}
          </div>
          <div className="lineBaseLabelInf">
            {dataItem.label && (
              <div className="lineTitleLabel" style={{ backgroundColor: secondaryColor }}>
                {dataItem.label}
              </div>
            )}

            {(dataItem.status || dataItem.nota) && (
              <div className="titlStatusInf">
                {dataItem.status && <div className="tlteStatus">{dataItem.status}</div>}
                {dataItem.nota !== null && (
                  <div className="tlteNota">
                    <b>Nota:</b> {dataItem.nota}
                  </div>
                )}
              </div>
            )}

            {dataItem.certificate && (
              <>
                <OverlayTrigger
                  placement={"top"}
                  overlay={<Tooltip id={`tooltip-top`}> Baixar Certificado </Tooltip>}
                >
                  <button
                    className={`lineTitleCertificate
                          ${!progressStatus ? "lineTitleCertificateInat" : ""}`}
                    onClick={(e) => {
                      handleCertificateMouseEvent(e);
                    }}
                  >
                    <GrCertificate style={{ color: secondaryColor }} />
                  </button>
                </OverlayTrigger>
                {certicationTxt !== null && <PreloaderCertification />}
              </>
            )}
          </div>
        </div>
      </div>

      {filterEl !== "LEARNING" && (
        <div className="lineTitleDates">
          <div className="lineTitleDatesInit">
            <b>Data de início:</b>{" "}
            {dataItem.initialDate
              ? moment(dataItem.initialDate).format("DD/MM/YYYY")
              : "Não iniciado."}
          </div>
          <div className="lineTitleDatesEnd">
            <b>Data de conclusão:</b>{" "}
            {dataItem.finalDate ? moment(dataItem.finalDate).format("DD/MM/YYYY") : "-"}
          </div>
        </div>
      )}
    </div>
  );
};

export default LineItem;
