import React, { useCallback, useEffect, useRef, useState } from "react";
import "./styles.scss";
import Switch from "react-bootstrap/esm/Switch";
import CloseIcon from "@material-ui/icons/Close";

import AccordionComponent from "components/PeopleCraft/AccordionComponent";
import Label from "components/PeopleCraft/Label";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Tooltip } from "@material-ui/core";
import DataTable from "react-data-table-component";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import {
  deleteCampaing,
  deleteGroupsCampaing,
  getCampaignsEvents,
  getGroups,
  getGroupsCampaing,
  getListCampaignsDetail,
  postGroupsCampaing,
  updatecampaign,
} from "services/NexiDigitalAPI";
import ActionScore from "../ActionsScore";
import ListMig from "components/PeopleCraft/AddCategories/ListMig/indes";
import { Button, Form, Modal } from "react-bootstrap";
import PreloaderBlur from "containers/PeopleCraft/PreloaderBlur";
import ModalDialog from "containers/PeopleCraft/Modals/ModalDialog";
import { ptBR } from "date-fns/locale";
import { Link } from "react-router-dom";
import { transformText } from "utils/Utils";
import CharacterCounter from "components/PeopleCraft/CharacterCounter";
registerLocale("pt-BR", ptBR);
export type DataCampaing = {
  name: string;
  description: string;
  endDate: string;
  startDate: string;
  status: number;
  events: any;
  eventGradeAsMultiplier: { eventType: number; gradeAsMultiplier: boolean };
  edit: boolean;
  disabledEdit: boolean;
  idCompany: string;
};
const GamificationEdit = ({ title, denyAction = null, dataNode = null }) => {
  const [showModal, setShowModal] = useState(true);
  const [validInputs, setIsValidInputs] = useState(false);
  const [idRemoveGroup, setIdRemoveGroup] = useState<number>(null);
  const [showModalFeedback, setShowModalFeedback] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [dataCampaing, setDataCampaing] = useState<DataCampaing>();
  const [dataActionScore, setDataActionScore] = useState([]);
  const [clearAll, setClearAll] = useState(false);
  const menuData = JSON.parse(localStorage.getItem("menu-data"));
  const [groups, setGroups] = useState<
    {
      title?: string;
      idGroups?: number;
    }[]
  >([]);
  const [selectCampaing, setSelectCampaing] = useState<{
    title: string;
    idGroups: number;
  }>({
    title: "",
    idGroups: 0,
  });
  const [selectTargetAudience, setSelectTargetAudience] = useState([]);
  const formularioRef = useRef<any>();

  async function handleDeny() {

    closeModalEvents()
    if (denyAction) {
      denyAction();
      return;
    }
    setShowModal(false);
  }

  const CustomNoDataComponent = () => (
    <div style={{ textAlign: "center" }}>
      <p>Sem itens encontrados</p>
    </div>
  );

  const columnsParticipantes = [
    {
      value: '',
      label: "Todas as empresas",
    },
    {
      value: menuData.idCompany,
      label: menuData.companyName,
    },
  ];

  const columns = [
    {
      name: "Publico-alvo",
      selector: "title",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },

    {
      name: "Ação",
      sortable: false,
      selector: "idGroups",
      width: "200px",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (group) => (
        <div className="groupActions" key={group.idGroups}>
          <Tooltip title="remover público-alvo" placement="top">
            <button
              disabled={
                dataCampaing.disabledEdit || (!dataCampaing.idCompany && menuData.admin === 2)
              }
              className="btn btn-sm float-right icon-delete"
              onClick={() => {
                setIdRemoveGroup(group.idGroups);
                setShowModalFeedback(true);
              }}
            >
              <RiDeleteBin5Line />
            </button>
          </Tooltip>

          <Link
            className={
              (dataCampaing.disabledEdit || (!dataCampaing.idCompany && menuData.admin === 2)) &&
              "disabled"
            }
            to={`/configuracao/grupos/editar/${group.idGroups}/${transformText(group.title)}`}
            target="_blank"
            onClick={(e) =>
              (dataCampaing.disabledEdit || (!dataCampaing.idCompany && menuData.admin === 2)) &&
              e.preventDefault()
            }
          >
            <Tooltip title="editar público-alvo" placement="top">
              <span className="btn btn-sm float-right icon-edit">
                <FiEdit />
              </span>
            </Tooltip>
          </Link>
        </div>
      ),
    },
  ];

  const getDatas = useCallback(async () => {
    setLoading(true);
    try {
      const request = [
        getGroups(),
        getCampaignsEvents(),
        getListCampaignsDetail(dataNode.idgamification_campaign),
        getGroupsCampaing(dataNode.idgamification_campaign),
      ];

      const [resultGroups, resultCampaingEvents, resultListCampaigns, resultGroupsCampaing] =
        await Promise.all(request);

      if (resultListCampaigns) {
        const eventTRAINING_COMPLETED = resultListCampaigns.events.find(
          (elem) => elem.dynamicAttributes,
        );
        const eventGradeAsMultiplier = eventTRAINING_COMPLETED
          ? {
              eventType: null,
              gradeAsMultiplier: eventTRAINING_COMPLETED?.dynamicAttributes?.gradeAsMultiplier,
            }
          : {
              eventType: null,
              gradeAsMultiplier: false,
            };
        const endDate = resultListCampaigns.campaign[0].endDate
          ? new Date(resultListCampaigns.campaign[0].endDate)
          : null;
        const startDate = resultListCampaigns.campaign[0].startDate
          ? new Date(resultListCampaigns.campaign[0].startDate)
          : null;

        setDataCampaing({
          ...resultListCampaigns.campaign[0],
          endDate: endDate,
          startDate: startDate,
          events: resultListCampaigns.events.reduce((acc, obj) => {
            acc[obj.idEvent] = obj.points;
            return acc;
          }, {}),
          eventGradeAsMultiplier,
          edit: !dataNode?.newCampaing,
          disabledEdit: startDate ? startDate < new Date() : false,
          idCompany: resultListCampaigns.campaign[0].idCompany === null ? "" :resultListCampaigns.campaign[0].idCompany
        });
      }
      if (resultGroups) {
        setSelectTargetAudience(resultGroups.data?.groups);
      }
      if (resultCampaingEvents) {
        setDataActionScore(resultCampaingEvents);
      }

      if (resultGroupsCampaing) {
        setGroups(resultGroupsCampaing.data.campaignGroups);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [dataNode]);

  const closeModalEvents = async (event?) => {
    if (dataNode.newCampaing) {
       event  &&event.preventDefault()
      try {
        await  deleteCampaing(dataNode?.idgamification_campaign);
      } catch (e) {
        console.log(e);
      }
    }
  }

  useEffect(() => {
    getDatas();

    const handleBeforeUnload = async (event) => {
       closeModalEvents(event)
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, [getDatas]);



  const handleClickSelectPublic = async () => {
    if (!selectCampaing.idGroups) {
      return;
    }
    const existGroup = groups.some((elem) => selectCampaing.idGroups === elem.idGroups);
    if (!existGroup) {
      setLoading(true);
      try {
        await postGroupsCampaing(dataNode.idgamification_campaign, {
          idGroups: selectCampaing.idGroups,
        });
        groups
          ? setGroups((after) => [...after, { ...selectCampaing }])
          : setGroups([{ ...selectCampaing }]);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    }
    setSelectCampaing({ title: "", idGroups: 0 });
  };

  const handleClickRemove = async (idGroup: number) => {
    if (!idGroup) {
      return;
    }
    setLoading(true);
    try {
      await deleteGroupsCampaing(dataNode.idgamification_campaign, idGroup);

      const _groups = groups.filter((item) => item.idGroups !== idGroup);
      setGroups(_groups);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (data) => setDataCampaing((prevState) => ({ ...prevState, ...data }));

  const handleSubmit = async (event) => {
    setIsValidInputs(true);

    event.preventDefault();
    const formInvalid = formularioRef.current
      ? Array.from(formularioRef.current.elements).some((campo: HTMLInputElement) => {
          return campo.classList.contains("is-invalid");
        })
      : false;

    if (!formInvalid && formularioRef.current.checkValidity()) {
      const form = { ...dataCampaing, events: formatEvents(),  idCompany:dataCampaing.idCompany ?  dataCampaing.idCompany : null};
      setLoading(true);

      try {
        await updatecampaign(dataNode.idgamification_campaign, form);

        if (denyAction) {
          const mensage = `Campanha ${dataNode?.newCampaing ? "criada" : "editada"} com sucesso!`;
          denyAction({ text: mensage, type: "success" });
          return;
        }
      } catch (e) {
        if (denyAction) {
          denyAction({ text: e.response.data.message, type: "error" }, true);
          return;
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const formatEvents = () => {
    const events = dataCampaing.events;
    const eventsWithValue = Object.keys(events)
      .filter((key) => events[key] || events[key] === "0")
      .map((elem) => ({
        idEvent: parseInt(elem),
        points: parseInt(events[elem]),
        conclude: 1,
        dynamicAttributes:
          dataCampaing.eventGradeAsMultiplier &&
          dataCampaing.eventGradeAsMultiplier.eventType === parseInt(elem)
            ? {
                gradeAsMultiplier: dataCampaing.eventGradeAsMultiplier.gradeAsMultiplier,
              }
            : null,
      }));

    return eventsWithValue;
  };

  const updateChangeIdCompany = async (id) => {
    if(!id){
      setLoading(true)

      try{
        setClearAll(true)
        await deleteAllGroups();
      }catch{

      }finally{
        setLoading(false)

      }


    }


  }

  const  deleteAllGroups = async () => {
    if(groups?.length){
      for (let index = 0; index < groups.length; index++) {
        await deleteGroupsCampaing(dataNode.idgamification_campaign, groups[index].idGroups);
      }
    }
  }
  return (
    <>
      <Modal
        show={showModal}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        onHide={() => {
          handleDeny();
        }}
      >
        <div
          className="modal-support-blur modal-support-blur-warning"
          style={{ borderRadius: 10 }}
        />
        <div
          onClick={() => handleDeny()}
          className="modal-warning-close-container"
          style={{ margin: 15, padding: 0, backgroundColor: "#6b48ff" }}
        >
          <CloseIcon style={{ color: "white" }} className="modal-warning-close" />
        </div>
        <div className="modal-principal-warning" style={{ minHeight: 400 }}>
          <Modal.Title style={{ textAlign: "center", fontSize: 20, marginTop: 20 }}>
            {title}
          </Modal.Title>

          <div className="modal-body-warning" style={{ padding: 20 }}>
            <div className="gamification-edit">
              {loading ? (
                <PreloaderBlur />
              ) : (
                <Form validated={validInputs} ref={formularioRef}>
                  <AccordionComponent
                    title="Informações Básicas"
                    removeIcon={true}
                    disableExpand={true}
                  >
                    <div className="flex-column">
                      <div className="form-data">
                        <Label name="Nome" htmlFor="title" />
                        <Form.Control
                          required
                          key={"nome"}
                          placeholder="Nome"
                          value={dataCampaing.name}
                          onChange={(e: any) => {
                            handleChange({ name: e.target.value });
                          }}
                          maxLength={100}
                          className="form-control"
                          type="text"
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo Obrigatório
                        </Form.Control.Feedback>
                      </div>
                      <div className="form-data">
                        <Label name="Descrição" htmlFor="description" />
                        <textarea
                          rows={4}
                          id="description"
                          aria-describedby="description"
                          maxLength={400}
                          className="form-control"
                          value={dataCampaing.description}
                          onChange={(e: any) => handleChange({ description: e.target.value })}
                        />
                        <CharacterCounter
                          characterNumber={dataCampaing.description?.length}
                          maxLength={400}
                        />
                      </div>
                    </div>
                  </AccordionComponent>
                  <AccordionComponent
                    title="Período de Realização"
                    removeIcon={true}
                    disableExpand={true}
                  >
                    <div className="form-group row">
                      <div className="form-data col-6">
                        <Form.Group>
                          <Label name="Data Início" htmlFor="startDate" />

                          <DatePicker
                            disabled={dataCampaing.disabledEdit}
                            required
                            selected={dataCampaing.startDate}
                            placeholder="Data Início"
                            name="startDate"
                            locale="pt-BR"
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            onChange={(date: any) => {
                              handleChange({ startDate: date, endDate: null });
                            }}
                          />
                          {!dataCampaing.startDate && validInputs && (
                            <div className="invalid-feedback" style={{ display: "block" }}>
                              Campo Obrigatório
                            </div>
                          )}
                        </Form.Group>
                      </div>
                      <div className="form-data col-6">
                        <Label name="Data Fim" htmlFor="endDate" />
                        <DatePicker
                          disabled={dataCampaing.disabledEdit || !dataCampaing.startDate}
                          startDate={dataCampaing.startDate}
                          minDate={dataCampaing.startDate}
                          placeholder="Data Fim"
                          selected={dataCampaing.endDate}
                          onChange={(date: any) => {
                            handleChange({ endDate: date });
                          }}
                          name="endDate"
                          locale="pt-BR"
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </AccordionComponent>
                  {menuData.admin === 2 && (
                    <AccordionComponent
                      title="Participantes"
                      removeIcon={true}
                      disableExpand={true}
                    >
                      <div className="flex-column">
                        <div className="flex-row space">
                          <div className="form-data" style={{ width: "100%" }}>
                            <Form.Control
                            disabled={dataCampaing.disabledEdit}
                              as="select"
                              value={dataCampaing.idCompany}
                              onChange={(event: any) => {
                                handleChange({ idCompany: event.target.value });
                                updateChangeIdCompany(event.target.value);
                              }}
                            >
                              <option value={0}>Selecionar empresa</option>
                              {columnsParticipantes?.map((elem) => (
                                <option key={elem.value} value={elem.value}>
                                  {elem.label}
                                </option>
                              ))}
                            </Form.Control>
                          </div>
                        </div>
                      </div>
                    </AccordionComponent>
                  )}
                  <AccordionComponent
                    title="Selecionar Publico-alvo"
                    removeIcon={true}
                    disableExpand={true}
                  >
                    <div className="flex-column">
                      <div className="flex-row space">
                        <div className="form-data" style={{ width: "100%" }}>
                          <Form.Control
                            disabled={
                              dataCampaing.disabledEdit ||
                              (!dataCampaing.idCompany && menuData.admin === 2)
                            }
                            as="select"
                            value={selectCampaing.idGroups}
                            onChange={(event: any) => {
                              const selectedIndex = event.target.selectedIndex;
                              setSelectCampaing({
                                idGroups: parseInt(event.target.value),
                                title: event.target.options[selectedIndex].text,
                              });
                            }}
                          >
                            <option value={0}>Selecionar Publico-alvo</option>
                            {selectTargetAudience?.map((elem) => (
                              <option key={elem.idGroups} value={elem.idGroups}>
                                {elem.title}
                              </option>
                            ))}
                          </Form.Control>
                        </div>
                        <Button
                          variant="warning"
                          disabled={
                            dataCampaing.disabledEdit ||
                            (!dataCampaing.idCompany && menuData.admin === 2)
                          }
                          className=""
                          onClick={() => {
                            handleClickSelectPublic();
                          }}
                        >
                          Adicionar
                        </Button>
                      </div>

                      <DataTable
                        columns={columns}
                        data={groups}
                        noHeader
                        fixedHeader
                        noDataComponent={<CustomNoDataComponent />}
                      />
                    </div>
                  </AccordionComponent>
                  <AccordionComponent
                    title="Ações e Pontuação"
                    removeIcon={true}
                    disableExpand={true}
                  >
                    <div className="container-divider">
                      <div className="flex-column">
                        <ActionScore
                          label={"Aluno"}
                          data={dataActionScore}
                          handleChange={handleChange}
                          dataCampaing={dataCampaing}
                          validated={validInputs}
                          disabled={dataCampaing.disabledEdit}
                        />
                      </div>
                      <div className="divider"></div>
                      <div className="flex-column">
                        <ActionScore />
                      </div>
                    </div>
                  </AccordionComponent>
                  <AccordionComponent title="Páginas" removeIcon={true} disableExpand={true}>
                    <ListMig
                      clearAll={clearAll}
                      setClearAll={setClearAll}
                      disabled={
                        dataCampaing.disabledEdit ||
                        (!dataCampaing.idCompany && menuData.admin === 2)
                      }
                      path={`campaign/${dataNode.idgamification_campaign}/categories`}
                      pathGet={`learning/categories/campaign/${dataNode.idgamification_campaign}`}
                    ></ListMig>
                  </AccordionComponent>
                  <AccordionComponent title="Status" removeIcon={true} disableExpand={true}>
                    <div className="form-data flex-row">
                      <Switch
                        checked={!!dataCampaing.status || false}
                        onChange={(e) => handleChange({ status: e.target.checked })}
                        color="primary"
                        id={"activeCampanha"}
                      />
                      <Label
                        className={"cursor-pointer"}
                        name="Ativar Campanha"
                        htmlFor={"activeCampanha"}
                      />
                    </div>
                  </AccordionComponent>
                </Form>
              )}
            </div>
          </div>
        </div>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => handleDeny()}>
            Cancelar
          </Button>

          <Button variant="warning" onClick={handleSubmit}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalDialog
        text={"Tem certeza de que deseja deletar esse Público-alvo?"}
        show={showModalFeedback}
        setShow={setShowModalFeedback}
        btnCall={{
          className: "",
          variantBtn: "danger",
          text: "Deletar",
          callEvent: () => {
            handleClickRemove(idRemoveGroup);
          },
        }}
      />
    </>
  );
};

export default GamificationEdit;
