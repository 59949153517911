// Basic
import { useEffect, useRef, useState } from "react";

import { Pie } from "react-chartjs-2";
import "./styles.scss";

// APIs
import { getDashboardsOverview } from "../../../../services/NexiDigitalAPI";

export default function Overview({ idCompanySelected }) {
  const [apiInfo, setApiInfo] = useState({
    infoLabel: ["Concluído", "Em andamento", "Não iniciado", "Expirado","Reprovado", 'Aprovado' ],
    infoContent: [0, 0, 0, 0, 0, 0],
  });

  const refChart = useRef(null);

  useEffect(() => {
    (async () => {
      if (idCompanySelected !== -1) {
        const containerApi = await getDashboardsOverview(idCompanySelected);
        const { COMPLETED, IN_PROGRESS, NOT_ATTEMPTED, EXPIRED, FAILED, PASSED } = containerApi.graphStatus;

        setApiInfo((it) => ({
          ...it,
          infoContent: [COMPLETED, IN_PROGRESS, NOT_ATTEMPTED, EXPIRED, FAILED, PASSED],
          ...containerApi,
        }));
      }
    })();
  }, [idCompanySelected]);

  const options = {
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          boxHeight: 10,
          boxWidth: 10,
          generateLabels: function(chart) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.labels.map((label, i) => {
                const dataset = data.datasets[0];
                const value = dataset.data[i];
                return {
                  text: `${label} (${value})`,
                  fillStyle: dataset.backgroundColor[i],
                  hidden: isNaN(dataset.data[i]) || dataset.data[i] === 0,
                  lineCap: 'round',
                  lineWidth: 2,
                };
              });
            }
            return [];
          },
        },
      },
      datalabels:{
        display: false,
      }

    },
    cutoutPercentage: 50
  };

  const colorArray = ["#333d9a", "#FFE200", "#118ae5", "#99aea7", "#FF8601", "#108000"];
  const info = {
    labels: apiInfo.infoLabel,
    datasets: [
      {
        data: apiInfo.infoContent,
        backgroundColor: colorArray,
        borderColor: ["transparent"],
        borderWidth: 2,
        pointBackgroundColor: "#fff",
        datalabels: {
          color: ["#fff", "#000", "#fff","#fff","#fff","#fff"],
        },
      },
    ],
  };

  const hasData = Boolean(apiInfo.infoContent.length);
  const noData = <div className="nenhumDado">Nenhum dado disponível</div>;

  const PieWrapper = () => {
    return (
      <div className="pieWrapper">
        {!hasData ? (
          noData
        ) : (
          <Pie ref={refChart} width={50} height={50} data={info} options={options} />
        )}
      </div>
    );
  };

  const ReportItem = ({ label, data }) => {
    return (
      <div className="report_item">
        <div className="report_item_data">{data}</div>
        <div className="report_item_text">{label}</div>
      </div>
    );
  };

  return (
    <div className="overviewBase">
      <div className="report_container">
        <div className="textLeft">Visão geral atual</div>
        <div className="selectPediodDiv">
          <div className="report_resume">
            <div className="report_group">
              <ReportItem label="Usuários ativos" data={apiInfo.users} />
              <ReportItem label="Usuários inativos" data={apiInfo.inactiveUsers} />
            </div>
            <ReportItem label="Treinamentos" data={apiInfo.trainings} />
            <ReportItem label="Turmas" data={apiInfo.teams} />
            <ReportItem label="Matrículas" data={apiInfo.enrollments} />
          </div>

          <div className="report_base">
            <div className="reportTitle">Status por matrícula</div>
            <PieWrapper />
          </div>
        </div>
      </div>
    </div>
  );
}
