import React, { useRef, useState, useEffect } from "react";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";

import { getMenuRoutes } from "utils/Utils";

import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import PopupState from "material-ui-popup-state";
import { IoPeopleOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import { LuEye } from "react-icons/lu";
import LinkGroupsVisibility from "components/PeopleCraft/NodeParamenters/components/LinkGroupsVisibility";
import { BiComment } from 'react-icons/bi';
import IcoBiArrow from "svg/BiArrow";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";
const styles = (theme) => ({
  paper: {
    // padding: theme.spacing.unit,
    marginLeft: 40,
    backgroundColor: "#343434",
    color: "#ffff",
  },
  list: {
    padding: 0,
  },
  menuItem: {
    display: "flex",
    gap: 10,
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "15px 40px",
    "&:hover": {
      backgroundColor: "rgba(70, 70, 70, 1);",
    },
  },
  menuItemInative: {
    // pointerEvents: "none",
    opacity: 0.2,
  },
  tooltipInative: {
    display: "none",
  },
});

const MenuActions = ({ branch, classes, deleteItem, viewTeam, setIsSidebarOpen }) => {
  const el = useRef();
  const history = useHistory()
  const [permissionLearningPage, setPermissionLearningPage] = useState(false);
  const [permissionLearningGroup, setPermissionLearningGroup] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(false);
  const [groupDisabled, setGroupDisabled] = useState(false);
  const [linkGroupModal, setlinkGroupModal] = useState(false);
  const isLoaded = useRef(false);

  /* [Permission Module] - Menu Options */
  const getMenuOption = () => {
    const menuOptionList = getMenuRoutes();
    const objCurrentMenu = menuOptionList.filter((menu) => menu.text === "Gestão de Aprendizagem");

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu) => menu.resource);
      try {
        if (
          Boolean(
            resources[0].filter((menu) => menu.name === "Gerenciar páginas de aprendizagem").length,
          )
        )
          setPermissionLearningPage(true);
        if (
          Boolean(
            resources[0].filter((menu) => menu.name === "Gerenciar turmas e matrículas").length,
          )
        )
          setPermissionLearningGroup(true);
      } catch (error) {
        console.log("Error setting permission: ", error);
      }
    }
  };

  // useEffect(() => {
  //   getMenuOption();
  // }, []);

  useEffect(() => {

    if(isLoaded.current){
      setPermissionLearningPage(false);
      setPermissionLearningGroup(false);
      setDeleteDisabled(false);
      setGroupDisabled(false);
      setlinkGroupModal(false);
    }

    setTimeout(() => {
      getMenuOption();
    }, 1000);

    isLoaded.current = true;

  }, [branch.status]);


  useEffect(() => {
    const permissionStatus = !permissionLearningPage || branch.redirect === 1;
    const deleteDisabledIt = branch.status !== "DRAFT" || permissionStatus;
    setDeleteDisabled(deleteDisabledIt);
  }, [permissionLearningPage]);

  useEffect(() => {
    const gDisabled =
      branch.status === "DRAFT" || branch.nodeType === "ORGANIZATION" || !permissionLearningGroup || branch.entryPoint === 0;
    setGroupDisabled(gDisabled);
  }, [permissionLearningGroup]);


  const isFirstNode = () => {
    let blIsFirstNode = false;

    if(branch.hasOwnProperty('nodeCode') && branch.nodeCode != null && branch != undefined){
      if(branch.nodeCode.split('.').length == 1){
        blIsFirstNode = true;
      }
    }

    return blIsFirstNode;
  }

  return (
    <>

      <Modal
          show={linkGroupModal}
          className={"modalLinkGroup"}
          onHide={() => {
            setlinkGroupModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Configurações de visibilidade</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LinkGroupsVisibility
                nodeCode={branch.nodeCode}
            />
          </Modal.Body>
      </Modal>

      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => {
          return (
            <React.Fragment>
              <div
                ref={el}
                className="infoMenu"
                onClick={(e) => {
                  popupState.setOpen(true, el.current);
                  e.stopPropagation();
                }}
              >
                <span style={popupState.isOpen ? { color: "#ffffff" } : {}}>•••</span>
              </div>
              <Menu
                classes={{
                  paper: classes.paper,
                  list: classes.list,
                }}
                onClick={(e) => {
                  popupState.setOpen(false, el.current);
                  e.stopPropagation();
                }}
                anchorEl={el.current}
                open={popupState.isOpen}
                // {...bindMenu(popupState)}
              >
                <OverlayTrigger
                  placement={"right"}
                  overlay={
                    <Tooltip
                      id={`tooltip-top`}
                      style={{
                        lineHeight: 1.2,
                        opacity: groupDisabled ? 1 : 0,
                      }}
                    >
                      {groupDisabled
                        ? branch.status === "DRAFT"
                          ? 'A ação não pode ser realizada porquê esta página ainda não está publicada. Para criar uma turma, altere o seu status para "Publicado"'
                          : branch.nodeType === "ORGANIZATION"
                          ? 'A ação não pode ser realizada porquê esta página é do tipo "Organização".'
                          : branch.entryPoint === 0
                          ? 'A ação não pode ser realizada porquê esta página não permite matrícula".'
                          : "Você não tem permissão para selecionar grupos."
                        : String(branch.status)}
                    </Tooltip>
                  }
                >
                  <MenuItem
                    className={
                      classes.menuItem + ` ${groupDisabled ? classes.menuItemInative : ""}`
                    }
                    onClick={(e) => {
                      popupState.setOpen(false, el.current);
                      if (!groupDisabled) viewTeam(branch);
                      e.stopPropagation();
                    }}
                  >
                    <IoPeopleOutline /> <span>Ver turmas</span>
                  </MenuItem>
                </OverlayTrigger>


                {( !isFirstNode()) &&
                  <MenuItem
                    className={
                      classes.menuItem +   ` ${!permissionLearningPage ? classes.menuItemInative : ""}`
                    }
                    onClick={(e) => {
                      // console.log(branch)
                      if(!permissionLearningPage){
                        e.stopPropagation()
                        return
                      }
                      popupState.setOpen(false, el.current);
                      setlinkGroupModal(true);
                      e.stopPropagation();
                    }}
                  >
                    <LuEye /> <span>Visibilidade da página</span>
                  </MenuItem>
                }

                {
                  //add regra
                  branch.nodeType === 'LEARNING' && (
                    <MenuItem
                    className={
                      classes.menuItem +   ` ${!permissionLearningPage ? classes.menuItemInative : ""}`
                    }
                    onClick={(e) => {
                      // console.log(branch)
                      if(!permissionLearningPage){
                        e.stopPropagation()
                        return
                      }
                     history.push(`/gestao-de-aprendizagem/equivalencia-entre-paginas/${branch.idNode}`);

                    }}
                  >
                      {parse(`${IcoBiArrow()}`)} <span>Equivalência entre páginas</span>
                  </MenuItem>
                  )
                }

                {(branch?.hasDadEntryPoint != 1 && !isFirstNode() && branch.nodeType === 'LEARNING') &&
                  <MenuItem
                    className={
                      classes.menuItem
                    }
                    onClick={(e) => {
                      setIsSidebarOpen(true, branch)
                    }}
                  >
                    <BiComment /> <span>Interação da página</span>
                  </MenuItem>
                }


                <OverlayTrigger
                  placement={"right"}
                  overlay={
                    <Tooltip
                      style={{
                        lineHeight: 1.2,
                        opacity: deleteDisabled ? 1 : 0,
                      }}
                      id={`tooltip-top`}
                    >
                      {deleteDisabled
                        ? branch.status !== "DRAFT"
                          ? 'A ação não pode ser realizada porquê esta página já não está mais como "Rascunho".'
                          : `Você não tem permissão para deletar conteúdos.`
                        : String(branch.status)}
                    </Tooltip>
                  }
                >
                  <MenuItem
                    className={
                      classes.menuItem + ` ${deleteDisabled ? classes.menuItemInative : ""}`
                    }
                    onClick={(e) => {
                      popupState.setOpen(false, el.current);
                      if (!deleteDisabled) deleteItem(branch);
                      e.stopPropagation();
                    }}
                  >
                    <RiDeleteBin6Line /> <span>Excluir</span>
                  </MenuItem>
                </OverlayTrigger>
              </Menu>
            </React.Fragment>
          );
        }}
      </PopupState>
    </>
  );
};

export default withStyles(styles)(MenuActions);
