import React from "react";
import styles from "./styles.module.scss";

const InputWithLabel = ({ Label, Value }) => {
  return (
    <>
      <label htmlFor="cadastroProdutoModalTitulo" className={styles.modalFormLabel}>
        {Label}
      </label>
      <input type="text" className="form-control" value={Value} />
    </>
  );
};

export default InputWithLabel;
