import { format, differenceInHours } from "date-fns";

function hexToRgbArray(hex) {
  // Remove o '#' se presente
  hex = hex.replace(/^#/, "");

  // Se o hexadecimal for shorthand (ex: #03F), expandir
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map(function (char) {
        return char + char;
      })
      .join("");
  }

  // Converter para RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  return [r, g, b];
}

const getDateNowFormatter = () => {
  const today = new Date();

  const day = String(today.getDate()).padStart(2, "0"); // Dia com dois dígitos
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Mês (0-indexado, por isso +1)
  const year = today.getFullYear(); // Ano

  const hours = String(today.getHours()).padStart(2, "0"); // Hora com dois dígitos
  const minutes = String(today.getMinutes()).padStart(2, "0"); // Minutos com dois dígitos

  return `${day}/${month}/${year} - ${hours}:${minutes}`;
};

const getDateCompletionFormatter = (startDate, finishDate) => {
  // Formatar a data de início e fim
  const start = new Date(startDate);
  const finish = new Date(finishDate);

  // Usar o `date-fns` para formatar a data e hora
  const formattedStartDate = format(start, "dd/MM/yyyy");
  const formattedStartTime = format(start, "HH:mm");
  const formattedFinishTime = format(finish, "HH:mm");

  const dateCompletion = `${formattedStartDate} das ${formattedStartTime} às ${formattedFinishTime}`;

  // Calcula a diferença em horas
  const hoursDifference = differenceInHours(finish, start) + "h";

  return { date: dateCompletion, hoursDifference: hoursDifference };
};

export const ModelHeader = (turmaTitle, pageTitle, currentMeeting) => {
  const _menuData = JSON.parse(localStorage.getItem("menu-data"));
  // const _appDetails = JSON.parse(localStorage.getItem("appDetails"));

  const primary = String(localStorage.getItem("primaryColor"));
  const secondary = String(localStorage.getItem("secondaryColor"));
  const third = String(localStorage.getItem("thirdColor"));
  const fourth = String(localStorage.getItem("fourthColor"));

  const primaryColor = hexToRgbArray(primary);
  const secondaryColor = hexToRgbArray(secondary);
  const thirdColor = hexToRgbArray(third);
  const fourthColor = hexToRgbArray(fourth);
  const logoUrl = String(localStorage.getItem("headerIcon"));

  const dateNow = getDateNowFormatter();

  const dateCompletion = getDateCompletionFormatter(
    currentMeeting.startDate,
    currentMeeting.finishDate,
  );

  const ModelHeaderObj = {
    meeting: turmaTitle,
    page: pageTitle,
    speaker: currentMeeting.speaker || "-",
    address: currentMeeting.address || "-",
    dateNow: dateNow,
    author: _menuData.name,
    dateCompletion: dateCompletion.date,
    hoursDifference: dateCompletion.hoursDifference,
    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
    thirdColor: thirdColor,
    fourthColor: fourthColor,
    logoUrl: logoUrl,
  };

  return ModelHeaderObj;
};
