import React, { useCallback, useContext, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./index.scss";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { useEffect } from "react";
import { FiTrash2 } from "react-icons/fi";
import { CircularProgress, Drawer, TextareaAutosize } from "@material-ui/core";
import { ElementList } from "./NodeTypes/ElementList";
import { nodeTypes } from "./NodeTypes";
import Skeleton from "@material-ui/lab/Skeleton";
import { CatalogCard } from "./NodeTypes/CatalogCard";
import { getMenuRoutes, getParametersTranslation } from "../../../utils/Utils";
import { InProgressCard } from "./NodeTypes/InProgressCard";
import { statusTypes } from "../ContentPage/StatusTypes";
import { Meeting } from "./NodeTypes/Meeting";
import { FaRegEdit } from "react-icons/fa";
import { editLearningBlockParameter, getMenuData } from "services/NexiDigitalAPI";
import { Form } from "react-bootstrap";
import { Switch } from "antd";
import { LMCreateContext } from "../LearningManagementNew/Contexts/LMContext";
import PlaceholderParameters from "../LearningManagementNew/Components/DynamicParameters/components/PlaceholderParameters";
import { RecommendationCard } from "./NodeTypes/RecommendationCard";
import { CampaignCard } from "./NodeTypes/CampaignCard";
const ContentType = ({
  block,
  updateBlock,
  saveBlock,
  baseNodeLevel,
  permissionLearningPage,
  index,
}) => {
  switch (block.blockType) {
    case nodeTypes.TITLE:
      return (
        <TextareaAutosize
          key={block.idBlock}
          placeholder="Digite um Título"
          className="node-manager-type-title"
          value={block.title || ""}
          spellCheck="false"
          onChange={(event) => updateBlock(block, event, "title")}
          onBlur={(event) => saveBlock(block, event, "title")}
          onKeyDown={(input) => {
            if (input.key === "Enter") {
              input.target.blur();
            }
          }}
        />
      );
    case nodeTypes.TEXT:
      return (
        <TextareaAutosize
          key={block.idBlock}
          placeholder="Digite um texto/descrição"
          className="node-manager-type-text"
          value={block.text || ""}
          spellCheck="false"
          onChange={(event) => updateBlock(block, event, "text")}
          onBlur={(event) => saveBlock(block, event, "text")}
          onKeyDown={(input) => {
            if (input.key === "Enter") {
              input.target.blur();
            }
          }}
        />
      );
    case nodeTypes.LIST:
      return (
        <ElementList
          key={block.idBlock}
          initialData={block.elements || []}
          blockData={block}
          baseNodeLevel={baseNodeLevel}
          permissionLearningPage={permissionLearningPage}
          index={index}
        />
      );
    case nodeTypes.MASTER_BANNER:
      return (
        <ElementList
          key={block.idBlock}
          initialData={block.elements || []}
          blockData={block}
          baseNodeLevel={baseNodeLevel}
          permissionLearningPage={permissionLearningPage}
          index={index}
        />
      );
    case nodeTypes.MEETINGS:
      return <Meeting />;
    case nodeTypes.GRID:
    case nodeTypes.CAROUSEL:
      return (
        <ElementList
          key={block.idBlock}
          hasMaxCards
          initialData={block.elements || []}
          blockData={block}
          baseNodeLevel={baseNodeLevel}
          permissionLearningPage={permissionLearningPage}
          index={index}
        />
      );
    case nodeTypes.CATALOG:
      return <CatalogCard blockData={block} />;
    case nodeTypes.CALENDAR:
      return <div style={{ fontWeight: "bold", fontSize: 22 }}>Agenda de eventos</div>;
    case nodeTypes.IN_PROGRESS:
      return <InProgressCard hasMaxCards blockData={block} />;
    case nodeTypes.RECOMMENDATION:
      return <RecommendationCard hasMaxCards blockData={block} />;
    case nodeTypes.CAMPAIGN:
      return <CampaignCard hasMaxCards blockData={block} />;
    default:
      return <div>Tipo de nó não existente</div>;
  }
};

const getSubtitleByBlockType = (blockType) => {
  switch (blockType) {
    case nodeTypes.TITLE:
      return "Título";
    case nodeTypes.TEXT:
      return "Texto/Descrição";
    case nodeTypes.LIST:
      return "Lista de conteúdo";
    case nodeTypes.MASTER_BANNER:
      return "Master Banner";
    case nodeTypes.CAROUSEL:
      return "Seu conteúdo será visualizado como carrossel";
    case nodeTypes.GRID:
      return "Seu conteúdo será visualizado como grade";
    case nodeTypes.CATALOG:
      return "Catálogo";
    case nodeTypes.CALENDAR:
      return "Agenda";
    case nodeTypes.IN_PROGRESS:
      return "Em Andamento";
    case nodeTypes.RECOMMENDATION:
      return "Seu conteúdo será exibido como recomendação";
    case nodeTypes.MEETINGS:
      return "Encontros";
    case nodeTypes.CAMPAIGN:
      return "Banner promocional";
    default:
      return "";
  }
};

const getDrawerSubtitleByBlockType = (blockType) => {
  switch (blockType) {
    case nodeTypes.TITLE:
      return "Título";
    case nodeTypes.TEXT:
      return "Texto/Descrição";
    case nodeTypes.LIST:
      return "Lista de conteúdo";
    case nodeTypes.MASTER_BANNER:
      return "Master Banner";
    case nodeTypes.CAROUSEL:
      return "Carrossel";
    case nodeTypes.GRID:
      return "Grade";
    case nodeTypes.CATALOG:
      return "Catálogo";
    case nodeTypes.CALENDAR:
      return "Agenda";
    case nodeTypes.IN_PROGRESS:
      return "Em Andamento";
    default:
      return blockType || "";
  }
};

const CardList = ({
  cards,
  updateBlock,
  removeBlock,
  loadingRemoveBlock,
  loadingMoveBlock,
  saveBlock,
  baseNodeLevel,
  permissionLearningPage,
  nodeData,
  isDragging,
  toggleDrawer,
  setCardIdBlock,
  setStatusDynamic,
}) => {
  const [showEdit, setShowEdit] = useState(null);
  const { idCompany } = getMenuData()
  const blockEditRedirect = !permissionLearningPage ||  (!!nodeData.redirect && (!nodeData.idCompany || nodeData.idCompany !== idCompany));

  return cards?.map((card, index) => (
    <div
      style={{
        border:
          isDragging === false && showEdit === card.idBlock && !blockEditRedirect
            ? "1px solid #6b48ff"
            : "1px solid transparent",
      }}
      className="node-manager-node-item-wrapper-editor"
      onMouseEnter={() => {if(blockEditRedirect) return; isDragging === false && setShowEdit(card.idBlock)}}
      onMouseOver={() =>  {if(blockEditRedirect) return;  showEdit === false && isDragging === false && setShowEdit(card.idBlock)}}
      onMouseLeave={() =>{ if(blockEditRedirect) return;  isDragging === false && setShowEdit(false)}}
      key={card.idBlock}
    >
      {showEdit === card.idBlock && isDragging === false && (
        <div className="node-manager-node-item-editor">
          <div
            onClick={() =>
              blockEditRedirect
                ? null
                : (setCardIdBlock(card.idBlock), setStatusDynamic("EDIT_BLOCK_PARAMETERS"))
            }
            className={`node-manager-node-editor-actions ${blockEditRedirect ? "" : "node-purple"}`}
          >
            <FaRegEdit style={{ fontSize: 22, marginRight: 3 }} />
            <span>Editar</span>
          </div>
          {card.blockType !== nodeTypes.MEETINGS &&
            card.blockType !== nodeTypes.CAMPAIGN  ? (
            <div
              className={`node-manager-node-editor-actions ${
                blockEditRedirect || ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData.status) &&
                nodeData?.nodeType !== "ORGANIZATION")
                  ? ""
                  : "node-red"
              }`}
              onClick={() =>
                blockEditRedirect ||
                !permissionLearningPage ||
                ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData.status) &&
                  nodeData?.nodeType !== "ORGANIZATION")
                  ? null
                  : removeBlock(card.idBlock)
              }
              style={{
                pointerEvents:
                  ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData.status) &&
                    nodeData?.nodeType !== "ORGANIZATION") ||
                  !permissionLearningPage ||
                  loadingRemoveBlock
                    ? "none"
                    : "auto",
              }}
            >
              {loadingRemoveBlock === card.idBlock ? (
                <CircularProgress size={22} style={{ marginRight: 3 }} />
              ) : (
                <FiTrash2 style={{ fontSize: 22, marginRight: 3 }} />
              )}
              <span>Remover</span>
            </div>
          ) : null}
        </div>
      )}

      {showEdit !== card.idBlock && loadingRemoveBlock === card.idBlock && (
        <div className="node-manager-node-delete-loading">
          <CircularProgress size={20} />
        </div>
      )}

      <Draggable
        key={card.idBlock}
        draggableId={String(card.idBlock)}
        index={index}
        isDragDisabled={loadingMoveBlock || !permissionLearningPage}

      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className="node-manager-node-item-wrapper"
          >
            <div
              className="node-manager-node-item-draggable-container"
              {...provided.dragHandleProps}
            >
              <div className="node-manager-node-item-draggable-color" />
              <div className="node-manager-node-item-draggable-dots">
                <DragIndicatorIcon style={{ fontSize: 35, color: "lightgray", marginBottom: -8 }} />
                <DragIndicatorIcon style={{ fontSize: 35, color: "lightgray" }} />
              </div>
            </div>
            <div className="node-manager-node-item-content">
              <ContentType
                block={card}
                updateBlock={updateBlock}
                saveBlock={saveBlock}
                baseNodeLevel={baseNodeLevel}
                permissionLearningPage={permissionLearningPage}
                index={index}
              />
            </div>
            <span className="node-manager-node-item-subtitle">
              *{getSubtitleByBlockType(card.blockType)}
            </span>
          </div>
        )}
      </Draggable>
    </div>
  ));
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const BlockList = ({
  removeBlock,
  loadingRemoveBlock,
  loadingAddBlock,
  loadingMoveBlock,
  onChangeOrder,
  onElementChangeOrder,
  onElementMove,
  updateBlock,
  saveBlock,
  baseNodeLevel,
}) => {
  const { setStatusDynamic, nodeData, setNodeData, cardIdBlock, setCardIdBlock } =
    useContext(LMCreateContext);

  const [permissionLearningPage, setPermissionLearningPage] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [loadingBlockParameters, setLoadingBlockParameters] = useState(false);
  const [blockData, setBlockData] = useState(null);
  const [blockDataError, setBlockDataError] = useState(null);

  function onDragEnd(result) {
    setIsDragging(false);
    if (!result.destination) {
      return;
    }

    // do not move if its the same place
    if (
      result.destination.index === result.source.index &&
      result.destination.droppableId === result.source.droppableId
    ) {
      return;
    }

    const isBlock = result.source.droppableId.includes("block-list");

    if (isBlock) {
      const cards = reorder(nodeData.blocks, result.source.index, result.destination.index);

      const draggedItem = nodeData.blocks.find(
        (item) => String(item.idBlock) === result.draggableId,
      );
      const orderNum = result.destination.index + 1;

      const changeOrderData = {
        idBlock: draggedItem.idBlock,
        orderNum,
      };
      onChangeOrder(changeOrderData);
      setNodeData((prevNodeData) => ({
        ...prevNodeData,
        blocks: cards,
      }));
    } else {
      // moving elements from element list
      const destinationElementIndex = parseInt(result.destination.droppableId.match(/\d+/)[0]);
      const sourceElementIndex = parseInt(result.source.droppableId.match(/\d+/)[0]);

      let newState = [...nodeData.blocks];
      const isMovingThroughBlocks = result.source.droppableId !== result.destination.droppableId;
      if (isMovingThroughBlocks) {
        // moving element trhough different blocks
        const sourceElementCardList = newState[sourceElementIndex].elements;
        const destinationElementCardList = newState[destinationElementIndex]?.elements || [];
        const sourceIndex = sourceElementCardList.findIndex(
          (item) => String(item.idBlockElement) === result.draggableId,
        );
        const destinationIndex = result.destination.index; // Index in the destination array where the object should be placed
        const idBlockElement = sourceElementCardList[sourceIndex].idBlockElement;
        const destinationIdBlock = newState[destinationElementIndex].idBlock;

        const elementMoveData = {
          idBlockElement: idBlockElement,
          orderNum: destinationIndex + 1,
          idBlock: destinationIdBlock,
        };

        onElementMove(elementMoveData);

        // Remove the object from the source array
        const [removedObject] = newState[sourceElementIndex].elements?.splice(sourceIndex, 1);

        // Insert the removed object into the destination array at the specified index
        if (destinationElementCardList.length > 0) {
          destinationElementCardList?.splice(destinationIndex, 0, removedObject);
        } else {
          newState[destinationElementIndex].elements = [removedObject];
        }
      } else {
        // moving element from the same block
        const elementCardList = nodeData.blocks[destinationElementIndex].elements;

        const elementCardListReordered = reorder(
          elementCardList,
          result.source.index,
          result.destination.index,
        );
        const draggedElementItem = elementCardList.find(
          (item) => String(item.idBlockElement) === result.draggableId,
        );
        const elementOrderNum = result.destination.index + 1;

        const elementChangeOrderData = {
          idBlockElement: draggedElementItem?.idBlockElement,
          orderNum: elementOrderNum,
        };
        onElementChangeOrder(elementChangeOrderData);

        newState[destinationElementIndex].elements = elementCardListReordered;
      }
      setNodeData((prevNodeData) => ({
        ...prevNodeData,
        blocks: newState,
      }));
    }
  }

  /* [Permission Module] - Menu Options */
  const getMenuOption = () => {
    const menuOptionList = getMenuRoutes();
    const objCurrentMenu = menuOptionList.filter((menu) => menu.text === "Gestão de Aprendizagem");

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu) => menu.resource);

      getPermissionsPage(resources);
    }
  };

  /* [Permission Module] - Permission attrs in page by login data information */
  const getPermissionsPage = (resources) => {
    try {
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Gerenciar páginas de aprendizagem").length,
        )
      )
        setPermissionLearningPage(true);
    } catch (error) {
      console.log("Error setting permission: ", error);
    }
  };

  const changeBlockDepth = useCallback((idBlock, depth) => {
    const blockIndex = nodeData?.blocks?.findIndex((node) => node.idBlock === idBlock);
    const newNodeData = { ...nodeData };

    if (blockIndex >= 0) {
      newNodeData.blocks[blockIndex].parameters.depth = Number(depth) || "";
      setNodeData(newNodeData);
    }
  }, []);

  const editParameter = useCallback((newData, currentdata) => {
    const parameterData = { name: currentdata.name, value: newData };

    editLearningBlockParameter(currentdata.idBlock, parameterData).catch((err) => {
      console.log("error editing", err);
    });
    if (currentdata.name === "depth") {
      changeBlockDepth(currentdata.idBlock, newData);
    }
  }, [changeBlockDepth]);

  const RenderInput = useCallback((parameter) => {
    switch (parameter) {
      case "LIST":
        return (
          <Form.Control
            as="select"
            defaultValue={parameter.value}
            onChange={(e) => {
              editParameter(e.target.value, parameter);
            }}
          >
            {parameter?.restrictions?.split("|").map((restriction, indexR) => (
              <option value={restriction} key={indexR}>
                {getParametersTranslation(restriction)}
              </option>
            ))}
          </Form.Control>
        );
      case "BOOLEAN":
        return (
          <div style={{ with: 70 }}>
            <Switch
              color="primary"
              className="custom-switch"
              defaultChecked={Boolean(Number(parameter.value))}
              onChange={(checked) => editParameter(checked ? "1" : "0", parameter)}
            />
          </div>
        );
      default:
        return !isNaN(Number(parameter.restrictions)) && Number(parameter.restrictions) > 200 ? (
          <textarea
            defaultValue={parameter.value}
            onBlur={(e) => {
              const valueTextArea = e.target.value.length > 0 ? e.target.value : null;

              editParameter(valueTextArea, parameter);
            }}
            maxLength={Number(parameter.restrictions) ? Number(parameter.restrictions) : undefined}
            rows={5}
            className="form-control"
            type="text"
          />
        ) : (
          <></>
        );
    }
  }, [editParameter]);

  useEffect(() => {
    getMenuOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Drawer anchor={"right"}>
        <div className="node-manager-drawer-container">
          {loadingBlockParameters ? (
            // <Loading center>Carregando parametros...</Loading>
            <PlaceholderParameters number={10} />
          ) : blockDataError ? (
            <div className="node-manager-error">Erro ao carregar dados do bloco</div>
          ) : (
            blockData && (
              <>
                <div className="node-manager-drawer-header">
                  {getDrawerSubtitleByBlockType(blockData?.blockType)}
                </div>
                {blockData?.parameters
                  ?.filter((parameter) => parameter.hidden !== 1)
                  ?.map((parameter, index) => (
                    <div className="node-manager-drawer-item" key={index}>
                      {parameter.label}
                      {RenderInput(parameter)}
                    </div>
                  ))}
              </>
            )
          )}
        </div>
      </Drawer>
      <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={() => setIsDragging(true)}>
        <Droppable type="block-list" droppableId="block-list">
          {(provided) => (
            <div className="block-list" ref={provided.innerRef} {...provided.droppableProps}>
              <CardList
                cardIdBlock={cardIdBlock}
                setStatusDynamic={setStatusDynamic}
                setCardIdBlock={setCardIdBlock}
                isDragging={isDragging}
                cards={nodeData.blocks || []}
                updateBlock={updateBlock}
                removeBlock={removeBlock}
                loadingRemoveBlock={loadingRemoveBlock}
                loadingMoveBlock={loadingMoveBlock}
                saveBlock={saveBlock}
                baseNodeLevel={baseNodeLevel}
                permissionLearningPage={permissionLearningPage}
                nodeData={nodeData}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {loadingAddBlock && (
        <Skeleton
          variant="rect"
          width={"90%"}
          height={90}
          style={{ borderRadius: 10, marginBottom: 35, maxWidth: 600 }}
        />
      )}
    </>
  );
};
