import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import "./index.css";
import { colors } from "../../utils/Colors";
import { getMenuRoutes, transformText, getMenuIconTypes } from "../../utils/Utils";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import ModalWarning from "../../components/ModalWarning";

import { FiAlignJustify } from "react-icons/fi";
import { MdClose } from "react-icons/md";

function SideBar({ location }) {
  const menuData = JSON.parse(localStorage.getItem("menu-data"));
  const appDetails = JSON.parse(localStorage.getItem("appDetails")) || null;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  // const [expanded, setExpanded] = useState(isMobile ? false : getDefaultExpanded());
  const [expanded, setExpanded] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const filterMenuOptions = getMenuRoutes().filter(function (opc) {
    return opc.enabled == 1 
  });

  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth <= 640);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  function getDefaultExpanded() {
    if (location.pathname === "/") {
      return false;
    } else {
      return true;
    }
  }

  function getMenuIcon(idSectionType) {
    const IconComponent = getMenuIconTypes(idSectionType);

    return <IconComponent style={{ color: colors.gray, fontSize: 23 }} className="sidebar-icon" />;
  }

  return (
    <div
      className={expanded ? "sidebar-container" : "sidebar-container-expanded"}
      style={{ backgroundColor: colors.blackSmooth }}
    >
      <div
        className={`sidebar-hamburguer-hit ${expanded ? "sidebar-hamburguer-hit-active" : ""}`}
        onClick={() => {
          setExpanded(false);
        }}
      >
        {" "}
      </div>
      <div className={`sidebar-hamburguer-container`}>
        {expanded ? (
          <div className="sideBar-expanded-top">
            <MdClose
              className="sidebar-hamburguer-close"
              onClick={() => setExpanded(!expanded)}
              style={{ color: colors.gray }}
            />
            <div className="sideBar-expanded-img">
              <img
                src={appDetails?.logo}
                // style={{ height: 20, marginleft: 15, cursor: "pointer" }}
                onClick={() => setOpenModal(true)}
              />
            </div>
          </div>
        ) : (
          <FiAlignJustify
            className="sidebar-hamburguer"
            onClick={() => setExpanded(!expanded)}
            style={{ color: colors.gray }}
          />
        )}
      </div>

      <div className="itens-sidebar-menu">
        {expanded && (
          <NavLink
            exact
            to={`/`}
            activeClassName={"sidebar-item-active"}
            className={"sidebar-item-container"}
            onClick={() => {
              // if (isMobile) setExpanded(!expanded);
              setExpanded(!expanded);
            }}
          >
            <HomeIcon style={{ color: colors.gray }} className="sidebar-icon" />
            <span className="sidebar-item-text" style={{ color: colors.gray }}>
              Início
            </span>
          </NavLink>
        )}

        {expanded &&
          filterMenuOptions?.map((item, index) => {
            return (
              <NavLink
                activeClassName={"sidebar-item-active"}
                key={index}
                to={`/${transformText(item.text)}/${item.sectionTo}`}
                className={"sidebar-item-container"}
                onClick={() => {
                  // if (isMobile) setExpanded(!expanded);
                  setExpanded(!expanded);
                }}
              >
                <div>{getMenuIcon(item.idSectionType)}</div>
                <span className="sidebar-item-text" style={{ color: colors.gray }}>
                  {item.text}
                </span>
              </NavLink>
            );
          })}
      </div>

      {/* {(!isMobile || (expanded && isMobile)) && (
        <div
          className={`sidebar-header ${!expanded ? "sidebar-header-hide" : ""}`}
          style={{ color: colors.gray, cursor: "pointer" }}
          onClick={() => setOpenModal(true)}
        >
          <img src={appDetails?.logo} style={{ height: 50, borderRadius: 5, marginBottom: 15 }} />
          <span style={{ whiteSpace: "nowrap" }}>Redirecionar para o aplicativo</span>
        </div>
      )}*/}
      {openModal && (
        <ModalWarning
          description={
            <div style={{ textAlign: "center" }}>Você será redirecionado para aplicação!</div>
          }
          acceptAction={() => {
            window.open(`https://${appDetails.domain}/#/login?token=${menuData?.token}`);
            setOpenModal(false);
          }}
          denyAction={() => setOpenModal(false)}
        />
      )}
    </div>
  );
}

export default withRouter(SideBar);
