import React, { memo, useMemo } from "react";
import { Tooltip } from "@material-ui/core";
import IcoTrash from "svg/Trash";
import parse from "html-react-parser";

const DeleteRow = memo(({ data, handleClick }) => {
  const disabledDelete = useMemo(() =>
      data?.versionNumber > 1 || data?.alreadySigned
  , [ data?.versionNumber, data.alreadySigned])
  return(
  <Tooltip
      title={
        disabledDelete
          ? "Este termo não pode ser excluído, pois já foi aceito por alguns usuários"
          : `Deletar`
      }
      placement="top"
    >
      <span
        className={`btn btn-sm float-right icon`}
        onClick={() => handleClick(data?.idTermOfResponsibility)}
      >
        <span className={`delete-term ${disabledDelete  && "delete-disabled"}`}>
          {parse(`${IcoTrash()}`)}
        </span>
      </span>
    </Tooltip>
  )

});

export default DeleteRow;
