import React, { useState, useCallback, useEffect } from "react";
import styles from "./styles.module.scss";
import DayAccess from "../DayAccess";
import ResumeCards from "../ResumeCards";
import moment from "moment";
import "moment/locale/pt-br.js";
import { DatePicker, ConfigProvider } from "antd";
import ptBR from "antd/es/locale/pt_BR";
const { RangePicker } = DatePicker;

const GeneralLookDays = (props) => {
  const [valuePeriod, setValuePeriod] = useState();

  const [firstElem, setFirstElem] = useState({
    date: moment(new Date()).subtract(6, "days").format("YYYY-MM-DD"),
    day: moment(new Date()).subtract(6, "days").format("dddd"),
  });
  const [lastElem, setLastElem] = useState({
    date: moment(new Date()).format("YYYY-MM-DD"),
    day: moment(new Date()).format("dddd"),
  });
  const [hideData, setHideData] = useState(true);
  const [dates, setDates] = useState([]);

  const [value, setValue] = useState([
    moment(firstElem.date, ["YYYY-MM-DD"]),
    moment(new Date(), ["YYYY-MM-DD"]),
  ]);

  useEffect(() => {
    if (props.filter) {
      changePeriod(props.filter.periodGlobal);
      setValuePeriod(props.filter.periodGlobal);
      if (props.filter.dateGlobal.length < 2) {
        nullfyDate();
        setValue(value);
        setDates(value);
        handleFilter(value);
      } else {
        setValue(props.filter.dateGlobal);
        setDates(props.filter.dateGlobal);
        handleFilter(props.filter.dateGlobal);
      }
    }
  }, [props]);

  const changePeriod = (value) => {
    setValuePeriod(value);
    const eventValue = value;
    switch (eventValue) {
      case "0":
        setHideData(true);
        editData(moment(new Date()).subtract(6, "days"), moment(new Date()));
        break;
      case "1":
        setHideData(true);
        editData(moment(new Date()).subtract(29, "days"), moment(new Date()));
        break;
      case "2":
        setHideData(false);
        break;
      default:
        break;
    }
  };

  const editData = (start, end) => {
    setFirstElem({
      date: start.format("YYYY-MM-DD"),
      day: start.format("dddd"),
    });
    setLastElem({
      date: end.format("YYYY-MM-DD"),
      day: end.format("dddd"),
    });
  };

  const nullfyDate = () => {
    setLastElem({
      date: null,
      day: null,
    });
    setLastElem({
      date: null,
      day: null,
    });
  };

  const styleData = hideData ? { display: "none" } : { display: "inline-block" };

  const disabledDate = (current) => {
    return current && current > moment();
  };

  function handleFilter(val = false) {
    let _value = val || value;
    if (_value && _value[0] && _value[1]) {
      setFirstElem({
        date: moment(_value[0]).format("YYYY-MM-DD"),
        day: moment(_value[0]).format("dddd"),
      });
      setLastElem({
        date: moment(_value[1]).format("YYYY-MM-DD"),
        day: moment(_value[1]).format("dddd"),
      });
    } else {
      nullfyDate();
    }
  }

  return (
    <div className={styles.report_container}>
      <div className={styles.report_graph} style={{ width: "100%" }}>
        <div className={styles.report_title}>
          <div className={styles.textLeft} style={{ marginBottom: "40px" }}>
            Visão geral por dia
          </div>
          <div className={styles.selectPediodDiv}>
            <select
              value={valuePeriod}
              className={styles.selectPediodSelection}
              onChange={(event) => changePeriod(event.target.value)}
            >
              <option value="0">Últimos 7 dias</option>
              <option value="1">Últimos 30 dias</option>
              <option value="2">Qualquer período</option>
            </select>
          </div>
          <div style={styleData} className={styles.dynamicPeriod}>
            <ConfigProvider locale={ptBR}>
              <RangePicker
                value={value}
                disabledDate={disabledDate}
                onCalendarChange={(val) => {
                  if (val && val.length === 2 && moment(val[1]).diff(moment(val[0]), "days") > 6) {
                    //val[1] = moment(val[0]).add(6, "days");
                  }
                  setDates(val);
                  if (!val) {
                    nullfyDate();
                  } else {
                    handleFilter(val);
                  }
                }}
                defaultValue={[
                  moment(firstElem.date, ["YYYY-MM-DD"]),
                  moment(lastElem.date, ["YYYY-MM-DD"]),
                ]}
                onChange={(val) => setValue(val)}
                format={["YYYY-MM-DD", "DD/MM/YY", "MM/YY"]}
                size={"large"}
              />
            </ConfigProvider>
          </div>
        </div>
        <div className={styles.report_container_content}>
          <DayAccess
            idGroupGlobal={props.filter.idGroupGlobal}
            hideData={hideData}
            nullfyDate={nullfyDate}
            firstElem={firstElem}
            lastElem={lastElem}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralLookDays;
