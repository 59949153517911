import React, { useState, useEffect, useRef } from "react";
import { FaEllipsisV } from "react-icons/fa";
import MenuOption from "components/MenuOptions";
import "./style.scss";

const MessageMenu = ({ menuOptions = [], menuExtraAction = () => {} }) => {
  const [visible, setVisible] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setVisible(!visible);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="menu-container" ref={menuRef}>
      <FaEllipsisV
        className="menu-icon"
        onClick={() => {
          toggleMenu();
          menuExtraAction();
        }}
      />
      {visible && (
        <div className="menu-options">
          {menuOptions.map((option, index) => (
            <MenuOption
              key={index}
              {...option}
              onClick={() => {
                option.onClick();
                setVisible(false);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default MessageMenu;
