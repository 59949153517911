import React, { useState, useEffect } from "react";
import Table from "./Table";
import Filter from "./Filter";
import {
  listPurchaseDailyReport,
  listPurchaseProductReport,
} from "../../../../../services/NexiDigitalAPI";
import moment from "moment";
import "moment/locale/pt-br.js";

const VendasDiarias = () => {
  const [loading, setLoading] = useState(false);
  const [daily, setDaily] = useState([]);

  const getVendas = async (info) => {
    setLoading(true);
    const list = await listPurchaseDailyReport(info);
    setDaily(list);
    setLoading(false);
  };

  const initialInfo = {
    startDate: moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  useEffect(() => {
    getVendas(initialInfo);
  }, []);

  return (
    <>
      <Filter actionFilter={getVendas} initialInfo={initialInfo} loading={loading} />
      <Table List={daily} loading={loading} />
    </>
  );
};

export default VendasDiarias;
