import React, { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ImageIcon from "@material-ui/icons/Image";
import ColorPicker from "material-ui-color-picker";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import { RiSave3Line } from "react-icons/ri";
import { updateAppDetails, uploadImageApi } from "../../services/NexiDigitalAPI";
import { withStyles } from "@material-ui/core/styles";
import { colors } from "../../utils/Colors";
import SnackComponent from "../../components/SnackComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";

function AppDetails(props) {
  const { loginData } = useContext(AuthContext);
  const { secondaryColor } = loginData;
  const [appDetails, setAppDetails] = useState(
    JSON.parse(localStorage.getItem("appDetails")) || {},
  );
  const [snackInfo, setSnackInfo] = useState({ message: "", severity: "success" });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [loadingAppDetails, setLoadingAppDetails] = useState(false);
  const [loadingImageUpload, setLoadingImageUpload] = useState({});

  const saveAppDetails = () => {
    if (loadingAppDetails) {
      return;
    } // prevent multiple requests when updating app details
    setLoadingAppDetails(true);
    const newAppDetails = {
      name: appDetails.name,
      logo: appDetails.logo,
      primaryColor: appDetails.primaryColor,
      secondaryColor: appDetails.secondaryColor,
      thirdColor: appDetails.thirdColor,
      fourthColor: appDetails.fourthColor,
      favIcon: appDetails.favIcon,
      pageTitle: appDetails.pageTitle,
      onBoardScreen: appDetails.onBoardScreen,
      onBoardUrl: appDetails.onBoardUrl,
      loginImage: appDetails.loginImage,
      headerIcon: appDetails.headerIcon,
      loginTextColor: appDetails.loginTextColor,
      backgroundColor: appDetails.backgroundColor,
      lastContentRedirect: appDetails.lastContentRedirect,
      loginPosition: appDetails.loginPosition,
      loginImageMobile: appDetails.loginImageMobile,
      store: appDetails.store,
      landingPage: appDetails.landingPage,
    };
    updateAppDetails(newAppDetails)
      .then((res) => {
        if (res.data.success === true) {
          localStorage.setItem("appDetails", JSON.stringify(appDetails));
          setSnackInfo({
            message: "Salvo com sucesso!",
            severity: "success",
          });
          setOpenSnackBar(true);
        } else {
          setOnSaveError();
        }
      })
      .catch((err) => {
        setOnSaveError();
      })
      .finally(() => {
        setLoadingAppDetails(false);
      });
  };

  const setOnSaveError = () => {
    setSnackInfo({
      message: "Erro ao salvar dados.",
      severity: "error",
    });
    setOpenSnackBar(true);
  };

  const getLoginPreview = (position) => {
    switch (position) {
      case "top":
        return "flex-start";
      case "middle":
        return "center";
      case "bottom":
        return "flex-end";
      default:
        return "center";
    }
  };

  const uploadFile = (file, detailItem) => {
    const filename = `${appDetails.name}-${detailItem}-${Date.now()}`;
    let selectedFile = file.files[0];

    const data = new FormData(); // If file selected
    if (selectedFile) {
      setLoadingImageUpload({ ...loadingImageUpload, [detailItem]: true });
      data.append("fileName", filename);
      data.append("fileData", selectedFile);

      uploadImageApi(data)
        .then((response) => {
          if (response.data.error) {
            setSnackInfo({
              message: "Erro ao subir imagem.",
              severity: "error",
            });
            setOpenSnackBar(true);
          } else {
            setAppDetails({ ...appDetails, [detailItem]: response.data.link });
          }
        })
        .catch((error) => {
          setSnackInfo({
            message: "Erro ao subir imagem.",
            severity: "error",
          });
          setOpenSnackBar(true);
        })
        .finally(() => {
          setLoadingImageUpload({ ...loadingImageUpload, [detailItem]: false });
        });
    } else {
      //   setCarregaImagem(false);
    }
  };

  const GreenButton = withStyles((theme) => ({
    root: {
      color: "white",
      backgroundColor: "#29CC61",
      "&:hover": {
        backgroundColor: "#29CC57",
      },
    },
  }))(Button);

  return (
    <div>
      <SnackComponent
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        message={snackInfo.message}
        severity={snackInfo.severity}
      />
      <div className="content-manager-container">
        <div className="content-manager-sub-item-settings">
          <div className="content-manager-sub-item-list">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="content-manager-sub-item-container" style={{ display: "flex" }}>
                <TextField
                  style={{ flex: 1 }}
                  id="outlined-basic-name"
                  label="Nome"
                  variant="outlined"
                  value={appDetails.name}
                  onChange={(e) => setAppDetails({ ...appDetails, name: e.target.value })}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div
                  className="content-manager-sub-item-container"
                  style={{ flex: 2, display: "flex", marginRight: 15 }}
                >
                  <TextField
                    style={{ flex: 1 }}
                    id="outlined-basic-title"
                    label="Título da página"
                    variant="outlined"
                    value={appDetails.pageTitle}
                    onChange={(e) => setAppDetails({ ...appDetails, pageTitle: e.target.value })}
                  />
                </div>
                <div
                  className="content-manager-sub-item-container"
                  style={{ flex: 1, display: "flex" }}
                >
                  <FormControl variant="outlined" style={{ flex: 1 }}>
                    <InputLabel id="demo-simple-select-outlined-label">Posição do Form</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={appDetails.loginPosition}
                      label="Posição do Form"
                      onChange={(e) =>
                        setAppDetails({ ...appDetails, loginPosition: e.target.value })
                      }
                    >
                      <MenuItem value={"top"}>Em cima</MenuItem>
                      <MenuItem value={"middle"}>Meio</MenuItem>
                      <MenuItem value={"bottom"}>Em baixo</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="content-manager-sub-item-container">
              <div style={{ display: "flex", alignItems: "center", marginLeft: -10 }}>
                <Switch
                  checked={appDetails.lastContentRedirect ? true : false}
                  color="primary"
                  onChange={(e) =>
                    setAppDetails({ ...appDetails, lastContentRedirect: e.target.checked ? 1 : 0 })
                  }
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <div>Redirecionar para último conteúdo</div>
              </div>
              <div style={{ display: "flex", alignItems: "center", marginLeft: -10 }}>
                <Switch
                  checked={appDetails.store ? true : false}
                  color="primary"
                  onChange={(e) =>
                    setAppDetails({ ...appDetails, store: e.target.checked ? 1 : 0 })
                  }
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <div>Habilitar Lojinha</div>
              </div>
              <div style={{ display: "flex", alignItems: "center", marginLeft: -10 }}>
                <Switch
                  checked={appDetails.onBoardScreen ? true : false}
                  color="primary"
                  onChange={(e) =>
                    setAppDetails({ ...appDetails, onBoardScreen: e.target.checked ? 1 : 0 })
                  }
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <div>Habilitar On-Board</div>
              </div>
              <div style={{ display: "flex", alignItems: "center", marginLeft: -10 }}>
                <Switch
                  checked={appDetails.landingPage ? true : false}
                  color="primary"
                  onChange={(e) =>
                    setAppDetails({ ...appDetails, landingPage: e.target.checked ? 1 : 0 })
                  }
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <div>Habilitar Landing Page</div>
              </div>
              <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
                <TextField
                  style={{ flex: 1 }}
                  id="outlined-basic-onboard"
                  label="Link do On-Board"
                  variant="outlined"
                  value={appDetails.onBoardUrl}
                  onChange={(e) => setAppDetails({ ...appDetails, onBoardUrl: e.target.value })}
                />
              </div>
            </div>

            <div className="content-manager-sub-item-container form-color-picker-container">
              <div className="content-manager-sub-item-title">Cores do formulário</div>
              <div className="content-manager-color-picker-container">
                <div
                  className="content-managet-color-picker"
                  style={{ backgroundColor: appDetails.loginTextColor }}
                />
                Cor do texto:
                <ColorPicker
                  style={{ marginLeft: 10 }}
                  name="color"
                  value={appDetails.loginTextColor || "black"}
                  TextFieldProps={
                    appDetails.loginTextColor ? { value: appDetails.loginTextColor } : null
                  }
                  onChange={(color) => setAppDetails({ ...appDetails, loginTextColor: color })}
                />
                {!appDetails.loginTextColor && <div style={{ color: "gray" }}>(Vazio)</div>}
              </div>
              <div className="content-manager-color-picker-container">
                <div
                  className="content-managet-color-picker"
                  style={{ backgroundColor: appDetails.backgroundColor }}
                />
                Cor de fundo do texto:
                <ColorPicker
                  disabled={appDetails.backgroundColor == "transparent"}
                  style={{
                    marginLeft: 10,
                    pointerEvents: appDetails.backgroundColor == "transparent" ? "none" : "auto",
                  }}
                  name="color"
                  value={appDetails.backgroundColor || "black"}
                  TextFieldProps={
                    appDetails.backgroundColor ? { value: appDetails.backgroundColor } : null
                  }
                  onChange={(color) => setAppDetails({ ...appDetails, backgroundColor: color })}
                />
                {
                  <div style={{ color: "gray" }}>
                    <Checkbox
                      checked={appDetails.backgroundColor == "transparent"}
                      onChange={(event) =>
                        event.target.checked
                          ? setAppDetails({ ...appDetails, backgroundColor: "transparent" })
                          : setAppDetails({ ...appDetails, backgroundColor: "#000" })
                      }
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    Transparente
                  </div>
                }
                {!appDetails.backgroundColor && <div style={{ color: "gray" }}>(Vazio)</div>}
              </div>
              <div className="content-manager-sub-item-container-big">
                <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
                  <div className="content-manager-sub-item-title">Cores do menu</div>
                  <div style={{ color: "gray", marginLeft: 5 }}>
                    * As cores do menu também são usadas em componentes genéricos
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
                  <div
                    className="content-managet-color-picker"
                    style={{ backgroundColor: appDetails.primaryColor }}
                  />
                  <span style={{ marginRight: 10 }}>Primeira</span>
                  <ColorPicker
                    className="content-manager-color-picker-input"
                    style={{ backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 10 }}
                    name="color"
                    value={appDetails.primaryColor || ""}
                    TextFieldProps={{ value: appDetails.primaryColor }}
                    onChange={(color) => setAppDetails({ ...appDetails, primaryColor: color })}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
                  <div
                    className="content-managet-color-picker"
                    style={{ backgroundColor: appDetails.secondaryColor }}
                  />
                  <span style={{ marginRight: 10 }}>Segunda</span>
                  <ColorPicker
                    className="content-manager-color-picker-input"
                    style={{ backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 10 }}
                    name="color"
                    value={appDetails.secondaryColor || ""}
                    TextFieldProps={{ value: appDetails.secondaryColor }}
                    onChange={(color) => setAppDetails({ ...appDetails, secondaryColor: color })}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
                  <div
                    className="content-managet-color-picker"
                    style={{ backgroundColor: appDetails.thirdColor }}
                  />
                  <span style={{ marginRight: 10 }}>Terceira</span>
                  <ColorPicker
                    className="content-manager-color-picker-input"
                    style={{ backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 10 }}
                    name="color"
                    value={appDetails.thirdColor || ""}
                    TextFieldProps={{ value: appDetails.thirdColor }}
                    onChange={(color) => setAppDetails({ ...appDetails, thirdColor: color })}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
                  <div
                    className="content-managet-color-picker"
                    style={{ backgroundColor: appDetails.fourthColor }}
                  />
                  <span style={{ marginRight: 10 }}>Quarta</span>
                  <ColorPicker
                    className="content-manager-color-picker-input"
                    style={{ backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 10 }}
                    name="color"
                    value={appDetails.fourthColor || ""}
                    TextFieldProps={{ value: appDetails.fourthColor }}
                    onChange={(color) => setAppDetails({ ...appDetails, fourthColor: color })}
                  />
                </div>
              </div>
            </div>

            <div className="content-manager-sub-item-container content-manager-image-picker">
              <div>
                <div>Imagem de fundo</div>
                <Button variant="contained" color="primary" component="label">
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    hidden
                    onChange={(e) => uploadFile(e.target, "loginImage")}
                  />
                  <ImageIcon style={{ marginRight: 10 }} />
                  Carregar imagem
                </Button>
              </div>
              <div className="content-manager-image-picker-container">
                {loadingImageUpload.loginImage ? (
                  <div>
                    <CircularProgress size={30} />
                  </div>
                ) : (
                  <img style={{ height: 100 }} src={appDetails.loginImage} />
                )}
              </div>
            </div>

            <div className="content-manager-sub-item-container content-manager-image-picker">
              <div>
                <div>Imagem de fundo (Mobile)</div>
                <Button variant="contained" color="primary" component="label">
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    hidden
                    onChange={(e) => uploadFile(e.target, "loginImageMobile")}
                  />
                  <ImageIcon style={{ marginRight: 10 }} />
                  Carregar imagem
                </Button>
              </div>
              <div className="content-manager-image-picker-container">
                {loadingImageUpload.loginImageMobile ? (
                  <div>
                    <CircularProgress size={30} />
                  </div>
                ) : (
                  <img style={{ height: 100 }} src={appDetails.loginImageMobile} />
                )}
              </div>
            </div>

            <div className="content-manager-sub-item-container content-manager-image-picker">
              <div>
                <div>Logo da aplicação</div>
                <Button variant="contained" color="primary" component="label">
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    hidden
                    onChange={(e) => uploadFile(e.target, "logo")}
                  />
                  <ImageIcon style={{ marginRight: 10 }} />
                  Carregar imagem
                </Button>
              </div>
              <div className="content-manager-image-picker-container">
                {loadingImageUpload.logo ? (
                  <div>
                    <CircularProgress size={30} />
                  </div>
                ) : (
                  <img style={{ height: 50 }} src={appDetails.logo} />
                )}
              </div>
            </div>

            <div className="content-manager-sub-item-container content-manager-image-picker">
              <div>
                <div>Icone de favoritos (.ico)</div>
                <Button variant="contained" color="primary" component="label">
                  <input
                    type="file"
                    accept="image/x-icon"
                    hidden
                    onChange={(e) => uploadFile(e.target, "favIcon")}
                  />
                  <ImageIcon style={{ marginRight: 10 }} />
                  Carregar imagem
                </Button>
              </div>
              <div className="content-manager-image-picker-container">
                {loadingImageUpload.favIcon ? (
                  <div>
                    <CircularProgress size={30} />
                  </div>
                ) : (
                  <img style={{ height: 50 }} src={appDetails.favIcon} />
                )}
              </div>
            </div>

            <div className="content-manager-sub-item-container content-manager-image-picker">
              <div>
                <div>Logo do Header</div>
                <Button variant="contained" color="primary" component="label">
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    hidden
                    onChange={(e) => uploadFile(e.target, "headerIcon")}
                  />
                  <ImageIcon style={{ marginRight: 10 }} />
                  Carregar imagem
                </Button>
              </div>
              <div className="content-manager-image-picker-container">
                {loadingImageUpload.headerIcon ? (
                  <div>
                    <CircularProgress size={30} />
                  </div>
                ) : (
                  <img style={{ height: 50 }} src={appDetails.headerIcon} />
                )}
              </div>
            </div>
          </div>
          <div className="content-manager-preview">
            <div style={{ fontSize: 18, textAlign: "center" }}>Preview</div>
            <div
              className="content-manager-login"
              style={{
                alignItems: getLoginPreview(appDetails.loginPosition),
                backgroundImage: `url(${appDetails.loginImage})`,
              }}
            >
              <div className="content-manager-login-form">
                <div
                  className="content-manager-login-form-text"
                  style={{
                    color: appDetails.loginTextColor,
                    backgroundColor: appDetails.backgroundColor,
                  }}
                >
                  Login
                </div>
                <div
                  className="content-manager-login-form-text"
                  style={{
                    color: appDetails.loginTextColor,
                    backgroundColor: appDetails.backgroundColor,
                  }}
                >
                  Password
                </div>
                <div
                  className="content-manager-login-form-button"
                  style={{ backgroundColor: secondaryColor }}
                >
                  Entrar
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-manager-footer" style={{ backgroundColor: colors.blueLight }}>
          <GreenButton variant="contained" color="primary" onClick={saveAppDetails}>
            <div>
              <RiSave3Line size={22} style={{ marginBottom: 3, marginRight: 10 }} />
              {loadingAppDetails
                ? "Salvando detalhes da aplicação..."
                : "Salvar detalhes da aplicação"}
            </div>
          </GreenButton>
        </div>
      </div>

      {/* <div className="content-manager-container">
                <div className="content-manager-item-title">
                    Geral
                    <hr />
                </div>
                <div className="content-manager-sub-item-settings">
                    <div className="content-manager-sub-item-list">
                        <div className="content-manager-sub-item-container">
                            <div className="content-manager-sub-item-title">Header</div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                Mostrar header
                                <Switch
                                    checked={showHeader}
                                    color="primary"
                                    onChange={(e) => setShowHeader(e.target.checked)}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                                <div className="content-managet-color-picker" style={{ backgroundColor: headerColor }} />
                                Cor:
                                <ColorPicker
                                    style={{ marginLeft: 10 }}
                                    name='color'
                                    value={headerColor}
                                    TextFieldProps={{ value: headerColor }}
                                    onChange={color => setHeaderColor(color)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </div>
  );
}

export default AppDetails;
