import "./style.modules.scss";

import React, { useState, useEffect, useContext } from "react";
import { Rnd } from "react-rnd";

import { CECreateContext } from "../../Contexts/CEContext";

import {
  CgArrowTopLeft,
  CgArrowTopRight,
  CgArrowBottomLeft,
  CgArrowBottomRight,
} from "react-icons/cg";

const CESyllabus = (props) => {
  const { backgroundSyllabus, propStyle, syllabusState, setSyllabusState } =
    useContext(CECreateContext);

  const handleDragStop = (e, data) => {
    setSyllabusState({ ...syllabusState, x: data.x, y: data.y });
  };

  const handleResizeStop = (e, direction, ref, delta, position) => {
    setSyllabusState({
      ...syllabusState,
      width: ref.style.width,
      height: ref.style.height,
      ...position,
    });
  };

  const bg =
    backgroundSyllabus.type === "image"
      ? { backgroundImage: `url(${backgroundSyllabus.result})` }
      : { backgroundColor: `${backgroundSyllabus.result}` };

  return (
    <div className="CESyllabus">
      <h4>
        Arraste ou redimensione o quadro destacado dentro da moldura. Verifique se o quadro está
        alinhado com o fundo do certificado.
      </h4>
      {syllabusState && (
        <div
          className="CESyllabusBase"
          style={{
            width: syllabusState.wProp,
            height: syllabusState.hProp,
            ...bg,
          }}
        >
          <Rnd
            size={{ width: syllabusState.width, height: syllabusState.height }}
            position={{ x: syllabusState.x, y: syllabusState.y }}
            minWidth={"10%"}
            minHeight={"10%"}
            bounds="parent"
            onDragStop={handleDragStop}
            onResizeStop={handleResizeStop}
          >
            <div className="CESyllabusContent">
              <CgArrowTopLeft className="topLeft" />
              <CgArrowTopRight className="topRight" />
              <CgArrowBottomLeft className="bottonLeft" />
              <CgArrowBottomRight className="bottonRight" />
            </div>
          </Rnd>
        </div>
      )}
    </div>
  );
};

export default CESyllabus;
