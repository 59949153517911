function elemsFlatten(blocks, removeType) {
  const flatten = (arr) => {
    return arr.reduce(function (flat, toFlatten) {
      return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten).filter(it => it.elementType !== removeType );
    }, []);
  };

  let blocksItens = [];

  blocks.map((it) => {
    if (it.elements && it.elements.length > 0) {
      blocksItens.push(it.elements);
    }

    return it;
  });

  return flatten(blocksItens);
}

export function filterFlatten(api, setEnrollmentBlocks , removeType = '') {
  if (api && api.node && api.node.blocks && api.node.blocks.length > 0) {
    const elents = elemsFlatten(api.node.blocks, removeType);
    setEnrollmentBlocks(elents);
  } else {
    setEnrollmentBlocks([]);
  }
}
