import React from "react";
import styles from "./styles.module.scss";
import Acordeon from "../../../../components/Acordeon";

const ReportAcordeon = ({ name, description, filter, result, index, expanded, onClickExpanded }) => {
  return (
    <Acordeon name={name} index={index} startExpanded={expanded} onClickExpanded={onClickExpanded}>
      <div className={styles.reportAcordeonDescription}>{description}</div>
      <div>
        <div>{filter}</div>
        <div>{result}</div>
      </div>
    </Acordeon>
  );
};

export default ReportAcordeon;
