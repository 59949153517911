import axios from "axios";

//const api_key = "46f3f01b8d1a45e013111a62ed52f7edb990fc302ec007bce1562947c8248154";
const api_key = "dfda38fdfc96b5c964c2d5366e89fb50e0644d502b6044e94c2f046b2a2613ca";

const api = axios.create({
  baseURL: `https://api.wistia.com/v1`,
  timeout: 10000,
});

api.interceptors.request.use(async (config) => {
  config.headers.Authorization = `Bearer ${api_key}`;
  return config;
});

export const getVideosFromWistiaPlaylist = (id) => {
  return api({
    method: "GET",
    url: `/projects/${id}.json`,
  });
};

export const getVideo = (id) => {
  return api({
    method: "GET",
    url: `/medias/${id}.json`,
  });
};
