import React from "react";

const Label: React.FC<{
  required?: boolean;
  name: string;
  htmlFor: string;
  className?: string
}> = ({ required = false, name, htmlFor, className }) => (
  <label htmlFor={htmlFor} className={className}>
    {required && <span className="required-icon"> * </span>}
    {name} {required && "(obrigatório)"}
  </label>
);

export default Label
