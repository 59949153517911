import React from "react";
import { modelQuestion, getQuestionTypeName } from "utils/Utils";

const OptionsQuestion = ({ evaluacionType }) => {
  const options = {
    REACTION: () => {
      return (
        <>
          <option value={""}>{"Todos os tipos"}</option>
          <option value={modelQuestion.LIKERT_SCALE}>
            {getQuestionTypeName(modelQuestion.LIKERT_SCALE)}
          </option>
          <option value={modelQuestion.MULTI_LIKERT_SCALE}>
            {getQuestionTypeName(modelQuestion.MULTI_LIKERT_SCALE)}
          </option>
          <option value={modelQuestion.SINGLE_OPTION_COMMENT}>
            {getQuestionTypeName(modelQuestion.SINGLE_OPTION_COMMENT)}
          </option>
          <option value={modelQuestion.COMMENT}>
            {getQuestionTypeName(modelQuestion.COMMENT)}
          </option>
        </>
      );
    },
    KNOWLEDGE: () => {
      return (
        <>
          <option value={""}>{"Todos os tipos"}</option>
          <option value={modelQuestion.MULTI_OPTION}>
            {getQuestionTypeName(modelQuestion.MULTI_OPTION)}
          </option>
          <option value={modelQuestion.SINGLE_OPTION}>
            {getQuestionTypeName(modelQuestion.SINGLE_OPTION)}
          </option>
          <option value={modelQuestion.TRUE_OR_FALSE}>
            {getQuestionTypeName(modelQuestion.TRUE_OR_FALSE)}
          </option>
          <option value={modelQuestion.CORRELATE}>
            {getQuestionTypeName(modelQuestion.CORRELATE)}
          </option>
        </>
      );
    },
    IMPACT: () => {
      return (
        <>
          <option value={""}>{"Todos os tipos"}</option>
          <option value={modelQuestion.LIKERT_SCALE}>
            {getQuestionTypeName(modelQuestion.LIKERT_SCALE)}
          </option>
          <option value={modelQuestion.MULTI_LIKERT_SCALE}>
            {getQuestionTypeName(modelQuestion.MULTI_LIKERT_SCALE)}
          </option>
          <option value={modelQuestion.SINGLE_OPTION_COMMENT}>
            {getQuestionTypeName(modelQuestion.SINGLE_OPTION_COMMENT)}
          </option>
          <option value={modelQuestion.COMMENT}>
            {getQuestionTypeName(modelQuestion.COMMENT)}
          </option>
        </>
      );
    },
  };
  return options[evaluacionType]() || options["REACTION"]();
};

export default OptionsQuestion;
