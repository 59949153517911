import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, TextField, Tooltip } from "@material-ui/core";
import DataTable from "react-data-table-component";
import { TiDeleteOutline } from "react-icons/ti";
import {
  getLearningEnrollmentRequest,
  putActionRequestEnrollment,
  putIncreaseCapacity,
} from "services/NexiDigitalAPI";
import moment from "moment";
import "./styles.scss";
import { LuUserCheck, LuUserPlus } from "react-icons/lu";
import { Button, Form, Modal } from "react-bootstrap";
import Label from "../Label";
import { FiAlertTriangle } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import PreloaderBlur from "containers/PeopleCraft/PreloaderBlur";
import { TbFileInfo } from "react-icons/tb";
import { Autocomplete } from "@material-ui/lab";
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
export enum Status {
  CANCELED = "CANCELED",
  PENDING = "PENDING",
  DENIED = "DENIED",
  APPROVED = "APPROVED",
}

export enum AdminLevel {
  ADMIN = "admin",
  MANAGER = "manager",
}

const StatusConfig = {
  [Status.PENDING]: {
    color: "196, 103, 26",
    label: "Aguardando",
    colorIcon: "90, 171, 117",
  },
  [Status.APPROVED]: {
    color: "15, 122, 72",
    label: "Aprovado",
    iconHidden: true,
  },
  [Status.DENIED]: {
    color: "180, 35, 24",
    label: "Reprovado",
  },
};
const FilterStatus = [
  {
    label: "Aguardando",
    value: Status.PENDING,
  },
  {
    label: "Aprovado",
    value: Status.APPROVED,
  },
  {
    label: "Reprovado",
    value: Status.DENIED,
  },
];

const icon = <MdOutlineCheckBoxOutlineBlank fontSize={20} />;
const checkedIcon = <MdOutlineCheckBox fontSize={20} />;
const PendingRegistrationRequest = ({
  idTeam = null,
  idUser = null,
  admLevel = AdminLevel.ADMIN,
  setRefreshCount,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const [sortPaginationPage, setSortPaginationPage] = useState<{
    offset: number;
    limit: number;
  }>({
    offset: 0,
    limit: 10,
  });
  const [validated, setValidated] = useState(false);
  const [justification, setJustification] = useState<string>("");
  const [modalControl, setModalControl] = useState<{
    openModal: boolean;
    status: Status;
    students?: {
      idEnrollmentRequest: number;
      name: string;
      createVacancy?: boolean;
    };
    reason?: string;
  }>({
    openModal: false,
    status: null,
  });
  const [sortOrder, setSortOrder] = useState<{
    orderBy: string;
    sort: string;
  }>({
    orderBy: "NAME",
    sort: "ASC",
  });
  const [sortStatus, setSortStatus] = useState<
    {
      label: string;
      value: Status;
    }[]
  >([
    {
      label: "Aguardando",
      value: Status.PENDING,
    },
  ]);

  const getDatas = useCallback(async () => {
    setLoading(true);
    const status = sortStatus.map((item) => item.value);
    const params: any = {
      ...sortOrder,
      ...sortPaginationPage,
      idTeam,
      idUser,
    };
    if (status && status.length) {
      params.status = status.toString();
    } else {
      params.status = FilterStatus.map((elem) => elem.value).toString();
    }
    try {
      const result: any = await getLearningEnrollmentRequest(params);
      setData(result.data.result);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, [sortOrder, sortStatus, idTeam, idUser, sortPaginationPage]);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  const IconStatus = ({ status }) => (
    <div
      className="body-status"
      style={{ backgroundColor: `rgba(${StatusConfig[status].color}, 0.14)` }}
    >
      <div
        className="point"
        style={{ backgroundColor: `rgba(${StatusConfig[status].color})` }}
      ></div>
      <div className="span" style={{ color: `rgba(${StatusConfig[status].color})` }}>
        {StatusConfig[status].label}
      </div>
    </div>
  );

  const paginationComponentOptions = {
    rowsPerPageText: "Conteúdos por página",
    rangeSeparatorText: "de",
  };

  const CustomNoDataComponent = () => (
    <div style={{ textAlign: "center" }}>
      <p>Sem itens encontrados</p>
    </div>
  );

  const onSort = (data, direction) => {
    setSortOrder({
      orderBy: data?.name?.toUpperCase(),
      sort: direction?.toUpperCase(),
    });
  };

  const onChangePage = (page) => {
    setSortPaginationPage((prevState) => ({
      ...prevState,
      offset: page === 1 ? 0 : (page - 1) * prevState.limit,
    }));
  };

  const onChangeRowsPerPage = (limit, page) => {
    setSortPaginationPage((prevState) => ({
      ...prevState,
      limit,
      offset: page === 1 ? 0 : (page - 1) * limit,
    }));
  };

  const IconApprove = ({group}) => (
    <Tooltip
    title={`${
      group.totalVacanciesAvailable !== 0 ? "Aprovar" : "Adicionar e aprovar vaga"
    }`}
    placement="top"
  >
    <span
      className="btn btn-sm float-right icon-approve"
      onClick={() => {
        openModalAction({ ...group, status: Status.APPROVED });
      }}
    >
      {group.totalVacanciesAvailable !== 0? <LuUserCheck /> : <LuUserPlus />}
    </span>
  </Tooltip>
  )

  const columns = [
    {
      name: "Nome",
      selector: "name",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      center: true,
      wrap: true,
      width: "300px",
      style: { textAlign: "center" },
      cell: ({ status }) => <IconStatus status={status} />,
    },
    {
      name: "Nome do treinamento",
      selector: "title",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Data solicitação",
      sortable: true,
      selector: "CREATION_DATE",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: ({ creationDate }) => (
        <div>
          <span>{creationDate ? moment(creationDate).format("MMM, D YYYY") : ""}</span>
        </div>
      ),
    },
    {
      name: "Periodo realização",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: ({ initialDate, finalDate }) =>
        initialDate && finalDate ? (
          <div>
            De <span>{initialDate ? moment(initialDate).format("DD/MM") : ""}</span> a{" "}
            <span>{finalDate ? moment(finalDate).format("DD/MM") : ""}</span>
          </div>
        ) : initialDate && !finalDate ? (
          <div>
            Início <span>{initialDate ? moment(initialDate).format("DD/MM") : ""}</span>
          </div>
        ) : (
          <></>
        ),
    },
    {
      name: "Vagas totais restantes",
      sortable: false,
      selector: "initialDate",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: ({ totalVacanciesAvailable, capacity }) =>
        totalVacanciesAvailable || totalVacanciesAvailable <= 0? (
          <div>
            <span>
              {totalVacanciesAvailable}
            </span>
          </div>
        ) : (
          <span>Vagas ilimitadas</span>
        ),
    },

    {
      name: "Ação",
      sortable: false,
      selector: "idGroups",
      width: "200px",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (group) => (
        <div className="groupActions" key={group.idGroups}>
          {group.status === Status.PENDING && (
            <>
              <Tooltip title="Reprovar" placement="top" className="icon-delete">
                <span
                  className="btn btn-sm float-right "
                  onClick={() => {
                    openModalAction({ ...group, status: Status.DENIED });
                  }}
                >
                  <TiDeleteOutline />
                </span>
              </Tooltip>
              <>
                {
               (
                (group.totalVacanciesAvailable) ||
                (!group.totalVacanciesAvailable && !group.capacity)  ||
                (group.totalVacanciesAvailable <= 0 && admLevel === AdminLevel.ADMIN) )&& (
                  <IconApprove group={group}/>
                )}
              </>
            </>
          )}
          {group.status === Status.DENIED && (
            <Tooltip title="Visualizar / editar justificativa" placement="top">
              <span
                className="btn btn-sm float-right icon-file"
                onClick={() => {
                  openModalAction({ ...group, status: Status.CANCELED });
                  setJustification(group.reason);
                }}
              >
                <TbFileInfo />
              </span>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const message = ({
    text,
    type,
    position = "top-right",
  }: {
    text: string;
    type: string;
    position?: string;
  }) => {
    toast[type](text, {
      position: position,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  const openModalAction = (row) => {
    setModalControl({
      openModal: true,
      status: row.status,
      students: {
        idEnrollmentRequest: row.idEnrollmentRequest,
        name: row.name,
        createVacancy: row.totalVacanciesAvailable <= 0,
      },
    });
  };

  const closeModal = () => {
    setModalControl({
      openModal: false,
      status: null,
      students: {
        idEnrollmentRequest: null,
        name: null,
        createVacancy: false,
      },
    });
    setJustification("");
  };

  const HeaderIcon = () => {
    switch (modalControl.status) {
      case Status.APPROVED:
        return (
          <div className="border-icon border-confirmation">
            <LuUserCheck />
          </div>
        );
      case Status.DENIED:
        return (
          <div className="border-icon border-denied">
            <FiAlertTriangle />
          </div>
        );
      default:
        return (
          <div className="border-icon border-cancel">
            <TbFileInfo />
          </div>
        );
    }
  };

  const Title = () => {
    switch (modalControl.status) {
      case Status.APPROVED:
        return <h5>Aprovar solicitação de matrícula!</h5>;
      case Status.DENIED:
        return <h5>Reprovar solicitação de matrícula!</h5>;

      default:
        return <h5>Justificativa de reprovação da matrícula</h5>;
    }
  };

  const SubTitle = () => {
    switch (modalControl.status) {
      case Status.APPROVED:
        return (
          <p className="subtitle-students subtitle-approved">
            Você tem certeza que deseja aprovar a matrícula do aluno:{" "}
            <span>"{modalControl?.students?.name}"</span>
          </p>
        );
      case Status.DENIED:
        return (
          <p className="subtitle-students subtitle-denied">
            Atenção! Está ação é irreversível. Você confirma a reprovação da matrícula do aluno{" "}
            <span>"{modalControl?.students?.name}"</span>
          </p>
        );
      default:
        return <></>;
    }
  };

  const NameAction = () => {
    switch (modalControl.status) {
      case Status.APPROVED:
        return "Sim, Aprovar";
      case Status.DENIED:
        return "Sim, Reprovar";
      default:
        return "Editar";
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    switch (modalControl.status) {
      case Status.APPROVED:
        return approveRequest();
      case Status.DENIED:
      case Status.CANCELED:
        return deniedRequest();
      default:
        return;
    }
  };

  const approveRequest = async () => {
    const request = modalControl.students.createVacancy
      ? putIncreaseCapacity(modalControl.students.idEnrollmentRequest)
      : putActionRequestEnrollment(modalControl.students.idEnrollmentRequest, "approve");

    try {
      setLoadingAction(true);
      await request;
      message({ text: "Solicitação aprovada com sucesso", type: "success" });
      getDatas();
      closeModal();
      setRefreshCount && setRefreshCount(true);
    } catch (e) {
      message({ text: e?.response?.data?.message, type: "error" });
    } finally {
      setLoadingAction(false);
    }
  };

  const deniedRequest = async (textMessage = "Solicitação reprovada com sucesso") => {
    setValidated(true);
    if (!justification) {
      return;
    }
    try {
      setLoadingAction(true);
      await putActionRequestEnrollment(modalControl.students.idEnrollmentRequest, "deny", {
        reason: justification,
      });
      setJustification(null);
      message({ text: textMessage, type: "success" });
      getDatas();
      closeModal();
      setRefreshCount && setRefreshCount(true);

    } catch (e) {
      message({ text: e?.response?.data?.message, type: "error" });
    } finally {
      setLoadingAction(false);
    }
  };

  const handleStatusChange = (event: any, newValue) => {
    setSortStatus(newValue);
  };

  return (
    <div className="pending-registration-container">
      <div className="pending-registration-wrapper">
        <h5>Solicitação de matrícula pendentes</h5>
      </div>
      <Autocomplete
        multiple
        size="small"
        className="form-control"
        style={{ width: 600, marginBottom: 10, paddingLeft: 0, paddingRight: 0 }}
        value={sortStatus}
        onChange={handleStatusChange}
        options={FilterStatus}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8, fontSize: 14 }}
              checked={
                sortStatus.some((item) => item.value === option.value)
              }
            />
            {option.label}
          </>
        )}
        renderInput={(params) => {
          return <TextField {...params} variant="standard" />;
        }}
      />
      <DataTable
        progressPending={loading}
        onSort={onSort}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        paginationTotalRows={data.length}
        columns={columns}
        data={data}
        fixedHeader
        noDataComponent={<CustomNoDataComponent />}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        paginationServer
      />
      <Modal
        className="modalFeedback"
        show={modalControl.openModal}
        onHide={() => {
          setModalControl({
            openModal: false,
            status: null,
          });
        }}
      >
        {loadingAction ? (
          <PreloaderBlur />
        ) : (
          <>
            <Modal.Body>
              <div className="div-flex-modal">
                <HeaderIcon />
                <Title />
                <SubTitle />
                {Status.APPROVED !== modalControl.status && (
                  <Form validated={validated} className="form-data">
                    <Label name="Justifique" htmlFor="justification" />
                    <Form.Control
                      required
                      as="textarea"
                      rows={3}
                      id="justification"
                      aria-describedby="justification"
                      maxLength={1000}
                      className="form-control"
                      value={justification}
                      onChange={(e: any) => setJustification(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Justificativa obrigatória
                    </Form.Control.Feedback>
                  </Form>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={() => closeModal()}>
                Cancelar
              </Button>
              <Button
                variant={modalControl.status === Status.DENIED ? "danger" : "warning"}
                onClick={handleClick}
              >
                {NameAction()}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default PendingRegistrationRequest;
