import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  listComments,
  insertComment,
  updateComment,
  deleteComment,
  toggleVisibilityComment,
} from "services/NexiDigitalAPI";
import { AuthContext } from "context/AuthContext";

import moment from "moment";
import "moment/locale/pt-br";
import Message from "components/Message";
import MessageItem from "components/MessageItem";
import Pagination from "components/Pagination";
import SendMessageBox from "components/SendMessageBox";
import EmptyMessage from "assets/images/ico-emptystate-mensagem.svg";
const COMMENTS_PER_PAGE = 10;

export const InteractionChats = ({ title, informationNode = null, infoParamsRequest = null }) => {
  const [page, setPage] = useState(1);
  const [allComments, setAllComments] = useState([]);
  const [showNewMessageBox, setShowNewMessageBox] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [filter, setFilter] = useState("Todos");
  const [loading, setLoading] = useState(false);
  const [totalComments, setTotalComments] = useState(0);
  const {
    loginData: { menuData },
  } = useContext(AuthContext);

  const fetchRepliesByIdParentComment =
    (parentComment) =>
    async (offset = 0, limit = 5, onlyReported = 0, reLoading = false, clearReplies = false) => {
      const { idUser: currentUserId } = menuData;
      const {
        data: { comments: replies },
      } = await listComments(parentComment.entityType, parentComment.idEntity, {
        offset,
        limit,
        idParentComment: parentComment.idComment,
        onlyReported,
      });

      const formattedReplies = replies.map((reply) => ({
        ...reply,
        isReply: true,
        time: moment(reply.creationDate).fromNow(),
        commentsCount: reply.totalReplies,
        warningsCount: reply.totalReports,
        showHide: true,
        removeButton: reply.createdBy === currentUserId,
        editButton: reply.createdBy === currentUserId,
        modeAuthor: Boolean(reply.moderator) && "Moderador",
        hiddenBy: reply.hiddenBy === currentUserId,
        warningMessage:
          reply.totalRepliesReports > 0 &&
          "Esta mensagem possui respostas denunciadas que precisam de sua análise!",
      }));

      const updatedComments = allComments.map((comment) =>
        comment.idComment === parentComment.idComment
          ? {
              ...comment,
              commentsCount: comment.commentsCount,
              replies: clearReplies
                ? []
                : !reLoading
                ? [...new Set([...comment.replies, ...formattedReplies])]
                : formattedReplies,
            }
          : comment,
      );

      setAllComments(updatedComments);
    };

  const fetchComments = async (offset, limit, onlyReported = 0) => {
    setLoading(true);

    const { idUser: currentUserId } = menuData;
    const {
      data: { comments: _comments, totalRecords },
    } = await listComments(infoParamsRequest.entityType, infoParamsRequest.idEntity, {
      offset,
      limit,
      onlyReported,
    });

    const filteredComments = _comments.filter((comment) => !comment.deleted);
    const mainComments = await Promise.all(
      filteredComments.map(async (comment) => {
        return {
          ...comment,
          time: moment(comment.creationDate).fromNow(),
          commentsCount: comment.totalReplies,
          warningsCount: comment.totalReports,
          showHide: true,
          isReply: false,
          removeButton: comment.createdBy === currentUserId,
          editButton: comment.createdBy === currentUserId,
          modeAuthor: Boolean(comment.moderator) && "Moderador",
          hiddenBy: comment.hiddenBy === currentUserId,
          warningMessage:
            comment.totalRepliesReports > 0
              ? "Esta mensagem possui respostas denunciadas que precisam de sua análise!"
              : null,
          replies: [],
          expanded: false,
        };
      }),
    );

    setTotalComments(totalRecords);
    setLoading(false);

    return mainComments;
  };

  const _getPaginatedComments = async () => {
    setLoading(true);
    setShowNewMessageBox(false);
    try {
      const offset = (page - 1) * COMMENTS_PER_PAGE;
      const paginated = await fetchComments(
        offset,
        COMMENTS_PER_PAGE,
        filter === "Denunciados" ? 1 : 0,
      );
      setAllComments(paginated);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setPage(1);
  };
  const handleNewMessage = async (newMessage) => {
    setLoading(true);
    try {
      await insertComment(infoParamsRequest.entityType, infoParamsRequest.idEntity, {
        text: newMessage,
      });
      setPage(1);
      setShowNewMessageBox(false);
      setNewMessage("");
      _getPaginatedComments();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleEditMessage = (idComment) => async (newText) => {
    const _beforeUpdateComments = [...allComments];
    const updatedComments = allComments.map((comment) =>
      comment.idComment === idComment ? { ...comment, text: newText } : comment,
    );
    setAllComments(updatedComments);

    try {
      await updateComment(infoParamsRequest.entityType, infoParamsRequest.idEntity, idComment, {
        text: newText,
      });
    } catch (error) {
      setAllComments(_beforeUpdateComments);
    }
  };

  const handleHideMessage = async (idComment, hidden) => {
    await toggleVisibilityComment(
      infoParamsRequest.entityType,
      infoParamsRequest.idEntity,
      idComment,
      { hidden },
    );
  };

  const handleEditReplyMessage = (idParentComment) => (idComment) => async (newText) => {
    const _beforeUpdateComments = [...allComments];
    const updatedComments = allComments.map((comment) =>
      comment.idComment === idParentComment
        ? {
            ...comment,
            replies: comment.replies.map((reply) =>
              reply.idComment === idComment ? { ...reply, text: newText } : reply,
            ),
          }
        : comment,
    );
    setAllComments(updatedComments);

    try {
      await updateComment(infoParamsRequest.entityType, infoParamsRequest.idEntity, idComment, {
        text: newText,
      });
    } catch (error) {
      setAllComments(_beforeUpdateComments);
    }
  };
  const handleRemoveMessage = async (idComment) => {
    setLoading(true);
    try {
      await deleteComment(infoParamsRequest.entityType, infoParamsRequest.idEntity, idComment);
      await _recalculatePaginationAfterRemoval();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const _recalculatePaginationAfterRemoval = async () => {
    const offset = (page - 1) * COMMENTS_PER_PAGE;
    const comments = await fetchComments(
      offset,
      COMMENTS_PER_PAGE,
      filter === "Denunciados" ? 1 : 0,
    );

    if (comments.length < COMMENTS_PER_PAGE && page > 1) {
      setPage(page - 1);
    }

    setAllComments(comments);
  };

  const handleRemoveReplyMessage = (idParentComment) => async (idComment) => {
    const _beforeUpdateComments = [...allComments];
    const updatedComments = allComments.map((comment) =>
      comment.idComment === idParentComment
        ? {
            ...comment,
            replies: comment.replies.map((reply) =>
              reply.idComment === idComment ? { ...reply, deleted: true } : reply,
            ),
          }
        : comment,
    );
    setAllComments(updatedComments);
    try {
      await deleteComment(infoParamsRequest.entityType, infoParamsRequest.entityType, idComment);
    } catch (error) {
      setAllComments(_beforeUpdateComments);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNewReplyMessageSubmit = (idParentComment) => async (text) => {
    const _beforeUpdateComments = [...allComments];
    try {
      await insertComment(infoParamsRequest.entityType, infoParamsRequest.idEntity, {
        text,
        idParentComment,
      });
      scrollToComponent(`#message-${idParentComment}`);
    } catch (error) {
      setAllComments(_beforeUpdateComments);
      console.error(error);
    }
  };

  const scrollToComponent = (elementId, behavior = "smooth", block = "center") => {
    const component = document.querySelector(elementId);
    if (component) {
      component.scrollIntoView({ behavior, block });
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(totalComments / COMMENTS_PER_PAGE)) {
      setPage(newPage);
    }
  };

  const mensagemEmpty = useMemo(() => {
    if (informationNode?.entityType === "NODE") {
      return "Esta página ainda não teve nenhuma interação.";
    }
    return "Essa turma ainda não teve nenhuma interação.";
  }, [informationNode]);

  useEffect(() => {
    _getPaginatedComments();
  }, [page, filter]);

  return (
    <>
      <p
        className={`interaction-drawer-header ${
          infoParamsRequest.entityType === "NODE" && "header-node"
        }`}
      >
        {title}
        {infoParamsRequest.entityType === "NODE" && <span>{` | ${informationNode.title}`}</span>}
      </p>
      <div className="interaction-drawer-actions">
        <div className="interaction-drawer-button-container">
          {!showNewMessageBox && (
            <button
              className="interaction-drawer-button"
              onClick={() => setShowNewMessageBox(true)}
            >
              Adicionar mensagem
            </button>
          )}
        </div>
        <select className="interaction-drawer-select" value={filter} onChange={handleFilterChange}>
          <option>Todos</option>
          <option>Denunciados</option>
        </select>
      </div>

      {showNewMessageBox && (
        <SendMessageBox
          newReply={newMessage}
          setNewReply={setNewMessage}
          handleReplyToggle={() => setShowNewMessageBox(false)}
          handleNewMessage={handleNewMessage}
        />
      )}

      {loading ? (
        <div className="interaction-drawer-comments">
          {Array.from(new Array(5)).map((_, index) => (
            <MessageItem index={index} loading={true} />
          ))}
        </div>
      ) : (
        <div className="interaction-drawer-comments">
          {allComments.length === 0 ? (
            <div className="no-comments-container">
              <img src={EmptyMessage} style={{ fontSize: 50, color: "#6b48ff" }} alt="icon-empty" />
              <p className="no-comments-text">
                {filter === "Denunciados"
                  ? "Não existem registros de mensagens ou respostas que tenham sido denunciados."
                  : mensagemEmpty}
              </p>
            </div>
          ) : (
            allComments.map((comment, index) => (
              <Message
                key={comment.idComment}
                {...comment}
                displayDivider={index < allComments.length - 1}
                onResize={() => {}}
                handleEditMessage={handleEditMessage(comment.idComment)}
                handleRemoveMessage={handleRemoveMessage}
                handleEditReplyMessage={handleEditReplyMessage(comment.idComment)}
                handleRemoveReplyMessage={handleRemoveReplyMessage(comment.idComment)}
                setShowNewMessageBox={setShowNewMessageBox}
                showNewMessageBox={showNewMessageBox}
                handleNewReplyMessageSubmit={handleNewReplyMessageSubmit(comment.idComment)}
                handleHideMessage={handleHideMessage}
                handleFetchReplies={fetchRepliesByIdParentComment(comment)}
              />
            ))
          )}
        </div>
      )}

      <Pagination
        total={Math.ceil(totalComments / COMMENTS_PER_PAGE)}
        current={page}
        onPageChange={handlePageChange}
      />
    </>
  );
};
