import React from "react";
import "../index.css";
import { ReactComponent as ProgressIcon } from "../../../../assets/images/upgrade-block.svg";

export const UpdateCard = () => {
  return (
    <div style={{ flex: 1, padding: "24px" }}>
      <div className="upgrade-card-container">
        <div className="upgrade-card-icon">
          <ProgressIcon style={{ width: 40, height: 40 }} />
        </div>
        <div>
          <div style={{ fontSize: 18, fontWeight: "bold" }}>Bloco de Upgrade</div>
          <div style={{ color: "rgb(163, 163, 163)" }}>
            Esse é um bloco de sistema
          </div>
          <div style={{ color: "rgb(163, 163, 163)" }}>
            E não pode ser removido ou editado
          </div>
        </div>
      </div>
    </div>
  );
};
