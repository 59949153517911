import React from "react";
import "../index.scss";

import { RiCalendarEventFill } from "react-icons/ri";

export const Meeting = () => {
  return (
    <div style={{ flex: 1, padding: "10px 0px" }}>
      <div className="meeting-card-container">
        <div className="meeting-card-icon">
          <RiCalendarEventFill style={{ fill: "#eea125", width: 40, height: 40 }} />
        </div>
        <div>
          <div style={{ fontSize: 18, fontWeight: "bold" }}>Bloco do tipo encontro</div>
          <div style={{ color: "rgb(163, 163, 163)" }}>
            Eventos serão adicionados em cada turma criada.
          </div>
        </div>
      </div>
    </div>
  );
};
