import "./styles.scss";

import React from "react";

const PreloaderBlur = (props) => {
  return (
    <div className="NMPreloader">
      <div className="NMPreloaderFundo"></div>
      <div className="loader loader-big" />
    </div>
  );
};

export default PreloaderBlur;
