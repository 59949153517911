import React, { useState, useEffect, useContext, useRef } from "react";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import "react-datepicker/dist/react-datepicker.css";
import _ from "underscore";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import moment from "moment";
import { updateContent, getTags, uploadImageApi } from "../../services/NexiDigitalAPI";
import SaveIcon from "@material-ui/icons/Save";
import SemImagem from "../../assets/images/sem-imagem.jpg";
import AccessTable from "../AccessTable";
import { AuthContext } from "../../context/AuthContext";
import Tags from "../../components/Tags";

import Switch from "@material-ui/core/Switch";
import { Form } from "react-bootstrap";
import { DatePickerInput } from "rc-datepicker";
import CancelButton from "components/CancelButton";
import Workload from "components/Workload";
import CmsContentLabel from "components/CmsContentLabel";
import ContentImageUpload from "components/ContentImageUpload";

const Podcast = (props) => {
  const { data, contentTags, permissionApproveContent, isNode, returnNodeData, closeModal } = props;
  // Lista de variáveis em ordem alfabética (para bater com a lista recebida do json)
  const [active, setActive] = useState(data.active || 0);
  const [autor, setAutor] = useState(data.author || "");
  const [autorEmail, setAutorEmail] = useState(data.authorEmail || "");
  const [backConfirmation, setBackConfirmation] = useState(data.backConfirmation || "");
  const [backgroundColor, setBackgroundColor] = useState(data.backgroundColor || "");
  const [content, setContent] = useState(data.content || "");
  const [contentLocation, setContentLocation] = useState(data.contentLocation || "");
  const [description, setDescription] = useState(data.description || "");
  const [endDate, setEndDate] = useState(data.endDate === null ? null : new Date(data.endDate));
  const [idContentGroup, setIdContentGroup] = useState(data.idContentGroup);
  const [idContentItem, setIdContentItem] = useState(data.idContentItem);
  const [idContentType, setIdContentType] = useState(data.idContentType);
  const [idSection, setIdSection] = useState(data.idSection);
  const [imageStyle, setImageStyle] = useState(data.imageStyle || "");
  const [initDate, setInitDate] = useState(
    data.initDate === null ? new Date() : new Date(data.initDate),
  );
  const [logoutConfirmation, setLogoutConfirmation] = useState(data.logoutConfirmation);
  const [referenceLink, setReferenceLink] = useState(data.referenceLink || "");
  const [showBottomMenu, setShowBottomMenu] = useState(data.showBottomMenu);
  const [showTopBar, setShowTopBar] = useState(data.showTopBar);
  const [titulo, setTitulo] = useState(data.text || "");
  const [tag, setTag] = useState("");
  const [audioUploadStatus, setAudioUploadStatus] = useState(false);
  const [mensagemAudioUpload, setMensagemAudioUpload] = useState("");
  const { loginData } = useContext(AuthContext);

    // Imagem
    const contentImageUploadRef = useRef(null);

  // variáveis de auxílio
  let tagsDeConteudo = [];
  for (let i = 0; i < contentTags.length; i++) {
    tagsDeConteudo.push({ id: i, name: contentTags[i].tag });
  }

  const [audioOriginal, setAudioOriginal] = useState(data.image || "");
  const [carregaAudio, setCarregaAudio] = useState(false);
  const [temEndDate, setTemEndDate] = useState(endDate ? true : false);
  const [tags, setTags] = useState(tagsDeConteudo);
  const [label, setLabel] = useState(data.label || "Áudio");
  const [lista, setLista] = useState([]);

  const [audioDuration, setAudioDuration] = useState(0);
  const [audioType, setAudioType] = useState("audio/mp3");
  const audioRef = useRef();

  const updateAudioDuration = () => {
    const hasAudio = Boolean(audioRef.current);

    if (hasAudio) {
      const duration = Boolean(audioRef.current.duration) ? audioRef.current.duration : 0;
      setAudioDuration(duration);
      return;
    }

    if (contentLocation === "") {
      setAudioDuration(0);
      return;
    }

    return setTimeout(updateAudioDuration, 500);
  };

  const configUpdateAudioDuration = () => {
    const hasAudio = Boolean(audioRef.current);

    if (hasAudio) {
      const hasDuration = Boolean(audioRef.current.duration);
      if (hasDuration) {
        updateAudioDuration();
      } else {
        audioRef.current.addEventListener("loadedmetadata", updateAudioDuration);
      }
    } else {
      setTimeout(configUpdateAudioDuration, 500);
    }
  };

  const getAudioType = () => {
    const brokedUrl = contentLocation.split(".");
    const type = `audio/${brokedUrl[brokedUrl.length - 1]}`;
    setAudioType(type);
  };

  useEffect(() => {
    configUpdateAudioDuration();
    getAudioType();
  }, [contentLocation]);

  useEffect(() => {
    getTags()
      .then((res) => {
        const tagSearch = res.data.tagsearch;
        const tagsSorted = _.sortBy(tagSearch, "tag");
        let nova = [];

        for (let i = 0; i < tagsSorted.length; i++) {
          nova.push({ id: tagsSorted[i].idTag, name: tagsSorted[i].tag });
        }

        setLista(nova);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const uploadAudio = (arquivo) => {
    setCarregaAudio(true);
    let selectedAudioFile = arquivo.files[0];

    const fileAudioName = "audio" + idContentItem + "-" + Date.now();

    const dataAudio = new FormData(); // If file selected
    if (selectedAudioFile) {
      dataAudio.append("fileName", fileAudioName);
      dataAudio.append("fileData", selectedAudioFile);
      uploadImageApi(dataAudio)
        .then((response) => {
          if (response.data.error) {
            setContentLocation(audioOriginal);
            setCarregaAudio(false);
            setAudioUploadStatus(false);
            setMensagemAudioUpload("Erro ao fazer upload do arquivo");
          } else {
            setContentLocation(response.data.location);
            setCarregaAudio(false);
            setAudioUploadStatus(true);
            setMensagemAudioUpload("Audio atualizado com successo");
          }
        })
        .catch((error) => {
          // If another error
          console.log("error", error);
          setContentLocation(audioOriginal);
          setCarregaAudio(false);
          setAudioUploadStatus(false);
          setMensagemAudioUpload("Erro ao fazer upload do arquivo");
        });
    } else {
      setContentLocation(audioOriginal);
      setCarregaAudio(false);
    }
  };

  const sendData = async (event, newImageLocation) => {
    
    if (event) {
      event.preventDefault();
    }

    const publishDate = moment().format("YYYY-MM-DD hh:mm:ss.SSS");
    const userId = loginData.userid; // Usuário logado
    const tagList = _.map(tags, function (t) {
      return t.name;
    }).join(";");


    //ADICIONANDO REGRA PARA QUANDO O USUÁRIO CLICAR PARA SALVAR NO SALVAR DO FORMULÁRIO.
    const uploadImageLocation = await contentImageUploadRef.current.uploadImagem();

    if(uploadImageLocation != null){
      newImageLocation = uploadImageLocation;
    }

    let obj = {
      idContentItem: idContentItem,
      idContentGroup: idContentGroup,
      image: newImageLocation,
      text: titulo,
      description: description,
      contentLocation: contentLocation,
      publishDate: publishDate,
      initDate: moment(initDate).format("YYYY-MM-DD hh:mm:ss.SSS"),
      endDate: endDate === null ? null : moment(endDate).format("YYYY-MM-DD hh:mm:ss.SSS"),
      content: content,
      idUserPublisher: parseInt(userId, 10),
      author: autor,
      authorEmail: autorEmail,
      active: parseInt(active),
      idContentType: idContentType,
      tags: tagList,
      label: label,
      backConfirmation: backConfirmation === 1 ? 1 : 0,
      backgroundColor: backgroundColor,
      idSection: idSection,
      imageStyle: imageStyle,
      logoutConfirmation: logoutConfirmation,
      referenceLink: referenceLink,
      showBottomMenu: showBottomMenu,
      showTopBar: showTopBar,
      duration: audioDuration,
    };

    updateContent(obj)
      .then((res) => {
        if (res.data.success) {
          if (isNode && returnNodeData) {
            returnNodeData({ ...obj, idContentItem: data.idContentItem });
          } else {
            props.history.goBack();
          }
        }
      })
      .catch((error) => {
        // If another error
        console.log("error", error);
      });
    //}
  };

  const usaDataFim = (op) => {
    setTemEndDate(op);

    if (op === false) {
      setEndDate(null);
    }
  };

  return (
    <div>
      {!isNode && (
        <div className="header-title-wrapper">
          <div className="header-title">
            <button
              className="btnGoBack"
              onClick={() => {
                props.history.goBack();
              }}
            >
              <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
            </button>
            Página de conteúdo
          </div>
        </div>
      )}

      <div className="podcast-form">
        <form onSubmit={sendData}>
          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">Título</div>
              <div className="card-body template-card-body">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Título
                    </span>
                  </div>
                  <input
                    type="titulo"
                    className="form-control"
                    id="titulo"
                    aria-describedby="titulo"
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">Tags</div>
              <div className="card-body template-card-body">
                <div className="tags-wrapper">
                  <Tags
                    handleNewTags={(newTags) => setTags(newTags)}
                    lista={lista}
                    contentTags={contentTags}
                  />
                </div>
              </div>
            </div>
          </div>
          <CmsContentLabel label={label} setLabel={setLabel} />
          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">Imagem</div>
                <ContentImageUpload reference={contentImageUploadRef} data={data} sendData={sendData} defaultPrefixName={"content"}/>
            </div>
          </div>
          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">Áudio</div>
              <div className="card-body template-card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="urlAudio">
                          url
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={contentLocation}
                        onChange={(e) => {
                          const Audio = e.target.value;
                          setCarregaAudio(true);
                          window.setTimeout(function () {
                            setContentLocation(Audio);
                            setCarregaAudio(false);
                            setAudioUploadStatus(true);
                            setMensagemAudioUpload("Áudio atualizado com successo");
                          }, 1);
                        }}
                      />
                    </div>

                    <input
                      type="file"
                      className="form-control-file"
                      id="audio"
                      accept="audio/mp3"
                      onChange={(e) => uploadAudio(e.target)}
                    />
                  </div>
                  {carregaAudio ? (
                    <div className="col-md-6">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="col-md-6">
                      <audio ref={audioRef} controls>
                        <source src={contentLocation} type={audioType} />
                        Your browser does not support the audio element.
                      </audio>
                      <br />
                      {audioUploadStatus ? (
                        <span style={{ color: "green" }}>{mensagemAudioUpload}</span>
                      ) : (
                        <span style={{ color: "red" }}>{mensagemAudioUpload}</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">Carga horária</div>
              <div className="card-body template-card-body">
                <div className="form-row">
                  <Workload
                    workload={audioDuration}
                    setWorkload={setAudioDuration}
                    hideTitle
                    hideHour
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">Autor</div>
              <div className="card-body template-card-body">
                <div className="form-row">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="autor">
                        Nome
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={autor}
                      onChange={(e) => setAutor(e.target.value)}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="autor">
                        E-mail
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      value={autorEmail}
                      onChange={(e) => setAutorEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="card overflow-visible">
              <div className="card-header template-card-header">Publicação</div>
              <div className="card-body template-card-body">
                <div className="form-row">
                  {permissionApproveContent && (
                    <div className="col-md-3">
                      <Form.Group className="access-table-select">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          value={active}
                          onChange={(e) => setActive(e.target.value)}
                        >
                          <option value={0}>Avaliação pendente</option>
                          <option value={1}>Aprovado</option>
                          <option value={2}>Cancelado</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                  )}

                  <div className="col-md-3">
                    <Form.Group className="access-table-select">
                      <Form.Label>Data de publicação</Form.Label>
                      <DatePickerInput
                        name="initDate"
                        locale="pt-br"
                        onChange={(date) => setInitDate(date)}
                        value={initDate}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <div className="access-table-select form-group">
                      <label className="form-label" htmlFor="tira-do-ar" style={{ margin: "-3 0" }}>
                        Agendar término publicação?
                      </label>
                      <br />
                      <Switch
                        className="form-group"
                        id="tira-do-ar"
                        color="primary"
                        checked={temEndDate}
                        onChange={(e) => usaDataFim(e.target.checked)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </div>
                  {temEndDate ? (
                    <div className="col-md-3">
                      <Form.Group className="access-table-select">
                        <Form.Label>Data de retirada do conteúdo</Form.Label>
                        <DatePickerInput
                          name="endDate"
                          placeholderText="Sem prazo"
                          locale="pt-br"
                          onChange={(date) => setEndDate(date)}
                          value={endDate}
                        />
                      </Form.Group>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header template-card-header">Permissões de acesso ao conteúdo</div>
            <div className="card-body template-card-body">
              <AccessTable id={data.idContentItem} />
            </div>
          </div>
          <br />
          <div className="row pr-3" style={{ padding: 5, justifyContent: "flex-end" }}>
            <CancelButton idContentItem={idContentItem} isNode={isNode} closeModal={closeModal} />
            <button type="submit" className="btn btn-primary">
              <SaveIcon style={{ height: 18 }} /> Salvar
            </button>
          </div>
        </form>
        <br />
      </div>
    </div>
  );
};

export default withRouter(Podcast);
