// Basic
import React from "react";

import "./styles.scss";

import StudentsItens from "./components/StudentsItens";

export default function StudentEnrollmentView({ idCompanySelected }) {
  return (
    <div className="studentEnrollmentViewBase">
      <div className="report_container">
        <div className="textLeft">Progresso dos usuários em conteúdos de aprendizagem</div>
        <div className="selectPediodDiv">
          <StudentsItens idCompanySelected={idCompanySelected} />
        </div>
      </div>
    </div>
  );
}
