import React from "react";
import { useManagerTrial } from "../../context/contextManagerTrial";
import { PlaneProps } from "../../model/ManagerTrial.model";
import Button from "components/PeopleCraft/Button";


const FeedbackUpgrade = ({}) => {
  const { currentCardUpgrade } = useManagerTrial();

  const { name,token, domain } = currentCardUpgrade as PlaneProps;

  const specialPlan = name === "Enterprise";


  const goTrial = () => {
   window.open(`https://${domain}/?token=${token}`);
  }
  return (
    <>
      <div className="title">
        {specialPlan ? (
          <h3>Obrigado sua mensagem foi enviada com sucesso!</h3>
        ) : (
          <h3>
            Parabéns, você acaba de Iniciar o trial do{" "}
            <span>{currentCardUpgrade?.name}!</span>
          </h3>
        )}
        <p>
          {specialPlan
            ? `Em breve, o nosso time comercial entrará em contato.`
            : `Verifique o seu e-mail para mais orientações de acesso.`}
        </p>
      </div>
      <div className="buttonsWrapper">
        {
          <Button
            callEvent={() => {!specialPlan && goTrial()}}
            className={`button06`}
            text={`${specialPlan ? "Ok, entendi" : "Acesse seu trial"}`}
          />
        }
      </div>
    </>
  );
};

export default FeedbackUpgrade;
