import React, { useState, useEffect } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import { uploadWebSite } from "../../services/NexiDigitalAPI";
import CircularProgress from "@material-ui/core/CircularProgress";

const CmsJornada = ({ titulo, setTitulo, contentLocation, setContentLocation }) => {
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [uploadItem, setUploadItem] = useState();
  const [listUploadItem, setListUploadItem] = useState();

  const handleUploadHtml = (e) => {
    setShowModalUpload(true);
    uploadFile(e.target.files[0], setUploadItem);
  };

  const uploadFile = (uploadFileItem, setUploadItem) => {
    const filename = "fe_thumb-" + Date.now();

    let data = new FormData();
    data.append("fileData", uploadFileItem);
    data.append("fileName", filename);
    data.append("nameOriginal", uploadFileItem.name);

    uploadWebSite(data, { setUploadItem, setContentLocation })
      .then((response) => {
        if (200 === response.status) {
          let fileData = response.data;
          setContentLocation(fileData.location || "");
        }
      })
      .catch((error) => {
        setContentLocation("");
      })
      .finally(() => setShowModalUpload(false));
  };

  useEffect(() => {
    if (uploadItem || listUploadItem) {
      let verificaItem = false;
      var list = listUploadItem;

      if (uploadItem !== "") {
        if (list) {
          list.map((item, index) => {
            if (item.fileName === uploadItem.fileName) {
              verificaItem = true;
              list[index].progress = uploadItem.progress;
            }
          });
          if (verificaItem === false) {
            list.push(uploadItem);
          }
        } else {
          list = new Array();
          list.push(uploadItem);
        }
      }
      setListUploadItem(list);
    }
  }, [uploadItem, listUploadItem]);

  return (
    <div className="form-group">
      <div className="card">
        <div className="card-header template-card-header">Título</div>
        <div className="card-body template-card-body">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                Título
              </span>
            </div>
            <input
              type="titulo"
              className="form-control"
              id="titulo"
              aria-describedby="titulo"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                Link para o Conteúdo
              </span>
            </div>
            <Col xs={12} sm={4} style={{ padding: 0 }}>
              <input
                type="text"
                className="form-control"
                id="contentLocation"
                aria-describedby="contentLocation"
                value={contentLocation || ""}
                onChange={(e) => {
                  setContentLocation(e.target.value);
                }}
              />
            </Col>
            <Col xs={12} sm={4} style={{ padding: 0 }}>
              <input
                type="file"
                className="form-control"
                id="contentLocationUpload"
                aria-describedby="contentLocationUpload"
                value={""}
                onChange={(e) => handleUploadHtml(e)}
              />
            </Col>
          </div>
        </div>
      </div>
      <Modal show={showModalUpload} animation={false}>
        {/* closeButton */}
        <Modal.Header>
          <Modal.Title>Seu arquivo está sendo carregado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              width: "100%",
              padding: listUploadItem ? "20px 20px 0px 20px" : "20px",
            }}
          >
            {listUploadItem
              ? listUploadItem.map((item, index) => {
                  return (
                    <div
                      style={{
                        marginRight: "20px",
                        marginBottom: "20px",
                        marginTop: "4px",
                        display: "inline-block",
                      }}
                      key={index}
                    >
                      <CircularProgress
                        variant="determinate"
                        value={item.progress}
                        style={{
                          verticalAlign: "middle",
                          display: "inline-block",
                          marginTop: "-4px",
                          color:
                            item.progress == "100"
                              ? "#4caf50"
                              : item.progress == "error"
                              ? "red"
                              : "",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          marginLeft: "4px",
                          marginTop: "-29px",
                          textAlign: "center",
                          width: "33px",
                          fontSize: "12px",
                        }}
                      >
                        {item.progress == "error" ? item.progress : item.progress + "%"}
                      </div>
                      <div
                        style={{
                          marginLeft: "5px",
                          display: "inline-block",
                          width: "calc(100% - 55px)",
                        }}
                      >
                        {item.fileName}
                      </div>
                    </div>
                  );
                })
              : "Nenhum upload em andamento"}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalUpload(false)}>
            Cancelar Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CmsJornada;
