import ColorLensIcon from "@material-ui/icons/ColorLens";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import TagIcon from "@material-ui/icons/LocalOffer";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import VideocamIcon from "@material-ui/icons/Videocam";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import "../../assets/css/TelaPrincipal.css";
import { AuthContext } from "../../context/AuthContext";
import {
  capitalizeFirstLetter,
  getMenuRoutes,
  getTypeName,
  transformText,
} from "../../utils/Utils";
import { MdNotificationsActive } from "react-icons/md";
import FileEdit from "assets/images/file-edit.svg";
function Configuracao(props) {
  const [redirect, setRedirect] = useState(false);
  const [data, setData] = useState([]);

  const { loginData } = useContext(AuthContext);
  const primaryColor = loginData.primaryColor;
  const [permissions, setPermission] = useState({
    "Gerenciamento de Tags": false,
    "Look And Feel App": false,
    "Menu Lateral": false,
    "Notificações": false,
    "Termos da Plataforma": false
  });

  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();
    const objCurrentMenu = menuOptionList.filter(
      (menu) => menu.text === "Configurações", // Use the name of the menu-option that is coming to your module
    );
    if (Boolean(objCurrentMenu.length)) {
      getPermissionsPage(objCurrentMenu[0].resource);
    }
  }

  /* [Permission Module] - Permission attrs in page by login data information */
  function getPermissionsPage(resources) {
    try {
      let newObj = {};
      for (let prop in permissions) {
        newObj = {
          ...newObj,
          [prop]: Boolean(resources.filter((menu) => menu.name === prop).length),
        };
      }

      setPermission({
        ...permissions,
        ...newObj,
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getMenuOption();
  }, []);

  useEffect(() => {
    console.log(permissions)
    let array = [];
    permissions["Gerenciamento de Tags"] &&
      array.push({ idContentGroup: "tags", idContentType: "tags", name: "tags" });
    permissions["Look And Feel App"] &&
      array.push({
        idContentGroup: "gestao-de-cor-e-logo",
        idContentType: "gestao-de-cor-e-logo",
        name: "Gestão de cor e logo",
      });
      permissions["Menu Lateral"] &&
      array.push({
        idContentGroup: "menu-lateral",
        idContentType: "menu-lateral",
        name: "Menu Lateral",
      });
      permissions["Notificações"] &&
      array.push({
        idContentGroup: "notificacoes",
        idContentType: "notificacoes",
        name: "Notificações",
      });
      //remover pos padd de rota
      permissions["Termos da Plataforma"] &&
      array.push({
        idContentGroup: "termos-da-plataforma",
        idContentType: "termos-da-plataforma",
        name: "Termos da Plataforma",
      });
    // array.push({ idContentGroup: 'zoom', idContentType: 'zoom', name: 'zoom' })
    // array.push({ idContentGroup: 'grupos', idContentType: 'grupos', name: 'grupos' })
    setData(array);
  }, [permissions]);

  if (redirect) {
    return <Redirect to="/login" />;
  }

  function getIconByName(name) {
    switch (name) {
      case "tags":
        return <TagIcon style={{ fontSize: 50 }} className="static-icon" />;
      case "zoom":
        return <VideocamIcon style={{ fontSize: 50 }} className="static-icon" />;
      case "grupos":
        return <GroupOutlinedIcon style={{ fontSize: 50 }} className="static-icon" />;
      case "gestao-de-cor-e-logo":
        return <ColorLensIcon style={{ fontSize: 50 }} className="static-icon" />;
      case "menu-lateral":
        return <MenuOpenIcon style={{ fontSize: 50 }} className="static-icon" />;
      case "notificacoes":
        return <MdNotificationsActive  style={{ fontSize: 50 }} className="static-icon" />
      case "termos-da-plataforma":
          return   <img src={FileEdit} style={{ fontSize: 50 }} alt="icon-remoção" className="static-icon"  />
      default:
        <></>
    }
  }
  return (
    <div>
      <div className="header-title-wrapper">
        <div className="header-title">Configurações</div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "stretch",
          justifyContent: "center",
          alignContent: "stretch",
          flexWrap: "wrap",
          marginLeft: -10,
          marginRight: -10,
        }}
      >
        {data.map((item, index) => {
          return (
            <NavLink
              className="menu-card-content col-lg-3 col-md-6 col-sm-6 col-xs-6 col-12"
              key={index}
              to={`/configuracao/${transformText(item.name)}/`}
              style={{ padding: 10, border: "none", textDecoration: "none", cursor: "pointer" }}
            >
              <div className="card card-effect" style={{ height: "100%" }}>
                <div
                  className="image-card"
                  style={{ color: "#FFF", backgroundColor: primaryColor }}
                >
                  {getIconByName(transformText(item.name))}
                </div>
                <div className="card-body">
                  <h6 className="card-title" style={{ color: "#861414" }}>
                    {capitalizeFirstLetter(item.name)}
                  </h6>
                  <span className="badge badge-pill badge-secondary badge-render">
                    {getTypeName(item.idContentType)}
                  </span>
                </div>
              </div>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
}

export default Configuracao;
