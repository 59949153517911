import React, { useState } from "react";
import "moment/locale/pt-br.js";
import Filtro from "./Filtro";
import Table from "./Table";
import ReportAcordeon from "../../../Components/ReportAcordeon";
import {
  getTrainingReport,
  createTrainingReport,
  getDashboardsValidityTrainings,
  getDownloadReport, 
  postDownloadReport
} from "../../../../../services/NexiDigitalAPI";
import ModalDialog from "./ModalDialog";

const TrainingValidityReport = ({ index, expanded: _expanded }) => {
  const name = "Relatório de Validade dos Treinamentos";
  const description =
    "Neste relatório você encontra informações sobre data de vencimento dos treinamentos orbrigatórios. Importante: Para visualizar as informações completas neste relatório é necessário informar a validade do treinamento no formulário de criação das páginas em Configurações Adicionais.";
  const [expanded, setExpanded] = useState(_expanded);
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const [trainingsValue, setTrainingsValue] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [modalErro, setModalErro] = useState(false);

  const idReport = 3;
  const onClickExpanded = (value) => {
    setExpanded(value);
  };
  const setTrainingReportPromises = async (values, call) => {
    const firstPromise = () => {
      return getDashboardsValidityTrainings(values);
    };

    const secondPromise = () => {
      const { offset, limit, ...rest } = values;
      return createTrainingReport(idReport, rest);
    };

    const promise = await Promise.all([firstPromise(), secondPromise()]);
    call(promise);
  };

  const actionFilter = (values) => {
    if (expanded) {
      setLoading(true);
      setTrainingsValue(values);

      const { offset, limit, ...rest } = values;

      getDashboardsValidityTrainings(values).then((response) => {
        const responseData = response.data;
        setTotalRecords(responseData.totalRecords);
        setDados(responseData.result);
        setLoading(false);
      });
    }
  };

  const downloadEvent = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    await postDownloadReport(idReport, {});
    await requestDownloadReport();
  };

  const requestDownloadReport = async () => {
    const response = await getDownloadReport(idReport);
    const { status, fileUrl } = response?.data || {};

    if (status === "Waiting") {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return requestDownloadReport();
    }

    if (status === "Done" && fileUrl) {
      getFileReport(fileUrl);
      setLoading(false);
    }
};

const getFileReport = (fileUrl) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.click();
};

  const filter = (
    <Filtro expanded={expanded} actionFilter={actionFilter} loading={loading} downloadEvent={downloadEvent} />
  );

  const result = (
    <Table
      dados={dados}
      actionFilter={actionFilter}
      loading={loading}
      totalRecords={totalRecords}
      trainingsValue={trainingsValue}
    />
  );

  return (
    <>
      <ModalDialog
        text={
          "A criação da planilha ainda não foi concluída. Por favor, aguarde um momento e tente novamente para efetuar o download."
        }
        show={modalErro}
        setShow={setModalErro}
        cancelStatus={false}
        btnCall={{
          variantBtn: "danger",
          text: "Confirmar",
          callEvent: () => {
            setModalErro(false);
          },
        }}
      />
      <ReportAcordeon
        onClickExpanded={onClickExpanded}
        name={name}
        description={description}
        filter={filter}
        result={result}
        index={index}
        expanded={expanded}
      />
    </>
  );
};

export default TrainingValidityReport;
