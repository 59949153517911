import ExternalCourseManager from "containers/PeopleCraft/ExternalCourseManager";
import React from "react";
import Header from "../../components/header";

const ExternalCoursePage = () => {
  return (
    <div className="ExternalCourses">
      <Header title={"Minha Equipe - Cursos externos"} />
      <ExternalCourseManager
        showHeader={false}
        showExternalCourseConfigPanel={false}
        showExportReportBtn={false}
        onlyStudentsFromManager
      />
    </div>
  );
}

export default ExternalCoursePage;
