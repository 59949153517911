// Basic
import axios from "axios";
import jwtDecode from "jwt-decode";
import moment from "moment";

// User authentication
export const STORAGE_KEY = "menu-data";
export const isAuthenticated = () => localStorage.getItem(STORAGE_KEY) !== null;
export const API_URL = process.env.REACT_APP_BACKEND_API;

export const getToken = () => {
  let menuData = JSON.parse(localStorage.getItem(STORAGE_KEY));
  if (menuData) {
    return menuData.token;
  } else {
    return null;
  }
};

// Init axios object
export let NexiDigitalAPI = axios.create({
  baseURL: API_URL,
  timeout: 1200000,
});

// Insert jwt token from localstorage in authorization header of all requests
NexiDigitalAPI.interceptors.request.use(
  (config) => {
    if (localStorage["token"]) {
      config.headers = {
        Authorization: "Bearer " + localStorage["token"],
      };

      if (config.url.startsWith("/learning/")) {
        config.headers["Tz-Offset"] = new Date().getTimezoneOffset();
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

NexiDigitalAPI.interceptors.response.use(
  (response) => {
    if (response.data.hasOwnProperty("newToken")) {
      updateToken(response.data.newToken);
    }
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      redirectInInvalidToken();
    }
    return Promise.reject(error);
  }
);

NexiDigitalAPI.interceptors.response.use(
  (response) => {
    // Redirect to login if token is expired
    const menuData = getMenuData();

    if (menuData) {
      const currentDate = moment().utc().format("YYYY-MM-DD");
      const lastLoginDate = menuData.lastLogin;

      if (currentDate > lastLoginDate) {
        _renewlogin();
      }
    }

    // Renew Token
    if (response.data.hasOwnProperty("newToken")) {
      updateToken(response.data.newToken);
    }

    return response;
  },
  (error) => {
    // Redirect on invalid token
    if (error?.response?.status === 401) {
      redirectInInvalidToken();
    }
  }
);

const redirectInInvalidToken = () => {
  localStorage.removeItem(STORAGE_KEY);
  window.location.replace("/");
};

export const getMenuData = () => {
  let menuData = JSON.parse(localStorage.getItem(STORAGE_KEY));
  return menuData;
};

export const setLoginData = (data) => {
  const date = moment().utc().format("YYYY-MM-DD");
  localStorage.setItem(STORAGE_KEY, JSON.stringify({ ...data, lastLogin: date }));
};

const updateToken = (newToken) => {
  let menuData = getMenuData();
  menuData.token = newToken;
  setLoginData(menuData);
};

export function getProfileInfo(urlToken) {
  let token = {};
  try {
    token = jwtDecode(urlToken || getToken());
  } catch {
    console.log("error on token get");
  }
  return token;
}

export async function _renewlogin(urlToken) {
  const profile = getProfileInfo(urlToken);
  const data = {
    idUser: profile.idUser,
    idAppType: profile.idAppType,
  };
  if (!data?.idUser) return;
  const response = await renewLogin(data);

  if (response.data?.success === true) {
    setLoginData({ ...response.data?.login, token: response.data?.token });
  }

  return response;
}

// Autenticação do usuario
export function userLogin(login, password) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/login",
    data: {
      email: `${login}`,
      password: `${password}`,
      idAppType: 1,
    },
  });
}

export function loginSecondary(loginData) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/companies/gettoken2",
    data: loginData,
  });
}

export function contentGroups(id) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/content/groups/`,
  });
}

export function getImportId(id) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/imports`,
  });
}

export function saveSection(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/sections",
    data: data,
  });
}

export function saveDesignParameter(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/design/setparameter",
    data: data,
  });
}

export function editMenuItem(data, sectionId) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/sections/editmenuitem/${sectionId}`,
    data: data,
  });
}

export function contentTypes() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/content/types/`,
  });
}

export function updateOfferTerms() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/logincpanel/acceptofferterms/1`,
  });
}

// Edita as informações do aluno
export function setUserAgreement(id, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/users/agreement/${id} `,
    data: data,
  });
}

export function contentItensGroups(id, data = "", offset = 0, limit, searchString, idContentType) {
  const queryParams = Object.entries({ offset, limit, searchString, idContentType })
    .filter(([, value]) => value !== undefined && value !== "")
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  return NexiDigitalAPI({
    method: "GET",
    url: `/content/contentgroup/${id + data}?${queryParams}`,
    data: data,
  });
}

export const getContentChild = (idJornadinha) => {
  return NexiDigitalAPI({
    method: "GET",
    url: `/content/listcontentchild/${idJornadinha}`,
  });
};

export function findContentItem(id) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/content/find/${id}`,
  });
}

export function getTags() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/tag/list/`,
  });
}

export function updateAppDetails(designData) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/app/updateappdesign",
    data: designData,
  });
}

export function setTags(tag) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/tag/insert/`,
    data: {
      tag: `${tag}`,
    },
  });
}

export function removeTag(id) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/tag/remove/${id}`,
  });
}

export function getPermissions(id) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/permission/${id}`,
  });
}

export function addPermission(data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/permission`,
    data: data,
  });
}

export function deletePermission(id) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/permission/${id}`,
  });
}

export function getCompany(appId) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/companies/${appId}`,
  });
}

export function getCompaniesByIdApp(idApp, pagination, searchText) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/companies/listcompanies/${idApp}?searchText=${searchText || ""}${
      pagination ? `&offset=${pagination.offset}&limit=${pagination.limit}` : ""
    }`,
  });
}

export function getCompanyByChannel(channelId) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/companies/channel/${channelId}`,
  });
}

export function getCompanyByChannelV2(companyName) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/companies/findchannelcompany?companyName=${companyName}`,
  });
}

export function getCompanyById(idCompany) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/companies/${idCompany}`,
  });
}

export function addCompany(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/companies",
    data: data,
  });
}

export function updateCompany(data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: "/companies",
    data: data,
  });
}

export function activateCompany(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/companies/activatechannelcompany",
    data: data,
  });
}

export function deactivateCompany(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/companies/deactivate",
    data: data,
  });
}

export function deleteCompany(idCompany) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/companies/${idCompany}`,
  });
}

export function getChannel() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/channel`,
  });
}

export function getChannelCampaign() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/channel/listchannelcampaign`,
  });
}

export function getApp() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/app`,
  });
}

export const logout = () => {
  localStorage.removeItem(STORAGE_KEY);
};

export function getSectionTypeList() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/sections/listsectiontype`,
  });
}

export function getSectionDesignList(sectionId) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/sections/listsectiondesign/${sectionId}`,
  });
}

export function getMenuList() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/sections/listmenu`,
  });
}

export function getMenuDesign(sectionId) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/sections/listmenudesign/${sectionId}`,
  });
}

export function uploadImageApi(data, progressItens = "") {
  data.append("token", getToken());
  return axios.post(`${process.env.REACT_APP_UPLOAD_FILE}`, data, {
    onUploadProgress: (event) => {
      let progress = Math.round((event.loaded * 100) / event.total);

      if (progressItens) {
        //console.log(progressItens.uploadItem);
        let uploadItem = {
          fileName: data.get("fileName"),
          name: data.get("nameOriginal") != "undefine" ? data.get("nameOriginal") : "",
          progress: progress,
        };
        progressItens.setUploadItem(uploadItem);
      }
    },

    headers: {
      "Content-Type": `multipart/form-data`,
    },
  });

  // return NexiDigitalAPI({
  //   method: "POST",
  //   url: `/upload`,
  //   data: data,
  //   headers: {
  //     accept: "application/json",
  //     "Accept-Language": "en-US,en;q=0.8",
  //     "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
  //   },
  // });
}

export function uploadWebSite(data, progressItens = "", set) {
  var fileName = data.get("fileName") != "undefine" ? data.get("fileName") : "";
  if (fileName) {
    data.delete("fileName");
  }

  var nomeOriginal = data.get("nameOriginal") != "undefine" ? data.get("nameOriginal") : "";
  if (nomeOriginal) {
    data.delete("nameOriginal");
  }

  return axios.post(`${process.env.REACT_APP_UPLOAD_WEBSITE}`, data, {
    onUploadProgress: (event) => {
      let progress = Math.round((event.loaded * 100) / event.total);

      if (progressItens) {
        let uploadItem = { fileName: "uploadWebSite", name: nomeOriginal, progress: progress };
        progressItens.setUploadItem(uploadItem);
      }
    },

    headers: {
      "Content-Type": `multipart/form-data`,
      Authorization: `Bearer ${getToken()}`,
    },
  });
}

export function uploadCsvApi(data) {
  data.append("token", getToken());
  return axios.post(`${process.env.REACT_APP_UPLOAD_USERIMPORT}`, data, {
    headers: {
      "Content-Type": `multipart/form-data`,
    },
  });
}

export function uploadEnrollmentCsvApi(data) {
  data.append("token", getToken());
  return axios.post(`${process.env.REACT_APP_UPLOAD_ENROLLMENTIMPORT}`, data, {
    headers: {
      "Content-Type": `multipart/form-data`,
    },
  });
}

export function checkImportStatus(id) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/imports/${id}/status`,
  });
}

export function addNewContent(data) {
  const { text, description, idContentType, idContentGroup, referenceLink, contentLocation } = data;

  return NexiDigitalAPI({
    method: "POST",
    url: `/content/`,
    data: {
      text,
      description,
      idContentType: `${idContentType}`,
      idContentGroup: `${idContentGroup}`,
      referenceLink,
      contentLocation,
      active: idContentGroup == 114 || idContentGroup == 138 ? 1 : 0, // 114 = jornadinha 138 = impact
    },
  });
}

export function addNewChildContent(data) {
  const {
    text,
    description,
    idContentType,
    idContentGroup,
    referenceLink,
    contentLocation,
    parent,
  } = data;

  let newData = { ...data, active: 0 };

  return NexiDigitalAPI({
    method: "POST",
    url: `/content/`,
    data: newData,
    /*{
      text,
      description,
      idContentType: `${idContentType}`,
      idContentGroup: `${idContentGroup}`,
      referenceLink: `${referenceLink}`,
      contentLocation: `${contentLocation}`,
      active: 0,
      parent: `${parent}`
    },*/
  });
}

export function orderJourneyPart(newOrder) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/content/orderContent`,
    data: {
      contentList: newOrder, // [1, 2, 3] use idContentItem
    },
  });
}

// Seleciona o appType de acordo com o endereco
export function appDetails(bucket) {
  return axios.post(`${API_URL}/app/details/`, { bucket });
  // return axios.post(`${API_URL}/app/details/`, { bucket: bucket });
}

export function updateContent(data) {
  const { idContentItem } = data;
  // remove o content id do objeto
  delete data.idContentItem;

  return NexiDigitalAPI({
    method: "PUT",
    url: `/content/${idContentItem}`,
    data: data,
  });
}

export function deleteContent(idContentItem) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/content/${idContentItem}`,
  });
}

export function updateContentHome(data) {
  const { idContentItem } = data;

  return NexiDigitalAPI({
    method: "PUT",
    url: `/content/${idContentItem}`,
    data: data,
  });
}

export function zoom_auth(code) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/meeting/zoom/auth/code=${code}`,
    data: {
      code: code,
    },
  });
}

export function zoom_edit(meeting) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/meeting/zoom/editzoommeeting`,
    data: {
      token: localStorage.getItem("zoom_token"),
      meeting: meeting,
    },
  });
}

export function zoom_add(meeting) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/meeting/zoom/setzoommeeting`,
    data: {
      token: localStorage.getItem("zoom_token"),
      meeting: meeting,
    },
  });
}

// Groups
export function getGroups() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/group/list`,
  });
}

export function createGroup(group) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/group/addgroup/`,
    data: {
      title: `${group}`,
    },
  });
}

export function updateGroup(id, group) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/group/${id}`,
    data: {
      title: `${group}`,
    },
  });
}

export function removeGroup(id) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/group/${id}`,
  });
}

export function getGroupUsers(id, filterObj) {
  return NexiDigitalAPI({
    method: "GET",
    url: filterObj
      ? `/group/groupusers/${id}?offset=${filterObj.offset}&limit=${filterObj.limit}&searchString=${filterObj.searchString}&inclusionType=${filterObj.inclusionType}`
      : `/group/groupusers/${id}`,
  });
}

export function linkUserToGroup(objLink) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/group/linkuser`,
    data: objLink,
  });
}

export function unlinkUserFromGroup(idGroups, idUser) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/group/unlinkuser`,
    data: {
      idGroups,
      idUser,
    },
  });
}

export function getLearningNodesTree() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/nodes/tree`,
  });
}

export function createLearningNode(node) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/nodes/`,
    data: node,
  });
}

export function createImage(obj) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/images/`,
    data: obj,
  });
}

export function getImage(idImage) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/images/${idImage}`,
  });
}

export function updateImage(obj) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/images/${obj.idImage}`,
    data: obj,
  });
}

export function editLearningNode(id, nodeData) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/nodes/${id}`,
    data: nodeData,
  });
}

export function getNodeLevels(nodecode) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/node-levels/?nodeCode=${nodecode}`,
  });
}

export function getLearningNode(nodeCode, onlyBasicInfo) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/nodes/${nodeCode}${onlyBasicInfo ? "?onlyBasicInfo=1" : ""}`,
  });
}

export function getCertificates(_idCompany) {
  return NexiDigitalAPI({
    method: "GET",
    url: "/learning/certificates",
    params: {
      idCompany: _idCompany,
    },
  });
}

export function getProfilePermissions(idProfile) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/permission/getprofilepermissions/${idProfile}`,
  });
}

export function createLearningBlock(block) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/blocks/`,
    data: block,
  });
}

export function removeLearningBlock(idBlock) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/learning/blocks/${idBlock}`,
  });
}

export function getLearningBlock(idBlock) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/blocks/${idBlock}`,
  });
}

export function updateLearningBlock(idBlock, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/blocks/${idBlock}`,
    data: data,
  });
}

export function getFeatures(featureName) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/features/${featureName}`,
  });
}

export function setFeatures(featureName, active) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/features/${featureName}`,
    data: { active },
  });
}

export function getFeaturesProfile(featureName, profileName) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/features/${featureName}/profiles/${profileName}`,
  });
}

export function setFeaturesProfile(featureName, profileName, active) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/features/${featureName}/profiles/${profileName}`,
    data: { active },
  });
}

export function editLearningBlockOrder(idBlock, order) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/blocks/${idBlock}/order/${order}`,
  });
}

export function linkElementToBlock(idBlock, data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/blocks/${idBlock}/elements`,
    data: data,
  });
}

export function removeBlockElement(idBlockElement, data) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/learning/block-elements/${idBlockElement}`,
    data,
  });
}

export function editBlockElementOrder(idBlockElement, order) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/block-elements/${idBlockElement}/order/${order}`,
  });
}

export function moveBlockElementOrder({ idBlockElement, orderNum, idBlock }) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/block-elements/${idBlockElement}/move`,
    data: {
      idBlock,
      orderNum,
    },
  });
}

export function getNodesToImport({ nodeId, blockId, offset, limit, textFilter, nodeLevel }) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/nodes/${nodeId}/blocks/${blockId}/nodes-to-import?offset=${offset}&limit=${limit}${
      textFilter ? "&searchString=" + textFilter : ""
    }${nodeLevel ? "&nodeLevel=" + nodeLevel : ""}`,
  });
}

export function getContentsToImport({
  idNode,
  idBlock,
  offset,
  limit,
  textFilter,
  idContentType,
  nodeType,
}) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/nodes/${idNode}/blocks/${idBlock}/contents-to-import?offset=${offset}&limit=${limit}${
      textFilter ? "&searchString=" + textFilter : ""
    }${idContentType ? "&idContentType=" + idContentType : ""}${
      nodeType ? "&nodeType=" + nodeType : ""
    }`,
  });
}

export function getEvaluationsToImportReaction(idNode, idBlock, params) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/nodes/${idNode}/blocks/${idBlock}/evaluations-to-import`,
    params: params,
  });
}

export function editLearningBlockParameter(idBlock, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/blocks/${idBlock}/parameters`,
    data: data,
  });
}

export function createLearningBlockParameter(idBlock, data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/blocks/${idBlock}/parameters`,
    data: data,
  });
}

export function toggleBlockSequential(idBlockElement) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/block-elements/${idBlockElement}/toggle-sequential`,
  });
}

export function toggleBlockMandatory(idBlockElement) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/block-elements/${idBlockElement}/toggle-mandatory`,
  });
}

export function setNodeStatusToPublished(idNode, publishChildren) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/nodes/${idNode}/publish?publishChildren=${publishChildren ? "1" : "0"}`,
  });
}

export function setNodeStatusToDraft(idNode) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/nodes/${idNode}/draft`,
  });
}
export function setNodeStatusToArchived(idNode) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/nodes/${idNode}/archive`,
  });
}

export function getUsers() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/users`,
  });
}

export function getUsersApi() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/users`,
  })
    .then((result) => {
      if (result.data.success === true) {
        return result.data.userList !== undefined ? result.data.userList : [];
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getTrainingsApi(idCompany) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/nodes/entrypoints-has-reaction-evaluation/${
      idCompany ? "?idCompany=" + idCompany : ""
    }`,
  })
    .then((result) => {
      return result.data || [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getUser(id) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/users/${id}`,
  });
}

export function editUser(id, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/users/${id}`,
    data: data,
  });
}

export function topRanking(filter) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/listtopranking",
    data: filter,
  })
    .then((response) => {
      if (response.data.success === true && response.data.data !== undefined) {
        return response.data.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}
export function postUserVisits(startDate, endDate, idUser) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/reports/uservisits`,
    data: {
      startDate: startDate,
      endDate: endDate,
      idUser: idUser,
    },
  });
}

export function lastRanking(filter) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/listlastranking",
    data: filter,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.data;
      }
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function listAllRooms() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/chat/listallrooms`,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.chatrooms;
      }
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function reportChatContent(filter) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/listchatcontent",
    data: filter,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.data !== undefined ? response.data.data : [];
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function reportGetUserUpload() {
  return NexiDigitalAPI.get(`/userupload/listsectionorigin`)
    .then((response) => {
      if (response.data.success === true) {
        return response.data.result;
      }
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function reportUserNoUpload(filter) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/listusernoupload",
    data: filter,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.content !== undefined ? response.data.content : [];
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function reportUserUpload(filter) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/listuserupload",
    data: filter,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.content !== undefined ? response.data.content : [];
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getSectionsList(idAppType) {
  return NexiDigitalAPI.get(`/sections/${idAppType}`)
    .then((response) => {
      if (response.data.success === true) {
        return response.data.sections;
      }
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function deleteSection(idAppType) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/sections/${idAppType}`,
  });
}

export function getSectionsStructureList(idAppType) {
  return NexiDigitalAPI.get(`/sections/getmenustructure/${idAppType}`)
    .then((response) => {
      if (response.data.success === true) {
        return response.data;
      } else {
        return [];
      }
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getContentList(sectionSelected) {
  return NexiDigitalAPI.get(`/content/list/${sectionSelected}`)
    .then((response) => {
      if (response.data.success === true && response.data.content) {
        return response.data.content;
      }

      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getGroupList() {
  return NexiDigitalAPI.get(`/group/list`)
    .then((response) => {
      if (response.data.success === true) {
        return response.data.groups;
      }

      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function reportStatisticsAccess(values) {
  return NexiDigitalAPI.post(`/reports/statisticsaccess`, values)
    .then((response) => {
      if (response.data.success === true) {
        return response.data;
      }

      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function getReportSections(values) {
  return NexiDigitalAPI.post(`/reports/sessions`, values)
    .then((response) => {
      if (response.data.success === true) {
        return response.data.data;
      }

      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function getReportUserActivity(values) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/uservisits",
    data: values,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getReportContentengagement(values) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/contentengagement",
    data: values,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getReportNonAccess(values) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/listcontentnonccess",
    data: values,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.data;
      }

      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}
export function getReportListtopcontentaccess(values) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/listtopcontentaccess",
    data: values,
  })
    .then((response) => {
      if (response.data.success === true && response.data.data !== undefined) {
        return response.data.data;
      }

      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getReportContentCommented(values) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/listcontentcommented",
    data: values,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.content;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getReportListlestcontentaccess(values) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/listlastcontentaccess",
    data: values,
  })
    .then((response) => {
      if (response.data.success === true && response.data.data !== undefined) {
        return response.data.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
    });
}

export function getOfferList(searchText) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/store/offers",
    data: { searchText: searchText },
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.offers;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getAdminOfferList(searchText) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/store/listoffers",
    data: { searchText: searchText },
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.offers;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getProductList() {
  return NexiDigitalAPI({
    method: "GET",
    url: "/store/listproducts",
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.listProducts;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function postCreateProduct(info) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/store/createproduct",
    data: info,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function postEditProduct(info, id) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/store/editproduct/${id}`,
    data: info,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function postCreateOffer(info) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/store/createoffer",
    data: info,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function postEditOffer(info, id) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/store/editoffer/${id}`,
    data: info,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function listPurchase(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/store/listpurchase`,
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.purchase;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function listPurchaseDetail(id) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/store/listpurchasedetail/${id}`,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.products;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function listPurchaseDailyReport(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `store/listpurchasesummarydaily`,
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.dates;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function listPurchaseProductReport(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/store/listpurchaseproduct`,
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.purchases;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function cancelpurchase(id) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/store/cancelpurchase/${id}`,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export async function GetUtilizationPerWeekDay(values) {
  try {
    const response = await NexiDigitalAPI.post(`/reports/getutilizationperweekday`, values);
    if (response.data.success === true) {
      return response.data.period;
    }
    return false;
  } catch (err) {
    return false;
  }
}

export async function GetUtilizationPerWeekDays(values) {
  try {
    const response = await NexiDigitalAPI.post(`/reports/getutilizationperday`, values);
    if (response.data.success === true) {
      return response.data.period;
    }
    return false;
  } catch (err) {
    return false;
  }
}

export async function GetContentCountEngagement(values) {
  try {
    const response = await NexiDigitalAPI.get(`/content/contentcountengagement`, values);
    if (response.data.success === true) {
      return response.data;
    }
    return false;
  } catch (err) {
    return false;
  }
}

export function addProfileApi(name) {
  const data = {
    name: name,
  };
  return NexiDigitalAPI({
    method: "PUT",
    url: "/permission/insertprofile",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function updateProfileApi(idProfile, name) {
  const data = {
    name: name,
  };
  return NexiDigitalAPI({
    method: "POST",
    url: "/permission/updateprofile/" + idProfile,
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function getProfiles(values) {
  return NexiDigitalAPI({
    method: "GET",
    url: "/permission/listProfile",
    data: values,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.listaPerfil;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function deleteProfile(idProfile) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: "/permission/deleteprofile/" + idProfile,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function getPermissionResources(values) {
  return NexiDigitalAPI({
    method: "GET",
    url: "/permission/listResource",
    data: values,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.listaRecursos;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getProfileResourcesApi(id) {
  const data = {
    idProfile: id,
  };
  return NexiDigitalAPI({
    method: "POST",
    url: "/permission/listResourceProfile",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.listaPerfil;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function addProfileResourcesApi(idProfile, idResource) {
  const data = {
    idProfile: idProfile,
    idResource: idResource,
  };
  return NexiDigitalAPI({
    method: "PUT",
    url: "/permission/insertresourceprofile",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function removeProfileResourcesApi(idProfile, idResource) {
  const data = {
    idProfile: idProfile,
    idResource: idResource,
  };
  return NexiDigitalAPI({
    method: "DELETE",
    url: "/permission/deleteresourceprofile",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function addUserIntoProfile(idProfile, idUser) {
  const data = {
    idUser: idUser,
    idProfile: idProfile,
  };
  return NexiDigitalAPI({
    method: "PUT",
    url: "/permission/insertuserprofile",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function getProfileUsers(idProfile) {
  return NexiDigitalAPI({
    method: "GET",
    url: "/permission/listuserprofile/" + idProfile,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.listUserProfile;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function deleteUserOfProfile(idProfile, idUser) {
  const data = {
    idUser: idUser,
    idProfile: idProfile,
  };
  return NexiDigitalAPI({
    method: "DELETE",
    url: "/permission/deleteuserprofile",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}
export function getContentWithAcessInteration(values) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/listlastcontentaccess",
    data: values,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.data;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}

export function getListCampaigns() {
  return NexiDigitalAPI({
    method: "GET",
    url: "/campaign/listcampaigns",
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.campaigns;
      }
      return [];
    })
    .catch((err) => {
      return [];
    });
}

export async function getListCampaignsDetail(id) {
  return await NexiDigitalAPI({
    method: "GET",
    url: `/campaign/listcampaigndetail/${id}`,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function getCampaignsEvents() {
  return await NexiDigitalAPI({
    method: "GET",
    url: "campaign/listevents/",
    params: { context: "LMS" },
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.event;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}

export function insertCampaigns(data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: "campaign/insertcampaign/",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function updatecampaign(id, data) {
  return await NexiDigitalAPI({
    method: "POST",
    url: `campaign/updatecampaign/${id}`,
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      throw err;
      return false;
    });
}

export async function listcampaignpointssummary(id, params) {
  return await NexiDigitalAPI({
    method: "GET",
    url: `campaign/listcampaignpointssummary/${id}`,
    params,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.campaignpoints;
      }
      return false;
    })
    .catch((err) => {
      throw err;
    });
}

export async function listcampaignuserpoints(info, params) {
  return await NexiDigitalAPI({
    method: "GET",
    url: `/campaign/${info.idCampaign}/users/${info?.idUser}/points`,
    params,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.campaignuserpointsList;
      }
      return false;
    })
    .catch((err) => {
      throw err;
    });
}

export async function postcampaignuserpoints(info, data) {
  return await NexiDigitalAPI({
    method: "POST",
    url: `/campaign/${info.idCampaign}/users/${info?.idUser}/points`,
    data,
  });
}

export async function departmentscampaign(idCampaign) {
  return await NexiDigitalAPI({
    method: "GET",
    url: `campaign/${idCampaign}/departments`,
  });
}

export async function getAppColors() {
  return await NexiDigitalAPI({
    method: "GET",
    url: `companies/listlookandfeel/`,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.lookandFeel;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function updateAppColors(data) {
  return await NexiDigitalAPI({
    method: "POST",
    url: `companies/lookandfeel/`,
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function getTokenToApplyQrcode() {
  return await NexiDigitalAPI({
    method: "GET",
    url: `companies/gettoken/`,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function checkQrCodeEnable(token) {
  try {
    return axios
      .get(`${API_URL}/companies/availablelicences`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        "Content-Type": "application/json",
      })
      .then(({ data }) => {
        return Boolean(data.availableLicences > 0);
      })
      .catch((e) => {
        console.log(e);
      });
  } catch (e) {
    return "false";
  }
}

export async function listUserEngagement(values) {
  return await NexiDigitalAPI({
    method: "post",
    url: `/reports/userconsumption`,
    data: values,
  })
    .then((response) => {
      if (response.data.success === true && response.data.data !== undefined) {
        return response.data.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export async function listContentTypeProgress() {
  return await NexiDigitalAPI({
    method: "GET",
    url: `/content/listcontenttypeprogress`,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.content;
      }
      return [];
    })
    .catch((err) => {
      return [];
    });
}

export async function listUsersAccess(values) {
  return await NexiDigitalAPI({
    method: "POST",
    url: `/reports/contentengagementpercontent`,
    data: values,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
    });
}

export async function listTagsReport() {
  return await NexiDigitalAPI({
    method: "POST",
    url: `/content/listcontenttag`,
  })
    .then((response) => {
      console.log("response", response);
      if (response.data.success === true) {
        return response.data.contenttaglist;
      }
      return [];
    })
    .catch((err) => {
      return [];
    });
}

export async function listTopContentItemProgress() {
  return await NexiDigitalAPI({
    method: "GET",
    url: `/content/listtopcontentitemprogress`,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.content;
      }
      return [];
    })
    .catch((err) => {
      return [];
    });
}

/*
 *
 *funcoes agenda
 *
 */
export function getSchedules(filterTypes) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/schedule/listschedules${filterTypes ? "?scheduleTypes=" + filterTypes : ""}`,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
}

export function insertSchedules(values) {
  return NexiDigitalAPI({
    method: "PUT",
    url: "/schedule/insertschedule",
    data: values,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}

export function updateSchedules(values) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/schedule/updateschedule/" + values.id,
    data: values,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data;
      }
      return false;
    })
    .catch(() => {
      return false;
    });
}

export function deleteSchedules(id) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: "/schedule/" + id,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data;
      }
      return false;
    })
    .catch(() => {
      return false;
    });
}

export function getSchedulesTypes() {
  return NexiDigitalAPI({
    method: "GET",
    url: "/schedule/listschedulestype",
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.schedulesType;
      }
      return false;
    })
    .catch(() => {
      return false;
    });
}

export function addSchedulesType(description) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/schedule/types",
    data: { description },
  })
    .then((response) => {
      if (response.data.success === true) {
        return response;
      }
      return false;
    })
    .catch((err) => {
      console.log("err", err);
      return false;
    });
}

export function removeScheduleType(idScheduleType) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/schedule/types/${idScheduleType}`,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      console.log("err", err);
      return false;
    });
}

export function editScheduleType(idScheduleType, description) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/schedule/types/${idScheduleType}`,
    data: { description },
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      console.log("err", err);
      return false;
    });
}

/* termina funcoes agenda*/

export function sendEmailBoasVindasApi(email) {
  return NexiDigitalAPI({
    method: "post",
    url: "/login/sendwelcomeemail",
    data: { email: email },
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function contentOrder(list) {
  const data = {
    contentList: list,
  };
  return NexiDigitalAPI({
    method: "Post",
    url: `/content/orderContent`,
    data: data,
  });
}

export function getLearningProducts() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/products`,
  });
}

export function getCompanyLearningProducts(idCompany) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/products/${idCompany}`,
  });
}

export function assignLearningProduct(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/products/assign`,
    data: data,
  });
}

export function unassignLearningProduct(idProduct, idTargetCompany) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/learning/products/${idProduct}/companies/${idTargetCompany}`,
  });
}

export function getLearningTrainingsTreeChild(nodeCode) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/products/company/nodes?nodeCode=${nodeCode}`,
  });
}

export function getLearningTrainingsTree() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/products/company/nodes`,
  });
}

export function disableLearningTraining(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/products/company/nodes/disable`,
    data: data,
  });
}

export function enableLearningTraining(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/products/company/nodes/enable`,
    data: data,
  });
}

export function getBlendedNodes(idTeam) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/teams/${idTeam}/blended-nodes`,
  });
}

export function saveLearningMeetings({ idTeam, data }) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/teams/${idTeam}/meetings`,
    data: data,
  });
}

export function getLearningMeetings(idTeam, nodeCode) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/teams/${idTeam}/meetings?${nodeCode ? "nodeCode=" + nodeCode : ""}`,
  });
}

export function getLearningManagement() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/nodes/tree`,
  })
    .then((response) => {
      if (response.data && response.data.result) {
        return response.data.result;
      }
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getLearningManagementNodeCode(nodeCode) {
  return NexiDigitalAPI({
    method: "GET",
    // url: `/learning/nodes/?type=ORGANIZATION`,
    url: `/learning/nodes/tree?nodeCode=${nodeCode}`,
  })
    .then((response) => {
      if (response.data && response.data.result) {
        return response.data.result;
      }
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function removeNodeLearningManagement(idNode) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `learning/nodes/${idNode}`,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

// ClassManagement
export function getlistClass({ offset = 0, limit, nodeCode }) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/teams/?offset=${offset}${limit ? "&limit=" + limit : ""}&nodeCode=${nodeCode}`,
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getTeams({ idTeam }) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/teams/${idTeam}`,
  });
  // .then((response) => {
  //   if (response.data) {
  //     return response.data;
  //   }
  //   return [];
  // })
  // .catch((err) => {
  //   return [];
  //   throw err;
  // });
}

export function getUsersWorkTeam(obj) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/users/${obj.idUser}/work-team/`,
    params: {
      offset: obj.offset,
      limit: obj.limit,
      searchText: obj.searchText,
      onlyDirects: obj.onlyDirects,
    },
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

//API para listar os itens de uma lista de presença.
export function getAttendanceList(obj) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/teams/${obj.idTeam}/meetings/${obj.idMeeting}/attendance-list`,
    params: {
      offset: obj.offset,
      limit: obj.limit,
      searchString: obj.searchString,
    },
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

// Endpoint responsável por registrar a presença de usuários
export function saveAttendanceList(obj, present) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/teams/${obj.idTeam}/meetings/${obj.idMeeting}/attendance-list`,
    data:
      present != null
        ? {
            users: [],
            present: present,
          }
        : {
            users: obj.users,
          },
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      throw new Error(err.status);
    });
}

// Endpoint responsável por finalizar a lista de presença de um encontro
export function finishAttendanceList(obj) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/teams/${obj.idTeam}/meetings/${obj.idMeeting}/finish`,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      throw new Error(err.status);
    });
}

// Create
export function createTeams({ data }) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/teams`,
    data: data,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw new Error(err.status);
    });
}

// Editar
export function editTeams({ idTeam, data }) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/teams/${idTeam}`,
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      throw new Error(err.status);
    });
}

// Esvaziar Turma
export function emptyTeam({ idTeam }) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/teams/${idTeam}/empty`,
    data: {},
  })
    .then((response) => {
      return true;
    })
    .catch((err) => {
      throw new Error(err.status);
    });
}

// Pegar os alunos matriculados
export function getLearningEnrollments(params) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/enrollments/`,
    params,
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

// Pegar os alunos não matriculados
export function getLearningNotEnrollments(paramsQuery) {
  const { idTeam, ...params } = paramsQuery;
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/teams/${idTeam}/users-not-enrolled`,
    params,
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

// Post Learning Enrollments
export function postLearningEnrollments({ idTeam, idUser }) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/learning/enrollments/",
    data: {
      idTeam: `${idTeam}`,
      idUser: `${idUser}`,
    },
  });
}

export function removeLearningEnrollments({ idEnrollment }) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/learning/enrollments/${idEnrollment}`,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

// Editar
export function editLearningEnrollmentsStatus({ data }) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/enrollments/${data.idEnrollment}/status`,
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function uploadScorm(data) {
  return axios.post(`${process.env.REACT_APP_UPLOAD}/uploads/scormImport`, data, {
    headers: {
      "Content-Type": `multipart/form-data`,
    },
  });
}

export function processScorm(data) {
  return axios.post(`${process.env.REACT_APP_UPLOAD}/uploads/scormProcess`, {
    fileKey: data,
  });
}

//
export function getDashboardsOverview(idCompany) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/dashboards/overview`,
    params: { idCompany },
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getDashboardsUsersRegister({
  startDate,
  finishDate,
  idCompany,
  limit,
  offset,
  searchString,
}) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/dashboards/users`,
    params: { startDate, finishDate, idCompany, limit, offset, searchString },
  });
}

export function getCompanyRegister({ startDate, finishDate, idCompany, limit, offset }) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/dashboards/companies?startDate=${startDate}&finishDate=${finishDate}${
      Boolean(idCompany) ? "&idCompany=" + idCompany : ""
    }&limit=${limit}&offset=${offset}`,
  });
}

export function getEnrollmentsReport({
  startDate,
  finishDate,
  idCompany,
  limit,
  offset,
  idTeam,
  nodeCode,
}) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/dashboards/enrollments`,
    params: { startDate, finishDate, idCompany, limit, offset, idTeam, nodeCode },
  });
}

export function getReactionEvaluation({ nodeCode, idTeam, idCompany, limit, offset }) {
  return NexiDigitalAPI({
    method: "GET",
    url: `learning/dashboards/reaction-evaluation?nodeCode=${nodeCode}${
      idTeam ? "&idTeam=" + idTeam : ""
    }${idCompany ? "&idCompany=" + idCompany : ""}&limit=${limit}&offset=${offset}`,
  });
}

export function getImpactEvaluation({ idCompany, limit, offset }) {
  return NexiDigitalAPI({
    method: "GET",
    url: `learning/dashboards/impact-evaluation?${
      idCompany ? "idCompany=" + idCompany : ""
    }&limit=${limit}&offset=${offset}`,
  });
}

export function getDashboardsLearningData(startDate, finishDate, idCompany) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/dashboards/learning-consumption`,
    params: { startDate, finishDate, idCompany },
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getStudents(idCompany, limit, offset, searchString) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/students/`,
    params: { idCompany, limit, offset, searchString },
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getStudentsEnrollments(idUsers) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/students/${idUsers}/enrollments/`,
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}
export function getStudentsEnrollmentsBlocks(idUsers, idEnrollment) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/students/${idUsers}/enrollments/${idEnrollment}`,
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getCertificateEnrollments(obj) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/certificate`,
    data: obj,
    headers: { Accept: "application/pdf" },
    // responseType: "blob"
  });
}

export function getStudentsEnrollmentsNodes(idUsers, idEnrollment, nodeCode) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/students/${idUsers}/enrollments/${idEnrollment}/nodes/${nodeCode}`,
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getContactAndSupportEmailTemplate() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/notifications/emails/CONTACT_US`,
  });
}

export function updateContactAndSupportEmailTemplate(data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/notifications/emails/CONTACT_US`,
    data,
  });
}

export function toggleContactAndSupportEmailTemplate(idEmailTemplate) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/notifications/emails/${idEmailTemplate}/toggle-contact-us`,
  });
}

export function getCertificatePlaceholders() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/certificate-placeholders/`,
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function createCertificate(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/learning/certificates/",
    data: data,
  });
}

export function getCertificate(idCertificate) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/certificates/${idCertificate}`,
  });
}

export function editCertificate(idCertificate, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/certificates/${idCertificate}`,
    data: data,
  });
}

// Learning Evaluations
export function getLearningEvaluationsAll(params) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/evaluations`,
    params: params,
  });
}
export function getLearningEvaluations(idEvaluation) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/evaluations/${idEvaluation}`,
  });
}

export function postLearningEvaluations(dataObj) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/learning/evaluations/",
    data: dataObj,
  });
}

export function editLearningEvaluations(idEvaluation, dataObj) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/evaluations/${idEvaluation}`,
    data: dataObj,
  });
}

export function addQuestionToEvaluation({ idEvaluation, idQuestion }) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/evaluations/${idEvaluation}/questions/${idQuestion}`,
  });
}

export function getEvaluationQuestions(idEvaluation) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/evaluations/${idEvaluation}/questions`,
  });
}

export function saveAndAddEvaluation(idEvaluation, data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/evaluations/${idEvaluation}/questions`,
    data: data,
  });
}

export function deleteCertificate(idCertificate) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/learning/certificates/${idCertificate}`,
  });
}

export function replaceCertificate(idCertificate, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/certificates/${idCertificate}/replace`,
    data: data,
  });
}

export function getCertificatesList(limit, offset, searchString, idCertificate = null) {
  const params = idCertificate
    ? { limit, offset, searchString, idCertificate }
    : { limit, offset, searchString };

  return NexiDigitalAPI({
    method: "GET",
    url: "/learning/certificates",
    params: params,
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function deleteEvaluationQuestion(idEvaluation, idQuestion) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/learning/evaluations/${idEvaluation}/questions/${idQuestion}`,
  });
}

export function moveEvaluationQuestion(idEvaluation, idQuestion, order) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/evaluations/${idEvaluation}/questions/${idQuestion}/order/${order}`,
  });
}

// Learning Evaluations Categories
export function getLearningCategories(type, idEvaluation) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/${type}/${idEvaluation}/categories`,
  });
}

export function postLearningCategories(type, id, dataObj) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/${type}/${id}/categories`,
    data: dataObj,
  });
}

export function deleteLearningCategories(type, idEvaluation, idCategory) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/learning/${type}/${idEvaluation}/categories/${idCategory}`,
  });
}

// Learning Questions
export function postLearningQuestions(obj) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/learning/questions/",
    data: obj,
  });
}

export function postLearningQuestionsInEvaluation(idEvaluation, obj) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/${idEvaluation}/questions/`,
    data: obj,
  });
}

//
export function getLearningQuestions({
  idEvaluation = null,
  searchString = null,
  type = null,
  evaluationType = null,
  offset = 0,
  limit = 100,
  includeEvaluationCount = null,
}) {
  const idEvaluationValidate = idEvaluation ? { idEvaluation: idEvaluation } : {};
  const searchStringValidate = searchString ? { searchString: searchString } : {};
  const typeValidate = type ? { type: type } : {};
  const evaluationTypeValidate = evaluationType ? { evaluationType: evaluationType } : {};
  const includeEvaluationCountValidate = includeEvaluationCount
    ? { includeEvaluationCount: includeEvaluationCount }
    : {};
  const paramsValidate = {
    offset: offset,
    limit: limit,
    ...idEvaluationValidate,
    ...searchStringValidate,
    ...typeValidate,
    ...evaluationTypeValidate,
    ...includeEvaluationCountValidate,
  };

  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/questions/`,
    params: paramsValidate,
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function putLearningQuestions(idQuestion, obj) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/questions/${idQuestion}`,
    data: obj,
  });
}

export function getLearningUniqueQuestion(idQuestion) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/questions/${idQuestion}`,
  });
}

export function editLearningUniqueQuestion(idQuestion, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/questions/${idQuestion}`,
    data: data,
  });
}

export function getLearningQuestionCategories(idQuestion) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/questions/${idQuestion}/categories`,
  });
}

/// Groups = Regras

export function getGroupsRules(groupId) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/group/${groupId}/rules`,
  });
}

export function editGroupsRules(groupId, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/group/${groupId}/rules`,
    data: data,
  });
}

/// Control de Groups nos times
export function getGroupsSecondary() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/group/list`,
  });
}

export function getGroupsInTeams(idTeam) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/teams/${idTeam}/groups/`,
  });
}

export function getGroupsInTeamsEnrollInfo(idTeam) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/teams/${idTeam}/groups/enroll-info/`,
  });
}

export function postAddGroupsInTeams(idTeam, data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/teams/${idTeam}/groups`,
    data: data,
  });
}

export function putActivateAutoEnroll(idTeam, status) {
  // activate - deactivate
  return NexiDigitalAPI({
    method: "PUT",
    url: `learning/teams/${idTeam}/groups/${status}-auto-enroll`,
  });
}

export function deleteGroupsInTeams(idTeam, idGroups) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/learning/teams/${idTeam}/groups/${idGroups}`,
  });
}

// Create
export function createTrainingReport(idReport, data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/reports/${idReport}`,
    data: data,
  });
}

export function getTrainingReport(idReport) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/reports/${idReport}/result`,
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch(() => {
      return [];
    });
}

export function getDashboardsValidityTrainings({
  startDate,
  finishDate,
  idCompany,
  limit,
  offset,
}) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/dashboards/validity-trainings`,
    params: { idCompany, limit, offset },
  });
}

export function getDashboardsTrainings({ startDate, finishDate, idCompany, limit, offset }) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/dashboards/trainings`,
    params: { startDate, finishDate, idCompany, limit, offset },
  });
}

export function getNodesBreadcrumb(nodeCode) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/nodes/${nodeCode}/breadcrumb`,
  });
}

export function postCategoriesService(path, dataObj) {
  return NexiDigitalAPI({
    method: "POST",
    url: path,
    data: dataObj,
  });
}

export function deleteCategoriesService(path) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: path,
  });
}

export function getCategoriesService(path) {
  return NexiDigitalAPI({
    method: "GET",
    url: path,
  });
}

export function getGroupsCampaing(idCampaign) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/campaign/${idCampaign}/groups`,
  });
}
export function postGroupsCampaing(idCampaign, data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/campaign/${idCampaign}/groups`,
    data,
  });
}
export function deleteGroupsCampaing(idCampaign, idGroups) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/campaign/${idCampaign}/groups/${idGroups}`,
  });
}

export function deleteCampaing(idCampaign) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/campaign/${idCampaign}`,
  });
}

export function postDownloadReport(idReport, data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/reports/${idReport}`,
    data,
  });
}

export function getDownloadReport(idReport) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/reports/${idReport}/result`,
  });
}

export function setNodeVisibility(nodeCode, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/nodes/${nodeCode}/configuration`,
    data: data,
  });
}

export function linkGroupToNode(nodeCode, data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/nodes/${nodeCode}/groups-visibility`,
    data,
  });
}

export function unlinkGroupFromNode(nodeCode, idNodeVisibilityGroups) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/learning/nodes/${nodeCode}/groups-visibility/${idNodeVisibilityGroups}`,
  });
}

export function getLinkedGroupsToNode(nodeCode) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/nodes/${nodeCode}/groups-visibility`,
  });
}

export function getLearningEnrollmentRequest(params) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/enrollment-requests/`,
    params,
  });
}

export function putActionRequestEnrollment(id, action, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/enrollment-requests/${id}/${action}`,
    data,
  });
}
export function putIncreaseCapacity(id) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/enrollment-requests/${id}/increase-capacity-aprove`,
  });
}

export function getUserWorkTeam(idUser, params) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/users/${idUser}/work-team`,
    params,
  });
}

export function getTotalImpactEvulations(idUser) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/managers/${idUser}/total-impact-evaluations`,
  });
}

export function getImpactEvulations(idUser) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/students/${idUser}/impact-evaluations`,
  });
}

// Avaliação de impacto
export function getQuestionnaire(obj) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/managers/${obj.idManager}/questionnaires/${obj.idQuestionnaire}`,
  });
}

export function postQuestionnairesFinish(data, idManager, idQuestionnaire) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/managers/${idManager}/questionnaires/${idQuestionnaire}/finish`,
    data: data,
  });
}

export function postQuestionnairesSave(data, idManager, idQuestionnaire) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/managers/${idManager}/questionnaires/${idQuestionnaire}/save`,
    data: data,
  });
}

export const getTrialPlans = (withDetails) => {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/plans/`,
    params: { withDetails: withDetails },
  });
};

export const postRequestTrialPlans = (data) => {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/plans/request-trial`,
    data,
  });
};

export const postRequestBuyPlans = (data) => {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/plans/request-plan `,
    data,
  });
};
export function getNotificationsConfigurations() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/notifications/configurations`,
  });
}

export function postNotificationsConfigurations(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/notifications/configurations`,
    data,
  });
}

export function getEmailSenders() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/notifications/emailsenders`,
  });
}

export function postEmailSenders(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/notifications/emailsenders`,
    data,
  });
}

export function toggleCustomEmailSenders(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/notifications/emailsenders/toggle`,
    data,
  });
}

export const renewLogin = (data) => {
  return NexiDigitalAPI({
    method: "POST",
    url: `/logincpanel/renewlogin`,
    data,
  });
};
export function postNotificationsConfigurationsLearning(data, idNode) {
  return NexiDigitalAPI({
    method: "POST",
    url: `notifications/configurations/nodes/${idNode}`,
    data,
  });
}

export function postQuestion(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/questions`,
    data: data,
  });
}

export function editQuestion(idQuestion, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/questions/${idQuestion}`,
    data: data,
  });
}

export function deleteQuestion(idQuestion) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/learning/questions/${idQuestion}`,
  });
}

export function getEvaluations(params) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/evaluations/`,
    params: params,
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function deleteEvaluations(idEvaluation) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/learning/evaluations/${idEvaluation}`,
  });
}

export function getImpactEvaluationParams(nodecode) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/nodes/${nodecode}/impact-evaluation-params`,
  });
}

export function putImpactEvaluationParams(nodecode, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/nodes/${nodecode}/impact-evaluation-params`,
    data,
  });
}

export function putLearningConfiguration(data, nodecode) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `learning/nodes/${nodecode}/configuration`,
    data,
  });
}

export function postMigrateUsers(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `totvs/migrateusers`,
    data,
  });
}

export function getMigrateUsers(params) {
  return NexiDigitalAPI({
    method: "GET",
    url: `totvs/user-migrations`,
    params,
  });
}

export function getDetailsMigrateUsers(idUserMigration, params) {
  return NexiDigitalAPI({
    method: "GET",
    url: `totvs/user-migrations/${idUserMigration}/items`,
    params,
  });
}

// Comentários
export function listComments(entityType, idEntity, params) {
  const queryParams = new URLSearchParams(params).toString();
  return NexiDigitalAPI({
    method: "GET",
    url: `learning/comments/${entityType}/${idEntity}?${queryParams}`,
  });
}

export function insertComment(entityType, idEntity, data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `learning/comments/${entityType}/${idEntity}`,
    data,
  });
}

export function updateComment(entityType, idEntity, idComment, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `learning/comments/${entityType}/${idEntity}/${idComment}`,
    data,
  });
}

export function reportComment(entityType, idEntity, data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `learning/comments/${entityType}/${idEntity}`,
    data,
  });
}

export function deleteComment(entityType, idEntity, idComment) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `learning/comments/${entityType}/${idEntity}/${idComment}`,
  });
}

export function toggleVisibilityComment(entityType, idEntity, idComment, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `learning/comments/${entityType}/${idEntity}/${idComment}/toggle-visibilitiy`,
    data,
  });
}

export function toggleNotification(idTeam, idNotification, data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `learning/teams/${idTeam}/notifications/${idNotification}`,
    data,
  });
}

export function listNotificationSchedule(idTeam, idNotification, data) {
  return NexiDigitalAPI({
    method: "GET",
    url: `learning/teams/${idTeam}/notifications/${idNotification}/schedule`,
  });
}

export function updateNotificationSchedule(idTeam, idNotification, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `learning/teams/${idTeam}/notifications/${idNotification}/schedule`,
    data,
  });
}

export function getTerms(params) {
  return NexiDigitalAPI({
    method: "GET",
    url: `learning/terms`,
    params,
  });
}

export function insertTerms(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `learning/terms/`,
    data,
  });
}

export function updateTerms(data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `learning/terms/${data.idTermOfResponsibility}`,
    data,
  });
}

export function getTermsById(idTermOfResponsibility) {
  return NexiDigitalAPI({
    method: "GET",
    url: `learning/terms/${idTermOfResponsibility}`,
  });
}

export function changeStatusTerms(data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `learning/terms/${data.idTermOfResponsibility}/toggle-active`,
    data,
  });
}

export function acceptResponsabilityTerms({ idTermOfResponsibility, versionNumber }) {
  return NexiDigitalAPI({
    method: "POST",
    url: `learning/terms/${idTermOfResponsibility}/${versionNumber}`,
  });
}

export function deleteTerms(id) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `learning/terms/${id}`,
  });
}

export function getTermOfResponsibilityById(id, params) {
  return NexiDigitalAPI({
    method: "GET",
    url: `learning/terms/${id}`,
    params,
  });
}

// Pegar equivalences
export function getEquivalences(params) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/nodes/${params.idNodeTarget}/equivalences`,
    params,
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

// Pegar não equivalences
export function getToSetEquivalences(params) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/nodes/${params.idNodeTarget}/nodes-to-set-as-equivalents`,
    params,
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

// Post Equivalents
export function postEquivalences({ idNodeTarget, idNodeOrigin }) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/nodes/${idNodeTarget}/equivalences`,
    data: {
      idNodeOrigin: idNodeOrigin,
    },
  });
}

// Remove Equivalents
export function removeEquivalents({ idNodeTarget, idNodeOrigin }) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/learning/nodes/${idNodeTarget}/equivalences/${idNodeOrigin}`,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export const uploadAttendanceListApi = (data) => {
  data.append("token", getToken());
  return axios.post(`${process.env.REACT_APP_UPLOAD_ATTENDANCE_LIST}`, data, {
    headers: {
      "Content-Type": `multipart/form-data`,
    },
  });
};

export const saveAttendanceListPath = (idTeam, idMeeting, signedAttendanceListPath) => {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/teams/${idTeam}/meetings/${idMeeting}/signed-attendance-list`,
    data: {
      idMeeting,
      signedAttendanceListPath,
    },
  });
};

export function getExternalCourses(params) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/externalcourses/`,
    params: params,
  });
}

export function getExternalCoursesId(id) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/learning/externalcourses/${id}`,
  });
}

export function setExternalCourses(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/learning/externalcourses/`,
    data: data,
  });
}

export function setExternalCoursesId(id, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/externalcourses/${id}`,
    data: data,
  });
}

export function cancelExternalCourses(id) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/externalcourses/${id}/cancel`,
  });
}

export function approveExternalCourses(id) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/externalcourses/${id}/approve`,
  });
}

export function rejectExternalCourses(id, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/learning/externalcourses/${id}/reject`,
    data: data,
  });
}

export default NexiDigitalAPI;
