import "./styles.scss";

import React, { useCallback, useContext, useEffect, useState } from "react";

import CardUpgrade from "../CardUpgrade";
import parse from "html-react-parser";
import { useModal } from "@ebay/nice-modal-react";
import { useManagerTrial } from "../../context/contextManagerTrial";
import { PlaneProps, Status } from "../../model/ManagerTrial.model";
import Button from "components/PeopleCraft/Button";
import Text from "components/PeopleCraft/Text";
import { textPlansStatus } from "../../Utils/TextPlansStatus";
const SelectedPlanUpgrade = ({}) => {

  const [otherPlans, setOtherPlans] = useState<PlaneProps[]>([]);
  const modal = useModal();

  useEffect(() => {
    getOtherPlans();
  }, []);

  const { trialStatusPlan, currentCardUpgrade, setUpgradeStatus,  listPlans } =
    useManagerTrial();


  const { name, deadline, planUpgrade } = currentCardUpgrade as PlaneProps;

  const planText = {
    screen: "SelectedPlanUpgrade",
    trialStatusPlan: trialStatusPlan,
    deadline,
    plan: `${name}`,
    requestsInfo: planUpgrade?.numberRequest,
  };

  const getOtherPlans = () => {
    const areOtherPlans = (listPlans || []).filter((plan) => plan.idPlan !== currentCardUpgrade?.idPlan);
    setOtherPlans(areOtherPlans as PlaneProps[]);
  };

  const buyPlan = async () => {
    setUpgradeStatus(Status.calculatorPlan);

  };

  return (
    <div className="pctSelectedPlanUpgrade">
      <div className={`pctSelectedPlaItemCurrent`}>
        {currentCardUpgrade && (
          <CardUpgrade
            key={`card-upgrade-${currentCardUpgrade.idRef}`}
            showDetail={true}
            elem={currentCardUpgrade}
          />
        )}
      </div>
      <div className="pctSelectedPlaItemInfo">

        <Text
          component={"h1"}
          text={textPlansStatus({
            ...planText,
            element: "h1",
          })}
        />
        <Text
          component={"p"}
          text={textPlansStatus({
            ...planText,
            element: "p",
          })}
        />
        <div className="buttonsWrapperSelected">
          {otherPlans.length > 0 && (
            <Button
              callEvent={() => {
                setUpgradeStatus(Status.upgrade);
              }}
              className={`button01`}
              text={`Ver planos`}
            />
          )}
          <Button callEvent={buyPlan} className={`button06`} text={`Contratar agora`} />
        </div>
      </div>
    </div>
  );
};

export default SelectedPlanUpgrade;
