// Basic
import axios from "axios";
import { getMenuData, loginSecondary } from "./NexiDigitalAPI";

// User authentication
export const STORAGE_KEY_SECONDARY = "menu-data-secondary";
export const isAuthenticated = () => localStorage.getItem(STORAGE_KEY_SECONDARY) !== null;
export const API_URL = process.env.REACT_APP_BACKEND_API;

export const getTokenSecondary = () => {
  let menuData = JSON.parse(localStorage.getItem(STORAGE_KEY_SECONDARY));
  if (menuData) {
    return menuData.token;
  } else {
    return null;
  }
};

// Init axios object
export let NexiDigitalAPISecondary = axios.create({
  baseURL: API_URL,
  timeout: 1200000,
});

// Add interceptor
NexiDigitalAPISecondary.interceptors.request.use(
  async (config) => {
    const tokenSecondary = localStorage["token-secondary"];
    if (tokenSecondary && tokenSecondary !== "null") {
      config.headers = { Authorization: "Bearer " + tokenSecondary };
      return config;
    }

    const loginSecondaryData = {
      idCompany: getMenuData()?.idCompany,
      idApp: getMenuData()?.idApp,
      idChannel: getMenuData()?.idChannel,
      idAppType: getMenuData()?.idAppType,
    };

    const tokenSecondaryRes = await loginSecondary(loginSecondaryData); // cria o token atráves do endpoint gettoken2
    localStorage.setItem("token-secondary", tokenSecondaryRes.data.token);
    config.headers = { Authorization: "Bearer " + tokenSecondaryRes.data.token };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export function getProfilesSecondary(values) {
  return NexiDigitalAPISecondary({
    method: "GET",
    url: "/permission/listProfile",
    data: values,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.listaPerfil;
      }
      return [];
    })
    .catch((err) => {
      console.log("listProfile error:", err);
      return [];
    });
}

export function addProfileApiSecondary(name) {
  const data = {
    name: name,
  };
  return NexiDigitalAPISecondary({
    method: "PUT",
    url: "/permission/insertprofile",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      console.log("insertprofile error:", err);
      return false;
    });
}

export function addProfilePermissionApiSecondary(idSection, idProfile) {
  return NexiDigitalAPISecondary({
    method: "PUT",
    url: "/permission/insertprofilepermission",
    data: {
      idSection,
      idProfile,
    },
  });
}

export function removeProfilePermissionApiSecondary(idSection, idProfile) {
  return NexiDigitalAPISecondary({
    method: "DELETE",
    url: "/permission/deleteprofilepermission",
    data: {
      idSection,
      idProfile,
    },
  });
}

export function getProfilePermissionsSecondary(idProfile) {
  return NexiDigitalAPISecondary({
    method: "GET",
    url: `/permission/getprofilepermissions/${idProfile}`,
  });
}

export function getUsersSecondary(filterObj = {}) {
  const queryParams = {
    offset: filterObj.offset,
    limit: filterObj.limit,
    searchText: filterObj.searchString,
    idGroups: filterObj.idGroups,
    excludeIds: filterObj.excludeIds
  };
  return NexiDigitalAPISecondary({
    method: "GET",
    url: "/users",
    params: queryParams,
  });
}

export function getUsersAdminSecondary(idProfile) {
  return NexiDigitalAPISecondary({
    method: "GET",
    url: `/users/admin`,
    params: { idProfile },
  });
}

export function getUserSecondary(id) {
  return NexiDigitalAPISecondary({
    method: "GET",
    url: `/users/${id}`,
  });
}

export function setUserSecondary(data) {
  return NexiDigitalAPISecondary({
    method: "POST",
    url: `/users`,
    data: data,
  });
}

export function editUserSecondary(id, data) {
  return NexiDigitalAPISecondary({
    method: "PUT",
    url: `/users/${id}`,
    data: data,
  });
}

export function forgotEmailSecondary(data) {
  return NexiDigitalAPISecondary({
    method: "POST",
    url: `/login/forgot`,
    data: data,
  });
}

export function sendEmailBoasVindasApiSecondary(email) {
  return NexiDigitalAPISecondary({
    method: "post",
    url: "/login/sendwelcomeemail",
    data: { email: email },
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function addUserIntoProfileSecondary(idProfile, idUser) {
  const data = {
    idUser: idUser,
    idProfile: idProfile,
  };
  return NexiDigitalAPISecondary({
    method: "PUT",
    url: "/permission/insertuserprofile",
    data: data,
  })
    .then((response) => {
      console.log("insertuserprofile", response);
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      console.log("Error insertuserprofile", err);
      return false;
    });
}

export function getProfileUsersSecondary(idProfile) {
  return NexiDigitalAPISecondary({
    method: "GET",
    url: "/permission/listuserprofile/" + idProfile,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.listUserProfile;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function deleteUserOfProfileSecondary(idProfile, idUser) {
  const data = {
    idUser: idUser,
    idProfile: idProfile,
  };
  return NexiDigitalAPISecondary({
    method: "DELETE",
    url: "/permission/deleteuserprofile",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}

export function getPermissionResourcesSecondary(values) {
  return NexiDigitalAPISecondary({
    method: "GET",
    url: "/permission/listResource",
    data: values,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.listaRecursos;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getProfileResourcesApiSecondary(id) {
  const data = {
    idProfile: id,
  };
  return NexiDigitalAPISecondary({
    method: "POST",
    url: "/permission/listResourceProfile",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function addProfileResourcesApiSecondary(idProfile, idResource) {
  const data = {
    idProfile: idProfile,
    idResource: idResource,
  };
  return NexiDigitalAPISecondary({
    method: "PUT",
    url: "/permission/insertresourceprofile",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function removeProfileResourcesApiSecondary(idProfile, idResource) {
  const data = {
    idProfile: idProfile,
    idResource: idResource,
  };
  return NexiDigitalAPISecondary({
    method: "DELETE",
    url: "/permission/deleteresourceprofile",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function deleteProfileSecondary(idProfile) {
  return NexiDigitalAPISecondary({
    method: "DELETE",
    url: "/permission/deleteprofile/" + idProfile,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function updateProfileApiSecondary(idProfile, name) {
  const data = {
    name: name,
  };
  return NexiDigitalAPISecondary({
    method: "POST",
    url: "/permission/updateprofile/" + idProfile,
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function getGroupsSecondary() {
  return NexiDigitalAPISecondary({
    method: "GET",
    url: `/group/list`,
  });
}

export function createGroupSecondary(group) {
  return NexiDigitalAPISecondary({
    method: "POST",
    url: `/group/addgroup/`,
    data: {
      title: `${group}`,
    },
  });
}

export function updateGroupSecondary(id, group) {
  return NexiDigitalAPISecondary({
    method: "PUT",
    url: `/group/${id}`,
    data: {
      title: `${group}`,
    },
  });
}

export function removeGroupSecondary(id) {
  return NexiDigitalAPISecondary({
    method: "DELETE",
    url: `/group/${id}`,
  });
}

export function uploadCsvApiSecondary(data) {
  const token = localStorage.getItem("token-secondary");
  data.append("token", token);
  return axios.post(`${process.env.REACT_APP_UPLOAD_USERIMPORT}`, data, {
    headers: {
      "Content-Type": `multipart/form-data`,
    },
  });
}

export function getImportId() {
  return NexiDigitalAPISecondary({
    method: "POST",
    url: `/imports`,
  });
}
