import React, { FC } from "react";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import "./style.scss"
type Props  = {
  isExpanded: boolean
  image: string
  name: string
  role: string
}


const RowInfo: FC<Props> = ({isExpanded, image, name, role}) => (
  <div className={`row-info`}>
    {isExpanded ? <CiSquareMinus /> : <CiSquarePlus />}
    <img alt="manager-profile" className="manager-profile" src={image} />
    <div className="title-team">
      <p className="name">{name}</p>
      <p className="role"> {role ? (role).toUpperCase() : ''}</p>
    </div>
  </div>
)

export default RowInfo
