import React, { useCallback, useContext, useEffect, useState } from "react";
import {  Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Content from "./components/content";
import Header from "./components/header";
import NotificationTeam from "./components/Notification";
import "./styles.scss";
import PendingRegistrationRequest, { AdminLevel, Status } from "components/PeopleCraft/PendingRegistrationRequest";
import { getExternalCourses, getFeaturesProfile, getLearningEnrollmentRequest, getTotalImpactEvulations } from "services/NexiDigitalAPI";
import { AuthContext } from "context/AuthContext";
import PreloaderBlur from "../PreloaderBlur";

const MyTeam = ({idSection}) => {
  const [requestRegistrationModal, setRequestRegistrationModal] = useState<boolean>(false);
  const [refreshCount, setRefreshCount] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [notificationRequest, setNotificationRequest] = useState<number>(0);
  const [requestEvaluationImpact, setRequestEvaluationImpact] = useState<boolean>(false);
  const [externalCoursesRequest, setExternalCoursesRequest] = useState<number>(0);
  const { loginData }: {loginData: any} = useContext(AuthContext);
  const history = useHistory();

  const getCountNotification = useCallback(async (refresh: boolean) => {
    setLoading(true)
    try {
      const requests =   [getLearningEnrollmentRequest({limit: 0, status: Status.PENDING })  ]
      if(!refresh){
        requests.push(getTotalImpactEvulations(loginData.userid))
        requests.push(getExternalCourses({ idManager: loginData.userid, limit: 0, statuses: Status.PENDING }))
      }
      const result =  await Promise.all(requests);

      if(result[0]){
        setNotificationRequest(result[0]?.data?.totalRecords)
      }

      if(result[1]){
        setRequestEvaluationImpact(result[1]?.data.totalRecords)
      }

      if (result[2]) {
        const { data } = await getFeaturesProfile('EXTERNAL_COURSES', 'MANAGER');
        if (data?.active) {
          setExternalCoursesRequest(result[2]?.data.totalRecords)
        }
      }
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }, [ loginData.userid]);

  useEffect(() => {

    getCountNotification(false);

  }, [getCountNotification]);

  useEffect(() => {
    if(refreshCount){
      getCountNotification(true);
      setRefreshCount(false)
    }

  }, [refreshCount, getCountNotification]);


  return (
    <>
      {loading && <PreloaderBlur />}
      <div className="MyTeam">
        <Header title={'Minha Equipe'}/>
        {!!requestEvaluationImpact
          && <NotificationTeam
          message={`Você possui ${requestEvaluationImpact} questionários de Impacto para avaliar os seus diretos.`}
          click={() =>  history.push(`/minha-equipe/avaliacao-impacto`)}
        />}
        {!!notificationRequest
          && <NotificationTeam
          message={`Há ${notificationRequest} solicitações de matrícula pendente dos seus diretos.`}
          click={() =>  setRequestRegistrationModal(true)}
        />}
        {!!externalCoursesRequest
          && <NotificationTeam
          message={`Há ${externalCoursesRequest} solicitações de curso externo pendente.`}
          click={() =>  history.push(`/minha-equipe/cursos-externos`)}
        />}
        

        <div className="MyTeam-container">
          <Content />
        </div>
      </div>

      <Modal
        show={requestRegistrationModal}
        className="modalRequestRegistrationModal"
        onHide={() => {
          setRequestRegistrationModal(false);

        }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <PendingRegistrationRequest setRefreshCount={setRefreshCount} admLevel={AdminLevel.MANAGER} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MyTeam;
