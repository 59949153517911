import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import sanitizeHtml from "sanitize-html";
import "./style.scss";
import { getInnerText } from "utils/Utils";
import TextEditor from "components/TextEditor";

const MAX_CHAR_COUNT = 1000;

const addTargetBlank = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  const links = tempDiv.getElementsByTagName("a");
  for (let i = 0; i < links.length; i++) {
    links[i].setAttribute("target", "_blank");
    links[i].setAttribute("rel", "noopener noreferrer");
  }
  return tempDiv.innerHTML;
};

const EditMessageBox = ({
  isReply = false,
  editContent,
  setEditContent,
  handleEditToggle,
  handleEditSubmit,
}) => {
  const editorRef = useRef(null);
  const [content, setContent] = useState(editContent);

  const handleSave = () => {
    const htmlContent = editorRef.current.getContent();

    const cleanHtmlContent = sanitizeHtml(htmlContent, {
      allowedTags: ["b", "i", "u", "a", "p", "br"],
      allowedAttributes: {
        a: ["href"],
      },
      allowedSchemes: ["http", "https", "mailto"],
    });

    const finalHtmlContent = addTargetBlank(cleanHtmlContent);

    const truncatedContent = finalHtmlContent.substring(0, MAX_CHAR_COUNT);
    if (Boolean(getInnerText(truncatedContent).trim().length)) {
      handleEditSubmit(truncatedContent); 
    }
  };

  return (
    <div className="edit-message-box">
      <TextEditor
        initialContent={editContent}
        ref={editorRef}
        placeholder={`Digite sua ${isReply ? "resposta" : "mensagem"}...`}
        maxLength={MAX_CHAR_COUNT}
        onChange={(newContent) => setContent(getInnerText(newContent))}
      />
      <div className="edit-message-actions">
        <div>
          <button
            className="edit-message-button edit-message-cancel-button"
            onClick={handleEditToggle}
          >
            Cancelar
          </button>
          <button
            className="edit-message-button edit-message-submit-button"
            onClick={handleSave}
            disabled={!Boolean(content.trim()?.length || 0)}
          >
            Editar
          </button>
        </div>
      </div>
    </div>
  );
};

EditMessageBox.propTypes = {
  editContent: PropTypes.string.isRequired,
  setEditContent: PropTypes.func.isRequired,
  handleEditToggle: PropTypes.func.isRequired,
  handleEditSubmit: PropTypes.func.isRequired,
};

export default EditMessageBox;
