import React, { useRef, useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import { uploadImageApi } from "../../services/NexiDigitalAPI";

const CmsVideoUpload = ({
  contentLocation,
  setContentLocation,
  setVideoDuration,
  idContentItem,
  videoOriginal,
}) => {
  const [carregaVideo, setCarregaVideo] = useState(false);
  const [videoUploadStatus, setVideoUploadStatus] = useState(false);
  const [mensagemVideoUpload, setMensagemVideoUpload] = useState("");

  const videoRef = useRef();

  const updateVideoDuration = () => {
    const hasVideo = Boolean(videoRef.current);

    if (hasVideo) {
      const duration = Boolean(videoRef.current.duration) ? videoRef.current.duration : 0;
      setVideoDuration(duration);
      return;
    }

    if (contentLocation === "") {
      setVideoDuration(0);
      return;
    }

    return setTimeout(updateVideoDuration, 500);
  };

  const configUpdateVideoDuration = () => {
    const hasVideo = Boolean(videoRef.current);
    //https://homolog.blast.etc.br/common/app0000001/content/video/video58.mp4

    if (hasVideo) {
      const hasDuration = Boolean(videoRef.current.duration);
      if (hasDuration) {
        updateVideoDuration();
      } else {
        videoRef.current.addEventListener("loadedmetadata", updateVideoDuration);
      }
    } else {
      setTimeout(configUpdateVideoDuration, 500);
    }
  };
  useEffect(() => {
    configUpdateVideoDuration();
  }, [contentLocation]);

  const uploadVideo = (arquivo) => {
    setCarregaVideo(true);
    let selectedVideoFile = arquivo.files[0];
    const filename = "video" + idContentItem + "-" + Date.now();

    const data = new FormData(); // If file selected
    if (selectedVideoFile) {
      data.append("fileName", filename);
      data.append("fileData", selectedVideoFile);
      // data.append("profileImage", newVideofile, newVideofile.name);
      uploadImageApi(data)
        .then((response) => {
          if (response.data.error) {
            setContentLocation(videoOriginal);
            setCarregaVideo(false);
            setVideoUploadStatus(false);
            setMensagemVideoUpload("Erro ao fazer upload do arquivo");
          } else {
            setContentLocation(response.data.location);
            setCarregaVideo(false);
            setVideoUploadStatus(true);
            setMensagemVideoUpload("Arquivo atualizado com successo");
          }
        })
        .catch((error) => {
          // If another error
          console.log("error", error);
          setContentLocation(videoOriginal);
          setCarregaVideo(false);
          setVideoUploadStatus(false);
          setMensagemVideoUpload("Erro ao fazer upload do arquivo");
        });
    } else {
      setContentLocation(videoOriginal);
      setCarregaVideo(false);
    }
  };

  return (
    <div className="form-group">
      <div className="card">
        <div className="card-header template-card-header">Arquivo</div>
        <div className="card-body template-card-body" style={{ minHeight: "260px" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="urVideodd">
                  url
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                value={contentLocation}
                onChange={(e) => {
                  var Video = e.target.value;
                  setCarregaVideo(true);
                  window.setTimeout(function () {
                    setContentLocation(Video);
                    setCarregaVideo(false);
                    setVideoUploadStatus(true);
                    setMensagemVideoUpload("Arquivo atualizado com successo");
                  }, 1);
                }}
              />
            </div>
            <input
              type="file"
              style={{ width: "auto" }}
              id="video"
              onChange={(e) => uploadVideo(e.target)}
            />
            {carregaVideo ? (
              <div>
                <CircularProgress />
              </div>
            ) : (
              <div>
                <br />
                {videoUploadStatus ? (
                  <span style={{ color: "green" }}>{mensagemVideoUpload}</span>
                ) : (
                  <span style={{ color: "red" }}>{mensagemVideoUpload}</span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CmsVideoUpload;
