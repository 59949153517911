import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import "./style.modules.scss";
import { TreeItem, TreeView } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  disableLearningTraining,
  enableLearningTraining,
  getLearningTrainingsTree,
  getLearningTrainingsTreeChild,
} from "../../../services/NexiDigitalAPI";
import Switch from "react-bootstrap/esm/Switch";
import Loading from "../../../components/Loading";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: "#f2f1fc",
    padding: 15,
  },
});

const RenderTree = ({ nodes, handleSwitch, parentEnabled = true }) => {
  return nodes?.map((node, index) => {
    const isParentEnabled = Boolean(parentEnabled);
    return (
      <TreeItem
        className="training-management-tree-item"
        key={index}
        nodeId={`${node.nodeCode}`}
        label={
          <>
            <div className="training-management-tree-switch">
              {node.nodeCode !== "0" && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Switch
                    color="primary"
                    id={String(node.nodeCode)}
                    checked={Boolean(node.enabled && isParentEnabled)}
                    onChange={(e) => {
                      handleSwitch(e.target.checked, e.target.id, node.idProduct);
                    }}
                    disabled={!isParentEnabled}
                  />
                </div>
              )}
              <label
                className="form-label training-management-tree-switch-text"
                htmlFor={String(node.nodeCode)}
                style={{ marginBottom: 0 }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                {node.title}
              </label>
            </div>
          </>
        }
      >
        {Array.isArray(node.childNodes) && node.childNodes.length > 0 ? (
          <RenderTree
            nodes={node.childNodes}
            handleSwitch={handleSwitch}
            parentEnabled={node.enabled && isParentEnabled}
          />
        ) : null}
      </TreeItem>
    );
  });
};

const PlusIcon = () => <AddIcon className="tree-icon" id="expand-id" />;

const MinusIcon = () => <RemoveIcon className="tree-icon" id="minimize-id" />;

const TrainingExhibition = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [nodesData, setNodesData] = useState([]);
  const [loadingNodes, setLoadingNodes] = useState(true);
  const history = useHistory();

  const handleToggle = (event, nodeIds) => {
    const isCollapsing = event.currentTarget.querySelector("#expand-id") === null;

    const clickedNodeCode = nodeIds[0];
    setExpanded(nodeIds);

    // check if if any of the child node has been expanded
    const isChildExpanded = nodeIds?.some((item) => item.includes(clickedNodeCode + "."));

    // dont call endpoint again when collpsing
    if (!isCollapsing && !isChildExpanded) {
      getChildNodes(clickedNodeCode);
    }
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const setChildPlaceholder = (data) => {
    data.forEach((obj) => {
      if (obj.hasChildNodes === 1) {
        obj.childNodes.push({
          idProduct: 0,
          idTargetCompany: 0,
          nodeCode: "0",
          title: "Carregando...",
          enabled: 0,
          hasChildNodes: 0,
          childNodes: [],
        });
      }
    });

    return data || [];
  };

  function updateChildNodes(array, nodeCode, newChildNodes) {
    for (let i = 0; i < array.length; i++) {
      const obj = array[i];
      if (obj.nodeCode === nodeCode) {
        obj.childNodes = newChildNodes;
        return true; // Return true to indicate that the object was found and updated
      }
      if (obj.hasChildNodes && obj.childNodes.length > 0) {
        const childUpdated = updateChildNodes(obj.childNodes, nodeCode, newChildNodes);
        if (childUpdated) {
          return true; // Return true if the child object was found and updated
        }
      }
    }
    return false; // Return false if the object was not found
  }

  function updateSwitchValue(array, nodeCode, newValue) {
    for (let i = 0; i < array.length; i++) {
      const obj = array[i];
      if (obj.nodeCode === nodeCode) {
        obj.enabled = newValue;
        return true; // Return true to indicate that the object was found and updated
      }
      if (obj.hasChildNodes && obj.childNodes.length > 0) {
        const childUpdated = updateSwitchValue(obj.childNodes, nodeCode, newValue);
        if (childUpdated) {
          return true; // Return true if the child object was found and updated
        }
      }
    }
    return false; // Return false if the object was not found
  }

  const getChildNodes = (nodeCode) => {
    getLearningTrainingsTreeChild(nodeCode)
      .then((res) => {
        let newNodesData = [...nodesData];
        const childData = setChildPlaceholder(res.data[0].childNodes);
        const result = updateChildNodes(newNodesData, nodeCode, childData);
        setNodesData(result ? newNodesData : nodesData);
      })
      .catch((err) => {
        console.log("error getting child trees", err);
      })
      .finally(() => {
        if (loadingNodes === true) {
          setLoadingNodes(false);
        }
      });
  };

  const _getLearningNodesTree = () => {
    setLoadingNodes(true);
    getLearningTrainingsTree()
      .then((res) => {
        let trainingData = setChildPlaceholder(res.data);
        setNodesData(trainingData);
      })
      .catch((err) => {
        console.log("error getting trees", err);
      })
      .finally(() => {
        setLoadingNodes(false);
      });
  };

  const handleSwitch = (checked, nodeCode, idProduct) => {
    const switchData = {
      idProduct: idProduct,
      nodeTarget: nodeCode,
    };
    let newNodesData = [...nodesData];
    const result = updateSwitchValue(newNodesData, nodeCode, checked);
    setNodesData(result ? newNodesData : nodesData);
    if (checked) {
      enableLearningTraining(switchData).catch((err) => console.log("enable err", err));
    } else {
      disableLearningTraining(switchData).catch((err) => console.log("disable err", err));
    }
  };

  useEffect(() => {
    _getLearningNodesTree();
  }, []);

  return (
    <Container className="training-management">
      <div className="header-title-wrapper">
        <div className="header-title">
          <button
            className="btnGoBack"
            onClick={() => {
              history.goBack();
            }}
          >
            <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
          </button>
          Exibição de Treinamentos
        </div>
      </div>
      <div className="training-management-container">
        {loadingNodes ? (
          <Loading containerStyle={{ height: 300 }}>Carregando lista de treinamentos...</Loading>
        ) : nodesData.length === 0 ? (
          <div style={{ textAlign: "center", color: "gray" }}>Nenhum treinamento disponível</div>
        ) : (
          <TreeView
            className={classes.root}
            defaultExpandIcon={<PlusIcon />}
            defaultCollapseIcon={<MinusIcon />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
          >
            {<RenderTree handleSwitch={handleSwitch} nodes={nodesData} />}
          </TreeView>
        )}
      </div>
    </Container>
  );
};

export default TrainingExhibition;
