import React, { useContext, useEffect, useRef, useState } from "react";
import TitleIcon from "@material-ui/icons/Title";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import { BsFillGridFill } from "react-icons/bs";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import "./index.scss";
import { nodeTypes } from "./NodeTypes";
import { MdPanorama } from "react-icons/md";
import { AiFillCalendar } from "react-icons/ai";
import { ReactComponent as ProgressIcon } from "../../../assets/images/bars-progress-solid.svg";
import { statusTypes } from "../ContentPage/StatusTypes";
import { LMCreateContext } from "../LearningManagementNew/Contexts/LMContext";
import CustomTooltip from "components/CustomTooltip";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CloseIcon from "assets/images/close-icon-menu.svg";
import { BsFillCollectionFill } from "react-icons/bs";
import { getFeatures, getMenuData } from "services/NexiDigitalAPI";

const BlockOptions = ({ permissionLearningPage,newBlock, loadingAddBlock }) => {
  const { nodeData } = useContext(LMCreateContext);
  const { idCompany } = getMenuData()
  const blockEditRedirect = !permissionLearningPage || ( !!nodeData?.redirect && (!nodeData.idCompany || nodeData.idCompany !== idCompany));

  const [showExpandedOptions, setShowExpandedOptions] = useState(false);
  const [maxComponents, setMaxComponents] = useState(10);
  const [parentWidth, setParentWidth] = useState(600);
  const [remainingOptions, setRemainingOptions] = useState([]);
  const [showRecommendation, setShowRecommendation] = useState(false);
  const nodeType = nodeData?.nodeType;
  const blockOptionStyle = {
    fontSize: 25,
  };

  const moreOption = {
    title: "Outros",
    subTitle: "Outros",
    type: nodeTypes.SHOW_OTHERS,
    icon: <MoreHorizIcon style={blockOptionStyle} />,
  };

  const allOptions = [
    {
      title: "Título",
      subTitle: "Título",
      type: nodeTypes.TITLE,
      icon: <TitleIcon style={blockOptionStyle} />,
    },
    {
      title: "Texto",
      subTitle: "Texto/Descrição",
      type: nodeTypes.TEXT,
      icon: <TextFormatIcon style={blockOptionStyle} />,
    },
    {
      title: "Lista",
      subTitle: "Lista de conteúdo",
      type: nodeTypes.LIST,
      icon: <ViewListIcon style={blockOptionStyle} />,
    },
    {
      title: "Carrossel",
      subTitle: "Seu conteúdo será visualizado como carrossel",
      type: nodeTypes.CAROUSEL,
      icon: <ViewCarouselIcon style={blockOptionStyle} />,
    },
    {
      title: "Master Banner",
      subTitle: "Master Banner",
      type: nodeTypes.MASTER_BANNER,
      icon: <MdPanorama style={blockOptionStyle} />,
    },
    {
      title: "Catálogo",
      subTitle: "Catálogo",
      type: nodeTypes.CATALOG,
      icon: <ViewWeekIcon style={blockOptionStyle} />,
    },
    {
      title: "Grade",
      subTitle: "Seu conteúdo será visualizado como grade",
      type: nodeTypes.GRID,
      icon: <BsFillGridFill style={{ ...blockOptionStyle, padding: 2 }} />,
    },
    {
      title: "Agenda",
      subTitle: "Agenda",
      type: nodeTypes.CALENDAR,
      icon: <AiFillCalendar style={{ ...blockOptionStyle, padding: 2 }} />,
    },
    {
      title: "Em Progresso",
      subTitle: "Em Progresso",
      type: nodeTypes.IN_PROGRESS,
      icon: (
        <div style={{ width: 25, padding: "0px 2px" }}>
          <ProgressIcon style={blockOptionStyle} />
        </div>
      ),
    },
    {
      title: "Recomendação",
      subTitle: "Recomendação",
      type: nodeTypes.RECOMMENDATION,
      icon: <BsFillCollectionFill style={{ ...blockOptionStyle, padding: 2 }} />,
      disabled: !showRecommendation,
    },
    moreOption,
    // {
    //     title: "Avaliações",
    //     subTitle: "Avaliações",
    //     type: nodeTypes.ASSESSMENT,
    //     icon: <AssignmentIcon style={blockOptionStyle} />
    // },
    // {
    //     title: "Enquetes",
    //     subTitle: "Enquetes",
    //     type: nodeTypes.SURVEY,
    //     icon: <RiSurveyLine style={blockOptionStyle} />
    // },
  ];

  const getRecommendationPermission = () => {
    getFeatures("RECOMMENDATION_BLOCK")
      .then((res) => {
        const active = res.data?.active;
        if (active) {
          setShowRecommendation(true);
        } else {
          setShowRecommendation(false);
        }
      })
      .catch((err) => {
        setShowRecommendation(false);
        console.log("ERR", err);
      });
  };

  const nodeTypeOrganization = [
    nodeTypes.TITLE,
    nodeTypes.TEXT,
    nodeTypes.LIST,
    nodeTypes.CAROUSEL,
    nodeTypes.MASTER_BANNER,
    nodeTypes.CATALOG,
    nodeTypes.GRID,
    nodeTypes.CALENDAR,
    nodeTypes.IN_PROGRESS,
    nodeTypes.RECOMMENDATION,
  ];

  const nodeTypeLearning = [
    nodeTypes.TITLE,
    nodeTypes.TEXT,
    nodeTypes.LIST,
    nodeTypes.CAROUSEL,
    nodeTypes.MASTER_BANNER,
    nodeTypes.GRID,
    nodeTypes.CATALOG,
  ];

  const filterOptions = (mustContain) => {
    let newOptions = mustContain.map((item) => {
      const findOption = allOptions.find((option) => option.type === item);
      return findOption;
    });

    if (maxComponents < newOptions.length) {
      const othersComponentsLength = newOptions.length - maxComponents;
      const newRemainingOptions = newOptions.slice(newOptions.length - othersComponentsLength);

      newOptions = newOptions.slice(0, -othersComponentsLength);
      newOptions.push(moreOption);

      if (newRemainingOptions.length !== remainingOptions.length) {
        setRemainingOptions(newRemainingOptions);
      }
    }

    return [...newOptions];
  };

  const blockOptions = () => {
    if (nodeType === "ORGANIZATION") {
      return filterOptions(nodeTypeOrganization);
    }
    if (nodeType === "LEARNING") {
      return filterOptions(nodeTypeLearning);
    }

    let newAllOptions = [...allOptions];
    if (maxComponents < newAllOptions.length) {
      const othersComponentsLength = newAllOptions.length - maxComponents;
      const newRemainingOptions = newAllOptions.slice(
        newAllOptions.length - othersComponentsLength,
      );

      newAllOptions = newAllOptions.slice(0, -othersComponentsLength);
      newAllOptions.push(moreOption);

      if (newRemainingOptions.length !== remainingOptions.length) {
        setRemainingOptions(newRemainingOptions);
      }
    }
    return newAllOptions;
  };

  const expandBlockOptions = () => {
    setShowExpandedOptions(!showExpandedOptions);
  };

  const handleNewBlockFromOthers = (blockItem) => {
    newBlock({
      title: blockItem.subTitle,
      blockType: blockItem.type,
    });
    setShowExpandedOptions(false);
  };

  useEffect(() => {
    const maxComponentsLength = Math.floor((parentWidth + 50) / 45);
    setMaxComponents(maxComponentsLength);
  }, [parentWidth]);

  const targetRef = useRef(null);
  const handleResize = (entries) => {
    const newWidth = entries[0].contentRect.width;

    if (newWidth) {
      setParentWidth(newWidth);
    }
  };

  useEffect(() => {
    getRecommendationPermission();
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);

    // Start observing the target element
    if (targetRef.current) {
      resizeObserver.observe(targetRef.current);
    }

    // Clean up the observer when the component unmounts
    return () => {
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="node-options-container-v2" ref={targetRef}>
        {/* <div className="node-options-container-support" /> */}
        {blockOptions()
          ?.filter((block) => !block.disabled)
          .map((blockItem, index) => (
            <CustomTooltip
              title={blockItem.title}
              placement="bottom"
              arrow
              key={`node-options-${index}`}
              className="node-options-item-v2"
              style={{
                pointerEvents:
                blockEditRedirect ||
                  ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                    nodeData?.nodeType !== "ORGANIZATION") ||
                  loadingAddBlock
                    ? "none"
                    : "auto",
                backgroundColor:
                  blockItem.type === nodeTypes.SHOW_OTHERS && showExpandedOptions
                    ? "#6b48ff"
                    : undefined,
                color:
                  blockItem.type === nodeTypes.SHOW_OTHERS && showExpandedOptions
                    ? "#eea125"
                    : undefined,
              }}
              onClick={() =>
                blockEditRedirect || ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                nodeData?.nodeType !== "ORGANIZATION")
                  ? null
                  : blockItem.type === nodeTypes.SHOW_OTHERS
                  ? expandBlockOptions()
                  : newBlock({
                      title: blockItem.subTitle,
                      blockType: blockItem.type,
                    })
              }
            >
              <div>{blockItem.icon}</div>
            </CustomTooltip>
          ))}
        {showExpandedOptions && (
          <div className="node-options-container-expanded">
            <div onClick={() => expandBlockOptions()} className="node-options-item-close-container">
              <img src={CloseIcon} className="node-options-close" alt="img" />
            </div>
            <span>Outros Componentes</span>
            <hr style={{ margin: "5px 0px", background: "#bcbcbc" }} />
            <div className="node-options-others-container">
              {remainingOptions.map((blockItem, index) => (
                <div
                  title={blockItem.title}
                  placement="bottom"
                  arrow
                  key={`node-options-${index}`}
                  className="node-options-item-others"
                  style={{
                    pointerEvents:
                      ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                        nodeData?.nodeType !== "ORGANIZATION") ||
                      loadingAddBlock
                        ? "none"
                        : "auto",
                    backgroundColor:
                      blockItem.type === nodeTypes.SHOW_OTHERS && showExpandedOptions
                        ? "#6b48ff"
                        : undefined,
                    color:
                      blockItem.type === nodeTypes.SHOW_OTHERS && showExpandedOptions
                        ? "#eea125"
                        : undefined,
                  }}
                  onClick={() =>
                    [statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                    nodeData?.nodeType !== "ORGANIZATION"
                      ? null
                      : blockItem.type === nodeTypes.SHOW_OTHERS
                      ? expandBlockOptions()
                      : handleNewBlockFromOthers(blockItem)
                  }
                >
                  <div>{blockItem.icon}</div>
                  <div className="node-options-item-others-text">{blockItem.title}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BlockOptions;
