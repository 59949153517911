import "./../Cockpit.css";
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import ImageIcon from "@material-ui/icons/Image";
import ColorPicker from "material-ui-color-picker";
import { RiSave3Line } from "react-icons/ri";
import { uploadImageApi, getAppColors, updateAppColors } from "../../services/NexiDigitalAPI";
import { withStyles } from "@material-ui/core/styles";
import { colors } from "../../utils/Colors";
import SnackComponent from "../../components/SnackComponent";
import CircularProgress from "@material-ui/core/CircularProgress";

function AppColorManager() {
  const [appDetails, setAppDetails] = useState({});
  const [snackInfo, setSnackInfo] = useState({ message: "", severity: "success" });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [loadingAppDetails, setLoadingAppDetails] = useState(false);
  const [loadingImageUpload, setLoadingImageUpload] = useState({});

  const saveAppDetails = () => {
    if (loadingAppDetails) {
      return;
    } // prevent multiple requests when updating app details
    setLoadingAppDetails(true);
    const newAppDetails = {
      primaryColor: appDetails.primaryColor,
      secondaryColor: appDetails.secondaryColor,
      thirdColor: appDetails.thirdColor,
      fourthColor: appDetails.fourthColor,
      headerIcon: appDetails.headerIcon,
    };

    updateAppColors(newAppDetails)
      .then((res) => {
        if (res === true) {
          let appDetailsTemp = JSON.parse(localStorage.getItem("appDetails"));

          appDetailsTemp.primaryColor = newAppDetails.primaryColor;
          appDetailsTemp.secondaryColor = newAppDetails.secondaryColor;
          appDetailsTemp.thirdColor = newAppDetails.thirdColor;
          appDetailsTemp.fourthColor = newAppDetails.fourthColor;
          appDetailsTemp.headerIcon = newAppDetails.headerIcon;

          localStorage.setItem("appDetails", JSON.stringify(appDetailsTemp));
          
          setSnackInfo({
            message: "Salvo com sucesso!",
            severity: "success",
          });
          setOpenSnackBar(true);
        } else {
          setOnSaveError();
        }
      })
      .catch((err) => {
        setOnSaveError();
      })
      .finally(() => {
        setLoadingAppDetails(false);
      });
  };

  const setOnSaveError = () => {
    setSnackInfo({
      message: "Erro ao salvar dados.",
      severity: "error",
    });
    setOpenSnackBar(true);
  };

  const getLoginPreview = (position) => {
    switch (position) {
      case "top":
        return "flex-start";
      case "middle":
        return "center";
      case "bottom":
        return "flex-end";
      default:
        return "center";
    }
  };

  const GreenButton = withStyles((theme) => ({
    root: {
      color: "white",
      backgroundColor: "#29CC61",
      "&:hover": {
        backgroundColor: "#29CC57",
      },
    },
  }))(Button);

  const uploadFile = (file, detailItem) => {
    const filename = `${appDetails.name}-${detailItem}-${Date.now()}`;
    let selectedFile = file.files[0];

    const data = new FormData(); // If file selected
    if (selectedFile) {
      setLoadingImageUpload({ ...loadingImageUpload, [detailItem]: true });
      data.append("fileName", filename);
      data.append("fileData", selectedFile);

      uploadImageApi(data)
        .then((response) => {
          if (response.data.error) {
            setSnackInfo({
              message: "Erro ao subir imagem.",
              severity: "error",
            });
            setOpenSnackBar(true);
          } else {
            setAppDetails({ ...appDetails, [detailItem]: response.data.link });
          }
        })
        .catch((error) => {
          setSnackInfo({
            message: "Erro ao subir imagem.",
            severity: "error",
          });
          setOpenSnackBar(true);
        })
        .finally(() => {
          setLoadingImageUpload({ ...loadingImageUpload, [detailItem]: false });
        });
    }
  };

  async function getColors() {
    const colors = await getAppColors();
    let companyDetails = colors[0];
    const appDetailsTemp = JSON.parse(localStorage.getItem("appDetails"));

    companyDetails.primaryColor = companyDetails.primaryColor ? companyDetails.primaryColor : appDetailsTemp.primaryColor;
    companyDetails.secondaryColor = companyDetails.secondaryColor ? companyDetails.secondaryColor : appDetailsTemp.secondaryColor;
    companyDetails.thirdColor = companyDetails.thirdColor ? companyDetails.thirdColor : appDetailsTemp.thirdColor;
    companyDetails.fourthColor = companyDetails.fourthColor ? companyDetails.fourthColor : appDetailsTemp.fourthColor;
    companyDetails.headerIcon = companyDetails.headerIcon ? companyDetails.headerIcon : appDetailsTemp.headerIcon;

    setAppDetails(companyDetails);
  }

  useEffect(() => {
    getColors();
    // 
  }, []);

  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <SnackComponent
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        message={snackInfo.message}
        severity={snackInfo.severity}
      />
      <div className="header-title-wrapper">
        <div className="header-title">Configuração de cores do app</div>
      </div>
      <div className="content-manager-container">
        <div className="content-manager-sub-item-settings">
          <div className="content-manager-sub-item-list">
            <div className="content-manager-sub-item-container form-color-picker-container">
              <div className="content-manager-sub-item-container-big">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="content-managet-color-picker"
                    style={{ backgroundColor: appDetails.primaryColor }}
                  />
                  Primeira cor:
                  <ColorPicker
                    style={{ marginLeft: 10 }}
                    name="color"
                    value={appDetails.primaryColor}
                    TextFieldProps={{ value: appDetails.primaryColor }}
                    onChange={(color) => setAppDetails({ ...appDetails, primaryColor: color })}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="content-managet-color-picker"
                    style={{ backgroundColor: appDetails.secondaryColor }}
                  />
                  Segunda cor:
                  <ColorPicker
                    style={{ marginLeft: 10 }}
                    name="color"
                    value={appDetails.secondaryColor}
                    TextFieldProps={{ value: appDetails.secondaryColor }}
                    onChange={(color) => setAppDetails({ ...appDetails, secondaryColor: color })}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="content-managet-color-picker"
                    style={{ backgroundColor: appDetails.thirdColor }}
                  />
                  Terceira cor:
                  <ColorPicker
                    style={{ marginLeft: 10 }}
                    name="color"
                    value={appDetails.thirdColor}
                    TextFieldProps={{ value: appDetails.thirdColor }}
                    onChange={(color) => setAppDetails({ ...appDetails, thirdColor: color })}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="content-managet-color-picker"
                    style={{ backgroundColor: appDetails.fourthColor }}
                  />
                  Quarta cor:
                  <ColorPicker
                    style={{ marginLeft: 10 }}
                    name="color"
                    value={appDetails.fourthColor}
                    TextFieldProps={{ value: appDetails.fourthColor }}
                    onChange={(color) => setAppDetails({ ...appDetails, fourthColor: color })}
                  />
                </div>
              </div>
              <div className="content-manager-sub-item-container content-manager-image-picker">
                <div>
                  <div>Logo do Header</div>
                  <Button variant="contained" color="primary" component="label">
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      hidden
                      onChange={(e) => uploadFile(e.target, "headerIcon")}
                    />
                    <ImageIcon style={{ marginRight: 10 }} />
                    Carregar imagem
                  </Button>
                </div>
                <div className="content-manager-image-picker-container">
                  {loadingImageUpload.headerIcon ? (
                    <div>
                      <CircularProgress size={30} />
                    </div>
                  ) : (
                    <img style={{ height: 50 }} src={appDetails.headerIcon} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="content-manager-preview">
            <div style={{ fontSize: 18, textAlign: "center" }}>Preview</div>
            <div className="form-group" style={{ width: "250px" }}>
              <div className="form-row">
                <div
                  className="col-11"
                  style={{
                    border: "1px solid white",
                    width: "240px",
                    height: "20px",
                    backgroundColor: appDetails.thirdColor,
                  }}
                ></div>
                <div
                  className="col-3"
                  style={{
                    textAlign: "center",
                    paddingTop: "4px",
                    border: "1px solid white",
                    width: "40px",
                    height: "300px",
                    backgroundColor: appDetails.primaryColor,
                  }}
                >
                  <img style={{ height: 30 }} src={appDetails.headerIcon} />
                </div>
                <div
                  className="col-8"
                  style={{
                    border: "1px solid white",
                    width: "200px",
                    height: "300px",
                    backgroundColor: appDetails.secondaryColor,
                  }}
                ></div>
                <div
                  className="col-11"
                  style={{
                    border: "1px solid white",
                    width: "240px",
                    height: "20px",
                    backgroundColor: appDetails.fourthColor,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-manager-footer" style={{ backgroundColor: colors.blueLight }}>
          <GreenButton variant="contained" color="primary" onClick={saveAppDetails}>
            <div>
              <RiSave3Line size={22} style={{ marginBottom: 3, marginRight: 10 }} />
              {loadingAppDetails
                ? "Salvando detalhes da aplicação..."
                : "Salvar detalhes da aplicação"}
            </div>
          </GreenButton>
        </div>
      </div>
    </div>
  );
}

export default AppColorManager;
