import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box, Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import "./styles.scss";
import Label from "../Label";
import CustomTooltip from "components/CustomTooltip";
import { BiSolidInfoCircle } from "react-icons/bi";
type Option = {
  label: string | number;
  value: number | string;
  [key: string]: unknown;
};
type Props = {
  options: Option[];
  loading: boolean;
  multiple?: boolean;
  label: string;
  disabled?: boolean;
  value: number | string | any;
  placeholder: string;
  size: "small" | "medium";
  tooltip: string;
  disableCloseOnSelect: boolean,
  useCheckboxRender: boolean,
  error: boolean,
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: Option) => React.ChangeEvent;
};
export default function AutoComplete({
  options,
  loading,
  multiple = true,
  label,
  disabled,
  value,
  placeholder = "Selecionar",
  size = "small",
  tooltip = null,
  disableCloseOnSelect=true,
  useCheckboxRender = true,
  error=false,
  onChange,
}: Props) {

  const renderOption = (option, { selected }) => {
    if (useCheckboxRender) {
      return (
        <FormControlLabel
          control={<Checkbox checked={selected} />}
          label={option.label}
        />
      );
    } else {
      return option.label;
    }
  };
  return (
    <>
      <div className="form-data d-flex flex-row align-items-start">
        <Label required={false} name={label} htmlFor="MultiAutoComplete" />
        {tooltip && (
          <CustomTooltip
            title={tooltip}
            placement="top"
            disableHoverListener={false}
            arrow
            className="header-form-data ml-1 "
          >
            <div className="importInfo">
              <BiSolidInfoCircle />
            </div>
          </CustomTooltip>
        )}
      </div>
      <Autocomplete

        loading={loading}
        disabled={disabled}
        getOptionLabel={(option) => option.label || ""}
        options={options}
        value={value}
        size={size}
        disableCloseOnSelect={disableCloseOnSelect}
        renderOption={renderOption}
        renderInput={(params) => (
          <TextField className={"myTextField"}  error={error} {...params} variant="outlined" label={placeholder} />
        )}
        multiple={multiple}
        onChange={onChange}
      />
    </>
  );
}
