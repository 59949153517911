import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import AppDetails from "./AppDetails";
import SectionList from "./SectionList";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import AppsIcon from "@material-ui/icons/Apps";
import "./index.css";
import SectionCreate from "./SectionCreate";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import BrushIcon from "@material-ui/icons/Brush";
import DesignManager from "./DesignManager";

function LayoutManager(props) {
  const { loginData } = useContext(AuthContext);
  const [newSection, setNewSection] = useState({});
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  function refreshSectionList(data) {
    setNewSection(data);
  }

  function onSelectTab(index) {
    localStorage.setItem("layout-manager-tab", String(index));
    setSelectedTabIndex(index);
  }

  useEffect(() => {
    const currentTab = Number(localStorage.getItem("layout-manager-tab")) || 0;
    setSelectedTabIndex(currentTab);
  }, []);

  return (
    <div>
      <div className="header-title-wrapper">
        <div className="header-title">Gerenciador de interface</div>
      </div>
      <Tabs
        forceRenderTabPanel
        onSelect={(index) => onSelectTab(index)}
        selectedIndex={selectedTabIndex}
      >
        <TabList className="layout-manager-tablist">
          <Tab>
            {" "}
            <DesktopMacIcon style={{ marginRight: 5 }} /> Detalhes da aplicação
          </Tab>
          <Tab>
            {" "}
            <AppsIcon style={{ marginRight: 5 }} />
            Seções
          </Tab>
          <Tab>
            {" "}
            <PlaylistAddIcon style={{ marginRight: 5, fontSize: 26 }} />
            Nova seção
          </Tab>
          <Tab>
            {" "}
            <BrushIcon style={{ marginRight: 5, fontSize: 26 }} />
            Gerenciador de design
          </Tab>
        </TabList>
        <TabPanel>
          <AppDetails />
        </TabPanel>
        <TabPanel>
          <SectionList newSection={newSection} />
        </TabPanel>
        <TabPanel>
          <SectionCreate refreshSectionList={(data) => refreshSectionList(data)} />
        </TabPanel>
        <TabPanel>
          <DesignManager />
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default LayoutManager;
