import React, { useCallback, useEffect, useState } from "react";


import Text from "../../../Text";
import CardUpgrade from "../CardUpgrade";

import { useManagerTrial } from "../../context/contextManagerTrial";
import CarrousselView from "components/PeopleCraft/CarrousselView";

const RequestUpgrade = ({}) => {
  const [showDetail, setshowDetail] = useState<boolean>(false);

  const { listPlans } = useManagerTrial();
  return (
    <>
      <div className="title">
        <Text component={"h3"} text={"Comece seu trial"} />
        <Text
        className="subtitle-upgrade"
          component={"p"}
          text={
            "Desenvolva e aprimore seus próprios programas de treinamento, com acesso exclusivo a uma ampla variedade de biblioteca, proporcionando um acervo rico em conteúdo e recursos de aprendizado."
          }
        />
      </div>

      <div className={`carroussel`}>
        {listPlans?.length && (
          <CarrousselView
            options={{
              cellAlign: "center",
              selectedAttraction: 1,
              friction: 1,
              contain: true,
            }}
            reloadOnUpdate={false}
          >
            {listPlans.map((elem, index) => (
              <CardUpgrade
                key={`card-upgrade-${index}`}
                showDetail={showDetail}
                elem={elem}
              />
            ))}
          </CarrousselView>
        )}
      </div>
      <button
        className="showDetail"
        onClick={(e) => setshowDetail(!showDetail)}
      >
        Ver {!showDetail ? "mais" : "menos"} detalhes
      </button>
    </>
  );
};

export default RequestUpgrade;
