import React, { useState, useEffect } from "react";
import { getMenuData, getCompaniesByIdApp } from "../../services/NexiDigitalAPI";
import { Modal, Button, Form, ModalBody } from "react-bootstrap";
import DataTable from "react-data-table-component";
import styles from "./styles.module.scss";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import { object, func, bool } from "prop-types";
import Loading from "components/Loading";

const propTypes = {
  setSelectedCompany: func.isRequired,
  selectedCompany: object,
  showInput: bool,
  showAllCompaniesOption: bool,
  disableSelection: bool,
};

const NoDataComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 400,
        width: "100%",
        color: "gray",
      }}
    >
      Não foi encontrado nenhum dado
    </div>
  );
};

const columns = [
  {
    name: "Nome da empresa",
    selector: "name",
    width: "70%",
    sortable: false,
    center: true,
    wrap: true,
    style: { textAlign: "center" },
    reorder: false,
    cell: (d) => <>{d.name || "-"}</>,
  },
  {
    name: "CNPJ",
    selector: "cnpj",
    sortable: false,
    center: true,
    wrap: true,
    width: "30%",
    style: { textAlign: "center" },
  },
];

const customTableStyles = {
  table: {
    style: {
      maxHeight: "500px",
      overflow: "auto",
    },
  },
  rows: {
    style: {
      minHeight: "30px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "30px", // override the cell padding for head cells
      textAlign: "center",
    },
  },
  cells: {
    style: {
      paddingLeft: "30px", // override the cell padding for data cells
    },
  },
  header: {
    style: {
      fontSize: "22px",
      minHeight: "56px",
      paddingLeft: "28px",
      paddingRight: "8px",
      paddingTop: "30px",
      paddingBottom: "15px",
    },
  },
};

const paginationComponentOptions = {
  rowsPerPageText: "Empresas por página",
  rangeSeparatorText: "de",
};

export default function CompaniesModal({
  showInput,
  setSelectedCompany,
  selectedCompany,
  showAllCompaniesOption,
  disableSelection = false,
  companiesModalInit,
}) {
  const { idCompany, companyCnpj, companyName, idApp } = getMenuData();

  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
    totalPage: 999,
  });
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState({
    columns,
    data: [],
  });

  function _getCompany(e, customPaginate) {
    e && e.preventDefault();
    customPaginate && setPagination(customPaginate);
    const paginationConfig = customPaginate || pagination;

    setLoading(true);
    getCompaniesByIdApp(idApp, paginationConfig, searchText)
      .then((res) => {
        if (res.data.success === true) {
          setTableData({
            columns: columns,
            data: res.data.companies,
          });
          setPagination({
            ...paginationConfig,
            totalPage: res.data.totalRecords,
          });
        }
      })
      .catch((err) => {
        console.log("error ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const onChangePage = (page) => {
    _getCompany(null, {
      ...pagination,
      offset: page === 1 ? 0 : (page - 1) * pagination.limit,
      page,
    });
  };

  const onChangeRowsPerPage = (limit, page) => {
    _getCompany(null, {
      ...pagination,
      limit,
      offset: page === 1 ? 0 : (page - 1) * limit,
      page,
    });
  };

  const onRowClicked = (company) => {
    setSelectedCompany(company);
    localStorage.setItem("last-selected-company-modal", JSON.stringify(company));
    setShowModal(false);

    let menuData = JSON.parse(localStorage.getItem("menu-data"));
    menuData.idCompany = company.idCompany;

    localStorage.setItem("menu-data", JSON.stringify(menuData));
  };

  const handleSelectAllCompanies = () => {
    setSelectedCompany({ name: "Todas empresas", idCompany: "all" });
    setShowModal(false);
  };

  useEffect(() => {
    if (!selectedCompany.idCompany) {
      const lastSelectedCompany = JSON.parse(
        localStorage.getItem("last-selected-company-modal") || null,
      );
      if (lastSelectedCompany) {
        setSelectedCompany({
          idCompany: lastSelectedCompany.idCompany,
          name: lastSelectedCompany.name,
          cnpj: lastSelectedCompany.cnpj,
          isUserCompany: true,
        });
      } else {
        setSelectedCompany({
          idCompany: idCompany,
          name: companyName,
          cnpj: companyCnpj,
          isUserCompany: true,
        });
      }
    }
    if (showModal && !tableData.data.length) {
      _getCompany();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <>
      {showModal && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
          className="modal-main-container"
        >
          <div className="modal-support-blur" />
          <ModalBody className="modal-content">
            <Modal.Header>
              <Modal.Title>Seleção de empresa</Modal.Title>
            </Modal.Header>
            <div className="modal-principal">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "20px 0",
                }}
              >
                <Form
                  onSubmit={_getCompany}
                  className="input-group col-md-8"
                  style={{ alignItems: "center", padding: 0 }}
                >
                  <input
                    type="text"
                    className="form-control"
                    id="company-name"
                    style={{ marginRight: 8 }}
                    aria-describedby="company-name"
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Nome da empresa"
                    value={searchText}
                  />
                  <button type="submit" disabled={loading} className="button-peop button-yellow">
                    <SearchIcon style={{ height: 18 }} /> <span className="mr-1">Pesquisar</span>
                    {loading && <CircularProgress size={18} style={{ color: "white" }} />}
                  </button>
                </Form>
                {showAllCompaniesOption && (
                  <button
                    type="button"
                    disabled={loading}
                    className="btn btn-primary"
                    onClick={handleSelectAllCompanies}
                  >
                    Selecionar todas
                    {loading && (
                      <CircularProgress
                        size={18}
                        style={{ margin: "0px 0px -3px 10px", color: "white" }}
                      />
                    )}
                  </button>
                )}
              </div>
              <div className="node-manager-import-table-wrapper mb-2">
                <DataTable
                  columns={tableData.columns}
                  data={tableData.data}
                  className={styles.dataTableFormat}
                  customStyles={customTableStyles}
                  progressComponent={
                    <Loading containerStyle={{ height: 400 }}>Carregando empresas...</Loading>
                  }
                  noDataComponent={<NoDataComponent />}
                  onRowClicked={onRowClicked}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  paginationTotalRows={pagination.totalPage}
                  paginationComponentOptions={paginationComponentOptions}
                  progressPending={loading && tableData?.data.length === 0}
                  paginationServer
                  noHeader
                  fixedHeader
                  pagination
                  pointerOnHover
                  highlightOnHover
                />
              </div>
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Sair
              </Button>
            </Modal.Footer>
          </ModalBody>
        </Modal>
      )}
      {showInput && (
        <div className="input-group" style={{ minWidth: "350px" }}>
          <div
            className={`button-peop button-yellow`}
            style={{
              pointerEvents: disableSelection ? "none" : "auto",
            }}
            onClick={() => setShowModal(true)}
          >
            {disableSelection ? "Empresa" : "Selecionar empresa"}
          </div>
          <input
            type="text"
            className="form-control"
            id="company-input-name"
            aria-describedby="company-input-name"
            value={
              selectedCompany
                ? `${selectedCompany.cnpj ? selectedCompany.cnpj + " | " : ""}${
                    selectedCompany.name ? selectedCompany.name : ""
                  }`
                : "Nenhuma empresa selecionada"
            }
            style={{ marginLeft: "-3px", zIndex: 1 }}
            disabled
          />
        </div>
      )}
    </>
  );
}

CompaniesModal.propTypes = propTypes;
