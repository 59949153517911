import React, { useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";

import { deleteCertificate } from "../../../../services/NexiDigitalAPI";

import { MdEdit } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";

import ModalDeleteSingle from "../modal/ModalDeleteSingle";
import ModalDeleteReplace from "../modal/ModalDeleteReplace";

const Table = ({ setLoading, loading, actionFilter, pag, callEditCertificates }) => {
  const [deleteShow, setDeleteShow] = useState(false);
  const [replaceShow, setReplaceShow] = useState(false);
  const [selectCertificate, setSelectCertificate] = useState({ title: "", idCertificate: 0 });

  const editCertificate = (d) => {
    const { idCompany, idCertificate } = d;
    callEditCertificates(idCompany, idCertificate);
  };

  const deleteCertificateEvent = (d) => {
    setSelectCertificate(d);

    if (d.hasEnrollments === 0) {
      setDeleteShow(true);
    } else {
      setReplaceShow(true);
    }
  };

  const deleteEvent = () => {
    setLoading(true);
    deleteCertificate(selectCertificate.idCertificate).then((res) => {
      // console.log(res);
      setLoading(false);
      actionFilter({ ...pag, searchString: "" });
    });
  };

  const columns = [
    {
      name: "Nome",
      selector: "title",
      sortable: true,
      center: true,
      wrap: true,
      // width: "90%",
      style: { textAlign: "left", justifyContent: "flex-start" },
      // cell: (d) => ValidateData(d.title),
    },
    {
      name: "Ações",
      selector: "action",
      sortable: false,
      // width: "10%",
      style: { textAlign: "center" },
      className: "actionBtns",
      cell: (d) => (
        <>
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                Editar
              </Tooltip>
            }
          >
            <Button
              variant="success"
              onClick={(e) => {
                editCertificate(d);
              }}
              className={"btnEdit"}
            >
              <MdEdit />
            </Button>
          </OverlayTrigger>

          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                Deletar
              </Tooltip>
            }
          >
            <Button
              variant="danger"
              onClick={(e) => {
                deleteCertificateEvent(d);
              }}
            >
              <RiDeleteBin5Line />
            </Button>
          </OverlayTrigger>
        </>
      ),
    },
  ];

  const customTableStyles = {
    col: {
      style: {
        paddingTop: "10px",
        paddingBottom: "10px",
        textAlign: "left",
        justifyContent: "space-between !important",
        // textAlign: "center",
      },
    },
    rows: {
      style: {
        paddingTop: "10px",
        paddingBottom: "10px",
        textAlign: "left",
        justifyContent: "space-between !important",
        // backgroundColor: "red",
        // textAlign: "center",
      },
    },
    headCells: {
      style: {
        paddingTop: "10px",
        paddingBottom: "10px",
        // textAlign: "center",
        width: "150px",
        fontWeight: "bold",
        fontSize: "20px",
        justifyContent: "flex-start !important",
        flex: "none !important",
        textAlign: "left",
      },
    },
    cells: {
      style: {
        textAlign: "left",
        justifyContent: "flex-start",
        flex: "none !important",
        maxWidth: "200px !important",
        // "&:not(:last-of-type)": {
        //   borderRightStyle: "solid",
        //   borderRightWidth: "1px",
        //   borderRightColor: "rgba(0, 0, 0, 0.57);",
        // },
      },
    },

    header: {
      style: {
        fontSize: "22px",
        justifyContent: "space-between !important",
        textAlign: "left",
        textWrap: "nowrap",
      },
    },
    headRow: {
      style: {
        fontSize: "22px",
        justifyContent: "space-between !important",
        textAlign: "left",
        borderTopColor: "#000",
        textWrap: "nowrap",
      },
    },
  };

  const tableData = {
    columns: columns,
    data: pag.data,
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Usuários por página",
    rangeSeparatorText: "de",
  };

  const onChangePage = (page) => {
    const offset = --page * pag.limit;
    actionFilter({ ...pag, offset: offset });
  };

  const onChangeRowsPerPage = (limit) => {
    actionFilter({ ...pag, offset: 0, limit: limit });
  };

  const loadingComponent = () => {
    return (
      <div className="tela-login-loading">
        <div className="loader-container" style={{ margin: "300px 0" }}>
          <div
            style={{
              position: "initial",
              margin: "initial",
            }}
            className="loader loader-center loader-big"
          ></div>
          <span
            style={{
              marginTop: 10,
              fontSize: 20,
            }}
          >
            Carregando dados...
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <DataTableExtensions
        filter={false}
        print={false}
        {...tableData}
        exportHeaders={false}
        export={false}
      >
        <DataTable
          columns={columns}
          data={pag.data}
          // className={styles.dataTableFormat}
          customStyles={customTableStyles}
          progressPending={loading}
          progressComponent={loadingComponent()}
          noHeader={true}
          noDataComponent="Não foi encontrado nenhum dado"
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationTotalRows={pag.totalRecords}
          paginationComponentOptions={paginationComponentOptions}
          paginationServer
          pagination
        />
      </DataTableExtensions>
      <ModalDeleteSingle show={deleteShow} setShow={setDeleteShow} deleteEvent={deleteEvent} />
      <ModalDeleteReplace
        show={replaceShow}
        setShow={setReplaceShow}
        selectCertificate={selectCertificate}
        loadingComponent={loadingComponent}
        deleteEvent={deleteEvent}
      />
    </>
  );
};

export default Table;
