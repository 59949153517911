import React, { useState, useEffect, useRef, useContext } from "react";
import { getTags, setTags, removeTag } from "../../services/NexiDigitalAPI";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import _ from "underscore";
import FilterResults from "react-filter-search";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { AuthContext } from "../../context/AuthContext";
import Loading from "../../components/Loading";
import { getMenuRoutes } from "../../utils/Utils";
import { CircularProgress } from "@material-ui/core";
import SnackComponent from "../../components/SnackComponent";

const Tags = ({ data }) => {
  const [lista, setLista] = useState([]);
  const [listaOrdenada, setListaOrdenada] = useState([]);
  const [tag, setTag] = useState("");
  const isMountedRef = useRef(null);
  const [loadingTags, setLoadingTags] = useState(true);
  const [loadingDeleteTag, setLoadingDeleteTag] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackInfo, setSnackInfo] = useState({
    message: "",
    severity: "error",
  });

  const { loginData } = useContext(AuthContext);
  const secondaryColor = loginData.secondaryColor;

  /* [Permission Module] - Permissions states */
  const [permissionAddTags, setPermissionAddTags] = useState(false);
  const [permissionDeleteTags, setPermissionDeleteTags] = useState(false);

  /* [Permission Module] - Menu Options */
  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();

    const objCurrentMenu = menuOptionList.filter((menu) => menu.text === "Configurações");

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);

      getPermissionsPage(resources);
    }
  }

  /* [Permission Module] - Permission attrs in page by login data information */
  function getPermissionsPage(resources) {
    try {
      if (Boolean(resources[0].filter((menu) => menu.name === "Inserir Tags").length))
        setPermissionAddTags(true);
      if (Boolean(resources[0].filter((menu) => menu.name === "Remover Tags").length))
        setPermissionDeleteTags(true);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    /* [Permission Module] - Function calls */
    getMenuOption();

    isMountedRef.current = true;

    getTags()
      .then((res) => {
        if (isMountedRef.current) {
          const tagSearch = res.data.tagsearch;
          const tagsSorted = _.sortBy(tagSearch, "tag");
          setListaOrdenada(tagsSorted);
          setLista(tagsSorted);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingTags(false);
      });

    return () => (isMountedRef.current = false);
  }, []);

  const handleAdd = () => {
    if (tag.length > 0) {
      setTags(tag).then((res) => {
        if (res.data.result.success) {
          const novoId = res.data.result.tagInsert[0];
          const novaLista = [...lista, { idTag: novoId, tag }];
          const novaListaOrdenada = _.sortBy(novaLista, "tag");
          setLista(novaListaOrdenada);

          setTag("");
        }
      });
    }
  };

  const handleDel = (id) => {
    const novaListaOrdenada = _.filter(lista, function (i) {
      return i.idTag !== id;
    });
    setLoadingDeleteTag(id);
    removeTag(id)
      .then((res) => {
        if (res.data.resultValue) {
          setLista(novaListaOrdenada);
        } else {
          setSnackInfo({
            message: res.data.message,
            severity: "error",
          });
          setOpenSnackBar(true);
        }
      })
      .finally(() => {
        setLoadingDeleteTag(null);
      });
  };

  const handleTag = (event) => {
    if (event.length === 0) {
      setTag("");
    }
    if (event.length >= 3) {
      setTag(event);
    }
  };

  return (
    <div className="tags">
      <SnackComponent
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        message={snackInfo.message}
        severity={snackInfo.severity}
      />
      <div className="header-title-wrapper">
        <div className="header-title">Tags</div>
      </div>

      {/* Cadastra nova tag */}
      {permissionAddTags && (
        <div className="post-form">
          <form>
            <div className="form-group">
              <div className="card">
                <div
                  className="card-header template-card-header"
                  style={{ color: "#FFF", backgroundColor: secondaryColor }}
                >
                  Cadastrar nova Tag
                </div>
                <div className="card-body card-body-tags">
                  <div style={{ flex: 1, marginRight: 20 }}>
                    <input
                      placeholder="Digite o nome da tag..."
                      type="text"
                      className="form-control input-default"
                      id="tag"
                      aria-describedby="tag"
                      onChange={(e) => handleTag(e.target.value)}
                    />
                  </div>
                  <button type="submit" className="btn btn-secondary" onClick={handleAdd}>
                    <AddCircleIcon /> Nova tag
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}

      {/* Lista tags */}
      <div className="card">
        <div
          className="card-header template-card-header"
          style={{ color: "#FFF", backgroundColor: secondaryColor }}
        >
          Lista de Tags
        </div>
        <div className="card-body template-card-body">
          <FilterResults
            value={tag}
            data={lista}
            renderResults={(results) => (
              <ul className="list-group">
                {loadingTags ? (
                  <Loading> Carregando tags... </Loading>
                ) : (
                  results.map((item, index) => {
                    return (
                      <div key={index}>
                        <li className="list-group-item">
                          {item.tag}
                          <span className="float-right" style={{ marginTop: -4 }}>
                            {permissionDeleteTags && (
                              <button
                                className="btn btn-outline-danger btn-sm"
                                style={{ display: "flex" }}
                                onClick={() => handleDel(item.idTag)}
                              >
                                {loadingDeleteTag === item.idTag ? (
                                  <CircularProgress size={20} />
                                ) : (
                                  <DeleteForeverOutlinedIcon fontSize="small" />
                                )}
                              </button>
                            )}
                          </span>
                        </li>
                      </div>
                    );
                  })
                )}
              </ul>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Tags;
