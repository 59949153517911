import React, { useState, useEffect } from "react";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import { withRouter, useParams } from "react-router-dom";

import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";

import {
  getLearningEnrollments,
  getLearningNotEnrollments,
  postLearningEnrollments,
  removeLearningEnrollments,
} from "../../../../services/NexiDigitalAPI";

import Loading from "../../../../components/Loading";

import { Tooltip } from "@material-ui/core";

import "./style.modules.scss";

import DataTable from "react-data-table-component";
import NoDataPlaceholder from "../../../../components/NoDataPlaceholder";
import { useCallback } from "react";
const defaultPagination = {
  offset: 0,
  limit: 10,
  totalRecords: 999,
};

const paginationComponentOptions = {
  rowsPerPageText: "Usuários por página",
  rangeSeparatorText: "de",
};

const ListRegistration = (props) => {
  const colunsDefault =   [
    {
      name: "Nome",
      selector: "name",
      reorder: false,
      sortable: false,
    },
    {
      name: "E-mail",
      selector: "email",
      reorder: false,
      sortable: false,
    },
  ]
  const columnsEnrollment = [
    ...colunsDefault,
    {
      name: "Ações",
      wrap: true,
      width: "200px",
      center: true,
      style: { textAlign: "center" },
      cell: (item) => (
        <Tooltip title="Remover Usuário" placement="top">
          <span className="btn btn-sm float-right" onClick={() => enrollmentEvent(item, "remove")}>
            <RemoveCircleOutlineOutlinedIcon style={{ color: "#dc3545" }} />
          </span>
        </Tooltip>
      ),
    },
  ];

  const columnsNotEnrollment = [
    ...colunsDefault,
    {
      name: "Ações",
      wrap: true,
      width: "200px",
      center: true,
      style: { textAlign: "center" },
      cell: (item) => (
        <Tooltip title="Adicionar Usuário" placement="top">
          <span className="btn btn-sm float-right" onClick={() => enrollmentEvent(item, "add")}>
            <AddCircleOutlineOutlinedIcon style={{ color: "#1e7e34" }} />
          </span>
        </Tooltip>
      ),
    },
  ]
  const {  title, idTeam } = useParams();

  const [currentUser, setCurrentUser] = useState("");
  const [userEnroll, setUserEnroll] = useState([]);
  const [loadingUserEnroll, setLoadingUserEnroll] = useState(false);
  const [loadingUserNotEnroll, setLoadingUserNotEnroll] = useState(false);
  const [userNotEnroll, setUserNotEnroll] = useState([]);
  const [paginationUserEnroll, setPaginationUserEnroll] = useState(defaultPagination);
  const [paginationUserNotEnroll, setPaginationUserNotEnroll] = useState( defaultPagination);


  const getParamsRequest = useCallback(
    (customPagination, clearSearchText, pagination) => {
      const queryParams = {
        offset: customPagination?.offset || pagination.offset,
        limit: customPagination?.limit || pagination.limit,
        idTeam,
      };
      if (clearSearchText || customPagination?.searchText || currentUser) {
        queryParams.name = customPagination?.searchText || currentUser;
      }
      return queryParams;
    },
    [idTeam, currentUser],
  );

  const getUserEnroll = useCallback(
    async (customPagination, clearSearchText) => {
      setLoadingUserEnroll(true);

      try {
        const containerApi = await getLearningEnrollments(
          getParamsRequest(customPagination, clearSearchText, paginationUserEnroll),
        );
        setUserEnroll(containerApi.result);

        setPaginationUserEnroll((prev) => {return {...prev, totalRecords: containerApi.totalRecords}} );

      } catch (e) {
        setUserEnroll([]);
      } finally {
        setLoadingUserEnroll(false);
      }
    },
    [ getParamsRequest],
  );

  const getUserNotEnroll = useCallback(
    async (customPagination, clearSearchText) => {
      setLoadingUserNotEnroll(true);
      try {
        const containerApi = await getLearningNotEnrollments(
          getParamsRequest(customPagination, clearSearchText, paginationUserNotEnroll),
        );
        setUserNotEnroll(containerApi.result);
        setPaginationUserNotEnroll((prev) => ({...prev, totalRecords: containerApi.totalRecords}));

      } catch (e) {
        setUserNotEnroll([]);
      } finally {
        setLoadingUserNotEnroll(false);
      }
    },
    [getParamsRequest],
  );

  useEffect(() => {
    getUserEnroll();
    getUserNotEnroll();
  }, [getUserEnroll, getUserNotEnroll]);

  const getParamsChangePage = (page, pagination) => ({
    ...pagination,
    offset: --page * pagination.limit,
  });

  const getParamsRowsPerPage = (limit, page, pagination) => ({
    ...pagination,
    limit,
    offset: --page * limit,
  });

  const onChangePageUserEnroll = (page) => {
    const _pagination = getParamsChangePage(page, paginationUserNotEnroll)
    setPaginationUserEnroll(_pagination)
    getUserEnroll(_pagination);
  };

  const onChangeUserEnrollRowsPerPage = (limit, page) => {
    const _pagination = getParamsRowsPerPage(limit, page, paginationUserNotEnroll)
    setPaginationUserEnroll(_pagination)
    getUserEnroll(_pagination);
  };

  const onChangePageUserNotEnroll = (page) => {
    const _pagination = getParamsChangePage(page, paginationUserNotEnroll)
    setPaginationUserNotEnroll(_pagination)
    getUserNotEnroll(_pagination);
  }

  const onChangeUserNotEnrollRowsPerPage = (limit, page) => {
    const _pagination = getParamsRowsPerPage(limit,page, paginationUserNotEnroll)
    setPaginationUserNotEnroll(_pagination)

    getUserNotEnroll(_pagination);
  }

  const controlEnroll =  async (el, type) => {
    const { idUser, idEnrollment } = el;

    if (type === "add") {
      setLoadingUserNotEnroll(true);

      await postLearningEnrollments({ idTeam, idUser });
    }else{
      setLoadingUserEnroll(true);

      await  removeLearningEnrollments({ idEnrollment });
    }
    getUserNotEnroll();
    getUserEnroll();

  };

  const enrollmentEvent = async (el, type) => {
   await controlEnroll(el, type);
  };

  const handleChangeInput = (e) => {
    setPaginationUserEnroll((prev) => ({...defaultPagination, limit: prev.limit}))
    setPaginationUserNotEnroll((prev) => ({...defaultPagination, limit: prev.limit}))
    setCurrentUser(e.target.value)
  }


  return (
    <div className="container groups">
      <div className="header-title-wrapper">
        <div className="header-title">
          <button
            className="btnGoBack"
            onClick={() => {
              props.history.goBack();
            }}
          >
            <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
          </button>
          Gestão de Turmas - {title}
        </div>
      </div>

      <div className="post-form">
        <div className="form-group">
          <div className="card">
            <div
              className="card-header template-card-header"
              style={{
                color: "#FFF",
                backgroundColor: "#6b48ff",
              }}
            >
              Selecionar usuários
            </div>
            <div className="card-body template-card-body">
              <div className="row">
                <div className="col-md-10 input-container">
                  <input
                    type="text"
                    className="form-control input-default"
                    id="groupDetail"
                    aria-describedby="usuario"
                    value={currentUser}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="user-list-container">
        {/* Lista usuarios do Grupo*/}
        <div className="card">
          <div
            className="card-header template-card-header"
            style={{
              color: "#FFF",
              backgroundColor: "#6b48ff",
            }}
          >
            Usuários matriculados
          </div>
          <div className="card-body template-card-body">
          <DataTable
               key='userEnrollment'
              columns={columnsEnrollment}
              data={userEnroll}
              noDataComponent={<NoDataPlaceholder style={{height: 50}} text="Ainda não há usuários matriculados" />}
              progressComponent={
                <Loading containerStyle={{ height: 100 }}>Carregando matriculas...</Loading>
              }
              onChangePage={onChangePageUserEnroll}
              onChangeRowsPerPage={onChangeUserEnrollRowsPerPage}
              progressPending={loadingUserEnroll}
              paginationTotalRows={paginationUserEnroll.totalRecords}
              paginationComponentOptions={paginationComponentOptions}
              noHeader
              fixedHeader
              pagination
              paginationServer
              highlightOnHover
            ></DataTable>
          </div>
        </div>

        <div className="user-list-separator"></div>

        {/* Lista usuarios Disponiveis*/}
        <div className="card">
          <div
            className="card-header template-card-header"
            style={{
              color: "#FFF",
              backgroundColor: "#6b48ff",
            }}
          >
            Usuários não matriculados
          </div>
          <div className="card-body template-card-body">
            <DataTable
              key='userNotEnrollment'
              columns={columnsNotEnrollment}
              data={userNotEnroll}
              noDataComponent={<NoDataPlaceholder  style={{height: 50}}  text="Ainda não há usuários matriculados" />}
              progressComponent={
                <Loading containerStyle={{ height: 100 }}>Carregando matriculas...</Loading>
              }
              onChangePage={onChangePageUserNotEnroll}
              onChangeRowsPerPage={onChangeUserNotEnrollRowsPerPage}
              paginationComponentOptions={paginationComponentOptions}
              progressPending={loadingUserNotEnroll}
              paginationTotalRows={paginationUserNotEnroll.totalRecords}
              noHeader
              fixedHeader
              pagination
              paginationPerPage={paginationUserNotEnroll.limit}
              paginationServer
            ></DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ListRegistration);
