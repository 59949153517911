import React, { useEffect, useState, createContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import { NexiDigitalAPI as api, getToken, getContentList } from "../../services/NexiDigitalAPI";
import "./styles.scss";

import Loading from "../../components/Loading";
import ModalCreateFolder from "./ModalCreateFolder";

import folderIcon from "../../assets/images/folder-icon.png";

import { getMenuRoutes } from "../../utils/Utils";

export const LibraryContext = createContext();

export default function LibraryProvider({ idSection }) {
  const [dialogMessage, setDialogMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [toggleAddNewItem, setToggleAddNewItem] = useState(false);
  const [openCreateFolderModal, setOpenCreateFolderModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);

  /* [Permission Module] - Permissions states */
  const [permissionAddFileAndFolder, setPermissionAddFileAndFolder] = useState(false);
  const [permissionDeleteFileAndFolder, setPermissionDeleteFileAndFolder] = useState(false);

  /* [Permission Module] - Menu Options */
  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();

    const objCurrentMenu = menuOptionList.filter((menu) => menu.text === "Gestão de Biblioteca");

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);

      getPermissionsPage(resources);
    }
  }

  /* [Permission Module] - Permission attrs in page by login data information */
  function getPermissionsPage(resources) {
    try {
      if (Boolean(resources[0].filter((menu) => menu.name === "Adicionar Arquivo e Pasta").length))
        setPermissionAddFileAndFolder(true);
      if (Boolean(resources[0].filter((menu) => menu.name === "Remover Arquivo e Pasta").length))
        setPermissionDeleteFileAndFolder(true);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    /* [Permission Module] - Function calls */
    getMenuOption();

    getData();
  }, []);

  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth <= 640);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  useEffect(() => {
    if (!folders.length) return;

    if (currentFolder) {
      const nextFolder = folders.find(({ key }) => currentFolder.key === key);
      setCurrentFolder(nextFolder);
    } else {
      setCurrentFolder(folders.find(({ folderLevel }) => folderLevel === 1));
    }
  }, [folders]);

  useEffect(() => {
    if (!data.length) return;

    let listFolders = [];

    data.forEach((folder) => {
      const { key, isFolder } = folder;

      if (!isFolder) return;

      const folderFiles = data
        .map((file) => {
          const { isFolder, key: keyFile } = file;

          if (isFolder) return "";

          if (key.split("/").length !== keyFile.split("/").length) return "";

          if (!keyFile.includes(key)) return "";

          return file;
        })
        .filter(String);

      const foldersInThisFolder = data
        .map((folder) => {
          const { isFolder, key: keyFolder } = folder;

          if (!isFolder || key === keyFolder) return "";

          if (key.split("/").length + 1 !== keyFolder.split("/").length) return "";

          if (!keyFolder.includes(key)) return "";

          return folder;
        })
        .filter(String);

      const folderLevel = key.split("/").length - 2;

      listFolders.push({
        ...folder,
        folderLevel,
        folders: foldersInThisFolder,
        files: folderFiles,
      });
    });

    setFolders(listFolders);
  }, [data]);

  const getData = async () => {
    const [data] = await getContentList(idSection);

    if (data) {
      api
        .get(`/mediacenter/${data.idContentItem}`)
        .then((response) => setData(response.data.files));
    }
  };

  const getExtension = (key) => {
    const lastIndex = key.split(".").length - 1;
    const extension = key.split(".")[lastIndex];
    return extension;
  };

  const getFileName = (key) => {
    const lastIndex = key.split("/").length - 1;
    let name = key.split("/")[lastIndex];
    return name;
  };

  const getFolderName = (key) => {
    const lastIndex = key.split("/").length - 2;
    let name = key.split("/")[lastIndex];
    return name;
  };

  const setNextFolderLevel = (nextKey) => {
    const nextFolder = folders.find(({ key }) => nextKey === key);
    setCurrentFolder(nextFolder);
  };

  const handleBreadcrumbs = (index) => {
    const nextFolder = currentFolder.key
      .split("/")
      .filter(String)
      .map((key, i) => (i <= index ? key : ""))
      .filter(String)
      .join("/");

    setNextFolderLevel(`/${nextFolder}/`);
  };

  const addNewItem = () => {
    setToggleAddNewItem(!toggleAddNewItem);
  };

  const handleModalCreateFolder = () => {
    setToggleAddNewItem(false);
    setOpenCreateFolderModal(!openCreateFolderModal);
  };

  const handleChangeFile = async (event) => {
    setToggleAddNewItem(false);

    const token = getToken();
    const formData = new FormData();

    formData.append("fileData", event.target.files[0]);
    formData.append("key", currentFolder.key);

    setIsLoading(true);
    setDialogMessage("Enviando arquivo...");

    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_UPLOAD}/mediacenter/file`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      getData();
      setIsLoading(false);
      setDialogMessage("Arquivo criado com sucesso.");
      setCurrentFolder({
        ...currentFolder,
        files: [
          ...currentFolder.files,
          {
            key: response.data.key,
            url: response.data.location,
            data: new Date(),
          },
        ],
      });
    });
  };

  const removeFolder = async (keyFolder) => {
    setIsLoading(true);
    setDialogMessage("Excluindo pasta...");

    await api.delete(`/mediacenter/administrator/folder?key=${keyFolder}`).then((response) => {
      getData();
      setIsLoading(false);
      setDialogMessage("Item excluído com sucesso.");
      setCurrentFolder({
        ...currentFolder,
        folders: currentFolder.folders.filter(({ key }) => key !== keyFolder),
      });
    });
  };

  const removeFile = async (keyFile) => {
    setIsLoading(true);
    setDialogMessage("Excluindo arquivo...");

    await api.delete(`/mediacenter/administrator/file?key=${keyFile}`).then((response) => {
      getData();
      setIsLoading(false);
      setDialogMessage("Item excluído com sucesso.");
      setCurrentFolder({
        ...currentFolder,
        files: currentFolder.files.filter(({ key }) => key !== keyFile),
      });
    });
  };

  return (
    <>
      <div className="header-title-wrapper">
        <div className="header-title">Gestão de Biblioteca</div>
      </div>
      <LibraryContext.Provider value={{ setOpenCreateFolderModal, getData, idSection }}>
        <div className="library">
          {!data.length ? (
            <Loading center> Carregando conteúdos... </Loading>
          ) : (
            <div className="library__content">
              <div className="breadcrumbs">
                {currentFolder &&
                  currentFolder.key
                    .split("/")
                    .filter(String)
                    .map((item, index) => (
                      <h3
                        key={index}
                        onClick={() => {
                          handleBreadcrumbs(index);
                        }}
                      >
                        <span>{item}</span>
                        {currentFolder.key.split("/").filter(String).length - 1 !== index && (
                          <i>{" / "}</i>
                        )}
                      </h3>
                    ))}
              </div>

              <div className="add-new">
                {permissionAddFileAndFolder && (
                  <button onClick={addNewItem}>
                    <AddIcon />
                    Adicionar
                  </button>
                )}

                <div className={`add-new__menu ${toggleAddNewItem && "open"}`}>
                  <span onClick={handleModalCreateFolder}>Nova pasta</span>
                  <span>
                    Novo arquivo
                    <input
                      type="file"
                      title=""
                      onChange={handleChangeFile}
                      encType="multipart/form-data"
                    />
                  </span>
                </div>
              </div>

              <div className="library__header">
                <span>Nome</span>
                <span>Modificado</span>
              </div>

              {currentFolder && (
                <>
                  <ModalCreateFolder keyfolder={currentFolder.key} isopen={openCreateFolderModal} />

                  {currentFolder.folders.map(({ key, date }, index) => (
                    <div className="library__folder" key={index}>
                      <img src={folderIcon} alt={""} />
                      <span onClick={() => setNextFolderLevel(key)}>{getFolderName(key)}</span>
                      <span onClick={() => setNextFolderLevel(key)}>
                        {isMobile ? moment(date).format("L") : moment(date).format("LL")}
                      </span>
                      <span>
                        {permissionDeleteFileAndFolder && (
                          <DeleteOutlineIcon onClick={() => removeFolder(key)} />
                        )}
                      </span>
                    </div>
                  ))}

                  {currentFolder.files.map(({ key, url, date }, index) => (
                    <div key={index} className="library__file">
                      <i className={getExtension(key)} />
                      <a href={url} download>
                        <span>{getFileName(key)}</span>
                      </a>
                      <span>{isMobile ? moment(date).format("L") : moment(date).format("LL")}</span>
                      <span>
                        {permissionDeleteFileAndFolder && (
                          <DeleteOutlineIcon onClick={() => removeFile(key)} />
                        )}
                      </span>
                    </div>
                  ))}
                </>
              )}

              {dialogMessage && (
                <div className="library__status">
                  {isLoading ? <div className="loading"></div> : <DoneIcon />}
                  <span>{dialogMessage}</span>
                  <CloseIcon className="close" onClick={() => setDialogMessage(null)} />
                </div>
              )}
            </div>
          )}
        </div>
      </LibraryContext.Provider>
    </>
  );
}
