import React, { useEffect } from "react";
import parse from "html-react-parser";

const MultiLikertScale = ({
  answer,
  mandatory = true,
  indice,
  onHandleChange,
}) => {
  const { scales, statement, options } = answer.question;
  const validateAnswerStatus = () => {
    if (mandatory)
      answer.answerStatus = options.length === answer.answerData.length;
    else answer.answerStatus = true;
  };

  validateAnswerStatus();
  useEffect(() => {
    onHandleChange();
  });

  const changeHandler = (e, item, indice) => {
    const { question } = answer;
    const op = question.options[indice];
    op.idQuestionScale = item.idQuestionScale;

    const answerDataFilter = answer.answerData.filter(
      (it) => it.idQuestionOption === op.idQuestionOption
    );

    if (answerDataFilter.length === 0) {
      const resNew = {
        idQuestionScale: op.idQuestionScale,
        idQuestionOption: op.idQuestionOption,
      };
      answer.answerData.push(resNew);
    } else {
      answer.answerData.map((it) => {
        const verfy = it.idQuestionOption === op.idQuestionOption;
        it.idQuestionScale = verfy ? op.idQuestionScale : it.idQuestionScale;
        return it;
      });
    }

    validateAnswerStatus();
    onHandleChange();
  };

  const checkStatus = (idQuestionScale, idQuestionOption) => {
    const checkStatusFilter = answer.answerData.filter(
      (el) =>
        el.idQuestionOption === idQuestionOption &&
        el.idQuestionScale === idQuestionScale
    );
    const statusCheck = checkStatusFilter[0] ? checkStatusFilter[0] : null;

    return statusCheck;
  };

  const renderRows = (i) => {
    const indiceInput = i + 1;
    const idQuestionOption = options[i].idQuestionOption;

    const _row = scales.map((item: any, k) => {
      return (
        <td id={item.title} key={k}>
          <input
            type="radio"
            id={`${idQuestionOption}_${item.idQuestionScale}`}
            name={`${indice}_multiL_${indiceInput}`}
            value={`${item.idQuestionScale}`}
            defaultChecked={checkStatus(item.idQuestionScale, idQuestionOption)}
            onChange={(e) => changeHandler(e, item, i)}
          />
        </td>
      );
    });

    return _row;
  };

  return (
    <div className="itD multi_likert_scale">
      <div className="titleType">
        <div className="titleIndice">{indice}</div>
        <div className="titleText">{parse(`${statement}`)}</div>
      </div>

      {
        <table className="itDChlidren">
          <thead>
            <tr>
              <th></th>
              {scales.map((colum: any, i) => {
                return (
                  <th key={i} id={colum.idQuestionScale}>
                    {colum.title}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {options.map((item, i) => {
              return (
                <tr key={i}>
                  <td className="descTD" id={item.idQuestionOption}>
                    <p>{item.description}</p>
                  </td>
                  {renderRows(i)}
                </tr>
              );
            })}
          </tbody>
        </table>
      }
    </div>
  );
};

export default MultiLikertScale;
