import { useEffect, useState } from "react";

// Page UI and icons
import "rc-datepicker/lib/style.css";
import { Form } from "react-bootstrap";
import CompaniesModal from "../../../components/CompaniesModal/CompaniesModal";
import { getMenuData } from "../../../services/NexiDigitalAPI";
import FiltroWrapper from "../Components/FiltroWrapper";

const Filtro = ({ actionFilter }) => {
  const adminLevel = getMenuData()?.admin;
  const [selectedCompany, setSelectedCompany] = useState({});

  function handleFilter() {
    return actionFilter(
      selectedCompany.idCompany === "all" ? undefined : selectedCompany.idCompany,
    );
  }

  function isAdminSecondDegree() {
    return adminLevel >= 2;
  }

  useEffect(() => {
    if (selectedCompany.idCompany) {
      handleFilter();
    }
  }, [selectedCompany]);

  if (!isAdminSecondDegree()) {
    return null;
  }

  return (
    <FiltroWrapper>
      <Form style={{ marginTop: 30, marginBottom: 30 }}>
        <Form.Row className="align-items-end">
          <Form.Row className="col-md-12 mb-2" style={{ padding: 5 }}>
            <CompaniesModal
              showAllCompaniesOption
              showInput={isAdminSecondDegree()}
              setSelectedCompany={setSelectedCompany}
              selectedCompany={selectedCompany}
            />
          </Form.Row>
        </Form.Row>
      </Form>
    </FiltroWrapper>
  );
};

export default Filtro;
