import React, { useState } from "react";
import styles from "./styles.module.scss";

const Acordeon = ({ name, index, children, startExpanded = false, onClickExpanded = null  }) => {
  const [expanded, setExpanded] = useState(startExpanded);
  const clickHandler = () => {
    setExpanded(!expanded);
    onClickExpanded && onClickExpanded(!expanded)
  }
  const secondColor = localStorage.getItem("secondaryColor");
  const titleColor =
    index % 2 === 0
      ? { backgroundColor: convertHex(secondColor, 14), color: "#000" }
      : { backgroundColor: convertHex(secondColor, 22), color: "#000" };

  function convertHex(hexCode, opacity) {
    var hex = hexCode.replace("#", "");

    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    var r = parseInt(hex.substring(0, 2), 16),
      g = parseInt(hex.substring(2, 4), 16),
      b = parseInt(hex.substring(4, 6), 16);

    return "rgba(" + r + "," + g + "," + b + "," + opacity / 100 + ")";
  }

  return (
    <div>
      <div onClick={clickHandler} index={index} style={titleColor} className={styles.acordeonTitle}>
        <div>{expanded ? "-" : "+"}</div>
        <div>{name}</div>
      </div>
      <div className={styles.acordeonContent} style={!expanded ? { display: "none" } : null}>
        {children}
      </div>
    </div>
  );
};

export default Acordeon;
