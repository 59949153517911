import "./styles.scss";
import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
// import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
// import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import _ from "underscore";
import { Tooltip } from "@material-ui/core";
import { Button, Modal } from "react-bootstrap";
import { getMenuRoutes } from "../../../../../utils/Utils";
import {
  createGroupSecondary,
  getGroupsSecondary,
  removeGroupSecondary,
} from "../../../../../services/NexiDigitalAPISecondary";
import DataTable from "react-data-table-component";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import Carousel from "react-bootstrap/Carousel";
import { useHistory } from "react-router-dom";
import { PACreateContext } from "../../Contexts/PAContext";
import ModalDialog from "containers/PeopleCraft/Modals/ModalDialog";

const GroupsCreator = (props) => {
  const history = useHistory();
  const goBack = useCallback(() => history.push("/user"), [history]);

  const isMountedRef = useRef(null);
  const { setPreloadInit, openConfirmModal } = useContext(PACreateContext);

  const [lista, setLista] = useState([]);
  const [currentGroup, setCurrentGroup] = useState("");
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  /*List Pagination */
  const [pageLimit, setPageLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  /* [Permission Module] - Permissions states */
  const [permissionAddUserIntoGroup, setPermissionAddUserIntoGroup] = useState(false);

  useEffect(() => {
    /* [Permission Module] - Function calls */
    getMenuOption();

    isMountedRef.current = true;
    getAvailableData(0, pageLimit);
    return () => (isMountedRef.current = false);
  }, []);

  /* [Permission Module] - Permission attrs in page by login data information */
  function getPermissionsPage(resources) {
    try {
      if (Boolean(resources[0].filter((menu) => menu.name === "Inserir usuário no grupo").length))
        setPermissionAddUserIntoGroup(true);
    } catch (error) {
      console.log("Falha ao buscar permissões de Grupos");
    }
  }

  /* [Permission Module] - Menu Options */
  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();

    const objCurrentMenu = menuOptionList.filter((menu) => menu.text === "Cadastro de Usuários");

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);

      getPermissionsPage(resources);
    }
  }

  /* Metodos para adicionar e remover grupos */
  const handleAdd = (event, type) => {
    event.preventDefault();
    if (currentGroup.length > 0) {
      setPreloadInit(true);
      createGroupSecondary(currentGroup).then((res) => {
        setPreloadInit(false);
        if (res.data.success) {
          switch (type) {
            case "manual":
              history.push(
                `/configuracao/grupos/editar/${res.data.idGroup[0]}/${currentGroup}/manual`,
              );
              break;

            case "rules":
              history.push(`/configuracao/grupos/regras/${res.data.idGroup[0]}/${currentGroup}`);
              break;
          }
        }
      });
    }
  };

  const handleDel = (group) => {
    openConfirmModal({
      text: `Você tem certeza que deseja remover o público-alvo ${group.title}?`,
      variantBtn: "danger",
      textBtn: "Remover público-alvo",
      fnc: () => {
        setPreloadInit(true);
        removeGroupSecondary(group.idGroups).then((res) => {
          if (res.data.success) {
            setLista(lista.filter((g) => g.idGroups !== group.idGroups));
          } else {
            openConfirmModal({
              text: `Não foi possível remover o público-alvo pelo seguinte motivo: ${res.data.message}`,
              alertMode: true,
            });
          }

          setPreloadInit(false);

          // if (!res.data.success && res.data.message === "existing users at group!") {
          //   setShowErrorModal(true);
          // }
        });
      },
    });
  };

  /* Configurações da tabela */
  const paginationComponentOptions = {
    rowsPerPageText: "Conteúdos por página",
    rangeSeparatorText: "de",
  };

  const onChangePage = (page) => {
    const newOffset = (page - 1) * pageLimit;
    getAvailableData(newOffset);
  };

  const onChangeRowsPerPage = (limit, page) => {
    setPageLimit(limit);
    const newOffset = (page - 1) * limit;
    getAvailableData(newOffset, limit);
  };

  /* Recuperando os grupos disponíveis. */
  const getAvailableData = async (newOffset, limit) => {
    const haveNewOffset = newOffset || newOffset === 0;
    if (haveNewOffset) {
      setOffset(newOffset);
    }

    // TODO: Estes parâmetros devem ser passados para o enpoint para o funcionamento da paginação.
    // const groupParameters = {
    //   offset: haveNewOffset ? newOffset : offset,
    //   limit: limit ? limit : pageLimit,
    // };

    setPreloadInit(true);

    const availableData = await getGroupsSecondary().catch((err) => {
      console.log("error", err);
    });

    setPreloadInit(false);

    if (availableData) {
      setLista(_.sortBy(availableData.data.groups, "title"));
      setTotalRecords(availableData.totalRecords);
    }
  };

  /* Controle da posição do Carousel de criação de novos grupos. */
  const handleSelect = (selectedIndex) => {
    setCarouselIndex(selectedIndex);
  };

  /* Colunas da tabela dos grupos já criados */
  const columns = [
    {
      name: "Código",
      selector: "idGroups",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (group) => (
        // <HighlightText value={removeHtmlTags(question.statement)} highlight={searchString} />
        <div>
          <p>{group.idGroups}</p>
        </div>
      ),
    },
    {
      name: "Público-alvo",
      sortable: false,
      selector: "title",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (group) => (
        // <HighlightText value={removeHtmlTags(question.statement)} highlight={searchString} />
        <div>
          <p>{group.title}</p>
        </div>
      ),
    },

    {
      name: "Ação",
      sortable: false,
      selector: "actions",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (group) => (
        // <HighlightText value={removeHtmlTags(question.statement)} highlight={searchString} />
        // <div>
        //   <p>{group.title}</p>
        // </div>
        <div className="groupActions" key={group.idGroups}>
          <Tooltip title="Remover público-alvo" placement="top">
            <span className="btn btn-sm float-right" onClick={() => handleDel(group)}>
              <RiDeleteBin5Line />
            </span>
          </Tooltip>
          {permissionAddUserIntoGroup && (
            <Tooltip title="Editar público-alvo" placement="top">
              <span className="btn btn-sm float-right">
                <Link to={`/configuracao/grupos/editar/${group.idGroups}/${group.title}`}>
                  <FiEdit />
                </Link>
              </span>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="groupsContainerAll">
      <div className="groupCreatorModal">
        <Modal
          show={showAddGroupModal}
          onHide={() => setShowAddGroupModal(false)}
          animation={false}
          className="groupCreatorModal"
        >
          <Modal.Header closeButton>
            <h5>Criando novo público-alvo</h5>
          </Modal.Header>
          <Modal.Body>
            <Carousel
              interval={null}
              touch={false}
              controls={false}
              indicators={true}
              activeIndex={carouselIndex}
              onSelect={handleSelect}
            >
              <Carousel.Item>
                <div className="createStep">
                  <div className="createStepContainer">
                    <h5>Novo público-alvo</h5>
                    <p>
                      Personalize seu público-alvo criando, editando e adicionando usuários
                      manualmente, além de aplicar regras utilizando campos de perfil.
                    </p>
                    <h5>Digite o nome do seu público-alvo:</h5>
                    <p className="emphasis">* Nome (obrigatório)</p>
                    <input
                      type="text"
                      className="form-control input-default"
                      id="group"
                      aria-describedby="group"
                      value={currentGroup}
                      maxlength="30"
                      onChange={(e) => setCurrentGroup(e.target.value)}
                    />

                    {currentGroup.length > 2 && (
                      <div
                        className="nextStep btnNav button-peop button-purple"
                        onClick={(e) => {
                          handleSelect(1);
                        }}
                      >
                        <spam>Próximo</spam> <GrFormNextLink />
                      </div>
                    )}
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="createStep">
                  <div className="createStepContainer">
                    <h5>Você pode incluir usuários manualmente e por regras</h5>
                    <p>
                      Personalize seu público-alvo criando, editando e adicionando usuários
                      manualmente, além de aplicar regras utilizando campos de perfil.
                    </p>

                    <div className="btns">
                      <button
                        className="button-peop button-purple"
                        onClick={(e) => {
                          handleAdd(e, "manual");
                        }}
                      >
                        Inserir manualmente
                      </button>

                      <button
                        className="button-peop button-yellow"
                        onClick={(e) => {
                          handleAdd(e, "rules");
                        }}
                      >
                        Inserir regras
                      </button>
                    </div>

                    <div
                      className="prevStep btnNav"
                      onClick={(e) => {
                        handleSelect(0);
                      }}
                    >
                      <GrFormPreviousLink /> <spam>Voltar</spam>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>

      <div className="PA-header-title-wrapper">
        <div className="PA-header-title">
          <button className="btnGoBack" onClick={goBack}>
            <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
          </button>
          Cadastro de usuário / <b>Gerenciar público-alvo</b>
        </div>
      </div>

      <div className="groupListHeader">
        <div className="infos">
          <h6 className="title">Gerenciar públicos-alvo</h6>
          <p>
            Neste espaço você pode adicionar, editar e remover seu público-alvo. Você tem a opção de
            inserir manualmente ou criar critérios para segmentar o seu público de forma mais
            eficaz.
          </p>
        </div>
        <button className="button-peop button-yellow" onClick={() => setShowAddGroupModal(true)}>
          Criar novo público-alvo
        </button>
      </div>

      <div className="groupListTableContainer">
        <DataTable
          columns={columns}
          data={lista}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationTotalRows={totalRecords}
          paginationComponentOptions={paginationComponentOptions}
          paginationServer
          noHeader
          fixedHeader
          // pagination
        />
      </div>
    </div>
  );
};

export default withRouter(GroupsCreator);
