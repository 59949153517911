import { concactStylesSVG, propsFilter } from "./concactStylesSVG";

// const filtALL = [
//   ["{Nome do aluno}", "{username}", "Nome do aluno"],
//   ["{Rótulo da página}", "{label}", "Rótulo da página"],
//   ["{Nome do treinamento}", "{titleWithParentsTitle}", "Nome do treinamento"],
//   ["{Carga horária}", "{workload}", "Carga horária"],
//   ["{Data da aprovação}", "{finishDate}", "Data da aprovação"],
//   ["{Data de início da matrícula}", "{startDate}", "Data de início da matrícula"],
//   ["{Data de conclusão da matrícula}", "{enrollDate}", "Data de conclusão da matrícula"],
//   ["{Nota de aprovação}", "{grade}", "Nota de aprovação"],
//   ["{Data de emissão}", "{today}", "Data de emissão"],
//   ["{Link de autenticidade do certificado}", "{token}", "Link de autenticidade do certificado"],
//   ["{Data de validade}", "{expirationDate}", "Data de validade"],
// ];

const structionSVG = (json) => {
  const { backgroundImage, objects, background } = json;

  const imagemSVG = (item) => {
    const { left, top, width, height, src, scaleX, scaleY, originX, originY } = item;

    const transform = `transform:scale(${scaleX},${scaleY}); transform-origin: ${originX} ${originY};`;
    const style = `style="height:100%; position:absolute; top:${top}px; left:${left}px; width:${width}px; height:${height}px; ${transform}"`;

    return `
      <foreignObject width="100%" height="100%" style="position:absolute">
        <img xmlns="http://www.w3.org/1999/xhtml" src="${src}" ${style}  />
      </foreignObject>
    `;
  };

  const textSVG = (item) => {
    const { text, left, top, width, fontSize, lineHeight, styles, scaleX } = item;

    const paragrafoMaps = () => {
      const textArray = text.split("\n");

      const obj = textArray.map((it, indice) => {
        const verifyToken = it === "{Token de autenticidade do certificado}" || it === "{token}";

        const fontSizeBase = fontSize ? fontSize : null;
        const fontSizeRes =
          styles[indice] && styles[indice][0] && styles[indice][0].fontSize
            ? styles[indice][0].fontSize
            : fontSizeBase;

        const textStyled = styles[indice] ? concactStylesSVG(styles[indice], it) : "";

        return `<p style="margin-block-start: 0em; margin-block-end: 0em;
        min-height:${fontSizeRes * lineHeight}px;
          ${verifyToken ? "word-break: break-all;" : ""}
          ${fontSizeRes ? `font-size:${fontSizeRes}px` : ""}">${textStyled}</p>`;
      });

      return obj.join("");
    };

    return `
      <foreignObject width="100%" height="100%" style="position:absolute; ${propsFilter(item)}">
        <div xmlns="http://www.w3.org/1999/xhtml" style="height:100%; position:absolute;top:${top}px;left:${left}px; width:${
          width * scaleX
        }px; ${propsFilter(item)}">
          ${paragrafoMaps()}
        </div>
      </foreignObject>
    `;
  };

  const objectsMaps = () => {
    const obj = objects.map((item, indice) => {
      return item.type === "i-text" || item.type === "textbox" ? textSVG(item) : imagemSVG(item);
    });

    return obj.join("");
  };

  const bgSVG = () => {
    return backgroundImage && backgroundImage.src
      ? `<g transform="matrix(1 0 0 1 561.5 396.5)"><image id="backgroundSVG" style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" xlink:href="${backgroundImage.src}" x="-561.5" y="-396.5" width="1124" height="794"></image></g>`
      : `<rect x="0" y="0" width="100%" height="100%" fill="${background}"></rect>`;
  };

  const calcSVG = `
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" viewBox="0 0 1124 794" style="enable-background:new 0 0 1124 794;" xml:space="preserve">
            ${bgSVG()}
            ${objectsMaps()}
        </svg>
    `;

  return calcSVG;
};

const convectFriendlyTAG = (svgElement, filtALL, syllabus, syllabusState, json) => {
  let newSVG = String(svgElement);

  filtALL.map((it) => {
    const originTAG = it[0];
    const friendlyTAG = it[1];
    newSVG = newSVG.replaceAll(originTAG, friendlyTAG);

    return it;
  });

  const { backgroundImage, background } = json;

  const w = parseInt(syllabusState.width) / syllabusState.prop;
  const h = parseInt(syllabusState.height) / syllabusState.prop;
  const top = syllabusState.y / syllabusState.prop;
  const left = syllabusState.x / syllabusState.prop;
  const style = background
    ? `display:none; background-color:${background};`
    : `display:none; background-color:#fff;`;
  const color = syllabusState.color;

  // const colorTxt = `color:${syllabusState.color};`;
  const syllabusBase = `position: absolute; width: ${w}px; height: ${h}px; top: ${top}px; left: ${left}px; overflow: hidden; color:${color};`;

  const syllabusContainer = syllabus
    ? `
    <div style="display:none; {styleContainer}">
      <div id="syllabusContainer" style="${style} {style}">
        <div style="${syllabusBase}">{syllabus}</div>
      </div>
    </div>`
    : "";

  return newSVG + syllabusContainer;
};

const validateSVG = (newSVG, filtALL) => {
  let verifyValdidate = null;

  filtALL.map((it) => {
    const verifExistencia = String(newSVG).toLowerCase().includes(it[2].toLowerCase());
    /// Verificação -> se foi colocado sem a tag foi colocada: sem o {}
    // console.log("status tag: ", verifExistencia, it[2]);

    if (verifExistencia) {
      const verifValidate = String(newSVG).indexOf(it[0]);
      /// Verificação -> se foi colocado tag dentro do {} de forma correta - se entrar no if esta incorreta

      // console.log("status tag de forma correta: ", verifExistencia, it[0]);
      if (verifValidate === -1) {
        verifyValdidate = it[0];
      }
    }

    return it;
  });

  return verifyValdidate;
};

export const convectSVG = (json, filterCertificates, syllabus, syllabusState, call) => {
  const svgElement = structionSVG(json);

  const validateStatus = validateSVG(svgElement, filterCertificates);

  if (validateStatus == null) {
    // Se não tiver erro entra nesse tratamento.
    const svgCall = convectFriendlyTAG(
      svgElement,
      filterCertificates,
      syllabus,
      syllabusState,
      json,
    );
    call(svgCall);
  } else {
    // Se tiver erro avisa por aqui
    call(validateStatus, "erro");
  }
};
