import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DescriptionIcon from "@material-ui/icons/Description";
import { RiVideoFill, RiPagesFill, RiFileEditFill } from "react-icons/ri";
import MicIcon from "@material-ui/icons/Mic";
import { AiFillFileAdd } from "react-icons/ai";
import { BsImageFill } from "react-icons/bs";
import { HiArchive } from "react-icons/hi";
import { MdFilePresent } from "react-icons/md";
import { SiGoogleforms } from "react-icons/si";
import { contentTypes, elementTypes, evaluationTypes } from "./NodeTypes";
import ContentModal from "./ContentModal";
import PreloaderBlur from "../PreloaderBlur";
import {
  addNewContent,
  getMenuData,
  postLearningEvaluations,
} from "../../../services/NexiDigitalAPI";
import NodeModal from "./NodeModal";
import { CircularProgress } from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import BannerCampaignModal from "./BannerCampaignModal";

export default function ContentOptionsPopover({
  addContentNode,
  disabled,
  linkContentToBlock,
  setSelectedContentType,
  baseNodeLevel,
  blockData,
  nodeData,
}) {
  const nodeType = nodeData?.nodeType;
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [contentModalVisible, setContentModalVisible] = useState(false);
  const [nodeModalVisible, setNodeModalVisible] = useState(false);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [currentContentNode, setCurrentContentNode] = useState(null);
  const [idContentItem, setIdContentItem] = useState(null);
  const [loadingNewContent, setLoadingNewContent] = useState(false);
  const [statusCreateLE, setCreateLearningEvaluations] = useState(false);

  const { nodeCode } = useParams();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const contentOptionStyle = {
    fontSize: 25,
    marginBottom: 5,
  };

  const contentOptionKEItemStyle = {
    fontSize: 25,
    marginTop: 10,
    marginBottom: 5,
  };

  const getLMSIdContentGroup = () => {
    const contentGroups = JSON.parse(localStorage.getItem("contentGroups"));
    const idCompany = getMenuData()?.idCompany;
    const LMSContentGroup = contentGroups.find(
      (item) => item.idCompany === idCompany && item.context === "LMS",
    ); // TODO alterar name para context
    if (!LMSContentGroup) console.log("ERROR: LMS idContentGroup não encontrado!");
    return LMSContentGroup?.idContentGroup;
  };

  const handleNewContent = (contentItem) => {
    addNewContent({
      text: "",
      description: "",
      idContentType: `${contentItem.idContentType}`,
      idContentGroup: `${getLMSIdContentGroup()}`,
    })
      .then((res) => {
        if (res.data.success) {
          const idContent = res.data.idContentItem;
          setIdContentItem(idContent);
          setContentModalVisible(true);
          handleClose();
        }
      })
      .catch((err) => {
        console.log("Erro ao criar conteúdo", err);
      })
      .finally(() => {
        setLoadingNewContent(false);
      });
  };

  const allContentOptions = [
    {
      title: "Artigo",
      type: contentTypes.ARTICLE,
      className: {},
      icon: <DescriptionIcon style={contentOptionStyle} />,
      idContentType: 5,
      elementType: elementTypes.CONTENT,
    },
    {
      title: "Avaliação de reação",
      type: contentTypes.REACTION_ASSESMENT,
      className: "KEItem",
      icon: <SiGoogleforms style={contentOptionKEItemStyle} />,
      idContentType: 0,
      elementType: elementTypes.REDIRECT,
      evaluationTypes: evaluationTypes.REACTION,
    },
    {
      title: "Avaliação de conhecimento",
      type: contentTypes.KNOWLEDGE_ASSESMENT,
      className: "KEItem",
      icon: <RiFileEditFill style={contentOptionKEItemStyle} />,
      idContentType: 0,
      elementType: elementTypes.REDIRECT,
      evaluationTypes: evaluationTypes.KNOWLEDGE,
    },
    {
      title: "Vídeo",
      type: contentTypes.VIDEO,
      className: {},
      icon: <RiVideoFill style={contentOptionStyle} />,
      idContentType: 2,
      elementType: elementTypes.CONTENT,
    },
    {
      title: "Áudio",
      type: contentTypes.AUDIO,
      className: {},
      icon: <MicIcon style={contentOptionStyle} />,
      idContentType: 1,
      elementType: elementTypes.CONTENT,
    },
    {
      title: "Pacote Html",
      type: contentTypes.HTML_PACKAGE,
      className: {},
      icon: <RiPagesFill style={contentOptionStyle} />,
      idContentType: 4,
      elementType: elementTypes.CONTENT,
    },
    {
      title: "Arquivo",
      type: contentTypes.FILE,
      className: {},
      icon: <MdFilePresent style={contentOptionStyle} />,
      idContentType: 18,
      elementType: elementTypes.CONTENT,
    },
    {
      title: "SCORM",
      type: contentTypes.SCORM,
      className: {},
      icon: <HiArchive style={contentOptionStyle} />,
      idContentType: 26,
      elementType: elementTypes.CONTENT,
    },
    {
      title: "Nova página",
      type: contentTypes.NEW_PAGE,
      className: {},
      icon: <AiFillFileAdd style={contentOptionStyle} />,
      elementType: elementTypes.NODE,
    },
    {
      title: "Imagem",
      type: contentTypes.NEW_BANNER_IMAGE,
      className: {},
      icon: <BsImageFill style={contentOptionStyle} />,
      elementType: elementTypes.BANNER_IMAGE,
    },
  ];

  const nodeTypeOrganization =
    blockData.blockType === "MasterBanner"
      ? [
          contentTypes.ARTICLE,
          contentTypes.HTML_PACKAGE,
          contentTypes.VIDEO,
          contentTypes.AUDIO,
          contentTypes.FILE,
          contentTypes.NEW_PAGE,
          contentTypes.NEW_BANNER_IMAGE,
        ]
      : [
          contentTypes.ARTICLE,
          contentTypes.HTML_PACKAGE,
          contentTypes.VIDEO,
          contentTypes.AUDIO,
          contentTypes.FILE,
          contentTypes.NEW_PAGE,
        ];

  const nodeTypeLearning =
    blockData.blockType === "MasterBanner"
      ? [
          contentTypes.SCORM,
          contentTypes.ARTICLE,
          contentTypes.HTML_PACKAGE,
          contentTypes.VIDEO,
          contentTypes.AUDIO,
          contentTypes.FILE,
          contentTypes.NEW_PAGE,
          contentTypes.REACTION_ASSESMENT,
          contentTypes.KNOWLEDGE_ASSESMENT,
          contentTypes.NEW_BANNER_IMAGE,
        ]
      : [
          contentTypes.SCORM,
          contentTypes.ARTICLE,
          contentTypes.HTML_PACKAGE,
          contentTypes.VIDEO,
          contentTypes.AUDIO,
          contentTypes.FILE,
          contentTypes.NEW_PAGE,
          contentTypes.REACTION_ASSESMENT,
          contentTypes.KNOWLEDGE_ASSESMENT,
        ];

  const filterOptions = (mustContain) => {
    const newOptions = mustContain.map((item) => {
      const findOption = allContentOptions.find((option) => option.type === item);
      return findOption;
    });

    return newOptions;
  };

  const contentOptions = () => {
    if (nodeType === "ORGANIZATION") {
      return filterOptions(nodeTypeOrganization);
    }
    if (nodeType === "LEARNING") {
      return filterOptions(nodeTypeLearning);
    }
    return allContentOptions;
  };

  const getNodeContentType = (idContentType) => {
    switch (idContentType) {
      case 1:
        return contentTypes.AUDIO;
      case 2:
        return contentTypes.VIDEO;
      case 5:
        return contentTypes.ARTICLE;
      default:
        return contentTypes.ARTICLE;
    }
  };

  const createEvaluation = (blockData, evaluationModel) => {
    setCreateLearningEvaluations(true);

    const KEData = {
      idCompany: getMenuData()?.idCompany,
      idEvaluationType: evaluationModel["indice"], /// identificador do evaluation de conhecimento
      evaluationParameters: [],
      evaluationTags: [],
      categories: [
        {
          nodeCode: nodeCode,
        },
      ],
    };

    postLearningEvaluations(KEData)
      .then((res) => {
        const idEvaluation = res.data.idEvaluation;

        setTimeout(() => {
          history.push(
            `/${evaluationModel["url"]}/${nodeCode}/${blockData?.idBlock}/${idEvaluation}/`,
          );
          setCreateLearningEvaluations(false);
        }, 1000 * 1);
      })
      .catch((err) => {
        console.log("Error na criação da avaliação de conhecimento", err.request);
        // alert("Error na criação da avaliação de conhecimento");
      })
      .finally(() => {});
  };

  return (
    <div>
      {statusCreateLE && <PreloaderBlur />}
      {contentModalVisible && (
        <ContentModal
          title="Criação de conteúdo"
          denyAction={() => setContentModalVisible(false)}
          idContentItem={idContentItem}
          returnContentData={(contentData) => {
            linkContentToBlock(contentData);
            setContentModalVisible(false);
          }}
        />
      )}
      {nodeModalVisible && (
        <NodeModal
          title="Criação de Nós"
          denyAction={() => setNodeModalVisible(false)}
          // denyAction={() => setContentModalVisible(false)}
          // idContentItem={idContentItem}
          returnNodeData={(nodeData) => {
            linkContentToBlock(nodeData);
            setNodeModalVisible(false);
          }}
        />
      )}
      {imageModalVisible && (
        <BannerCampaignModal
          title=""
          denyAction={() => setImageModalVisible(false)}
          returnNodeData={(nodeData) => {
            linkContentToBlock(nodeData);
            setImageModalVisible(false);
          }}
        />
      )}
      {disabled ? (
        <AddCircleOutlineIcon
          style={{
            fontSize: 40,
            marginBottom: 5,
            pointerEvents: "none",
            color: "gray",
          }}
        />
      ) : (
        <AddCircleOutlineIcon
          className="content-options-add"
          aria-describedby={id}
          variant="contained"
          color="primary"
          onClick={handleClick}
          style={{ fontSize: 40 }}
        />
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <div className="content-options-container">
          {contentOptions().map((contentItem, index) =>
            contentItem.elementType === elementTypes.NODE && baseNodeLevel > 5 ? null : (
              <div
                key={`content-options-${index}`}
                className={`content-options-item ${contentItem.className}`}
                style={{ pointerEvents: loadingNewContent ? "none" : "auto" }}
                onClick={() => {
                  if (contentItem.elementType === elementTypes.CONTENT) {
                    setLoadingNewContent(contentItem.type);
                    handleNewContent(contentItem);
                  } else if (contentItem.elementType === elementTypes.REDIRECT) {
                    createEvaluation(blockData, contentItem.evaluationTypes);
                  } else if (contentItem.elementType === elementTypes.NODE) {
                    setNodeModalVisible(true);
                    handleClose();
                  } else if (contentItem.elementType === elementTypes.BANNER_IMAGE) {
                    setImageModalVisible(true);
                    handleClose();
                  }
                  setCurrentContentNode(contentItem);
                  setSelectedContentType(contentItem.type);
                }}
              >
                {contentItem.type === loadingNewContent ? (
                  <CircularProgress size={25} style={{ marginBottom: 5, color: "#6d6b6b" }} />
                ) : (
                  contentItem.icon
                )}
                <span>{contentItem.title}</span>
              </div>
            ),
          )}
        </div>
      </Popover>
    </div>
  );
}
