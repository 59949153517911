export function filterContent(idContentType) {
  switch (idContentType) {
    case 1:
      return "Audio";
    case 2:
      return "Video";
    case 3:
      return "Post";
    case 4:
      return "Jornada";
    case 5:
      return "Artigo";
    case 6:
      return "Live";
    case 7:
      return "Página";
    case 8:
      return "Meeting";
    case 9:
      return "Video";
    case 18:
      return "Arquivo";
    case 26:
      return "SCORM";
    default:
      return "Conteúdo";
  }
}
