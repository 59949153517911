import React, { useState } from "react";
import Filtro from "./Filtro";
import Table from "./Table";
import ReportAcordeon from "../../../Components/ReportAcordeon";
import { listcampaignuserpoints } from "../../../../../services/NexiDigitalAPI";
import moment from "moment";

const DetailsRankingUser = ({ index }) => {
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const name = "Detalhes usuário";
  const description = `Detalhamento do usuário na campanha`;

  const actionFilter = async (data) => {
    setLoading(true);
    listcampaignuserpoints({ idCampaign: parseInt(data.idCampanha), idUser: data.idUser })
      .then(async (res) => {
        setDados(
          res.map((elem) => ({
            ...elem,
            eventDate: moment(elem.eventDate).utc().format("DD/MM/YYYY HH:mm:ss "),
          })),
        );
        setLoading(false);
      })
      .catch((err) => {
        setDados([]);
        setLoading(false);
      });
    setLoading(false);
  };

  const filter = <Filtro actionFilter={actionFilter} loading={loading} />;

  const result = <Table dados={dados} loading={loading} />;

  return (
    <ReportAcordeon
      name={name}
      description={description}
      filter={filter}
      result={result}
      index={index}
    />
  );
};

export default DetailsRankingUser;
