import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

const Comment = ({ answer, mandatory = true, indice, onHandleChange }) => {
  const [inputText, setInputText] = useState(
    answer.answerText ? answer.answerText.length : 0
  );
  const [characterLimit] = useState(4000);

  const { statement } = answer.question;
  const validateAnswerStatus = () => {
    if (mandatory) {
      if (answer.answerText) {
        answer.answerStatus = true;
      } else {
        answer.answerStatus = false;
      }
    } else {
      answer.answerStatus = true;
    }
  };

  validateAnswerStatus();
  useEffect(() => {
    onHandleChange();
  });

  const changeHandler = (e) => {
    answer.answerText = e.target.value.length > 0 ? e.target.value : null;
    setInputText(e.target.value.length);
    validateAnswerStatus();
    onHandleChange();
  };

  const checkTextAreaInit = () => {
    return answer.answerText ? answer.answerText : "";
  };

  return (
    <div className="itD single_option_comment">
      <div className="titleType">
        <div className="titleIndice">{indice}</div>
        {/* <Text component={"p"} text={statement} /> */}
        <div className="titleText">{parse(`${statement}`)}</div>
      </div>

      <div className="textAreaContainer itDChlidren">
        <textarea
          maxLength={characterLimit}
          onChange={(e) => changeHandler(e)}
          placeholder="Escreva aqui a sua resposta"
          defaultValue={checkTextAreaInit()}
        ></textarea>
        <p className="infoTextArea">{`${inputText}/${characterLimit}`}</p>
      </div>
    </div>
  );
};

export default Comment;
