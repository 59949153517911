import "./styles.modules.scss";

import React, { useState, useEffect, useContext, Fragment } from "react";
import { withRouter, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";

import { postCategoriesPromises, deleteCategoriesPromises } from "../../Fetch/EvaluationsAPI";

import { RECreateContext } from "../../Contexts/REContext";

import ModalAddSegment from "../../../Modals/ModalAddSegment";
import BreadCrumbCategories from "../../../BreadCrumbCategories";
import PreloaderBlur from "../../../PreloaderBlur";

import ModalDialog from "../../../Modals/ModalDialog";

const BreadcrumbListing = (props) => {
  const [showAddSegment, setShowAddSegment] = useState(false);
  const [showModalThash, setShowModalThash] = useState(false);
  const [thashItem, setThashItem] = useState(null);
  const [isPreloader, setPreloader] = useState(false);
  const { currentCatalog, setCurrentCatalog } = useContext(RECreateContext);

  const { idEvaluation, idBlock } = useParams();

  const addEvent = (item) => {
    const objNode = {
      nodeCode: item.nodeCode,
    };

    setPreloader(true);
    postCategoriesPromises(props.type, props.paramID, objNode, (res) => {
      console.log(res);

      setCurrentCatalog(res[1].data);
      setPreloader(false);
    });
  };

  const trashEvent = (item) => {
    setThashItem(item);
    setShowModalThash(true);
  };

  const trashConfirmEvent = () => {
    const idCategory = thashItem.idCategory;

    setShowModalThash(false);
    setPreloader(true);

    deleteCategoriesPromises(props.type, idEvaluation, idCategory, (res) => {
      setCurrentCatalog(res[1].data);
      setPreloader(false);
    });
  };

  return (
    <>
      {isPreloader && <PreloaderBlur />}
      <div className="KEForm KEFormMig">
        <label>Migalha de página atual</label>

        {idBlock !== "null" ? (
          <>
            {currentCatalog && currentCatalog.length >= 1 && (
              <BreadCrumbCategories edit={false} catalog={currentCatalog[0]} />
            )}

            {currentCatalog && currentCatalog.length > 1 && (
              <div className="listMig">
                {currentCatalog.map((item, indice) => {
                  if (indice > 0) {
                    return (
                      <Fragment key={indice}>
                        <BreadCrumbCategories
                          catalog={item}
                          trashEvent={(item) => {
                            trashEvent(item);
                          }}
                        />
                      </Fragment>
                    );
                  }
                })}
              </div>
            )}
          </>
        ) : (
          <>
            {currentCatalog && (
              <div className="listMig">
                {currentCatalog.map((item, indice) => {
                  if (indice > 0) {
                    return (
                      <Fragment key={indice}>
                        <BreadCrumbCategories
                          catalog={item}
                          trashEvent={(item) => {
                            trashEvent(item);
                          }}
                        />
                      </Fragment>
                    );
                  }
                })}
              </div>
            )}
          </>
        )}

        <div className="btnSeguimentacaoBase">
          <Button
            variant="warning"
            className=""
            onClick={() => {
              setShowAddSegment(true);
            }}
          >
            Adicionar categoria
          </Button>
        </div>
      </div>
      {showAddSegment && (
        <ModalAddSegment
          denyAction={() => setShowAddSegment(false)}
          blockData={currentCatalog}
          returnNodeData={addEvent}
        />
      )}

      <ModalDialog
        text={"Tem certeza de que deseja deletar essa categoria?"}
        show={showModalThash}
        setShow={setShowModalThash}
        btnCall={{
          className: "",
          variantBtn: "danger",
          text: "Deletar",
          callEvent: () => {
            trashConfirmEvent();
          },
        }}
      />
    </>
  );
};

export default withRouter(BreadcrumbListing);
