import { removeHtmlTags, scrollToTop, dataEvaluation } from "utils/Utils";

import {
  saveAndAddEvaluation,
  editLearningUniqueQuestion,
  getLearningQuestionCategories,
  getLearningUniqueQuestion,
  postQuestion,
  editQuestion,
} from "services/NexiDigitalAPI";

export const getEditQuestion = (
  setLoadingQuestion,
  setStatement,
  setTypeQuestion,
  setQuestionOptions,
  setQuestionScales,
  setTagsEnd,
  setCategories,
  idQuestion,
) => {
  setLoadingQuestion(true);
  getLearningUniqueQuestion(idQuestion)
    .then((res) => {
      const questionData = res.data;

      setStatement(questionData.statement);
      setTypeQuestion(questionData.questionType);
      setQuestionOptions(questionData.questionOptions ? questionData.questionOptions : []);
      setQuestionScales(questionData.questionScales ? questionData.questionScales : []);
      setTagsEnd(questionData.questionTags);
      setLoadingQuestion(false);
    })
    .catch((err) => {
      console.log("error", err);
    });

  getLearningQuestionCategories(idQuestion)
    .then((res) => {
      setCategories(res.data);
    })
    .catch((err) => {
      console.log("error", err);
    });
};

export const saveSetQuestion = (
  closeModal = true,
  idQuestion,
  setLoadingUpdateContent,
  setLoadingUpdateContentRefresh,
  profileInfo,
  statement,
  tagsEnd,
  categories,
  questionData,
  idEvaluation,
  typeQuestion,
  setQuestionsList,
  handleDeny,
  clearForm,
  questionsList,
  evaluationModel,
  returnContentData,
) => {
  if (closeModal) {
    setLoadingUpdateContent(true);
  } else {
    setLoadingUpdateContentRefresh(true);
  }

  const tagsMap = tagsEnd.map((it) => {
    return { idTag: it.id || it.idTag };
  });

  const saveQuestionParameters = {
    idCompany: profileInfo.idCompany,
    statement: statement,
    categories,
    questionTags: tagsMap,
    // idQuestionType: idQuestionTypeCurrent(typeQuestion),
    idEvaluationType: dataEvaluation(evaluationModel.evaluacionType).idEvaluationType,
    ...questionData(),
  };

  if (!idQuestion) {
    ///Quando a Criação do Questão esta vinculada a uma avaliação especifica
    if (setQuestionsList) {
      saveAndAddEvaluation(idEvaluation, saveQuestionParameters)
        .then((res) => {
          const newQuestionObject = {
            idQuestion: res.data.idQuestion,
            statement: removeHtmlTags(statement),
            questionType: typeQuestion,
          };

          setQuestionsList((prevQuestionList) => [...prevQuestionList, newQuestionObject]);

          if (closeModal) {
            handleDeny();
          } else {
            clearForm();
            scrollToTop(".ModalCreateQuestion");
          }

          setLoadingUpdateContent(false);
          setLoadingUpdateContentRefresh(false);

          if (returnContentData) returnContentData();
        })
        .catch((err) => {
          console.log("error!", err);
          setLoadingUpdateContent(false);
          setLoadingUpdateContentRefresh(false);
        });
    } else {
      const createQuestionParameters = {
        ...saveQuestionParameters,
        idEvaluationType: dataEvaluation(evaluationModel.evaluacionType).idEvaluationType,
      };

      postQuestion(createQuestionParameters).then((res) => {
        clearForm();
        handleDeny();
        setLoadingUpdateContent(false);
        setLoadingUpdateContentRefresh(false);

        if (returnContentData) returnContentData();
      });
    }
  } else {
    setLoadingUpdateContentRefresh(true);
    const editQuestionParameters = {
      ...saveQuestionParameters,
      idQuestion: idQuestion,
    };

    ///Quando a Criação do Questão esta vinculada a uma avaliação especifica
    if (setQuestionsList) {
      editLearningUniqueQuestion(idQuestion, editQuestionParameters)
        .then(() => {
          const newQuestionsList = questionsList.map((item) => {
            if (item.idQuestion === idQuestion) item.statement = editQuestionParameters.statement;
            return item;
          });
          setQuestionsList(newQuestionsList);

          handleDeny();
          setLoadingUpdateContent(false);
          setLoadingUpdateContentRefresh(false);

          if (returnContentData) returnContentData();
        })
        .catch((err) => {
          console.log("error", err);
        });
    } else {
      editQuestion(idQuestion, editQuestionParameters).then((res) => {
        clearForm();
        handleDeny();
        setLoadingUpdateContent(false);
        setLoadingUpdateContentRefresh(false);

        if (returnContentData) returnContentData();
      });
    }
  }
};
