import { MdRocketLaunch } from "react-icons/md";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import Button from "../Button";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalLoadingInfo from "../Modais/ModalLoadingInfo";
import { openModalUpgrade } from "utils/control-modal";
import { ActivePlan } from "context/AuthContext";

type Props = {
  text?: string;
  hide?: boolean;
  css?: object;
  className?: string;
  numberRequest: number;
  hideNotification: boolean;
  userIsAdmin: boolean;
  updateLicense?: any;
  hasRequestedUpgrade?: boolean;
  disableRuleIconModal?: boolean;
  callEvent?: React.MouseEventHandler<HTMLButtonElement> | null;
  isUpgradeButtonActive?: Boolean;
  activePlan?: ActivePlan
};

const ContractPlan = ({
  text = "Conheça a LMS/LXP by Afferolab",
  hide = false,
  numberRequest,
  hideNotification = false,
  userIsAdmin = false,
  hasRequestedUpgrade = false,
  disableRuleIconModal = false,
  updateLicense,
  isUpgradeButtonActive,
  callEvent,
  activePlan,
  ...props
}: Props) => {
  const modalLoading = useModal("ModalLoadingInfo");
  const {deadline} = activePlan
  NiceModal.register("ModalLoadingInfo", ModalLoadingInfo);
  const openModal = () => {
    openModalUpgrade({
      hasRequestedUpgrade,
      userIsAdmin,
      numberRequest,
   });
  };

  const handleClick = (e) => {
    openModal();
    if (callEvent) {
      callEvent(e);
    }
    openModal();
  };


  return (
    <>
      {isUpgradeButtonActive && !hide && (
        <div className="upgradeTrial">
          <Button
             callEvent={(e) => handleClick(e)}
            className={`button07 buttonDefault  ${
              !disableRuleIconModal && "buttonIconText"
            }`}
            ico={MdRocketLaunch}
            text={`${deadline === 1 ? 'Resta' : 'Restam'} <span>${deadline} ${deadline === 1 ? 'dia' : 'dias'}`}
          />
        </div>
      )}
    </>
  );
};

export default ContractPlan;
