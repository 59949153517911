import "./styles.scss";
import React, { useState, useEffect } from "react";

import {
  getMenuData,
  getCompaniesByIdApp,
  postMigrateUsers,
  loginSecondary,
} from "../../../services/NexiDigitalAPI";
import { getUsersSecondary } from "../../../services/NexiDigitalAPISecondary";
import MovementWrapper from "../Components/MovementWrapper";
import { Modal, ModalBody } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import StepComponent from "./Components/StepComponent";
import TableComponent from "../Components/TableComponent";
import StepComponentSecond from "./Components/StepComponentSecond";
import SemImagemSmall from "../../../assets/images/sem-imagem-small.jpg";
import transferIcon from "../../../assets/images/transfer.svg";
import StepComponentQuarter from "./Components/StepComponentQuarter";
import EditIcon from "@material-ui/icons/Edit";

const columns = [
  {
    name: "Nome",
    selector: "name",
    sortable: true,
    center: true,
    wrap: true,
    grow: 0.3,
    style: { textAlign: "center" },
    cell: (data, index) => (
      <div className="profile-container">
        <img alt="avatar" className="avatar" src={data.photo || SemImagemSmall} />
        <p className="profile-name">{data.name || "-"}</p>
      </div>
    ),
  },
  {
    name: "E-mail",
    sortable: false,
    selector: "E-mail",
    center: true,
    wrap: true,
    style: { textAlign: "center" },
    cell: (data, index) => <p>{data.email || "-"}</p>,
  },
  {
    name: "Cargo",
    sortable: false,
    selector: "Cargo",
    center: true,
    wrap: true,
    style: { textAlign: "center" },
    cell: (data, index) => <p>{data.role || "-"}</p>,
  },
  {
    name: "Ativo",
    sortable: false,
    selector: "Ativo",
    center: true,
    wrap: true,
    width: "200px",
    style: { textAlign: "center" },
    cell: (data, index) => <p>{data.ativo || "-"}</p>,
  },
];

const CompaniesColumns = [
  {
    name: "Nome da empresa",
    selector: "name",
    width: "70%",
    sortable: false,
    center: true,
    wrap: true,
    style: { textAlign: "center" },
    reorder: false,
    cell: (d) => <>{d.name || "-"}</>,
  },
  {
    name: "CNPJ",
    selector: "cnpj",
    sortable: false,
    center: true,
    wrap: true,
    width: "30%",
    style: { textAlign: "center" },
  },
];

const customTableStyles = {
  table: {
    style: {
      maxHeight: "500px",
      overflow: "auto",
    },
  },
  rows: {
    style: {
      minHeight: "30px",
    },
    selectedRow: {
      backgroundColor: "#6b48ff",
    },
  },
  headCells: {
    style: {
      paddingLeft: "30px",
      textAlign: "center",
    },
  },
  cells: {
    style: {
      paddingLeft: "30px",
      color: "#3e4246",
    },
  },
  header: {
    style: {
      fontSize: "22px",
      minHeight: "56px",
      paddingLeft: "28px",
      paddingRight: "8px",
      paddingTop: "30px",
      paddingBottom: "15px",
      color: "white",
      backgroundColor: "#6b48ff",
    },
  },
  pagination: {
    options: {
      select: {
        color: "white", // set checkbox color
      },
    },
  },
};

const paginationComponentOptions = {
  rowsPerPageText: "Empresas por página",
  rangeSeparatorText: "de",
};

const steps = [
  { label: "Escolha a empresa de origem", buttonText: "Selecionar empresa", canClick: true },
  { label: "Escolha o usuário que deseja movimentar" },
  {
    label: "Escolha a empresa de destino",
    buttonText: "Selecionar empresa destino",
    canClick: true,
  },
  { label: "Resumo da movimentação" },
];

export default function MoveUsers({ setChooseTab }) {
  const { idApp, idChannel, idAppType } = getMenuData();
  const [showModal, setShowModal] = useState(false);
  const [processingLoading, setProcessingLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedOriginCompany, setSelectedOriginCompany] = useState({});
  const [selectedTargetCompany, setSelectedTargetCompany] = useState({});
  const [tableData, setTableData] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
    totalPage: 999,
  });
  const [userPagination, setUserPagination] = useState({
    offset: 0,
    limit: 10,
    totalPage: 999,
    searchText: "",
  });

  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [selectedUsersToMigration, setSelectedUsersToMigration] = useState({});

  const stepCallBack = (index) => {
    setShowModal(true);
    setProcessingLoading(false);
    if (index === 0 && activeStep !== index) {
      setModalTitle("Selecionar empresa origem");
      setActiveStep(0);
      setCompanyUsers([]);
    } else if (activeStep === 0) {
      setModalTitle("Selecionar empresa origem");
    } else if (activeStep === 2) {
      setModalTitle("Selecionar empresa destino");
      setTableData(
        tableData.filter((company) => company.idCompany !== selectedOriginCompany.idCompany),
      );
    }
  };

  const resetSteps = () => {
    setActiveStep(0);
    setCompanyUsers([]);
    setTableData([]);
    setSelectedOriginCompany({});
    setSelectedTargetCompany({});
    setSelectedRowsData({});
    setSelectedUsersToMigration({});
    setProcessingLoading(false);
    setShowModal(false);
  };

  function _getCompany(e, customPaginate) {
    e && e.preventDefault();
    customPaginate && setPagination(customPaginate);
    const paginationConfig = customPaginate || pagination;

    setLoadingCompany(true);
    getCompaniesByIdApp(idApp, paginationConfig, searchText)
      .then((res) => {
        if (res.data.success === true) {
          setTableData(res.data.companies);
          setPagination({
            ...paginationConfig,
            totalPage: res.data.totalRecords,
          });
        }
      })
      .catch((err) => {
        console.log("error ", err);
      })
      .finally(() => {
        setLoadingCompany(false);
      });
  }

  const onChangeUserPage = (page) => {
    const offset = page === 1 ? 0 : (page - 1) * userPagination.limit;
    setUserPagination((prev) => ({
      ...prev,
      offset,
    }));
    _getUsers({
      ...userPagination,
      offset,
      searchText: userPagination.searchText,
    });
  };

  const onChangeUserRowsPerPage = (limit, page) => {
    const offset = page === 1 ? 0 : (page - 1) * limit;
    setUserPagination((prev) => ({
      ...prev,
      limit,
      offset,
    }));
    _getUsers({
      ...userPagination,
      limit,
      offset,
      searchText: userPagination.searchText,
    });
  };

  const _getUsers = async (customPagination) => {
    setLoadingUsers(true);

    const paginationConfig = customPagination || userPagination;

    const queryParams = {
      offset: paginationConfig.offset,
      limit: paginationConfig.limit,
      searchString: paginationConfig.searchText,
    };

    setUserPagination({
      offset: paginationConfig.offset,
      limit: paginationConfig.limit,
      searchText: paginationConfig.searchText,
    });

    await getUsersSecondary(queryParams)
      .then(({ data }) => {
        if (data.success) {
          setUserPagination((prev) => ({
            ...paginationConfig,
            totalPage: data.totalRecords,
          }));
          setCompanyUsers(data.userList);
        } else {
          setCompanyUsers([]);
        }
      })
      .catch((e) => console.log("Erro ao listar usuarios", e))
      .finally(() => {
        setLoadingUsers(false);
      });
  };

  const onChangePage = (page) => {
    _getCompany(null, {
      ...pagination,
      offset: page === 1 ? 0 : (page - 1) * pagination.limit,
      page,
    });
  };

  const onChangeRowsPerPage = (limit, page) => {
    _getCompany(null, {
      ...pagination,
      limit,
      offset: page === 1 ? 0 : (page - 1) * limit,
      page,
    });
  };

  const getPaginationRowsPerPageOptions = (totalRows) => {
    const options = [];
    if (totalRows <= 10) {
      options.push(totalRows);
      return options;
    }

    for (let i = 10; i < totalRows; i += 10) {
      options.push(i);
    }
    options.push(totalRows);
    return [...new Set(options)];
  };

  const onRowClicked = (company) => {
    if (activeStep === 0) {
      setLoadingUsers(true);
      resetSteps();
      setSelectedOriginCompany(company);
      setShowModal(false);
      const loginSecondaryData = {
        idCompany: company.idCompany,
        idApp,
        idChannel: idChannel,
        idAppType: idAppType,
      };

      loginSecondary(loginSecondaryData)
        .then((res) => {
          localStorage.setItem("token-secondary", res.data.token);
          _getUsers();
        })
        .catch((err) => {
          setLoadingUsers(false);
          console.log("err", err);
        });
    } else {
      if (company.idCompany === selectedOriginCompany.idCompany) {
        alert("Ops! O destino é igual à origem. Por favor, selecione um destino diferente");
        return;
      }
      setSelectedTargetCompany(company);
      scrollToComponent(".step-component-quarter");
    }

    setShowModal(false);
    if (activeStep + 1 < steps.length) {
      setActiveStep(activeStep + 1);
    }
  };

  useEffect(() => {
    if (showModal && !tableData.length) {
      _getCompany();
    }
  }, [showModal]);

  const scrollToComponent = (elementId) => {
    const component = document.querySelector(elementId);
    if (component) {
      component.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const doMigration = async () => {
    setProcessingLoading(true);
    setModalTitle();
    setShowModal(true);
    if (selectedUsersToMigration.selectedRows && selectedUsersToMigration.selectedRows.length > 0) {
      const users = [...new Set(selectedUsersToMigration.selectedRows.map((user) => user.idUser))];
      await postMigrateUsers({
        idCompanySource: selectedOriginCompany.idCompany,
        idCompanyDestination: selectedTargetCompany.idCompany,
        users,
      });
    }
  };

  const renderSteps = (step, index) => {
    if (index === 1) {
      return (
        <StepComponentSecond
          className="step-component-second"
          key={index}
          index={index}
          label={step.label}
          isActive={activeStep >= index}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          numberOfSteps={steps.length}
          onSubmit={_getUsers}
          onChangePage={onChangeUserPage}
          onChangeRowsPerPage={onChangeUserRowsPerPage}
          userPagination={userPagination}
          paginationComponentOptions={paginationComponentOptions}
          loadingUsers={loadingUsers}
          tableColumn={columns}
          tableData={companyUsers}
          customTableStyles={customTableStyles}
          NoDataComponent={<NoDataComponent />}
          setSelectedRowsData={setSelectedRowsData}
          selectedRowsData={selectedRowsData}
          companyTarget={selectedTargetCompany}
          getPaginationRowsPerPageOptions={getPaginationRowsPerPageOptions}
        />
      );
    }

    if (index === 0 || index === 2) {
      return (
        <StepComponent
          key={index}
          index={index}
          label={<strong>{step.label}</strong>}
          isActive={
            (index !== 2 && activeStep >= index) ||
            (index === 2 && selectedRowsData.selectedCount > 0)
          }
          setActiveStep={setActiveStep}
          numberOfSteps={steps.length}
        >
          {step.canClick ? (
            <>
              {index === 0 && selectedOriginCompany && selectedOriginCompany?.name ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    marginLeft: 7,
                  }}
                  onClick={() => stepCallBack(index)}
                >
                  <input
                    type="text"
                    className="form-control"
                    id="edit-input"
                    value={selectedOriginCompany.name}
                    readOnly
                    style={{ paddingRight: "30px", cursor: "pointer" }}
                  />
                  <EditIcon
                    fontSize="small"
                    className="edit-icon"
                    style={{
                      position: "absolute",
                      right: "10px",
                      pointerEvents: "none",
                      color: "#6b48ff",
                    }}
                  />
                </div>
              ) : (
                <>
                  {index === 2 && selectedTargetCompany && selectedTargetCompany?.name ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        marginLeft: 7,
                      }}
                      onClick={() => stepCallBack(index)}
                    >
                      <input
                        type="text"
                        className="form-control select-company-destination-edit"
                        value={selectedTargetCompany.name}
                        readOnly
                        disabled={selectedRowsData.selectedCount === 0}
                      />
                      <EditIcon
                        fontSize="small"
                        className="edit-icon"
                        style={{
                          position: "absolute",
                          right: "10px",
                          pointerEvents: "none",
                          color: "#6b48ff",
                        }}
                      />
                    </div>
                  ) : (
                    <button
                      onClick={() => stepCallBack(index)}
                      className="select-company-destination button-yellow button button-default"
                      style={{ marginLeft: "10px" }}
                      disabled={
                        (index === 2 && activeStep !== index) ||
                        (index === 2 && selectedRowsData.selectedCount === 0)
                      }
                    >
                      {!loadingCompany && <strong>{step.buttonText}</strong>}
                      {loadingCompany && <CircularProgress size={18} style={{ color: "white" }} />}
                    </button>
                  )}
                </>
              )}
            </>
          ) : null}
        </StepComponent>
      );
    }

    if (index === 3) {
      return (
        <StepComponentQuarter
          key={index}
          index={index}
          label={step.label}
          isActive={activeStep >= index && selectedRowsData.selectedCount > 0}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          numberOfSteps={steps.length}
          paginationComponentOptions={paginationComponentOptions}
          loading={loadingCompany}
          tableColumn={columns}
          tableData={selectedRowsData.selectedRows}
          customTableStyles={customTableStyles}
          NoDataComponent={<NoDataComponent />}
          setSelectedUsersToMigration={setSelectedUsersToMigration}
          selectedUsersToMigration={selectedUsersToMigration}
          companyTarget={selectedTargetCompany}
          companyOrigin={selectedOriginCompany}
          addUser={scrollToComponent}
          getPaginationRowsPerPageOptions={getPaginationRowsPerPageOptions}
        />
      );
    }
  };

  const onHide = () => {
    setShowModal(false);
    if (activeStep === 0) {
      resetSteps();
    }

    if (processingLoading) {
      resetSteps();
    }
  };

  const NoDataComponent = () => {
    if ((loadingUsers || loadingCompany) && tableData.length === 0) {
      return <CircularProgress size={18} style={{ color: "white" }} />;
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 400,
          width: "100%",
          color: "gray",
        }}
      >
        Não foi encontrado nenhum dado
      </div>
    );
  };

  const ProcessingTitle = ({ svg, children }) => (
    <div className={`columnItem`}>
      <div className="circle">
        <img className="icone" src={transferIcon} alt="Transferência entre empresas" />
      </div>
      <h3>{children}</h3>
    </div>
  );

  const ProcessingSubtitle = ({ children }) => (
    <div className="subtitle">
      <p>{children}</p>
    </div>
  );

  const Footer = ({ children, className }) => <div className={`footer`}>{children}</div>;

  const goToMoveUsersHistory = () => {
    // setChooseTab(1);
    resetSteps();
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={onHide}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size={!processingLoading ? "xl" : ""}
        className="modal-main-container"
      >
        <div className="modal-support-blur" />
        {!processingLoading ? (
          <ModalBody className="modal-content">
            {modalTitle && (
              <Modal.Header className="custom-header">
                <Modal.Title>{modalTitle}</Modal.Title>
              </Modal.Header>
            )}
            <TableComponent
              onSubmit={_getCompany}
              onRowClicked={onRowClicked}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              paginationComponentOptions={paginationComponentOptions}
              loading={loadingCompany}
              tableColumn={CompaniesColumns}
              tableData={
                activeStep >= 1
                  ? tableData.filter(
                      (company) => company.idCompany !== selectedOriginCompany.idCompany,
                    )
                  : tableData
              }
              customTableStyles={{}}
              NoDataComponent={<NoDataComponent />}
              paginationTotalRows={pagination.totalPage}
              pagination
              loadingDataFallBackText="Carregando Empresas..."
              searchPlaceholder="Nome da empresa"
              setSearchText={setSearchText}
              searchText={searchText}
              extraTableProps={{
                paginationRowsPerPageOptions: getPaginationRowsPerPageOptions(pagination.totalPage),
                paginationDefaultPage: 1,
              }}
            />
          </ModalBody>
        ) : (
          <div className="processing-dialog">
            <ProcessingTitle>
              <p> Estamos processando... </p>
            </ProcessingTitle>
            <ProcessingSubtitle>
              Está operação pode levar alguns minutos. Você poderá consulta-la na aba{" "}
              <span> "Histórico de Movimentações".</span>
            </ProcessingSubtitle>

            <Footer>
              <button
                onClick={goToMoveUsersHistory}
                className="move-users-button button-yellow button button-default"
              >
                <strong>{"Ok entendi"}</strong>
              </button>
            </Footer>
          </div>
        )}
      </Modal>
      <MovementWrapper>
        <div>
          <h2>Movimentar usuários</h2>
          <span className="main-subtitle">
            Aqui você poderá trocar os usuários entre empresas. Ao realizar essa movimentação o
            histórico de conclusão do usuário também será transferido
          </span>
          <div className="step-container">
            {steps.map((step, index) => renderSteps(step, index))}
          </div>
        </div>
        <div
          style={{
            alignItems: "center",
            justifyContent: "right",
            display: "flex",
            position: "relative",
          }}
        >
          {activeStep === 3 && (
            <button
              onClick={() => resetSteps()}
              className="cancelar-button button button-default"
              style={{
                marginLeft: "10px",
                background: "white",
                border: "1px solid #ccc",
                borderRadius: "12px",
                padding: "8px 12px",
                color: "#333",
                fontWeight: "bold",
              }}
            >
              <strong>{"Cancelar"}</strong>
              {loadingCompany && <CircularProgress size={18} style={{ color: "white" }} />}
            </button>
          )}
          {activeStep === 3 && (
            <button
              onClick={() => {
                doMigration();
              }}
              className="move-users-button button-yellow button button-default move-users-button"
              disabled={
                processingLoading ||
                !selectedUsersToMigration.selectedRows ||
                selectedUsersToMigration.selectedRows.length === 0
              }
            >
              {!processingLoading && <strong>Finalizar e mover usuários selecionados</strong>}
              {processingLoading && (
                <strong>
                  <CircularProgress size={18} style={{ color: "white" }} />
                </strong>
              )}
            </button>
          )}
        </div>
      </MovementWrapper>
    </>
  );
}
