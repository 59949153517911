import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

function FormField({ value, name, handlerChange, required, type, suggestions }) {
  const fieldId = `id_${name}`;
  const isTextArea = type === "textarea";
  const tag = isTextArea ? "textarea" : "input";
  const hasValue = Boolean(value.length);
  const hasSuggestions = Boolean(suggestions.length);

  return (
    <div className={styles.FormFieldWrapper}>
      <label className={styles.FormFieldLabel} htmlFor={fieldId}>
        <input
          className={styles.FormFieldInput}
          as={tag}
          id={fieldId}
          type={type}
          name={name}
          onChange={handlerChange}
          required={required}
          value={value}
          hasValue={hasValue}
          autoComplete={hasSuggestions ? "off" : "on"}
          list={hasSuggestions ? `suggestionFor_${fieldId}` : undefined}
        />
        {hasSuggestions && (
          <datalist id={`suggestionFor_${fieldId}`}>
            {suggestions.map((suggestion, index) => (
              <option
                value={suggestion}
                key={`suggestionFor_${fieldId}_option_${suggestion}_${index}`}
              >
                {suggestion}
              </option>
            ))}
          </datalist>
        )}
      </label>
    </div>
  );
}

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handlerChange: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string,
  suggestions: PropTypes.arrayOf(PropTypes.string),
};

FormField.defaultProps = {
  required: false,
  handlerChange: () => {},
  type: "input",
  suggestions: [],
};

export default FormField;
