// Basic
import React, { useEffect } from "react";
import Content from "./SubReports/Content";

import NonAccess from "./SubReports/NonAccess";
import Commented from "./SubReports/Commented";
import TopConcluded from "./SubReports/TopConcluded";
import TopTypeConcluded from "./SubReports/TopTypeConcluded";
import TopContentaccess from "./SubReports/TopContentaccess";
import LastContentaccess from "./SubReports/LastContentaccess";
import AcessInteration from "./SubReports/AcessInteration";
import ReportWrapper from "../Components/ReportWrapper";
import ReportListTitle from "../Components/ReportListTitle";
import UsersAccess from "./SubReports/UsersAccess";
import Tags from "./SubReports/Tags";

const ReportsContent = ({ rel }) => {
  const reportList = [
    (index, expanded) => <TopContentaccess index={index} expanded={expanded} key={index} />,
    (index, expanded) => <Content index={index} expanded={expanded} key={index} />,
    (index, expanded) => <Commented index={index} expanded={expanded} key={index} />,
    (index, expanded) => <LastContentaccess index={index} expanded={expanded} key={index} />,
    (index, expanded) => <NonAccess index={index} expanded={expanded} key={index} />,
    (index, expanded) => <AcessInteration index={index} expanded={expanded} key={index} />,
    (index, expanded) => <TopConcluded index={index} expanded={expanded} key={index} />,
    (index, expanded) => <TopTypeConcluded index={index} expanded={expanded} key={index} />,
    (index, expanded) => <UsersAccess index={index} expanded={expanded} key={index} />,
    (index, expanded) => <Tags index={index} expanded={expanded} key={index} />,
  ];

  return (
    <ReportWrapper>
      <ReportListTitle>Lista de Relatórios</ReportListTitle>
      {reportList.map((report, index) => {
        const expand = index.toString() === rel;
        return report(index, expand);
      })}
    </ReportWrapper>
  );
};

export default ReportsContent;
