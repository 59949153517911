import "./styles.scss";

import React, { useState, useContext, useEffect } from "react";
import { Tooltip, withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import HelpIcon from "@material-ui/icons/Help";

import { Field, ErrorMessage } from "formik";
import { RECreateContext } from "../../Contexts/REContext";
import { convert } from "../../Utils/ParametersEvaluation";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "12px",
  },
}))(Tooltip);

const questioAvaInfo =
  "Defina quantas questões serão exibidas para quem irá responder à avaliação. Se você definir um número menor de questões do que o total cadastrado, elas serão exibidas de forma aleatória.";
const numTentaInfo = "Número máximo de tentativas no intervalo de 1 a 99.";
const pesoInfo = "Define um peso para a avaliação quando o curso possui 2 ou mais avaliações.";
const notaInfo = "Defina um valor no intervalo de 10 a 100.";
const tempoInfo = "Tempo máximo, em minutos, de realização da avaliação.";
const blockInfo =
  "Ao ativar essa funcionalidade, o avanço para a próxima questão só será possível após a conclusão da questão atual.";

const GeneralSettingsKnowledge = (props) => {
  const { evaluationForm } = props;
  const { currentEvaluation, setCurrentEvaluation, questionsList } = useContext(RECreateContext);
  const [noTimeLimitStatus, setNoTimeLimit] = useState(null);
  const [timeLimit, setTimeLimit] = useState(null);
  const [qAmount, setQuestionAmount] = useState(null);
  const [qShuffleQuestions, setShuffleQuestions] = useState(null);
  const [shuffleOptions, setShuffleOptions] = useState(false);

  const disableEdit = currentEvaluation?.hasQuestionnaire;

  const handleChange = React.useCallback((event) => {
    evaluationForm.current?.handleChange(event);

    const currentTarget = event.currentTarget;
    const target = event.target;
    setTimeout(() => {
      if (currentTarget.value) {
        setCurrentEvaluation((it) => ({
          ...it,
          [target.name]: currentTarget.value,
        }));

        if (target.name === "shuffleQuestions") {
          const shuffleQuestions = convert(currentTarget.value, null, "1|0");
          setShuffleQuestions(shuffleQuestions);
        }
        if (target.name === "shuffleOptions") {
          setShuffleOptions(convert(currentTarget.value, null, "1|0"));
        }
      }
    }, 1000 * 0.1);

    if (target.name === "timeLimit") setTimeLimit(currentTarget.value);
    if (target.name === "questionAmount") setQuestionAmount(currentTarget.value);
  }, []);

  useEffect(() => {
    /// Verificação da validação do Time limit inicial
    if (currentEvaluation && currentEvaluation.rootGetParamInit && noTimeLimitStatus === null) {
      setNoTimeLimit(currentEvaluation.rootGetParamInit.timeLimit === 0 ? true : false);
      setTimeLimit(currentEvaluation.rootGetParamInit.timeLimit);
    }
    /// Verificação da validação do Time limit durante o preenchimento
    if (currentEvaluation && currentEvaluation.noTimeLimit) {
      const convertNoTimeLimit = convert(currentEvaluation.noTimeLimit, null, "1|0");
      setNoTimeLimit(convertNoTimeLimit);
      setTimeLimit(convertNoTimeLimit ? 0 : 1);
    }

    /// Verificação da validação do QuestionAmount inicial
    if (currentEvaluation && currentEvaluation.rootGetParamInit && qAmount === null) {
      setQuestionAmount(currentEvaluation.rootGetParamInit.questionAmount);
    }
    /// Verificação da validação do QuestionAmount durrante a add de questoes
    if (questionsList.length >= 1) {
      if (evaluationForm.current.values.questionAmount > questionsList.length) {
        evaluationForm.current.values.questionAmount = questionsList.length;
        setQuestionAmount(questionsList.length);
      }
    }

    /// Verificação da validação do shuffleQuestions inicial
    if (currentEvaluation && currentEvaluation.rootGetParamInit && qShuffleQuestions === null) {
      setShuffleQuestions(currentEvaluation.rootGetParamInit.shuffleQuestions);
    }

    if (currentEvaluation && currentEvaluation.rootGetParamInit && !shuffleOptions) {
      setShuffleOptions(currentEvaluation.rootGetParamInit?.shuffleOptions);
    }

    if (qAmount < questionsList.length) {
      setShuffleQuestions(false);
    }
  }, [currentEvaluation.noTimeLimit, currentEvaluation.rootGetParamInit, questionsList, qAmount]);

  return (
    <div className={`KEConfigGeral ${disableEdit ? "disableEdit" : ""}`}>
      <div className="KEForm KEFormConfig">
        <p>Critérios de exibição</p>

        <div className="mr-3">
          <div className="kEInfoLabel">
            <label>Questões exibidas na avaliação</label>
            <CustomTooltip title={questioAvaInfo} placement="top">
              <HelpIcon style={{ fontSize: 20, cursor: "pointer" }} />
            </CustomTooltip>
          </div>

          {qAmount !== null && (
            <Field
              type="number"
              min={1}
              max={questionsList.length}
              className="form-control"
              id="realizationPeriod"
              aria-describedby="Quantidade"
              placeholder="1"
              name="questionAmount"
              title="Questões exibidas na avaliação"
              form="novalidatedform"
              value={qAmount}
              onChange={handleChange}
              disabled={disableEdit}
            />
          )}
          <div className="character-counter">
            <small>
              {evaluationForm.current && evaluationForm.current.values.questionAmount
                ? evaluationForm.current.values.questionAmount
                : 0}
              {`/${questionsList.length} questões cadastradas`}
            </small>
          </div>
          <ErrorMessage
            name="questionAmount"
            render={(msg) => <div className={"errorMessage"}>{msg}</div>}
          />
        </div>

        <div
          className={`KEInputContainer ${
            evaluationForm.current &&
            evaluationForm.current.values.questionAmount < questionsList.length
              ? "shuffleQuestionsDisabled"
              : ""
          }`}
        >
          {qShuffleQuestions !== null && (
            <Field
              as="input"
              type="checkbox"
              name="shuffleQuestions"
              onChange={handleChange}
              checked={qShuffleQuestions}
              disabled={
                (evaluationForm.current &&
                  evaluationForm.current.values.questionAmount < questionsList.length) ||
                disableEdit
              }
            />
          )}
          <label htmlFor="shuffleQuestions">Exibir questões de forma aleatória</label>
        </div>
        <div className="KEInputContainer">
          <Field
            as="input"
            type="checkbox"
            name="shuffleOptions"
            checked={shuffleOptions}
            onChange={handleChange}
          />
          <label htmlFor="shuffleOptions">Exibir as alternativas de múltipla escolha de forma aleatória.</label>
        </div>
        <div className="KEInputContainer">
          <Field
            as="input"
            type="checkbox"
            name="showResponseSummary"
            onChange={handleChange}
            disabled={disableEdit}
          />
          <label htmlFor="showResponseSummary">Exibir gabarito ao final da avaliação</label>
        </div>



        <div className="KEInputContainer">
          <Field
            as="input"
            type="checkbox"
            name="lockAfterApproval"
            onChange={handleChange}
            disabled={disableEdit}
          />
          <label htmlFor="lockAfterApproval">Bloquear acesso a avaliação após aprovação</label>
        </div>

        <div className="KEInputContainer">
          <Field
            as="input"
            type="checkbox"
            name="saveHighestScore"
            onChange={handleChange}
            disabled={disableEdit}
          />
          <label htmlFor="saveHighestScore">Salvar maior nota entre as tentativas</label>
        </div>
      </div>

      <div className="KEForm KEFormConfig KEFormConfig2">
        <p>Critérios de conclusão</p>

        <div className="form-mr-3">
          <div className="mr-3">
            <div className="kEInfoLabel">
              <label>Número de tentativas</label>
              <CustomTooltip title={numTentaInfo} placement="top">
                <HelpIcon style={{ fontSize: 20, cursor: "pointer" }} />
              </CustomTooltip>
            </div>

            <Field
              type="number"
              min={1}
              className="form-control"
              id="realizationPeriod"
              aria-describedby="Número de tentativas"
              placeholder="1"
              name="maxTries"
              form="novalidatedform"
              onChange={handleChange}
              disabled={disableEdit}
            />
            <ErrorMessage
              name="maxTries"
              render={(msg) => <div className={"errorMessage"}>{msg}</div>}
            />
          </div>

          <div className="mr-3">
            <div className="kEInfoLabel">
              <label>Peso da avaliação</label>
              <CustomTooltip title={pesoInfo} placement="top">
                <HelpIcon style={{ fontSize: 20, cursor: "pointer" }} />
              </CustomTooltip>
            </div>
            <Field
              type="number"
              min={1}
              className="form-control"
              id="realizationPeriod"
              aria-describedby="Peso da avaliação"
              placeholder="1"
              name="weight"
              form="novalidatedform"
              onChange={handleChange}
              disabled={disableEdit}
            />
            <ErrorMessage
              name="weight"
              render={(msg) => <div className={"errorMessage"}>{msg}</div>}
            />
          </div>
        </div>

        <div className="form-mr-3">
          <div className="mr-3">
            <div className="kEInfoLabel">
              <label>Nota de corte</label>
              <CustomTooltip title={notaInfo} placement="top">
                <HelpIcon style={{ fontSize: 20, cursor: "pointer" }} />
              </CustomTooltip>
            </div>
            <Field
              type="number"
              min={10}
              className="form-control"
              id="realizationPeriod"
              aria-describedby="Nota de corte"
              placeholder="10"
              onChange={handleChange}
              name="masteryScore"
              form="novalidatedform"
              disabled={disableEdit}
            />
            <ErrorMessage
              name="masteryScore"
              render={(msg) => <div className={"errorMessage"}>{msg}</div>}
            />
          </div>

          <div className="mr-3">
            {
              <>
                <div className="kEInfoLabel">
                  <label>Tempo limite</label>
                  <CustomTooltip title={tempoInfo} placement="top">
                    <HelpIcon style={{ fontSize: 20, cursor: "pointer" }} />
                  </CustomTooltip>
                </div>
                {timeLimit !== null && (
                  <Field
                    type="number"
                    min={noTimeLimitStatus ? 0 : 1}
                    className="form-control"
                    id="realizationPeriod"
                    aria-describedby="Tempo limite"
                    placeholder="1"
                    name="timeLimit"
                    form="novalidatedform"
                    onChange={handleChange}
                    disabled={noTimeLimitStatus || disableEdit}
                    value={timeLimit}
                  />
                )}
                <ErrorMessage
                  name="timeLimit"
                  render={(msg) => <div className={"errorMessage"}>{msg}</div>}
                />
              </>
            }

            <div className="KEInputContainer KEInputContainerB">
              <Field
                as="input"
                type="checkbox"
                name="noTimeLimit"
                onChange={handleChange}
                disabled={disableEdit}
              />
              <label htmlFor="noTimeLimit">Sem tempo limite</label>
            </div>
          </div>
        </div>

        <div className="contrBlock">
          <div className="KEInputContainer">
            <Field
              as="input"
              type="checkbox"
              name="lockNavigation"
              onChange={handleChange}
              disabled={disableEdit}
            />
            <label htmlFor="lockNavigation">Bloquear avançar questões</label>
          </div>
          <CustomTooltip title={blockInfo} placement="top">
            <HelpIcon style={{ fontSize: 20, cursor: "pointer" }} />
          </CustomTooltip>
        </div>
      </div>
    </div>
  );
};

export default withRouter(GeneralSettingsKnowledge);
