import "./styles.modules.scss";

import React, { useContext, useState } from "react";
import { withRouter, useParams } from "react-router-dom";

import BreadcrumbListing from "../BreadcrumbListing";
import TagsEvaluation from "../TagsEvaluation";

import { Field, ErrorMessage } from "formik";
import { RECreateContext } from "../../Contexts/REContext";

const CategoriesListEvaluation = (props) => {
  const { evaluationForm } = props;
  const {
    currentEvaluation,
    setCurrentEvaluation,
    contextTagsEnd,
    setContextTagsEnd,
    evaluationModel,
  } = useContext(RECreateContext);

  const { idEvaluation } = useParams();

  const handleChange = React.useCallback((event) => {
    setCurrentEvaluation({
      ...currentEvaluation,
      [event.target.name]: event.currentTarget.value,
    });

    evaluationForm.current?.handleChange(event);
  }, []);

  return (
    <div className="KECategorias">
      {evaluationModel && evaluationModel.evaluacionType !== "IMPACT" && (
        <>
          <p className="pTitle">Categorização por páginas</p>
          <p>
            Neste espaço, você pode categorizar a sua avaliação de conhecimento por páginas.
            Adicione e edite conforme suas preferências.
          </p>

          <BreadcrumbListing type={"evaluations"} paramID={idEvaluation} />
        </>
      )}

      <div className="KEForm KEFormTags">
        <div className="tagsName">Tags</div>
        <TagsEvaluation
          containerTags={currentEvaluation.evaluationTags}
          tagEnd={contextTagsEnd}
          setTagEnd={setContextTagsEnd}
        />
      </div>

      <div className="KEForm KEFormAsthor">
        <label htmlFor="kEFormName">Nome do autor</label>
        <Field
          type="text"
          id="kEFormName"
          className="form-control"
          name="author"
          maxLength="50"
          placeholder="Autor"
          onChange={handleChange}
        />
        <div className="character-counter">
          <small>
            {evaluationForm.current && evaluationForm.current.values.author
              ? evaluationForm.current.values.author.length
              : 0}
            /50
          </small>
        </div>
      </div>

      <div className="KEForm KEFormMail">
        <label htmlFor="KEFormMail">E-mail</label>
        <Field
          id="KEFormMail"
          className="form-control"
          name="authorEmail"
          type="email"
          // onChange={formik.handleChange}
          // value={formik.values.authorEmail}
          placeholder=""
          onChange={handleChange}
        />
        <ErrorMessage
          name="authorEmail"
          render={(msg) => <div className={"errorMessage"}>{msg}</div>}
        />
      </div>
    </div>
  );
};

export default withRouter(CategoriesListEvaluation);
