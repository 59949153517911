import "./styles.modules.scss";

import React, { useRef, useContext, useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";

import { Formik } from "formik";

import { linkElementToBlock, deleteEvaluations } from "services/NexiDigitalAPI";

import { setParametersEvaluation, getParametersEvaluation } from "../../Utils/ParametersEvaluation";
import {  getLearnEvaluationInitPromises, putLearnEvaluation } from "../../Fetch/EvaluationsAPI";

import Accordion from "../Accordion";
import BasicInformation from "../BasicInformation";
import CategoriesListEvaluation from "../CategoriesListEvaluation";
import GeneralSettingsKnowledge from "../GeneralSettingsKnowledge";
import GeneralSettingsImpact from "../GeneralSettingsImpact";

import { RECreateContext } from "../../Contexts/REContext";
import { schemaFormInit, schemaEvaluation } from "../../Schema";
import ModalDialog from "../../../Modals/ModalDialog";


const Menu = (props) => {
  const evaluationForm = useRef(null);
  const isMounted = useRef(null);
  const isMountedClear = useRef(true);


  const {
    preloadInit,
    setPreloadInit,
    evaluationModel,
    evaluationSubmit,
    setCurrentEvaluation,
    currentEvaluation,
    currentCatalog,
    setCurrentCatalog,
    setContextTagsInit,
    contextTagsEnd,
    questionsList,
    imageEvaluation,
    setImageEvaluation,
    setTitleEvaluation,
    setDescriptionEvaluation,
  } = useContext(RECreateContext);

  const disableEdit = currentEvaluation?.hasQuestionnaire;


  const { idEvaluation, isEditing, idBlock } = useParams();
  const [show, setShow] = useState(false)
  const booleanIsEditing = Boolean(Number(isEditing));
  useEffect(() => {
    if (evaluationSubmit) {
      if (questionsList.length === 0) {
        setShow(true)
        return
      }
      evaluationForm.current.handleSubmit();

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluationSubmit]);

  useEffect(() => {
    return () => {
      if(isMounted?.current && isMountedClear.current && !isMounted.current?.lastModifiedDate){

        deleteEvaluations(isMounted.current?.idEvaluation)
      }
  }}, [])

  const linkContentToBlock = async () => {
    if (idBlock !== "null") {
      const linkBlockData = {
        idBlock: idBlock,
        idElement: idEvaluation,
        elementType: "EVALUATION",
      };
      linkElementToBlock(idBlock, linkBlockData)
        .then(() => {
          setPreloadInit(false);
          props.history.goBack();
        })
        .catch((err) => {
          console.log("Error linking", err?.request || err);
        });
    } else {
      setPreloadInit(false);
      props.history.goBack();
    }
  };

  useEffect(() => {
    if (evaluationModel && !currentEvaluation) {
      setPreloadInit(true);
      setCurrentEvaluation(schemaFormInit(evaluationModel.evaluacionType));
      getLearnEvaluationInitPromises(idEvaluation, "evaluations", (promise) => {
        if (!isMounted.current) {
          const objcallLearnEvaluation = promise[0];
          const objCategoriesEvaluation = promise[1];
          const objTags = promise[2];
          const getParam = getParametersEvaluation(objcallLearnEvaluation.data);
          //// Context
          setCurrentEvaluation({
            ...schemaFormInit(evaluationModel.evaluacionType),
            ...getParam,
            rootGetParamInit: getParam,
            rootEvaluationData: objcallLearnEvaluation.data,
          });
          setCurrentCatalog(objCategoriesEvaluation.data);
          setContextTagsInit(objTags);
          setImageEvaluation(objcallLearnEvaluation.data.image);
          setTitleEvaluation(objcallLearnEvaluation.data.title);
          setDescriptionEvaluation(objcallLearnEvaluation.data.description);
          //// Context
          isMounted.current =  {
            lastModifiedDate: objcallLearnEvaluation.data?.lastModifiedDate,
            idEvaluation: objcallLearnEvaluation.data?.idEvaluation
          };

          setPreloadInit(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluationModel]);


  return (
    <>
      {preloadInit ? (
        <div className="KEMenuPreloader" />
      ) : (
        <Formik
          innerRef={evaluationForm}
          validationSchema={schemaEvaluation(evaluationModel.evaluacionType)}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{
            ...currentEvaluation,
            noTimeLimit: parseInt(currentEvaluation.timeLimit) === 0 ? true : false,
            image: "",
          }}
          enableReinitialze={true}
          onSubmit={(values, actions) => {
            setPreloadInit(true);

            const contextTagsEndFilter = contextTagsEnd.map((it) => {
              return { idTag: it.id };
            });
            const obj = setParametersEvaluation(
              { ...currentEvaluation.rootEvaluationData, categories: currentCatalog },
              values,
              contextTagsEndFilter,
              imageEvaluation,
            );

            putLearnEvaluation(idEvaluation, obj, () => {
              isMountedClear.current = false
              if (booleanIsEditing) {
                setPreloadInit(false);
                props.history.goBack();
              } else {
                linkContentToBlock();
              }
            });

            actions.setSubmitting(false);
          }}
        >
          {({ errors }) => {
            return (
              <form
                className={`KEMenu ${errors.title ? "KEErroField" : ""}
            ${errors.authorEmail ? "KEErroFieldEmail" : ""}`}
              >
                <div className="KEMenuTitle">
                  Editar avaliação {evaluationModel ? "de " + evaluationModel.name : ""}
                </div>

                <div id="KEAccordionBase" className="KEAccordionBase">
                  <Accordion indice={1} title="Informações básicas" erroStatus={errors}>
                    <BasicInformation evaluationForm={evaluationForm} />
                  </Accordion>
                  <Accordion indice={2} title="Categorias" erroStatus={errors}>
                    <CategoriesListEvaluation evaluationForm={evaluationForm} />
                  </Accordion>
                  {evaluationModel && evaluationModel.evaluacionType === "KNOWLEDGE" && (
                    <Accordion
                      disableEdit={disableEdit}
                      indice={3}
                      title="Configurações gerais"
                      erroStatus={errors}
                    >
                      <GeneralSettingsKnowledge evaluationForm={evaluationForm} />
                    </Accordion>
                  )}
                  {evaluationModel && evaluationModel.evaluacionType === "IMPACT" && (
                    <Accordion
                      disableEdit={disableEdit}
                      indice={3}
                      title="Configurações gerais"
                      erroStatus={errors}
                    >
                      <GeneralSettingsImpact evaluationForm={evaluationForm} />
                    </Accordion>
                  )}
                </div>
              </form>
            );
          }}
        </Formik>
      )}
      <ModalDialog
        text={
          "É necessário possuir pelo menos uma questão."
        }
        show={show}
        setShow={setShow}
        btnCall={{
          alertMode: true
        }}
      />
    </>

  );
};

export default withRouter(Menu);
