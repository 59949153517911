import React from "react";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { useHistory } from "react-router-dom";

const Header = ({ title }) => {

  const history = useHistory();

  return (
    <div className="header-title-wrapper">
      <div className="header-title">
        <button
          className="btnGoBack"
          onClick={() => {
            history.goBack();
          }}
        >
          <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
        </button>
        {title}
      </div>
    </div>

  )

}

export default Header;
