import "./styles.scss";

import React, { useState, useEffect, useRef, useContext } from "react";

import "react-datepicker/dist/react-datepicker.css";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";

const TagsList = ({ tagsInit = [], tagsReturn }) => {
  const [tags, setTags] = useState(tagsInit);
  const [inputText, setInputText] = useState("");
  const tagRef = useRef();

  useEffect(() => {
    if (tagsInit) {
      setTags(tagsInit);
    }
  }, [tagsInit]);

  const updateTags = (newtags) => {
    setTags(newtags);
    tagsReturn(newtags);
  };

  const handleDelete = (value) => {
    const newtags = tags.filter((val) => val.title !== value);
    updateTags(newtags);
  };

  const handleKeyUp = (e) => {
    // enter -> confirmar tag
    if (e.keyCode === 13) {
      const verifyTags = tags.filter((it) => it.title !== tagRef.current.value);
      updateTags([...verifyTags, { title: tagRef.current.value }]);
      setInputText("");
    }

    // delete => remover a ultima tag
    if (e.keyCode === 8) {
      const deleteTags = tags.filter((it, indice) => indice !== tags.length - 1);
      updateTags([...deleteTags]);
    }
  };

  const onChangeTags = (e) => {
    setInputText(e.currentTarget.value);
  };

  return (
    <div className="pc-form-tags">
      <TextField
        inputRef={tagRef}
        fullWidth
        variant="standard"
        size="small"
        margin="none"
        placeholder={"Escreva e pressione o Enter"}
        value={inputText}
        onKeyUp={handleKeyUp}
        onChange={(e) => {
          onChangeTags(e);
        }}
        InputProps={{
          startAdornment: (
            <div className="container-tags-list">
              {tags.map((data, index) => {
                return (
                  <Chip
                    key={index + data.title}
                    size="small"
                    label={data.title}
                    onDelete={() => {
                      handleDelete(data.title);
                    }}
                    color="primary"
                  />
                );
              })}
            </div>
          ),
        }}
      />
    </div>
  );
};

export default TagsList;
