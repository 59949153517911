import React, { useState, useEffect, useRef, useContext } from "react";

import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";

import { RiDeleteBin5Line } from "react-icons/ri";

import { PACreateContext } from "../../../Contexts/PAContext";

import TagsList from "../../../../../PeopleCraft/TagsList";

const CreateNewCondiciones = ({ rule, condicione }) => {
  // const [tags, setTags] = useState([{ title: "nilo" }, { title: "casa" }, { title: "base" }]);
  const [tags, setTags] = useState([]);
  const [removeAtivado, setRemoveAtivado] = useState(true);
  const [changeAttribute, setChangeAttribute] = useState("Campo de perfil");
  const [changeMethod, setChangeMethod] = useState("CONTAINS");

  const [erroAttribute, setErroAttribute] = useState(false);
  const [erroTags, setErroTags] = useState(false);

  const { containerRules, setContainerRules, openConfirmModal } = useContext(PACreateContext);

  const filterType = (type, elem = null) => {
    let filt = null;
    if (type === "departamento") {
      filt = "Departamento";
    } else if (type === "email") {
      filt = "E-mail";
    } else if (type === "unidade") {
      filt = "Unidade";
    }
    else if (type === "CONTAINS") {
      filt = "Contém";
    } else if (type === "EXACT_MATCH") {
      filt = "É igual";
    } else if (type === "value") {
      filt = elem.map((it) => {
        return it.title ? it.title : { title: it };
      });
    } else {
      filt = type;
    }

    return filt;
  };

  const removeCondicione = () => {
    openConfirmModal({
      text: `Você tem certeza que deseja remover essa condição?`,
      variantBtn: "danger",
      textBtn: "Remover condição",
      fnc: () => {
        const removeFilter = containerRules.map((it) => {
          if (it === rule) {
            if (it.items.length > 1) {
              const items = it.items.filter((el) => el !== condicione);
              it.items = items;
            }
          }

          return it;
        });

        setContainerRules(removeFilter);
      },
    });
  };

  const filterDropdown = () => {
    /// control de quantidade de Condicionais > 1 tem remove
    const cRS = containerRules.filter((it) => it === rule && it.items.length > 1).length === 0;
    setRemoveAtivado(!cRS);

    /// controle de datas
    containerRules.map((it) => {
      if (it === rule) {
        const condicioneFilter = it.items.map((el) => {
          if (el === condicione) {
            if (el.erroAttribute) setErroAttribute(el.erroAttribute);
            if (el.erroValue) setErroTags(el.erroValue);

            if (el.method) {
              setChangeMethod(el.method);
            }

            if (el.attribute) {
              setChangeAttribute(el.attribute);
              el.erroAttribute = false;
              setErroAttribute(false);
            }

            if (el.value && el.value.length > 0) {
              setTags(filterType("value", el.value));
              el.erroValue = false;
              setErroTags(false);
            }
          }

          return el;
        });

        it.items = condicioneFilter;
      }

      return it;
    });
  };

  const selectDropdown = (select, type) => {
    const itSelec = type === "value" ? filterType("value", select) : select;

    const res = containerRules.map((it) => {
      if (it === rule) {
        it.items = it.items.map((el) => {
          if (el === condicione) el[type] = itSelec;
          return el;
        });
      }
      return it;
    });

    setContainerRules(res);
  };

  useEffect(() => {
    if (containerRules) {
      filterDropdown();
    }
  }, [containerRules]);

  return (
    <div className="createNewCondicoes">
      <Dropdown
        onSelect={(e) => {
          selectDropdown(e, "attribute");
        }}
        className={`${erroAttribute ? "erroStatus" : ""}`}
      >
        <Dropdown.Toggle variant="outline-secondary" id="campo-perfil">
          {filterType(changeAttribute)}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item eventKey="departamento">Departamento</Dropdown.Item>
          <Dropdown.Item eventKey="email">E-mail</Dropdown.Item>
          <Dropdown.Item eventKey="cargo">Cargo</Dropdown.Item>
          <Dropdown.Item eventKey="unidade">Unidade</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown
        onSelect={(e) => {
          selectDropdown(e, "method");
        }}
      >
        <Dropdown.Toggle variant="outline-secondary" id="campo-contem">
          {filterType(changeMethod)}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item eventKey="CONTAINS">Contém</Dropdown.Item>
          <Dropdown.Item eventKey="EXACT_MATCH">É igual</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <div className={`container-tags ${erroTags ? "erroStatus" : ""}`}>
        <TagsList
          tagsInit={tags}
          tagsReturn={(e) => {
            selectDropdown(e, "value");
          }}
        />
      </div>

      <div className="spaceRemove">
        {removeAtivado && (
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip style={{ lineHeight: 1.2 }} id={`tooltip-top`}>
                {"Excluir Condição"}
              </Tooltip>
            }
          >
            <button className="removeCondicao" type="button" onClick={removeCondicione}>
              <RiDeleteBin5Line />
            </button>
          </OverlayTrigger>
        )}
      </div>
    </div>
  );
};

export default CreateNewCondiciones;
