import Switch from "@material-ui/core/Switch";
import SaveIcon from "@material-ui/icons/Save";
import moment from "moment";
import { DatePickerInput } from "rc-datepicker";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useParams, withRouter } from "react-router-dom";
import _ from "underscore";
import { updateContent } from "../../services/NexiDigitalAPI";

import { CircularProgress } from "@material-ui/core";
import CancelButton from "components/CancelButton";
import CmsContentLabel from "components/CmsContentLabel";
import CmsScorm from "components/CmsScorm";
import CmsWorkload from "components/CmsWorkload";
import CmsAudio from "../../components/CmsAudio";
import CmsAutor from "../../components/CmsAutor";
import CmsFile from "../../components/CmsFile";
import CmsImageUpload from "../../components/CmsImageUpload";
import CmsJornada from "../../components/CmsJornada";
import CmsPost from "../../components/CmsPost";
import CmsSinglePage from "../../components/CmsSinglePage";
import CmsTags from "../../components/CmsTags";
import CmsTitulo from "../../components/CmsTitulo";
import CmsType from "../../components/CmsType";
import CmsVideoUpload from "../../components/CmsVideoUpload";
import CmsYoutube from "../../components/CmsYoutube";
import { AuthContext } from "../../context/AuthContext";
import AccessTable from "../AccessTable";
import CmsLinkTitle from "components/CmsLinkTitle";
import ModalWarning from "components/ModalWarning";
import ModalUsersVisible from "containers/configuracao/PlatformTerms/components/Dialogs/ModalUsersVisible";
import DialogWarning from "components/DialogWarning";
import NiceModal from "@ebay/nice-modal-react";

const idVideo = "2";
const idAudio = "1";

const MultiFormat = (props) => {
  const { data, contentTags, permissionApproveContent } = props;
  // Lista de variáveis em ordem alfabética (para bater com a lista recebida do json)
  const [active, setActive] = useState(data.active || 0);
  const [autor, setAutor] = useState(data.author || "");
  const [autorEmail, setAutorEmail] = useState(data.authorEmail || "");
  const [backConfirmation, setBackConfirmation] = useState(data.backConfirmation || "");
  const [backgroundColor, setBackgroundColor] = useState(data.backgroundColor || "");
  const [content, setContent] = useState(data.content || "");
  const [contentLocation, setContentLocation] = useState(data.contentLocation || "");
  const [description, setDescription] = useState(data.description || "");
  const [endDate, setEndDate] = useState(data.endDate === null ? null : new Date(data.endDate));
  const [idContentGroup, setIdContentGroup] = useState(data.idContentGroup);
  const [idContentItem, setIdContentItem] = useState(data.idContentItem);
  const [idContentType, setIdContentType] = useState(`${data.idContentType}`); 
  const [idSection, setIdSection] = useState(data.idSection);
  const [imagemS3, setImagemS3] = useState(data.image || "");
  const [imageStyle, setImageStyle] = useState(data.imageStyle || "");
  const [initDate, setInitDate] = useState(
    data.initDate === null ? new Date() : new Date(data.initDate),
  );
  const [logoutConfirmation, setLogoutConfirmation] = useState(data.logoutConfirmation);
  const [referenceLink, setReferenceLink] = useState(data.referenceLink || "");
  const [showBottomMenu, setShowBottomMenu] = useState(data.showBottomMenu);
  const [showTopBar, setShowTopBar] = useState(data.showTopBar);
  const [titulo, setTitulo] = useState(data.text || "");
  const [label, setLabel] = useState(data.label || "");
  const [loadingUpdateContent, setLoadingUpdateContent] = useState(false);
  const [scormData, setScormData] = useState(
    data.scorm
      ? {
          result: {
            ...data.scorm,
            filename: data.scorm.scormPkgName,
            items: data.scorm.scoItens,
          },
        }
      : {},
  );

  const { loginData } = useContext(AuthContext);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningModalText, setWarningModalText] = useState("O link inserido não é válido, por favor verifique e tente novamente.");
 
  // variáveis de auxílio
  let tagsDeConteudo = [];
  for (let i = 0; i < contentTags.length; i++) {
    tagsDeConteudo.push({ id: i, name: contentTags[i].tag });
  }

  const [imagemOriginal, setImagemOriginal] = useState(data.image || "");
  const [videoOriginal, setVideoOriginal] = useState(data.image || "");
  const [audioOriginal, setAudioOriginal] = useState(data.image || "");
  const [temEndDate, setTemEndDate] = useState(endDate ? true : false);
  const [tags, setTags] = useState(tagsDeConteudo);
  const [duration, setDuration] = useState(Number(data.duration || 0));

  const [videoDuration, setVideoDuration] = useState(0);

  const [audioDuration, setAudioDuration] = useState(0);
  const [audioType, setAudioType] = useState("audio/mp3");

  const showWarningDialog = () => {
    NiceModal.show(DialogWarning, {
      title: 'Link inválido',
      description: "O link inserido não é válido, por favor verifique e tente novamente."
    })
  }

  async function isValidURL(url) {
    const pattern = /^(https?:\/\/|www\.)\S+/i; 
    
    if (!/^https?:\/\//i.test(url)) { 
      url = `http://${url}`;
    }

    if(!pattern.test(url)){
      return false;
    }

    try {
      await fetch(url, { mode: "no-cors" }); 
      return true;
    } catch (error) {
      return false;
    }
  }

  const sendData = async (event) => {
    event.preventDefault();

    const _isValidUrl = await isValidURL(contentLocation)

    if (idContentType === '27' && !_isValidUrl) {
      showWarningDialog();
      return;
    } 

    const publishDate = moment().format("YYYY-MM-DD hh:mm:ss.SSS");
    const userId = loginData.userid; // Usuário logado
    const tagList = _.map(tags, function (t) {
      return t.name;
    }).join(";");

    let obj = {
      idContentItem: idContentItem,
      idContentGroup: idContentGroup,
      image: imagemS3,
      text: titulo,
      description: description,
      contentLocation: contentLocation,
      publishDate: publishDate,
      initDate: moment(initDate).format("YYYY-MM-DD hh:mm:ss.SSS"),
      endDate: endDate === null ? null : moment(endDate).format("YYYY-MM-DD hh:mm:ss.SSS"),
      content: content,
      idUserPublisher: parseInt(userId, 10),
      author: autor,
      authorEmail: autorEmail,
      active: parseInt(active),
      idContentType: idContentType,
      tags: tagList,
      backConfirmation: backConfirmation === 1 ? 1 : 0,
      backgroundColor: backgroundColor,
      idSection: idSection,
      imageStyle: imageStyle,
      logoutConfirmation: logoutConfirmation,
      referenceLink: referenceLink,
      showBottomMenu: showBottomMenu,
      showTopBar: showTopBar,
      label: label,
      duration,
      scorm:
        idContentType === "26"
          ? {
              scormPkgName: scormData.result?.filename,
              title: titulo,
              scoItens: scormData.result?.items,
              processScorm: scormData.processScorm,
            }
          : undefined,
    };
    if (idContentType === idAudio) {
      obj.duration = audioDuration;
    } else if (idContentType === idVideo) {
      obj.duration = videoDuration;
    }
    setLoadingUpdateContent(true);
    updateContent(obj)
      .then((res) => {
        if (res.data.success) {
          props.history.goBack();
        }
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoadingUpdateContent(false);
      });
  };

  const acceptedWorkloadIdTypes = ["1", "2", "3", "4", "5", "7", "18", "27"];

  const getDuration = () => {
    switch (idContentType) {
      case idAudio:
        return audioDuration;
      case idVideo:
        return videoDuration;
      default:
        return duration;
    }
  };

  const hasAudioVideo = [idAudio, idVideo].includes(idContentType);

  const usaDataFim = (op) => {
    setTemEndDate(op);

    if (op === false) {
      setEndDate(null);
    }
  };

  const handleLabelText = (newIdContentType) => {
    switch (Number(newIdContentType)) {
      case 1:
        setLabel("Áudio");
        break;
      case 2:
        setLabel("Video");
        break;
      case 4:
        setLabel("Conteúdo");
        break;
      case 5:
        setLabel("Artigo");
        break;
      case 18:
        setLabel("Arquivo");
        break;
      case 26:
        setLabel("Conteúdo");
        break;
      case 27:
        setLabel("Link");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (idContentType) {
      handleLabelText(idContentType);
    }
  }, [idContentType]);

  return (
    <div>
      <div className="header-title-wrapper">
        <div className="header-title">Multiformato</div>
      </div>
      <div className="Video-form">
        <form>
          {idContentType === "4" ? (
            <CmsJornada
              titulo={titulo}
              setTitulo={setTitulo}
              contentLocation={contentLocation}
              setContentLocation={setContentLocation}
            />
          ) : (
            <>
              {idContentType !== "27" ? (
                <CmsTitulo
                  titulo={titulo}
                  setTitulo={setTitulo}
                  description={description}
                  setDescription={setDescription}
                />
              ) : (
                <CmsLinkTitle
                  titulo={titulo}
                  setTitulo={setTitulo}
                  contentLocation={contentLocation}
                  setContentLocation={setContentLocation}
                />
              )}
            </>
          )}
          <CmsTags setTags={setTags} contentTags={contentTags} />
          <CmsType
            contentType={idContentType}
            setContentType={setIdContentType}
            isLms={data.context === "LMS"}
          />
          <CmsContentLabel label={label} setLabel={setLabel} />
          <CmsImageUpload
            imagemOriginal={imagemOriginal}
            imageUrl={imagemS3}
            changeImage={setImagemS3}
            idContentItem={idContentItem}
          />
          {idContentType === "5" && (
            <CmsSinglePage
              content={content}
              setContent={setContent}
              loading={(e) => {}}
              idContentItem={idContentItem}
            />
          )}
          {idContentType === "26" && (
            <CmsScorm data={data} scormData={scormData} setScormData={setScormData} />
          )}
          {idContentType === "1" && (
            <CmsAudio
              contentLocation={contentLocation}
              setContentLocation={setContentLocation}
              audioDuration={audioDuration}
              setAudioDuration={setAudioDuration}
              audioType={audioType}
              setAudioType={setAudioType}
              idContentItem={idContentItem}
              audioOriginal={audioOriginal}
            />
          )}
          {idContentType === "2" && (
            <CmsVideoUpload
              contentLocation={contentLocation}
              setContentLocation={setContentLocation}
              videoOriginal={videoOriginal}
              setVideoDuration={setVideoDuration}
              idContentItem={idContentItem}
            />
          )}
          {idContentType === "3" && (
            <CmsPost
              content={content}
              setContent={setContent}
              referenceLink={referenceLink}
              setReferenceLink={setReferenceLink}
            />
          )}
          {idContentType === "7" && (
            <CmsYoutube contentLocation={contentLocation} setContentLocation={setContentLocation} />
          )}
          {idContentType === "18" && (
            <CmsFile contentLocation={contentLocation} setContentLocation={setContentLocation} />
          )}
          {acceptedWorkloadIdTypes.includes(idContentType) && (
            <CmsWorkload
              workload={getDuration()}
              setWorkload={setDuration}
              hideHour={acceptedWorkloadIdTypes}
              disabled={hasAudioVideo}
            />
          )}
          <CmsAutor
            autor={autor}
            setAutor={setAutor}
            autorEmail={autorEmail}
            setAutorEmail={setAutorEmail}
          />
          <div className="form-group">
            <div className="card overflow-visible">
              <div className="card-header template-card-header">Publicação</div>
              <div className="card-body template-card-body">
                <div className="form-row">
                  {permissionApproveContent && (
                    <div className="col-md-3">
                      <Form.Group className="access-table-select">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          value={active}
                          onChange={(e) => setActive(e.target.value)}
                        >
                          <option value={0}>Avaliação pendente</option>
                          <option value={1}>Aprovado</option>
                          <option value={2}>Cancelado</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                  )}
                  <div className="col-md-3">
                    <Form.Group className="access-table-select">
                      <Form.Label>Data de publicação</Form.Label>
                      <DatePickerInput
                        name="initDate"
                        locale="pt-br"
                        onChange={(date) => setInitDate(date)}
                        value={initDate}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <div className="access-table-select form-group">
                      <label className="form-label" htmlFor="tira-do-ar" style={{ margin: "-3 0" }}>
                        Agendar término publicação?
                      </label>
                      <br />
                      <Switch
                        className="form-group"
                        id="tira-do-ar"
                        color="primary"
                        checked={temEndDate}
                        onChange={(e) => usaDataFim(e.target.checked)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </div>
                  {temEndDate ? (
                    <div className="col-md-3">
                      <Form.Group className="access-table-select">
                        <Form.Label>Data de retirada do conteúdo</Form.Label>
                        <DatePickerInput
                          name="endDate"
                          placeholderText="Sem prazo"
                          locale="pt-br"
                          onChange={(date) => setEndDate(date)}
                          value={endDate}
                        />
                      </Form.Group>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header template-card-header">Permissões de acesso ao conteúdo</div>
            <div className="card-body template-card-body">
              <AccessTable id={data.idContentItem} />
            </div>
          </div>
          <br />
          <div className="row" style={{ padding: 5 }}>
            <div className="col-md-8"></div>
            <div className="col-md-4">
              <button type="button" className="btn btn-primary" onClick={sendData}>
                <SaveIcon /> Salvar{" "}
                {loadingUpdateContent && (
                  <CircularProgress
                    size={18}
                    style={{ margin: "0px 0px -3px 10px", color: "white" }}
                  />
                )}
              </button>
              <CancelButton idContentItem={idContentItem} />
            </div>
          </div>
        </form>
        <br />
      </div>    
    </div>
  );
};

export default withRouter(MultiFormat);
