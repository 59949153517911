import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { addNewContent, zoom_auth, zoom_add } from "../../services/NexiDigitalAPI";
import moment from "moment";
import queryString from "query-string";
import _ from "underscore";

const ZoomAuth = (props) => {
  const params = queryString.parse(props.location.search);
  const { code } = params;
  const zoom_auth_url = process.REACT_APP_ZOOM_AUTH;
  const [loading, setLoading] = useState(true);
  const [erro, setErro] = useState(false);
  const history = useHistory();

  const zoomAPI = () => {
    zoom_auth(code)
      .then(function (response) {
        if (response.data.status) {
          localStorage.setItem("zoom_token", response.data.data.access_token);
          setLoading(false);
        } else {
          setErro(true);
        }
      })
      .catch(function (error) {
        setErro(true);
        console.log("Erro", error);
      });
  };

  useEffect(() => {
    zoomAPI();
  }, []);

  const handleNovoConteudo = () => {
    const idContentType = 8;
    //const contentName = "Meeting";
    const idContentGroup = 1;
    const initDate = new Date();
    const duracao = 60;
    const meeting = {
      topic: "Nova Reunião",
      type: 2, //Scheduled meeting
      start_time: moment(initDate).format("YYYY-MM-DDThh:mm:ss"),
      timezone: "America/Sao_Paulo",
      duration: duracao,
      agenda: "",
    };
    zoom_add(meeting)
      .then((response) => {
        // depois salva no banco de dados.
        const zoom = response.data;
        if (zoom.name === "Error") {
          console.log("Erro ao criar reunião no Zoom", zoom.message);
          return false;
        }
        addNewContent({
          text: "Novo",
          description: "Novo conteúdo",
          idContentType: `${idContentType}`,
          idContentGroup: `${idContentGroup}`,
          referenceLink: `${zoom.id}`,
          contentLocation: `${zoom.join_url}`,
        }).then((res) => {
          if (res.data.success) {
            // Se conseguiu criar o conteúdo,  redireciona para o editor deste conteúdo
            props.history.push(`/cms/content/${res.data.idContentItem}`);
          }
        });
      })
      .catch((e) => {
        console.log("Erro ao criar reunião no zoom", e);
        return false;
      });
  };
  return (
    <div>
      <div className="template-header">
        <h3>Meetings</h3>
      </div>
      <div className="card">
        <div className="card-header template-card-header">
          <h4>Zoom</h4>
        </div>
        <div className="card-body template-card-body">
          {!erro && loading && <h5>Estamos conectando sua conta do Zoom no nosso aplicativo</h5>}
          {!erro && !loading && <h5>Configuração do Zoom Realizada!</h5>}
          {!erro && loading && (
            <p>Assim que estiver pronto, você já poderá criar sua primeira reunião</p>
          )}
          {erro && (
            <p>Não conseguimos conectar na sua conta do Zoom, por favor, tente novamente.</p>
          )}
          {erro && !loading && <p>Você já pode cadastrar suas reuniões...</p>}
        </div>
        <div className="card-footer template-card-header">
          {loading && !erro && <div>Carregando</div>}
          {erro && (
            <a className="btn btn-lg btn-primary" href={zoom_auth_url}>
              Login no Zoom
            </a>
          )}
          {!loading && !erro && <Redirect to="/cms/meeting" />}
        </div>
      </div>
    </div>
  );
};

export default ZoomAuth;
