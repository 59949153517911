import React, { useState } from "react";
import styles from "./styles.module.scss";

const Acordeon = ({ position, name, children }) => {
  const [expanded, setExpanded] = useState(false);
  const clickHandler = () => setExpanded(!expanded);
  const titleColor = { backgroundColor: "rgba(101,45,144,0.22)" };
  const titleColor2 = { backgroundColor: "rgb(101, 45, 144, 0.14)" };

  return (
    <div>
      <div
        onClick={clickHandler}
        style={position % 2 === 0 ? titleColor : titleColor2}
        className={styles.reportAcordeonTitle}
      >
        <div style={{ color: "#000" }} className="custom-acordeon">
          <span style={{ padding: "0px 10px" }}>{expanded ? "-" : "+"}</span>
          {name}
        </div>
      </div>
      <div className={styles.reportAcordeonContent} style={!expanded ? { display: "none" } : null}>
        {children}
      </div>
    </div>
  );
};

export default Acordeon;
