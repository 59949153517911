import "./styles.modules.scss";

import React, { useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";

import { RECreateContext } from "../../Contexts/REContext";
import { dataEvaluation } from "utils/Utils";

import Box from "../Box";
import Menu from "../Menu";
import Footer from "../Footer";

const ManagerBase = (props) => {
  const { evaluationType } = useParams();
  const { evaluationModel, setEvaluationModel } = useContext(RECreateContext);

  useEffect(() => {
    if (!evaluationModel) {
      setEvaluationModel(dataEvaluation(evaluationType));
    }
  }, []);

  return (
    <div className={`ReactionEvaluation-form`}>
      <div className="KEConstrol">
        <div className="KEBoxL">
          <div className="KETitle">
            Avaliação {evaluationModel ? "de " : ""}{" "}
            <span>{evaluationModel ? evaluationModel.name : ""}</span>
          </div>
          <Box />
        </div>
        <Menu />
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(ManagerBase);
