// Basic
import React from "react";
import ReportWrapper from "../Components/ReportWrapper";
import ReportListTitle from "../Components/ReportListTitle";
import UsersRegister from "./SubReports/UsersRegister";
import EnrollmentsReport from "./SubReports/EnrollmentsReport";
import TrainingReport from "./SubReports/TrainingReport";
import TrainingValidityReport from "./SubReports/TrainingValidityReport";
import CompanyRegister from "./SubReports/CompanyRegister";
import ReactionEvaluation from "./SubReports/ReactionEvaluation";
import ImpactEvaluation from "./SubReports/ImpactEvaluation";

const ReportsLearningTraining = ({ rel }) => {
  const reportList = [
    (index, expanded) => <UsersRegister index={index} expanded={expanded} key={index} />,
    (index, expanded) => <EnrollmentsReport index={index} expanded={expanded} key={index} />,
    (index, expanded) => <TrainingReport index={index} expanded={expanded} key={index} />,
    (index, expanded) => <CompanyRegister index={index} expanded={expanded} key={index} />,
    (index, expanded) => <ReactionEvaluation index={index} expanded={expanded} key={index} />,
    (index, expanded) => <TrainingValidityReport index={index} expanded={expanded} key={index} />,
    (index, expanded) => <ImpactEvaluation index={index} expanded={expanded} key={index} />,
  ];

  return (
    <ReportWrapper>
      <ReportListTitle>Lista de Relatórios</ReportListTitle>
      {reportList.map((report, index) => {
        const expand = index.toString() === rel;
        return report(index, expand);
      })}
    </ReportWrapper>
  );
};

export default ReportsLearningTraining;
