import "./style.scss";

import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { FiCheckSquare } from "react-icons/fi";
import { FaListCheck } from "react-icons/fa6";
import MultiLikertScale from "./Questions/MultiLikertScale";
import LikertScale from "./Questions/LikertScale";
import SingleOptionComment from "./Questions/SingleOptionComment";
import Comment from "./Questions/Comment";
import PreloaderBlur from "containers/PeopleCraft/PreloaderBlur";
import ModalDialog from "containers/PeopleCraft/Modals/ModalDialog";
import { postQuestionnairesFinish, postQuestionnairesSave } from "services/NexiDigitalAPI";

type Props = {
  fetchData: any;
  idQuestionnaire: any;
  idManager: any;
  finishEvent: any;
};

const EvaluationImpact = ({
  fetchData,
  idQuestionnaire,
  idManager,
  finishEvent
}: Props) => {

  const history = useHistory();
  const [finishStatus, setFinishStatus] = useState(false);
  const [saveStatus, setSaveStatus] = useState<boolean | null>(null);
  const [forceCompleted, setForceCompleted] = useState(false);
  const [showModalDialog, setShowModalDialog] = useState(false);
  const { answers, evaluation, finishDate } = fetchData;

  const title = evaluation.title;
  const description = evaluation.description;
  const closingText =
    evaluation.parameters.closingText ||
    "<h3>Finalizar questionário de impacto</h3><br>Tem certeza que deseja finalizar o questionário de impacto?<br>Ao finalizar, não será possível realizar alterações.<p>";

  const onHandleChange = () => {
    const answerStatusFilter = answers.filter((it) => !it.answerStatus);
    const answerStatus = answerStatusFilter.length === 0 ? true : false;

    setFinishStatus(answerStatus);
  };

  const saveEvent = () => {
    setSaveStatus(true);
    postQuestionnairesSave(fetchData, idManager, idQuestionnaire )
        .then((response) => {
          setSaveStatus(null);
        })
        .catch((error) => {});
  
  };

  const finishEventEvaluation = () => {
    if (!finishDate) {
      setShowModalDialog(true);
    }
  };

  return (
    <div
      className={`pctPage pctEvaluations ${
        finishDate || forceCompleted ? "pctFinish" : ""
      }`}
    >

      {showModalDialog && (
        <ModalDialog
          title={""}
          text={closingText}
          show={showModalDialog}
          setShow={setShowModalDialog}
          btnCall={{
            className: "",
            text: 'Finalizar',
            alertMode: false,
            callEvent: () => {
              // AO CONFIRMAR FINALIZA A AVALIAÇÃO
              postQuestionnairesFinish(fetchData, idManager, idQuestionnaire )
                  .then((response) => {
                    setSaveStatus(null);
                    finishEvent();
                    history.goBack();
                      
                  })
                  .catch((error) => {});
            },
          }}
          btnDenyCall={() => {
            //AO CANCELAR
          }}
        />
      )}

      {saveStatus !== null && <PreloaderBlur />}
      <div className="pctEvaluationsBase">
        <h3>{title}</h3>
        <p className="description">{description}</p>
       
        <div className="pctEvaluationsBaseForm">
          {answers.map((item, i) => {
            const obj = { answer: item, key: i, indice: i + 1, onHandleChange };

            return {
              MULTI_LIKERT_SCALE: <MultiLikertScale {...obj} />,
              LIKERT_SCALE: <LikertScale {...obj} />,
              SINGLE_OPTION_COMMENT: <SingleOptionComment {...obj} />,
              COMMENT: <Comment {...obj} />,
            }[item.question.questionType];
          })}
        </div>
        <div className={`containerBtns`}>
          <button
            className={`btnSalvar`}
            onClick={() => {
              saveEvent();
            }}
          >
            Salvar
          </button>
          <button
            className={!finishStatus ? "inativeFinish" : ""}
            onClick={() => {
              finishEventEvaluation();
            }}
          >
            Enviar avaliação
          </button>
          <p className="textFinish"> Sua avaliação já foi recebida. </p>
        </div>
      </div>
    </div>
  );
};

export default EvaluationImpact;
