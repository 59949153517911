import "./styles.modules.scss";

import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";

import { MdInsertPhoto } from "react-icons/md";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Field, ErrorMessage } from "formik";
import imageCompression from "browser-image-compression";

import { uploadImageApi } from "../../../../../services/NexiDigitalAPI";
import { RECreateContext } from "../../Contexts/REContext";

const BasicInformation = (props) => {
  const { evaluationForm } = props;
  const {
    currentEvaluation,
    setCurrentEvaluation,
    imageEvaluation,
    setImageEvaluation,
    setTitleEvaluation,
    setDescriptionEvaluation,
    evaluationModel,
  } = useContext(RECreateContext);

  const handleChange = React.useCallback((event) => {
    setCurrentEvaluation({ ...currentEvaluation, [event.target.name]: event.currentTarget.value });

    if (event.target.name === "title") {
      setTitleEvaluation(event.currentTarget.value);
    }

    if (event.target.name === "description") {
      setDescriptionEvaluation(event.currentTarget.value);
    }

    evaluationForm.current?.handleChange(event);
  }, []);

  const [carregaImagem, setCarregaImagem] = useState(false);

  const uploadImagem = async (e) => {
    const arquivo = e.target;
    const imageFile = arquivo.files[0];
    const options = {
      maxSizeMB: 0.9,
      // maxWidthOrHeight: 1920
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      const filename = "kEInfoBas" + "-" + Date.now();
      const data = new FormData(); // If file selected

      if (compressedFile) {
        data.append("fileName", filename);
        data.append("fileData", compressedFile);
        uploadImageApi(data)
          .then((response) => {
            if (response.data?.error) {
              setCurrentEvaluation({ ...currentEvaluation, image: "" });
              setCarregaImagem(false);
              console.log("Erro ao fazer upload do arquivo");
            } else {
              setTimeout(() => {
                setCurrentEvaluation({
                  ...currentEvaluation,
                  image: response.data?.location,
                });
                setCarregaImagem(false);
              }, 1000 * 1);

              // evaluationForm.current?.setFieldValue("imageCloud", response.data?.location);
              setImageEvaluation(response.data?.location);

              console.log("Imagem atualizada com successo");
            }
          })
          .catch((error) => {
            // If another error
            console.log("error", error);
            setCurrentEvaluation({ ...currentEvaluation, image: "" });
            setCarregaImagem(false);
            console.log("Erro ao fazer upload do arquivo");
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeImage = React.useCallback((event) => {
    setCarregaImagem(true);
    uploadImagem(event);
    evaluationForm.current?.handleChange(event);
  }, []);

  return (
    <div className="KEInfoBas">
      <div className={`KEForm KEFormName`}>
        <label htmlFor="title">* Título (obrigatório)</label>
        <Field
          type="text"
          id="title"
          className={`form-control`}
          onChange={handleChange}
          maxLength="50"
          name="title"
          placeholder="Título da sua avaliação de reação"
        />
        <div className="character-counter">
          <small>
            {evaluationForm.current && evaluationForm.current.values.title
              ? evaluationForm.current.values.title.length
              : 0}
            /50
          </small>
        </div>
        <ErrorMessage name="title" render={(msg) => <div className={"errorMessage"}>{msg}</div>} />
      </div>

      <div className="KEForm KEFormDescricao">
        <label htmlFor="description">Descrição</label>
        <Field
          as={"textarea"}
          rows={4}
          className="form-control"
          id="description"
          aria-describedby="description"
          onChange={handleChange}
          maxLength="150"
          name="description"
          placeholder="Descrição da avaliação"
        />
        <div className="character-counter">
          <small>
            {evaluationForm.current && evaluationForm.current.values.description
              ? evaluationForm.current.values.description.length
              : 0}
            /150
          </small>
        </div>
      </div>

      {evaluationModel && evaluationModel.evaluacionType === "REACTION" && (
        <div className="KEForm KEFormDescricao">
          <label htmlFor="closingText">Encerramento</label>
          <Field
            as={"textarea"}
            rows={4}
            className="form-control"
            id="closingText"
            aria-describedby="closingText"
            onChange={handleChange}
            maxLength="250"
            name="closingText"
            placeholder="Texto de encerramento"
          />
          <div className="character-counter">
            <small>
              {evaluationForm.current && evaluationForm.current.values.closingText
                ? evaluationForm.current.values.closingText.length
                : 0}
              /250
            </small>
          </div>
        </div>
      )}

      {evaluationModel && evaluationModel.evaluacionType !== "IMPACT" && (
        <>
          <div className="KEForm KEFormDescricao">
            <label htmlFor="description">Adicionar uma imagem</label>
            <div className="conteudoPhoto">
              {carregaImagem ? (
                <CircularProgress size={50} />
              ) : (
                <div className="content-page-img-container">
                  {imageEvaluation ? (
                    <img src={imageEvaluation} alt="Imagem" className="" />
                  ) : (
                    <MdInsertPhoto />
                  )}
                </div>
              )}
            </div>
            <div className="btnPhoto">
              <Button variant="primary" className="">
                Escolher arquivo
              </Button>
            </div>
            <Field
              as="input"
              type="file"
              multiple={true}
              className="form-control-file"
              id="imagem"
              name="image"
              accept="image/png, image/jpeg, image/gif"
              onChange={handleChangeImage}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(BasicInformation);
