import "./styles.modules.scss";

import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Tags from "components/Tags";
import _ from "underscore";

import { RECreateContext } from "../../Contexts/REContext";

const TagsEvaluation = (props) => {
  const { containerTags, tagEnd, setTagEnd } = props;

  const { contextTagsInit } = useContext(RECreateContext);

  const [listRegistered, setListRegistered] = useState([]);

  useEffect(() => {
    if (contextTagsInit) {
      const tagSearch = contextTagsInit.data.tagsearch;
      const tagsSorted = _.sortBy(tagSearch, "tag");

      let tagsSelected = [];

      const objListTags = tagsSorted.map((item) => {
        const eTags = containerTags;

        if (eTags.length > 0) {
          eTags.map((ixt) => {
            if (ixt["idTag"] === item.idTag) tagsSelected.push({ id: item.idTag, name: item.tag });
          });
        }

        return { id: item.idTag, name: item.tag };
      });

      setListRegistered(objListTags);
      setTagEnd(tagsSelected);
    }
  }, [contextTagsInit]);

  return (
    <Tags
      handleNewTags={(newTags) => setTagEnd(newTags)}
      lista={listRegistered}
      contentTags={tagEnd}
    />
  );
};

export default withRouter(TagsEvaluation);
