import { modelQuestion } from "utils/Utils";

export const checkSaveBlock = (
  typeQuestion,
  statement,
  questionScales,
  questionOptions,
  setSaveBlock,
) => {
  if (typeQuestion === modelQuestion.LIKERT_SCALE) {
    if (statement && statement.length > 0 && questionScales.length > 0) {
      setSaveBlock(false);
    } else {
      setSaveBlock(true);
    }
  } else if (typeQuestion === modelQuestion.MULTI_LIKERT_SCALE) {
    if (
      statement &&
      statement.length > 0 &&
      questionScales.length > 0 &&
      questionOptions.length > 0
    ) {
      setSaveBlock(false);
    } else {
      setSaveBlock(true);
    }
  } else if (typeQuestion === modelQuestion.SINGLE_OPTION_COMMENT) {
    if (statement && statement.length > 0 && questionOptions.length > 0) {
      setSaveBlock(false);
    } else {
      setSaveBlock(true);
    }
  } else if (typeQuestion === modelQuestion.COMMENT) {
    if (statement && statement.length > 0) {
      setSaveBlock(false);
    } else {
      setSaveBlock(true);
    }
  } else if (
    typeQuestion === modelQuestion.MULTI_OPTION ||
    typeQuestion === modelQuestion.SINGLE_OPTION ||
    typeQuestion === modelQuestion.TRUE_OR_FALSE
  ) {
    const questionOptionsIscorrect = questionOptions.filter((it) => it.isCorrect === 1).length > 0;
    
    if (
      statement &&
      statement.length > 0 &&
      questionOptions.length > 0 &&
      questionOptionsIscorrect
    ) {
      setSaveBlock(false);
    } else {
      setSaveBlock(true);
    }
  } else if (typeQuestion === modelQuestion.CORRELATE) {
    const questionOptionsFilled = questionOptions.every((question) => {
      const isDescriptionFilled = question.description && question.description.trim().length > 0;
      const isNestedDescriptionFilled =
        question.questionOptionAnswer &&
        question.questionOptionAnswer.description &&
        question.questionOptionAnswer.description.trim().length > 0;

      return isDescriptionFilled && isNestedDescriptionFilled;
    });

    setSaveBlock(!questionOptionsFilled);
  }
};
