import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { removeHtmlTags } from "utils/Utils";
import styles from "./styles.module.scss";

const enumQuestionType = (value) => {
  switch (value) {
    case "MULTI_LIKERT_SCALE":
      return "Likert Múltipla";
    case "LIKERT_SCALE":
      return "Likert";
    case "SINGLE_OPTION_COMMENT":
      return "Seleção+Texto";
    default:
      break;
  }
};

const Table = ({ dados, loading, actionFilter, totalRecords }) => {
  const columns = [
    {
      name: "Treinamento",
      selector: "Treinamento",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Modalidade",
      selector: "Modalidade",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },

    {
      name: "Turma",
      selector: "Turma",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },

    {
      name: "Nome do gestor",
      selector: "Nome do gestor",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "E-mail do gestor",
      selector: "E-mail do gestor",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Empresa",
      selector: "Empresa",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Cargo do Gestor",
      selector: "Cargo do Gestor",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Usuário (aluno)",
      selector: "Usuário (aluno)",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Cargo do aluno",
      selector: "Cargo do aluno",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Departamento",
      selector: "Departamento",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Unidade",
      selector: "Unidade",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Avaliação",
      selector: "Avaliação",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Tipo da questão",
      selector: "Tipo da questão",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Questão",
      selector: "Questão",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center", maxWidth: "400px", },
      width: 100,
      cell: (d) => <>{d['Questão']? removeHtmlTags(d['Questão'], true) : null}</>,
    },
    {
      name: "Resposta",
      selector: "Resposta",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => <>{d.Resposta ? removeHtmlTags(d.Resposta, true) : null}</>,
    },
    {
      name: "Data de disponibilização",
      selector: "Data de disponibilização",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Prazo para finalização",
      selector: "Prazo para finalização",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Status da avaliação",
      selector: "Status da avaliação",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Data de finalização",
      selector: "Data de finalização",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
  ];

  const customTableStyles = {
    rows: {
      style: {
        minHeight: "60px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "30px", // override the cell padding for head cells
        textAlign: "center",
      },
    },
    cells: {
      style: {
        paddingLeft: "30px", // override the cell padding for data cells
      },
    },
    header: {
      style: {
        fontSize: "22px",
        minHeight: "56px",
        paddingLeft: "28px",
        paddingRight: "8px",
        paddingTop: "30px",
        paddingBottom: "15px",
      },
    },
  };

  const tableData = {
    columns: columns,
    data: dados,
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Questões por página",
    rangeSeparatorText: "de",
  };

  const onChangePage = (page, totalRows) => {
    actionFilter({ offset: --page * dados.length });
  };

  const onChangeRowsPerPage = (limit) => {
    actionFilter({ limit, offset: 0 });
  };

  const loadingComponent = () => {
    return (
      <div className="tela-login-loading">
        <div className="loader-container" style={{ margin: "300px 0" }}>
          <div
            style={{
              position: "initial",
              margin: "initial",
            }}
            className="loader loader-center loader-big"
          ></div>
          <span
            style={{
              marginTop: 10,
              fontSize: 20,
            }}
          >
            Carregando dados...
          </span>
        </div>
      </div>
    );
  };

  return (
    <DataTableExtensions
      filter={false}
      print={false}
      {...tableData}
      exportHeaders={false}
      export={false}
    >
      <DataTable
        columns={columns}
        data={dados}
        className={styles.dataTableFormat}
        customStyles={customTableStyles}
        progressPending={loading}
        progressComponent={loadingComponent()}
        noHeader={true}
        noDataComponent="Não foi encontrado nenhum dado"
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        paginationTotalRows={totalRecords}
        paginationComponentOptions={paginationComponentOptions}
        paginationServer
        pagination
      />
    </DataTableExtensions>
  );
};

export default Table;
