import React, { useRef, useState } from "react";
import { CgImage } from "react-icons/cg";
import "./style.scss";
import { ContentData } from "../../nodeParamenters.model";
import ModalContent from "containers/PeopleCraft/Modals/ModalContent";
import ContentImageUploadV2 from "components/ContentImageUploadV2";
import { editLearningNode } from "services/NexiDigitalAPI";

import { AiOutlineReload } from "react-icons/ai";

type Props = {
  contentData: ContentData;
  onCreateImage?: any;
  handleCallback?: () => void;
  disabled: boolean
};
const ImageComponent: React.FC<Props> = ({ disabled = false , contentData, handleCallback, onCreateImage }) => {
  const [showModalImage, setShowModalImage] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  const contentImageUploadRef = useRef(null);
  const handleClick = (event) => {
    if(disabled){
      return
    }
    //logica modal
    setShowModalImage(true);
    handleCallback && handleCallback();
  };

  const loadedImg = (e) => {
    const image = e.currentTarget;
    const interval = setInterval(() => {
      if (image.naturalWidth > 0 && image.naturalHeight > 0) {
        clearInterval(interval);
        setLoadingImage(false);
      }
    }, 20);
  };

  return (
    <div className="image-component">
      <ModalContent
        title={
          contentData.image
            ? "ATUALIZE A IMAGEM E PERSONALIZE O FORMATO DE SEUS CARDS"
            : "ADICIONE A IMAGEM E PERSONALIZE O FORMATO DE SEUS CARDS"
        }
        text={
          contentData.image
            ? "Veja aqui todos os formatos disponíveis para seus cards, dentro da plataforma."
            : ""
        }
        show={showModalImage}
        setShow={setShowModalImage}
        btnCall={{
          className: "",
          variantBtn: "success",
          text: "SALVAR",
          closeModal: false,
          callEvent: async () => {
            const imageBaseUrl = await contentImageUploadRef.current.uploadImagem();

            if (contentData.image === null || (contentData.image === "" && imageBaseUrl != null)) {
              await editLearningNode(contentData.idNode, { image: imageBaseUrl });
              onCreateImage(imageBaseUrl);
            }

            setLoadingImage(true);
            setShowModalImage(false);
          },
        }}
        innerContent={
          <ContentImageUploadV2
            data={contentData}
            reference={contentImageUploadRef}
            // sendData={sendData}
            defaultPrefixName="node"
          />
        }
      />
      <div className="image-section">
        <div onClick={handleClick} className={`circle ${contentData.image && "image"}`}>
          {loadingImage && (
            <div className="loadingImage">
              <AiOutlineReload />
            </div>
          )}
          {!contentData.image ? (
            <CgImage size={32} />
          ) : (
            <img
              src={`${contentData.image}?${Date.now()}`}
              alt="Imagem"
              onLoad={(e) => {
                loadedImg(e);
              }}
            />
          )}
        </div>
        <button className={`button-class ${disabled && 'disabled-button'}`} onClick={handleClick}>
          Editar Imagem
        </button>
      </div>
      <div className="text-section">
        <p>Tamanho recomendado para melhor adaptação em todos os formatos: 1920x900px.</p>
        {/* <p className="warning-text">
          <span>Atenção:</span> Ao pressionar o botão editar imagem, você pode substituir todas as imagens de uma só vez, mas caso pretenda apenas modificar um dos formatos você também pode editar diretamente nele. Após qualquer alteração é necessário clicar no botão salvar.
        </p> */}
      </div>
    </div>
  );
};

export default ImageComponent;
