import React, { useState, useEffect } from "react";

import moment from "moment";
import "moment/locale/pt-br.js";

// Page UI and icons
import { Col, Form } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import { getMenuData } from "../../../../../services/NexiDigitalAPI";
import FiltroWrapper from "../../../Components/FiltroWrapper";
import styles from "./styles.module.scss";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import CompaniesModal from "../../../../../components/CompaniesModal/CompaniesModal";

const Filtro = ({ actionFilter, loading, expanded, downloadEvent }) => {
  const [values, setValues] = useState({
    startDate: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
    finishDate: moment(new Date()).format("YYYY-MM-DD"),
    idCompany: getMenuData().idCompany,
    searchString: "",
    offset: 0,
    limit: 10,
  });

  const [selectedCompany, setSelectedCompany] = useState({});
  const adminLevel = getMenuData()?.admin;

  function handleFilter() {
    if (selectedCompany) {
      return actionFilter({
        ...values,
        idCompany: selectedCompany.idCompany === "all" ? undefined : selectedCompany.idCompany,
      }, 'DASHBOARD');
    }
    actionFilter(values, 'DASHBOARD');
  }

  function handleDownload() {
    if (selectedCompany) {
      return actionFilter({
        ...values,
        idCompany: selectedCompany.idCompany === "all" ? undefined : selectedCompany.idCompany,
      }, 'DOWNLOAD');
    }
    actionFilter(values, 'DOWNLOAD');
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      borderRadius: "0 0 1px 1px",
      border: "1px solid #ced4da",
    }),
  };

  function handleDateStart(jsDate) {
    let date = moment(jsDate).format("YYYY-MM-DD");
    setValues({
      ...values,
      startDate: date,
    });
  }

  function handleDateFinish(jsDate) {
    let date = moment(jsDate).format("YYYY-MM-DD");
    setValues({
      ...values,
      finishDate: date,
    });
  }

  function isAdminSecondDegree() {
    return adminLevel >= 2;
  }

  useEffect(() => {
    if (expanded) {
      selectedCompany?.isUserCompany && handleFilter();
    }
  }, [selectedCompany, expanded]);

  return (
    <FiltroWrapper>
      <Form style={{ marginTop: 30, marginBottom: 30 }}>
        <Form.Row className="align-items-end">
          <Form.Row className="col-md-12 mb-2" style={{ padding: 5 }}>
            <CompaniesModal
              showAllCompaniesOption
              showInput={isAdminSecondDegree()}
              setSelectedCompany={setSelectedCompany}
              selectedCompany={selectedCompany}
            />
          </Form.Row>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="startDate">Data de Início</Form.Label>
            <DatePickerInput locale="pt-br" onChange={handleDateStart} value={values.startDate} />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="endDate">Data de Término</Form.Label>
            <DatePickerInput locale="pt-br" onChange={handleDateFinish} value={values.finishDate} />
          </Col>
          <Col xs="12" md="4" lg="3" className={styles.formSpace}>
            <Form.Label htmlFor="searchString">Usuário</Form.Label>
            <Form.Control
              id="searchString"
              value={values.searchString}
              type="text"
              placeholder="Digite um nome"
              styles={customStyles}
              onChange={(e) => setValues({ ...values, searchString: e.target.value })}
            />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Group className="mb-0">
              <ButtonWithLoading
                onClickHandler={handleFilter}
                loading={loading}
                text={
                  <>
                    Aplicar filtro <FaFilter style={{ marginLeft: 5 }} />{" "}
                  </>
                }
                style={{ width: "100%" }}
              />
            </Form.Group>
          </Col>
          <Col xs="12" md="4" lg="3" className={styles.formSpace}>
            <Form.Group className="mb-0">
              <ButtonWithLoading
                onClickHandler={handleDownload}
                loading={loading}
                text={<>Download da planilha</>}
                style={{ width: "100%", backgroundColor: "#0d6efd", borderColor: "#0d6efd" }}
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    </FiltroWrapper>
  );
};

export default Filtro;
