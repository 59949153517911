import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import "./style.scss";
import { useParams } from "react-router-dom";
import "moment/locale/pt-br";
import { MdExpandLess } from "react-icons/md";
import Label from "components/PeopleCraft/Label";
import Switch from "react-bootstrap/esm/Switch";
import { InteractionChats } from "./components/interactionChat";
import { getNodesBreadcrumb, putLearningConfiguration } from "services/NexiDigitalAPI";
import { Breadcrumb } from "react-bootstrap";
import { Skeleton } from "@material-ui/lab";
import MessageItem from "components/MessageItem";
import { getMenuRoutes } from "utils/Utils";

const InteractionDrawer = ({
  isOpen,
  toggleSidebar,
  title = "Interações e mensagens",
  informationNode = null,
  editPropsNodes = null
}) => {
  const [formValues, setFormValue] = useState({
    allowComments: false,
    commentsLabel: title,
  });
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingInformationPage, setLoadingInformationPage] = useState(false);
  const [dataParent, setDataParent] = useState(null);
  const modManager = Boolean(
    (
      getMenuRoutes().find((option) => option.idSectionType === 62 && option.enabled)?.resource ||
      []
    ).find((res) => res.idResource === 50),
  )

  const { idTeam } = useParams();

  const infoParamsRequest = useMemo(() => {
    return {
      idEntity: informationNode ? informationNode.nodeCode : idTeam,
      entityType: informationNode ? "NODE" : "TEAM",
    };
  }, [idTeam, informationNode]);
  const isVisibleChat = useMemo(() => {
    return infoParamsRequest.entityType === "NODE" ? formValues.allowComments : true;
  }, [formValues, infoParamsRequest.entityType]);

  const updateConfig = async (item) => {
    try {
      await putLearningConfiguration(item, informationNode.nodeCode);
      editPropsNodes(item, informationNode);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (informationNode) {
      setLoadingInformationPage(true);
      getNodesBreadcrumb(informationNode.nodeCode)
        .then((res) => {
          setFormValue({
            allowComments: res.data?.allowComments,
            commentsLabel: res.data?.commentsLabel,
          });
          setDataParent(res.data);
        })
        .finally(() => {setLoadingInformationPage(false)});
    }
  }, [informationNode]);

  const BreadcrumbControl = useMemo(() => {
    if (!dataParent) {
      return <Skeleton variant="rectangular" width={100} height={10} />;
    }
    return (
      <div className="breadcrumb-control-interaction">
        <Breadcrumb>
          {dataParent.parentNodes.map((it, i) => {
            if (it) {
              return (
                <Breadcrumb.Item key={i} active={false} className="breadcrumb-parent-title-interaction">
                  <span>{it.title}</span>
                </Breadcrumb.Item>
              );
            }
          })}
          <Breadcrumb.Item className="current-page-breadcrumb" key={"currentPage"} active={true}>
            <span>{dataParent.title}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
    );
  }, [dataParent]);

  const pageConfig = useMemo(() => (
    <>
      {loadingInformationPage ? (
          <Skeleton variant="rectangular" width={'100%'} height={100} />
      ) : (
        <Accordion
          className="interaction-drawer-accordion"
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded}
          onChange={(e) => setExpanded((prevState) => !prevState)}
        >
          <AccordionSummary expandIcon={<MdExpandLess />}>
            <span className="accordion-title">Configurar interações na pagina</span>
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            <p className=" accordion-title accordion-label">Rótulo de interações na página</p>
            <p className="accordion-subtitle">
              Caso a interação na turma também seja habilitada, procure utilizar textos que deixem
              claro para o usuário que está é a interação na pagina
            </p>
            <div className="form-data">
              <input
                key={"interaçao"}
                placeholder="Interação"
                required
                value={formValues.commentsLabel || null}
                onChange={(e) => {
                  e.persist();
                  setFormValue((prevState) => ({
                    ...prevState,
                    commentsLabel: e.target?.value,
                  }));
                }}
                onBlur={(e) => {
                  e.persist();
                  setFormValue((prevState) => ({
                    ...prevState,
                    commentsLabel: e.target?.value || "Interações e mensagens",
                  }));
                  updateConfig({
                    commentsLabel: e.target?.value || "Interações e mensagens",
                  });
                }}
                id="interaçao"
                maxLength={50}
                className="form-control"
                type="text"
              />
            </div>
            <hr />
            <div className="flex-row ">
              <Switch
                color="primary"
                id="EnablePageInteractions"
                checked={!!formValues.allowComments}
                onChange={(e) => {
                  setLoading(true);
                  e.persist();
                  setFormValue((prevState) => ({
                    ...prevState,
                    allowComments: !!e.target?.checked,
                  }));
                  updateConfig({ allowComments: !!e.target?.checked ? 1 : 0 });
                }}
              />
              <Label
                className={"cursor-pointer"}
                name="Habilitar interações e comentários na página"
                htmlFor="EnablePageInteractions "
              />
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  ), [expanded, formValues.allowComments, formValues.commentsLabel, loadingInformationPage]);

  return (
    <>
      <Drawer anchor="right" open={isOpen} onClose={toggleSidebar} disableEnforceFocus>
        <div className="interaction-drawer-container" role="presentation">
          <div className={`interaction-drawer-header-title ${!informationNode && 'flex-end'}`}>
            {Boolean(informationNode) && BreadcrumbControl}
            <IconButton onClick={toggleSidebar} className="interaction-drawer-close-button">
              <CloseIcon />
            </IconButton>
          </div>
          {Boolean(informationNode) && pageConfig}
          {loading ? (
            <div className="interaction-drawer-title">
              {Array.from(new Array(5)).map((_, index) => (
                <MessageItem index={index} loading={true} />
              ))}
            </div>
          ) : (
            Boolean(isVisibleChat) && (modManager) && (
              <InteractionChats
                title={formValues.commentsLabel}
                informationNode={informationNode}
                infoParamsRequest={infoParamsRequest}
              />
            )
          )}
        </div>
      </Drawer>
    </>
  );
};

InteractionDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default InteractionDrawer;
