import { fabric } from "fabric";

export const imgBASE64 = (e, type, canvas, callImg = null) => {
  const widthCanvas = 1124;
  const heightCanvas = 794;

  const resizeBase64Image = (base64, width, height) => {
    // Create a canvas element
    const canvas = document.createElement("canvas");

    // Create an image element from the base64 string
    const image = new Image();
    image.src = base64;

    // Return a Promise that resolves when the image has loaded
    return new Promise((resolve, reject) => {
      image.onload = () => {
        // // Calculate the aspect ratio of the image
        // const aspectRatio = image.width / image.height;

        // // Calculate the best fit dimensions for the canvas
        // if (width / height > aspectRatio) {
        //   canvas.width = height * aspectRatio;
        //   canvas.height = height;
        // } else {
        //   canvas.width = width;
        //   canvas.height = width / aspectRatio;
        // }

        canvas.width = width;
        canvas.height = height;

        // Draw the image to the canvas
        canvas.getContext("2d").drawImage(image, 0, 0, canvas.width, canvas.height);

        // Resolve the Promise with the resized image as a base64 string
        resolve(canvas.toDataURL());
      };

      image.onerror = reject;
    });
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  let file = e.target.files[0];
  e.target.value = "";

  const createBase64Image = (result, widthCanvas, heightCanvas) => {
    resizeBase64Image(result, widthCanvas, heightCanvas).then((data) => {
      const img = new Image();
      img.src = data;

      img.onload = (e) => {
        const f_img = new fabric.Image(img, { lockRotation: true });
        if (canvas.backgroundImage) {
          canvas.setBackgroundImage(null);
          canvas.renderAll();
        }

        callImg(f_img, data);
        canvas.setBackgroundImage(
          f_img,
          () => {
            canvas.renderAll();
          },
          {
            width: widthCanvas,
            height: heightCanvas,
          },
        );
      };
    });
  };

  getBase64(file)
    .then((result) => {
      file["base64"] = result;

      if (type === "background") {
        createBase64Image(result, widthCanvas, heightCanvas);
      } else {
        const img = new Image();
        img.src = result;
        img.onload = (e) => {
          const f_img = new fabric.Image(img, { lockRotation: true });
          canvas.add(f_img);
          canvas.renderAll();

          //
          callImg(f_img, result);
        };
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
