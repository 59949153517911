import React from "react";
import "./index.css";

const BreadCrumb = ({ data, index, paddingHorizontal = "0px" }) => {
  return (
    <div className="breadcrumb-container" style={{ padding: `0px ${paddingHorizontal}` }}>
      {data.map((breadCrumbItem, breadCrumbIndex) => (
        <div style={{ display: "contents" }} key={`breadcump-${breadCrumbIndex}`}>
          <div key={breadCrumbIndex} className="breadcrumb-container-item">
            <div
              style={{ backgroundColor: breadCrumbIndex <= index ? "#6b48ff" : "rgba(0,0,0,0.1)" }}
              className="breadcrumb-ball"
            >
              {breadCrumbIndex + 1}
            </div>
            <span
              style={{ color: breadCrumbIndex <= index ? "#6b48ff" : "rgba(0,0,0,0.1)" }}
              className="breadcrumb-text"
            >
              {breadCrumbItem.title}
            </span>
          </div>
          {breadCrumbIndex + 1 < data?.length && (
            <div
              key={`breadline-${breadCrumbIndex}`}
              style={{ backgroundColor: breadCrumbIndex <= index ? "#6b48ff" : "rgba(0,0,0,0.1)" }}
              className="breadcrumb-line"
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default BreadCrumb;
