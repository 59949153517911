import "./style.scss";
import React, { useState, useContext, useEffect } from "react";

import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as yup from "yup";

import { useManagerTrial } from "../../context/contextManagerTrial";
import { FormProps, PlaneProps, Status } from "../../model/ManagerTrial.model";
import { getProfileInfo } from "utils/Utils";
import Button from "components/PeopleCraft/Button";
import { getUser, postRequestTrialPlans, renewLogin } from "services/NexiDigitalAPI";
import Text from "../../../Text";
import CustomTooltip from "components/CustomTooltip";
import { AuthContext } from "context/AuthContext";
import ModalDialog from "containers/PeopleCraft/Modals/ModalDialog";

const schema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  email: yup.string().email("E-mail inválido.").required("E-mail obrigatório"),
  company: yup.string(),
  role: yup.string().required("Cargo obrigatório"),
  department: yup.string().required("Depatamento obrigatório"),
  description: yup.string(),
  numberOfEmployees: yup.string(),
});

const FormUpgrade = ({ closed }: { closed: (message?) => void }) => {
  const { setUpgradeStatus, currentCardUpgrade, setCurrentCardUpgrade } = useManagerTrial();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalErro, setModalErro] = useState<boolean>(false);
  const [messageErro, setMessageErro] = useState<string>('');
  const authContext = useContext(AuthContext);
  const { name, idPlan } = currentCardUpgrade as PlaneProps;
  const selects = [
    { value: "1, 100", label: "1 - 100" },
    { value: "251,500", label: "251 - 500" },
    { value: "501, 2500", label: "501 - 2500" },
    { value: "2500", label: "2500" },
  ];

  const specialPlan = name === "Enterprise";

  const [formItem, setFormItem] = useState<FormProps | null>(null);
  const _getUserInfo = (id) => {
    getUser(id).then((res) => {
      const { user, company } = res.data;

      const userData = {
        idUser: user.idUser,
        idCompany: user.idCompany,
        company: company.name,
        email: user.email,
        name: user.name,
        role: user.role,
        department: user.department,
        description: "",
        numberOfEmployees: "1,100",
      };

      setFormItem(userData);
    });
  };

  useEffect(() => {
    const _user = getProfileInfo();
    _getUserInfo(_user.idUser);
  }, []);

  async function updateRenewLogin(data) {
    if (data.success === true && data.login.active === 1) {
      const menuOption = data?.menu?.[0]?.menuOption || [];
      localStorage.setItem("menu-option", JSON.stringify(menuOption));
      authContext.signIn(data.token, data.login);
      authContext.updateLicense(await authContext.getDataTrivial());
      setUpgradeStatus(Status.feedback);
      setLoading(false);
    }
  }

  const postInitTrial = async (data) => {
    setLoading(true);
    try {
      const {
        data: { adminDomain, token },
      } = await postRequestTrialPlans({ ...data, idPlan });
        setCurrentCardUpgrade({ ...currentCardUpgrade, token, domain: adminDomain });
        const result  = await renewLogin()
       updateRenewLogin(result.data)
    } catch (e) {
       const result  = await renewLogin()
       updateRenewLogin(result.data)
      closed( e.response.data.message === "Já existe um trial liberado."
      ? "Seu trial já foi ativado, para acessar o APP vá em perfil para poder acessar o aplicativo."
      : e.response.data.message)
    }
  };

  return (
    <div className={`pctUpgradeForm`}>
      {!formItem || loading ? (
        <>
          <div className="loaderAdmin">
            <div className="preloaderIt"></div>
          </div>
        </>
      ) : (
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            const arrayNumberOfEmployess = values.numberOfEmployees.toString().split(",");
            postInitTrial({
              ...values,
              numberOfEmployees: arrayNumberOfEmployess.map((str) => Number(str)),
            });
          }}
          initialValues={{
            ...formItem,
            role: formItem.role || "",
            department: formItem.department || "",
            description: formItem.description || "",
            numberOfEmployees: formItem.numberOfEmployees || "1,100",
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, touched, values, errors }) => (
            <>
              <Form noValidate onSubmit={handleSubmit}>
                <div className="pctUpgradeFormBase">
                  <Text
                    component={"h3"}
                    text={`Se cadastre para o trial de <span>30 dias</span> do <span>Plano ${name}!</span>`}
                  />

                  <Form.Group className="profile-edit-item section-form-edit">
                    <Form.Label className="profile-edit-label  disabled-label">
                      Nome completo *
                    </Form.Label>
                    <CustomTooltip title={"Dados já cadastrados anteriormente"} placement="bottom">
                      <Form.Control
                        disabled
                        type="text"
                        name="name"
                        placeholder="Nome"
                        value={values.name}
                        className="form-control  "
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.name}
                      />
                    </CustomTooltip>
                  </Form.Group>

                  {values.email && (
                    <Form.Group className="profile-edit-item section-form-edit">
                      <Form.Label className="profile-edit-label  disabled-label">
                        E-mail comercial *
                      </Form.Label>
                      <CustomTooltip
                        className="zIndex"
                        title={"Dados já cadastrados anteriormente"}
                        placement="top"
                      >
                        <Form.Control
                          disabled
                          type="text"
                          name="email"
                          placeholder="Email"
                          value={values.email as string}
                          className="form-control edit-login-input "
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.email}
                        />
                      </CustomTooltip>
                      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                  )}

                  <div className="profile-list">
                    {
                      <Form.Group className="profile-edit-item section-form-edit">
                        <Form.Label className="profile-edit-label">Cargo *</Form.Label>
                        <Form.Control
                          name="role"
                          placeholder="Ex: coordenador, gerente"
                          value={values.role as string}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control edit-login-input"
                          isInvalid={!!errors.role && touched.role}
                        />
                        <Form.Control.Feedback type="invalid">{errors.role}</Form.Control.Feedback>
                      </Form.Group>
                    }
                    {
                      <Form.Group className="profile-edit-item section-form-edit">
                        <Form.Label className="profile-edit-label">Departamento *</Form.Label>
                        <Form.Control
                          name="department"
                          placeholder="Ex: comercial, tecnologia"
                          value={values.department as string}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control edit-login-input"
                          isInvalid={!!errors.department && touched.department}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.department}
                        </Form.Control.Feedback>
                      </Form.Group>
                    }
                  </div>

                  <div className="profile-list">
                    {
                      <Form.Group className="profile-edit-item section-form-edit">
                        <Form.Label className="profile-edit-label disabled-label">
                          Empresa *
                        </Form.Label>
                        <CustomTooltip title={"Dados já cadastrados anteriormente"} placement="top">
                          <Form.Control
                            disabled
                            name="company"
                            placeholder="Ex: Empresa SA"
                            // value={values.email}
                            className="form-control edit-login-input"
                            value={values.company as string}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.company && touched.company}
                          />
                        </CustomTooltip>
                        <Form.Control.Feedback type="invalid">
                          {errors.company}
                        </Form.Control.Feedback>
                      </Form.Group>
                    }

                    <Form.Group className="profile-edit-item section-form-edit">
                      <Form.Label className="profile-edit-label">
                        Quantidade de funcionários *
                      </Form.Label>
                      <Form.Group>
                        <Form.Control
                          as="select"
                          name="numberOfEmployees"
                          value={values.numberOfEmployees as string}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {selects.map((elem, index) => (
                            <option key={index} value={elem.value}>
                              {elem.label}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Form.Group>
                  </div>

                  {specialPlan && (
                    <Form.Group className="profile-edit-item section-form-edit profile-edit-textarea">
                      <Form.Label>Como nosso time comercial pode ajudar? *</Form.Label>
                      <Form.Control
                        placeholder="Ex: Possuem alguma integração com... "
                        as="textarea"
                        name="description"
                        rows={4}
                        value={values.description as string}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.description}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                  <Form.Group className="profile-edit-item section-form-edit profile-edit-textarea">
                    <Form.Label>
                      OBS: Ao Iniciar o trial, os usuários cadastrados receberão um e-mail de boas
                      vindas para acesso a nova plataforma.
                    </Form.Label>
                  </Form.Group>
                </div>

                <div className="buttonsWrapper">
                  {
                    <Button
                      callEvent={() => {}}
                      className={`button06`}
                      attr={{ type: "submit" }}
                      text={`${specialPlan ? "Enviar mensagem" : "Iniciar trial"}`}
                    />
                  }
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
      <ModalDialog
        title={"O trial já foi ativado"}
        text={messageErro}
        show={modalErro}
        setShow={setModalErro}
        btnDenyCall={() => {
          setUpgradeStatus(Status.feedback);
          closed();
        }}
        btnCall={{alertMode: true}}
      />
    </div>
  );
};

export default FormUpgrade;
