import "./styles.scss";

import React, { Fragment } from "react";

import { DropdownSkyn } from "./components/DropdownSkyn";

const TreeDropdown = ({
  childORG,
  onSelectCallback,
  nodeCurrent,
  preload,
  deleteItem,
  viewTeam,
  setIsSidebarOpen
}) => {
  const createTree = (branch) => {
    return (
      (branch.childNodes) && (
        <DropdownSkyn
          branch={branch}
          key={branch.nodeCode}
          nodeCurrent={nodeCurrent}
          onSelectCallback={(e, updateAuthor) => {
            onSelectCallback(branch, e, updateAuthor);
            e.preventDefault();
          }}
          preload={preload}
          deleteItem={deleteItem}
          viewTeam={viewTeam}
          setIsSidebarOpen={setIsSidebarOpen}
        >
        {
          (branch.status != "ARCHIVED") &&
            branch.childNodes.map((branch) => {
              return <Fragment key={branch.nodeCode}>{createTree(branch)}</Fragment>;
            })
        }


        </DropdownSkyn>
      )
    );
  };

  return (
    <>
      {childORG.length > 0 &&
        childORG.map((branch) => {
          return <Fragment key={branch.nodeCode}>{createTree(branch)}</Fragment>;
        })}
    </>
  );
};

export default TreeDropdown;
