import React, { useState, createContext, useReducer, useContext } from "react";

import CEPreloaderBlur from "../Components/CEPreloaderBlur";

const initialState = {
  certificateSubmit: null,
};

const actions = {
  SUBMIT_CERTIFICATES: "SUBMIT_CERTIFICATES",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SUBMIT_CERTIFICATES: {
      return {
        certificateSubmit: () => {},
      };
    }

    default:
      return state;
  }
};

export const CEContainerContext = (props) => {
  const { preloadInit } = useContext(CECreateContext);

  return (
    <>
      {preloadInit && <CEPreloaderBlur />}
      {props.children}
    </>
  );
};

export const CECreateContext = createContext();

const CEContext = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [preloadInit, setPreloadInit] = useState(true);

  const [nameCertificates, setNameCertificates] = useState("");
  const [canvasCertificates, setCanvasCertificates] = useState(null);
  const [filterCertificates, setFilterCertificates] = useState(null);
  const [bgChange, setBgChange] = useState(null);
  const [tempJson, setTempJson] = useState(null);

  const [inputActive, setInputActive] = useState(null);

  const [typeView, setTypeView] = useState("certificate"); // or syllabus

  const [propStyle, setPropStyle] = useState({
    backgroundColor: "#fff",
    backgroundURL: null,
    fontSize: "29",
    fill: "#000000", //color da font
    textAlign: "center",
    fontWeight: "",
    fontStyle: "",
    underline: false,
    linethrough: false,
    overline: false,
    fontFamily: "Arial",
  });

  const [syllabus, setSyllabus] = useState(0);
  const [backgroundSyllabus, setBackgroundSyllabus] = useState({
    result: "#ffffff",
    type: "color",
  });

  const prop = 0.6;
  const wBase = 1120;
  const hBase = 790;
  const wProp = wBase * prop;
  const hProp = hBase * prop;

  const [syllabusState, setSyllabusState] = useState({
    prop: prop,
    wBase: wBase,
    hBase: hBase,
    wProp: wProp,
    hProp: hProp,
    x: wProp / 4,
    y: hProp / 4,
    width: wProp / 2,
    height: hProp / 2,
    color: "#000000",
  });

  const value = {
    certificateSubmit: state.certificateSubmit,
    submitCERTIFICATES: () => {
      dispatch({ type: actions.SUBMIT_CERTIFICATES });
    },

    preloadInit,
    setPreloadInit,

    propStyle,
    setPropStyle,

    bgChange,
    setBgChange,

    tempJson,
    setTempJson,

    inputActive,
    setInputActive,

    nameCertificates,
    setNameCertificates,

    canvasCertificates,
    setCanvasCertificates,

    filterCertificates,
    setFilterCertificates,

    typeView,
    setTypeView,

    syllabus,
    setSyllabus,

    backgroundSyllabus,
    setBackgroundSyllabus,

    syllabusState,
    setSyllabusState,
  };

  return <CECreateContext.Provider value={value}>{children}</CECreateContext.Provider>;
};

export default CEContext;
