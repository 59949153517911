import AdminUpgrade from "./components/AdminUpgrade";
import { ContextManagerTrial } from "./context/contextManagerTrial";
import { ManagerTrialProps } from "./model/ManagerTrial.model";
import "./styles.scss";

import React, { useState } from "react";

const ManagerTrial = (props: ManagerTrialProps) => {
  return (
    <ContextManagerTrial>
      {props.profile && <AdminUpgrade modal={props.modal} />}
    </ContextManagerTrial>
  );
};

export default ManagerTrial;
