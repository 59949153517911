import React, { useState, useEffect, useRef, useContext } from "react";
import {
  getSchedulesTypes,
  addSchedulesType,
  removeScheduleType,
  editScheduleType,
} from "../../services/NexiDigitalAPI";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditIcon from "@material-ui/icons/Edit";
import _ from "underscore";
import FilterResults from "react-filter-search";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { AuthContext } from "../../context/AuthContext";
import Loading from "../../components/Loading";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";

const CalendarCategoryManager = () => {
  const isMountedRef = useRef(null);
  const [scheduleTypeList, setScheduleTypeList] = useState([]);
  const [loadingScheduleTypes, setLoadingScheduleTypes] = useState(true);
  const [loadingAddScheduleTypes, setLoadingAddScheduleTypes] = useState(false);
  const [loadingRemoveScheduleTypes, setLoadingRemoveScheduleTypes] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [eventDescription, setEventDescription] = useState("");

  const { loginData } = useContext(AuthContext);
  const secondaryColor = loginData.secondaryColor;

  const inputRefs = useRef([]);

  const history = useHistory();

  useEffect(() => {
    isMountedRef.current = true;

    getSchedulesTypes()
      .then((res) => {
        if (res) {
          setScheduleTypeList(res);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingScheduleTypes(false);
      });

    return () => (isMountedRef.current = false);
  }, []);

  const _addSchedulesType = () => {
    if (eventDescription?.length === 0) {
      return;
    }
    const description = String(eventDescription);
    setEventDescription("");
    setLoadingAddScheduleTypes(true);
    addSchedulesType(description)
      .then((res) => {
        const idSchedule = res?.data?.idSchedule?.[0];
        const newScheduleType = {
          idScheduleType: idSchedule,
          description,
        };
        setScheduleTypeList([...scheduleTypeList, newScheduleType]);
      })
      .catch((err) => {
        console.log("addSchedulesType Error", err);
      })
      .finally(() => {
        setLoadingAddScheduleTypes(false);
      });
  };

  const handleDelete = (idSchedule) => {
    setLoadingRemoveScheduleTypes(idSchedule);
    const novaListaOrdenada = _.filter(scheduleTypeList, function (item) {
      return item.idScheduleType !== idSchedule;
    });

    removeScheduleType(idSchedule)
      .then((res) => {
        if (res) {
          setScheduleTypeList(novaListaOrdenada);
        }
      })
      .finally(() => {
        setLoadingRemoveScheduleTypes(false);
      });
  };

  const handleDescriptionChange = (description, index) => {
    let newScheduleTypeList = [...scheduleTypeList];
    newScheduleTypeList[index].description = description;

    setScheduleTypeList(newScheduleTypeList);
  };

  const handleEdit = (idSchedule, description) => {
    setIsEditing(false);
    editScheduleType(idSchedule, description);
  };

  return (
    <div className="tags">
      <div className="header-title-wrapper">
        <div className="header-title">
          <div
            onClick={() => history.goBack()}
            className="design-manager-back-container"
            style={{ marginBottom: "initial" }}
          >
            <ArrowBack style={{ marginRight: 10 }} />
          </div>
          Categoria de eventos
        </div>
      </div>

      {/* Cadastra novo tipo de evento */}
      <div className="post-form">
        <div className="form-group">
          <div className="card">
            <div
              className="card-header template-card-header"
              style={{ color: "#FFF", backgroundColor: secondaryColor }}
            >
              Cadastrar nova categoria de evento
            </div>
            <div className="card-body card-body-tags">
              <div style={{ flex: 1, marginRight: 20 }}>
                <input
                  placeholder="Digite o nome do tipo do evento..."
                  type="text"
                  className="form-control input-default"
                  id="event-type"
                  aria-describedby="event-type"
                  value={eventDescription}
                  onChange={(e) => setEventDescription(e.target.value)}
                />
              </div>
              <button
                className="btn btn-secondary"
                onClick={_addSchedulesType}
                style={{ display: "flex", alignItems: "center" }}
              >
                <span style={{ marginRight: 5, display: "flex", alignItems: "center" }}>
                  {loadingAddScheduleTypes ? (
                    <CircularProgress size={18} style={{ color: "white", margin: "1px 3px" }} />
                  ) : (
                    <AddCircleIcon />
                  )}
                </span>
                Nova categoria de evento
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Lista eventos */}
      <div className="card">
        <div
          className="card-header template-card-header"
          style={{ color: "#FFF", backgroundColor: secondaryColor }}
        >
          Lista de categoria de eventos
        </div>
        <div className="card-body template-card-body">
          <FilterResults
            value={[]}
            data={scheduleTypeList}
            renderResults={(results) => (
              <ul className="list-group">
                {loadingScheduleTypes ? (
                  <Loading> Carregando lista... </Loading>
                ) : (
                  results.map((item, index) => {
                    return (
                      <div key={index}>
                        <li
                          className="list-group-item"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {isEditing === item.idScheduleType ? (
                            <input
                              ref={(el) => (inputRefs.current[index] = el)}
                              value={item.description}
                              onBlur={(e) => handleEdit(item.idScheduleType, e.target.value)}
                              onKeyDown={(input) => {
                                if (input.key === "Enter") {
                                  input.target.blur();
                                }
                              }}
                              onChange={(e) => handleDescriptionChange(e.target.value, index)}
                              style={{ display: "inline", width: 250, height: 30 }}
                              type="text"
                              className="form-control"
                            />
                          ) : (
                            <span>{item.description}</span>
                          )}
                          <div className="float-right">
                            <button
                              className="btn btn-outline-secondary btn-sm mr-3"
                              onClick={() => {
                                setIsEditing(item.idScheduleType);

                                setTimeout(() => {
                                  if (inputRefs?.current?.[index]) {
                                    inputRefs.current[index].focus();
                                  }
                                }, 0);
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </button>
                            <button
                              className="btn btn-outline-danger btn-sm"
                              onClick={() => handleDelete(item.idScheduleType)}
                            >
                              {loadingRemoveScheduleTypes === item.idScheduleType ? (
                                <CircularProgress size={18} />
                              ) : (
                                <DeleteForeverOutlinedIcon fontSize="small" />
                              )}
                            </button>
                          </div>
                        </li>
                      </div>
                    );
                  })
                )}
              </ul>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarCategoryManager;
