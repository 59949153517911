import React, { useState } from "react";
import "moment/locale/pt-br.js";
import Filtro from "./Filtro";
import Table from "./Table";
import ReportAcordeon from "../../../Components/ReportAcordeon";
import {
  getDashboardsTrainings, getDownloadReport, postDownloadReport
} from "../../../../../services/NexiDigitalAPI";
import ModalDialog from "./ModalDialog";

const TrainingReport = ({ index,  expanded:_expanded  }) => {
  const name = "Relatório Treinamentos e Trilhas (portfólio)";
  const description =
    "Exibe dados de usuários, informações de acesso, aceite aos termos de uso e comunicação.";

  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const [expanded, setExpanded] = useState(_expanded);
  const [trainingsValue, setTrainingsValue] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [requestFilters, setRequestFilters] = useState({});
  const [modalErro, setModalErro] = useState(false);

  const idReport = 1;
  const onClickExpanded = (value) => {
    setExpanded(value);
  };
  
  const actionFilter = (values) => {
    if(expanded){
      setLoading(true);
      setTrainingsValue(values);

      const { offset, limit, ...rest } = values;
      getDashboardsTrainings(values).then((response) => {
        const responseData = response.data;
        setTotalRecords(responseData.totalRecords);
        setDados(responseData.result);
      }).finally(() =>   setLoading(false));
    }
  };

  const downloadEvent = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    const { startDate, finishDate, idCompany } = trainingsValue;
    await postDownloadReport(idReport, { startDate, finishDate, idCompany });
    await requestDownloadReport();
  };

  const requestDownloadReport = async () => {
      const response = await getDownloadReport(idReport);
      const { status, fileUrl } = response?.data || {};

      if (status === "Waiting") {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return requestDownloadReport();
      }

      if (status === "Done" && fileUrl) {
        getFileReport(fileUrl);
        setLoading(false);
      }
  };

  const getFileReport = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.click();
  };

  const filter = (
    <Filtro actionFilter={actionFilter} expanded={expanded} loading={loading} downloadEvent={downloadEvent} />
  );

  const result = (
    <Table
      dados={dados}
      actionFilter={actionFilter}
      loading={loading}
      totalRecords={totalRecords}
      trainingsValue={trainingsValue}
    />
  );

  return (
    <>
      <ModalDialog
        text={
          "A criação da planilha ainda não foi concluída. Por favor, aguarde um momento e tente novamente para efetuar o download."
        }
        show={modalErro}
        setShow={setModalErro}
        cancelStatus={false}
        btnCall={{
          variantBtn: "danger",
          text: "Confirmar",
          callEvent: () => {
            setModalErro(false);
          },
        }}
      />
      <ReportAcordeon
       onClickExpanded={onClickExpanded}
        name={name}
        description={description}
        filter={filter}
        result={result}
        index={index}
        expanded={expanded}
      />
    </>
  );
};

export default TrainingReport;
