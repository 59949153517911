import React from "react";
import styles from "./styles.module.scss";
import DataTable from "react-data-table-component";
import { colors } from "../../utils/Colors";

const StandartDataTable = ({ columns, data, loading }) => {
  const customTableStyles = {
    rows: {
      style: {
        minHeight: "60px", // override the row height
        backgroundColor: "#ebe8f0",
      },
      stripedStyle: {
        minHeight: "60px", // override the row height
        backgroundColor: "#f7f8fc",
      },
    },
    headCells: {
      style: {
        paddingLeft: "30px", // override the cell padding for head cells
        color: "#212529",
      },
    },
    cells: {
      style: {
        paddingLeft: "30px", // override the cell padding for data cells
        color: "#212529",
      },
    },
    header: {
      style: {
        fontSize: "22px",
        minHeight: "56px",
        paddingLeft: "28px",
        paddingRight: "8px",
        paddingTop: "30px",
        paddingBottom: "15px",
      },
    },
  };

  const loadingComponent = () => {
    return (
      <div className="tela-login-loading">
        <div className="loader-container" style={{ margin: "50px 0" }}>
          <div
            style={{
              borderTopColor: colors.gray,
              position: "initial",
              margin: "initial",
            }}
            className="loader loader-center loader-big"
          ></div>
          <span
            style={{
              marginTop: 10,
              color: colors.gray,
              fontSize: 20,
            }}
          >
            Carregando dados...
          </span>
        </div>
      </div>
    );
  };

  return (
    <DataTable
      columns={columns}
      data={data}
      pagination
      className={styles.dataTableFormat}
      customStyles={customTableStyles}
      progressPending={loading}
      progressComponent={loadingComponent()}
      noHeader={true}
      noDataComponent="Não foi encontrado nenhum dado"
      striped={true}
    />
  );
};

export default StandartDataTable;
