import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import SemImagem from "../../../assets/images/sem-imagem.jpg";
import Loading from "components/Loading";
import { RxMagnifyingGlass } from "react-icons/rx";
import { Form, InputGroup } from "react-bootstrap";
import { getContentsToImport } from "services/NexiDigitalAPI";

const NoDataComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 400,
        width: "100%",
        color: "gray",
      }}
    >
      Não foi encontrado nenhum dado
    </div>
  );
};

const UniqueContentPageTable = ({ blockData, handleContentImport, nodeType }) => {
  const columns = [
    {
      name: "Imagem",
      selector: "image",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      reorder: false,
      cell: (d) => (
        <div
          className="image-card"
          style={{
            backgroundImage: `url( ${d.image || SemImagem} )`,
            height: 40,
            width: 40,
            color: "#fff",
            display: "inline-grid",
            backgroundColor: "rgba(105, 72, 255, 0.3)",
            borderRadius: 5,
          }}
        />
      ),
    },
    {
      name: "Nome",
      selector: "text",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Tipo",
      selector: "name",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => <div>{getTranslation(d.name)}</div>,
    },
    {
      name: "Tags",
      selector: "tags",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Ação",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => (
        <button
          type="button"
          onClick={() => {
            handleContentImport(d);
          }}
          className="btn content-page-button"
          style={{
            backgroundColor: "#feac0e",
            margin: "0 auto",
            whiteSpace: "nowrap",
            height: 30,
          }}
        >
          Importar
        </button>
      ),
    },
  ];

  const getTranslation = (name = "") => {
    const itemIndex = contentTypes().findIndex(
      (item) => item.originalName.toLowerCase() === name.toLowerCase()
    );
     
    return contentTypes()?.[itemIndex]?.name || "";
  };

  const contentTypes = () => {

  let array = [
    { name: "Artigo", id: 5, originalName: "single page" },
    { name: "Vídeo", id: 2, originalName: "video" },
    { name: "Audio", id: 1, originalName: "audio" },
    { name: "Pacote Html", id: 4, originalName: "site" },
    { name: "Arquivo", id: 18, originalName: "File" },
    { name: "Link Externo", id: 27, originalName: "External Link" }
  ]
  if (nodeType !== "ORGANIZATION") {
    array.push({ name: "SCORM", id: 26, originalName: "SCORM" })
  }
  return array
  };

  const [nodeData, setNodeData] = useState([]);
  const [loadingNode, setLoadingNode] = useState(true);
  const [offset, setOffset] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [textFilter, setTextFilter] = useState("");
  const [idContentType, setIdContentType] = useState(null);

  const getNodes = (newOffset, limit) => {
    setLoadingNode(true);
    const haveNewOffset = newOffset || newOffset === 0;
    if (haveNewOffset) {
      setOffset(newOffset);
    }
    const importParams = {
      idNode: blockData?.idNode,
      idBlock: blockData?.idBlock,
      offset: haveNewOffset ? newOffset : offset,
      limit: limit ? limit : pageLimit,
      textFilter: Boolean(textFilter) ? textFilter : null,
      idContentType: Boolean(idContentType) ? idContentType : null,
      nodeType: nodeType
    };
    getContentsToImport(importParams)
      .then((res) => {
        setNodeData(res.data.contents);
        setTotalRecords(res.data.totalRecords);
      })
      .catch((err) => {
        console.log("error getting nodes", err.request);
      })
      .finally(() => {
        setLoadingNode(false);
      });
  };

  const onChangePage = (page) => {
    const newOffset = (page - 1) * pageLimit;
    getNodes(newOffset);
  };

  const onChangeRowsPerPage = (limit, page) => {
    setPageLimit(limit);
    const newOffset = (page - 1) * limit;
    getNodes(newOffset, limit);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Conteúdos por página",
    rangeSeparatorText: "de",
  };

  useEffect(() => {
    getNodes();
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div style={{ display: "flex" }}>
        <div className="input-group mb-3" style={{ width: 400 }}>
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              <RxMagnifyingGlass style={{ width: 20, height: 20 }} />
            </span>
          </div>
          <input
            required
            type="text"
            className="form-control mr-3"
            id="node-find"
            aria-describedby="node-find"
            placeholder="Procurar por título ou descrição"
            value={textFilter || ""}
            onChange={(e) => setTextFilter(e.target.value)}
            onKeyDown={(input) => {
              if (input.key === "Enter") {
                getNodes();
              }
            }}
          />
        </div>
        <div className="input-group mb-3 mr-3" style={{ width: 400 }}>
          <InputGroup.Prepend>
            <InputGroup.Text>Tipo de conteúdo</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            as="select"
            value={idContentType}
            onChange={(e) => {
              const value = e.target.value;
              setIdContentType(value === null ? null : Number(e.target.value));
            }}
          >
            <option value={null}>Todos</option>
            {contentTypes().map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
          </Form.Control>
        </div>
        <button
          type="button"
          onClick={() => {
            getNodes();
          }}
          className="btn content-page-button"
          style={{
            backgroundColor: "rgb(107, 72, 255)",
            color: "white",
          }}
        >
          Filtrar
        </button>
      </div>
      <div className="node-manager-import-table-wrapper">
        {loadingNode && nodeData.length >= 0 && (
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Loading>Carregando nós...</Loading>
          </div>
        )}
        <DataTable
          columns={columns}
          data={nodeData}
          progressComponent={<Loading>Carregando nós...</Loading>}
          noDataComponent={<NoDataComponent />}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationTotalRows={totalRecords}
          paginationComponentOptions={paginationComponentOptions}
          progressPending={loadingNode && nodeData.length === 0}
          paginationServer
          noHeader
          fixedHeader
          pagination
          disabled={loadingNode}
        />
      </div>
    </div>
  );
};

export default UniqueContentPageTable;
