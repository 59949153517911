import React, { useState, useEffect } from "react";
import Table from "./Table";
import ReportAcordeon from "../../../Components/ReportAcordeon";
import { listTagsReport } from "../../../../../services/NexiDigitalAPI";
import "moment/locale/pt-br.js";

const Tags = ({ index, expanded }) => {
  const name = "Lista de Tags com acesso de conteúdo";
  const description = "Filtra Tags pelo conteúdo acessado.";

  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);

  const loadReportData = async () => {
    setLoading(true);
    const responseData = await listTagsReport();
    console.log("responseData", responseData);

    setDados(responseData);

    setLoading(false);
  };

  useEffect(() => {
    loadReportData();
  }, []);

  //   const filter = (
  //     <Filtro
  //         actionFilter={actionFilter}
  //         loading={loading}
  //     />
  // );

  const result = <Table dados={dados} loading={loading} />;

  return (
    <ReportAcordeon
      name={name}
      description={description}
      result={result}
      index={index}
      expanded={expanded}
    />
  );
};

export default Tags;
