// Basic
import React from "react";
import Content from "./SubReports/Content";
import ReportWrapper from "../Components/ReportWrapper";
import ReportListTitle from "../Components/ReportListTitle";

const ReportChat = ({ rel }) => {
  const reportList = [
    (index, expanded) => <Content index={index} expanded={expanded} key={index} />,
  ];

  return (
    <ReportWrapper>
      <ReportListTitle>Lista de Relatórios</ReportListTitle>
      {reportList.map((report, index) => {
        const expand = index.toString() === rel;
        return report(index, expand);
      })}
    </ReportWrapper>
  );
};

export default ReportChat;
