import { CircularProgress } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import RoomIcon from "@material-ui/icons/Room";
import Loading from "components/Loading";
import { addMinutes, endOfDay, format, getHours, getMinutes, set } from "date-fns";
import React, { forwardRef, useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { AiFillCalendar } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { TbClockHour4 } from "react-icons/tb";
import { useParams } from "react-router-dom";
import { getLearningMeetings, saveLearningMeetings } from "services/NexiDigitalAPI";
import { convertToCapitalFirst } from "utils/Utils";
import SemImagemSmall from "../../../../../assets/images/sem-imagem-small.jpg";
import "../style.modules.scss";
import moment from "moment";

type BlendedNodesData = {
  nodeCode: string;
  nodeLevel: number;
  levelName: string;
  originCode: null | string;
  idNode: number;
  nodeType: string;
  title: string;
  shortTitle: string;
  label: string;
  description: string;
  redirect: number;
  entryPoint: number;
  workload: null | number;
  views: null | number;
  image: string;
  author: string;
  authorEmail: string;
  status: string;
  essential: number;
  modality: string;
  selfEnrollment: number;
  idCertificate: null | number;
  enableCertificateForChildren: number;
  hasChildNodes: number;
  meetings: Meetings[];
};

type Meetings = {
  idMeeting: number;
  idNode: number | null;
  idTeam: number;
  nodeCode: string;
  meetingType: "PRESENCIAL" | "VIRTUAL"; // Update with possible meeting types
  startDate: string;
  finishDate: string;
  address: string;
  speaker: string;
  attendanceListEnabled: 0 | 1;
};

type NodeMeeting = {
  idMeeting?: number;
  idTeam: number;
  nodeCode: string;
  meetingType: "ONLINE" | "PRESENCIAL";
  startDate: string | Date;
  finishDate: string | Date;
  address: string;
  speaker: string;
  attendanceListEnabled: number;
};

type Props = {
  blendedNodesData: BlendedNodesData[] | [];
  teamStatus: "NOT_STARTED" | "STARTED" | "FINISHED";
  teamInitialDate: Date;
  teamFinalDate: Date;
  disabled?: boolean;
  isRetroactiveTeam?: boolean;
};

const InputCalendario = forwardRef(
  (
    {
      value,
      placeholderText,
      onClick,
      isTime,
    }: { value?: string; placeholderText?: string; onClick?: () => void; isTime?: boolean },
    ref: any,
  ) => (
    <div
      className={`btnCalendar node-blended-calendar btn`}
      onClick={onClick}
      ref={ref}
      style={{
        cursor: "pointer",
        padding: "0px 10px",
      }}
    >
      {isTime ? <TbClockHour4 /> : <AiFillCalendar />}

      <span
        style={{ color: "#495057" }}
        className={`ml-1 ${isTime ? "node-blended-calendar--time" : "node-blended-calendar--date"}`}
      >
        {value || placeholderText}
      </span>
    </div>
  ),
);

const BlendedNodes = ({ blendedNodesData, teamStatus, teamInitialDate, teamFinalDate, disabled, isRetroactiveTeam }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedNode, setSelectedNode] = useState<BlendedNodesData | null>(null);
  const [nodeMeetings, setNodeMeetings] = useState<NodeMeeting[] | []>([]);
  const [loadingSaveMeetings, setLoadingSaveMeetings] = useState(false);
  const [loadingMeetings, setLoadingMeetings] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const addDisabled = teamStatus !== "NOT_STARTED" && !isRetroactiveTeam;
  const removeDisabled = teamStatus !== "NOT_STARTED" && !isRetroactiveTeam;
  const currentDate: Date = new Date();
  const minDate: Date = teamInitialDate ? moment(teamInitialDate).toDate() : currentDate;
  const maxDate: Date = teamFinalDate;

  const { idTeam } = useParams<{ idTeam: string }>();

  const handleSelect = (node: BlendedNodesData, e) => {
    e.preventDefault();
    setShowModal(true);
    setSelectedNode(node);
  };

  const addMeeting = () => {
    const newMeeting: NodeMeeting = {
      idTeam: Number(idTeam),
      nodeCode: selectedNode.nodeCode,
      meetingType: "PRESENCIAL",
      startDate: null,
      finishDate: null,
      attendanceListEnabled: 1,
      address: "",
      speaker: "",
    };
    setNodeMeetings([...nodeMeetings, newMeeting]);
  };

  const removeMeeting = (index: number) => {
    const updatedNodeMeetings = nodeMeetings.filter((item, meetIndex) => meetIndex !== index);
    setNodeMeetings(updatedNodeMeetings);
  };

  // TODO: use this to remove timezone if needed later
  const transformNodeMeetings = (nodeMeetings: NodeMeeting[]) => {
    const newNodeMeetings = [...nodeMeetings];
    return newNodeMeetings.map((nodeMeeting) => ({
      ...nodeMeeting,
      startDate: format(new Date(nodeMeeting.startDate), "yyyy-MM-dd HH:mm"),
      finishDate: format(new Date(nodeMeeting.finishDate), "yyyy-MM-dd HH:mm"),
    }));
  };

  const saveMeetings = (e) => {
    e.preventDefault();
    setErrorMessage(false);
    setLoadingSaveMeetings(true);

    const saveMeetingsParameter = {
      nodeCode: selectedNode.nodeCode,
      meetings: nodeMeetings,
    };

    // @ts-ignore
    saveLearningMeetings({ idTeam, data: saveMeetingsParameter })
      .then((res) => {
        setShowModal(false);
        handleSyncNodeMeetings(nodeMeetings);
      })
      .catch((err) => {
        console.log("ERROR", err.response.data.message);
        setErrorMessage(err?.response?.data?.message || "Erro ao salvar encontro");
      })
      .finally(() => {
        setLoadingSaveMeetings(false);
      });
  };

  const handleSyncNodeMeetings = (nodeMeetings: NodeMeeting[]) => {
    const newBlendedNodesData = [...blendedNodesData];

    const nodeIndex = newBlendedNodesData.findIndex(
      (node) => node.nodeCode === selectedNode.nodeCode,
    );
    newBlendedNodesData[nodeIndex].meetings = nodeMeetings as Meetings[];
  };

  const getMeetings = () => {
    setLoadingMeetings(true);
    setErrorMessage(null);
    getLearningMeetings(idTeam, selectedNode.nodeCode)
      .then((res) => {
        setNodeMeetings(
          res.data.map((meeting) => {
            if (meeting.idNode) {
              meeting.idNode = null;
              meeting.idTeam = Number(idTeam);
              meeting.nodeCode = selectedNode.nodeCode;
            }
            return meeting;
          }),
        );
      })
      .catch((err) => {
        console.log("ERROR getLearningMeetings", err);
      })
      .finally(() => {
        setLoadingMeetings(false);
      });
  };

  const onChangeCalendarInit = (date, index: number) => {
    const updatedMeetings = [...nodeMeetings];

    if (index >= 0 && index < updatedMeetings.length) {
      // get time from finishDate to update it´s date

      const finishHours = getHours(new Date(updatedMeetings[index].finishDate));
      const finishMinutes = getMinutes(new Date(updatedMeetings[index].finishDate));
      const updatedFinishDate = set(date, {
        hours: finishHours,
        minutes: finishMinutes,
      });

      updatedMeetings[index].startDate = date;
      updatedMeetings[index].finishDate = updatedFinishDate;
    }

    setNodeMeetings(updatedMeetings);
  };

  const onChangeCalendarTime = (date, index: number, init?: boolean) => {
    const updatedMeetings = [...nodeMeetings];
    const timeFromDate = format(date, "HH:mm");
    const combinedDate = set(new Date(updatedMeetings[index].startDate), {
      hours: parseInt(timeFromDate.split(":")[0], 10),
      minutes: parseInt(timeFromDate.split(":")[1], 10),
    });


    if (index >= 0 && index < updatedMeetings.length) {
      if (init) {
        updatedMeetings[index].startDate = combinedDate;
      } else {
        updatedMeetings[index].finishDate = combinedDate;
      }
    }

    setNodeMeetings(updatedMeetings);
  };

  const handleMeetingsChange = ({ field, value, index }) => {
    const updatedMeetings = [...nodeMeetings];

    if (index >= 0 && index < updatedMeetings.length) {
      updatedMeetings[index][field] = value;
    }

    setNodeMeetings(updatedMeetings);
  };

  const handleExpandNodes = (index: number) => {
    if (expandedNodes.includes(index)) {
      setExpandedNodes(expandedNodes.filter((nodeIndex) => nodeIndex !== index));
    } else {
      setExpandedNodes([...expandedNodes, index]);
    }
  };

  useEffect(() => {
    if (showModal) {
      getMeetings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <div style={{ overflow: "hidden" }}>
      <Modal
        className="node-blended-modal"
        size="xl"
        onHide={() => setShowModal(false)}
        show={showModal}
        centered
        dialogClassName="node-blended-modal-container"
      >
        <div className="node-blended-line-modal-header">
          <div>
            Encontros síncronos - {selectedNode?.title}<br />
            { addDisabled ? <div className="subtitle">
              Não é possível editar/adicionar eventos nessa turma.
            </div> : '' }
          </div>
          <div
            onClick={() => setShowModal(false)}
            className="modal-warning-close-container"
            style={{ margin: 15, padding: 0, backgroundColor: "#6b48ff" }}
          >
            <CloseIcon style={{ color: "white" }} className="modal-warning-close" />
          </div>
        </div>
        <form onSubmit={(e) => saveMeetings(e)}>
          <Modal.Body className="node-blended-modal-body">
            <div className="content-page-modal-description">
              {loadingMeetings ? (
                //@ts-ignore
                <Loading containerStyle={{ height: 300 }}>Carregando encontros...</Loading>
              ) : nodeMeetings.length === 0 ? (
                <div style={{ display: "flex", justifyContent: "center", marginBottom: 30 }}>
                  Nenhum encontro disponível, cliquei no botão abaixo para criar um.
                </div>
              ) : (
                nodeMeetings.map((nodeMeeting: NodeMeeting, index: number) => {
                  if (nodeMeeting.startDate) {
                    nodeMeeting.startDate =
                      typeof nodeMeeting.startDate === "string"
                        ? new Date(nodeMeeting.startDate)
                        : nodeMeeting.startDate;
                  }
                  return (
                    <div key={index} className="node-blended-meeting-item mb-3">
                      <span style={{ color: "black", fontWeight: "bold", fontSize: 16 }}>
                        Encontros {index + 1}
                      </span>
                      <InputGroup className={`ml-3 calendarInit`} style={{ flexWrap: "nowrap" }}>
                        <InputGroup.Prepend>
                          <InputGroup.Text>Data/Horário</InputGroup.Text>
                        </InputGroup.Prepend>

                        <DatePicker
                          disabled={(nodeMeeting.startDate && nodeMeeting.startDate < currentDate && !isRetroactiveTeam) || disabled}
                          name="initDate"
                          minDate={minDate}
                          maxDate={maxDate}
                          selected={nodeMeeting.startDate && new Date(nodeMeeting.startDate) }
                          locale={"pt-BR"}
                          dateFormat="dd-MM-yyyy"
                          onChange={(date) => onChangeCalendarInit(date, index)}
                          customInput={<InputCalendario placeholderText="dd-mm-yyyy" />}
                        />
                        <DatePicker
                          disabled={
                            (nodeMeeting.startDate && nodeMeeting.startDate < currentDate && !isRetroactiveTeam) ||
                            !nodeMeeting.startDate
                            || disabled
                          }
                          name="startTime"
                          minDate={minDate}
                          maxDate={maxDate}
                          selected={nodeMeeting.startDate && new Date(nodeMeeting.startDate)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          locale={"pt-BR"}
                          dateFormat="HH:mm"
                          onChange={(date) => onChangeCalendarTime(date, index, true)}
                          customInput={<InputCalendario placeholderText="hh:mm" isTime />}
                          timeCaption="Começo"
                        />
                        <DatePicker
                          disabled={
                            (nodeMeeting.startDate && nodeMeeting.startDate < currentDate&& !isRetroactiveTeam) ||
                            !nodeMeeting.finishDate || disabled
                          }
                          name="finishTime"
                          minTime={addMinutes(new Date(nodeMeeting.startDate), 15)}
                          maxTime={endOfDay(new Date(nodeMeeting.startDate))}
                          selected={nodeMeeting.finishDate && new Date(nodeMeeting.finishDate)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          locale={"pt-BR"}
                          dateFormat="HH:mm"
                          timeCaption="Fim"
                          onChange={(date) => onChangeCalendarTime(date, index)}
                          customInput={<InputCalendario placeholderText="hh:mm" isTime />}
                        />
                      </InputGroup>

                      <div className="input-group ml-3">
                        <Form.Control
                          disabled={(nodeMeeting.startDate && nodeMeeting.startDate < currentDate && !isRetroactiveTeam) || disabled}
                          as="select"
                          value={nodeMeeting.meetingType}
                          onChange={(e) => {
                            const value = e.target.value;
                            handleMeetingsChange({ field: "meetingType", value, index });
                          }}
                        >
                          <option value={"PRESENCIAL"}>Presencial</option>
                          <option value={"ONLINE"}>Online</option>
                        </Form.Control>
                      </div>
                      <div className="input-group ml-3">
                        <input
                          disabled={(nodeMeeting.startDate && nodeMeeting.startDate < currentDate && !isRetroactiveTeam) || disabled}
                          required
                          placeholder="Endereço do encontro"
                          type="text"
                          className="form-control"
                          id="meeting-address"
                          aria-describedby="meeting-address"
                          value={nodeMeeting.address}
                          onChange={(e) => {
                            const value = e.target.value;
                            handleMeetingsChange({ field: "address", value, index });
                          }}
                        />
                      </div>
                      <div className="input-group ml-3">
                        <input
                          disabled={(nodeMeeting.startDate && nodeMeeting.startDate < currentDate && !isRetroactiveTeam) || disabled}
                          required
                          placeholder="Nome do condutor"
                          type="text"
                          className="form-control"
                          id="conductor-name"
                          aria-describedby="conductor-name"
                          value={nodeMeeting.speaker}
                          onChange={(e) => {
                            const value = e.target.value;
                            handleMeetingsChange({ field: "speaker", value, index });
                          }}
                        />
                      </div>

                      <div
                        className={`node-manager-node-item-actions node-red ml-3 ${(disabled ? 'disabled' : '')}`}
                        onClick={() => removeDisabled ||  disabled || removeMeeting(index)}
                        style={{ padding: 3 }}

                      >
                        <span style={{ color: "black" }}>Remover</span>
                        <FiTrash2 style={{ fontSize: 22 }} />
                      </div>
                    </div>
                  );
                })
              )}
            </div>
            {!loadingMeetings && (
              <div className={"node-blended-add-icon " + (addDisabled || disabled ? 'disabled' : '')}>
                <AddCircleOutlineIcon onClick={() => addDisabled || disabled || addMeeting()} />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer
            className="content-page-modal-footer"
            style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
          >
            {errorMessage && <div className="node-blended-error">{errorMessage}</div>}
            <button
              type="button"
              className="btn content-page-button-blank ml-2"
              style={{ zIndex: 10 }}
              onClick={(e) => {
                e.preventDefault();
                setShowModal(false);
              }}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="btn content-page-button-new-class ml-2"
              style={{ backgroundColor: "#feac0e", zIndex: 10 }}
              disabled={ addDisabled || disabled}
            >
              Adicionar eventos
              {loadingSaveMeetings && <CircularProgress size={15} className="ml-3" />}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {blendedNodesData.map((node: BlendedNodesData, index) => {
        const isFirst = index === 0;
        return (
          <div style={{ display: "flex", position: "relative" }} key={index}>
            {!isFirst && (
              <div className="node-blended-line">
                <div />
              </div>
            )}
            <div className={`node-manager-node-item-wrapper mb-3`} style={{ zIndex: 1 }}>
              <div className="node-manager-node-item-draggable-container">
                <div className="node-manager-node-item-draggable-color" />
              </div>
              <div>
                <div className="node-manager-node-item-content">
                  <div
                    className="node-manager-content-type-article-wrapper"
                    style={{ paddingLeft: 10, paddingTop: 5 }}
                  >
                    <img
                      alt="article"
                      className="node-manager-content-type-article-image"
                      src={node?.image || SemImagemSmall}
                    />
                    <div
                      className="node-manager-content-type-article-container"
                      style={{ marginRight: 10, flex: 1 }}
                    >
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: "bold",
                          marginBottom: 5,
                        }}
                      >
                        {node.title}
                      </div>
                      <div className="node-manager-type-text-container">
                        <span>{node.levelName}</span>
                      </div>
                    </div>

                    <div className="node-manager-node-item-actions-container">
                      <button
                        className="btn content-page-button ml-3"
                        style={{ backgroundColor: "#feac0e" }}
                        onClick={(e) => handleSelect(node, e)}
                        type="button"
                        disabled={disabled}
                      >
                        Configurar eventos
                      </button>
                      <div
                        className={`node-blended-line-action ${
                          node.meetings?.length > 0 ? "blended-active" : "blended-off"
                        }`}
                        onClick={() => handleExpandNodes(index)}
                      >
                        <RoomIcon />
                        Ver encontros
                        {expandedNodes.includes(index) ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {expandedNodes.includes(index) && (
                  <div style={{ marginBottom: 15 }}>
                    {node.meetings.map((meeting, index) => {
                      const formattedInitDate = format(new Date(meeting.startDate), "dd/MM/yyyy");
                      const formattedInitHour = format(new Date(meeting.startDate), "HH:mm");
                      const formattedFinishHour = format(new Date(meeting.finishDate), "HH:mm");
                      return (
                        <div key={index} className="node-blended-attendance-item">
                          {`${formattedInitDate} das ${formattedInitHour}h às ${formattedFinishHour}h - `}{" "}
                          <span style={{ color: "#6b48ff" }}>
                            {convertToCapitalFirst(meeting.meetingType)}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BlendedNodes;
