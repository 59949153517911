import moment from "moment";
import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import styles from "./styles.module.scss";

const Table = ({ dados, loading, actionFilter, totalRecords }) => {
  const statusFormater = (status) => {
    switch (status) {
      case "NOT_ATTEMPTED":
        return "Não iniciado";
      case "IN_PROGRESS":
        return "Em progresso";
      case "COMPLETED":
        return "Concluído";
      case "EXPIRED":
        return "Expirado";
      case "APPROVED":
      case "PASSED":
        return "Aprovado";
      case "REJECTED":
      case "FAILED":
        return "Reprovado";
      case "DISAPPROVED":
        return "Reprovado";
      default:
    }
  };

  const columns = [
    {
      name: "Nome do usuário",
      selector: "name",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Login do usuário",
      selector: "username",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Empresa",
      selector: "company",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Cargo",
      selector: "role",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Departamento",
      selector: "department",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Unidade",
      selector: "unit",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Gestor Imediato",
      selector: "manager",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Nome do treinamento",
      selector: "title",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Carga horária",
      selector: "workload",
      sortable: true,
      center: true,
      wrap: false,
      style: { textAlign: "center" },
      cell: (d) => (
        <span>
          {d.workload > 0
            ? new Date(d.workload * 1000).toISOString().substring(11, 16)
            : d.workload || ""}
        </span>
      ),
    },
    {
      name: "Nome da turma",
      selector: "team",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Progresso",
      selector: "progress",
      sortable: true,
      center: true,
      wrap: false,
      style: { textAlign: "center" },
      cell: (d) => <span>{d.progress}%</span>,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => <>{statusFormater(d.status)}</>,
    },
    {
      name: "Data de inscrição",
      selector: "enrollDate",
      sortable: true,
      center: true,
      wrap: false,
      cell: (d) => <span>{d.enrollDate ? moment(d.enrollDate).format("DD/MM/YYYY") : ''}</span>,
    },
    {
      name: "Data do primeiro acesso",
      selector: "startDate",
      sortable: true,
      center: true,
      wrap: false,
      cell: (d) => <span>{d.startDate ? moment(d.startDate).format("DD/MM/YYYY HH:mm"): ''}</span>,
    },
    {
      name: "Data de finalização",
      selector: "finishDate",
      sortable: true,
      center: true,
      wrap: false,
      cell: (d) => <span>{d.finishDate ?  moment(d.finishDate).format("DD/MM/YYYY HH:mm"): ''}</span>,
    },
    {
      name: "Nota",
      selector: "grade",
      sortable: true,
      center: true,
      wrap: false,
      style: { textAlign: "center" },
      cell: (d) => <span>{d.grade ?? '-'}</span>,
    },
    {
      name: "Gerou o certificado?",
      selector: "certificateGeneratedDate",
      sortable: true,
      center: true,
      wrap: false,
      cell: (d) => <span>{Boolean(d.certificateGeneratedDate) ? "SIM" : "NÃO"}</span>,
    },
  ];

  const customTableStyles = {
    rows: {
      style: {
        minHeight: "60px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "30px", // override the cell padding for head cells
        textAlign: "center",
      },
    },
    cells: {
      style: {
        paddingLeft: "30px", // override the cell padding for data cells
      },
    },
    header: {
      style: {
        fontSize: "22px",
        minHeight: "56px",
        paddingLeft: "28px",
        paddingRight: "8px",
        paddingTop: "30px",
        paddingBottom: "15px",
      },
    },
  };

  const tableData = {
    columns: columns,
    data: dados,
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Matrículas por página",
    rangeSeparatorText: "de",
  };

  const onChangePage = (page, totalRows) => {
    actionFilter({ offset: --page * dados.length });
  };

  const onChangeRowsPerPage = (limit) => {
    actionFilter({ limit, offset: 0 });
  };

  const loadingComponent = () => {
    return (
      <div className="tela-login-loading">
        <div className="loader-container" style={{ margin: "300px 0" }}>
          <div
            style={{
              position: "initial",
              margin: "initial",
            }}
            className="loader loader-center loader-big"
          ></div>
          <span
            style={{
              marginTop: 10,
              fontSize: 20,
            }}
          >
            Carregando dados...
          </span>
        </div>
      </div>
    );
  };

  return (
    <DataTableExtensions filter={false} print={false} {...tableData} exportHeaders={true} export={false}>
      <DataTable
        columns={columns}
        data={dados}
        className={styles.dataTableFormat}
        customStyles={customTableStyles}
        progressPending={loading}
        progressComponent={loadingComponent()}
        noHeader={true}
        noDataComponent="Não foi encontrado nenhum dado"
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        paginationTotalRows={totalRecords}
        paginationComponentOptions={paginationComponentOptions}
        paginationServer
        pagination
      />
    </DataTableExtensions>
  );
};

export default Table;
