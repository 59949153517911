import "./styles.scss";

import React, { useState, useEffect } from "react";

import "react-data-table-component-extensions/dist/index.css";

import { Modal, Button } from "react-bootstrap";

import {
  approveExternalCourses,
  rejectExternalCourses,
} from "../../../../../services/NexiDigitalAPI";

import { convertToBrDate } from "utils/Utils";

import { AiOutlineClose } from "react-icons/ai";
import { PiDownloadSimple } from "react-icons/pi";

import PreloaderBlur from "containers/PeopleCraft/PreloaderBlur";

import {
  TypeExternalCourseDescriptions,
  StatusExternalCourseDescriptions,
  getFileName,
} from "../../constants";

const ModalRequestDetail = ({ dataRequestDetail, setDataRequestDetail, resetActionFilter }) => {
  const [loading, setLoading] = useState(false);
  const [dataRequestDetailInput, setDataRequestDetailInput] = useState(null);
  const [statusModal, setStatusModal] = useState(null);

  const appDetails = JSON.parse(localStorage.getItem("appDetails"));

  useEffect(() => {
    if (dataRequestDetail) {
      setDataRequestDetailInput(dataRequestDetail);
      setStatusModal(dataRequestDetail.statusModal);
    }
  }, [dataRequestDetail]);

  const urlImg =
    dataRequestDetail && dataRequestDetail?.evidencePath
      ? "https://" + appDetails.domain + "/" + dataRequestDetail?.evidencePath
      : null;

  const handleChange = (e) => {
    setDataRequestDetailInput({ ...dataRequestDetailInput, statusReason: e.target.value });
  };

  const onClosed = () => {
    setDataRequestDetail(null);
    setLoading(false);
  };

  const onClickHandler = (status) => {
    setLoading(true);

    if (status === "approve") {
      approveExternalCourses(dataRequestDetailInput.idUserExternalCourse)
        .then((res) => {
          setLoading(false);
          resetActionFilter();
          onClosed();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      rejectExternalCourses(dataRequestDetailInput.idUserExternalCourse, {
        reason: dataRequestDetailInput.statusReason,
      })
        .then((res) => {
          setLoading(false);
          resetActionFilter();
          onClosed();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onClickHandlerStatus = (statusModal) => {
    setStatusModal(statusModal);
  };

  const statuReject =
    statusModal === "reject" ||
    (statusModal === "view" && dataRequestDetailInput.status === "REJECTED");

  const statusReasonActiveStatusDisabled =
    !dataRequestDetailInput?.statusReason ||
    (dataRequestDetailInput &&
      dataRequestDetailInput.statusReason &&
      dataRequestDetailInput.statusReason.length < 10);

  return (
    <>
      <Modal
        show={dataRequestDetail}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        className={`ModalDilogComponent ModalRequestDetail`}
        centered
        size="xl"
        onHide={() => {}}
      >
        <div
          className="modal-support-blur modal-support-blur-warning"
          style={{ borderRadius: 5 }}
        />

        <Modal.Header className="modal-header-warning">
          <div
            onClick={() => {
              onClosed();
            }}
            className="modal-warning-close-container"
          >
            <AiOutlineClose style={{ fontSize: "30", color: "#7b8590" }} />
          </div>
        </Modal.Header>

        <div className="modal-principal-warning">
          <div className="modal-body-warning">
            <div className="form-group form-group-block-02">
              <div className="containInfo">
                <div className="containText">
                  <div className="containStatus">
                    <div className={`statusItem ${dataRequestDetail?.status}`}>{`${
                      StatusExternalCourseDescriptions[dataRequestDetail?.status]
                    }`}</div>
                    <h1>
                      <b>Detalhes da solicitação</b>
                    </h1>
                    <div className="itBase">
                      <div className="it">
                        <h4>
                          <b>Nome</b>
                        </h4>
                        <p>{dataRequestDetail?.name}</p>
                      </div>
                      <div className="it">
                        <h4>
                          <b>Curso</b>
                        </h4>
                        <p>{dataRequestDetail?.title}</p>
                      </div>
                      <div className="listIt">
                        <div className="it">
                          <h4>
                            <b>Tipo</b>
                          </h4>
                          <p>{TypeExternalCourseDescriptions[dataRequestDetail?.type]}</p>
                        </div>
                        <div className="it">
                          <h4>
                            <b>Carga horária</b>
                          </h4>
                          <p>{dataRequestDetail?.workload}</p>
                        </div>
                        <div className="it">
                          <h4>
                            <b>Validade</b>
                          </h4>
                          <p>{convertToBrDate(dataRequestDetail?.expirationDate)}</p>
                        </div>
                      </div>

                      <div className="it">
                        <h4>
                          <b>Período de realização</b>
                        </h4>
                        <p>
                          {convertToBrDate(dataRequestDetail?.startDate)} até{" "}
                          {convertToBrDate(dataRequestDetail?.finishDate)}
                        </p>
                      </div>

                      <div className="it">
                        <h4>
                          <b>Local de realização do curso</b>
                        </h4>
                        <p>{dataRequestDetail?.entityName}</p>
                      </div>

                      {urlImg && (
                        <div className="it basTxt">
                          <div className="basInfo">
                            <h4>
                              <b>Comprovante de realização</b>
                            </h4>
                            <p>{getFileName(dataRequestDetail?.evidencePath)}</p>
                          </div>

                          <div className="blockInput">
                            <a href={urlImg} target="_blank" rel="noreferrer">
                              <PiDownloadSimple style={{ height: 18 }} /> Baixar comprovante
                            </a>
                          </div>
                        </div>
                      )}

                      {statuReject && (
                        <div className="it itult">
                          <h4>
                            <b>Motivo da rejeição</b> <small>(mínimo de 10 caracteres)</small>
                          </h4>
                          <textarea
                            name="statusReason"
                            className="statusReason"
                            maxLength={1000}
                            onChange={handleChange}
                            value={dataRequestDetailInput?.statusReason}
                            autoFocus={true}
                            disabled={dataRequestDetailInput.status === "REJECTED"}
                          />
                        </div>
                      )}

                      {statusModal === "approve" && (
                        <div className="it itultSucess">
                          <h4>
                            <b>Tem certeza que deseja validar esta solicitação?</b>
                          </h4>
                          <p>
                            Esta ação é irreversível. Ao validar, o curso externo será registrado no
                            histórico do aluno. Deseja prosseguir?
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`containBtn ${
                  statusModal === "view" && dataRequestDetailInput.status !== "PENDING"
                    ? "containBtnOk"
                    : ""
                }`}
              >
                {statusModal === "view" && dataRequestDetailInput.status === "PENDING" && (
                  <>
                    <Button variant="success" onClick={(e) => onClickHandlerStatus("approve")}>
                      Validar solicitação
                    </Button>
                    <Button variant="danger" onClick={(e) => onClickHandlerStatus("reject")}>
                      Rejeitar solicitação
                    </Button>
                  </>
                )}

                {statusModal === "view" && dataRequestDetailInput.status !== "PENDING" && (
                  <Button variant="primary" onClick={(e) => onClosed()}>
                    Fechar
                  </Button>
                )}

                {statusModal === "approve" && (
                  <>
                    <Button variant="outline-secondary" onClick={(e) => onClosed()}>
                      Cancelar
                    </Button>
                    <Button variant="success" onClick={(e) => onClickHandler("approve")}>
                      Sim, validar
                    </Button>
                  </>
                )}

                {statusModal === "reject" && (
                  <>
                    <Button variant="outline-secondary" onClick={(e) => onClosed()}>
                      Cancelar
                    </Button>
                    <Button
                      variant="danger"
                      onClick={(e) => onClickHandler("reject")}
                      disabled={statusReasonActiveStatusDisabled}
                    >
                      Sim, rejeitar
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {loading && <PreloaderBlur />}
    </>
  );
};

export default ModalRequestDetail;
