import React from "react";
import { Button } from "react-bootstrap"; 
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import iconDelete from "assets/images/ico-delete.svg";
import "./styles.scss";

const DialogWarning = NiceModal.create(
  ({
    showModal,
    title,
    description,
    buttonText = "Fechar",
    onHide = () => {},
    buttonAction = () => {},
    ico = null,
  }) => {
    const modal = useModal();

    const closeModal = () => {
      modal.remove();
    }

    return (
      <div centered show={showModal} onHide={closeModal} backdrop className="DialogWarning" size="lg">
        <div className="modal-body-pct dialog-content-page-modal-body">
          <div className="dialog-circle">
           {ico ? ico : <img src={iconDelete} style={{ height: 67, width: 67 }} alt="icon-remoção" />}
          </div>

          <div className="body-modal">
            <div className="title-modal-text">{title}</div>
            <div className="description-modal-text">{description}</div>
          </div>

          <div className="content-page-modal-footer">
            <button type="button" className="close-button" onClick={closeModal}>
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    );
  },
);

export default DialogWarning;
