import "./styles.modules.scss";

import { CircularProgress, Tooltip, withStyles } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import parse from "html-react-parser";
import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FaRegEdit } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import { useParams, withRouter } from "react-router-dom";
import {
  deleteEvaluationQuestion,
  getEvaluationQuestions,
  moveEvaluationQuestion,
} from "services/NexiDigitalAPI";
import { removeHtmlTags } from "utils/Utils";
import { RECreateContext } from "../../Contexts/REContext";
import ModalCreateQuestion from "../../../Modals/ModalCreateQuestion";

import { getQuestionTypeName } from "utils/Utils";

const CustomTooltip = withStyles(() => ({
  tooltip: {
    fontSize: "12px",
  },
}))(Tooltip);

function TextWithEllipsis({ text, maxLength }) {
  const truncatedText = text.length > maxLength ? text.slice(0, maxLength) + "..." : text;

  return <span title={text}>{truncatedText}</span>;
}

const BoxListQuestion = (props) => {
  const { questionsList, setQuestionsList, contextTagsInit, evaluationModel } =
    useContext(RECreateContext);
  const { idEvaluation } = useParams();
  const [loadingDelete, setLoadingDelete] = useState(null);
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [loadingMoveQuestion, setLoadingMoveQuestion] = useState(false);
  const [showEditQuestion, setShowEditQuestion] = useState(false);
  const [selectedIdQuestion, setSelectedIdQuestion] = useState("");

  const getQuestions = async () => {
    setLoadingQuestions(true);
    let questionsData = await getEvaluationQuestions(idEvaluation);
    if (questionsData.data) {
      const formattedQuestionData = questionsData.data.map((question) => ({
        ...question,
        statement: removeHtmlTags(question.statement),
      }));
      setQuestionsList(formattedQuestionData);
    }
    setLoadingQuestions(false);
  };

  const deleteQuestion = async (idQuestion) => {
    setLoadingDelete(idQuestion);
    deleteEvaluationQuestion(idEvaluation, idQuestion)
      .then(() => {
        const filteredQuestionList = questionsList.filter((obj) => obj.idQuestion !== idQuestion);
        let newQuestionsList = [...questionsList];
        newQuestionsList = filteredQuestionList;

        setQuestionsList(newQuestionsList);
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setLoadingDelete(null);
      });
  };

  const editQuestion = (idQuestion) => {
    setSelectedIdQuestion(idQuestion);
    setShowEditQuestion(true);
  };

  useEffect(() => {
    getQuestions();
  }, []);

  const NoQuestion = () => {
    return (
      <div className="noQuestion">
        <div className="KEico"></div>
        <div className="KEtxt">
          <h4>No momento, você não tem nenhuma questão criada neste quadro.</h4>
        </div>
      </div>
    );
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleChangeOrder = (orderData) => {
    setLoadingMoveQuestion(true);
    const { idQuestion, orderNum } = orderData;
    moveEvaluationQuestion(idEvaluation, idQuestion, orderNum)
      .then(() => {
        console.log("Success moving question");
      })
      .catch((err) => {
        console.log("error moving question", err);
      })
      .finally(() => {
        setLoadingMoveQuestion(false);
      });
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    // do not move if its the same place
    if (
      result.destination.index === result.source.index &&
      result.destination.droppableId === result.source.droppableId
    ) {
      return;
    }

    const cards = reorder(questionsList, result.source.index, result.destination.index);

    const draggedItem = questionsList.find(
      (item) => String(item.idQuestion) === result.draggableId,
    );
    const orderNum = result.destination.index + 1;

    const changeOrderData = {
      idQuestion: draggedItem.idQuestion,
      orderNum,
    };
    handleChangeOrder(changeOrderData);
    setQuestionsList(cards);
  }

  const CardList = React.memo(function CardList({ cards }) {
    return cards?.map((elementItem, index) => {
      const disableEdit = elementItem?.hasQuestionnaire || elementItem?.hasEvaluationQuestionnaire;
      const disableDelete = elementItem?.hasEvaluationQuestionnaire;
      return (
        <Draggable
          draggableId={String(elementItem.idQuestion)}
          index={index}
          key={String(elementItem.idQuestion)}
          isDragDisabled={loadingMoveQuestion}
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              className="node-manager-content-item-wrapper"
            >
              <div
                className="node-manager-node-item-draggable-container"
                {...provided.dragHandleProps}
              >
                <div className="node-manager-node-item-draggable-dots">
                  <DragIndicatorIcon
                    style={{ fontSize: 35, color: "lightgray", marginBottom: -8 }}
                  />
                  <DragIndicatorIcon style={{ fontSize: 35, color: "lightgray" }} />
                </div>
              </div>
              <div className="node-manager-node-item-content">
                <small>{getQuestionTypeName(elementItem.questionType)}</small>
                <TextWithEllipsis
                  text={parse(elementItem.statement.replace(/<img .*?>/g, ""))}
                  maxLength={50}
                />
              </div>
              <div className="node-manager-node-item-actions-container">
                <CustomTooltip
                  title="A ação não pode ser realizada, pois a questão já está sendo utilizada em outra avaliação"
                  placement="top"
                  disableHoverListener={!disableEdit}
                  arrow
                >
                  <div
                    className={`node-manager-node-item-actions ${
                      disableEdit ? "node-disabled" : "node-purple"
                    }`}
                    onClick={() => (!disableEdit ? editQuestion(elementItem.idQuestion) : null)}
                  >
                    <span>Editar</span>
                    <FaRegEdit style={{ fontSize: 22 }} />
                  </div>
                </CustomTooltip>
                <CustomTooltip
                  title="A ação não pode ser realizada, pois a questão já está sendo utilizada em outra avaliação"
                  placement="top"
                  disableHoverListener={!disableDelete}
                  arrow
                >
                  <div
                    className={`node-manager-node-item-actions ${
                      disableDelete ? "node-disabled" : "node-red"
                    }`}
                    onClick={() => (!disableDelete ? deleteQuestion(elementItem.idQuestion) : null)}
                  >
                    <span>Remover</span>
                    {loadingDelete === elementItem.idQuestion ? (
                      <CircularProgress size={22} />
                    ) : (
                      <FiTrash2 style={{ fontSize: 22 }} />
                    )}
                  </div>
                </CustomTooltip>
              </div>
            </div>
          )}
        </Draggable>
      );
    });
  });

  return (
    <>
      <div className="BoxListQuestion">
        {loadingMoveQuestion && (
          <div style={{ position: "absolute", top: 17, right: 25, color: "gray" }}>
            <CircularProgress size={15} />
          </div>
        )}
        {loadingQuestions ? (
          <div className="BoxListQuestionLoading">
            <CircularProgress size={15} className="mr-2 mb-1" /> Carregando questões...
          </div>
        ) : questionsList.length === 0 ? (
          <NoQuestion />
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="question-list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <CardList
                    cards={questionsList}
                    updateBlock={() => {}}
                    removeBlock={() => {}}
                    loadingRemoveBlock={true}
                    loadingMoveBlock={true}
                    saveBlock={() => {}}
                    baseNodeLevel={() => {}}
                    permissionLearningPage={true}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}

        <ModalCreateQuestion
          title="Editar Questão"
          show={showEditQuestion}
          setShow={setShowEditQuestion}
          setQuestionsList={setQuestionsList}
          questionsList={questionsList}
          contextTagsInit={contextTagsInit}
          idQuestion={selectedIdQuestion}
          evaluationModel={evaluationModel}
        />
      </div>
    </>
  );
};

export default withRouter(BoxListQuestion);
