import { getAttendanceList } from "../../../../../services/NexiDigitalAPI";

export const AttendanceListRecursive = (idTeam, idMeeting, callComplete) => {
  let atList = []; // Lista que irá acumular os resultados
  let totalR = 0; // Número total de registros
  let offset = 0; // Começar pela primeira página
  const limit = 10; // Limite de itens por página

  const listParameters = {
    searchString: null,
    offset: offset,
    limit: limit,
    idTeam: idTeam,
    idMeeting: idMeeting,
  };

  const fetchData = (offset) => {
    // Atualiza o offset nas chamadas recursivas
    listParameters.offset = offset;

    getAttendanceList(listParameters)
      .then((response) => {
        const { attendanceList, totalRecords } = response;

        const attendanceListRes = attendanceList.map((item) => {
          const obj = {
            name: item.name || "-",
            employeeCode: item.employeeCode || "-",
            role: item.role || "-",
            unit: item.unit || "-",
            department: item.department || "-",
            signature: "",
          };

          return obj;
        });

        // Atualiza a lista de atendimentos e o total de registros
        atList = [...atList, ...attendanceListRes];
        totalR = totalRecords;

        // Verifica se há mais registros para carregar
        if (atList.length < totalR) {
          // Se a lista ainda não atingiu o total de registros, chama a função recursivamente
          fetchData(offset + limit); // Incrementa o offset para pegar a próxima página
        } else {
          // Caso todos os registros sejam obtidos, exibe a lista final
          //console.log("completa", atList);
          callComplete(atList);
        }
      })
      .catch((e) => {
        console.error("Erro ao buscar dados:", e);
      });
  };

  // Inicia a primeira chamada para buscar os dados
  fetchData(offset);
};
