import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import { TreeItem, TreeView } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import {
  editLearningBlockParameter,
  getLearningManagementNodeCode,
} from "../../../services/NexiDigitalAPI.js";
import Loading from "../../../components/Loading.js";
import "./index.scss";
import { Form, InputGroup } from "react-bootstrap";
import { getDepthName, getProfileInfo } from "../../../utils/Utils.js";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: "#f2f1fc",
    padding: 15,
  },
});

const RenderTree = ({ nodes, setSelectedNode, onlyEntrypoints = false }) => {
  return nodes?.map((node, index) => (
    <TreeItem
      className="catalog-modal-tree-item"
      key={index}
      nodeId={`${node.nodeCode}`}
      label={
        <>
          <span>{node.title}</span>
          {(!onlyEntrypoints || node.entryPoint === 1) &&
            <button
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                setSelectedNode(node);
              }}
              className="btn catalog-modal-button"
            >
              Selecionar
            </button>
          }
        </>
      }
    >
      {Array.isArray(node.childNodes) && node.childNodes.length > 0 ? (
        <RenderTree nodes={node.childNodes} setSelectedNode={setSelectedNode} onlyEntrypoints={onlyEntrypoints} />
      ) : null}
    </TreeItem>
  ));
};

export default function CatalogModal({
  denyAction = false,
  returnNodeData,
  blockData,
  isCatalogEdit,
  isSimpleSelect = false,
  onlyEntrypoints = false
}) {
  const element = blockData?.elements?.[0];
  const [showModal, setShowModal] = useState(true);
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [nodesData, setNodesData] = useState([]);
  const [selectedNode, setSelectedNode] = useState(isCatalogEdit ? element : null);
  const [loadingNodes, setLoadingNodes] = useState(true);
  const [depth, setDepth] = useState(null);
  const [loadingSetParameters, setLoadingSetParameters] = useState(false);

  const callToken = getProfileInfo();
  const { idCompany } = callToken;

  const _getLearningManagementNodeCode = (nodeCode) => {
    setLoadingNodes(true);
    getLearningManagementNodeCode(nodeCode)
      .then((res) => {
        let transformedData = res;
        transformedData[0].childNodes = setChildPlaceholder(res[0].childNodes);
        setNodesData(transformedData);
        const firstNodeTreeId = res?.[0]?.nodeCode;
        setExpanded([String(firstNodeTreeId)]);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoadingNodes(false);
      });
  };

  const getChildNodes = (nodeCode) => {
    getLearningManagementNodeCode(nodeCode)
      .then((res) => {
        let newNodesData = [...nodesData];
        const childData = setChildPlaceholder(res[0].childNodes);
        const result = updateChildNodes(newNodesData, nodeCode, childData);
        setNodesData(result ? newNodesData : nodesData);
      })
      .catch((err) => {
        console.log("error getting child trees", err);
      })
      .finally(() => {
        if (loadingNodes === true) {
          setLoadingNodes(false);
        }
      });
  };

  const setChildPlaceholder = (data) => {
    data.forEach((obj) => {
      if (obj.hasChildNodes === 1) {
        obj.childNodes.push({
          idProduct: 0,
          idTargetCompany: 0,
          nodeCode: "0",
          title: "Carregando...",
          enabled: 0,
          hasChildNodes: 0,
          childNodes: [],
        });
      }
    });

    return data || [];
  };

  function updateChildNodes(array, nodeCode, newChildNodes) {
    for (let i = 0; i < array.length; i++) {
      const obj = array[i];
      if (obj.nodeCode === nodeCode) {
        obj.childNodes = newChildNodes;
        return true; // Return true to indicate that the object was found and updated
      }
      if (obj.hasChildNodes && obj.childNodes.length > 0) {
        const childUpdated = updateChildNodes(obj.childNodes, nodeCode, newChildNodes);
        if (childUpdated) {
          return true; // Return true if the child object was found and updated
        }
      }
    }
    return false; // Return false if the object was not found
  }

  const PlusIcon = () => <AddIcon className="catalog-modal-icon" id="expand-id" />;

  const MinusIcon = () => <RemoveIcon className="catalog-modal-icon" id="minimize-id" />;

  const handleToggle = (event, nodeIds) => {
    const isCollapsing = event.currentTarget.querySelector("#expand-id") === null;

    const clickedNodeCode = nodeIds[0];
    setExpanded(nodeIds);

    // check if if any of the child node has been expanded
    const isChildExpanded = nodeIds?.some((item) => item.includes(clickedNodeCode + "."));

    // dont call endpoint again when collpsing
    if (!isCollapsing && !isChildExpanded) {
      getChildNodes(clickedNodeCode);
    }
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const handleNodeData = (nodeData) => {
    returnNodeData(nodeData);
    setShowModal(false);
  };

  function handleDeny() {
    if (denyAction) {
      denyAction();
      return;
    }
    setShowModal(false);
  }

  const handleChangeCatalogParameter = async () => {
    const editDepthData = {
      name: "depth",
      value: depth === "" || depth === null ? null : String(depth),
    };
    const editNodeCodeData = {
      name: "nodeCode",
      value: selectedNode.nodeCode,
    };
    setLoadingSetParameters(true);
    Promise.all([
      editLearningBlockParameter(blockData.idBlock, editDepthData),
      editLearningBlockParameter(blockData.idBlock, editNodeCodeData),
    ])
      .then((res) => {
        handleNodeData({
          ...selectedNode,
          depth: String(depth),
          textSearch: true,
          tagsFilter: true,
        });
      })
      .catch((error) => {
        console.log("ERROR promise all", JSON.parse(error?.request?.response));
      })
      .finally(() => {
        setLoadingSetParameters(false);
      });
  };

  useEffect(() => {
    _getLearningManagementNodeCode(idCompany);
  }, []);

  return (
    <Modal
      show={showModal}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="xl"
      onHide={() => {}}
    >
      <div className="modal-support-blur modal-support-blur-warning" style={{ borderRadius: 10 }} />
      <div
        onClick={() => handleDeny()}
        className="modal-warning-close-container"
        style={{ margin: 15, padding: 0, backgroundColor: "#6b48ff" }}
      >
        <CloseIcon style={{ color: "white" }} className="modal-warning-close" />
      </div>
      <div className="modal-principal-warning" style={{ minHeight: 400 }}>
        <div className="modal-body-warning" style={{ padding: 20 }}>
          {loadingNodes ? (
            <Loading>Carregando lista de nós...</Loading>
          ) : !selectedNode ? (
            <>
              <Modal.Title
                style={{ textAlign: "center", fontSize: 22, margin: "15px 0px", color: "#807b7b" }}
              >
                Selecione página de conteúdo para o catálogo
              </Modal.Title>
              <TreeView
                className={classes.root}
                defaultExpandIcon={<PlusIcon />}
                defaultCollapseIcon={<MinusIcon />}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
              >
                {<RenderTree
                  setSelectedNode={
                    (_selectedNode) => {
                      if (isSimpleSelect) {
                        returnNodeData(_selectedNode);
                        handleDeny();
                      }
                      else {
                        setSelectedNode(_selectedNode)
                      }
                    }
                  } nodes={nodesData} onlyEntrypoints={onlyEntrypoints} />}
              </TreeView>
            </>
          ) : (
            <div>
              {!isCatalogEdit && (
                <div
                  onClick={() => setSelectedNode(null)}
                  style={{ cursor: "pointer", marginBottom: 30 }}
                >
                  <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
                  Voltar
                </div>
              )}
              <div style={{ textAlign: "center", fontSize: 22, marginTop: 15, color: "#807b7b" }}>
                <span style={{ fontWeight: "bold" }}>{selectedNode.title} - </span>
                <span>Selecione a quantidade de níveis abaixo que deseja mostrar no catálogo:</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  paddingTop: isCatalogEdit ? 120 : 80,
                }}
              >
                <InputGroup style={{ width: 480 }}>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      style={{ backgroundColor: "#6b48ff", color: "white", padding: "0px 30px" }}
                    >
                      Níveis
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    as="select"
                    value={depth || ""}
                    onChange={(e) => setDepth(Number(e.target.value))}
                  >
                    <option value={null}>Todos os níveis abaixo</option>
                    <option value={1}>{getDepthName(1)}</option>
                    <option value={2}>{getDepthName(2)}</option>
                    <option value={3}>{getDepthName(3)}</option>
                    <option value={4}>{getDepthName(4)}</option>
                    <option value={5}>{getDepthName(5)}</option>
                  </Form.Control>
                  <button
                    type="button"
                    className="btn content-page-button ml-3"
                    style={{ backgroundColor: "#feac0e" }}
                    onClick={handleChangeCatalogParameter}
                    disabled={loadingSetParameters}
                  >
                    {loadingSetParameters ? "Salvando..." : "Salvar"}
                  </button>
                </InputGroup>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
