import "./styles.scss";
import React from "react";
import { AiFillHome as HomeIco } from "react-icons/ai";
import { BsTrash as TrashIco } from "react-icons/bs";
import { CircularProgress } from "@material-ui/core";

const BreadCrumb = (props) => {
  const { edit = true, trashEvent, catalog, loading = false } = props;
  const limit = 20;

  const limitText = (text) => {
    const newText = text.length > limit ? `${text.substr(0, limit)}...` : text;
    return newText;
  };
  const parentNodes = catalog.parentNodes
    .filter((it, indice) => {
      return indice > 0;
    })
    .map((it) => {
      return limitText(it.title);
    });

  const parentF = parentNodes.length > 0 ? parentNodes.join(" / ") + " / " : "";
  const stringParentNodes = parentF + limitText(catalog.title);

  return (
    <div className={`BreadCrumb mig ${edit ? "migEdit" : ""}`}>
      <div className="migText">
        <HomeIco /> raiz: {stringParentNodes}
      </div>
      {edit && (
        <div className="migBtns">
          <button
            disabled={props.dibabled}
            className="trashBtn"
            onClick={() => {
              trashEvent(catalog);
            }}
          >
            {loading ? <CircularProgress size={15} /> : <TrashIco />}
          </button>
        </div>
      )}
    </div>
  );
};

export default BreadCrumb;
