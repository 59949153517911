import React, { useState, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import moment from "moment";
import "moment/locale/pt-br.js";
import { DatePickerInput } from "rc-datepicker";
import { listAllRooms } from "../../../../../services/NexiDigitalAPI";
import FiltroWrapper from "../../../Components/FiltroWrapper";
import styles from "./styles.module.scss";
import Select from "react-select";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";

const Filtro = ({ actionFilter, loading }) => {
  const [values, setValues] = useState({
    startDate: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    idChatRoom: 1,
  });

  const [chatList, setChatList] = useState([]);

  const initialSelected = {
    value: 0,
    label: "",
  };

  const [selected, setSelected] = useState(initialSelected);
  const [options, setOptions] = useState([]);

  function changeDateStart(jsDate) {
    setValues({
      ...values,
      startDate: moment(jsDate).format("YYYY-MM-DD"),
    });
  }

  function changeDateFinish(jsDate) {
    setValues({
      ...values,
      endDate: moment(jsDate).format("YYYY-MM-DD"),
    });
  }

  const submitFilter = () => {
    actionFilter(values);
  };

  const getRoomsList = async () => {
    const rooms = await listAllRooms();
    setChatList(rooms);
    const treatedOptions = rooms.map((item) => {
      return { value: item.idChatRoom, label: item.roomName };
    });
    setOptions(treatedOptions);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      borderRadius: "0 0 1px 1px",
      border: "1px solid #ced4da",
    }),
  };

  const findChat = (idChat) => {
    const first = chatList.filter((obj) => obj.idChatRoom === idChat);
    return first;
  };

  const changeChat = async (chat) => {
    setSelected(chat);
    const chatInfo = await findChat(chat.value);
    const hasChatInfo = Boolean(chatInfo.length);
    if (hasChatInfo) {
      setValues({
        ...values,
        idChatRoom: chatInfo[0].idChatRoom,
      });
    }
  };

  useEffect(() => {
    getRoomsList();
  }, []);

  return (
    <FiltroWrapper>
      <Form style={{ marginTop: 30, marginBottom: 30 }}>
        <Form.Row className="align-items-end">
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="dateStart">Data de Início</Form.Label>
            <DatePickerInput
              name="dateStart"
              locale="pt-br"
              onChange={changeDateStart}
              value={values.startDate}
            />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="dateStart">Data de Término</Form.Label>
            <DatePickerInput
              name="dateFinish"
              locale="pt-br"
              onChange={changeDateFinish}
              value={values.endDate}
            />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="dateStart">Sala de Chat</Form.Label>
            <Select
              options={options}
              id="cadastroProdutoModalProduto"
              value={selected}
              onChange={changeChat}
              name="idUser"
              styles={customStyles}
            />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Group className="mb-0">
              <ButtonWithLoading
                onClickHandler={submitFilter}
                loading={loading}
                text={
                  <>
                    Aplicar filtro <FaFilter style={{ marginLeft: 5 }} />{" "}
                  </>
                }
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    </FiltroWrapper>
  );
};

export default Filtro;
