import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/pt-br.js";
import Filtro from "./Filtro";
import Table from "./Table";
import ReportAcordeon from "../../../Components/ReportAcordeon";
import { listUserEngagement } from "../../../../../services/NexiDigitalAPI";
import { FaCheckCircle, FaMinusCircle } from "react-icons/fa";

const Historic = ({ index, expanded }) => {
  const name = "Histórico de progresso";
  const description = "Detalhes sobre progressos.";

  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);

  const actionFilter = async (values) => {
    setLoading(true);
    // Sessions
    const responseData = await listUserEngagement(values);
    const convertedData = responseData.map((response) => {
      return {
        ...response,
        completed: Boolean(response.completed) ? (
          <FaCheckCircle style={{ fontSize: 20, color: "green" }} />
        ) : (
          <FaMinusCircle style={{ fontSize: 20, color: "red" }} />
        ),
      };
    });

    setDados(convertedData);

    setLoading(false);
  };

  const filter = <Filtro actionFilter={actionFilter} loading={loading} />;

  const result = <Table dados={dados} loading={loading} />;

  return (
    <ReportAcordeon
      name={name}
      description={description}
      filter={filter}
      result={result}
      index={index}
      expanded={expanded}
    />
  );
};

export default Historic;
