import React from "react";

const NoDataPlaceholder = ({ text = "" , style}: { text?: string, style: any }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 400,
        width: "100%",
        color: "gray",
        ...style
      }}
    >
      {text || "Não foi encontrado nenhum dado"}
    </div>
  );
};

export default NoDataPlaceholder;
