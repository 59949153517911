import React, { Fragment } from "react";
import parse from 'html-react-parser'
import './style.scss'

type Props = {
  text?: string | null,
  limit?: number | null,
  component?: React.ElementType,
  css?: object,
  className?: string
  title?: boolean,
  tooltip?: boolean,
}


function Text({ component = "p", text="" , css = {}, title = false, tooltip = false, limit = null, className = "" }: Props) {

  const As = component
  const model = component as string

  let newText = (text !== null) ? text: '';

  if( limit && text !== null ){
    newText = ( text.length > limit) ? `${text.substr(0, limit)}...` : text;
    tooltip =  text.length > limit
  }

  if( text !== null && limit !== null && text.length <= limit ){
    title = false;
    tooltip = false;
  }
  const typeClassModel = (tp) =>{

    const obj = {
      'p': 'paragraph',
      'h1': 'headline headline01',
      'h2': 'headline headline02',
      'h3': 'headline headline03',
      'h4': 'headline headline04',
      'small': 'small'
    }

    return obj[tp] ?? 'p';

  }

  const typeClass = typeClassModel( model );

  return (
    ( !!text ) ?(
      <div className='peoplecraftClass'>
        <As className={ `pctAtom pctText ${typeClass} ${className}` }
            style={css}
            title={(title) ? text : null }
            tooltip={(tooltip) ? text : null }
        >{ parse(`${newText}`) } </As>
      </div>
    ): null
  )
}

export default Text

