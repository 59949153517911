import "./styles.scss";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { Button, Modal } from "react-bootstrap";
import { GoAlert } from "react-icons/go";


type Props = {
  show: boolean;
  innerContent?: any;
  setShow: (status: boolean) => void;
  title?: string;
  text?: string;
  btnCall?: btnCallObj;
  btnCallSuport?: btnCallObj;
  btnDenyCall?: () => void;
  className?: string;
};


type btnCallObj = {
  className?: string;
  variantBtn?: string;
  text?: string;
  closeModal?: boolean;
  callEvent?: () => void;
}


const ModalContent = ({
  show,
  innerContent,
  setShow,
  title = "",
  text = "",
  btnCallSuport,
  btnCall,
  btnDenyCall,
  className,
}: Props) => {
  function handleDeny() {
    setShow(false);

    if (btnDenyCall) {
      btnDenyCall();
    }
  }

  const [showButtons, setShowButtons] = useState(true);

  useEffect(() => {
    if (show === true) {
      setShowButtons(true);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      className={`ModalContentComponent ${className ? className : ""}`}
      centered
      size="xl"
      onHide={() => {
        
      }}
    >
      <div className="modal-support-blur modal-support-blur-warning" style={{ borderRadius: 5 }} />
      <div className="modal-principal-warning">
        <div className="modal-body-warning">
          <div className="form-group form-group-block-02">
            <div className="card">
              <div className="card-body template-card-body">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="containInfo">
                      {/* <div className={`blockAlert ${variantIco}`}>
                        <GoAlert />
                      </div> */}
                      <div className="containText">
                        {title && <h4>{parse(title)}</h4>}
                        <h6>{parse(text)}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="innerContainer">
              { innerContent }
          </div>

          {showButtons &&
            (<div className="containerBtn">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 baseBtns">
                      {btnCallSuport &&
                          (<Button
                            variant={btnCallSuport.variantBtn}
                            className={btnCallSuport.className}
                            onClick={() => {
                              btnCallSuport.callEvent();
                            }}
                          >
                            {btnCallSuport.text}
                          </Button>)
                      }

                      <Button
                        variant="outline-secondary"
                        onClick={() => {
                          handleDeny();
                        }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant={btnCall.variantBtn}
                        className={btnCall.className}
                        onClick={() => {
                          btnCall.callEvent();
                          
                          setShowButtons(false);

                          if(btnCall.closeModal === true){
                            setShow(false);
                          }
                          
                        }}
                      >
                        {btnCall.text}
                      </Button>
                </div>
              </div>
            </div>)
          }


        </div>
      </div>
    </Modal>
  );
};

export default ModalContent;
