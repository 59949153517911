import React, { useState, useEffect, useContext, useRef } from "react";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import "react-datepicker/dist/react-datepicker.css";
import _ from "underscore";
import moment from "moment";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import {
  updateContent,
  getTags,
  uploadImageApi,
} from "../../services/NexiDigitalAPI";
import { getVideoDuration } from "../../services/youTubeApi";
import { getVideo } from "../../services/wistiaApi";
import SaveIcon from "@material-ui/icons/Save";
import SemImagem from "../../assets/images/sem-imagem.jpg";
import AccessTable from "../AccessTable";
import { AuthContext } from "../../context/AuthContext";
import Tags from "../../components/Tags";

import { Form } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import { DatePickerInput } from "rc-datepicker";
import ReactPlayer from "react-player";

import YouTube from "react-youtube";
import CancelButton from "components/CancelButton";
import Workload from "components/Workload";
import CmsContentLabel from "components/CmsContentLabel";
// import imageCompression from "browser-image-compression";
import { async } from "q";
import ContentImageUpload from "components/ContentImageUpload";

const Video = (props) => {
  const {
    data,
    contentTags,
    permissionApproveContent,
    isNode,
    returnNodeData,
    closeModal,
  } = props;
  // const contentData = useRef(data);
  // Lista de variáveis em ordem alfabética (para bater com a lista recebida do json)
  const [active, setActive] = useState(data.active || 0);
  const [autor, setAutor] = useState(data.author || "");
  const [autorEmail, setAutorEmail] = useState(data.authorEmail || "");
  const [backConfirmation, setBackConfirmation] = useState(
    data.backConfirmation || ""
  );
  const [backgroundColor, setBackgroundColor] = useState(
    data.backgroundColor || ""
  );
  const [content, setContent] = useState(data.content || "");
  const [contentLocation, setContentLocation] = useState(
    data.contentLocation || ""
  );
  const [description, setDescription] = useState(data.description || "");
  const [endDate, setEndDate] = useState(
    data.endDate === null ? null : new Date(data.endDate)
  );
  console.log(data)
  const [idContentGroup, setIdContentGroup] = useState(data.idContentGroup);
  const [idContentItem, setIdContentItem] = useState(data.idContentItem);
  const [idContentType, setIdContentType] = useState(data.idContentType);
  const [idSection, setIdSection] = useState(data.idSection);
  const [imageStyle, setImageStyle] = useState(data.imageStyle || "");
  const [initDate, setInitDate] = useState(
    data.initDate === null ? new Date() : new Date(data.initDate)
  );
  const [logoutConfirmation, setLogoutConfirmation] = useState(
    data.logoutConfirmation
  );
  const [referenceLink, setReferenceLink] = useState(data.referenceLink || "");
  const [showBottomMenu, setShowBottomMenu] = useState(data.showBottomMenu);
  const [showTopBar, setShowTopBar] = useState(data.showTopBar);
  const [titulo, setTitulo] = useState(data.text || "");
  const [tag, setTag] = useState("");
  const [videoUploadStatus, setVideoUploadStatus] = useState(false);
  const [mensagemVideoUpload, setMensagemVideoUpload] = useState("");
  const { loginData } = useContext(AuthContext);

  // Imagem
  const contentImageUploadRef = useRef(null);

  // variáveis de auxílio
  let tagsDeConteudo = [];
  for (let i = 0; i < contentTags.length; i++) {
    tagsDeConteudo.push({ id: i, name: contentTags[i].tag });
  }

  const [videoOriginal, setVideoOriginal] = useState(data.image || "");
  const [carregaVideo, setCarregaVideo] = useState(false);
  const [temEndDate, setTemEndDate] = useState(endDate ? true : false);
  const [tags, setTags] = useState(tagsDeConteudo);
  const [label, setLabel] = useState(data.label || "Video");
  const [lista, setLista] = useState([]);

  /*********** */
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoPlay, setVideoPlay] = useState("");
  const [youtubeId, setYoutubeId] = useState("");
  const [whistiaId, setWhistiaId] = useState("");
  console.log(data.shareable)
  const [shareable, setShareable]  = useState(data.shareable)

  const videoRef = useRef();
  const updateVideoDuration = () => {
    const hasVideo = Boolean(videoRef.current);

    if (hasVideo) {
      const duration = Boolean(videoRef.current.duration)
        ? videoRef.current.duration
        : 0;
      setVideoDuration(duration);
      return;
    }

    if (contentLocation === "") {
      setVideoDuration(0);
      return;
    }

    return setTimeout(updateVideoDuration, 500);
  };

  const matchYoutubeUrl = (url) => {
    const youtubeRegex = /^(?:(?:(?:https?:)?\/\/)?(?:www\.)?(?:m\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/|v\/|u\/\w\/|embed\?v=)?([a-zA-Z0-9_-]+)(?:\S+)?|(?:(?:https?:)?\/\/)?(?:www\.)?youtube\.com\/playlist\?list=([a-zA-Z0-9_-]+))$/;

    const match = url.match(youtubeRegex)
    if(match){
      const [videoId] = match.slice(1);
      return videoId
    }

    return false


  };


  // const matchYoutubeUrl = (url) => {
  //   var p =
  //     /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  //     console.log(url.match(p) )
  //     console.log(RegExp.$1 )
  //   return url.match(p) ? RegExp.$1 : false;
  // };

  const matchWistiaUrl = (url) => {
    let broken = url.split("/");
    return broken.find((item) => item === "be-br.wistia.com")
      ? broken[broken.length - 1]
      : null;
  };

  const configUpdateVideoDuration = async () => {
    const hasVideo = Boolean(videoRef.current);

    const idYoutube = matchYoutubeUrl(contentLocation);
    const idWistia = matchWistiaUrl(contentLocation);
    if (idYoutube) {
      setVideoPlay("youtube");
      setYoutubeId(idYoutube);
      const info = await getVideoDuration(idYoutube);
      setVideoDuration(info);
      return;
    }
    // whistia
    else if (idWistia) {
      setWhistiaId(idWistia);
      setVideoPlay("whistia");
      const info = await getVideo(idWistia);
      setVideoDuration(info.data.duration);
      setShareable(false)
      return;
    } else {
      setVideoPlay("");
      if (hasVideo) {
        const hasDuration = Boolean(videoRef.current.duration);
        if (hasDuration) {
          updateVideoDuration();
        } else {
          videoRef.current.addEventListener(
            "loadedmetadata",
            updateVideoDuration
          );
        }
      } else {
        setTimeout(configUpdateVideoDuration, 500);
      }
      setShareable(false)

    }
  };
  useEffect(() => {
    configUpdateVideoDuration();
  }, [contentLocation]);

  useEffect(() => {
    const idYoutube = matchYoutubeUrl(contentLocation);
    const idWistia = matchWistiaUrl(contentLocation);

    if (idYoutube) {
      setVideoPlay("youtube");
      setYoutubeId(idYoutube);
      return;
    }
    // whistia
    else if (idWistia) {
      setWhistiaId(idWistia);
      setVideoPlay("whistia");
      return;
    }
  }, []);

  /**** */

  useEffect(() => {
    getTags()
      .then((res) => {
        const tagSearch = res.data.tagsearch;
        const tagsSorted = _.sortBy(tagSearch, "tag");
        let nova = [];

        for (let i = 0; i < tagsSorted.length; i++) {
          nova.push({ id: tagsSorted[i].idTag, name: tagsSorted[i].tag });
        }

        setLista(nova);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const uploadVideo = (arquivo) => {
    setCarregaVideo(true);
    let selectedVideoFile = arquivo.files[0];
    const filename = "video" + idContentItem + "-" + Date.now();

    const data = new FormData(); // If file selected
    if (selectedVideoFile) {
      data.append("fileName", filename);
      data.append("fileData", selectedVideoFile);
      // data.append("profileImage", newVideofile, newVideofile.name);
      uploadImageApi(data)
        .then((response) => {
          if (response.data.error) {
            setContentLocation(videoOriginal);
            setCarregaVideo(false);
            setVideoUploadStatus(false);
            setMensagemVideoUpload("Erro ao fazer upload do arquivo");
          } else {
            setContentLocation(response.data.location);
            setCarregaVideo(false);
            setVideoUploadStatus(true);
            setMensagemVideoUpload("Video atualizado com successo");
          }
        })
        .catch((error) => {
          // If another error
          console.log("error", error);
          setContentLocation(videoOriginal);
          setCarregaVideo(false);
          setVideoUploadStatus(false);
          setMensagemVideoUpload("Erro ao fazer upload do arquivo");
        });
    } else {
      setContentLocation(videoOriginal);
      setCarregaVideo(false);
    }
  };

  const formatAppId = (id) => {
    const digitos = 7;
    let len = id.length;

    let zeros = "0".repeat(digitos - len);

    return `app${zeros}${id}`;
  };

  const removePrefixoURL = (url) => {
    const app_id = loginData.app_id;
    const app_code = formatAppId(app_id);

    if (url.includes(app_code) === false) {
      return url;
    }

    let uri = url.split(app_code)[1];
    //remove a barra que sobrou no começo
    while (uri.substring(0, 1) === "/") {
      uri = uri.substring(1);
    }
    return uri;
  };

  const sendData = async (event, newImageLocation) => {
    if (event) {
      event.preventDefault();
    }

    const publishDate = moment().format("YYYY-MM-DD hh:mm:ss.SSS");
    const userId = loginData.userid; // Usuário logado
    const tagList = _.map(tags, function (t) {
      return t.name;
    }).join(";");

    //ADICIONANDO REGRA PARA QUANDO O USUÁRIO CLICAR PARA SALVAR NO SALVAR DO FORMULÁRIO.
    const uploadImageLocation = await contentImageUploadRef.current.uploadImagem();

    if(uploadImageLocation != null){
      newImageLocation = uploadImageLocation;
    }


    let obj = {
      idContentItem: idContentItem,
      idContentGroup: idContentGroup,
      image: newImageLocation,
      text: titulo,
      description: description,
      contentLocation: contentLocation,
      publishDate: publishDate,
      initDate: moment(initDate).format("YYYY-MM-DD hh:mm:ss.SSS"),
      endDate:
        endDate === null
          ? null
          : moment(endDate).format("YYYY-MM-DD hh:mm:ss.SSS"),
      content: content,
      idUserPublisher: parseInt(userId, 10),
      author: autor,
      authorEmail: autorEmail,
      active: parseInt(active),
      idContentType: idContentType,
      tags: tagList,
      label: label,
      backConfirmation: backConfirmation === 1 ? 1 : 0,
      backgroundColor: backgroundColor,
      idSection: idSection,
      imageStyle: imageStyle,
      logoutConfirmation: logoutConfirmation,
      referenceLink: referenceLink,
      showBottomMenu: showBottomMenu,
      showTopBar: showTopBar,
      duration: videoDuration,
      shareable: shareable
    };

    updateContent(obj)
      .then((res) => {
        if (res.data.success) {
          if (isNode && returnNodeData) {
            returnNodeData({ ...obj, idContentItem: data.idContentItem });
          } else {
            props.history.goBack();
          }
        }
      })
      .catch((error) => {
        // If another error
        console.log("error", error);
      });
    //}
  };

  const   usaDataFim = (op) => {
    setTemEndDate(op);

    if (op === false) {
      setEndDate(null);
    }
  };

  return (
    <div>
      {!isNode && (
        <div className="header-title-wrapper">
          <div className="header-title">
            <button
              className="btnGoBack"
              onClick={() => {
                props.history.goBack();
              }}
            >
              <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
            </button>
            Página de conteúdo
          </div>
        </div>
      )}

      <div className="Video-form">
        <form onSubmit={sendData}>
          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">Título</div>
              <div className="card-body template-card-body">
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Título
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="titulo"
                    aria-describedby="titulo"
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                  />
                </div>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Descrição
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    aria-describedby="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">Tags</div>
              <div className="card-body template-card-body">
                <div className="row">
                  <div className="col-md-12">
                    <Tags
                      handleNewTags={(newTags) => setTags(newTags)}
                      lista={lista}
                      contentTags={contentTags}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CmsContentLabel label={label} setLabel={setLabel} />
          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">Imagem</div>
                <ContentImageUpload reference={contentImageUploadRef} data={data} sendData={sendData} defaultPrefixName={"content"}/>
            </div>
          </div>
          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">Vídeo</div>
              <div
                className="card-body template-card-body"
                style={{ minHeight: "260px" }}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="urVideodd">
                          url
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={contentLocation}
                        onChange={(e) => {
                          var Video = e.target.value;
                          setCarregaVideo(true);
                          window.setTimeout(function () {
                            setContentLocation(Video);
                            setCarregaVideo(false);
                            setVideoUploadStatus(true);
                            setMensagemVideoUpload(
                              "Video atualizado com successo"
                            );
                          }, 1);
                        }}
                      />
                    </div>
                    <input
                      type="file"
                      className="form-control-file"
                      id="video"
                      accept=".mp4"
                      onChange={(e) => uploadVideo(e.target)}
                    />
                   {videoPlay === "youtube"
                    &&  <div
                        style={{display: 'flex',alignItems: 'center',justifyContent: 'flex-start'}}>
                          <label
                            style={{marginBottom: '0'}}
                            className="form-label"
                            htmlFor="shareable"
                          >
                            Permitir compartilhamento externo
                          </label>
                          <Switch
                              style={{marginBottom: '0.7rem'}}
                              id="shareable"
                              color="primary"
                              checked={shareable}
                              onChange={(e) => setShareable(e.target.checked)}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                        </div>
                    }

                  </div>
                  {carregaVideo ? (
                    <div className="col-md-6">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="col-md-6">
                      {videoPlay === "" && (
                        <video ref={videoRef} id="teste" width="320" controls>
                          <source src={contentLocation} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                      {videoPlay === "youtube" && (
                        <YouTube opts={{ width: "400" }} videoId={youtubeId} />
                      )}
                      {videoPlay === "whistia" && (
                        <ReactPlayer
                          url={`https://be-br.wistia.com/medias/${whistiaId}`}
                        />
                      )}
                      <br />
                      {videoUploadStatus ? (
                        <span style={{ color: "green" }}>
                          {mensagemVideoUpload}
                        </span>
                      ) : (
                        <span style={{ color: "red" }}>
                          {mensagemVideoUpload}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">
                Carga horária
              </div>
              <div className="card-body template-card-body">
                <div className="form-row">
                  <Workload
                    workload={videoDuration}
                    setWorkload={setVideoDuration}
                    hideTitle
                    hideHour
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="card">
              <div className="card-header template-card-header">Autor</div>
              <div className="card-body template-card-body">
                <div className="form-row">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="autor">
                        Nome
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={autor}
                      onChange={(e) => setAutor(e.target.value)}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="autor">
                        E-mail
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      value={autorEmail}
                      onChange={(e) => setAutorEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="card overflow-visible">
              <div className="card-header template-card-header">Publicação</div>
              <div className="card-body template-card-body">
                <div className="form-row">
                  {permissionApproveContent && (
                    <div className="col-md-3">
                      <Form.Group className="access-table-select">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          value={active}
                          onChange={(e) => setActive(e.target.value)}
                        >
                          <option value={0}>Avaliação pendente</option>
                          <option value={1}>Aprovado</option>
                          <option value={2}>Cancelado</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                  )}
                  <div className="col-md-3">
                    <Form.Group className="access-table-select">
                      <Form.Label>Data de publicação</Form.Label>
                      <DatePickerInput
                        name="initDate"
                        locale="pt-br"
                        onChange={(date) => setInitDate(date)}
                        value={initDate}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <div className="access-table-select form-group">
                      <label
                        className="form-label"
                        htmlFor="tira-do-ar"
                        style={{ margin: "-3 0" }}
                      >
                        Agendar término publicação?
                      </label>
                      <br />
                      <Switch
                        className="form-group"
                        id="tira-do-ar"
                        color="primary"
                        checked={temEndDate}
                        onChange={(e) => usaDataFim(e.target.checked)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </div>
                  {temEndDate ? (
                    <div className="col-md-3">
                      <Form.Group className="access-table-select">
                        <Form.Label>Data de retirada do conteúdo</Form.Label>
                        <DatePickerInput
                          name="endDate"
                          placeholderText="Sem prazo"
                          locale="pt-br"
                          onChange={(date) => setEndDate(date)}
                          value={endDate}
                        />
                      </Form.Group>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header template-card-header">
              Permissões de acesso ao conteúdo
            </div>
            <div className="card-body template-card-body">
              <AccessTable id={data.idContentItem} />
            </div>
          </div>
          <br />
          <div
            className="row pr-3"
            style={{ padding: 5, justifyContent: "flex-end" }}
          >
            <CancelButton
              idContentItem={idContentItem}
              isNode={isNode}
              closeModal={closeModal}
            />
            <button type="submit" className="btn btn-primary">
              <SaveIcon style={{ height: 18 }} /> Salvar
            </button>
          </div>
        </form>
        <br />
      </div>
    </div>
  );
};

export default withRouter(Video);
