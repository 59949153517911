import "./style.scss";

import flow from "../../../../../../../assets/images/flow.svg";
import page from "../../../../../../../assets/images/page.svg";

import React from "react";

type Props = {
  number: number;
  className?: string;
  init?: boolean;
};

function PlaceholderTree({ number = 5, className = "", init = false }: Props) {
  let rows: number[] = [];
  let i: number = 0;

  while (++i <= number) {
    rows.push(i);
  }
  const PlaceholderItem = ({ style, className }) => {
    return (
      <div className={`ph-wrapper ${className}`} style={style}>
        <div className="ph-line-base">
          <div className="infoIn">
            <div className="ops"></div>
            <img src={flow} className="icoTiInfo" />
            <div className="ph-base-b">
              <div className="ph-text short ph-animated"></div>
              <div>•••</div>
            </div>
          </div>
        </div>
        <div className="ph-line-base">
          <div className="infoIn">
            <div className="ops ops2"></div>
            <img src={flow} className="icoTiInfo" />
            <div className="ph-base-b">
              <div className="ph-text short ph-animated"></div>
              <div>•••</div>
            </div>
          </div>
        </div>
        <div className="ph-line-base ph-line-baseX">
          <div className="infoIn">
            {/* <div className="ops ops2"></div> */}
            <img src={page} className="icoTiInfo" />
            <div className="ph-base-b">
              <div className="ph-text short ph-animated"></div>
              <div>•••</div>
            </div>
          </div>
        </div>
        <div className="ph-line-base ph-line-baseX">
          <div className="infoIn">
            {/* <div className="ops ops2"></div> */}
            <img src={page} className="icoTiInfo" />
            <div className="ph-base-b">
              <div className="ph-text short ph-animated"></div>
              <div>•••</div>
            </div>
          </div>
        </div>
        <div className="ph-line-base ph-line-baseX">
          <div className="infoIn">
            {/* <div className="ops ops2"></div> */}
            <img src={page} className="icoTiInfo" />
            <div className="ph-base-b">
              <div className="ph-text short ph-animated"></div>
              <div>•••</div>
            </div>
          </div>
        </div>
        <div className="ph-line-base ph-line-baseX">
          <div className="infoIn">
            {/* <div className="ops ops2"></div> */}
            <img src={page} className="icoTiInfo" />
            <div className="ph-base-b">
              <div className="ph-text short ph-animated"></div>
              <div>•••</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`pg-grid-placeholder-tree ${init ? "ph-wrapperInit" : ""}`}>
      {rows.map(function (i) {
        return (
          <PlaceholderItem
            key={i}
            style={{ paddingLeft: Math.round(i % 2 ? i * 20 : 20) + "px" }}
            className={className}
          />
        );
      })}
    </div>
  );
}

export default PlaceholderTree;
