import React, { useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import "./style.scss";
import { ModalUpgradeProps } from "components/PeopleCraft/ManagerTrial/model/ManagerTrial.model";
import ManagerTrial from "components/PeopleCraft/ManagerTrial";
import { useManagerTrial } from "components/PeopleCraft/ManagerTrial/context/contextManagerTrial";

const ModalUpgrade = NiceModal.create(({ obj }: ModalUpgradeProps) => {
  const modal = useModal();
  return (
    <div className={`ModalUpgrade`}>
      <div className={`modal-body-pct modal-admin`}>
        <>
          <ManagerTrial  profile={obj.profile} modal={modal} />
        </>
      </div>
    </div>
  );
});

export default ModalUpgrade;
