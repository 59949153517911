import "./styles.scss";

import React, { useState, useEffect, useContext, Fragment } from "react";
import { withRouter, useParams } from "react-router-dom";

import { Button } from "react-bootstrap";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { PACreateContext } from "../../Contexts/PAContext";

import CreateNewRules from "./Components/CreateNewRules";
import { getGroupsRules, editGroupsRules } from "../../../../../services/NexiDigitalAPI";

const GroupsRules = (props) => {
  const [rulesEdit, setRulesEdit] = useState(false);
  const { containerRules, setContainerRules, setPreloadInit, openConfirmModal } =
    useContext(PACreateContext);
  const { groupId, groupTitle } = useParams();

  useEffect(() => {
    let componentMounted = true; // Uma variável local para rastrear se o componente ainda está montado

    if (containerRules === null) {
      setPreloadInit(true);
      getGroupsRules(groupId)
        .then((res) => {
          if (componentMounted) {
            setContainerRules(res.data);
            setRulesEdit(res.data.length > 0);
            setPreloadInit(false);
          }
        })
        .catch((error) => {
          // Lidar com erros, se necessário
        });
    }

    return () => {
      componentMounted = false; // Marque o componente como desmontado ao sair do useEffect
    };
  }, [containerRules, groupId]);

  const createRules = () => {
    const newRules = {
      idGroups: groupId,
      items: [{ method: "CONTAINS" }],
    };

    setContainerRules([...containerRules, newRules]);
  };

  const returnEditar = () => {
    props.history.push(`/configuracao/grupos/editar/${groupId}/${groupTitle}/`);
  };

  const callClosed = () => {
    openConfirmModal({
      title: `Você tem certeza de que deseja sair sem salvar?`,
      text: `Caso você confirme a saída agora, todas as suas alterações de regra serão perdidas. Por favor, confirme sua escolha antes de prosseguir.`,
      variantBtn: "warning",
      textBtn: "Sair e não salvar",
      fnc: () => {
        returnEditar();
      },
    });
  };

  const validateSave = () => {
    let erroStatus = false;
    const res = containerRules.map((it) => {
      it.items.map((el) => {
        if (!el["attribute"]) {
          el.erroAttribute = true;
          erroStatus = true;
        } else {
          el.erroAttribute = false;
        }

        if (!el["value"] || el["value"].length === 0) {
          el.erroValue = true;
          erroStatus = true;
        } else {
          el.erroValue = false;
        }

        return el;
      });

      return it;
    });

    setContainerRules(res);

    if (!erroStatus) {
      if (rulesEdit) modalSave();
      else saveApi();
    }
  };

  const saveApi = () => {
    (async () => {
      setPreloadInit(true);
      await editGroupsRules(groupId, containerRules)
        .then((response) => {
          setPreloadInit(false);
          returnEditar();
          return [];
        })
        .catch((err) => {
          return [];
          throw err;
        });
    })();
  };

  const modalSave = () => {
    openConfirmModal({
      text: `Alerta <b>IMPORTANTE</b>, quaisquer alterações nas regras resultarão em modificações nas matrículas ao serem salvas. Tem certeza de que deseja prosseguir?`,
      variantBtn: "warning",
      textBtn: "Prosseguir e alterar regras",
      fnc: () => {
        saveApi();
      },
    });
  };

  useEffect(() => {
    const element = document.querySelector('.route-section-content')

    if(element){
      element.style.overflowY = 'hidden'
    }

    return () => {
      if(element){
        element.style.overflowY = 'auto'
      }
    }
  }, [])

  return (
    <div className={"PACreateRules"}>
      <div className={"PACreateRulesBase"}>
        <div className="PA-header-title-wrapper">
          <div className="PA-header-title">
            <button
              className="btnGoBack"
              onClick={() => {
                callClosed();
              }}
            >
              <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
            </button>
            Cadastro de usuário / … / Editar público-alvo / <b>Editar regra do público-alvo</b>
          </div>
        </div>

        <div className="infoRules">
          <div className="infoRulesText">
            <h1>Inserir público-alvo por regra</h1>
            <p>
              Você pode inserir regras com campos de perfis para definir seu público-alvo. Escolha
              as condições que acionam as regras para o seu público-alvo.{" "}
            </p>
          </div>
          <Button
            variant="primary"
            onClick={() => {
              createRules();
            }}
          >
            + Adicionar nova regra
          </Button>
        </div>

        <div className="listRules">
          {containerRules && containerRules.length > 0 ? (
            containerRules.map((item, indice) => {
              return (
                <Fragment key={item.idGroups + "_" + indice}>
                  <CreateNewRules rule={item} indice={indice + 1} />
                </Fragment>
              );
            })
          ) : (
            <div className="notRules">Nenhuma regra foi criada.</div>
          )}
        </div>
        <div className="footerBtns">
          <Button variant="outline-secondary" onClick={callClosed}>
            Fechar
          </Button>
          <Button variant="warning" onClick={validateSave}>
            Salvar
          </Button>
        </div>
      </div>

    </div>
  );
};

export default withRouter(GroupsRules);
