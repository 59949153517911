import React, { useState, useEffect } from "react";
import { editMenuItem, getMenuDesign } from "../../services/NexiDigitalAPI";
import Loading from "../../components/Loading";
import ErrorIcon from "@material-ui/icons/Error";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { ArrowBack, Check, Error } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { RiSave3Line } from "react-icons/ri";
import SnackComponent from "../../components/SnackComponent";

const MenuDesign = ({
  match: {
    params: { sectionId },
  },
}) => {
  const [designList, setDesignList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackInfo, setSnackInfo] = useState({ message: "", severity: "success" });
  const [error, setError] = useState(false);
  const history = useHistory();

  const setOnSaveError = () => {
    setSnackInfo({
      message: "Erro ao salvar dados.",
      severity: "error",
    });
    setOpenSnackBar(true);
  };

  const _getMenuDesignList = () => {
    setLoading(true);
    setError(false);
    getMenuDesign(Number(sectionId))
      .then((res) => {
        if (res.data.success === true) {
          setDesignList(res.data.menu);
        } else {
          console.log("Error", res);
          setError(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const _editMenuItem = () => {
    setSaving(true);
    editMenuItem(designList[0], sectionId)
      .then((res) => {
        if (res.data.success === true) {
          setSnackInfo({
            message: "Salvo com sucesso!",
            severity: "success",
          });
          setOpenSnackBar(true);
        } else {
          console.log("Erro ao gravar dados!");
          setOnSaveError();
        }
      })
      .catch((err) => {
        console.log("err", err);
        setOnSaveError();
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const updateArray = (value, index, key) => {
    let newArr = [...designList];
    newArr[index][key] = value;

    setDesignList(newArr);
  };

  const GreenButton = withStyles((theme) => ({
    root: {
      color: "white",
      backgroundColor: "#29CC61",
      "&:hover": {
        backgroundColor: "#29CC57",
      },
    },
  }))(Button);

  useEffect(() => {
    _getMenuDesignList();
  }, []);

  return (
    <div>
      <SnackComponent
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        message={snackInfo.message}
        severity={snackInfo.severity}
      />
      <div>
        <div style={{ marginBottom: "80px" }} className="header-title-wrapper">
          <div className="header-title">
            Design de seção
            <span style={{ fontSize: 16, color: "gray", marginLeft: 10 }}>
              (Section ID {sectionId})
            </span>
          </div>
        </div>
        <div
          className="user-edit-container"
          style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        >
          <div
            style={{
              width: "100%",
              marginBottom: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              onClick={() => history.push(`/layout-manager`)}
              className="design-manager-back-container"
            >
              <ArrowBack style={{ marginRight: 5 }} />
              <span>Voltar para o gerenciador de interface</span>
            </div>
            <GreenButton variant="contained" color="primary" onClick={_editMenuItem}>
              <div>
                <RiSave3Line size={22} style={{ marginBottom: 3, marginRight: 10 }} />
                {saving ? "Salvando detalhes da aplicação..." : "Salvar detalhes da aplicação"}
              </div>
            </GreenButton>
          </div>
          {loading ? (
            <Loading>Carregando seção...</Loading>
          ) : !error ? (
            <>
              {designList?.map((item, index) => {
                return (
                  <div key={index} className="design-manager-group-container">
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: 5 }}
                      variant="outlined"
                      label="Active"
                      value={item.active || ""}
                      onChange={(e) => updateArray(Number(e.target.value), index, "active")}
                    />
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: 5 }}
                      variant="outlined"
                      label="Color"
                      value={item.color || ""}
                      onChange={(e) => updateArray(e.target.value, index, "color")}
                    />
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: 5 }}
                      variant="outlined"
                      label="Content"
                      value={item.content || ""}
                      onChange={(e) => updateArray(e.target.value, index, "content")}
                    />
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: 5 }}
                      variant="outlined"
                      label="Description"
                      value={item.description || ""}
                      onChange={(e) => updateArray(e.target.value, index, "description")}
                    />
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: 5 }}
                      variant="outlined"
                      label="Id App"
                      value={item.idApp || ""}
                      onChange={(e) => updateArray(Number(e.target.value), index, "idApp")}
                    />
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: 5 }}
                      variant="outlined"
                      label="Id Menu"
                      value={item.idMenu || ""}
                      onChange={(e) => updateArray(Number(e.target.value), index, "idMenu")}
                    />
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: 5 }}
                      variant="outlined"
                      label="Id Section"
                      value={item.idSection || ""}
                      onChange={(e) => updateArray(Number(e.target.value), index, "idSection")}
                    />
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: 5 }}
                      variant="outlined"
                      label="Image"
                      value={item.image || ""}
                      onChange={(e) => updateArray(e.target.value, index, "image")}
                    />
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: 5 }}
                      variant="outlined"
                      label="Image Secondary"
                      value={item.imageSecondary || ""}
                      onChange={(e) => updateArray(e.target.value, index, "imageSecondary")}
                    />
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: 5 }}
                      variant="outlined"
                      label="Image Style"
                      value={item.imageStyle || ""}
                      onChange={(e) => updateArray(e.target.value, index, "imageStyle")}
                    />
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: 5 }}
                      variant="outlined"
                      label="Ordering"
                      value={item.ordering || ""}
                      onChange={(e) => updateArray(Number(e.target.value), index, "ordering")}
                    />
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: 5 }}
                      variant="outlined"
                      label="Section To"
                      value={item.sectionTo || ""}
                      onChange={(e) => updateArray(Number(e.target.value), index, "sectionTo")}
                    />
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: 5 }}
                      variant="outlined"
                      label="Show Text"
                      value={item.showText || ""}
                      onChange={(e) => updateArray(Number(e.target.value), index, "showText")}
                    />
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: 5 }}
                      variant="outlined"
                      label="Text"
                      value={item.text || ""}
                      onChange={(e) => updateArray(e.target.value, index, "text")}
                    />
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: 5 }}
                      variant="outlined"
                      label="Url"
                      value={item.url || ""}
                      onChange={(e) => updateArray(e.target.value, index, "url")}
                    />
                  </div>
                );
              })}
            </>
          ) : (
            <div className="design-manager-error-container">
              <ErrorIcon style={{ marginBottom: 15, fontSize: 50 }} />
              <span>Erro ao consultar seção</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuDesign;
