import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import "moment/locale/pt-br.js";
import Filtro from "./Filtro";
import Table from "./Table";
import ReportAcordeon from "../../../Components/ReportAcordeon";
import { getListCampaigns } from "../../../../../services/NexiDigitalAPI";

const Listcampaigns = ({ index }) => {
  const name = "Campanhas";
  const description = "Detalhes sobre as campanhas cadastradas";
  const isMountedRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const [dadosFilter, setDadosFilter] = useState([]);
  useEffect(() => {
    isMountedRef.current = true;
    getListCampaigns()
      .then((res) => {
        if (isMountedRef.current) {
          const status = ["Desativado", "Ativo", "Finalizado"];
          let dados = res.map((elem) => {
            return {
              ...elem,
              startDate: moment(elem.startDate).format("DD/MM/YYYY"),
              endDate: moment(elem.endDate).format("DD/MM/YYYY"),
              status: status[elem.status],
            };
          });
          setDados(dados);
          setDadosFilter(dados);
        }
        setLoading(false);
      })
      .catch((err) => {
        setDados([]);
      });

    return () => (isMountedRef.current = false);
  }, []);

  const actionFilter = async (name) => {
    setLoading(true);
    const responseData = dados.filter(
      (elem) => elem.name.toLowerCase().indexOf(name.toLowerCase()) > -1,
    );
    setDadosFilter(responseData);
    setLoading(false);
  };

  const filter = <Filtro actionFilter={actionFilter} loading={loading} />;

  const result = <Table dados={dadosFilter} loading={loading} />;

  return (
    <ReportAcordeon
      name={name}
      description={description}
      filter={filter}
      result={result}
      index={index}
    />
  );
};

export default Listcampaigns;
