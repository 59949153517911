import React, { useState, useEffect } from "react";

import moment from "moment";
import "moment/locale/pt-br.js";

// Page UI and icons
import { Col, Form } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import { getUsersApi } from "../../../../../services/NexiDigitalAPI";
import FiltroWrapper from "../../../Components/FiltroWrapper";
import styles from "./styles.module.scss";
import Select from "react-select";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";

const Filtro = ({ actionFilter, loading }) => {
  const [values, setValues] = useState({
    startDate: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    idUser: 0,
  });

  const initialSelected = {
    value: 0,
    label: "",
  };

  const [userList, setUserList] = useState([]);
  const [selected, setSelected] = useState(initialSelected);
  const [options, setOptions] = useState([]);

  async function startUsers() {
    const usersApi = await getUsersApi();
    setUserList(usersApi);
    const treatedOptions = usersApi.map((item) => {
      return { value: item.idUser, label: item.name };
    });
    setOptions(treatedOptions);
  }

  function handleDateStart(jsDate) {
    let date = moment(jsDate).format("YYYY-MM-DD");
    setValues({
      ...values,
      startDate: date,
    });
  }

  function handleDateFinish(jsDate) {
    let date = moment(jsDate).format("YYYY-MM-DD");
    setValues({
      ...values,
      endDate: date,
    });
  }

  function handleFilter() {
    actionFilter(values);
  }

  const findUser = (idUser) => {
    const first = userList.filter((obj) => obj.idUser === idUser);
    return first;
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      borderRadius: "0 0 1px 1px",
      border: "1px solid #ced4da",
    }),
  };

  const changeUser = async (user) => {
    setSelected(user);
    const userInfo = await findUser(user.value);
    const hasUserInfo = Boolean(userInfo.length);
    if (hasUserInfo) {
      setValues({
        ...values,
        idUser: userInfo[0].idUser,
      });
    }
  };

  useEffect(() => {
    startUsers();
  }, []);

  return (
    <FiltroWrapper>
      <Form style={{ marginTop: 30, marginBottom: 30 }}>
        <Form.Row className="align-items-end">
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="startDate">Data de Início</Form.Label>
            <DatePickerInput locale="pt-br" onChange={handleDateStart} value={values.startDate} />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="endDate">Data de Término</Form.Label>
            <DatePickerInput locale="pt-br" onChange={handleDateFinish} value={values.endDate} />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Label htmlFor="dateStart">Usuário</Form.Label>
            <Select
              options={options}
              id="cadastroProdutoModalProduto"
              value={selected}
              onChange={changeUser}
              name="idUser"
              styles={customStyles}
            />
          </Col>
          <Col xs="12" md="4" lg="2" className={styles.formSpace}>
            <Form.Group className="mb-0">
              <ButtonWithLoading
                onClickHandler={handleFilter}
                loading={loading}
                text={
                  <>
                    Aplicar filtro <FaFilter style={{ marginLeft: 5 }} />{" "}
                  </>
                }
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    </FiltroWrapper>
  );
};

export default Filtro;
