import React, { useState, useEffect, useReducer, useMemo, memo, useCallback } from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./styles.scss";

import { useParams } from "react-router-dom";

import ReportResumo from "./ReportResumo";
import ReportUser from "./ReportUser";
import ReportRanking from "./ReportRanking";
import ReportChat from "./ReportChat";
import ReportUploads from "./ReportUploads";
import ReportsContent from "./ReportsContent";
import ReportDayWeek from "./ReportDayWeek";
import ReportContentIntent from "./ReportContentIntent";
import ReportsGamification from "./ReportsGamification";
import ReportsLearning from "./ReportsLearning";

import { getMenuRoutes, getParameterByName, getPermission } from "../../utils/Utils";
import ReportsLearningTraining from "./ReportsLearningTraining";



const initialState = {
  dashboard: false,
  content: false,
  user: false,
  rating: false,
  chat: false,
  uploads: false,
  gamification: false,
  accessPerWeek: false,
  learningGeneral: false,
  learningReport: true,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_PERMISSIONS":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

const tabConfig = {
  dashboard: {
    index: 0,
    component: ReportResumo,
    label: "Dashboard",
    keyRel: null,
  },
  content: {
    index: 1,
    component: ReportsContent,
    label: "Relatórios de Conteúdo",
    keyRel: "conteudo",
  },
  user: {
    index: 2,
    component: ReportUser,
    label: "Relatórios de Usuário",
    keyRel: "usuario",
  },
  rating: {
    index: 3,
    component: ReportRanking,
    label: "Relatórios de Avaliação",
    keyRel: "avaliacao",
  },
  chat: {
    index: 4,
    component: ReportChat,
    label: "Relatórios de Chat",
    keyRel: "chat",
  },
  uploads: {
    index: 5,
    component: ReportUploads,
    label: "Relatórios de Upload",
    keyRel: "uploads",
  },
  gamification: {
    index: 6,
    component: ReportsGamification,
    label: "Gamificação",
    keyRel: "gamification",
  },
  learningGeneral: {
    index: 7,
    component: ReportsLearning,
    label: "Treinamentos - Visão Geral",
    keyRel: "learning",
  },
  learningReport: {
    index: 8,
    component: ReportsLearningTraining,
    label: "Treinamentos - Relatórios",
    keyRel: "learningreport",
  },
};

export default function Reports() {
  const initialRel = {
    conteudo: false,
    usuario: false,
    avaliacao: false,
    chat: false,
    uploads: false,
    acessoporsemana: false,
    interacaodeconteudo: false,
    gamification: false,
    learning: false,
    learningreport: false,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true)
  const [chooseTab, setChooseTab] = useState(0);
  const [chooseRel, setChooseRel] = useState(initialRel);

  const getTab = (text) => {
    return tabConfig[text] ? tabConfig[text].index : 0;
  };

  /* [Permission Module] - Menu Options */
  const getMenuOption  = useCallback(async () =>{

    setLoading(true)
    const menuOptionList = await getMenuRoutes();

    const objCurrentMenu = menuOptionList.filter((menu) => menu.text === "Relatórios");

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);

      getPermissionsPage(resources);
    }
  }, [])

  /* [Permission Module] - Permission attrs in page by login data information */
  const  getPermissionsPage = useCallback((resources)  => {
    try {
      const permissions = {
        dashboard: false, //getPermission(resources[0], "Dashboard"),
        content: false, //getPermission(resources[0], "Relatórios de Conteúdo"),
        user: false, //getPermission(resources[0], "Relatórios de Usuário"),
        rating: false, //getPermission(resources[0], "Relatórios de Avaliação"),
        chat: false, //getPermission(resources[0], "Relatórios de Chat"),
        uploads: false, //getPermission(resources[0], "Relatórios de Upload"),
        accessPerWeek: false, //getPermission(resources[0], "ABA ACESSOS POR SEMANA"),
        gamification: false, //getPermission(resources[0], "Gamificação"),
        learningGeneral: getPermission(resources[0], "Treinamentos - Visão Geral"),
        learningReport: getPermission(resources[0], "Treinamentos - Relatórios"),
      };
      dispatch({ type: "SET_PERMISSIONS", payload: permissions });

    } catch (error) {
      console.log(error);
    }finally{
      setLoading(false)

    }
  }, [])

  useEffect(() => {
    /* [Permission Module] - Function calls */
    getMenuOption();
    const tabs = getParameterByName("tabs");
    const rel = getParameterByName("rel");

    const _rel = { ...initialRel };
    if (_rel.hasOwnProperty(tabs)) {
      _rel[tabs] = rel;
    }
    setChooseRel(_rel);
    setChooseRel(getTab(tabs));
  }, []);

  const getVisibleTabs = useMemo(() => {
    return Object.keys(tabConfig).reduce((acc, key) => {
      if (state[key]) {
        acc[key] = tabConfig[key];
      }
      return acc;
    }, {});
  }, [state]);

  const renderTabList = () => {
    return (
      <TabList>
        {Object.keys(getVisibleTabs).map((key) => (
          <Tab key={key}>{getVisibleTabs[key].label}</Tab>
        ))}
      </TabList>
    );
  };

  const renderTabPanels = () => {

    return Object.keys(getVisibleTabs).map((key, index) => {
      const { component: Component, keyRel } = getVisibleTabs[key];
      return (
        <TabPanel key={`tabelPanell-${index}`}>
          {chooseTab === index && <Component rel={keyRel ? chooseRel[key] : null} />}
        </TabPanel>
      );
    });
  };
  return (
    <div className="Reports">
      <div className="header-title-wrapper">
        <div className="header-title">Relatórios</div>
      </div>
      {!loading ? (
        <Tabs
          selectedIndex={chooseTab}
          onSelect={(tabIndex) => {
            setChooseTab(tabIndex);
            setChooseRel(initialRel);
          }}
        >
          {renderTabList()}
          {renderTabPanels()}
        </Tabs>
      ) : null}
    </div>
  );
}
