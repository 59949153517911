import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import { Button, Form, Modal } from "react-bootstrap";
import PreloaderBlur from "containers/PeopleCraft/PreloaderBlur";
import { listcampaignuserpoints } from "services/NexiDigitalAPI";
import DataTable from "react-data-table-component";

const ViewScore = ({ data, denyAction }) => {
  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const columns = [
    {
      name: "Ação",
      selector: "event",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Nome (Descrição)",
      sortable: false,
      selector: "text",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Pontos",
      sortable: false,
      selector: "points",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (data, index) => (
        <div key={data.points + index} className={`${data.points < 0 && 'negative-value'}`}>
          <p>{data.points}</p>
        </div>
      ),
    },
    {
      name: "Campanha",
      sortable: false,
      selector: "campaign",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Data",
      sortable: false,
      selector: "campaign",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (data, index) => (
        <div>
          <p>{data?.eventDate ? new Date(data?.eventDate).toLocaleDateString(): ''}</p>
        </div>
      ),
    },
  ];

  const getPointsUser = useCallback(async () => {
    if (data.idUser) {
      setLoading(true);
      try {
        let userPoints = await listcampaignuserpoints({
          idCampaign: data.idCampaign,
          idUser: data.idUser,
        }, pagination);
        setDataTable(userPoints);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    }
  }, [data, pagination]);

  useEffect(() => {
    getPointsUser();
  }, [getPointsUser]);

  const CustomNoDataComponent = () => (
    <div className="noContent" style={{ textAlign: "center" }}>
      <p>Nenhum ponto encontrado</p>
    </div>
  );

  const handleClickModel = () => {
    setShowModal(false)
    if (denyAction) {
      denyAction()
    }
  }
  const paginationComponentOptions = {
    rowsPerPageText: "Conteúdos por página",
    rangeSeparatorText: "de",
  };

  const onChangePage = (page) => {
    setPagination((prev) => ({ ...prev, offset: --page * prev.limit }));
  }

  const onChangeRowsPerPage = (limit, page) => {
    const newOffset = (page - 1) * limit;
    setPagination((prev) => ({ ...prev, limit, offset: newOffset }));
  };
  return (
    <Modal
      show={showModal}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <div className="modal-support-blur modal-support-blur-warning" style={{ borderRadius: 10 }} />

      <div className="modal-principal-warning" style={{ minHeight: 400 }}>
        <Modal.Title style={{ textAlign: "left", fontSize: 20, marginTop: 20, marginLeft: 20 }}>
          <div className="flex-row-title">
            <h5 style={{fontWeight: 700}}>{data.name}</h5>
            <span className="points">
              {data.points} pts
            </span>
          </div>
        </Modal.Title>
        <Modal.Body className="modal-body-warning">
          <div className="edit-score">
            <div className="flex-column">
              <div className="view-score-table-container">
                <DataTable
                  progressPending={loading}
                  columns={columns}
                  data={dataTable}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  noHeader
                  fixedHeader
                  noDataComponent={<CustomNoDataComponent />}
                  paginationComponentOptions={paginationComponentOptions}
                  paginationTotalRows={dataTable.length}
                  pagination
                />
              </div>

            </div>
          </div>
        </Modal.Body>
      </div>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => handleClickModel()}>
          Fechar
        </Button>

      </Modal.Footer>
    </Modal>
  );
}

export default ViewScore;
