import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import {
  getMenuData,
  setLoginData,
  setUserAgreement,
  logout,
  acceptResponsabilityTerms,
  updateOfferTerms,
} from "../../services/NexiDigitalAPI";
import { getProfileInfo } from "../../utils/Utils";
import { Form, Modal, Button } from "react-bootstrap";
import PreloaderBlur from "containers/PeopleCraft/PreloaderBlur";
import { PiWarning } from "react-icons/pi";
import "./styles.scss";

export default function TermsModal({ showModal, setShowModal }) {
  const menuData = getMenuData() || {};
  const { showOfferTerm } = JSON.parse(localStorage.getItem("appDetails")) || null;
  const { loginData } = useContext(AuthContext);
  const thirdColor = loginData?.thirdColor;
  const [termsAccepted, setTermsAccepted] = useState({});
  const [showError, setShowError] = useState(false);
  const [termsPageIndex, setTermsPageIndex] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [loadingSignTerm, setLoadingSignTerm] = useState(false);
  const {
    responsabilityTerms,
    agreementTerms,
    userLicenceTermsAccepted,
    agreementVersion,
    offerTerms,
    offerTermsAccepted,
    needSignResponsabilityTerms,
  } = menuData;

  const {
    name: responsabilityTermsName,
    content: responsabilityTermsContent,
    versionNumber: responsabilityTermsVersionNumber,
    idTermOfResponsibility: responsabilityTermsId,
    hasOldSignature: responsabilityTermsHasOldSignature,
  } = responsabilityTerms || {};

  const termsPageList = [
    {
      name: "Termos de licença",
      type: "license",
      content: agreementTerms,
      show: userLicenceTermsAccepted !== agreementVersion,
    },
    {
      name: "Termos de oferta",
      type: "offer",
      content: offerTerms,
      show: !offerTermsAccepted,
    },
    {
      name: responsabilityTermsName,
      type: "responsibility",
      content: responsabilityTermsContent,
      show: needSignResponsabilityTerms,
      updated: responsabilityTermsHasOldSignature,
    },
  ];

  const filteredTerms = termsPageList.filter(({ show }) => show);

  const handleCheckboxChange = (type, checked) => {
    setTermsAccepted((prev) => ({ ...prev, [type]: checked }));
  };

  const getTermByType = (type) => {
    return filteredTerms.find((term) => term.type === type);
  };

  const updateTerms = async () => {
    setLoadingSignTerm(true);
    try {
      const menuData = getMenuData() || {};
      let loginData = { ...menuData };

      if (getTermByType("responsibility")) {
        await acceptResponsabilityTerms({
          idTermOfResponsibility: responsabilityTermsId,
          versionNumber: responsabilityTermsVersionNumber,
        });

        loginData = {
          ...loginData,
          needSignResponsabilityTerms: false,
        };
      }

      if (getTermByType("license")) {
        const data = {
          communicationAccepted: termsAccepted.communication ? 1 : 0,
          userLicenceTermsAccepted: menuData.agreementVersion,
        };

        const { idUser } = getProfileInfo();

        await setUserAgreement(idUser, data);

        loginData = {
          ...loginData,
          userLicenceTermsAccepted: menuData.agreementVersion,
        };
      }

      if (getTermByType("offer")) {
        await updateOfferTerms();

        loginData = {
          ...loginData,
          offerTermsAccepted: termsAccepted.offer ? 1 : 0,
        };
      }

      setLoginData(loginData);
    } catch (error) {
      setShowError(true);
    } finally {
      setLoadingSignTerm(false);
    }
  };

  const continuar = () => {
    if (
      (getTermByType("license") && !termsAccepted.license && showOfferTerm) ||
      (getTermByType("offer") && showOfferTerm === 1 && !termsAccepted.offer) ||
      (getTermByType("responsibility") && !termsAccepted.responsibility)
    ) {
      setShowError(true);
      return;
    }

    updateTerms();
    setShowModal(false);
  };

  const setLogout = () => {
    logout();
    setRedirect(true);
  };

  const noTermsFound = () => {
    return `
      <div style="text-align: center; padding: 10px 0px; color: gray">
        Nenhum termo encontrado.
      </div>
    `;
  };

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      {loadingSignTerm && <PreloaderBlur />}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="modal-main-container"
      >
        <div className="modal-support-blur" />
        <Modal.Header>
          <Modal.Title>{filteredTerms[termsPageIndex].name}</Modal.Title>
        </Modal.Header>
        <div className="modal-principal">
          {filteredTerms[termsPageIndex].type === "responsibility" &&
            filteredTerms[termsPageIndex].updated && (
              <div className="terms-modal-header">
                <PiWarning className="warning-icon" />
                <div className="warning-message">
                  <div className="message-text">
                    <span>Os termos de responsabilidade da sua organização foram atualizados.</span>
                    <span>
                      Para continuar utilizando a plataforma, é necessário que você os aceite
                      novamente.
                    </span>
                  </div>
                </div>
              </div>
            )}
          <div className="modal-body-term-container">
            <div
              dangerouslySetInnerHTML={{
                __html: filteredTerms[termsPageIndex].content || noTermsFound(),
              }}
            />
          </div>

          <div className="terms-page-container">
            {filteredTerms &&
              filteredTerms.length > 1 &&
              filteredTerms.map((item, index) => (
                <div
                  className="terms-page-item"
                  onClick={() => setTermsPageIndex(index)}
                  key={index}
                  style={{
                    backgroundColor: termsPageIndex === index ? thirdColor : "transparent",
                    color: termsPageIndex === index ? "white" : "black",
                  }}
                >
                  {`${index + 1}. ${item.name}`}
                </div>
              ))}
          </div>

          <div className="terms-checkbox-container" style={{ marginTop: 15, fontWeight: 500 }}>
            {filteredTerms.map(
              (term, index) =>
                term.show && (
                  <Form.Group
                    className="terms-form-group"
                    controlId={`formBasicCheckbox${term.type}`}
                    key={index}
                  >
                    <Form.Check
                      onChange={(e) => handleCheckboxChange(term.type, e.target.checked)}
                      checked={termsAccepted[term.type] || false}
                      type="checkbox"
                      label={`Li e aceito os ${term.name.toLowerCase()}.`}
                    />
                    {filteredTerms && filteredTerms.length > 1 && (
                      <span className="terms-check-number">{`[${index + 1}]`}</span>
                    )}
                  </Form.Group>
                ),
            )}
            <Form.Group className="terms-form-group" controlId="formBasicCheckboxCommunication">
              <Form.Check
                onChange={(e) => handleCheckboxChange("communication", e.target.checked)}
                type="checkbox"
                label="Aceito receber e-mail ou outras comunicações."
                checked={termsAccepted.communication || false}
              />
            </Form.Group>

            {showError && (
              <div className="term-error">Você precisa aceitar os termos para continuar.</div>
            )}
          </div>
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={setLogout}>
            Sair
          </Button>
          <Button variant="primary" onClick={continuar}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
