import React, { Component, useState } from "react";
import { IoMdClose } from "react-icons/io";
import "./style.scss";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useHistory } from "react-router-dom";
import parse from 'html-react-parser';

type Props = {
  obj: any;
};

const ModalLoadingInfo = NiceModal.create(({ obj }: Props) => {
  const history = useHistory();
  const modal = useModal();
  let timerHandle: any;

  const {
    message,
    content,
    closeBtn,
    closeBtnFn,
    loadding,
    verificationFunction,
    verificationInterval,
    className
  } = obj;

  if (verificationFunction) {
    timerHandle = setInterval(
      function () {
        verificationFunction().then((response) => {
          if (response === true) {
            if (timerHandle) clearInterval(timerHandle);
            modal.remove();
          }
        });
      }.bind(this),
      verificationInterval
    );
  }

  return (
    <div className={`modalLoadingInfo ${((className)? className : '')}`}>
      <div className="modal-body-pct">


        {message && <h4>{ parse(`${message}`) }</h4>}
        {loadding && <div className="preloaderIt"></div>}



        {content && content()}

        {closeBtn && (
          <button className="closeBtn" onClick={() => {

            if(timerHandle){
              clearInterval(timerHandle);
            }

            if(closeBtnFn) closeBtnFn();

            modal.remove();
          }}>
            <IoMdClose />
          </button>
        )}

          <div className="bottonLine"></div>
      </div>
    </div>
  );
});

export default ModalLoadingInfo;
