import React, { useState, useEffect, useCallback } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import MaskInput from "react-maskinput";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import Loading from "../../components/Loading";

import { appDetails } from "../../services/NexiDigitalAPI";
import { getProfilesSecondary, setUserSecondary } from "../../services/NexiDigitalAPISecondary";
import ImportManager from "components/ImportManager";
import AutoComplete from "components/PeopleCraft/Autocomplete";


export default function UserForm({ submitResult, token, notAuto }) {
  const history = useHistory();
  const goBack = useCallback(() => history.push("/user"), [history]);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingProfiles, setLoadingProfiles] = useState(true);
  const [profiles, setProfiles] = useState([]);
  const [profileSelected, setProfileSelected] = useState([]);
  const defaultData = {
    email: "",
    nome: "",
    fone: "",
    cpf: "",
    admin: 0,
    password: "",
    userType: 1,
    userLicenceTermsAccepted: 0,
    communicationAccepted: 0,
    guestCompany: " ",
    guestCnpj: " ",
    active: 1,
    photo: "",
    branch: ''
  };

  const [data, setData] = useState(defaultData);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [wrongPassword, setWrongPassword] = useState(false);
  const [errorProfile, setErrorProfile] = useState(false);

  const [passwordEqualError, setPasswordEqualError] = useState(false);
  const [errorSave, setErrorSave] = useState(false);
  const [details, setDetails] = useState([]);
  const [messageError, setMessageError] = useState("");
  const [selectedManager, setSelectedManager] = useState(null);
  const hasMessageError = Boolean(messageError);

  const url =
    window.location.origin === "http://localhost:3000"
      ? "devadmin.blast.etc.br"
      : window.location.origin.replace(/https:\/\/|http:\/\//g, "");

  useEffect(() => {
    window.scrollTo(0, 0);
    _appDetails();
  }, []);

  function _appDetails() {
    appDetails(url)
      .then((res) => {
        if (res.data.app.length == 0) {
          setLoadingScreen(false);
          return;
        }
        setDetails(res.data.app[0]);
        setLoadingScreen(false);
      })
      .catch((err) => {
        setLoadingScreen(false);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setMessageError("");
    setWrongPassword(false);
    setPasswordEqualError(false);
    setErrorSave(false);

    //validar senha (8 digit and a number)
    let passwordMatch = /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/gm.test(data.password);
    if (data.password && !passwordMatch) {
      setWrongPassword(true);
      return;
    }

    //validar confirmação de senha
    if (data.password && data.password != confirmPassword) {
      setPasswordEqualError(true);
      return;
    }

    if(data.admin === 1 && !profileSelected?.length){
      setErrorProfile(true)
      return
    }

    submit(data);
  }

  async function submit(obj) {
    try {
      setLoadingUser(true);
      let userDataPayload = {
        username: obj.username,
        unit: obj.unit,
        employeeCode: obj.employeeCode,
        email: obj.email,
        name: obj.nome,
        phone: obj.fone,
        cpf: obj.cpf,
        userType: obj.userType,
        userLicenceTermsAccepted: obj.userLicenceTermsAccepted,
        communicationAccepted: obj.communicationAccepted,
        guestCompany: obj.guestCompany,
        guestCnpj: obj.guestCnpj,
        admin: obj.admin,
        active: obj.active,
        photo: obj.photo,
        idManager: obj.idManager,
        role: obj.role,
        department: obj.department,
        idProfile: profileSelected?.length ? profileSelected.map(elem => elem.idProfile) : undefined,
        branch: obj.branch,
      };
      if (obj.password) {
        userDataPayload.password = obj.password;
      }
      setUserSecondary(userDataPayload)
        .then(({ data }) => {
          setLoadingUser(false);
          if (data.success) {
            submitResult();
          } else {
            setMessageError(data.message);
          }
        })
        .catch((e) => {
          setLoadingUser(false);
          let message = e.response.data.message ? e.response.data.message : e.response.data.error;
          if (message === "Not Authorized") {
            message = "Não Autorizado";
          }
          setMessageError(message);
          console.log("Erro ao cadastrar usuário", e);
        });
    } catch (e) {
      alert(e.message);
    }
  }

  const handleOnClickManager = (manager) => {
    setData({ ...data, idManager: manager.idUser });
    setSelectedManager(manager);
  };

  async function handleProfileSelect(event, value) {
    setProfileSelected(value);
    errorProfile && setErrorProfile(false)
  }

  async function getAllProfiles() {
    setLoadingProfiles(true);
    const profilesResponse = await getProfilesSecondary().finally(() => {
      setLoadingProfiles(false);
    });
    const profileList = profilesResponse?.map((profileItem) => ({
      ...profileItem,
      label: profileItem.name,
    }));
    setProfiles(profileList || []);
  }

  useEffect(() => {
    getAllProfiles();
  }, []);

  return (
    <>
      {loadingScreen ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div>
          <div style={{ marginBottom: "80px" }} className="header-title-wrapper">
            <div className="header-title">{notAuto ? "Registro de usuário" : "Auto inscrição"}</div>
          </div>
          <div className="user-edit-container">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Row>
                  <Col lg="6" md="6" xs="12" sm="4">
                    <Form.Label>Nome do usuário</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Digite o seu nome"
                      value={data.nome}
                      onChange={(e) => setData({ ...data, nome: e.target.value })}
                    />
                  </Col>

                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder="Digite o seu email"
                      value={data.email}
                      onChange={(e) => setData({ ...data, email: e.target.value })}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="6">
                    <Form.Label>Login</Form.Label>
                    <Form.Control
                      required
                      type="username"
                      placeholder="Digite o login"
                      value={data.username}
                      onChange={(e) => setData({ ...data, username: e.target.value })}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col lg="4" md="4" xs="12" sm="7">
                    <Form.Label>
                      Senha <span style={{ color: "gray" }}>(Opcional)</span>
                    </Form.Label>
                    <Form.Control
                      className={wrongPassword ? "border-red" : "border-none"}
                      type="password"
                      autoComplete="new-password"
                      placeholder="Senha"
                      onChange={(e) => setData({ ...data, password: e.target.value })}
                    />
                    <Form.Text className="text-muted">Pelo menos 8 caracteres</Form.Text>
                    <Form.Text className="text-muted">Pelo menos uma letra</Form.Text>
                    <Form.Text className="text-muted">Pelo menos um número</Form.Text>
                    <Form.Group>
                      *Se o campo senha não for preenchido, um email com instruções para troca de
                      senha será enviado. Caso uma senha seja passada, o email não será enviado.
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="4" xs="12" sm="7">
                    <Form.Label>Confirmar senha</Form.Label>
                    <Form.Control
                      className={passwordEqualError ? "border-red" : "border-none"}
                      type="password"
                      disabled={data.password.length == 0}
                      autoComplete="new-password"
                      placeholder="Senha"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <ImportManager
                onClickManager={handleOnClickManager}
                selectedManager={selectedManager}
              />

              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Cargo</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite um cargo"
                      value={data.role}
                      onChange={(e) => setData({ ...data, role: e.target.value })}
                    />
                  </Col>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Departamento</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite um departamento"
                      value={data.department}
                      onChange={(e) =>
                        setData({
                          ...data,
                          department: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="6">
                    <Form.Label>Unidade</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite uma unidade"
                      value={data.unit}
                      onChange={(e) => setData({ ...data, unit: e.target.value })}
                    />
                  </Col>
                  <Col lg="6" md="6" xs="12" sm="6">
                    <Form.Label>Filial</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite código da filial"
                      value={data.branch}
                      onChange={(e) => setData({ ...data, branch: e.target.value })}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="formEmployee">
                <Row>

                <Col lg="3" md="4" xs="12" sm="7">
                    <Form.Label>Código de colaborador</Form.Label>
                    <Form.Control
                      type="employeeCode"
                      placeholder="Digite código de colaborador"
                      value={data.employeeCode}
                      onChange={(e) => setData({ ...data, employeeCode: e.target.value })}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="formCelularLabel">
                <Row>
                  <Col lg="4" md="4" xs="12" sm="7">
                    <Form.Label>Celular </Form.Label>
                    <br />
                    <MaskInput
                      style={{ width: "100%" }}
                      required
                      className="mask-input"
                      alwaysShowMask
                      mask={"(00) 00000-0000"}
                      size={20}
                      showMask
                      maskChar="_"
                      placeholder="Digite o número do seu celular"
                      value={data.fone}
                      onChange={(e) => setData({ ...data, fone: e.target.value })}
                    />
                  </Col>
                  <Col lg="4" md="4" xs="12" sm="7">
                    <Form.Label style={{ position: "relative" }}>
                      <span className="add-user-optional">(opcional)</span>
                      CPF
                    </Form.Label>
                    <br />
                    <MaskInput
                      style={{ width: "100%" }}
                      className="mask-input"
                      alwaysShowMask
                      mask={"000.000.000-00"}
                      size={20}
                      showMask
                      maskChar="_"
                      placeholder="Digite o número do seu CPF"
                      value={data.cpf}
                      onChange={(e) => setData({ ...data, cpf: e.target.value })}
                    />
                  </Col>
                </Row>
              </Form.Group>

              {notAuto && (
                <Row>
                  <Col lg={4} md={4} xs={Boolean(data.admin) ? 5 : 12} sm="5">
                    <Form.Group controlId="formAdmin">
                      <Form.Label>Admin</Form.Label>
                      <Form.Control
                        as="select"
                        value={data.admin}
                        onChange={(e) =>
                          setData({
                            ...data,
                            admin: parseInt(e.target.value),
                          })
                        }
                      >
                        <option value={0}>Não</option>
                        <option value={1}>Sim</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  {Boolean(data.admin) && (
                    <Col lg="4" md="4" xs="7" sm="5">
                      <AutoComplete
                        error={data.admin === 1 && errorProfile}
                        onChange={(e, value) => handleProfileSelect(e, value)}
                        label="Associar ao perfil"
                        tooltip="Selecione ao menos um perfil de permissão."
                        multiple={true}
                        loading={loadingProfiles}
                        value={profileSelected}
                        options={profiles}
                        placeholder="Selecionar perfil"
                      ></AutoComplete>
                    </Col>
                  )}
                </Row>
              )}
              <div className="mb-2" style={{ display: "flex", marginTop: 30 }}>
                {notAuto && (
                  <button
                    onClick={goBack}
                    className="button-peop button-gray"
                    style={{ marginRight: 15 }}
                    size="sm"
                  >
                    <div>
                      <BiArrowBack size={22} style={{ marginBottom: 3, marginRight: 10 }} />
                      Voltar
                    </div>
                  </button>
                )}

                <button className="button-peop button-yellow" type="submit" size="sm">
                  {!loadingUser ? (
                    "Efetuar o Registro"
                  ) : (
                    <div style={{ display: "flex" }}>
                      <div className="loader loader-white" style={{ marginRight: 10 }} />
                      Criando usuário...
                    </div>
                  )}
                </button>
              </div>

              <div style={{ marginTop: 10 }}>
              <div style={{ color: "red" }}>
                <p>{errorSave}</p>
                <p>{wrongPassword &&   "Senha não segue os padrões."}</p>
                <p> {passwordEqualError &&  "Confirme sua senha corretamente."}</p>

                <p> {errorProfile &&   "Para continuar Associal algum perfil ao usuário."}</p>

            </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
}
