import React, { useState } from "react";
import Filtro from "./Filtro";
import Table from "./Table";
import ReportAcordeon from "../../../Components/ReportAcordeon";
import { getReportNonAccess } from "../../../../../services/NexiDigitalAPI";

const Commented = ({ index, expanded }) => {
  const name = "Conteúdos sem acesso";
  const description = "Exibe os conteúdo sem acessos em um período de tempo.";

  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);

  const actionFilter = async (values) => {
    setLoading(true);
    // Sessions
    const responseData = await getReportNonAccess(values);
    setDados(responseData);

    setLoading(false);
  };

  const filter = <Filtro actionFilter={actionFilter} loading={loading} />;

  const result = <Table dados={dados} loading={loading} />;

  return (
    <ReportAcordeon
      name={name}
      description={description}
      filter={filter}
      result={result}
      index={index}
      expanded={expanded}
    />
  );
};

export default Commented;
