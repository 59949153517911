import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { BiBookContent } from "react-icons/bi";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { TableContainer } from "@material-ui/core";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { getEvaluationsToImportReaction } from "../../../services/NexiDigitalAPI";
import SemImagem from "../../../assets/images/sem-imagem.jpg";
import moment from "moment";
import Loading from "../../../components/Loading";
import { RiPagesFill } from "react-icons/ri";
import { LiaClipboardCheckSolid } from "react-icons/lia";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import ContentPageTable from "./ContentPageTable";
import UniqueContentPageTable from "./UniqueContentPageTable";
import { modelEvaluation } from "../../../utils/Utils";

function ImportContentModal({
  title,
  denyAction = false,
  returnNodeData,
  blockData,
  returnContentData,
  nodeData,
}) {
  const nodeType = nodeData?.nodeType;
  const [showModal, setShowModal] = useState(true);
  const [modalPath, setModalPath] = useState(1);
  const [contentData, setContentData] = useState([]);
  const [loadingContent, setLoadingContent] = useState(false);

  const getEvaluations = (modelEvaluationType) => {
    const params = { evaluationType: modelEvaluationType };
    setLoadingContent(true);
    getEvaluationsToImportReaction(blockData?.idNode, blockData?.idBlock, params)
      .then((res) => {
        setContentData(res.data);
      })
      .catch((err) => {
        console.log("error getting content", err.request);
      })
      .finally(() => {
        setLoadingContent(false);
      });
  };

  function handleDeny() {
    if (denyAction) {
      denyAction();
      return;
    }
    setShowModal(false);
  }

  const handleEvaluationImport = (contentData) => {
    returnContentData(contentData, "EVALUATION");
    setModalPath(1);
    setShowModal(false);
  };

  const handleContentImport = (contentData) => {
    returnContentData(contentData, "CONTENT");
    setModalPath(1);
    setShowModal(false);
  };

  const handleNodeImport = (nodeData, state) => {
    // console.log()
    returnNodeData({ ...nodeData, idNode: nodeData.idNode, state });
    setModalPath(1);
    setShowModal(false);
  };

  const loadPathData = () => {
    switch (modalPath) {
      case 2:
        // getNodes();
        break;
      case 3:
        // getContents();
        break;
      case 4:
        getEvaluations(modelEvaluation.REACTION);
        break;
      case 5:
        getEvaluations(modelEvaluation.KNOWLEDGE);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    loadPathData();
  }, [modalPath]);

  return (
    <Modal
      show={showModal}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="xl"
      onHide={() => {}}
    >
      <div className="modal-support-blur modal-support-blur-warning" style={{ borderRadius: 10 }} />
      <div
        onClick={() => handleDeny()}
        className="modal-warning-close-container"
        style={{ margin: 15, padding: 0, backgroundColor: "#6b48ff" }}
      >
        <CloseIcon style={{ color: "white" }} className="modal-warning-close" />
      </div>
      <div className="modal-principal-warning" style={{ minHeight: 400, padding: "40px 20px" }}>
        <Modal.Title style={{ textAlign: "center", fontSize: 18, marginTop: 15 }}>
          {title}
        </Modal.Title>
        <div
          className="modal-body-warning"
          style={{ padding: 20, display: "flex", justifyContent: "center" }}
        >
          <div style={{ width: "100%" }}>
            {modalPath === 1 && (
              <div>
                <div
                  style={{
                    fontSize: 28,
                    marginBottom: 30,
                    textAlign: "center",
                  }}
                >
                  Escolha o tipo de conteúdo que deseja importar
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: nodeType === "ORGANIZATION" ? "space-evenly" : "center",
                    gap: 10,
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    className="node-manager-import-content-card import-card-yellow"
                    onClick={() => setModalPath(2)}
                  >
                    <RiPagesFill style={{ fontSize: 50 }} />
                    <span>Página de conteúdo</span>
                  </div>
                  <div
                    className="node-manager-import-content-card import-card-purple"
                    onClick={() => setModalPath(3)}
                  >
                    <BiBookContent style={{ fontSize: 50 }} />
                    <span>Conteúdo único</span>
                  </div>
                  {nodeType !== "ORGANIZATION" && (
                    <div
                      className="node-manager-import-content-card import-card-blue"
                      onClick={() => setModalPath(4)}
                    >
                      <EmojiEmotionsIcon style={{ fontSize: 50 }} />
                      <span>Avaliação de reação</span>
                    </div>
                  )}
                  {nodeType !== "ORGANIZATION" && (
                    <div
                      className="node-manager-import-content-card import-card-orange"
                      onClick={() => setModalPath(5)}
                    >
                      <LiaClipboardCheckSolid style={{ fontSize: 50 }} />
                      <span>Avaliação de conhecimento</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {modalPath !== 1 && (
              <div
                onClick={() => setModalPath(1)}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: 5,
                  left: 5,
                }}
              >
                <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
                Voltar
              </div>
            )}
            {modalPath === 2 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ContentPageTable blockData={blockData} handleNodeImport={handleNodeImport} />
              </div>
            )}
            {modalPath === 3 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <UniqueContentPageTable
                  nodeType={nodeType}
                  blockData={blockData}
                  handleContentImport={handleContentImport}
                />
              </div>
            )}
            {(modalPath === 4 || modalPath === 5) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  maxHeight: "692px",
                }}
              >
                {loadingContent ? (
                  <Loading>Carregando Conteúdos...</Loading>
                ) : contentData?.length > 0 ? (
                  <TableContainer>
                    <Table>
                      <Thead style={{ backgroundColor: "#6b48ff" }}>
                        <Tr>
                          <Th align="center" style={{ color: "#FFF", width: "30%" }}>
                            Imagem
                          </Th>
                          <Th align="center" style={{ color: "#FFF", width: "30%" }}>
                            Título
                          </Th>
                          <Th align="center" style={{ color: "#FFF", width: "22%" }}>
                            Data da última alteração
                          </Th>
                          <Th align="center" style={{ color: "#FFF", width: "18%" }}>
                            Ação
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {contentData &&
                          contentData.map((row, index) => (
                            <Tr key={index}>
                              <Td align="center" style={{ minWidth: "30%", textAlign: "center" }}>
                                <div
                                  className="image-card"
                                  style={{
                                    backgroundImage: `url( ${row.image || SemImagem} )`,
                                    height: 130,
                                    width: 150,
                                    color: "#fff",
                                    display: "inline-grid",
                                    backgroundColor: "rgba(105, 72, 255, 0.3)",
                                  }}
                                />
                              </Td>
                              <Td align="center" style={{ minWidth: "30%" }}>
                                {row.title || <span style={{ color: "gray" }}>Sem titulo</span>}
                              </Td>
                              <Td align="center" style={{ minWidth: "20%" }}>
                                {moment(row.lastUpdated).format("DD/MM/YYYY")}
                              </Td>
                              <Td align="center">
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleEvaluationImport(row);
                                  }}
                                  className="btn content-page-button"
                                  style={{
                                    backgroundColor: "#feac0e",
                                    margin: "0 auto",
                                  }}
                                >
                                  Selecionar
                                </button>
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div style={{ marginTop: 40 }}>Nenhum dado disponível</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ImportContentModal;
