import {
  getGroupsInTeams,
  postAddGroupsInTeams,
  deleteGroupsInTeams,
  getGroupsInTeamsEnrollInfo,
  getGroupsSecondary,
  putActivateAutoEnroll,
} from "../../../../../../services/NexiDigitalAPI";

export function getGroupsAutoRegistrationAPIPromises(idTeam, call) {
  // Defining our first promise
  let firstPromise = () => {
    return new Promise((resolve, reject) => {
      resolve(getGroupsSecondary());
      //TODO: Retornar com a chamada da api NexiDigitalAPISecondary
    });
  };

  // Defining our second promise
  let secondPromise = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(getGroupsInTeams(idTeam)), 500);
    });
  };

  // Async function to perform execution of all promise
  (async () => {
    try {
      let promise = await Promise.all([firstPromise(), secondPromise()]);
      call(promise);
    } catch (e) {
      console.log(
        "Error no GET da lista completa de grupos e lista dos grupos selecionados para essa turma",
        e,
      );
    }
  })();
}

export function postAddGroupPromises(idTeam, data, call) {
  // Defining our first promise
  let firstPromise = () => {
    return new Promise((resolve, reject) => {
      resolve(postAddGroupsInTeams(idTeam, data));
    });
  };

  // Async function to perform execution of all promise
  (async () => {
    try {
      let promise = await Promise.all([firstPromise()]);
      call(promise);
    } catch (e) {
      console.log("Error no POST no Add Group: " + idTeam, e);
    }
  })();
}

export function deleteGroupsInTeamsPromises(idTeam, idGroups, call) {
  // Defining our first promise
  let firstPromise = () => {
    return new Promise((resolve, reject) => {
      resolve(deleteGroupsInTeams(idTeam, idGroups));
    });
  };

  // Async function to perform execution of all promise
  (async () => {
    try {
      let promise = await Promise.all([firstPromise()]);
      call(promise);
    } catch (e) {
      console.log("Error no DELETE do Groups in Teams: " + idGroups, e);
    }
  })();
}

export function getGroupsInTeamsEnrollInfoPromises(idTeam, call) {
  // Defining our first promise
  let firstPromise = () => {
    return new Promise((resolve, reject) => {
      resolve(getGroupsInTeamsEnrollInfo(idTeam));
    });
  };

  // Async function to perform execution of all promise
  (async () => {
    try {
      let promise = await Promise.all([firstPromise()]);
      call(promise);
    } catch (e) {
      console.log("Error no GET no getGroupsInTeamsEnrollInfoPromises", e);
    }
  })();
}

export function putActivateAutoEnrollPromises(idTeam, status, call) {
  // Defining our first promise
  let firstPromise = () => {
    return new Promise((resolve, reject) => {
      resolve(putActivateAutoEnroll(idTeam, status));
    });
  };

  (async () => {
    try {
      let promise = await Promise.all([firstPromise()]);
      call(promise);
    } catch (e) {
      console.log("Error no PUT no putActivateAutoEnroll -> activate - deactivate", e);
    }
  })();
}
