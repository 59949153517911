import React, { useEffect } from "react";
import { HashRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";

import Routes from "./Routes";
import "./App.css";
import { useClearCacheCtx } from "react-clear-cache";
import { DragDropContext } from "react-beautiful-dnd";
import NiceModal from "@ebay/nice-modal-react";
import { _renewlogin, isAuthenticated } from "./services/NexiDigitalAPI";

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, [isLatestVersion, emptyCacheStorage]);

  useEffect(() => {
    const [navigationEntry] = performance.getEntriesByType("navigation");

    if (navigationEntry && isAuthenticated()) {
      _renewlogin();
    }
  }, []);

  return (
    <DragDropContext>
      <HashRouter basename="/">
        <div className="App" translate="no">
          <AuthProvider>
            <NiceModal.Provider>
              <Routes />
            </NiceModal.Provider>
          </AuthProvider>
        </div>
      </HashRouter>
    </DragDropContext>
  );
}

export default App;
