import "./style.scss";

import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { ContextEvaluationImpact } from "./context/ContextEvaluationImpact";
import PreloaderBlur from "../PreloaderBlur";
import EvaluationImpact from "./components/EvaluationImpact";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { getQuestionnaire } from "services/NexiDigitalAPI";

type evaluationsType = {
  idQuestionnaire?: string;
  idManager?: string;
};

const Evaluations = (props) => {
  const { updateConfigHeaderFooter } = props;
  const { idQuestionnaire, idManager } = useParams<evaluationsType>();
  const [data, setData] = useState(null);

  useEffect(() => {
    const ObjParams = {
        idQuestionnaire: idQuestionnaire, 
        idManager: idManager
    };

    // const ObjParams = {
    //     idQuestionnaire: 574,
    //     idManager: 123
    // };


    getQuestionnaire(ObjParams)
        .then((response) => {
            setData(response.data);
        })
        .catch((error) => {});

  }, []);

  
  if (!data) return <PreloaderBlur />;


  const fetchData: any = data;
  const { answers, evaluation, finishDate } = fetchData;
  const headerImpact = () => {
      return (<div className="header-title-wrapper marginNone">
        <div className="header-title">
          <button
            className="btnGoBack"
            onClick={() => {
              props.history.goBack();
            }}
          >
            <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
          </button>
          Questionário de Avaliação de Impacto
        </div>
      </div>);
  }


  const currentEvaluationType = () => {
    switch (evaluation.evaluationType) {
      case "IMPACT":
        // AVALIAÇÃO DE REAÇÃO.
        return (
          
          <ContextEvaluationImpact>
            {headerImpact()}
            <div className={`pctPage pctEvaluation`}>
                <EvaluationImpact
                  fetchData={fetchData}
                  idQuestionnaire={idQuestionnaire}
                  idManager={idManager}
                  finishEvent={()=>{}}
                />
            </div>
          </ContextEvaluationImpact>
        );

        break;

      default:
        return <p>TIPO DE AVALIAÇÃO NÃO ENCONTRADA.</p>;

        break;
    }
  };

  return currentEvaluationType();
};


export default compose(
  withRouter,
)(Evaluations);
