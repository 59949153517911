import React, { useState, useEffect } from "react";
import { Menu, Dropdown } from "antd";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";

const MenuChart = ({ downloadPdf, data, filename, downloadImg }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth <= 600);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key={1}>
        <div onClick={(e) => downloadPdf(e)}>Exportar PDF</div>
      </Menu.Item>
      <Menu.Item key={3}>
        <CSVLink data={data} filename={filename}>
          Exportar CSV
        </CSVLink>
      </Menu.Item>
      <Menu.Item key={2}>
        <div
          onClick={(e) => {
            downloadImg(e);
          }}
        >
          Exportar IMAGEM
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={[isMobile ? "click" : "hover"]} placement="bottomRight" arrow>
      <DownloadOutlined style={{ color: "white", fontSize: 20 }} />
    </Dropdown>
  );
};

export default MenuChart;
