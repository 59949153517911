import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import ShowImageUploading from "../../../../../components/ShowImageUploading";
import { uploadImageApi, getProductList } from "../../../../../services/NexiDigitalAPI";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import { DatePickerInput } from "rc-datepicker";
import moment from "moment";
import Select from "react-select";

const Modal = ({ show, hideModal, info, submitEdit, submitCreate }) => {
  const initialInfo = {
    idProduct: "",
    title: "",
    imageURL: "",
    startDate: "",
    endDate: "",
    price: "",
    idApp: "",
  };
  const initialSelected = {
    value: 0,
    label: "",
  };

  const [value, setValue] = useState(initialInfo);
  const [selected, setSelected] = useState(initialSelected);
  const [products, setProducts] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageStatus, setImageStatus] = useState(0);
  const imageRef = useRef();

  const submitForm = () => {
    if (Object.keys(initialInfo).length == Object.keys(value).length) {
      submitCreate(value);
    } else {
      const treatedInfo = {
        idProduct: value.idProduct,
        title: value.title,
        imageURL: value.imageURL,
        startDate: moment(value.startDate).format("YYYY-MM-DD"),
        endDate: moment(value.endDate).format("YYYY-MM-DD"),
        price: value.price,
        idApp: value.idApp,
      };
      submitEdit(treatedInfo, value.idOffer);
    }
  };

  const getProducts = async () => {
    const productList = await getProductList();
    setProducts(productList);
    const treatedOptions = productList.map((item) => {
      return { value: item.idProduct, label: item.title };
    });
    setOptions(treatedOptions);
  };

  const changeHandler = (event) => {
    const chave = event.target.getAttribute("name");
    const targetValue = event.target.value;

    setValue({
      ...value,
      [chave]: targetValue,
    });
  };
  const opening = async () => {
    if (show) {
      setImageStatus(0);
      const filterSelected = await findProduct(info.idProduct);
      const hasFiltered = Boolean(filterSelected.length);
      if (hasFiltered) {
        const treated = {
          value: filterSelected[0].idProduct,
          label: filterSelected[0].title,
        };
        setSelected(treated);
        setValue({
          ...info,
          idApp: filterSelected[0].idApp,
        });
      } else {
        setValue(info);
      }
    } else {
      setValue(initialInfo);
      setSelected(initialSelected);
    }
  };

  useEffect(() => {
    opening();
  }, [show]);

  useEffect(() => {
    getProducts();
  }, []);

  const imageChangeHandler = async (event) => {
    setLoading(true);
    setImageStatus(0);
    let arquivo = event.target.files[0];
    const filename = "thumb_criar_Produto-" + Date.now();
    const data = new FormData();
    if (arquivo) {
      data.append("fileName", filename);
      data.append("fileData", arquivo);
      //data.append("profileImage", newfile, newfile.name);

      await uploadImageApi(data)
        .then((response) => {
          if (response.data.error) {
            alert("Erro ao fazer upload do arquivo");
          } else {
            setValue({
              ...value,
              imageURL: response.data.location,
            });
            setImageStatus(1);
          }
        })
        .catch((error) => {
          // If another error
          setImageStatus(2);
        });
    }
    setLoading(false);
  };

  function changeDateStart(jsDate) {
    setValue({
      ...value,
      startDate: moment(jsDate).format("YYYY-MM-DD"),
    });
  }
  function changeDateFinish(jsDate) {
    setValue({
      ...value,
      endDate: moment(jsDate).format("YYYY-MM-DD"),
    });
  }
  const findProduct = (idProduct) => {
    return products.filter((obj) => obj.idProduct === idProduct);
  };
  const changeProduct = async (product) => {
    setSelected(product);
    const productInfo = await findProduct(product.value);
    const hasProductInfo = Boolean(productInfo.length);
    if (hasProductInfo) {
      setValue({
        ...value,
        idProduct: product.value,
        title: productInfo[0].title,
        imageURL: productInfo[0].imageURL,
        idApp: productInfo[0].idApp,
      });
    }
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      marginBottom: "10px",
      borderRadius: "0 0 1px 1px",
      border: "1px solid #ced4da",
    }),
  };

  const cancelModal = () => {
    setValue(initialInfo);
    setSelected(initialSelected);
    hideModal();
  };
  const imageMessageOption = [
    <span></span>,
    <span>Upload realizado com sucesso</span>,
    <span>Falha ao realizar o upload</span>,
  ];
  const imageMessage = imageMessageOption[imageStatus];

  const uploadImage = () => imageRef.current.click();
  const colorStyle = { backgroundColor: localStorage.getItem("secondaryColor") };

  const modalHtml = (
    <div className={styles.modalWrapper}>
      <div className={styles.modalTitle} style={colorStyle}>
        Cadastrar nova oferta
      </div>
      <div className={styles.modalFormWrapper}>
        <form>
          <label htmlFor="cadastroProdutoModalProduto" className={styles.modalFormLabel}>
            Produto
          </label>
          <Select
            options={options}
            id="cadastroProdutoModalProduto"
            value={selected}
            onChange={changeProduct}
            name="idProduct"
            styles={customStyles}
          />
          <label htmlFor="cadastroProdutoModalTitulo" className={styles.modalFormLabel}>
            Título
          </label>
          <input
            id="cadastroProdutoModalTitulo"
            onChange={changeHandler}
            value={value.title}
            className={styles.modalFormInput}
            name="title"
          />
          <label htmlFor="cadastroProdutoModalDataStartDate" className={styles.modalFormLabel}>
            Data Início
          </label>
          <DatePickerInput
            name="startDate"
            locale="pt-br"
            onChange={changeDateStart}
            value={value.startDate}
            className={styles.modalFormInputData}
          />
          <label htmlFor="cadastroProdutoEndDate" className={styles.modalFormLabel}>
            Data final
          </label>
          <DatePickerInput
            name="endDate"
            locale="pt-br"
            onChange={changeDateFinish}
            value={value.endDate}
            className={styles.modalFormInputData}
          />
          <label htmlFor="cadastroProdutoPrice" className={styles.modalFormLabel}>
            Preço
          </label>
          <input
            id="cadastroProdutoPrice"
            onChange={changeHandler}
            value={value.price}
            className={styles.modalFormInput}
            name="price"
          />
          <input
            id="cadastroProdutoApp"
            onChange={changeHandler}
            value={value.app}
            className={styles.modalFormInput}
            name="app"
            type="hidden"
          />
          <label className={styles.modalFormLabel}>Imagem</label>
          <div className={styles.modalInputImageWrapper}>
            <input
              type="file"
              id="cadastroProdutoModalImagem"
              className={styles.modalFormInputFile}
              name="imageURL"
              ref={imageRef}
              onChange={imageChangeHandler}
              accept="image/png, image/jpeg, image/gif"
            />
            <div className={styles.imageModalDiv}>
              <ShowImageUploading
                loading={loading}
                height="100px"
                width="100px"
                image={value.imageURL}
              />
              {imageMessage}
            </div>
            <button
              onClick={uploadImage}
              type="button"
              className="btn btn-primary"
              style={colorStyle}
            >
              <PublishOutlinedIcon />
            </button>
          </div>
        </form>
      </div>
      <div className={styles.modalButtonWrapper}>
        <button onClick={submitForm} className="btn btn-primary" style={colorStyle}>
          Salvar
        </button>

        <button onClick={cancelModal} className="btn btn-primary" style={colorStyle}>
          Cancelar
        </button>
      </div>
    </div>
  );

  return show && modalHtml;
};

export default Modal;
