import { Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";

const NewTermButton = () => {
  const history  = useHistory();
  const location = useLocation();

  const handleNewTermClick = () => {
    const newPath = `${location.pathname}cadastrar-termo`;
    history.push(newPath);
  };

  return (
    <Button className="buttonSearch color" variant="primary" onClick={handleNewTermClick}>
      <span>Cadastrar novo termo</span>
    </Button>
  )
}

export default NewTermButton
