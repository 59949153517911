import React from "react";
const IcoBiArrow = () =>
  `<svg xmlns="http://www.w3.org/2000/svg" width="16.788" height="12.724" viewBox="0 0 16.788 12.724">
  <g id="Grupo_4793" data-name="Grupo 4793" transform="translate(-1.167 -5.727)">
    <path id="Caminho_3679" data-name="Caminho 3679" d="M7.959,7.592l-4.57,5.367,4.57,5.415" transform="translate(-1.572 -0.839)" fill="none" stroke="#d9d5d5" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Caminho_3680" data-name="Caminho 3680" d="M17,8l4.623,5.446L17,18.892" transform="translate(-4.318 -1.357)" fill="none" stroke="#d9d5d5" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Caminho_3681" data-name="Caminho 3681" d="M3,12H14.174" transform="translate(0.991 -2.349)" fill="none" stroke="#d9d5d5" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Caminho_3682" data-name="Caminho 3682" d="M3,12H14.174" transform="translate(0.991 2.423)" fill="none" stroke="#d9d5d5" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
  </g>
</svg>

`;

export default IcoBiArrow
