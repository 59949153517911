import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useContext, useEffect, useState } from "react";
import {
  getContactAndSupportEmailTemplate,
  toggleContactAndSupportEmailTemplate,
  updateContactAndSupportEmailTemplate,
} from "services/NexiDigitalAPI";
import { AuthContext } from "../../../context/AuthContext";
import ToggleSwitch from "../../Profiles/Components/ToggleSwitch";
import ContactAndSupportModal from "./contactAndSupportModal";
import "./styles.scss";

const SideMenuManager = () => {
  const { loginData } = useContext(AuthContext);
  const primaryColor = loginData.primaryColor;
  const secondaryColor = loginData.secondaryColor;

  const [pageReady, setPageReady] = useState(false);
  const [contactUsEnabled, setContactUsEnabled] = useState(false);
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const [contactUsEmailTemplate, setContactUsEmailTemplate] = useState({});

  const setup = () => {
    getContactAndSupportEmailTemplate().then(({ data }) => {
      if (data) {
        setContactUsEmailTemplate(data);
        if (data.active) {
          setContactUsEnabled(true);
        }

        setPageReady(true);
      }
    });
  };

  const toggleContactAndSupport = async () => {
    if (!contactUsEmailTemplate.idApp) {
      await updateContactAndSupportEmailTemplate({
        ...contactUsEmailTemplate,
        active: !contactUsEmailTemplate.active,
      });
      const { data } = await getContactAndSupportEmailTemplate();
      setContactUsEmailTemplate(data);
    } else {
      setContactUsEmailTemplate({
        ...contactUsEmailTemplate,
        active: !contactUsEmailTemplate.active,
      });
      toggleContactAndSupportEmailTemplate(contactUsEmailTemplate.idEmailTemplate);
    }
  };

  useEffect(setup, []);

  if (!pageReady) {
    return null;
  }

  return (
    <>
      <div className="side-menu-manager">
        <div className="header-title-wrapper">
          <div className="header-title">Configurações {">"} Menu Lateral</div>
        </div>
        <div className="card">
          <div
            className="card-header template-card-header"
            style={{ color: "#000", backgroundColor: secondaryColor }}
          >
            Configurar e personalizar menu lateral
          </div>
          <div className="card-body template-card-body">
            <p>
              Nesta área, você pode <strong>configurar e editar o menu laterar dinâmico</strong> de
              acordo com suas preferências.
            </p>
            <div className="config-container">
              <div className="config-item">
                <div className="item-info">
                  <MailOutlineIcon
                    fontSize="large"
                    className="icon"
                    style={{ color: secondaryColor }}
                  />
                  <div>
                    <p>
                      <strong>Contato e suporte</strong>
                    </p>
                    <p className="text-muted">
                      Personalizar e configurar a página de contato e suporte de acordo com as suas
                      preferências.
                    </p>
                  </div>
                </div>
                <div className="item-control">
                  <ToggleSwitch
                    onChange={(e) => {
                      setContactUsEnabled(!contactUsEnabled);
                      toggleContactAndSupport();
                    }}
                    checked={contactUsEnabled}
                    color={primaryColor}
                    disabled={loginData.isAdmin !== 2 && !contactUsEmailTemplate.idCompany}
                  />
                </div>
                <div className="item-edit">
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => setShowContactUsModal(true)}
                  >
                    Editar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactAndSupportModal
        show={showContactUsModal}
        hide={() => {
          setShowContactUsModal(false);
          setup();
        }}
        emailTemplate={contactUsEmailTemplate}
      />
    </>
  );
};

export default SideMenuManager;
