import "./style.modules.scss";

import React, { useState, useContext } from "react";
import { withRouter, useParams } from "react-router-dom";
import { Form, Container } from "react-bootstrap";

// import { Form, InputGroup, OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import CEMenu from "./Components/CEMenu";
import CECanvas from "./Components/CECanvas";
import CEModalDialog from "./Components/CEModalDialog";

import CEContext from "./Contexts/CEContext";
import { CEContainerContext } from "./Contexts/CEContext";

const CertificatesEdit = (props) => {
  const { idCertificate } = useParams();
  const [outScreen, setOutScreen] = useState(false);

  return (
    <>
      <Container className="certificatesEdit">
        <CEContext>
          <CEContainerContext>
            <div className={`certificatesEdit-base`}>
              <div className="CEConstrol">
                <div className="CEBoxL">
                  <div className="CETitle">
                    <button
                      className="btnGoBack"
                      onClick={() => {
                        setOutScreen(true);
                      }}
                    >
                      <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
                    </button>
                    Certificado
                  </div>
                  <CECanvas />
                </div>
                <CEMenu />
              </div>
            </div>

            <CEModalDialog
              show={outScreen}
              setShow={setOutScreen}
              text={`Tem certeza de que deseja sair da <b>${
                idCertificate ? "Editação" : "Criação"
              } do Certificado</b>?`}
              btnCall={{
                variantBtn: "danger",
                text: "Confirmar",
                callEvent: () => {
                  props.history.push("/certificates-manager/");
                },
              }}
            />
          </CEContainerContext>
        </CEContext>
      </Container>
    </>
  );
};

export default withRouter(CertificatesEdit);
