import React, { memo, useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { getMenuRoutes, transformText } from "../utils/Utils";
import GestaoConteudo from "./cms/GestaoConteudo";
import GestaoAgenda from "./Calendario";
import UploadList from "./cms/UploadList";
import Biblioteca from "./Biblioteca";
import Gamification from "./Gamification";
import EvaluationManagement from "./EvaluationManagement";
import CompanyManager from "./CompanyManager/CompanyManager";
import Reports from "./Reports";
import TelaConfiguracao from "./configuracao/Configuracao";
import User from "./User";
import Loja from "./Loja";
import QrcodeManager from "./../containers/QrcodeManager";
import Profiles from "./../containers/Profiles";
import MenuManagement from "./MenuManagement";
import MoveUsersCompanies from "./MoveUsersCompanies";

import LearningManagementNew from "./../containers/PeopleCraft/LearningManagementNew";
import ProductsManagement from "./PeopleCraft/ProductsManager";
import TrainingExhibition from "./PeopleCraft/TrainingExhibition";
import CertificatesManager from "./PeopleCraft/CertificatesManager";
import MyTeam from "./PeopleCraft/MyTeam";

import ExternalCourseManager from "./PeopleCraft/ExternalCourseManager";

function MenuSelector(props) {
  const _menuItem = useMemo(() => {
    // gets the current path name
    let pathName = props.match.path.replace("/", "");

    // gets menu options stored on cache
    const menuOptionList = getMenuRoutes();

    // gets the menu object according to the path name
    let menuItem = menuOptionList.filter((obj) => {
      return transformText(obj.text) + "/" + obj.sectionTo === pathName;
    })[0];
    return menuItem;
  }, []);

  function noSection() {
    return (
      <div
        style={{
          width: "100%",
          textAlign: "center",
          color: "rgba(255,255,255,0.8)",
          paddingTop: "calc(25vh - 000px - 54px)",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ backgroundColor: "gray", width: 250, borderRadius: 5 }}>
          <span>Seção não disponível</span>
        </div>
      </div>
    );
  }

  function selectTemplate(type) {
    const { idSectionType, sectionTo } = _menuItem;
    switch (idSectionType) {
      case 26:
        return <GestaoConteudo idSection={sectionTo} />;
      case 27:
        return <User idSection={sectionTo} />;
      case 28:
        return <Reports idSection={sectionTo} />;
      case 30:
        return <TelaConfiguracao idSection={sectionTo} />;
      case 35:
        return <UploadList idSection={sectionTo} />;
      case 37:
        return <Biblioteca idSection={sectionTo} />;
      case 39:
        return <CompanyManager idSection={sectionTo} />;
      case 40:
        return <GestaoAgenda idSection={sectionTo} />;
      case 41:
        return <Loja idSection={sectionTo} />;
      case 42:
        return <Gamification idSection={sectionTo} />;
      case 46:
        return <QrcodeManager idSection={sectionTo} />;
      case 47:
        return <Profiles idSection={sectionTo} />;
      case 50:
        return <MenuManagement idSection={sectionTo} />;
      case 62:
        return <LearningManagementNew idSection={sectionTo} />;
      case 63:
        return <TrainingExhibition idSection={sectionTo} />;
      case 64:
        return <ProductsManagement idSection={sectionTo} />;
      case 68:
        return <CertificatesManager idSection={sectionTo} />;
      case 69:
        return <MyTeam idSection={sectionTo} />;
      case 70:
        return <EvaluationManagement idSection={sectionTo} />;
      case 71:
        return <MoveUsersCompanies idSection={sectionTo} />;
      case 73:
        return <ExternalCourseManager idSection={sectionTo} />;
      default:
        return noSection();
    }
  }

  return selectTemplate();
}

export default withRouter(MenuSelector);
