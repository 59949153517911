import React, { useContext } from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import Switch from "react-bootstrap/esm/Switch";

import SemImagemSmall from "../../../../assets/images/sem-imagem-small.jpg";
import {
  toggleBlockMandatory,
  toggleBlockSequential,
  getMenuData,
} from "../../../../services/NexiDigitalAPI";
import { NodeManagerContext } from "../NodeManagerProvider";
import { statusTypes } from "containers/PeopleCraft/ContentPage/StatusTypes";

import { BsShareFill } from "react-icons/bs";

export const ElementCard = ({ element, permissionLearningPage, idBlock }) => {
  const { nodeData, setNodeData } = useContext(NodeManagerContext);
  const { elementType, data } = element;

  const { idCompany } = getMenuData();
  const pageRedirectShare = element.data.redirect === 1 && element.data.idCompany === idCompany;
  const pageShareOrigin = element.data.usedAsRedirect === 1;


  const handleToggleEssential = (value) => {
    const blockIndex = nodeData.blocks.findIndex((item) => item.idBlock === idBlock);
    const elementIndex = nodeData.blocks[blockIndex].elements.findIndex(
      (item) => item.idBlockElement === element.idBlockElement,
    );

    let newNodeData = { ...nodeData };
    newNodeData.blocks[blockIndex].elements[elementIndex].data.mandatory = Boolean(value) ? 1 : 0;
    setNodeData(newNodeData);
    toggleBlockMandatory(element.idBlockElement)
      .then(() => {
        console.log("Success toggleBlockMandatory");
      })
      .catch((err) => {
        console.log("Error toggleBlockSequential", err);
      });
  };

  const handleToggleSequential = (value) => {
    const blockIndex = nodeData.blocks.findIndex((item) => item.idBlock === idBlock);
    const elementIndex = nodeData.blocks[blockIndex].elements.findIndex(
      (item) => item.idBlockElement === element.idBlockElement,
    );
    let newNodeData = { ...nodeData };
    newNodeData.blocks[blockIndex].elements[elementIndex].data.sequential = Boolean(value) ? 1 : 0;
    setNodeData(newNodeData);

    toggleBlockSequential(element.idBlockElement)
      .then(() => {
        console.log("Success toggleBlockSequential");
      })
      .catch((err) => {
        console.log("Error toggleBlockSequential", err);
      });
  };

  const getNameByContentId = (idContentType) => {
    switch (idContentType) {
      case 1:
        return "Audio";
      case 2:
        return "Video";
      case 3:
        return "Post";
      case 4:
        return "Jornada";
      case 5:
        return "Artigo";
      case 6:
        return "Live";
      case 7:
        return "Página";
      case 8:
        return "Meeting";
      case 9:
        return "Video";
      case 18:
        return "Arquivo";
      case 26:
        return "SCORM";
      default:
        return "Conteúdo";
    }
  };
  return (
    <div className="node-manager-content-type-article-wrapper">
      <img
        alt="article"
        className="node-manager-content-type-article-image"
        src={element.data?.image || SemImagemSmall}
      />
      <div className="node-manager-content-type-article-container">
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            marginBottom: 5,
          }}
        >
          {element.data?.title}
        </div>
        {elementType === "CONTENT" ? (
          <div style={{ display: "flex" }}>
            <DescriptionIcon style={{ color: "#6b48ff", fontSize: 22, marginRight: 5 }} />
            <span style={{ color: "#a3a3a3", marginTop: 2 }}>
              {getNameByContentId(element?.data?.idContentType)}
            </span>
          </div>
        ) : elementType === "EVALUATION" ? (
          <div style={{ display: "flex" }}>
            <DescriptionIcon style={{ color: "#6b48ff", fontSize: 22, marginRight: 5 }} />
            <span style={{ color: "#a3a3a3", marginTop: 2 }}>
              Avaliação de {data.evaluationType === "REACTION" ? "Reação" : "Conhecimento"}{" "}
            </span>
          </div>
        ) : (
          <div className="node-manager-type-text-container node-manager-type-text-container2">
            <div>{element?.data?.nodeLevelName ? element?.data?.nodeLevelName : "Imagem"}</div>
            {pageRedirectShare || pageShareOrigin && (
              <div className={"pageShareInfo"}>
                <BsShareFill /> Página compartilhada
              </div>
            )}
          </div>
        )}
      </div>

      {element.elementType != "IMAGE" && (
        <div className="node-manager-switch">
          <Switch
            id={`essential-${String(element?.idBlockElement)}`}
            color="primary"
            checked={Boolean(element?.data?.mandatory)}
            onChange={(e) => handleToggleEssential(e.target.checked)}
            disabled={
              !permissionLearningPage ||
              ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                nodeData?.nodeType !== "ORGANIZATION")
            }
          />

          <label
            className="form-label mr-4"
            htmlFor={`essential-${String(element?.idBlockElement)}`}
            style={{ marginBottom: 0 }}
          >
            Obrigatório
          </label>
          <Switch
            id={`sequential-${String(element?.idBlockElement)}`}
            color="primary"
            checked={Boolean(element?.data?.sequential)}
            onChange={(e) => handleToggleSequential(e.target.checked)}
            disabled={
              !permissionLearningPage ||
              ([statusTypes.ARCHIVED, statusTypes.PUBLISHED].includes(nodeData?.status) &&
                nodeData?.nodeType !== "ORGANIZATION")
            }
          />
          <label
            className="form-label"
            htmlFor={`sequential-${String(element?.idBlockElement)}`}
            style={{ marginBottom: 0 }}
          >
            Sequencial
          </label>
        </div>
      )}
    </div>
  );
};
