import { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { stringify } from "utils/Utils";
import {
  getLearningManagementNodeCode,
  getMenuData,
  removeNodeLearningManagement,
} from "services/NexiDigitalAPI";
import { LMCreateContext } from "../../../Contexts/LMContext";

export const useNodesTree = (setShowModalRemove) => {
  const history = useHistory();
  const [itemSelect, setItemSelect] = useState(null);
  const {
    preload,
    setPreload,
    nodeList,
    setNodeList,
    setNodeCurrent,
    nodeCurrent,
    setStatusDynamic,
    deleteNode,
  } = useContext(LMCreateContext);

  /// Controle Init
  useEffect(() => {
    let componentMounted = true;

    if (nodeList.length === 0 && getMenuData().idCompany) {
      getLMNodeCode(getMenuData().idCompany, componentMounted);
    }

    return () => {
      componentMounted = false;
    };
  }, [nodeList]);


  useEffect(() => {
    if(nodeCurrent && !nodeCurrent.home){

      if(nodeCurrent.nodeCode){
        localStorage.setItem("currentNodeManagement", stringify(nodeCurrent));
      }else{
        let tempNode = nodeCurrent;
        tempNode.nodeCode = tempNode.originalNodeCode;
        setNodeCurrent(tempNode);
      }

    }

  }, [nodeCurrent]);



  /// Controle Init

  const getLMNodeCode = (nodeCode, componentMounted = false) => {
    setPreload(true);

    getLearningManagementNodeCode(nodeCode).then((res) => {
      /// Controle Init
      const savedNode = JSON.parse(localStorage.getItem("currentNodeManagement"));

      if(res.length > 0){

        const savedOrInitialNode = savedNode ? ( (savedNode.nodeCode || savedNode.originalNodeCode)  ? savedNode : res[0])  : res[0];

        if (componentMounted) {
          res[0].home = true;
          res[0].complete = true;
          // res[0].breadcrumb = [res[0]];

          const currentNodeCode = savedOrInitialNode.nodeCode ? savedOrInitialNode.nodeCode : savedOrInitialNode.originalNodeCode;

          savedOrInitialNode.home = currentNodeCode.split('.').length === 1 ? true : false;
          savedOrInitialNode.complete = true;
          // savedOrInitialNode.breadcrumb = [savedOrInitialNode[0]];

          if(currentNodeCode){
            setNodeCurrent(savedOrInitialNode);
          }else{
            setNodeCurrent(res[0]);
          }

        }
        /// Controle Init
        createNewNodes(nodeCode, res[0]);

      }

    });
  };

  const createNewNodes = async(nodeCode, item) => {

    const recurseCreateNodes = (re, currentItem) => {
      return re.map((it) => {

        // it.breadcrumb = it.breadcrumb ? it.breadcrumb : [it];
        it.hasDadEntryPoint = currentItem.entryPoint;

        if(it.childNodes){
          it.childNodes.map((elem) => {
            // elem.breadcrumb = elem.breadcrumb ? elem.breadcrumb : [...it.breadcrumb, elem];
            elem.hasDadEntryPoint = it.entryPoint;
            return elem;

          });
        }

        if (it.hasChildNodes === 0) {
          it.complete = true;
        }

        if (nodeCode === it.nodeCode  || nodeCode === it.originalNodeCode) {
            it.childNodes = item.childNodes;
            recurseCreateNodes(it.childNodes, it);
        } else {
          if (it.childNodes && it.childNodes.length > 0) {
            recurseCreateNodes(it.childNodes, it);
          }
        }

        return it;
      });
    };

    const recurseNodes = recurseCreateNodes(nodeList.length > 0 ? nodeList : [item], item);
    setNodeList(recurseNodes);
    setPreload(false);
  };


  const nodeSelectItem = (item = null, updateAuthor = true) => {
    setStatusDynamic("EDIT_NODE");

    if (item) {

      if(updateAuthor){
        if(item.nodeCode || item.originalNodeCode){
          setNodeCurrent(item);
        }else{
          setNodeCurrent(nodeCurrent);
        }
      }


      if (!item.complete) {
        item.complete = true;
        getLMNodeCode(item.nodeCode ? item.nodeCode : item.originalNodeCode);
      }
    } else {
      if(nodeList[0].nodeCode || nodeList[0].originalNodeCode){
        setNodeCurrent(nodeList[0]);
      }else{
        setNodeCurrent(nodeCurrent);
      }

    }
  };


  const viewModalDelete = (item) => {
    setItemSelect(item);
    setShowModalRemove(true);
  };

  const viewTeam = (item) => {
    // console.log(item);
    history.push(`/class-list/${item.nodeCode ? item.nodeCode : item.originalNodeCode}/${item.title}`);
  };

  const deleteItem = () => {
    deleteNode(itemSelect.nodeCode ? itemSelect.nodeCode : itemSelect.originalNodeCode);
    removeNodeLearningManagement(itemSelect.idNode);
  };

  return { nodeSelectItem, nodeList, preload, nodeCurrent, viewModalDelete, deleteItem, viewTeam, createNewNodes };
};
