import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import _ from "underscore";
import "./style.modules.scss";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { isBefore, parseISO } from "date-fns";
import { format } from "date-fns-tz";
import differenceInDays from "date-fns/differenceInDays";
import { ptBR } from "date-fns/locale";
import { Button, Form, InputGroup, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { AiFillCalendar } from "react-icons/ai";
import { BsLayers } from "react-icons/bs";
import { FiLayers } from "react-icons/fi";
import { MdAutoAwesomeMotion, MdCancel, MdDelete } from "react-icons/md";
import { useHistory, useParams, withRouter } from "react-router-dom";
import InfoIcon from "@material-ui/icons/Info";
import GroupsAutoRegistration from "../../../configuracao/Groups/Components/GroupsAutoRegistration";
import PreloaderBlur from "../../PreloaderBlur";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../../../../components/Loading";
import { BiTrash } from "react-icons/bi";
import {
  createTeams,
  editTeams,
  emptyTeam,
  getBlendedNodes,
  getCertificates,
  getTeams,
  listNotificationSchedule,
  toggleNotification,
  updateNotificationSchedule,
} from "../../../../services/NexiDigitalAPI";
import BlendedNodes from "./BlendedNodes";
import "./style.modules.scss";
import PendingRegistrationRequest from "components/PeopleCraft/PendingRegistrationRequest";
import InteractionDrawer from "components/InteractionDrawer";
import { getMenuRoutes } from "utils/Utils";
import NiceModal from "@ebay/nice-modal-react";
import ModalNotificationConfig from "components/PeopleCraft/Modais/ModalNotificationConfig";
import moment from "moment";

const CreateNewClass = (props) => {
  registerLocale("pt-BR", ptBR);
  const history = useHistory();
  const { nodeCode, title, idTeam } = useParams();
  const [editLoading, setEditLoading] = useState(true);
  const [alertshow, setAlert] = useState(0);
  const [initialDateIsBeforeInfo, setInitialDateIsBefore] = useState(false);
  const [minInitialDate, setMinInitialDate] = useState(false);
  const [autoRegistrationModal, setAutoRegistrationModal] = useState(false);
  const [requestRegistrationModal, setRequestRegistrationModal] = useState(false);
  const modManager = Boolean(
    (
      getMenuRoutes().find((option) => option.idSectionType === 62 && option.enabled)?.resource ||
      []
    ).find((res) => res.idResource === 50),
  );
  const [deleteRegistrationsModal, setDeleteRegistrationsModal] = useState(false);
  const [deleteRegistrationsModalStep, setDeleteRegistrationsModalStep] = useState("CONFIRM");
  const [reuseEenrollmentStatus, setReuseEenrollmentStatus] = useState(false);
  const [contentData, setContentData] = useState({
    name: "",
    description: "Descrição da turma",
    initialDate: new Date(),
    finalDate: null,
    duration: 0,
    durationInDays: 99999999999999,
    capacity: undefined,
    enrollments: 0,
    allowAccessUponCompletion: true,
    allowReenrollment: false,
    allowComments: false,
    selfEnrollment: false,
    autoEnrollProcessGroupsActive: false,
    enableCertificateForChildren: false,
    modality: "",
    status: "",
    needApproval: false,
    requestStartDate: null,
    requestFinishDate: null,
    ignoreEnrolledInAnotherTeam: false,
    managerCanApprove: false,
    limitForPeriod: false,
    commentsLabel: "Interações e mensagens na minha turma",
    shareEnrollmentType: "MOST_RECENT",
    initialClass: true,
    notifications: [],
  });

  const [autoEnrollProcess, setAutoEnrollProcess] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [blendedNodesData, setBlendedNodesData] = useState([]);
  const [loadingBlended, setLoadingBlended] = useState(true);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [disabledInputs, setDisabledInpus] = useState(false);
  const [disabledEnrollments, setDisabledEnrollments] = useState(false);
  const [disabledMeetingsConfiguration, setDisabledMeetingsConfiguration] = useState(false);
  const [hasMandatoryContent, setHasMandatoryContent] = useState(false);
  const editMode = useRef(false);

  useEffect(() => {
    let componentMounted = true; // Uma variável local para rastrear se o componente ainda está montado

    // if (!!idTeam && editLoading) {
    //   getTeams({ idTeam: idTeam }).then((response) => {
    //     if (componentMounted) {
    //       const containerApi = response.data;
    //       editMode.current = response.data.initialDate ? true : false;
    //       filterNode(containerApi);
    //       _getCertificates(response.data.nodeOrigin);
    //     }
    //   });
    // } else {
    //   // _getCertificates();
    //   setEditLoading(false);
    // }

    setEditLoading(false);

    return () => {
      componentMounted = false; // Marque o componente como desmontado ao sair do useEffect
    };
  }, [editLoading]);

  useEffect(() => {
    getTeams({ idTeam: idTeam }).then((response) => {
      const containerApi = response.data;
      editMode.current = response.data.initialDate ? true : false;
      filterNode(containerApi);
      _getCertificates(response.data.nodeOrigin);
    });

    _getBlendedNodes();
  }, []);

  const filterNode = (containerApi) => {
    const initialDate = containerApi.initialDate ? parseISO(containerApi.initialDate) : null;
    const finalDate = containerApi.finalDate ? parseISO(containerApi.finalDate) : null;
    const requestFinishDate = containerApi.requestFinishDate
      ? parseISO(containerApi.requestFinishDate)
      : null;
    const requestStartDate = containerApi.requestStartDate
      ? parseISO(containerApi.requestStartDate)
      : null;
    let dayDif = contentData.durationInDays;
    let duration = containerApi.duration || 0;

    if (finalDate) {
      dayDif = differenceInDays(finalDate, initialDate) + 1;
      // duration = duration >= dayDif ? dayDif : duration;
    }
    setContentData({
      ...contentData,
      name: containerApi.name,
      description: containerApi.description || "",
      initialDate: initialDate,
      finalDate: finalDate,
      duration: duration,
      durationInDays: dayDif,
      capacity: containerApi.capacity || undefined,
      allowAccessUponCompletion: editMode.current ? !!containerApi.allowAccessUponCompletion : true,
      allowComments: editMode.current ? !!containerApi.allowComments : false,
      allowReenrollment: !!containerApi.allowReenrollment,
      selfEnrollment: !!containerApi.selfEnrollment,
      enrollments: containerApi.enrollments || 0,
      idCertificate: containerApi.idCertificate || null,
      modality: containerApi.modality,
      enableCertificateForChildren: !!containerApi.enableCertificateForChildren,
      autoEnrollProcessGroupsActive: !!containerApi.autoEnrollProcessGroupsActive,
      status: containerApi.status,
      needApproval: !!containerApi.needApproval,
      ignoreEnrolledInAnotherTeam: !!containerApi.ignoreEnrolledInAnotherTeam,
      managerCanApprove: !!containerApi.managerCanApprove,
      limitForPeriod: !!containerApi.requestStartDate || !!containerApi.requestEndDate,
      requestFinishDate,
      requestStartDate,
      totalRequestsPending: containerApi.totalRequestsPending,
      commentsLabel: containerApi.commentsLabel || "Interações e mensagens na minha turma",
      shareEnrollmentType:
        containerApi.shareEnrollmentType && containerApi.shareEnrollmentType !== "NONE"
          ? containerApi.shareEnrollmentType
          : "NONE",
      notifications: containerApi.notifications,
      isRetroactive: containerApi.isRetroactive
    });
    setDisabledInpus(containerApi.status === "FINISHED");
    setDisabledEnrollments(containerApi.status === "FINISHED" && !containerApi.isRetroactive);
    setDisabledMeetingsConfiguration(containerApi.status === "FINISHED" && !containerApi.isRetroactive);

    setReuseEenrollmentStatus(
      containerApi.shareEnrollmentType && containerApi.shareEnrollmentType !== "NONE",
    );

    setAutoEnrollProcess(!!containerApi.autoEnrollProcessGroupsActive);

    const initialDateIsBefore = isBefore(parseISO(containerApi.initialDate), new Date());
    setInitialDateIsBefore(initialDateIsBefore);

    const minInitialDate_ = containerApi.isRetroactiveElegible ? moment().subtract(7,'days').toDate() : new Date();  
    setMinInitialDate(minInitialDate_);
    
    setHasMandatoryContent(containerApi.hasMandatoryContent);

    setEditLoading(false);
  };

  const _toggleNotification = async (event, notification) => {
    const { idNotification, type, description, status } = notification;
    if (status === "DEACTIVATED" || (type !== "TEAM_STARTED" && !contentData.finalDate)) {
      return;
    }

    setContentData({
      ...contentData,
      notifications: contentData.notifications.map((notification) => {
        if (notification.idNotification === idNotification) {
          return {
            ...notification,
            active: event.target.checked,
          };
        }

        return notification;
      }),
    });

    toggleNotification(idTeam, idNotification, { active: event.target.checked });

    if (event.target.checked) {
      NiceModal.show(ModalNotificationConfig, {
        type,
        description,
        idNotification,
        idTeam,
      });
    }
  };

  const handleChange = (event) => {
    setContentData({ ...contentData, [event.target.name]: event.target.checked });
  };

  const gotoListRegistration = () => {
    // localStorage.setItem("formTeams", JSON.stringify(contentData));
    props.history.push(`/list-registration/${nodeCode}/${title}/${idTeam}/`);
  };

  const gotoListRegistrationBatch = () => {
    // localStorage.setItem("formTeams", JSON.stringify(contentData));
    props.history.push(`/list-registration-batch/${nodeCode}/${title}/${idTeam}/`);
  };

  const gotoListRegistrationAuto = () => {
    // props.history.push(`/configuracao/grupos/auto/${nodeCode}/${title}/${idTeam}/`);
    setAutoRegistrationModal(true);
  };

  const connfirmEmptyClass = () => {
    setDeleteRegistrationsModalStep("CONFIRM");
    setDeleteRegistrationsModal(true);
  };

  const emptyClass = async () => {
    setDeleteRegistrationsModal(false);
    setEditLoading(true);


    if (idTeam) {
      await emptyTeam({ idTeam });
      setContentData((prev) => ({ ...prev, enrollments: 0 }));
      setDeleteRegistrationsModal(true);
      setDeleteRegistrationsModalStep("SUCCESS");
    }
  };

  const openRequestRegistrationModal = () => setRequestRegistrationModal(true);

  const gotoLM = () => {
    history.goBack();
  };

  const _getBlendedNodes = () => {
    setLoadingBlended(true);
    getBlendedNodes(idTeam)
      .then((res) => {
        setBlendedNodesData(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoadingBlended(false);
      });
  };

  const sendData = () => {
    if (!!contentData.name === false || !!contentData.initialDate === false) {
      if (!!contentData.name === false) setAlert(1);
      else if (!!contentData.initialDate === false) setAlert(2);

      return;
    }

    if (contentData.modality === "BLENDED") {
      if (!contentData.finalDate) {
        setAlert(5);
        return;
      }

      let meetings = _.chain(blendedNodesData).pluck("meetings").flatten().value();

      if (meetings.length > 0) {
        meetings = _.sortBy(meetings, (m) =>
          typeof m.startDate === "string" ? new Date(m.startDate) : m.startDate,
        );

        const firstMeeting =
          typeof meetings[0].startDate === "string"
            ? new Date(meetings[0].startDate)
            : meetings[0].startDate;
        const lastMeeting =
          typeof meetings[meetings.length - 1].startDate === "string"
            ? new Date(meetings[meetings.length - 1].startDate)
            : meetings[meetings.length - 1].startDate;

        lastMeeting.setUTCHours(0, 0, 0, 0);

        if (contentData.initialDate > firstMeeting || contentData.finalDate < lastMeeting) {
          setAlert(6);
          return;
        }
      }
    }
    if (
      contentData.finalDate &&
      contentData.limitForPeriod &&
      contentData.requestFinishDate > contentData.finalDate
    ) {
      setAlert(7);
      return;
    }

    setEditLoading(true);

    const initD = contentData.initialDate ? format(contentData.initialDate, "yyyy-MM-dd") : null;
    const endD = contentData.finalDate ? format(contentData.finalDate, "yyyy-MM-dd") : null;
    const requestStartDate = contentData.requestStartDate
      ? format(contentData.requestStartDate, "yyyy-MM-dd")
      : null;
    const requestFinishDate = contentData.requestFinishDate
      ? format(contentData.requestFinishDate, "yyyy-MM-dd")
      : null;
    let contentDataFilter = {
      ...contentData,
      initialDate: initD,
      finalDate: endD,
      requestStartDate,
      requestFinishDate,
    };

    delete contentDataFilter.durationInDays;

    if (idTeam) {
      (async () => {
        await editTeams({ idTeam: idTeam, data: contentDataFilter })
          .then((response) => {
            setAlert(4);
            setEditLoading(false);
          })
          .catch((err) => {
            setAlert(3);
            setEditLoading(false);
          });
      })(props);
    } else {
      const contentDataNew = {
        ...contentDataFilter,
        nodeCode,
      };

      (async () => {
        await createTeams({ data: contentDataNew })
          .then((response) => {
            setAlert(4);
            setEditLoading(false);
          })
          .catch((err) => {
            setAlert(3);
            setEditLoading(false);
          });
      })(props);
    }
  };

  const onChangeCalendarInit = (date) => {
    const { finalDate, duration } = contentData;
    const dateInit = date;
    const dateEnd = finalDate;
    let isRetroactive = contentData.isRetroactive;

    if (date < moment().startOf('day').toDate()) {
      console.log('opa', contentData.isRetroactive);
      isRetroactive = 1;
    } else {
      isRetroactive = 0;
    }

    if (date > dateEnd) {
      setContentData({
        ...contentData,
        isRetroactive,
        initialDate: date,
        finalDate: null,
        durationInDays: 99999999999,
      });
    } else {
      const day = dateEnd ? differenceInDays(dateEnd, dateInit) + 1 : 99999999999999;
      const _duration = duration < day ? duration : day;
      setContentData({
        ...contentData,
        isRetroactive,
        initialDate: date,
        durationInDays: day,
        duration: _duration,
      });
    }
    console.log(contentData);
  };

  const onChangeCalendarEnd = async (date) => {
    const { initialDate, duration } = contentData;
    const dateInit = initialDate;
    const dateEnd = date;
    const day = differenceInDays(dateEnd, dateInit) + 1;
    const _duration = !day || duration < day ? duration : day;
    setContentData({ ...contentData, finalDate: date, duration: _duration, durationInDays: day });

    await editTeams({
      idTeam: idTeam,
      data: { ...contentData, finalDate: date, duration: _duration, durationInDays: day },
    });
  };

  const durationValid = (value) => {
    const valueDuration = value >= contentData.durationInDays ? contentData.durationInDays : value;
    setContentData({ ...contentData, duration: parseInt(valueDuration) });
  };

  const InputCalendario = forwardRef(({ value, onClick, name }, ref) => (
    <Button
      disabled={(!contentData.initialDate && name === "endDate") || disabledInputs}
      type="button"
      className={`btn btnCalendar
                content-page-button btn-success`}
      onClick={onClick}
      ref={ref}
    >
      <AiFillCalendar />
      {value}
    </Button>
  ));

  const ManagerCanApprove = () => (
    <div className="ml-5" style={{ display: "flex", flexDirection: "column" }}>
      <strong style={{ fontSize: 16 }}>Perfis que podem aprovar a matrícula</strong>
      <div className="mr-3">
        <FormControlLabel
          control={
            <Switch
              disabled={disabledInputs}
              color="primary"
              checked={contentData.managerCanApprove}
              onChange={handleChange}
              name="managerCanApprove"
            />
          }
          label="Gestor imediato"
        />
      </div>
    </div>
  );

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const ControlInteraction = () => {
    return (
      <InteractionDrawer
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        title={contentData.commentsLabel}
      />
    );
  };

  const handleChangeStatus = async (event) => {
    const changedData = {
      ...contentData,
      shareEnrollmentType: reuseEenrollmentStatus ? event.target.value : "NONE",
    };
    setContentData(changedData);
    await editTeams({ idTeam: idTeam, data: changedData });
  };

  const handleReuseEenrollmentStatus = async (event) => {
    setReuseEenrollmentStatus(event.target.checked);
    const changedData = {
      ...contentData,
      shareEnrollmentType: event.target.checked ? "MOST_RECENT" : "NONE",
    };

    setContentData(changedData);

    await editTeams({ idTeam: idTeam, data: changedData });
  };

  const LimitForPeriod = () => (
    <div className="row-control" style={{ minHeight: 55 }}>
      <div className="mr-3">
        <FormControlLabel
          control={
            <Switch
              disabled={disabledInputs}
              color="primary"
              checked={contentData.limitForPeriod}
              onChange={(e) =>
                setContentData((prevState) => ({
                  ...prevState,
                  limitForPeriod: e.target.checked,
                  requestStartDate: null,
                  requestFinishDate: null,
                }))
              }
              name="limitForPeriod"
            />
          }
          label="Limite para período de solicitação"
        />
      </div>
      {contentData.limitForPeriod && (
        <>
          <div className="prop-4">
            <Form.Group>
              <InputGroup className={`mb-2 mr-sm-2`}>
                <InputGroup.Prepend>
                  <InputGroup.Text>Data de início</InputGroup.Text>
                </InputGroup.Prepend>

                <DatePicker
                  name="requestStartDate"
                  minDate={new Date()}
                  disabled={disabledInputs}
                  selected={contentData.requestStartDate}
                  locale="pt-BR"
                  dateFormat="dd-MM-yyyy"
                  onChange={(date) =>
                    setContentData((prevState) => ({
                      ...prevState,
                      requestStartDate: date,
                      requestFinishDate: null,
                    }))
                  }
                  className="form-control"
                />
              </InputGroup>
            </Form.Group>
          </div>
          <div className="prop-4">
            <Form.Group>
              <InputGroup className={`mb-2 mr-sm-2`}>
                <InputGroup.Prepend>
                  <InputGroup.Text>Data Fim</InputGroup.Text>
                </InputGroup.Prepend>

                <DatePicker
                  name="requestFinishDate"
                  minDate={contentData.requestStartDate}
                  disaled={!contentData.requestStartDate || disabledInputs}
                  selected={contentData.requestFinishDate}
                  locale="pt-BR"
                  dateFormat="dd-MM-yyyy"
                  onChange={(date) =>
                    setContentData((prevState) => ({ ...prevState, requestFinishDate: date }))
                  }
                  className="form-control"
                />
              </InputGroup>
            </Form.Group>
          </div>
        </>
      )}
    </div>
  );

  const AutoRegistrationOption = () => (
    <div className="ml-5" style={{ display: "flex", flexDirection: "column" }}>
      <strong style={{ fontSize: 16 }}>Opções de matrícula</strong>
      <div className="mr-3">
        <FormControlLabel
          control={
            <Switch
              disabled={disabledInputs}
              color="primary"
              checked={contentData.needApproval}
              onChange={handleChange}
              name="needApproval"
            />
          }
          label="Com aprovação"
        />
      </div>
      {contentData.needApproval && <ManagerCanApprove />}
      <LimitForPeriod />
      <div className="mr-3">
        <FormControlLabel
          control={
            <Switch
              disabled={disabledInputs}
              color="primary"
              checked={contentData.ignoreEnrolledInAnotherTeam}
              onChange={handleChange}
              name="ignoreEnrolledInAnotherTeam"
            />
          }
          label="Bloquear aluno já matriculado em outra turma, mesmo que esteja aprovado ou reprovado"
        />
      </div>
    </div>
  );

  const _getCertificates = async (originalNodeCode) => {
    const response = await getCertificates(originalNodeCode.split(".")[0]);
    setCertificates(response.data.result || []);
  };

  const handleChangeCertificate = (value) => {
    setContentData({
      ...contentData,
      idCertificate: value !== "0" ? value : null,
    });
  };

  return (
    <>
      {editLoading && <PreloaderBlur />}
      {editMode.current && <ControlInteraction />}
      <div className="header-title-wrapper">
        <div className="header-title">
          <button
            className="btnGoBack"
            onClick={() => {
              history.goBack();
            }}
          >
            <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
          </button>
          Gestão de Turmas - {title && title}
        </div>
      </div>

      <div className="post-form createNewClass">
        <form>
          <div className="content-page-form-group ">
            <div className="content-page-card mb-2">
              <div
                style={{ backgroundColor: "#6b48ff" }}
                className="card-header template-card-header"
              >
                Informações da turma
              </div>

              <div className="">
                <div style={{ display: "flex" }}>
                  <div className="input-group mb-4" style={{ padding: "1.25rem 1.25rem 0" }}>
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text"
                        id="basic-addon1"
                        style={{ minWidth: 100 }}
                      >
                        Título
                      </span>
                    </div>
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="name"
                      aria-describedby="titulo"
                      value={contentData.name}
                      disabled={disabledInputs}
                      onChange={(e) => setContentData({ ...contentData, name: e.target.value })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content-page-form-group">
            <div className="card overflow-visible">
              <div
                style={{ backgroundColor: "#6b48ff" }}
                className="card-header template-card-header"
              >
                Período de realização
              </div>
              <div className="card-body template-card-body">
                <div className="row-control">
                  <div className="prop-4">
                    <Form.Group className="access-table-select">
                      <InputGroup
                        className={`mb-2 mr-sm-2 calendarInit ${
                          initialDateIsBeforeInfo || disabledInputs ? "inativeCalendar" : ""
                        }`}
                      >
                        <InputGroup.Prepend>
                          <InputGroup.Text>Data de início</InputGroup.Text>
                        </InputGroup.Prepend>

                        <DatePicker
                          name="initDate"
                          minDate={minInitialDate}
                          disabled={initialDateIsBeforeInfo || disabledInputs}
                          selected={contentData.initialDate}
                          locale="pt-BR"
                          dateFormat="dd-MM-yyyy"
                          onChange={(date) => onChangeCalendarInit(date)}
                          customInput={<InputCalendario />}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>

                  <div className="prop-4">
                    <Form.Group className="access-table-select">
                      <InputGroup
                        className={`mr-sm-2
                         ${disabledInputs ? " calendarInit inativeCalendar" : ""}
                      `}
                      >
                        <InputGroup.Prepend>
                          <InputGroup.Text>Data fim</InputGroup.Text>
                        </InputGroup.Prepend>

                        <DatePicker
                          name="endDate"
                          disabled={disabledInputs}
                          locale="pt-BR"
                          dateFormat="dd-MM-yyyy"
                          className={"disabled"}
                          isClearable={!disabledInputs}
                          minDate={
                            contentData.initialDate > new Date() || (contentData.isRetroactive && !hasMandatoryContent)
                              ? new Date(contentData.initialDate).setDate(
                                  new Date(contentData.initialDate).getDate() + 1,
                                )
                              : new Date().setDate(new Date().getDate() + 1)
                          }
                          selected={contentData.finalDate}
                          onChange={(date) => onChangeCalendarEnd(date)}
                          customInput={<InputCalendario name="endDate" />}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>

                  {contentData.modality && contentData.modality !== "BLENDED" && (
                    <div className="prop-5">
                      <Form.Group className="access-table-select">
                        <InputGroup className="">
                          <InputGroup.Prepend>
                            <InputGroup.Text>Período de execução</InputGroup.Text>
                          </InputGroup.Prepend>

                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Tooltip
                                style={{ opacity: contentData.finalDate ? 1 : 0 }}
                                id={`tooltip-top`}
                              >
                                {`Período máximo: ${contentData.durationInDays}`}
                              </Tooltip>
                            }
                          >
                            <input
                              type="number"
                              min={0}
                              disabled={!contentData.initialDate || disabledInputs}
                              max={contentData.durationInDays}
                              className="form-control"
                              style={{ width: "100px" }}
                              id="realizationPeriod"
                              aria-describedby="Período de execução"
                              value={contentData.initialDate ? contentData.duration : ""}
                              onChange={(e) => durationValid(e.target.value)}
                            />
                          </OverlayTrigger>
                        </InputGroup>
                      </Form.Group>
                    </div>
                  )}
                </div>
                {contentData.modality === "BLENDED" && (
                  <span>
                    <b>OBS.: </b>Você terá até 10 dias corridos após a data fim da turma para
                    finalizar as listas de presença dos encontros. Após essa data a lista será
                    finalizada automaticamente.
                  </span>
                )}
              </div>
            </div>
          </div>

          {contentData.modality === "BLENDED" && (
            <div className="content-page-form-group">
              <div className="content-page-card">
                <div
                  style={{ backgroundColor: "#6b48ff" }}
                  className="card-header template-card-header"
                >
                  Configurar e editar eventos síncronos
                </div>
                <div className="card-body template-card-body meetsCardBody">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span className="mb-3">
                      Nesta seção, você pode{" "}
                      <b style={{ fontSize: 16 }}>
                        configurar e editar os eventos síncronos nas páginas
                      </b>{" "}
                      de acordo com suas preferências.
                    </span>
                    {loadingBlended ? (
                      <Loading containerStyle={{ height: 200 }}>Carregando nós...</Loading>
                    ) : blendedNodesData.length === 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "20px 0px",
                          color: "lightgray",
                        }}
                      >
                        Nenhum nó encontrado.
                      </div>
                    ) : (
                      <BlendedNodes
                        disabled={disabledMeetingsConfiguration}
                        blendedNodesData={blendedNodesData}
                        teamStatus={contentData.status}
                        teamInitialDate={contentData.initialDate}
                        teamFinalDate={contentData.finalDate}
                        isRetroactiveTeam={contentData.isRetroactive}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {editMode.current && (
            <div className="content-page-form-group">
              <div className="card overflow-visible">
                <div
                  style={{ backgroundColor: "#6b48ff" }}
                  className="card-header template-card-header"
                >
                  Configurações de matrícula
                </div>
                <div className="template-card-body" style={{ paddingTop: 10, paddingLeft: 20 }}>
                  <div className="row-control">
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={reuseEenrollmentStatus}
                          onChange={handleReuseEenrollmentStatus}
                          name="reuseEenrollmentStatus"
                        />
                      }
                      label={
                        <span style={{ fontSize: "14px" }}>
                          Reaproveitar status de matrícula nas páginas importadas. Por padrão, o
                          status considerado é o da matrícula mais recente, mas você pode alterá-lo
                          de acordo com as opções abaixo:
                        </span>
                      }
                    />
                  </div>

                  <div
                    className="row-control status-options"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      opacity: reuseEenrollmentStatus ? 1 : 0.5,
                    }}
                  >
                    <Form>
                      <Form.Check
                        type="radio"
                        label="Considerar o status da matrícula mais recente"
                        value="MOST_RECENT"
                        checked={contentData.shareEnrollmentType === "MOST_RECENT"}
                        disabled={!reuseEenrollmentStatus}
                        onChange={handleChangeStatus}
                        name="statusOption"
                        style={{ marginBottom: "10px" }}
                        className="d-flex align-items-center custom-radio"
                      />
                      <Form.Check
                        type="radio"
                        label="Considerar o status de melhor desempenho"
                        value="HIGHEST_SCORE"
                        checked={contentData.shareEnrollmentType === "HIGHEST_SCORE"}
                        disabled={!reuseEenrollmentStatus}
                        onChange={handleChangeStatus}
                        name="statusOption"
                        className="d-flex align-items-center custom-radio"
                      />
                    </Form>
                  </div>
                  <div
                    variant="light"
                    className="d-flex align-items-center info-status"
                    style={{
                      backgroundColor: reuseEenrollmentStatus ? "#F0EDFD" : "#EDEDED",
                      color: "#000",
                    }}
                  >
                    <div
                      style={{
                        padding: 20,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <InfoIcon
                        style={{
                          color: reuseEenrollmentStatus ? "#6b48ff" : "gray",
                          fontSize: 20,
                          marginRight: 10,
                        }}
                      />
                      <span
                        style={{
                          color: "#3C3C3C",
                          opacity: reuseEenrollmentStatus ? 1 : 0.6,
                        }}
                      >
                        Nas páginas em que houver avaliação, será considerada aquela na qual o
                        <strong>usuário alcançou a maior nota</strong>. Nas páginas sem avaliação,
                        será considerado o <strong>status mais avançado de consumo</strong>.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-body template-card-body">
                  <div className="form-column">
                    <div className="row-control">
                      <div className="prop-4">
                        <Form.Group className="access-table-select">
                          <InputGroup className="mb-2 mr-sm-2">
                            <InputGroup.Prepend>
                              <InputGroup.Text>Quantidade total de vagas</InputGroup.Text>
                            </InputGroup.Prepend>
                            <input
                              type="number"
                              min={1}
                              className="form-control"
                              id="realizationPeriod"
                              aria-describedby="Quantidade"
                              placeholder="-"
                              disabled={disabledInputs}
                              value={contentData.capacity}
                              onChange={(e) =>
                                setContentData({
                                  ...contentData,
                                  capacity: e.target.value || undefined,
                                })
                              }
                            />
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="mr-3">
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={disabledInputs}
                            color="primary"
                            checked={contentData.selfEnrollment}
                            onChange={handleChange}
                            name="selfEnrollment"
                          />
                        }
                        label="Auto-matrícula"
                      />
                    </div>
                    {contentData.selfEnrollment && <AutoRegistrationOption />}
                  </div>
                </div>
              </div>
            </div>
          )}
          {editMode.current && (
            <div className="content-page-form-group">
              <div className="content-page-card">
                <div
                  style={{ backgroundColor: "#6b48ff" }}
                  className="card-header template-card-header"
                >
                  Matrículas
                </div>
                <div className="card-body template-card-body">
                  <div className="row-control">
                    <Button
                      variant="success"
                      onClick={gotoListRegistration}
                      disabled={disabledEnrollments}
                    >
                      <BsLayers /> Matricular
                    </Button>

                    <Button
                      variant="success"
                      onClick={gotoListRegistrationBatch}
                      disabled={disabledEnrollments}
                    >
                      <FiLayers /> Matricular em lote
                    </Button>

                    <Button variant="warning" onClick={gotoListRegistrationAuto}>
                      <MdAutoAwesomeMotion /> Matricular automaticamente
                    </Button>

                    <Button
                      variant="warning"
                      onClick={connfirmEmptyClass}
                      disabled={!contentData?.enrollments}
                    >
                      <MdDelete /> Esvaziar Turma
                    </Button>
                  </div>
                  <div className="row-control textDest">
                    {contentData.enrollments} usuários matriculados
                    {!disabledInputs && (
                      <button
                        onClick={() => {
                          gotoListRegistration();
                        }}
                        className={"btnVerTodos"}
                      >
                        ver todos
                      </button>
                    )}
                  </div>
                  <div className="row-control textDest">
                    {contentData?.totalRequestsPending || 0} usuários na lista de solicitação de
                    matrícula
                    {!disabledInputs && (
                      <button
                        type="button"
                        onClick={openRequestRegistrationModal}
                        className={"btnVerTodos"}
                      >
                        ver todos
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="content-page-form-group">
            <div className="content-page-card">
              <div
                style={{ backgroundColor: "#6b48ff" }}
                className="card-header template-card-header"
              >
                Configurações adicionais
              </div>
              <div className="card-body template-card-body">
                <div className="form-row">
                  <div className="mr-3">
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={disabledInputs}
                          color="primary"
                          checked={contentData.allowAccessUponCompletion}
                          onChange={handleChange}
                          name="allowAccessUponCompletion"
                        />
                      }
                      label="Permitir acesso ao conteúdo após a conclusão"
                    />
                  </div>

                  {contentData.modality !== "BLENDED" && (
                    <div className="mr-3">
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={disabledInputs}
                            color="primary"
                            checked={contentData.allowReenrollment}
                            onChange={handleChange}
                            name="allowReenrollment"
                          />
                        }
                        label="Permitir rematrícula em caso de reprovação ou expiração"
                      />
                    </div>
                  )}

                  <div style={{ width: "100%" }} className="mr-3">
                    <InputGroup className="mb-2">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Certificado</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        disabled={disabledInputs}
                        className="col-md-4"
                        as="select"
                        value={contentData.idCertificate}
                        onChange={(e) => {
                          handleChangeCertificate(e.target.value);
                        }}
                      >
                        <option>Sem certificado</option>
                        {certificates?.map((certificate) => (
                          <>
                            <option value={certificate.idCertificate}>{certificate.title}</option>
                          </>
                        ))}
                      </Form.Control>
                    </InputGroup>
                    <div>
                      <div className="mr-3">
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={disabledInputs}
                              color="primary"
                              id="enableCertificateForChildren"
                              checked={contentData.enableCertificateForChildren}
                              onChange={handleChange}
                              name="enableCertificateForChildren"
                            />
                          }
                          label="Habilitar a geração do certificado para os níveis abaixo dentro da página"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content-page-form-group">
            <div className="content-page-card">
              <div
                style={{ backgroundColor: "#6b48ff" }}
                className="card-header template-card-header"
              >
                Notificações
              </div>
              <div className="card-body template-card-body">
                <div className="form-column">
                  {contentData.notifications.map((notification) => (
                    <div className="mr-3 row-container" key={notification.idNotification}>
                      <FormControlLabel
                        className="notification-label"
                        control={
                          <Switch
                            color="primary"
                            checked={!(notification.type !== "TEAM_STARTED" && !contentData.finalDate) &&  Boolean(notification.active)}
                            onChange={(event) => _toggleNotification(event, notification)}
                            name={`class${notification.idNotification}`}
                          />
                        }
                        label={
                          <div className="notification-switch-label">
                            <span className="notification-switch-description">
                              {notification.description}
                            </span>
                            <div className="row-control">
                              <Button
                                variant="warning"
                                className="notification-start-config-btn"
                                onClick={() =>
                                  NiceModal.show(ModalNotificationConfig, {
                                    type: notification.type,
                                    description: notification.description,
                                    idNotification: notification.idNotification,
                                    idTeam,
                                  })
                                }
                                disabled={
                                  (notification.type !== "TEAM_STARTED" && !contentData.finalDate) || !notification.active || notification.status === "DEACTIVATED" 
                                }
                              >
                                Configurar
                              </Button>
                            </div>
                          </div>
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {editMode.current && (
            <div className="content-page-form-group">
              <div className="content-page-card">
                <div
                  style={{ backgroundColor: "#6b48ff" }}
                  className="card-header template-card-header"
                >
                  Interação de turma
                </div>
                <div className="card-body template-card-body">
                  <div className="row-control">
                    <span>Configurações de interações e mensagens:</span>
                  </div>
                  <div className="row-control interactions" style={{ marginTop: "10px" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={disabledInputs}
                          color="primary"
                          checked={contentData.allowComments}
                          onChange={handleChange}
                          name="allowComments"
                        />
                      }
                      label={
                        <span style={{ fontSize: "14px" }}>
                          Habilitar comentários e respostas dentro de interações de turma
                        </span>
                      }
                    />
                  </div>
                  <div
                    className="row-control interactions"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      opacity: contentData.allowComments ? 1 : 0.5,
                    }}
                  >
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Caso a interação na página também seja habilitada, procure utilizar textos
                          que deixem claro para o usuário que esta é a interação na turma.
                        </Tooltip>
                      }
                    >
                      <InfoIcon
                        style={{
                          color: contentData.allowComments ? "#6b48ff" : "gray",
                          fontSize: 20,
                        }}
                      />
                    </OverlayTrigger>
                    <span style={{ marginRight: "10px" }}>Rótulo de interação de turma</span>
                    <Form.Control
                      type="text"
                      className="form-control interaction-control-input"
                      id="interactionlabel"
                      aria-describedby="titulo"
                      maxLength={50}
                      placeholder="Interações e mensagens na minha turma"
                      value={contentData.commentsLabel}
                      onChange={(e) =>
                        setContentData({ ...contentData, commentsLabel: e.target.value })
                      }
                      disabled={!contentData.allowComments || disabledInputs}
                    />
                  </div>
                  {modManager && (
                    <div className="row-control interactions">
                      <Button
                        variant="warning"
                        className="interaction-control-btn"
                        onClick={toggleSidebar}
                        disabled={!contentData.allowComments}
                      >
                        Moderar interações de turma
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <br />

          <div className="row pr-3" style={{ padding: 5, justifyContent: "flex-end" }}>
            {!disabledInputs ? (
              <Button variant="warning" onClick={sendData}>
                Salvar
              </Button>
            ) : (
              <Button variant="secondary" onClick={() => window.goBack()}>
                Voltar
              </Button>
            )}
          </div>
        </form>
        <Modal
          show={alertshow > 0 ? true : false}
          onHide={() => {
            setAlert(0);
          }}
        >
          <Modal.Header style={{ backgroundColor: "#6b48ff" }} closeButton>
            <Modal.Title style={{ color: "#fff", fontWeight: 900 }}>
              {alertshow !== 4 ? (
                <h5 style={{ color: "#fff", fontWeight: 900 }}>Atenção:</h5>
              ) : (
                <h5 style={{ color: "#fff", fontWeight: 900 }}>Cadastro editado com sucesso!</h5>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {alertshow === 1 && <h5>Favor preencher o campo Título da turma.</h5>}
            {alertshow === 2 && <h5>Favor preencher a Data de Início.</h5>}
            {alertshow === 3 && <h5>Ocorreu um erro, não foi possível concluir.</h5>}
            {alertshow === 4 && (
              <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button variant="success" onClick={gotoLM}>
                    Confirmar
                  </Button>
                </div>
              </>
            )}
            {alertshow === 5 && <h5>Data fim é obrigatório para modalidade Blended.</h5>}
            {alertshow === 6 && (
              <h5>Altere as datas de início e fim para que elas contenham os encontros.</h5>
            )}
            {alertshow === 7 && (
              <h5>Data fim de Solicitação não pode ser maior que a data fim da Turma</h5>
            )}
          </Modal.Body>
        </Modal>
        <br />

        <Modal
          show={autoRegistrationModal}
          className={"modalAutoRegistrationModal"}
          onHide={() => {
            setAutoRegistrationModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Matrícula automática</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GroupsAutoRegistration
              disabled={disabledInputs}
              autoEnrollProcess={autoEnrollProcess}
              setAutoEnrollProcess={setAutoEnrollProcess}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={requestRegistrationModal}
          className="modalRequestRegistrationModal"
          onHide={() => {
            setRequestRegistrationModal(false);
          }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <PendingRegistrationRequest idTeam={idTeam} />
          </Modal.Body>
        </Modal>

        <Modal
          show={deleteRegistrationsModal}
          className={"modalEmptyClassModal"}
          onHide={() => {
            setDeleteRegistrationsModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {deleteRegistrationsModalStep === "CONFIRM"
                ? "Deseja realmente remover todos os alunos da turma?"
                : "Alunos removidos com sucesso"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {deleteRegistrationsModalStep === "CONFIRM" ? (
              <p>
                O histórico dos alunos que já tiverem concluído o treinamento ou que estejam com
                status em andamento será perdido. Esta ação é irreversível e levará alguns minutos
                para ser concluída.{" "}
              </p>
            ) : (
              <p>Todos os alunos foram removidos.</p>
            )}
          </Modal.Body>
          {deleteRegistrationsModalStep === "CONFIRM" && (
            <Modal.Footer>
              <Button variant="light" onClick={() => setDeleteRegistrationsModal(false)}>
                <MdCancel /> Cancelar
              </Button>
              <Button variant="warning" onClick={emptyClass}>
                <MdDelete /> Remover
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    </>
  );
};

export default withRouter(CreateNewClass);
