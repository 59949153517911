import React, { createContext, useEffect, useState } from "react";
import { getTrialPlans } from "services/NexiDigitalAPI";
import { compareTwoArrays } from "utils/Utils";
import moment from "moment";
type AuthContextType = {
  loginData: loginDataType | null;
  signIn: (token: string, data: loginDataType) => void;
  signOut: () => void;
  getDataTrivial: () => Promise<License>;
  updateLicense: (value) => void;
  getLicense: () => License;
  getAvailablePlans: () => any;
};
export type ActivePlan = {
  name?: string;
  idPlan: number;
  idCompanyApp: number;
  finishDate: string;
  trial?: boolean;
  startTime?: string;
  deadline?: number;
};
export type License = {
  isUpgradeButtonActive: boolean;
  userIsAdmin: boolean;
  hasRequestedUpgrade: boolean;
  numberRequest: number;
  plansContainer: any[];
  activePlan?: ActivePlan;
};

export const AuthContext = createContext<AuthContextType>({
  loginData: null,
  signIn: () => null,
  signOut: () => null,

  getDataTrivial: () => null,
  updateLicense: () => null,
  getLicense: () => null,
  getAvailablePlans: () => null,
});

type Props = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: Props) => {
  const [data, setData] = useState<any>(() => {
    const app_id = localStorage.getItem("app_id");
    const primaryColor = localStorage.getItem("primaryColor");
    const secondaryColor = localStorage.getItem("secondaryColor");
    const thirdColor = localStorage.getItem("thirdColor");
    const fourthColor = localStorage.getItem("fourthColor");
    const headerIcon = localStorage.getItem("headerIcon");
    const isAdmin = parseInt(localStorage.getItem("is-admin") as string);
    const userid = localStorage.getItem("userid");
    const menuData = JSON.parse(localStorage.getItem("menu-data") as string);
    const token = localStorage.getItem("token");

    const id = localStorage.getItem("id");

    if (token) {
      return {
        token,
        loginData: {
          app_id,
          primaryColor,
          secondaryColor,
          thirdColor,
          fourthColor,
          headerIcon,
          isAdmin,
          userid,
          menuData,
          id,
        },
      };
    }
  });

  const [license, setLicense] = useState<License>({
    isUpgradeButtonActive: false,
    userIsAdmin: false,
    hasRequestedUpgrade: false,
    numberRequest: 0,
    plansContainer: [],
  });

  const signIn = async (token: string, data: loginDataType) => {
    data.idCompanyLogin = data.idCompany;
    localStorage.setItem("app_id", String(data.idApp));
    localStorage.setItem("primaryColor", data.primaryColor);
    localStorage.setItem("secondaryColor", data.secondaryColor);
    localStorage.setItem("thirdColor", data.thirdColor);
    localStorage.setItem("fourthColor", data.fourthColor);
    localStorage.setItem("headerIcon", data.headerIcon);
    localStorage.setItem("is-admin", String(data.admin));
    localStorage.setItem("userid", String(data.idUser));
    localStorage.setItem("menu-data", JSON.stringify(data));
    localStorage.setItem("token", token);
    localStorage.setItem("id", String(data.idUser));
    setData({
      token,
      loginData: {
        app_id: data.idApp,
        secondaryColor: data.secondaryColor,
        thirdColor: data.thirdColor,
        fourthColor: data.fourthColor,
        headerIcon: data.headerIcon,
        isAdmin: data.admin,
        userid: data.idUser,
        menuData: data,
        primaryColor: data.primaryColor,
        id: data.idUser,
      },
    });
  };

  const signOut = async () => {
    setData({});
    localStorage.clear();
    sessionStorage.clear();
  };

  const getDataTrivial = async (): Promise<License> => {
    const _menuData = localStorage.getItem("menu-data");
    const menuDataInfo = _menuData ? JSON.parse(_menuData) : null;
    const trialPlans = await getTrialPlans(true);
    const appsUserAccess = menuDataInfo["appsUserAccess"];
    const trialPlansData = trialPlans.data;
    const activePlan = menuDataInfo["activePlan"] || {};

    const { idPlan, trial, finishDate }: ActivePlan = activePlan;

    const comparePlans = compareTwoArrays(
      appsUserAccess,
      trialPlansData,
      "idApp",
      "idAppAvailable",
    );

    const startTime = moment().startOf("day");
    const finishTime = moment(finishDate).startOf("day");

    const deadline = finishTime.diff(startTime, "days");
    const data = {
      isUpgradeButtonActive: !!trial || comparePlans.length > 0,
      userIsAdmin: [1, 2].includes(menuDataInfo?.loginInfo?.admin),
      hasRequestedUpgrade: false,
      numberRequest: 0,
      plansContainer: comparePlans,
      activePlan: { ...activePlan, finishTime, startTime, deadline, idPlan, trial },
    };

    return data;
  };

  const getAvailablePlans = async (withDetails = true) => {
    const { data } = await getTrialPlans(withDetails);
    return data;
  };

  const triggerCallBackInterval = (activePlanData, callBack = () => {}, timeout = 5000) => {
    let planModalValue = -1;
    if (activePlanData.deadline === 1) {
      planModalValue = 1;
    } else if (activePlanData.deadline >= 2 && activePlanData.deadline <= 5) {
      planModalValue = 2;
    } else if (activePlanData.deadline >= 6 && activePlanData.deadline <= 10) {
      planModalValue = 3;
    }

    if (planModalValue !== -1 && sessionStorage.getItem("planModal") !== String(planModalValue)) {
      sessionStorage.setItem("planModal", String(planModalValue));

      if (
        !localStorage.getItem("planModal") ||
        localStorage.getItem("planModal") !== String(planModalValue)
      ) {
        localStorage.setItem("planModal", String(planModalValue));
        setTimeout(() => {
          callBack();
        }, timeout);
      }
    }
  };

  const updateLicense = (value): void => {
    setLicense((prevState) => ({ ...prevState, ...value }));
  };

  const getLicense = (): License => license;

  const value = {
    loginData: data?.loginData,
    signIn,
    signOut,

    getDataTrivial,
    updateLicense,
    getLicense,
    getAvailablePlans,
    triggerCallBackInterval,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
