import React, { memo } from "react";
import { Tooltip } from "@material-ui/core";
import IcoSleep from "assets/images/sleep.svg";
import IcoSleepActive from "assets/images/sleep-active.svg";

const ChangeStatusRow = memo(({ data, setControlModals }) => (
    <Tooltip title={Boolean(data.active) ? "Inativar termo" : "Ativar termo"} placement="top">
      <span
        className="btn btn-sm float-right icon"
        onClick={() => {
          setControlModals((prev) => ({
            showModals: {
              ...prev.showModals,
              inactive: Boolean(data.active),
              active: !Boolean(data.active),
            },
            id: data.idTermOfResponsibility,
          }));
        }}
      >
        <img src={Boolean(data.active) ? IcoSleepActive : IcoSleep} alt="icon-sleep" />
      </span>
    </Tooltip>
));

export default ChangeStatusRow;
