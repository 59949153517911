import React, { useEffect, useState, Fragment } from "react";

import {
  getStudentsEnrollments,
  getStudentsEnrollmentsBlocks,
  getStudentsEnrollmentsNodes,
} from "../../../../../services/NexiDigitalAPI";

import { filterFlatten } from "../filters/filterFlatten";
import { filterElemType } from "../filters/filterElemType";

import LineItem from "./LineItem";
import AccordionItens from "./AccordionItens";

const RecursiveItens = ({ endPoint, indice, idUser, idEnrollment, nodeCode, open = false }) => {
  const [isEnrollmentBlocks, setEnrollmentBlocks] = useState(null);
  const [nextRecursive, setNextRecursive] = useState(1);

  useEffect(() => {
    if (open && isEnrollmentBlocks === null) {
      if (endPoint === 0) {
        (async () => {
          const containerApi = await getStudentsEnrollments(idUser);
          const containerApiMap = containerApi.map((item) => {
            item.mod = 0;
            return item;
          });

          const containerApiFilter = containerApiMap.filter((item) => item.node);
          setEnrollmentBlocks(containerApiFilter);
          setNextRecursive(1);
        })();
      } else if (endPoint === 1) {
        (async () => {
          const containerApi = await getStudentsEnrollmentsBlocks(idUser, idEnrollment);
          filterFlatten(containerApi, setEnrollmentBlocks, 'IMAGE');
          setNextRecursive(2);
        })();
      } else if (endPoint === 2) {
        (async () => {
          const containerApi = await getStudentsEnrollmentsNodes(idUser, idEnrollment, nodeCode);
          filterFlatten(containerApi, setEnrollmentBlocks, 'IMAGE');
          setNextRecursive(2);
        })();
      }
    }
  }, [open, idUser, idEnrollment, isEnrollmentBlocks, endPoint, nodeCode]);

  const DropdownElement = ({ data }) => {


    return (
      <div className="dropdown">
        {data.map((item, i) => {
          const filterEl = filterElemType(item);
          const indKey = indice + "_" + i;

          const notHaveChildren =
            filterEl !== "NODE" && filterEl !== "LEARNING" && filterEl !== "INIT";

          const idEnrollmentRef =
            filterEl === "INIT" ? item.nodeEnrollment.idEnrollment : idEnrollment;

          const nodeCodeRef = filterEl === "NODE" ? item.data.nodeCode : item.nodeCode;

          return (
            <Fragment key={indKey}>
              {notHaveChildren ? (
                <div className="titleAccordionRes">
                  <LineItem item={item} idUser={idUser} />
                </div>
              ) : (
                <AccordionItens
                  item={item}
                  idUser={idUser}
                  endPoint={nextRecursive}
                  indKey={indKey}
                  idEnrollment={idEnrollmentRef}
                  nodeCode={nodeCodeRef}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    );
  };

  return isEnrollmentBlocks === null ? (
    "Carregando..."
  ) : isEnrollmentBlocks.length > 0 ? (
    <DropdownElement data={isEnrollmentBlocks} />
  ) : (
    "Sem Conteúdo."
  );
};

export default RecursiveItens;
