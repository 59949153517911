import React from "react";

import Workload from "components/Workload";

const CmsWorkload = ({ workload, setWorkload, hideHour, disabled }) => {
  return (
    <div className="form-group">
      <div className="card">
        <div className="card-header template-card-header">Carga horária</div>
        <div className="card-body template-card-body">
          <div className="form-row">
            <Workload
              hideTitle
              workload={workload}
              setWorkload={setWorkload}
              hideHour={hideHour}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CmsWorkload;
