import React, { useState, useEffect, useRef } from "react";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { useHistory, useParams, withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import "react-datepicker/dist/react-datepicker.css";
import _ from "underscore";
import { Form, InputGroup } from "react-bootstrap";
import imageCompression from "browser-image-compression";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import BreadCrumb from "../BreadCrumb/BreadCrumb";
import "./index.scss";
import {
  createImage,
  getImage,
  updateImage
} from "../../../services/NexiDigitalAPI";
import Switch from "react-bootstrap/esm/Switch";
import TagsInput from "../TagsInput/TagsInput";
import Loading from "../../../components/Loading";
import ModalV2 from "../ModalV2";
import ContentBannerImageUpload from "components/ContentBannerImageUpload";
import { useNodesTree } from "containers/PeopleCraft/LearningManagementNew/Components/NodesTree/hooks/useNodesTree";
import PlaceholderParameters from "../LearningManagementNew/Components/DynamicParameters/components/PlaceholderParameters";



const ContentBannerImage = (props) => {
  
  const params = useParams();
  const GetTreeNodeCode = () => {
    const { nodeCurrent } = useNodesTree();
    return nodeCurrent;
  }

  const { isModal = false, closeModal, returnNodeData } = props;
  const { idBlockElement, idElement, nodeCode,  editNodeCode } = params.nodeCode ? params : GetTreeNodeCode();
  // const { idBlockElement, idElement, nodeCode,  editNodeCode } = params;
  const history = useHistory();
  
  const defaultBannerData = {
    image: null,
    mobileImage: null,
    link: null,
    idCompany: nodeCode.split('.')[0]
  };


  const [contentData, setContentData] = useState(defaultBannerData);
  const [initialContentData, setInitialContentData] = useState(defaultBannerData);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [modalProps, setModalProps] = useState({
    title: "",
    subTitle: "",
    acceptButtonAction: () => null,
  });

  // variáveis de auxílio
  const [loadingCreateNode, setLoadingCreateNode] = useState(false);
  const [errorCreateNode, setErrorCreateNode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingNode, setLoadingNode] = useState(true);

  // Imagem
  const contentImageUploadRef = useRef(null);

  useEffect(() => {
    if (editNodeCode) {
      _getImage();
    }
  }, [editNodeCode]);

  const _getImage = () => {
    setLoadingNode(true);

    getImage(idElement)
      .then((res) => {
        const nodeDataResult = res.data;
        // Save initial content do compare for changes on save
        setInitialContentData(nodeDataResult);
        setContentData(nodeDataResult);
      })
      .catch((err) => {
        console.log("Error getting learning node", err.request);
        alert("Erro ao requisitar informações do nó");
      })
      .finally(() => {
        setLoadingNode(false);
      });
  };

  const _createImage = async () => {

    if(contentImageUploadRef.current.imagesToUpload.current.length === 0){
      alert('É necessário adicionar as imagens para salvar.');
      return false;
    }

    setLoadingCreateNode(true);
    setErrorCreateNode(false);

     const uploadImageLocation = await contentImageUploadRef.current.uploadImagem();
     let newImageLocation = uploadImageLocation.current.image;
     let newMobileImageLocation = uploadImageLocation.current.mobileImage; 
    
    createImage({
      ...contentData,
      link: !contentData.link ? "" : contentData.link,
      image: newImageLocation,
      mobileImage: newMobileImageLocation,
    })
      .then((res) => {
        const result = res.data;

        if (isModal) {
          returnNodeData(result);
        }
      })
      .catch((err) => {
        const errorObject = JSON.parse(err?.request?.responseText);
        const errorMessage = errorObject?.message;
        setErrorMessage(errorMessage);
        setErrorCreateNode(true);
      })
      .finally(() => {
        setLoadingCreateNode(false);
      });
  };

  
  const _editImage = async() => {

    
    setLoadingCreateNode(true);
    setErrorCreateNode(false);
    

     const uploadImageLocation = await contentImageUploadRef.current.uploadImagem();
     let newImageLocation = uploadImageLocation.current.image;
     let newMobileImageLocation = uploadImageLocation.current.mobileImage; 

     const payload = {
      ...contentData,
      link: !contentData.link ? "" : contentData.link,
      image: newImageLocation,
      mobileImage: newMobileImageLocation,
    }

     if(initialContentData == payload){
      // history.push(`/node-manager/${nodeCode}`);
      history.goBack();
      setLoadingCreateNode(false);
      return;
     }
    
    updateImage({
      ...contentData,
      image: newImageLocation,
      mobileImage: newMobileImageLocation,
    })
      .then((res) => {
        // history.push(`/node-manager/${nodeCode}`);
        history.goBack();
      })
      .catch((err) => {
        const errorObject = JSON.parse(err?.request?.responseText);
        const errorMessage = errorObject?.message;
        setErrorMessage(errorMessage);
        setErrorCreateNode(true);
      })
      .finally(() => {
        setLoadingCreateNode(false);
      });

  };

  const sendData = () => {
    if(editNodeCode){
      _editImage();
    }else{
      _createImage();
    } 
  }

  return editNodeCode && loadingNode ? (
    <Loading center>Carregando...</Loading>
  ):(
    <div>
      <ModalV2
        showModal={showStatusModal}
        setShowModal={(status) => setShowStatusModal(status)}
        acceptButtonText={modalProps.acceptButtonText}
        acceptButtonAction={modalProps.acceptButtonAction}
        secondaryButtonText={modalProps.secondaryButtonText}
        secondaryButtonAction={modalProps.secondaryButtonAction}
        title={modalProps.title}
        subTitle={modalProps.subTitle}
      />

      {!isModal && (
        <div className="header-title-wrapper">
          <div className="header-title">
            <button
              className="btnGoBack"
              onClick={() => {
                props.history.goBack();
              }}
            >
              <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
            </button>
            Editar imagens
          </div>
        </div>
      )}

      <div className="post-form">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            editNodeCode ? _editImage() : _createImage();
          }}
        >

          <div className="content-page-form-group">
            <div className="content-page-card">
              <div
                style={{ backgroundColor: "#6b48ff" }}
                className="card-header template-card-header"
              >
                Insira as imagens conforme orientações
              </div>
              <ContentBannerImageUpload reference={contentImageUploadRef} data={contentData} sendData={sendData} defaultPrefixName="bannerImage"/>
            </div>
          </div>

          <div className="content-page-form-group">
            <div className="content-page-card">
              <div
                style={{ backgroundColor: "#6b48ff" }}
                className="card-header template-card-header"
              >
                Link da imagem
              </div>
              <div className="card-body template-card-body">
                <div style={{ display: "flex" }}>
                  <div className="input-group mb-3 ml-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        link
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="imageLink"
                      aria-describedby="imageLink"
                      value={contentData.link || ""}
                      onChange={(e) =>
                        setContentData({ ...contentData, link: e.target.value })
                      }
                    />
                  </div>
                </div>

                <p><b>Atenção:</b> Caso não queira vincular um link a imagem, apenas deixe o campo acima em branco.</p>
                
              </div>
            </div>
          </div>
          
          

          <br />
          <div className="row pr-3" style={{ padding: 5, justifyContent: "flex-end" }}>
           
            <button
              type="submit"
              className="btn content-page-button ml-3"
              style={{ backgroundColor: "#feac0e" }}
            >
              Salvar
              {loadingCreateNode && (
                <CircularProgress
                  className="content-page-button-icon"
                  style={{ marginLeft: 10 }}
                  size={18}
                />
              )}
            </button>
          </div>
          {errorCreateNode && (
            <span style={{ display: "flex", justifyContent: "center", color: "indianred" }}>
              Erro: {errorMessage || "Falha ao se comunicar com o servidor"}
            </span>
          )}
        </form>
        <br />
      </div>
    </div>
  );
};

export default withRouter(ContentBannerImage);
