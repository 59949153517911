const fetchImageAsBlob = async (url) => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Falha ao carregar a imagem");
    }

    const blob = await response.blob();
    const base64Image = await blobToBase64(blob);
    const dimensions = await getBase64ImageDimensions(base64Image);

    return { img: base64Image, dimensions };
  } catch (error) {
    const defaultImageBase64 =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
    return { img: defaultImageBase64, dimensions: { width: 0, height: 0 } };
  }
};

// Função para converter Blob em Base64
const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
};

const getBase64ImageDimensions = (base64) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = base64;

    img.onload = () => {
      resolve({
        width: img.width, // Ajuste de escala opcional
        height: img.height, // Ajuste de escala opcional
      });
    };

    img.onerror = () => reject(new Error("Erro ao carregar a imagem Base64"));
  });
};

// Função de cálculo de redimensionamento mantendo a proporção
const calculateContainDimensions = (originalWidth, originalHeight, maxWidth, maxHeight) => {
  if (maxWidth === 0 && maxHeight === 0) {
    return { width: originalWidth, height: originalHeight };
  }

  const widthRatio = maxWidth / originalWidth || Infinity;
  const heightRatio = maxHeight / originalHeight || Infinity;
  const ratio = Math.min(widthRatio, heightRatio);

  return {
    width: Math.round(originalWidth * ratio),
    height: Math.round(originalHeight * ratio),
  };
};

// const calculateContainDimensionsFixedHeight = (originalWidth, originalHeight, fixedHeight) => {
//   if (fixedHeight === 0) {
//     return { width: originalWidth, height: originalHeight };
//   }

//   const ratio = fixedHeight / originalHeight;

//   return {
//     width: Math.round(originalWidth * ratio),
//     height: fixedHeight,
//   };
// };

export const loadImageAsBase64 = async (url, maxWidth = 0, maxHeight = 0) => {
  if (!url || typeof url !== "string") {
    throw new Error("URL inválida");
  }

  if (maxWidth < 0 || maxHeight < 0) {
    throw new Error("Dimensões inválidas");
  }

  try {
    const { img, dimensions } = await fetchImageAsBlob(url);

    // Calcular as dimensões ajustadas
    const { width, height } = calculateContainDimensions(
      dimensions.width,
      dimensions.height,
      maxWidth,
      maxHeight,
    );

    // console.log(img);
    // console.log(dimensions.width, dimensions.height, maxWidth, maxHeight);
    // console.log("width, height", width, height);

    return { imgBase64: img, dimensions: { width: width, height: height } };
  } catch (error) {
    console.error("Erro no carregamento da imagem:", error);
    return {
      imgBase64:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=",
      dimensions: { width: 0, height: 0 },
    };
  }
};
