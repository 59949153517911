import React, { useState, useEffect, useContext } from "react";
import { withRouter, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import GetAppIcon from "@material-ui/icons/GetApp";
import Swal from "sweetalert2";

import Loading from "../../../components/Loading";
import { AuthContext } from "../../../context/AuthContext";
import { NexiDigitalAPI as api } from "../../../services/NexiDigitalAPI";

import "./styles.scss";

const Upload = () => {
  const [data, setData] = useState({
    title: "",
    description: "",
    mimetype: "",
    extension: "",
    url: "",
    status: "",
    sectionTarget: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [listSectionsTarget, setListSectionsTarget] = useState(null);

  const { id: idUserUpload } = useParams();
  const { loginData } = useContext(AuthContext);
  const { thirdColor } = loginData;

  useEffect(() => {
    async function getData(id) {
      setIsLoading(true);

      const findUploadById = (data) => {
        return data.find(({ idUserUpload }) => idUserUpload === Number(id));
      };

      await api
        .get("/userupload/listall")
        .then(({ data: { data } }) => {
          const userUpload = findUploadById(data);
          setIsLoading(false);
          setData(userUpload);
        })
        .catch(() => setIsLoading(false));
    }

    async function getListSectionsTarget() {
      await api
        .get("/userupload/listsectiontarget")
        .then(({ data: { result } }) =>
          setListSectionsTarget([{ name: "Selecione", idSection: 0 }, ...result]),
        );
    }

    getData(idUserUpload);
    getListSectionsTarget();
  }, []);

  const handleUpdate = async () => {
    console.log(data);

    if (data.status === "P") {
      Swal.fire({ icon: "warning", text: "Selecione um status válido!" });
      return;
    }

    if (!data.sectionTarget || data.sectionTarget === "0") {
      Swal.fire({ icon: "warning", text: "Selecione a seção de destino!" });
      return;
    }

    if (!data.title) {
      Swal.fire({ icon: "warning", text: "Título é um campo obrigatório!" });
      return;
    }

    if (!data.description) {
      Swal.fire({ icon: "warning", text: "Descrição é um campo obrigatório!" });
      return;
    }

    await api
      .put(`/userupload/${idUserUpload}`, {
        status: data.status,
        title: data.title,
        description: data.description,
        idSectionTarget: Number(data.sectionTarget),
      })
      .then(() => Swal.fire({ icon: "success", text: "Alterado com sucesso!" }))
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: error.response.data.message || error.response.data.error,
        });
      });
  };

  function getIdSection() {
    const [section] = listSectionsTarget.filter(filterSectionByName);
    return section && section.idSection;
  }

  function filterSectionByName(object) {
    if (object.name === data.sectionTarget) return true;
  }

  function checkFileType(file) {
    if (!file) return null;
    if (file.includes("image")) return "image";
    if (file.includes("video")) return "video";
    return "others";
  }

  const handleChangeValue = (prop, value) => {
    setData({ ...data, [prop]: value });
  };

  const { title, description, mimetype, url, status, name } = data;

  return (
    <div className="upload-user">
      {isLoading ? (
        <Loading center> Carregando conteúdos... </Loading>
      ) : (
        data && (
          <div className="upload-user__content">
            <div className="upload-user__author">
              <label>Autor</label>
              <input defaultValue={name} readOnly />
            </div>

            <div className="upload-user__header">
              <label className="">Título</label>
              <input
                value={title}
                onChange={(event) => handleChangeValue("title", event.target.value)}
              />
            </div>

            <div className="upload-user__image">
              {checkFileType(mimetype) === "image" && (
                <>
                  <label className="">Arquivo</label>
                  <img src={url} alt="" />
                </>
              )}
              {checkFileType(mimetype) === "video" && (
                <>
                  <label className="">Arquivo</label>
                  <ReactPlayer url={url} controls={true} width="100%" height="100%" />
                </>
              )}
            </div>

            <div className="upload-user__info">
              <label className="">Descrição</label>
              <textarea
                value={description}
                onChange={(event) => handleChangeValue("description", event.target.value)}
              />
            </div>
            {checkFileType(mimetype) === "others" && (
              <div className="upload-user__file">
                <p>Para baixar o arquivo anexado ao post clique no botão abaixo.</p>
                <a style={{ backgroundColor: thirdColor }} href={url} download>
                  <GetAppIcon />
                  DOWNLOAD
                </a>
              </div>
            )}

            <div className="upload-user__footer">
              <div>
                <label className="upload-user__status">Status</label>
                <select
                  id="selectStatus"
                  onChange={(event) => handleChangeValue("status", event.target.value)}
                  value={status}
                >
                  <option value="A">Aprovado</option>
                  <option value="P">Pendente</option>
                  <option value="C">Cancelado</option>
                </select>

                <label className="upload-user__target">Seção Destino</label>
                {listSectionsTarget && (
                  <select
                    id="selectTarget"
                    onChange={(event) => handleChangeValue("sectionTarget", event.target.value)}
                    value={getIdSection()}
                  >
                    {listSectionsTarget.map(({ name, idSection }) => (
                      <option key={name} value={idSection}>
                        {name}
                      </option>
                    ))}
                  </select>
                )}
              </div>

              <button onClick={handleUpdate} style={{ backgroundColor: thirdColor }}>
                SALVAR ALTERAÇÕES
              </button>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default withRouter(Upload);
