import React from "react";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { useHistory } from "react-router-dom";
import './styles.scss'
const HeaderNavigation = ({ title, goBack = null }) => {

  const history = useHistory();

  return (
    <div className="header-title-wrapper">
      <div className="header-title">
        <button
          className="btnGoBack"
          onClick={() => {
            if(goBack){
              goBack()
              return
            }
            history.goBack();
          }}
        >
          <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
        </button>
        {title}
      </div>
    </div>

  )

}

export default HeaderNavigation;
