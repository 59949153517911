import React, { Fragment, useState, useEffect } from "react";
import { modelQuestion } from "utils/Utils";
import { Button, Form, Row, Col } from "react-bootstrap";
import { BsTrash as TrashIco } from "react-icons/bs";
import { CircularProgress } from "@material-ui/core";
import AutoResizeTextarea from "../../../../../../components/AutoResizeTextarea";
import "./AlternativesInputs.scss";

const AlternativesInputs = ({
  typeQuestion,
  questionOptions,
  setQuestionOptions,
  setValidateForm,
}) => {
  const [loadingQuestionOptions, setLoadingQuestionOptions] = useState(false);
  const [currentTypeQuestion, setCurrentTypeQuestion] = useState(typeQuestion);

  const init = () => {
    if (
      typeQuestion === modelQuestion.TRUE_OR_FALSE &&
      (questionOptions.length === 0 ||
        (questionOptions.length > 0 && questionOptions[0].description !== "Verdadeiro"))
    ) {
      const initialOptions = [
        {
          description: "Verdadeiro",
          isCorrect: 0,
          idQuestionOption: new Date().getTime().toString(),
        },
        {
          description: "Falso",
          isCorrect: 0,
          idQuestionOption: new Date().getTime().toString() + 10,
        },
      ];
      setQuestionOptions(initialOptions);
      setCurrentTypeQuestion(typeQuestion);
    } else if (typeQuestion === modelQuestion.CORRELATE) { 
      if (!questionOptions || questionOptions.length === 0) {
        setQuestionOptions([]);
        setCurrentTypeQuestion(typeQuestion);
        return;
      }

      const initialOptions = questionOptions.filter((qOption) => qOption.questionOptionAnswer);
      setQuestionOptions(initialOptions);
      setCurrentTypeQuestion(typeQuestion);
    } else if (
      currentTypeQuestion === modelQuestion.CORRELATE &&
      (typeQuestion === modelQuestion.MULTI_OPTION || typeQuestion === modelQuestion.SINGLE_OPTION)
    ) {
      setQuestionOptions([]);
      setCurrentTypeQuestion(typeQuestion);
    }
  };

  useEffect(() => {
    setLoadingQuestionOptions(true);
    init();
    setLoadingQuestionOptions(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeQuestion]);

  const addAlt = (e) => {
    e.preventDefault();
    setQuestionOptions((item) => [
      ...item,
      {
        description: "",
        isCorrect: typeQuestion === modelQuestion.CORRELATE ? 1 : 0,
        idQuestionOption: new Date().getTime().toString(),
        questionOptionAnswer:
          typeQuestion === modelQuestion.CORRELATE ? { description: "" } : undefined,
      },
    ]);
  };

  const trashEvent = (index) => {
    const newQuestionOptions = [...questionOptions];
    newQuestionOptions.splice(index, 1);
    setQuestionOptions(newQuestionOptions);
  };

  const toggleStatus = (e, indice) => {
    const checked = e.target.checked;
    const newQuestionOptions = questionOptions.map((it, index) => {
      if (typeQuestion !== modelQuestion.MULTI_OPTION) {
        it.isCorrect = 0;
      }
      if (indice === index) it.isCorrect = checked ? 1 : 0;
      return it;
    });
    setQuestionOptions(newQuestionOptions);
  };

  const filterType = (typeQuestion) => {
    const res = {
      TRUE_OR_FALSE: "radio",
      SINGLE_OPTION: "radio",
      MULTI_OPTION: "checkbox",
    };
    return res[typeQuestion] || "radio";
  };

  const AlternativeCard = ({ item, index, toggleStatus }) => {
    const [description, setDescription] = useState(item?.description || "");
    const [questionOptionAnswer, setQuestionOptionAnswer] = useState(
      item?.questionOptionAnswer?.description || "",
    );

    const handleDescriptionChange = (e) => {
      const newValue = e.currentTarget.value;
      item.description = newValue;
      setDescription(newValue);
    };

    const handleAnswerDescriptionChange = (e) => {
      const newValue = e.currentTarget.value;
      item.questionOptionAnswer.description = newValue;
      setQuestionOptionAnswer(newValue);
    };

    if (typeQuestion === modelQuestion.CORRELATE && item?.questionOptionAnswer) {
      return (
        <Row className="align-items-center mb-2 matching-row">
          <Col className="pr-2 matching-col">
            <AutoResizeTextarea
              defaultValue={item.description}
              onChange={handleDescriptionChange}
              placeholder="Digite uma alternativa"
              onBlur={(e) => {
                e.preventDefault();
                if (item.questionOptionAnswer.description.trim().length > 0) {
                  setValidateForm((prev) => !prev);
                }
              }}
              maxLength={500}
              className="alternative-matching-input"
            />
            <Form.Text className={"text-muted"}>
              {description.length}/{500}
            </Form.Text>
          </Col>
          <Col className="pl-2 matching-col">
            <AutoResizeTextarea
              defaultValue={item.questionOptionAnswer.description}
              onChange={handleAnswerDescriptionChange}
              placeholder="Digite uma resposta"
              onBlur={(e) => {
                e.preventDefault();
                if (item.description.trim().length > 0) {
                  setValidateForm((prev) => !prev);
                }
              }}
              maxLength={500}
              className="answer-input"
            />
            <Form.Text className={"text-muted"}>
              {questionOptionAnswer.length}/{500}
            </Form.Text>
          </Col>
          <Col xs="auto" className="d-flex align-items-center">
            <div className="matching-altRemove">
              <p>Remover</p>
              <Button
                variant="link"
                className="p-0 matching-remove-button"
                onClick={() => trashEvent(index)}
              >
                <TrashIco />
              </Button>
            </div>
          </Col>
        </Row>
      );
    }

    return (
      <div className="altIt">
        <div className="altItSelec">
          <Form.Check
            inline
            name={`alternativa`}
            type={filterType(typeQuestion)}
            value={`${"Alt" + index}`}
            id={`${"alt" + index}`}
            checked={Boolean(item.isCorrect)}
            onChange={(e) => toggleStatus(e, index)}
            label={typeQuestion === modelQuestion.TRUE_OR_FALSE ? item.description : ""}
            className="form-check"
          />
          {typeQuestion !== modelQuestion.TRUE_OR_FALSE && (
            <div className="pl-2 matching-col">
              <AutoResizeTextarea
                defaultValue={description}
                onChange={handleDescriptionChange}
                placeholder={`Alternativa ${index + 1}`}
                className="alternative-input"
              />
              <Form.Text className="text-muted">
                {description.length}/{500}
              </Form.Text>
            </div>
          )}
        </div>
        {typeQuestion !== modelQuestion.TRUE_OR_FALSE && (
          <div className="questions-altRemove">
            <p>Remover</p>
            <Button
              onClick={() => trashEvent(index)}
              variant="link"
              className="p-0 matching-remove-button"
            >
              <TrashIco />
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="form-group form-group-block-02 form-group-Knowledge">
      <div className="card">
        <div className="card-header template-card-header">Inserir alternativas</div>
        <div className="card-body template-card-body">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 altContainer">
              <p>
                {typeQuestion === modelQuestion.CORRELATE
                  ? "Organize as alternativas à esquerda e as respostas à direita, o embaralhamento ocorre automaticamente."
                  : typeQuestion === modelQuestion.MULTI_OPTION
                  ? "Adicione as alternativas e marque as respostas corretas."
                  : typeQuestion === modelQuestion.SINGLE_OPTION
                  ? "Adicione as alternativas e marque a resposta correta."
                  : "Selecione apenas uma alternativa correta."}
              </p>
              <div className="altList">
                {!loadingQuestionOptions ? (
                  <>
                    {questionOptions.length > 0 ? (
                      questionOptions.map((item, index) => {
                        return (
                          <Fragment key={index}>
                            <AlternativeCard
                              item={item}
                              index={index}
                              toggleStatus={toggleStatus}
                            />
                          </Fragment>
                        );
                      })
                    ) : (
                      <div className="noAlternative">Sem nenhuma alternativa.</div>
                    )}
                  </>
                ) : (
                  <CircularProgress
                    style={{ color: "white", width: 16, height: 16, marginLeft: 5 }}
                  />
                )}
              </div>
              {typeQuestion !== modelQuestion.TRUE_OR_FALSE && (
                <Button variant="warning" onClick={addAlt}>
                  Adicionar alternativa
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlternativesInputs;
