import React from "react";
import "./style.scss";

const MenuOption = ({ icon: Icon, iconProps, label, labelProps, onClick = () => {}}) => {
  return (
    <div className="menu-option" onClick={onClick}>
      {Icon && <Icon {...iconProps}/>}
      <span {...labelProps}>{label}</span>
    </div>
  );
};

export default MenuOption;