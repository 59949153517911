export const propsFilter = (item) => {
  const {
    fontSize,
    fontWeight,
    fontStyle,
    textAlign,
    fontFamily,
    lineHeight,
    color,
    fill,
    underline,
    overline,
    linethrough,
  } = item;

  const textDecoration = `${underline ? "text-decoration: underline;" : " "}${
    overline ? "text-decoration: overline;" : " "
  }${linethrough ? "text-decoration: line-through;" : " "}`;

  const textAlignBase = `${textAlign ? `text-align:${textAlign};` : ``}`;
  const colorBase = `${color ? `color:${color};` : ``}`;
  const fillBase = `${fill ? `color:${fill};` : ``}`;
  const fontSizeBase = `${fontSize ? `font-size:${fontSize}px;` : ``}`;
  const fontStyleBase = `${fontStyle ? `font-style:${fontStyle};` : ``}`;
  const fontFamilyBase = `${fontFamily ? `font-family:${fontFamily};` : ``}`;
  const fontWeightBase = `${fontWeight ? `font-weight:${fontWeight};` : ``}`;
  const lineHeighttBase = `${lineHeight ? `line-height:${lineHeight};` : ``}`;
  //const lineHeighttBase = `line-height:1;`;

  const styleFilter = `${textAlignBase} ${colorBase} ${fillBase} ${fontSizeBase} ${fontStyleBase} ${fontWeightBase} ${fontFamilyBase} ${lineHeighttBase} ${textDecoration}`;
  return styleFilter.trim();
};

export const concactStylesSVG = (stylesItem, text) => {
  const grupArrayProp = (array, propriedade) => {
    if (array.length === 0) {
      return [];
    }

    let resultado = [];
    let grupoAtual = [array[0]];

    for (let i = 1; i < array.length; i++) {
      if (JSON.stringify(array[i][propriedade]) === JSON.stringify(grupoAtual[0][propriedade])) {
        grupoAtual.push(array[i]);
      } else {
        resultado.push(grupoAtual);
        grupoAtual = [array[i]];
      }
    }

    resultado.push(grupoAtual);

    return resultado;
  };

  const orderObject = (objeto) => {
    // 1. Obtenha as chaves do objeto
    const chaves = Object.keys(objeto);

    // 2. Ordene as chaves
    chaves.sort();

    // 3. Crie um novo objeto com as chaves ordenadas
    const objetoOrdenado = {};
    for (const chave of chaves) {
      objetoOrdenado[chave] = objeto[chave];
    }

    return objetoOrdenado;
  };

  const filterProp = (objeto) => {
    for (const chave in objeto) {
      if (objeto[chave] === null || objeto[chave] === undefined || objeto[chave] === "") {
        delete objeto[chave];
      }
    }

    return orderObject(objeto);
  };

  const createTextSpan = (text, textArraySpan) => {
    let textFilter = [];

    textArraySpan.map((item, ind) => {
      const startIndice = item[0].indice;
      const endIndice = item[item.length - 1].indice;
      const style = propsFilter(item[0].style);

      const spanStart = `<span style="${style}">`;
      const spanEnd = `</span>`;

      textFilter = [...textFilter, [startIndice, 0, [spanStart]], [endIndice + 1, 0, [spanEnd]]];

      return item;
    });

    const mulSplice = (str, arr) => {
      let dif = 0;

      arr.forEach(([start, deleteCount = 0, insertionArray = []]) => {
        str = [
          ...str.slice(0, start + dif),
          ...insertionArray,
          ...str.slice(start + deleteCount + dif),
        ];

        dif += insertionArray.length - deleteCount;
      });

      return str.join("");
    };

    const modifiedString = mulSplice(text.split(""), textFilter);

    return modifiedString;
  };

  const arrayStart = Object.entries(stylesItem).map((item, indice) => {
    return { indice: parseInt(item[0]), style: filterProp(item[1]) };
  });

  const textArraySpan = grupArrayProp(arrayStart, "style");
  const textSpan = createTextSpan(text, textArraySpan);

  return textSpan;
};
