import "./style.scss";
import React from "react";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

type Props = {
  number: number;
  className?: string;
  init?: boolean;
};

function PlaceholderBlocks({ number = 5, className = "", init = false }: Props) {
  let rows: number[] = [];
  let i: number = 0;

  while (++i <= number) {
    rows.push(i);
  }
  const PlaceholderItem = ({ style, className }) => {
    return (
      <div className={`ph-wrapper ${className}`} style={style}>
        
        {/* <div className="ph-line-base">
            <div className="ph-TransparentBlock ph-animated">
                  <AddCircleOutlineIcon style={{ fontSize: 30 }} />
            </div>
        </div> */}

        <div className="">
            <div className="ph-conteinerBlocks ph-animated">
                <div className="ph-contentBlock ph-animated"></div>
                <div className="ph-contentBlock ph-animated"></div>
            </div> 
        </div>

        <div className="">
            <div className="ph-conteinerBlocks ph-animated">
                <div className="ph-contentBlock ph-animated"></div>
                <div className="ph-contentBlock ph-animated"></div>
                <div className="ph-contentBlock ph-animated"></div>
            </div> 
        </div>

      </div>
    );
  };

  return (
    <div className={`pg-grid-placeholder-Blocks ${init ? "ph-wrapperInit" : ""}`}>
      {rows.map(function (i) {
        return (
          <PlaceholderItem
            key={i}
            style={{ paddingLeft: Math.round(i % 2 ? i * 20 : 20) + "px" }}
            className={className}
          />
        );
      })}
    </div>
  );
}

export default PlaceholderBlocks;
