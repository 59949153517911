import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import { Divider } from "@material-ui/core";
import {
  editLearningNode,
  getCertificates,
  getLearningNode,
  getMenuData,
  getNodeLevels,
  setNodeStatusToArchived,
  setNodeStatusToDraft,
  setNodeStatusToPublished,
} from "services/NexiDigitalAPI";
import { ContentData, StatusTypes, Notification } from "./nodeParamenters.model";
import AccordionComponent from "../AccordionComponent";
import BasicInformation from "./components/BasicInformation";
import ImageComponent from "./components/ImageComponent";
import AuthorComponent from "./components/AuthorComponent";
import MeetingComponent, { Meeting } from "./components/MeetingComponent";
import AdditionalSettingsComponent from "./components/AdditionalSettingsComponent";
import ModalV2 from "containers/PeopleCraft/ModalV2";
import TagsCompoent from "./components/TagsComponent";
import { useNodesTree } from "containers/PeopleCraft/LearningManagementNew/Components/NodesTree/hooks/useNodesTree";
import PlaceholderParameters from "containers/PeopleCraft/LearningManagementNew/Components/DynamicParameters/components/PlaceholderParameters";
import NotificationPage from "./components/NotificationPage";
import ImpactEvaluation from "./components/ImpactEvaluation";
import ModalDialog from "containers/PeopleCraft/Modals/ModalDialog";

type Props = {
  editPropsNodes: (payload: {
    [key: string]: number | string | string[] | Meeting[] | boolean | null;
  }) => void;
  nodeCode: string;
  editNodeCode: boolean;
  setNodeData: any;
  setLoadingNodeList: any;
  permissionLeraningPage: boolean
};

enum nodeType {
  ORGANIZATION = "ORGANIZATION",
  LEARNING = "LEARNING",
}

const NodeParameters: React.FC<Props> = ({
  editPropsNodes,
  nodeCode,
  editNodeCode,
  setNodeData,
  setLoadingNodeList,
  permissionLeraningPage
}: Props) => {
  const defaultContentData: ContentData = {
    title: null,
    shortTitle: null,
    label: null,
    description: null,
    image: null,
    originalNodeCode: null,
    company: {
      id: getMenuData().idCompany,
    },
    nodeLevel: 0,
    status: StatusTypes.DRAFT,
    hasEnrollmentCompleted: false,
    idImpactEvaluation: null,
    // parentNodeCode: "1.1",
    parentNodeCode: null,
    essential: true,
    entryPoint: 1,
    modality: null,
    selfEnrollment: true,
    author: null,
    authorEmail: null,
    tags: [],
    idCertificate: null,
    meetings: [],
    attendanceListAsApprovalCriteria: 0,
    enableCertificateForChildren: false,
    hasEnrollmentStarted: true,
    hasImpactEvaluation: 0,
    hasChildren: true,
    nodeType: null,
    syllabus: null,
    sideMenu: null
,
  };
  const [contentData, setContentData] = useState<ContentData>(defaultContentData);
  const [contentDataBackup, setContentDataBackup] = useState<ContentData>(defaultContentData);
  const [loading, setLoading] = useState<boolean>(false);
  const [nodeLevels, setNodeLevels] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [archivedDisabled, setArchivedDisabled] = useState(false);
  const [publishedDisabled, setPublishedDisabled] = useState(false);
  const [draftDisabled, setDraftDisabled] = useState(true);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showModalErro, setShowModalErro] = useState(false);
  const [messageFail, setMessageFail] = useState<{
    title: string;
    message: string;
  }>({
    title: "",
    message: "",
  });
  const [modalProps, setModalProps] = useState<{
    title: string;
    subTitle: string;
    acceptButtonText: string;
    acceptButtonAction: () => void;
    secondaryButtonAction?: () => void;
    secondaryButtonText?: string;
  }>({
    title: "",
    subTitle: "",
    acceptButtonText: "",
    secondaryButtonText: "",
    acceptButtonAction: () => null,
    secondaryButtonAction: () => null,
  });

  const { nodeSelectItem, nodeCurrent } = useNodesTree();
  const { idCompany } = getMenuData();
  const blockEditRedirect = !permissionLeraningPage || ( !!nodeCurrent?.redirect && (!nodeCurrent.idCompany || nodeCurrent.idCompany !== idCompany));
  const _getLearningNode = useCallback(async () => {
    try {
      setLoading(true);
      setLoadingNodeList(true);
      initState();
      const res = await getLearningNode(nodeCode);
      const parrentNodeCode = nodeCode.split(".").slice(0, -1).join(".");
      const resNodeLevels = await getNodeLevels(parrentNodeCode);
      const nodeLevels = resNodeLevels.data.nodeLevels;
      setNodeLevels(nodeLevels);
      const nodeDataResult = res.data.result;
      const contentDataParams = {
        ...nodeDataResult,
        entryPoint: Boolean(nodeDataResult.entryPoint),
        essential: Boolean(nodeDataResult.essential),
        selfEnrollment: Boolean(nodeDataResult.selfEnrollment),
        parentNodeCode: nodeCode,
        id: nodeDataResult.idNode,
        company: { id: nodeDataResult.idCompany },
        status: nodeDataResult.status.toUpperCase(),
        attendanceListAsApprovalCriteria:
          nodeDataResult.attendanceListAsApprovalCriteria === null
            ? false
            : nodeDataResult.attendanceListAsApprovalCriteria,
        meetings: nodeDataResult.hasOwnProperty("meetings") ? nodeDataResult.meetings : [],
        hasChildren: getHasChildren(nodeDataResult.blocks),
        lastNodeLevel: nodeLevels[nodeLevels.length-1]?.nodeLevel,
        nodeLevel: nodeDataResult.nodeLevel  ||  nodeLevels?.[0]?.nodeLevel,
        label: nodeDataResult.label || nodeDataResult.nodeLevelName,
      };

      setContentData(contentDataParams);
      setContentDataBackup(contentDataParams);
      setNodeData(contentDataParams);
    } catch (err) {
      localStorage.removeItem("currentNodeManagement");
      setMessageFail(
        err.response.status === 464
          ? {
              message: "Provavelmente este conteúdo foi removido.",
              title: "Conteúdo não encontrado!",
            }
          : {
              title: "Ocorreu uma falha ao carregar o conteúdo!",
              message:
                "Aguarde alguns minutos e tente novamente, mas caso o problema persista, entre em contato com o suporte.",
            },
      );
      setShowModalErro(true);
      setNodeLevels([])
    } finally {
      setLoadingNodeList(false);

      setLoading(false);
    }
  }, [nodeCode]);

  const MessageErro = () => (
    <ModalDialog
      title={messageFail.title}
      text={messageFail.message}
      variantIco="danger"
      show={showModalErro}
      setShow={setShowModalErro}
      btnDenyCall={() => {
        setMessageFail({
          message: "",
          title: "",
        });
        setShowModalErro(false);
        window.location.reload();
      }}
      btnCall={{
        alertMode: true,
      }}
    />
  );

  const initState = () => {
    setDraftDisabled(true);
    setArchivedDisabled(false);
    setPublishedDisabled(false);
  };
  const getHasChildren = (blocks = []) => {
    if (!blocks.length) {
      return false;
    }

    return blocks
      .flatMap((block) => block.elements || [])
      .some((elements) => elements.elementType === "NODE");
  };

  const _editLearningNode = async (
    payload: {
      [key: string]: number | string | string[] | Meeting[] | boolean | null;
    },
    required?: boolean,
  ) => {
    const { id } = contentData;
    const _payload = payload;
    let noChange = true;
    
    Object.keys(payload).forEach((key) => {
      //recupera valor interior quando o valor for vazio
      if (required && !payload[key]) {
        _payload[key] = contentDataBackup[key];
      }
      //caso o valor seja igual não insere
      const validationValue =
        typeof _payload[key] === "string" && !_payload[key] ? null : _payload[key];
      const value =
        typeof contentData[key] === "string" && !contentData[key] ? null : contentData[key];
      if (validationValue !== value) {
        noChange = false;
        handleChange({ [key]: _payload[key] });
      }
    });
    
    if (noChange) return;

    try {
      await editLearningNode(id, _payload);
      editPropsNodes(_payload);
    } catch (err) {
      console.log(err);
    }
  };


  const _getCertificates = useCallback(async () => {
    const currentNoneCode = contentData.originalNodeCode || nodeCode;
    if (currentNoneCode) {
      const response = await getCertificates(currentNoneCode.split(".")[0]);
      setCertificates(response.data.result || []);
    }
  }, [contentData.originalNodeCode, nodeCode]);

  const handleChange = (data: {
    [key: string]: number | string | string[] | Meeting[] | boolean | Notification[] | null;
  }) => {
    setContentData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const onCreateImage = (imageBaseName) => {
    handleChange({ image: imageBaseName });
    editPropsNodes({ image: imageBaseName });
  };

  const handleAttendanceListAsApprovalCriteria = (param) => {
    editPropsNodes(param);
    _editLearningNode(param);
  };

  const handleValidityChange = (param) => {
    editPropsNodes({ validity: param });
    _editLearningNode({ validity: param });
  };

  const handleVisibilityChange = (param) => {
    // handleChange({  visibility: param });
    // editPropsNodes({  visibility: param });
    // _editLearningNode({  visibility: param });
    // setNodeVisibility(nodeCode, { visibility: param })
  };

  const publish = (publishChildren: boolean) => {
    setNodeStatusToPublished(contentData?.idNode, publishChildren)
      .then(() => {
        nodeSelectItem(contentData);
        handleChange({ status: StatusTypes.PUBLISHED });
        editPropsNodes({ status: StatusTypes.PUBLISHED });
      })
      .catch((err) => {
        console.log("error", err.request);
      });
    setShowStatusModal(false);
  };

  const draftContent = () => {
    setNodeStatusToDraft(contentData?.idNode)
      // .then(_getLearningNode)
      .then(() => {
        nodeSelectItem(contentData);
        handleChange({ status: StatusTypes.DRAFT });
        editPropsNodes({ status: StatusTypes.DRAFT });
      })
      .catch((err) => console.log("error", err.request));

    setShowStatusModal(false);
  };

  const archiveContent = () => {
    setNodeStatusToArchived(contentData?.idNode)
      // .then(_getLearningNode)
      .then(() => {
        nodeSelectItem(contentData);
        handleChange({ status: StatusTypes.ARCHIVED });
        editPropsNodes({ status: StatusTypes.ARCHIVED });
      })
      .catch((err) => console.log("error", err.request));

    setShowStatusModal(false);
  };

  const applyruleNodeType = () => {
    if (contentData.nodeType === nodeType.ORGANIZATION) {
      return {
        secondaryButtonText: "Publicar a página e níveis abaixo",
        secondaryButtonAction: () => publish(true),
        acceptButtonAction: () => publish(false),
        acceptButtonText: "Publicar apenas a página",
      };
    }
    return {
      acceptButtonAction: () => publish(true),
      acceptButtonText: "Publicar a página e níveis abaixo",
    };
  };

  const handleStatusChange = (value: StatusTypes) => {
    switch (value) {
      case StatusTypes.PUBLISHED:
        setShowStatusModal(true);
        setModalProps({
          title: "Você gostaria de publicar está página e as páginas abaixo?",
          subTitle:
            "Ao publicar, todas as páginas serão visíveis para todos os usuários. Após publicadas, não será possível realizar adições ou exclusão de conteúdos nas páginas.",
          ...applyruleNodeType(),
        });
        break;
      case StatusTypes.ARCHIVED:
        setShowStatusModal(true);
        setModalProps({
          title: 'Você tem certeza que deseja arquivar esta página?',
          subTitle:
            'Se prosseguir, a página e todos os níveis abaixo deixarão de ser visíveis e você não poderá mais realizar nenhuma edição dentro dela. Todas as matrículas vigentes serão encerradas e ninguém mais terá acesso à página. Esta ação é irreversível.',
          acceptButtonText: "Sim, Arquivar",
          acceptButtonAction: () => archiveContent(),
        });
        break;
      case StatusTypes.DRAFT:
        setShowStatusModal(true);
        setModalProps({
          title: 'Você tem certeza que deseja colocar o status dessa página para "Rascunho"?',
          subTitle:
            "Ao retornar a página para o status em rascunho, as turmas e matrículas serão excluídas e será necessário cria-las novamente ao republicar a página. Deseja continuar?",
          acceptButtonText: "Sim, colocar para Rascunho",
          acceptButtonAction: () => draftContent(),
        });
        break;
      default:
        return;
    }
  };
  const checkRulesPublished = () => {
    if (!contentData.hasEnrollmentStarted) {
      setDraftDisabled(false);
    }
  };
  const disabledStatus = useCallback(() => {
    switch (contentData.status) {
      case StatusTypes.DRAFT:
        setArchivedDisabled(true);
        setPublishedDisabled(false);
        break;
      case StatusTypes.PUBLISHED:
        setArchivedDisabled(false);
        setPublishedDisabled(false);
        checkRulesPublished();
        break;
      case StatusTypes.ARCHIVED:
        setPublishedDisabled(true);
        setArchivedDisabled(false);
        break;
      default:
        break;
    }
  }, [contentData.status, contentData.hasEnrollmentStarted, contentData.nodeCode]);

  useEffect(() => {
    _getLearningNode();
  }, [_getLearningNode]);



  useEffect(() => {
    _getCertificates();
  }, [_getCertificates]);

  useEffect(() => {
    disabledStatus();
  }, [disabledStatus]);

  const components: {
    title: string;
    component: React.ReactNode;
    condition?: boolean;
    tooltip?: string;
  }[] = [
    {
      title: "Informações básicas",
      condition: true,
      tooltip: "",
      component: (
        <BasicInformation
          disabled={blockEditRedirect}
          contentData={contentData}
          editNodeCode={editNodeCode}
          nodeLevels={nodeLevels}
          handleChange={handleChange}
          handleBlur={_editLearningNode}
          certificates={certificates}
        />
      ),
    },
    {
      title: "Tags",
      condition: true,
      tooltip: "",
      component: (
        <TagsCompoent
        disabled={blockEditRedirect}
          tags={contentData.tags || []}
          handleChange={handleChange}
          handleBlur={_editLearningNode}
        />
      ),
    },
    {
      title: "Imagem",
      condition: true,
      tooltip: "",
      component: <ImageComponent disabled={blockEditRedirect} contentData={contentData} onCreateImage={onCreateImage} />,
    },
    {
      title: "Autor",
      condition: !!contentData.entryPoint,
      tooltip: "",
      component: <AuthorComponent disabled={blockEditRedirect}   handleBlur={_editLearningNode} contentData={contentData} />,
    },
    {
      title: "Encontros",
      condition: contentData.modality === "BLENDED",
      tooltip:
        "Defina aqui um modelo para a quantidade e os tipos de encontros para essa página. Os encontros poderão ser editados na criação da turma.",
      component: (
        <MeetingComponent
        disabled={blockEditRedirect}
          handleChange={handleChange}
          handleBlur={_editLearningNode}
          handleAttendanceListAsApprovalCriteria={handleAttendanceListAsApprovalCriteria}
          contentData={contentData}
        />
      ),
    },
    {
      title: "Configurações adicionais",
      condition: true,
      tooltip: "",
      component: (
        <AdditionalSettingsComponent
        disabled={blockEditRedirect}
          handleStatusChange={handleStatusChange}
          handleValidityChange={handleValidityChange}
          handleBlur={_editLearningNode}
          contentData={contentData}
          setContentData={setContentData}
          publishedDisabled={publishedDisabled}
          archivedDisabled={archivedDisabled}
          draftDisabled={draftDisabled}
          editNodeCode={editNodeCode}
        />
      ),
    },
    {
      title: "Notificações de página",
      condition: nodeType.LEARNING === contentData.nodeType && !!contentData?.notifications?.length,
      tooltip: "",
      component: <NotificationPage  handleChange={handleChange} contentData={contentData} />,
    },
    {
      title: "Avaliação de Impacto",
      condition: nodeType.LEARNING === contentData.nodeType,
      tooltip:
        "Ao habilitar essa opção, quando o aluno finalizar o treinamento será disponibilizado para o gestor uma avaliação de impacto ",
      component: (
        <ImpactEvaluation
          contentData={contentData}
          handleChange={handleChange}
          handleBlur={_editLearningNode}
        />
      ),
    },
  ];
  return loading ? (
    <PlaceholderParameters number={10} />
  ) : (
    <div className="node-parameters">
      <ModalV2
        showModal={showStatusModal}
        setShowModal={(status) => setShowStatusModal(status)}
        acceptButtonText={modalProps.acceptButtonText}
        acceptButtonAction={modalProps.acceptButtonAction}
        secondaryButtonText={modalProps?.secondaryButtonText}
        secondaryButtonAction={modalProps?.secondaryButtonAction}
        title={modalProps.title}
        subTitle={modalProps.subTitle}
      />
      <div className="header">
        <h5>Propriedade da Página</h5>
      </div>
      <Divider />
      {components.map(
        (elem, index) =>
          elem.condition && (
            <AccordionComponent
              key={`accordion-${index}`}
              title={elem.title}
              tooltip={elem.tooltip}
            >
              {elem.component}
            </AccordionComponent>
          ),
      )}
      <MessageErro />
    </div>
  );
};

export default NodeParameters;
