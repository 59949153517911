// Basic
import React, { useEffect, useState } from "react";

import { getMigrateUsers, getDetailsMigrateUsers } from "../../../services/NexiDigitalAPI";

import MovementWrapper from "../Components/MovementWrapper";
import TableComponent from "../Components/TableComponent";
import SemImagemSmall from "../../../assets/images/sem-imagem-small.jpg";
import { CircularProgress } from "@material-ui/core";
import { Modal, ModalBody } from "react-bootstrap";
import transferIcon from "../../../assets/images/transfer.svg";
import Loading from "components/Loading";
import "./style.scss";
import MovementStatus from "../Components/MovementStatus";

const MovementHistory = ({ rel }) => {
  const modalTitle = "Detalhes da Movimentação";
  const [idUserMigration, setIdUserMigration] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [tableDataDetails, setTableDataDetails] = useState([]);
  const [paginationDetails, setPaginationDetails] = useState({
    offset: 0,
    limit: 10,
    totalPage: 999,
  });

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
    totalPage: 999,
  });

  const paginationComponentOptions = {
    rowsPerPageText: "Histórico de movimentação por página",
    rangeSeparatorText: "de",
  };

  const DetailsColumns = [
    {
      name: "Nome",
      selector: "name",
      sortable: true,
      center: true,
      wrap: false,
      grow: 0.3,
      style: { textAlign: "center" },
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
      wrap: true,
      grow: 0.3,
      style: { textAlign: "center" },
    },
    {
      name: "Cargo",
      selector: "role",
      sortable: true,
      center: true,
      wrap: true,
      grow: 0.3,
      style: { textAlign: "center" },
      cell: (data, index) => <p>{data.cargo ?? "-"}</p>,
    },
    {
      name: "Ativo",
      selector: "active",
      sortable: true,
      center: true,
      wrap: true,
      grow: 0.3,
      style: { textAlign: "center" },
      cell: (data, index) => <p>{data.active === 1 ? "Sim" : "Não"}</p>,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      center: true,
      wrap: false,
      // grow: 0.3,
      style: { textAlign: "center" },
      cell: (data, index) => <MovementStatus title={data.status || "-"} />,
    },
  ];

  const columns = [
    {
      name: "Data",
      selector: "creationdate",
      sortable: true,
      center: true,
      wrap: false,
      // grow: 0.3,
      style: { textAlign: "center" },
    },
    {
      name: "Empresa de origem",
      sortable: true,
      selector: "CompanyOrigin",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Empresa de destino",
      sortable: false,
      selector: "CompanyDestination",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Total de usuários processados",
      sortable: false,
      selector: "",
      center: true,
      wrap: false,
      // width: "200px",
      style: { textAlign: "center" },
      cell: (data, index) => <MovementStatus title={data.processedUsers + "/" + data.totalusers} />,
    },
    {
      name: "Status",
      sortable: false,
      selector: "status",
      center: true,
      wrap: false,
      // width: "200px",
      style: { textAlign: "center" },
      cell: (data, index) => <MovementStatus title={data.status || "-"} />,
    },
    {
      name: "Detalhes",
      sortable: false,
      selector: "detallhes",
      center: true,
      wrap: true,
      width: "200px",
      style: { textAlign: "center" },
      cell: (data, index) => (
        <p className="TablelitemVisualizar" onClick={() => openDetails(data.idUserMigration)}>
          Visualizar
        </p>
      ),
    },
  ];

  const getPaginationRowsPerPageOptions = (totalRows) => {
    const options = [];
    if (totalRows <= 10) {
      options.push(totalRows);
      return options;
    }

    for (let i = 10; i < totalRows; i += 10) {
      options.push(i);
    }
    options.push(totalRows);
    return [...new Set(options)];
  };

  const openDetails = (id) => {
    setIdUserMigration(id);
    setShowModal(true);
    dataGetDetailsMigrateUsers(id, {
      ...paginationDetails,
      offset: 0,
      page: 0,
    });
  };

  const closeDetails = () => {
    setShowModal(false);
  };

  const NoDataComponent = () => {
    if (loading && tableData.length === 0) {
      return <CircularProgress size={18} style={{ color: "white" }} />;
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 400,
          width: "100%",
          color: "gray",
        }}
      >
        Não foi encontrado nenhum dado
      </div>
    );
  };

  const onChangePage = (page) => {
    dataGetMigrateUsers({
      ...pagination,
      offset: page === 1 ? 0 : (page - 1) * pagination.limit,
      page,
    });
  };

  const onChangePageDetails = (page) => {
    dataGetDetailsMigrateUsers("", {
      ...paginationDetails,
      offset: page === 1 ? 0 : (page - 1) * paginationDetails.limit,
      page,
    });
  };

  const onChangeRowsPerPage = (limit, page) => {
    dataGetMigrateUsers({
      ...pagination,
      limit,
      offset: page === 1 ? 0 : (page - 1) * limit,
      page,
    });
  };
  const onChangeRowsPerPageDetails = (limit, page) => {
    dataGetDetailsMigrateUsers("", {
      ...paginationDetails,
      limit,
      offset: page === 1 ? 0 : (page - 1) * limit,
      page,
    });
  };

  function dataGetMigrateUsers(params) {
    const queryParams = {
      offset: params?.offset || params?.offset === 0 ? params?.offset : pagination.offset,
      limit: params?.limit || pagination.limit,
    };

    setLoading(true);
    getMigrateUsers(queryParams)
      .then((res) => {
        if (res.status === 200) {
          setPagination({
            ...queryParams,
            totalPage: res.data?.totalRecords || 0,
          });
          setTableData(res.data.migrations);
        }
      })
      .catch((err) => {
        console.log("error ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function dataGetDetailsMigrateUsers(id = "", params) {
    const queryParams = {
      offset: params?.offset || params?.offset === 0 ? params?.offset : paginationDetails.offset,
      limit: params?.limit || paginationDetails.limit,
    };

    if (id) {
      setLoadingDetails(true);
    }
    getDetailsMigrateUsers(id ? id : idUserMigration, queryParams)
      .then((res) => {
        if (res.status === 200) {
          setPaginationDetails({
            ...params,
            totalPage: res.data.migration.totalusers,
          });
          setTableDataDetails(res.data.migration);
        }
      })
      .catch((err) => {
        console.log("error ", err);
      })
      .finally(() => {
        if (id) {
          setLoadingDetails(false);
        }
      });
  }

  useEffect(() => {
    dataGetMigrateUsers({
      ...pagination,
      offset: 0,
      page: 0,
    });
  }, []);

  return (
    <MovementWrapper id="movementHistory">
      <div className="header-item">
        <h2>Histórico de movimentação</h2>
        <span>
          Aqui você poderá trocar os usuários entre empresas. Ao realizar essa movimentação o
          histórico de conclusão do usuário também será transferido
        </span>
      </div>
      <TableComponent
        header={false}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        paginationComponentOptions={paginationComponentOptions}
        loading={loading}
        tableColumn={columns}
        tableData={tableData}
        customTableStyles={{}}
        NoDataComponent={<NoDataComponent />}
        pagination={pagination}
        loadingDataFallBackText="Carregando..."
        extraTableProps={{
          paginationRowsPerPageOptions: getPaginationRowsPerPageOptions(pagination.totalPage),
          paginationDefaultPage: 1,
        }}
      />

      <Modal
        id="movementHistory-modal"
        show={showModal}
        onHide={closeDetails}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size={"xl"}
        className="modal-main-container"
      >
        <div className="modal-support-blur" />

        <ModalBody className="modal-content">
          {!loadingDetails ? (
            <>
              {modalTitle && (
                <>
                  <Modal.Header className="custom-header">
                    <Modal.Title>{modalTitle}</Modal.Title>
                  </Modal.Header>
                  <div className="description-header">
                    <div className="float-left details">
                      <p>
                        Saindo da empresa: <b>{tableDataDetails.CompanyOrigin}</b>
                      </p>
                      <p>
                        Entrando na empresa: <b>{tableDataDetails.CompanyDestination}</b>
                      </p>
                    </div>
                    <div className="float-right processedUsers">
                      <br />
                      <p>
                        Total de usuários processados:{" "}
                        <b>
                          {tableDataDetails.processedUsers}/{tableDataDetails.totalusers}
                        </b>
                      </p>
                    </div>
                  </div>
                </>
              )}

              <TableComponent
                header={false}
                onChangePage={onChangePageDetails}
                onChangeRowsPerPage={onChangeRowsPerPageDetails}
                paginationComponentOptions={paginationComponentOptions}
                loading={loadingDetails}
                tableColumn={DetailsColumns}
                tableData={tableDataDetails?.items}
                customTableStyles={{}}
                NoDataComponent={<NoDataComponent />}
                pagination={paginationDetails}
                loadingDataFallBackText="Carregando..."
                extraTableProps={{
                  paginationRowsPerPageOptions: getPaginationRowsPerPageOptions(
                    paginationDetails.totalPage,
                  ),
                  paginationDefaultPage: 1,
                }}
              />
            </>
          ) : (
            <Loading containerStyle={{ height: 400 }}>Carregando...</Loading>
          )}
        </ModalBody>
      </Modal>
    </MovementWrapper>
  );
};

export default MovementHistory;
