import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { insertTerms, updateTerms, getTermsById, renewLogin, _renewlogin } from "services/NexiDigitalAPI";
import FormTerms from "./../../components/FormTerms/Form";
import ButtonTerms from "./../../components/FormTerms/Button";
import ModalActive from "../../components/Dialogs/ModalActive";
import SnackComponent from "components/SnackComponent";

import "./style.scss";
import HeaderNavigation from "components/PeopleCraft/HeaderNavigation";
import ModalInactive from "../../components/Dialogs/ModalInactive";
import "moment/locale/pt-br";
const NewTerms = () => {

  const { id: idTermOfResponsibility } = useParams();
  const history  = useHistory();

  const [text, setText] = useState("Salvar");
  const [name, setName] = useState("");
  const [active, setActive] = useState(0);
  const [activeInitialEdit, setActiveInitialEdit] = useState(0);
  const [content, setContent] = useState("");
  const [availabilityMode, setAvailabilityMode] = useState("ALL_USERS");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackInfo, setSnackInfo] = useState({
    message: "",
    severity: "success",
  });

  const messageError = (message) => {
    setSnackInfo({
      message: message || "Falha no sistema",
      severity: "error",
    });
    setOpenSnackBar(true);
  };

  const [controlModals, setControlModals] = useState({
    showModals: {
      edit: false,
      inactive: false,
      active: false
    },
    id: 0,
  });

  const closeModal = (key) => {
    setLoading(false);
    setControlModals((prev) => ({
      ...prev,
      showModals: {
        ...prev,
        [key]: false,
      },
    }));
  };

  const handlerConfirmationModal = async (data) => {
    setAvailabilityMode(data)
    closeModal(controlModals.showModals.active ? "active" : "inactive");
    setLoading(true);
    if( idTermOfResponsibility ){
      _updateTerms({availabilityMode: data})
    }else{
      _insertTerms({availabilityMode: data})
    }
  };

  const handlerConfirmationModalActive = async () => {
    closeModal(controlModals.showModals.inactive ? "active" : "inactive");
    setLoading(true);
    if( idTermOfResponsibility ){
      _updateTerms()
    }else{
      _insertTerms()
    }
  };

  const _getData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getTermsById(idTermOfResponsibility);
      setData(result.data);
      setName(result.data.name)
      setActive(result.data.active)
      setActiveInitialEdit(result.data.active)
      setContent(result.data.content)
    } finally {
      setLoading(false);
    }
  }, []);

  const _updateTerms = (data) => {
    updateTerms({idTermOfResponsibility, name, active, content, availabilityMode, ...data})
      .then((res) => {
        if (res.status === 200) {
          _renewlogin();
          history.push({pathname:`/configuracao/termos-da-plataforma/`, state: { success: true }});
        }
      })
      .catch((error) => {
        setLoading(false)
        messageError(error?.response?.data?.message);
      });
  };

  const _insertTerms = (data) => {
    insertTerms({name, active, content, availabilityMode, ...data})
      .then((res) => {
        if (res.data.result) {
          _renewlogin();
          history.push({pathname:`/configuracao/termos-da-plataforma/`, state: { success: true }});
        }
      })
      .catch((error) => {
        setLoading(false)
        messageError(error?.response?.data?.message);
      });
  };

  const handlerCancel = () => {
    history.push(`/configuracao/termos-da-plataforma/`);
  }

  const handlerConfirmation = () => {
    if( active === 1 ){
      setControlModals((prev) => ({
        ...prev,
        showModals: {
          ...prev,
          active: true
        },
      }));
    }else{
      if( activeInitialEdit === 1 ){
        setControlModals((prev) => ({
          ...prev,
          showModals: {
            ...prev,
            inactive: true
          },
        }));
      }else{
        setLoading(true);
        if( idTermOfResponsibility ){
          _updateTerms()
        }else{
          _insertTerms()
        }
      }
    }
  }

  useEffect(() => {
    if(idTermOfResponsibility){
      _getData();
    }
  }, [_getData]);

  useEffect(() => {
    if( Boolean(active) ){
      setText("Salvar e configurar exibição")
    }else{
      setText("Salvar")
    }
  }, [active]);

  return(
    <div className="pageTerms">
      <div className="form-group">
          <div>
          <HeaderNavigation title={`Termos da plataforma - ` + ( idTermOfResponsibility ? "Editar termo":"Cadastrar novo termo")} />
            <h4 className="term-title"><span style={{ color: "red" }}>*</span>Termos de responsabilidade</h4>
            <p className="term-subtitle">Crie e personalize seu termo no espaço abaixo!</p>
          </div>
        <div className="card">
          <div className="card-body template-card-body row">
            {loading ? (
                <div className="loaderTerms">
                  <div className="preloaderIt"></div>
                </div>
              ) : (data.length == 0 && idTermOfResponsibility) ? (
                <p>Termo não encontrado</p>
              ) : (
                <FormTerms
                  name={name}
                  setName={setName}
                  active={active}
                  setActive={setActive}
                  content={content}
                  setContent={setContent}
                />
            )}
          </div>
        </div>
        <div className="float-left"><span style={{ color: "red" }}>*</span> Campos obrigatórios</div>
        <div className="row pr-3 float-right" style={{ padding: 5, justifyContent: "flex-end", gap: 20 }} >
          {!loading && (data.length != 0 || !idTermOfResponsibility) &&
            <ButtonTerms handlerButtonConfirmation={() => handlerConfirmation()} handlerButtonCancel={() => handlerCancel()} text={text} />
          }
        </div>
      </div>

      <ModalActive
        defaultValue={availabilityMode}
        showModal={controlModals.showModals.active}
        closeModal={() => {
          closeModal("active");
        }}
        handleClickConfirmation={(value) =>
          handlerConfirmationModal(value)
        }
      />
      <ModalInactive
        showModal={controlModals.showModals.inactive}
        closeModal={() => {
          closeModal("inactive");
        }}
        handleClickConfirmation={() =>
          handlerConfirmationModalActive()
        }
      />
      <SnackComponent
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        message={snackInfo.message}
        severity={snackInfo.severity}
      />

    </div>
  );

}

export default NewTerms;
