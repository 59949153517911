import React, { useCallback, useEffect, useState } from "react";

import ModalDialog from "../../../Modals/ModalDialog";

import ExternalCourseInclusionAcordeon from "../ExternalCourseInclusionAcordeon";
import { getFeatures, setFeatures, getFeaturesProfile, setFeaturesProfile } from "services/NexiDigitalAPI";

const ExternalCourseInclusion = () => {
  const [startExpanded] = useState(false);
  const [statusRequest, setStatusRequest] = useState(false);
  const [immediateManager, setImmediateManager] = useState(false);
  const [showModalDisableRequest, setShowModalDisableRequest] = useState(false);

  const toggleFeature = useCallback((active) => {
    setFeatures('EXTERNAL_COURSES', active);
    setStatusRequest(active);
  }, []);

  const toggleFeatureProfile = useCallback((active) => {
    setFeaturesProfile('EXTERNAL_COURSES', 'MANAGER', active);
    setImmediateManager(active);
  }, []);

  useEffect(() => {
    getFeatures('EXTERNAL_COURSES').then((featureResult) => {
      if (featureResult?.data?.active) {
        setStatusRequest(true);
      }
    });

    getFeaturesProfile('EXTERNAL_COURSES', 'MANAGER').then((featureProfileResult) => {
      if (featureProfileResult?.data?.active) {
        setImmediateManager(true);
      }
    });
  }, []);

  return (
    <>
      <ModalDialog
        title={"Desativar solicitação de cursos externos?"}
        text={
          "Ao desativar esta funcionalidade, os alunos não poderão mais solicitar a inclusão de cursos externos na plataforma. Deseja continuar?"
        }
        show={showModalDisableRequest}
        setShow={setShowModalDisableRequest}
        btnDenyCall={() => null}
        btnCall={{
          className: "",
          variantBtn: "danger",
          text: "Sim, desativar",
          callEvent: () => {
            toggleFeature(false);
            setShowModalDisableRequest(false);
          },
        }}
      />
      <ExternalCourseInclusionAcordeon
        statusRequest={statusRequest}
        setStatusRequest={toggleFeature}
        immediateManager={immediateManager}
        setImmediateManager={toggleFeatureProfile}
        setShowModalDisableRequest={setShowModalDisableRequest}
        startExpanded={startExpanded}
      />
    </>
  );
};

export default ExternalCourseInclusion;
