import React from "react";
import "./style.scss";


import { useManagerTrial } from "../../context/contextManagerTrial";
import { CardUpgradeProps, Status } from "../../model/ManagerTrial.model";
import Button from "components/PeopleCraft/Button";
import CheckListUpgrade from "../CheckListUpgrade";

const CardUpgrade = ({
  elem,
  showDetail = false,
}: CardUpgradeProps) => {
  const { name, description, features, products } = elem;
  const { setUpgradeStatus, setCurrentCardUpgrade } = useManagerTrial();

  const showView = (status) => {
    setCurrentCardUpgrade(elem);
    setUpgradeStatus(status);
  };

  return (
    <div>
      <div className={`pctCardUpgrade`}>
        <div className="cardContent">
          <h2 >
            Plano {name}
          </h2>
          {description && <p className="subtitle">{description}</p>}
          {/* <p className="price colorWhite">
            {formatValue(item ? item.price.pricePerUser : 0)}
            <span> / por usuário mês</span>
          </p> */}
          <Button
            callEvent={(e) => {
              showView(Status.form);
            }}
            className={"button05 button-cofing"}
            text={`${
              name !== "Enterprise" ? "Começar trial" : "Entrar em contato"
            }`}
          />
          <p className="terms">
            Ao clicar no botão acima, você aceita os
            <span
              onClick={(e) => {
                showView(Status.terms);
              }}
            >
              {" "}
              termos e condições
            </span>{" "}
            de Learning Hub.
          </p>
          <div className="hr"></div>

          <CheckListUpgrade
            text="Crie e edite seus próprios treinamentos"
            features={features || []}
            products={products || []}
            showDetail={showDetail}
          />
        </div>
      </div>
    </div>
  );
};

export default CardUpgrade;
