import jsPDF from "jspdf";
import "jspdf-autotable";

import { loadImageAsBase64 } from "./LoadImageAsBase64";

function pxToPt(px) {
  const pt = px * (72 / 96); // ou px * 0.75
  return pt;
}

// Função para desenhar o cabeçalho em todas as páginas
const addHeader = (doc, dimensions, modelHeader, imgBase64, maxHeight) => {
  const paddingX = 30;
  const { page, meeting, dateCompletion, hoursDifference, address, speaker, primaryColor } =
    modelHeader;

  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text("Lista de presença", paddingX + 310, 30);

  const headerTableData = [
    ["Nome do treinamento:", page],
    ["Nome da turma:", meeting],
    ["Data e hora de realização:", dateCompletion],
    ["Carga horária do encontro:", hoursDifference],
    ["Local:", address],
    ["Instrutor:", speaker],
  ];

  const colorFundo = primaryColor;
  const colorLine = [186, 186, 186];

  doc.autoTable({
    theme: "plain",
    margin: { top: 50, left: paddingX },
    head: [["", ""]],
    body: headerTableData,
    styles: {
      fontSize: 10,
      cellPadding: 3,
      lineColor: colorLine,
      lineWidth: 0.1,
    },
    headStyles: {
      fillColor: colorFundo,
      lineWidth: 0,
      cellPadding: 30, // Espaçamento interno
      // halign: "center",
      valign: "middle",
    },
    columnStyles: {
      0: { fontStyle: "bold", halign: "left", cellWidth: 200 },
      1: { halign: "left", cellWidth: 580 },
    },
    didDrawCell: (data) => {
      if (data.section === "head" && data.column.index === 0) {
        if (imgBase64) {
          const wImg = pxToPt(dimensions.width) || 0;
          const hImg = pxToPt(dimensions.height) || 0;
          // console.log("wImg, hImg", wImg, hImg);
          // Adiciona a imagem ao PDF com as dimensões ajustadas

          // Coordenadas para centralizar a imagem na célula
          // const xOffset = data.cell.x + (data.cell.width - imgWidth) / 2;
          const yOffset = data.cell.y + (data.cell.height - hImg) / 2;
          doc.addImage(imgBase64, "PNG", data.cell.x + 10, yOffset, wImg, hImg);
        }
      }
    },
    didParseCell: (data) => {
      if (data.section === "head") {
        const hImg = dimensions.height || 0;
        data.row.height = hImg + 20;
        data.cell.styles.fillColor = colorFundo;
      }
    },
  });
};

// Função para desenhar o rodapé em todas as páginas
const addFooter = (doc, dateNow, author) => {
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const pages = doc.internal.getNumberOfPages();

  doc.setFontSize(8);

  for (let j = 1; j <= pages; j++) {
    doc.setPage(j);
    doc.text(`Arquivo gerado em: ${dateNow} - por: ${author}`, 30, pageHeight - 10);
    doc.text(`Página ${j}/${pages}`, pageWidth - 30, pageHeight - 10, { align: "right" });
  }
};

// Função principal para gerar o PDF
export const CreatePDF = (modelHeader, listRecursive) => {
  const doc = new jsPDF("l", "pt");
  const paddingX = 30;
  const maxWidth = 250;
  const maxHeight = 60;

  const { primaryColor, fourthColor, logoUrl: imageUrl } = modelHeader;

  // const imageUrl =
  //   "https://dev.peoplecraft.com.br/common/app0000001/content/image/Learning%20Hub-logo-1710872022453.png";

  // console.log(logoUrl);

  const generatePDF = async () => {
    try {
      const { imgBase64, dimensions } = await loadImageAsBase64(imageUrl, maxWidth, maxHeight);

      const tableColumn = [
        "CÓD. COLABORADOR",
        "NOME",
        "CARGO",
        "UNIDADE",
        "DEPARTAMENTO",
        "ASSINATURA",
      ];

      const tableRows = listRecursive.map((item) => [
        item.employeeCode,
        item.name,
        item.role,
        item.unit,
        item.department,
        item.signature,
      ]);

      const columnWidths = [113, 150, 100, 90, 110, 217];

      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        theme: "grid",
        margin: { top: 250, left: paddingX },
        styles: {
          fontSize: 10,
          cellPadding: 3,
          lineColor: [186, 186, 186],
          lineWidth: 0.1,
        },
        headStyles: {
          fillColor: primaryColor,
          textColor: fourthColor,
          fontStyle: "bold",
          fontSize: 8,
          halign: "center",
          valign: "middle",
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          halign: "center",
          valign: "middle",
        },
        columnStyles: {
          0: { cellWidth: columnWidths[0], halign: "center" },
          1: { cellWidth: columnWidths[1], halign: "center" },
          2: { cellWidth: columnWidths[2], halign: "center" },
          3: { cellWidth: columnWidths[3], halign: "center" },
          4: { cellWidth: columnWidths[4], halign: "center" },
          5: { cellWidth: columnWidths[5], halign: "center" },
        },
        didDrawPage: () => {
          addHeader(doc, dimensions, modelHeader, imgBase64, maxHeight);
        },
      });

      addFooter(doc, modelHeader.dateNow, modelHeader.author);
      doc.save(`lista_presenca_${new Date().getTime()}.pdf`);
    } catch (error) {
      console.error("Erro ao gerar o PDF:", error);
    }
  };

  generatePDF();
};
