import React, { Fragment, useCallback, useEffect, useState } from "react";
import RowInfo from "../RowInfo";
import SemImagemSmall from "../../../../../assets/images/sem-imagem-small.jpg";
import DataTable from "react-data-table-component";
import { Tooltip } from "@material-ui/core";
import moment from "moment";
import { CgArrowTopRightR } from "react-icons/cg";
import './styles.scss'
import { getImpactEvulations } from "services/NexiDigitalAPI";
import { useHistory } from "react-router-dom";
export enum Status {
  NOTSTARTED = "NotStarted",
  PENDING = "PENDING",
  DENIED = "DENIED",
  APPROVED = "APPROVED",
  ALL = "ALL",
}

const StatusConfig = {
  [Status.NOTSTARTED]: {
    color: "88, 88, 88",
    label: "Não iniciado",
  },
  [Status.PENDING]: {
    color: "196, 103, 26",
    label: "Em andamento",
  },
  [Status.DENIED]: {
    color: "180, 35, 24",
    label: "Reprovado",
  },
};
const AccordionTable = ({item, idUser}) => {

  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [data, setData] = useState([])
  const  toggle = (e) => {
    isExpanded ? setIsExpanded(false) : setIsExpanded(true);

    e.stopPropagation();
  }

  const CustomNoDataComponent = () => (
    <div style={{ textAlign: "center" }}>
      <p>Sem itens encontrados</p>
    </div>
  );

  const IconStatus = ({ status }) => (
    <div
      className="body-status"
      style={{ backgroundColor: `rgba(${StatusConfig[status].color}, 0.14)` }}
    >

      <div className="span" style={{ color: `rgba(${StatusConfig[status].color})` }}>
        {StatusConfig[status].label}
      </div>
    </div>
  );

  const seachDateTable =  useCallback(async () => {
    setLoading(true)
    try{
      const result = await getImpactEvulations(idUser)
      setData(result.data)
    }catch(e){

    }finally{
      setLoading(false)

    }
  }, [idUser])


  useEffect(() => {
    if(isExpanded){
      seachDateTable()
    }
  }, [isExpanded, seachDateTable])


  const columns = [
    {
      name: "Treinamento",
      selector: "title",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Disponível em",
      selector: "creationDate",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: ({ creationDate }) => (
        <div>
          <span>{creationDate ? moment(creationDate).format("MMM D, YYYY") : ""}</span>
        </div>
      ),
    },
    {
      name: "Prazo",
      selector: "startDate",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: ({ startDate }) => (
        <div>
          <span>{startDate ? moment(startDate).add(30, 'days').format("MMM D, YYYY") : ""}</span>
        </div>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: ({ startDate }) => <IconStatus status={startDate ? Status.PENDING : Status.NOTSTARTED} />,

    },

    {
      name: "Ação",
      sortable: false,
      selector: "idGroups",
      width: '200px',
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: ({idQuestionnaire, idUser}) => (

        <div className="groupActions" key={idQuestionnaire}>
          <Tooltip title="Ir para avaliação" placement="top">
            <button

                 className="btn btn-sm float-right icon-leave"
                 onClick={() => {
                  history.push(`/impact-evaluation/${idQuestionnaire}/${idUser}`)
                }}
            >
              <CgArrowTopRightR  />
            </button>

          </Tooltip>
        </div>
      ),
    },
  ]


  return (
    <div className="AccordionTable">
      <div
        className={`titleAccordion ${isExpanded && 'border-open'}` }
        onClick={(e) => {
          toggle(e);
        }}
      >
        <RowInfo isExpanded={isExpanded} role={item.role} image={item.photo || SemImagemSmall} name={item.name}/>
      </div>
      {isExpanded && (
        <div className={`collapseAccordion`}>
              <DataTable
                progressPending={loading}
                columns={columns}
                data={data}
                paginationTotalRows={data.length}
                noDataComponent={<CustomNoDataComponent />}
              />
        </div>
      )}
    </div>
  );
}


export default AccordionTable;
