import React, { useState } from "react";
import "./style.scss";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { Form } from "react-bootstrap";
import { ContentData, StatusTypes } from "../../nodeParamenters.model";
import Label from "../../../Label";
import Switch from "react-bootstrap/esm/Switch";

export interface Meeting {
  meetingType: "ONLINE" | "PRESENCIAL";
  id?: number
}

type Props = {
  contentData: ContentData,
  handleChange: (data: { [key: string]: Meeting[] | number},) => void;
  handleBlur: (data: { [key: string]: Meeting[] | number},) => void;
  handleAttendanceListAsApprovalCriteria: any;
  disabled: boolean

}

const MeetingComponent: React.FC<Props> = ({contentData, disabled = false, handleChange, handleBlur}) => {
  const [meetings, setMeetings] = useState<Meeting[]>(contentData.meetings);
  const [newMeeting, setNewMeeting] = useState<Meeting>({
    meetingType: "ONLINE",
  });
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const addMeeting = () => {
    if(disabled){
      return
    }
    if (editingIndex !== null) {
      const updatedMeetings = [...meetings];
      updatedMeetings[editingIndex] = newMeeting;
      setMeetings(updatedMeetings);
      setEditingIndex(null);
      handleBlur({meetings: updatedMeetings})
    } else {
      if (newMeeting.meetingType.trim() !== "") {
        setMeetings((afterState) =>  [...afterState, {...newMeeting}]);
        handleBlur({meetings: [...meetings, {...newMeeting}]})
      }
    }

    // Limpar os valores do novo encontro
    setNewMeeting({
      meetingType: "ONLINE",
    });
  };

  const editMeeting = (index: number) => {
    if(disabled){
      return
    }
    // Preencher o seletor e o campo de entrada com os valores da reunião selecionada para edição
    setNewMeeting(meetings[index]);
    setEditingIndex(index);
  };

  const deleteMeeting = (index: number) => {
    if(disabled){
      return
    }
    const updatedMeetings = [...meetings];
    updatedMeetings.splice(index, 1);
    setMeetings(updatedMeetings);
    handleChange({meetings: updatedMeetings})
    handleBlur({meetings: updatedMeetings})
  };

  return (
    <div className="meeting-component">
      <div className="meeting-row">
        <Form.Control
         disabled={disabled || contentData?.status === "ARCHIVED"}
          as="select"
          value={newMeeting.meetingType}
          onChange={(e: any) => {
            setNewMeeting({ ...newMeeting, meetingType: e.target.value as Meeting["meetingType"] });
          }}
        >
          <option value="ONLINE">Online</option>
          <option value="PRESENCIAL">Presencial</option>
        </Form.Control>
        <button onClick={addMeeting} className={`${disabled && 'disabled-button'}`}>
          <FaPlus />
        </button>
      </div>

      {meetings.map((meeting, index) => (
        <div className="meeting-row border-bottom" key={index}>
          <span className="meeting-text">{meeting.meetingType}</span>
          <div>
            <FiEdit  color={'#6b48ff'} onClick={() => editMeeting(index)} />
            <FaRegTrashAlt color={'#fa9191'} onClick={() => deleteMeeting(index)} />
          </div>
        </div>
      ))}
          <div className="form-data flex-row">
          <Switch
            color="primary"
            disabled={disabled}
            checked={contentData.attendanceListAsApprovalCriteria === 1}
            id='attendanceListAsApprovalCriteria'
            onChange={(e) => {

              handleBlur({
                attendanceListAsApprovalCriteria: e.target.checked ? 1 : 0,
              });
            }}
          />
          <Label
            className={"cursor-pointer"}
            name=" Contabilizar a lista de presença como critério de aprovação"
            htmlFor="attendanceListAsApprovalCriteria"
          />
        </div>
    </div>
  );
};

export default MeetingComponent;
