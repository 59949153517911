// Basic
import React, { useState, useEffect, useRef, useContext } from "react";
import moment from "moment";
import "moment/locale/pt-br.js";
import { colors } from "../../../utils/Colors";
// APIs
// Utils
import * as Utils from "../../../utils/Utils";
// Page UI and icons
import styles from "./styles.module.scss";
import "rc-datepicker/lib/style.css";
import { AuthContext } from "../../../context/AuthContext";
import "react-data-table-component-extensions/dist/index.css";
import GeneralLookDays from "./components/GeneralLookDays";
import GeneralLook from "./components/GeneralLook";
import FilterDashboard from "./components/FilterDashboard";
import BestRankingAccess from "./components/BestRankingAccess";
import ContentAccess from "./components/ContentAccess";
import LessContentAccess from "./components/LessContentAccess";
import MoreAccessedUser from "./components/MoreAccessedUser";

export default function Reports() {
  const { loginData } = useContext(AuthContext);
  const fourthColor = loginData.fourthColor;

  const [dateGlobal, setDateGlobal] = useState([]);
  const [periodGlobal, setPeriodGlobal] = useState("0");
  const [idGroupGlobal, setIdGroupGlobal] = useState();

  const changeDateGlobal = (value) => {
    setDateGlobal(value);
  };
  const changePeriodFilterGlobal = (value) => {
    setPeriodGlobal(value);
  };
  const changeIdGroupGlobal = (value) => {
    setIdGroupGlobal(value);
  };

  // Render
  return (
    <div className="Reports">
      <FilterDashboard
        changePeriodFilterGlobal={changePeriodFilterGlobal}
        changeDateGlobal={changeDateGlobal}
        changeIdGroupGlobal={changeIdGroupGlobal}
      />
      <GeneralLook filter={{ periodGlobal, dateGlobal, idGroupGlobal }} />
      <GeneralLookDays filter={{ periodGlobal, dateGlobal, idGroupGlobal }} />

      <div className={styles.row_block}>
        <ContentAccess filter={{ periodGlobal, dateGlobal, idGroupGlobal }} />
        <LessContentAccess filter={{ periodGlobal, dateGlobal, idGroupGlobal }} />
        <BestRankingAccess filter={{ periodGlobal, dateGlobal, idGroupGlobal }} />
        <MoreAccessedUser filter={{ periodGlobal, dateGlobal, idGroupGlobal }} />
      </div>
    </div>
  );
}
