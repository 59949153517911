import "./styles.modules.scss";

import Loading from "components/Loading";
import React, { useEffect, useState } from "react";
import { Tooltip, withStyles } from "@material-ui/core";
import { Button, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { BsFilter } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";

import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";
import { getLearningQuestions, deleteQuestion } from "services/NexiDigitalAPI";

import ModalCreateQuestion from "../../../PeopleCraft/Modals/ModalCreateQuestion";
import ModalDialog from "../../../PeopleCraft/Modals/ModalDialog";

import {
  removeHtmlTags,
  getQuestionTypeName,
  getEvaluationTypeName,
  convertToBrDate,
} from "utils/Utils";

import Options from "./Components/Options";

import HeaderCrumb from "../../Components/HeaderCrumb";

const NoData = () => {
  return <div className="noData">Nenhum registro encontrado</div>;
};

const HighlightText = ({ highlight, value }) => {
  const limit = 100;
  let newText = value !== null ? value : "";

  if (limit && value !== null) {
    newText = value.length > limit ? `${newText.substr(0, limit)}...` : value;
  }

  return <div>{getHighlightedText(newText, value, highlight)}</div>;
};

function getHighlightedText(textLimit, text, highlight) {
  // Split text on highlight term, include term itself into parts, ignore case
  var parts = text.split(new RegExp(`(${highlight})`, "gi"));

  // console.log(parts, highlight, highlight.length);

  return highlight.length > 0
    ? parts.map((part, index) => (
        <React.Fragment key={index}>
          {part.toLowerCase() === highlight.toLowerCase() ? (
            <b style={{ backgroundColor: "#e8bb49" }}>{part}</b>
          ) : (
            part
          )}
        </React.Fragment>
      ))
    : textLimit;
}

const customTableStyles = {
  rows: {
    style: {
      minHeight: "auto",
      padding: "5px 0px",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      textAlign: "center",
      fontSize: "14px",
      fontWeight: "bold",
    },
  },
};

const paginationComponentOptions = {
  rowsPerPageText: "Conteúdos por página",
  rangeSeparatorText: "de",
};

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "14px",
  },
}))(Tooltip);

const BankQuestions = () => {
  const history = useHistory();

  const { evaluationType } = useParams();

  const [availableQuestionsList, setAvailableQuestionsList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [type, setType] = useState("");
  const [loadingAvailableQuestionList, setLoadingAvailableQuestionList] = useState(false);
  const [offset, setOffset] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [idQuestionEdit, setIdQuestionEdit] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);

  const [selectItem, setSelectItem] = useState(null);
  const [showCreateQuestion, setShowCreateQuestion] = useState(false);

  const editQuestion = (e, question) => {
    console.log(question);
    setIdQuestionEdit(question.idQuestion);
    setShowCreateQuestion(true);
  };

  const deleteItem = (e, res) => {
    setSelectItem(res);
  };

  const trashConfirmEvent = (e) => {
    // console.log(selectItem);
    //setSelectItem(null);
    setLoadingAvailableQuestionList(true);
    deleteQuestion(selectItem.idQuestion).then(() => {
      getAvailableQuestions(0, 10);
    });
    setSelectItem(null);
  };

  const columns = [
    {
      name: "Enunciado",
      selector: "statement",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (question) => (
        <HighlightText value={removeHtmlTags(question.statement)} highlight={searchString} />
      ),
    },
    {
      name: "Tags",
      sortable: false,
      selector: "tags",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (question) => (
        <div>
          {question.tags?.map((tag, index) => (
            <div className="tagItns">
              <span key={index}>{tag.tag}</span>
            </div>
          ))}
        </div>
      ),
    },
    {
      name: "Tipo da questão",
      sortable: false,
      selector: "questionType",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (question) => <div>{getQuestionTypeName(question.questionType)}</div>,
    },
    {
      name: "Data da criação",
      sortable: false,
      selector: "creationDate",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (question) => <div>{convertToBrDate(question.creationDate)}</div>,
    },
    {
      name: "Criado por",
      sortable: false,
      selector: "createdByName",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (question) => <div>{question.createdByName}</div>,
    },
    {
      name: "Aparece em",
      sortable: false,
      selector: "totalEvaluation",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (question) => (
        <div>
          {question.totalEvaluation} {question.totalEvaluation > 1 ? "avaliações" : "avaliação"}
        </div>
      ),
    },
    {
      name: "Ação",
      selector: "checkbox",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (question) => (
        <div className="btnsList">
          <CustomTooltip
            title={"Editar"}
            placement="top"
            disableHoverListener={Boolean(question.hasQuestionnaire)}
          >
            <Button
              className={`${Boolean(question.hasQuestionnaire) ? "disabledBtn" : ""}`}
              onClick={(e) => {
                editQuestion(e, question);
              }}
              disabled={Boolean(question.hasQuestionnaire)}
            >
              <FaRegEdit style={{ fontSize: 22, marginRight: 3 }} />
            </Button>
          </CustomTooltip>
          <CustomTooltip title={"Deletar"} placement="top">
            <Button
              className={`${Boolean(question.hasQuestionnaire) ? "disabledBtn" : ""}`}
              onClick={(e) => {
                deleteItem(e, question);
              }}
              disabled={Boolean(question.hasQuestionnaire)}
            >
              <FiTrash2 style={{ fontSize: 22, marginRight: 3 }} />
            </Button>
          </CustomTooltip>
        </div>
      ),
    },
  ];

  const getAvailableQuestions = async (newOffset, limit) => {
    setLoadingAvailableQuestionList(true);

    const haveNewOffset = newOffset || newOffset === 0;
    if (haveNewOffset) {
      setOffset(newOffset);
    }

    const questionsParameters = {
      searchString,
      offset: haveNewOffset ? newOffset : offset,
      limit: limit ? limit : pageLimit,
      type,
      evaluationType: evaluationType,
      includeEvaluationCount: true,
    };
    const questionsData = await getLearningQuestions(questionsParameters).catch((err) => {
      console.log("error", err);
    });
    setLoadingAvailableQuestionList(false);
    if (questionsData) {
      setAvailableQuestionsList(questionsData?.result);
      setTotalRecords(questionsData.totalRecords);
    }
  };

  const onChangePage = (page) => {
    setPageNumber(page);
    const newOffset = (page - 1) * pageLimit;
    getAvailableQuestions(newOffset);
  };

  const onChangeRowsPerPage = (limit, page) => {
    setPageLimit(limit);
    const newOffset = (page - 1) * limit;
    getAvailableQuestions(newOffset, limit);
  };

  useEffect(() => {
    getAvailableQuestions(0, 10);
  }, []);

  const createQuestionComplete = () => {
    setPageNumber(0);
    setIdQuestionEdit(null);
    setOffset(0);
    setTotalRecords(0);
    getAvailableQuestions(0, 10);
  };

  const HeaderCrumbLink = () => {
    // return "Gestão de Avaliações";
    return (
      <>
        <Link to={`/evaluation-management`}>Gestão de Avaliações</Link>
        <span className="headerCrumbTxt">{"/"}</span>
        <Link to={`/gestao-de-avaliacoes/evaluation-control/${evaluationType}`}>
          Avaliação de {getEvaluationTypeName(evaluationType)}
        </Link>
        <span className="headerCrumbTxt">{"/ Banco de Questões"}</span>
      </>
    );
  };

  return (
    <>
      <HeaderCrumb>
        <HeaderCrumbLink />
      </HeaderCrumb>
      <div className="questionBank">
        <div className="modal-content">
          <div className="modal-body-warning">
            <div className="form-group form-group-block-02">
              <div className="card">
                <div className="card-body template-card-body">
                  <h4>
                    Banco de questões para Avaliação de {getEvaluationTypeName(evaluationType)}
                  </h4>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="containInfo">
                        <div className="searchInfo">
                          <AiOutlineSearch />
                          <Form.Control
                            value={searchString}
                            onKeyDown={(input) => {
                              if (input.key === "Enter") {
                                getAvailableQuestions();
                              }
                            }}
                            onChange={(e) => setSearchString(e.target.value)}
                            placeholder={`Pesquisar...`}
                          />
                          <button onClick={() => setSearchString("")}>
                            <AiOutlineClose />
                          </button>
                        </div>
                        <div className="typeQuestion">
                          <Form.Control
                            className=""
                            as="select"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                          >
                            <Options evaluacionType={evaluationType} />
                          </Form.Control>
                        </div>
                        <div className="listBtns">
                          <Button
                            variant="primary"
                            onClick={() => {
                              setPageNumber(0);
                              getAvailableQuestions();
                            }}
                          >
                            <BsFilter />
                            Filtrar
                          </Button>
                          <Button variant="warning" onClick={() => setShowCreateQuestion(true)}>
                            Criar nova questão
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group form-group-block-02">
              <div className="card">
                <div className="card-body template-card-body">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="node-manager-import-table-wrapper">
                        {loadingAvailableQuestionList && availableQuestionsList?.length > 0 && (
                          <div className="KELoadingBackdrop">
                            <Loading>Carregando questões...</Loading>
                          </div>
                        )}
                        <DataTable
                          columns={columns}
                          data={availableQuestionsList}
                          customStyles={customTableStyles}
                          progressComponent={
                            <Loading containerStyle={{ height: 400 }}>
                              Carregando questões...
                            </Loading>
                          }
                          noDataComponent={<NoData />}
                          onChangePage={onChangePage}
                          onChangeRowsPerPage={onChangeRowsPerPage}
                          paginationTotalRows={totalRecords}
                          paginationComponentOptions={paginationComponentOptions}
                          progressPending={
                            loadingAvailableQuestionList && availableQuestionsList?.length === 0
                          }
                          paginationServer
                          noHeader
                          fixedHeader
                          pagination
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="containerBtn">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 baseBtns">
                  {/* <div className="errorText">{importError}</div> */}
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      history.push(`/gestao-de-avaliacoes/evaluation-control/${evaluationType}`);
                    }}
                    style={{ zIndex: 10 }}
                  >
                    Voltar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalCreateQuestion
        title={`${idQuestionEdit ? "Editar questão" : "Adicionar questão"}`}
        show={showCreateQuestion}
        setShow={setShowCreateQuestion}
        returnContentData={createQuestionComplete}
        setQuestionsList={null}
        questionsList={null}
        contextTagsInit={null}
        idQuestion={idQuestionEdit ? idQuestionEdit : null}
        evaluationModel={{ evaluacionType: evaluationType }}
        onCloseModal={createQuestionComplete}
        pageNumber={pageNumber}
      />

      <ModalDialog
        text={"Tem certeza de que deseja deletar essa questão?"}
        show={!!selectItem}
        setShow={setSelectItem}
        btnCall={{
          className: "",
          variantBtn: "danger",
          text: "Deletar",
          callEvent: () => {
            trashConfirmEvent();
          },
        }}
      />
    </>
  );
};

export default BankQuestions;
