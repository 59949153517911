import "./styles.scss";
import React, { useState, useEffect, useRef } from "react";
import { withRouter, useParams } from "react-router-dom";
import _ from "underscore";

import { Form, InputGroup } from "react-bootstrap";
import {
  getGroupsAutoRegistrationAPIPromises,
  postAddGroupPromises,
  deleteGroupsInTeamsPromises,
  getGroupsInTeamsEnrollInfoPromises,
  putActivateAutoEnrollPromises,
} from "./Fetch/GroupsAutoRegistrationAPI";
import DataTable from "react-data-table-component";

import { RiDeleteBin5Line } from "react-icons/ri";

import { FormControlLabel, Switch, Tooltip } from "@material-ui/core";
import { Button } from "react-bootstrap";

import PreloaderBlur from "../../../../PeopleCraft/PreloaderBlur";
import ModalDialog from "../../../../PeopleCraft/Modals/ModalDialog";

const GroupsAutoRegistration = (props) => {
  const { idTeam } = useParams();

  const { autoEnrollProcess, setAutoEnrollProcess, disabled } = props;

  const [preloadInit, setPreloadInit] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const showModalConfirmParamiters = useRef({
    text: "",
    variantBtn: "danger",
    textBtn: "",
    className: "",
    fnc: null,
  });

  const [list, setList] = useState(null);
  const [listSelect, setListSelect] = useState([]);

  const openConfirmModal = (objParamiters) => {
    showModalConfirmParamiters.current = objParamiters;
    setShowModalConfirm(true);
  };

  const NoPublic = () => {
    return <div className="noPublicTarget">Sem público-alvo</div>;
  };

  const filterListInit = (promise) => {
    const listAll = promise[0];
    const listSelect = promise[1];

    setListSelect(listSelect.data);

    let listAllGroups = listAll.data.groups;
    let listRemoveGroups = listSelect.data;
    for (var i = listAllGroups.length - 1; i >= 0; i--) {
      for (var j = 0; j < listRemoveGroups.length; j++) {
        if (listAllGroups[i] && listAllGroups[i].idGroups === listRemoveGroups[j].idGroups) {
          listAllGroups.splice(i, 1);
        }
      }
    }

    setList(_.sortBy(listAllGroups, "title"));
  };

  useEffect(() => {
    let componentMounted = true; // Uma variável local para rastrear se o componente ainda está montado

    if (list === null) {
      // if (list === null) {
      setPreloadInit(true);
      getGroupsAutoRegistrationAPIPromises(idTeam, (promise) => {
        if (componentMounted) {
          filterListInit(promise);
          setPreloadInit(false);
        }
      });
    }

    return () => {
      componentMounted = false; // Marque o componente como desmontado ao sair do useEffect
    };
  }, [list]);

  let idGroups = 0;
  const handleChangeGroups = (idGr) => {
    idGroups = parseInt(idGr);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Conteúdos por página",
    rangeSeparatorText: "de",
  };

  const addGroupsTarget = () => {
    if (idGroups !== 0) {
      setPreloadInit(true);
      const idGr = list.find((el) => el.idGroups === idGroups);
      setListSelect([...listSelect, idGr]);

      const listInit = list.filter((el) => el.idGroups !== idGroups);
      setList(listInit);

      postAddGroupPromises(
        idTeam,
        {
          idGroups: idGr.idGroups,
        },
        () => {
          setPreloadInit(false);
        },
      );
    }
  };

  const autoEnrollProcessGroupsEvent = (e) => {
    if (e.currentTarget.checked) {
      setPreloadInit(true);

      getGroupsInTeamsEnrollInfoPromises(idTeam, (promise) => {
        const res = promise[0].data;
        setPreloadInit(false);

        const textInfo =
          res.matchedToEnroll > 1
            ? `<span>${res.matchedToEnroll}</span> novos alunos`
            : `<span>${res.matchedToEnroll}</span> novo aluno`;

        openConfirmModal({
          text: `Ao habilitar o processo de matrícula automática, a quantidade de novas matrículas será de ${textInfo}. Você tem certeza de que deseja prosseguir com essa alteração?`,
          variantBtn: "warning",
          textBtn: "Sim, ativar matrícula automática",
          className: "subModalAutoRegistration",
          fnc: () => {
            setPreloadInit(true);
            putActivateAutoEnrollPromises(idTeam, "activate", () => {
              setAutoEnrollProcess(true);
              setPreloadInit(false);
            });
          },
        });
      });
    } else {
      setPreloadInit(true);
      putActivateAutoEnrollPromises(idTeam, "deactivate", () => {
        setAutoEnrollProcess(false);
        setPreloadInit(false);
      });
    }
  };

  const removeGroupsTarget = (group) => {
    openConfirmModal({
      text: `Você tem certeza que deseja remover o público-alvo: <span>${group.title}</span>?`,
      variantBtn: "danger",
      textBtn: "Remover público-alvo",
      className: "subModalAutoRegistration",
      fnc: () => {
        setPreloadInit(true);
        setList([...list, group]);
        const idGr = listSelect.filter((el) => el.idGroups !== group.idGroups);
        setListSelect(idGr);
        deleteGroupsInTeamsPromises(idTeam, group.idGroups, () => {
          setPreloadInit(false);
        });
      },
    });
  };

  /* Colunas da tabela dos grupos já criados */
  const columns = [
    {
      name: "Código",
      selector: "idGroups",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (group) => (
        <div>
          <p>{group.idGroups}</p>
        </div>
      ),
    },
    {
      name: "Público-alvo",
      sortable: false,
      selector: "title",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (group) => (
        <div>
          <p>{group.title}</p>
        </div>
      ),
    },

    {
      name: "Ação",
      sortable: false,
      selector: "actions",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (group) => (
        <>
          {!disabled && (
            <div className="groupActions" key={group.idGroups}>
              <Tooltip title="Remover público-alvo" placement="top">
                <span
                  className="btn btn-sm float-right"
                  onClick={() => removeGroupsTarget(group)}
                >
                  <RiDeleteBin5Line />
                </span>
              </Tooltip>
            </div>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      {preloadInit && <PreloaderBlur />}

      {showModalConfirm && (
        <ModalDialog
          title={showModalConfirmParamiters.current.title}
          text={showModalConfirmParamiters.current.text}
          className={showModalConfirmParamiters.current.className}
          show={showModalConfirm}
          setShow={setShowModalConfirm}
          btnCall={{
            className: "",
            variantBtn: showModalConfirmParamiters.current.variantBtn,
            text: showModalConfirmParamiters.current.textBtn,
            callEvent: () => {
              if (showModalConfirmParamiters.current.fnc) {
                showModalConfirmParamiters.current.fnc();
              }
            },
          }}
        />
      )}

      <div className="groupsContainerAll">
        <div className="groupListHeader">
          <div className="infos">
            {/* <h6 className="title">Matrícula automática</h6> */}
            <h6>
              Aqui você pode adicionar o seu público-alvo com os critérios de matricula automática
              para esta turma.
            </h6>
          </div>
          <div className="containerActiveAuto">
            <div className="contText">
              <h6>Ativar processo de matrícula automática</h6>
              <p>
                Ao ativar esta funcionalidade, o processo de matrícula automática realizará a
                matrícula de novos alunos de acordo com o público-alvo adicionado abaixo.
              </p>
            </div>
            <FormControlLabel
              control={
                <Switch
                disabled={disabled}
                  color="primary"
                  checked={autoEnrollProcess}
                  onChange={(e) => {
                    autoEnrollProcessGroupsEvent(e);
                  }}
                  name="autoEnrollProcessGroupsActive"
                />
              }
            />
          </div>
        </div>

        <div className={"targetAdd"}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Público-alvo</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
                disabled={disabled}

              as="select"
              // value={contentData.idGroups}
              onChange={(e) => {
                handleChangeGroups(e.target.value);
              }}
            >
              <option>Selecione um público-alvo</option>
              {list &&
                list.map((it) => (
                  <option key={it.idGroups} value={it.idGroups}>
                    {it.title}
                  </option>
                ))}
            </Form.Control>
          </InputGroup>

          <Button variant="warning" onClick={addGroupsTarget} disabled={disabled}>
            Adicionar
          </Button>
        </div>

        <div className="groupListTableContainer">
          <DataTable
            columns={columns}
            data={listSelect}
            paginationComponentOptions={paginationComponentOptions}
            noDataComponent={<NoPublic />}
            paginationServer
            noHeader
            fixedHeader
          />
        </div>
      </div>
    </>
  );
};

export default withRouter(GroupsAutoRegistration);
