import React from "react";

const CmsLinkTitle = ({ titulo, setTitulo, contentLocation = '', setContentLocation }) => { 

  return (
    <div className="form-group">
      <div className="card">
        <div className="card-header template-card-header">Título</div>
        <div className="card-body template-card-body">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                Título
              </span>
            </div>
            <input
              type="titulo"
              className="form-control"
              id="titulo"
              aria-describedby="titulo"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
            />
          </div>
          <div className="input-group mb-3 center" style={{ alignItems: "center" }}>
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                Link para o Conteúdo
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              id="contentLocation"
              aria-describedby="contentLocation"
              placeholder="Digite ou cole o link e clique no botão ao lado."
              value={contentLocation || ""}
              onChange={(e) => {
                setContentLocation(e.target.value);
              }}
            /> 
          </div>
        </div>
      </div> 
    </div>
  );
};

export default CmsLinkTitle;
