import React, { useState, useEffect, useCallback, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import MaskInput from "react-maskinput";
import { BiArrowBack } from "react-icons/bi";
import { AuthContext } from "../../context/AuthContext";
import { getChannel, activateCompany, getChannelCampaign } from "../../services/NexiDigitalAPI";
import { DatePickerInput } from "rc-datepicker";
import moment from "moment";
import { cpf as cpfValidator, cnpj as cnpjValidator } from "cpf-cnpj-validator";
import Swal from "sweetalert2";

export default function AddCompany() {
  const history = useHistory();
  const goBack = useCallback(() => history.push("/company-manager"), [history]);

  const [responsibleEmail, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [lms, setLms] = useState(true);
  const [responsibleName, setResponsibleName] = useState("");
  const [responsiblePhone, setResponsiblePhone] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cpf, setCpf] = useState("");
  const [originCode, setOriginCode] = useState("");
  const [campaign, setCampaign] = useState("");
  const [idChannel, setIdChannel] = useState(0);
  const [trialPeriod, setTrialPeriod] = useState(0);
  const [channels, setChannels] = useState([]);
  const [numberOfLicences, setNumberOfLicences] = useState(0);
  const [errorSave, setErrorSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validCpf, setValidCpf] = useState(true);
  const [validCnpj, setValidCnpj] = useState(true);
  const [campaignList, setCampaignList] = useState([]);
  const [lastValidationCheck, setLastValidationCheck] = useState(
    moment().format("YYYY-MM-DD hh:mm:ss"),
  );

  const { loginData } = useContext(AuthContext);
  const isAdmin = loginData?.isAdmin || 0;
  const primaryColor = loginData.primaryColor;

  function _getChannel() {
    getChannel().then((res) => {
      if (res.data.success == true) {
        setIdChannel(res.data.channel[0].idChannel);
        setChannels(res.data.channel);
      }
    });
  }

  function _getChannelCampaign() {
    getChannelCampaign()
      .then((res) => {
        if (res.data.success == true) {
          let newCampaignList = [...res?.data?.campaign];
          const othersIndex = res?.data?.campaign.findIndex((e) => e.idChannelCampaign === 0);
          if (othersIndex >= 0) {
            newCampaignList.push(newCampaignList.splice(othersIndex, 1)[0]);
          }
          setCampaign(newCampaignList[0]?.idChannelCampaign);
          setCampaignList(newCampaignList);
          setTrialPeriod(newCampaignList[0]?.trialDays);
          setNumberOfLicences(newCampaignList[0]?.licenceNumber);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function setCampaignValues(selectedIdChannelCampaign) {
    const filteredCampaign = campaignList.find(
      (campaignItem) => campaignItem.idChannelCampaign === selectedIdChannelCampaign,
    );
    setCampaign(filteredCampaign.idChannelCampaign);
    setTrialPeriod(filteredCampaign.trialDays);
    setNumberOfLicences(filteredCampaign.licenceNumber);
  }

  function handleValidateDate(jsDate) {
    let date = moment(jsDate).format("YYYY-MM-DD hh:mm:ss");
    setLastValidationCheck(date);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    _getChannel();
    _getChannelCampaign();
  }, []);

  function resetErrorState() {
    setErrorSave(false);
    setValidCnpj(true);
    setValidCpf(true);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    resetErrorState();

    if (!cnpjValidator.isValid(cnpj)) {
      setValidCnpj(false);
      return;
    }

    if (cpf && !cpfValidator.isValid(cpf)) {
      setValidCpf(false);
      return;
    }

    const activateData = {
      companyName: nome,
      cnpj: cnpj?.replace(/[^0-9]/g, ""),
      adminName: responsibleName,
      adminEmail: responsibleEmail,
      adminPhone: responsiblePhone,
      idChannel,
      adminCPF: cpf?.replace(/[^0-9]/g, "") || null,
      numberOfLicences,
      trialPeriod,
      originCode,
      idChannelCampaign: campaign,
      lastValidationCheck,
      lms,
    };

    setLoading(true);

    activateCompany(activateData)
      .then(({ data }) => {
        if (data.success) {
          setLoading(false);
          Swal.fire({
            title: "Empresa criada com sucesso!",
            icon: "success",
            confirmButtonText: "Continuar",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/company-manager");
            }
          });
        } else {
          setLoading(false);
          setErrorSave(true);
        }
      })
      .catch((e) => {
        setLoading(false);
        setErrorSave(true);
      });
  }

  return (
    <>
      <div className="header-title-wrapper">
        <div className="header-title">Registro de empresa</div>
      </div>
      <div className="user-edit-container">
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>Nome da empresa</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Digite o nome da empresa"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formAdminName">
            <Form.Label>Nome da administrador</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Digite o seu nome"
              value={responsibleName}
              onChange={(e) => setResponsibleName(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formEmail">
            <Form.Label>Email do administrador</Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="Email do administrador"
              value={responsibleEmail}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <div className="company-group-label">
            <Form.Group controlId="formCelularLabel">
              <Form.Label>Celular </Form.Label>
              <br />
              <MaskInput
                required
                className="mask-input"
                alwaysShowMask
                mask={"(00) 00000-0000"}
                size={20}
                showMask
                maskChar="_"
                placeholder="Digite o número do seu celular"
                value={responsiblePhone}
                onChange={(e) => setResponsiblePhone(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formCNPJLabel">
              <Form.Label style={{ position: "relative" }}>CNPJ</Form.Label>
              <br />
              <MaskInput
                required
                className="mask-input"
                alwaysShowMask
                mask={"00.000.000/0000-00"}
                size={20}
                showMask
                maskChar="_"
                placeholder="Digite o número do CNPJ"
                value={cnpj}
                onChange={(e) => setCnpj(e.target.value)}
              />
            </Form.Group>
          </div>

          <div className="company-group-label">
            <Form.Group controlId="formCNPJLabel">
              <Form.Label style={{ position: "relative" }}>CPF</Form.Label>
              <br />
              <MaskInput
                required
                className="mask-input"
                alwaysShowMask
                mask={"000.000.000-00"}
                size={20}
                showMask
                maskChar="_"
                placeholder="Digite o número do CPF"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="company-dropdown-label">
              <Form.Label>Canal</Form.Label>
              <Form.Control
                value={idChannel}
                onChange={(e) => setIdChannel(parseInt(e.target.value))}
                as="select"
              >
                {channels.map((item, index) => {
                  return (
                    <option key={index} value={item.idChannel}>
                      {item.nome}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </div>

          <div className="company-group-label">
            <Form.Group>
              <Form.Label>Código de origem</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Origem"
                value={originCode}
                onChange={(e) => setOriginCode(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="company-dropdown-label">
              <Form.Label>Campanha</Form.Label>
              <Form.Control
                value={campaign}
                onChange={(e) => setCampaignValues(parseInt(e.target.value))}
                as="select"
              >
                {campaignList.map((item, index) => {
                  return (
                    <option key={index} value={item.idChannelCampaign}>
                      {item.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </div>

          <div className="company-group-label">
            <Form.Group controlId="formCNPJLabel">
              <Form.Label>Numeros de licença</Form.Label>
              <Form.Control
                required={isAdmin >= 1}
                type="number"
                placeholder="Digite a quantidade de lincenças"
                value={numberOfLicences}
                onChange={(e) => setNumberOfLicences(parseInt(e.target.value))}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Período de trials (em dias)</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Trials"
                value={trialPeriod}
                onChange={(e) => setTrialPeriod(parseInt(e.target.value))}
              />
            </Form.Group>
          </div>
          <div className="company-group-label">
            <Form.Group>
              <Form.Label>Data da ultima validação</Form.Label>
              <br />
              <DatePickerInput
                locale="pt-br"
                onChange={handleValidateDate}
                value={lastValidationCheck}
              />
            </Form.Group>
            <Form.Group className="company-dropdown-label">
              <Form.Label>LMS</Form.Label>
              <Form.Control
                value={lms}
                onChange={(e) => setLms(parseInt(e.target.value))}
                as="select"
              >
                <option key="0" value="1">
                  Sim
                </option>
                <option key="1" value="0">
                  Não
                </option>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="mb-2" style={{ display: "flex", marginTop: 30 }}>
            <Button onClick={goBack} variant="secondary" style={{ marginRight: 15 }} size="sm">
              <div>
                <BiArrowBack size={22} style={{ marginBottom: 3, marginRight: 10 }} />
                Voltar
              </div>
            </Button>

            <Button
              className="blast-button"
              style={{ backgroundColor: primaryColor }}
              type="submit"
              size="sm"
            >
              {!loading ? (
                "Efetuar o Registro"
              ) : (
                <div style={{ display: "flex" }}>
                  <div className="loader loader-white" style={{ marginRight: 10 }} />
                  Criando empresa...
                </div>
              )}
            </Button>
          </div>

          <div style={{ marginTop: 10 }}>
            <div style={{ color: "red" }}>
              {errorSave && "Erro ao salvar empresa"}
              {!validCpf && "CPF inválido"}
              {!validCnpj && "CNPJ inválido"}
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}
