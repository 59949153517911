import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getNodesToImport } from "services/NexiDigitalAPI";
import SemImagem from "../../../assets/images/sem-imagem.jpg";
import Loading from "components/Loading";
import { RxMagnifyingGlass } from "react-icons/rx";
import { getNodeLevels } from "services/NexiDigitalAPI";
import { Form, InputGroup } from "react-bootstrap";

const NoDataComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 400,
        width: "100%",
        color: "gray",
      }}
    >
      Não foi encontrado nenhum dado
    </div>
  );
};

const ContentPageTable = ({ blockData, handleNodeImport }) => {
  const columns = [
    {
      name: "Imagem",
      selector: "image",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      reorder: false,
      grow: 0,
      cell: (d) => (
        <div
          className="image-card"
          style={{
            backgroundImage: `url( ${d.image || SemImagem} )`,
            height: 40,
            width: 40,
            color: "#fff",
            display: "inline-grid",
            backgroundColor: "rgba(105, 72, 255, 0.3)",
            borderRadius: 5,
          }}
        />
      ),
    },
    {
      name: "Titulo",
      selector: "title",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
    },
    {
      name: "Nível de conteúdo",
      selector: "nodeLevelName",
      sortable: false,
      center: true,
      wrap: true,
      grow: 0,
      style: { textAlign: "center" },
      cell: (d) => <>{d.nodeLevelName || "-"}</>,
    },
    {
      name: "Origem",
      selector: "originParentNodesText",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (d) => <>{d.originParentNodesText || "-"}</>,
    },
    {
      name: "Ação",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center", marginRight: 10 },
      grow: 0,
      cell: (d) => (
        <button
          type="button"
          onClick={() => {
            handleNodeImport(d);
          }}
          className="btn content-page-button"
          style={{
            backgroundColor: "#feac0e",
            margin: "0 auto",
            whiteSpace: "nowrap",
            height: 30,
          }}
        >
          Importar
        </button>
      ),
    },
  ];
  const [nodeData, setNodeData] = useState([]);
  const [loadingNode, setLoadingNode] = useState(true);
  const [offset, setOffset] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [textFilter, setTextFilter] = useState("");
  const [nodeLevelList, setNodeLevelList] = useState([]);
  const [nodeLevel, setNodeLevel] = useState(null);

  const customTableStyles = {
    headCells: {
      style: {
        textAlign: "center",
      },
    },
  };

  const originFilter = (result) => {
    const concatParentNodes = (it) => {
      let orginTxt = "";

      it.parentNodes.map((el, ind) => {
        if (ind !== 0) {
          orginTxt += " • " + el.title;
        } else {
          orginTxt += el.title;
        }
      });

      return orginTxt;
    };

    result.map((it) => {
      it.originParentNodesText = concatParentNodes(it);
      return it;
    });

    return result;
  };

  const getNodes = (newOffset, limit) => {
    setLoadingNode(true);
    const haveNewOffset = newOffset || newOffset === 0;
    if (haveNewOffset) {
      setOffset(newOffset);
    }
    const importParams = {
      nodeId: blockData?.idNode,
      blockId: blockData?.idBlock,
      offset: haveNewOffset ? newOffset : offset,
      limit: limit ? limit : pageLimit,
      textFilter: Boolean(textFilter) ? textFilter : null,
      nodeLevel: Boolean(nodeLevel) ? nodeLevel : null,
    };
    getNodesToImport(importParams)
      .then((res) => {
        const result = originFilter(res.data.result);

        setNodeData(result);
        setTotalRecords(res.data.totalRecords);
      })
      .catch((err) => {
        console.log("error getting nodes", err.request);
      })
      .finally(() => {
        setLoadingNode(false);
      });
  };

  const _getNodeLevels = () => {
    getNodeLevels("")
      .then((res) => {
        const nodeLevels = res.data.nodeLevels.filter((nodeItem) => nodeItem.nodeLevel !== 1);
        setNodeLevelList(nodeLevels);
      })
      .catch((err) => {
        console.log("Error getting node levels", err.request);
      });
  };

  const onChangePage = (page) => {
    const newOffset = (page - 1) * pageLimit;
    getNodes(newOffset);
  };

  const onChangeRowsPerPage = (limit, page) => {
    setPageLimit(limit);
    const newOffset = (page - 1) * limit;
    getNodes(newOffset, limit);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Conteúdos por página",
    rangeSeparatorText: "de",
  };

  useEffect(() => {
    getNodes();
    _getNodeLevels();
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div style={{ display: "flex" }}>
        <div className="input-group mb-3" style={{ width: 400 }}>
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              <RxMagnifyingGlass style={{ width: 20, height: 20 }} />
            </span>
          </div>
          <input
            required
            type="text"
            className="form-control mr-3"
            id="node-find"
            aria-describedby="node-find"
            placeholder="Procurar por título ou descrição"
            value={textFilter || ""}
            onChange={(e) => setTextFilter(e.target.value)}
            onKeyDown={(input) => {
              if (input.key === "Enter") {
                getNodes();
              }
            }}
          />
        </div>
        <div className="input-group mb-3 mr-3" style={{ width: 400 }}>
          <InputGroup.Prepend>
            <InputGroup.Text>Nível de conteúdo</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            as="select"
            value={nodeLevel}
            onChange={(e) => {
              const value = e.target.value;
              setNodeLevel(value === null ? null : Number(e.target.value));
            }}
          >
            {nodeLevelList.length > 0 ? (
              <>
                <option value={null}>Todos</option>
                {nodeLevelList?.map((nodeItem, index) => (
                  <option key={`node-level-${index}`} value={nodeItem.nodeLevel}>
                    {nodeItem.name}
                  </option>
                ))}
              </>
            ) : (
              <option value={0}>Carregando...</option>
            )}
          </Form.Control>
        </div>
        <button
          type="button"
          onClick={() => {
            getNodes();
          }}
          className="btn content-page-button"
          style={{
            backgroundColor: "rgb(107, 72, 255)",
            color: "white",
          }}
        >
          Filtrar
        </button>
      </div>
      <div className="node-manager-import-table-wrapper">
        {loadingNode && nodeData.length >= 0 && (
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Loading>Carregando nós...</Loading>
          </div>
        )}
        <DataTable
          columns={columns}
          data={nodeData}
          progressComponent={<Loading>Carregando nós...</Loading>}
          noDataComponent={<NoDataComponent />}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationTotalRows={totalRecords}
          paginationComponentOptions={paginationComponentOptions}
          progressPending={loadingNode && nodeData.length === 0}
          paginationServer
          noHeader
          fixedHeader
          pagination
          disabled={loadingNode}
          customStyles={customTableStyles}
        />
      </div>
    </div>
  );
};

export default ContentPageTable;
