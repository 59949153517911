import { memo, useMemo, useEffect, useState } from "react";
import HeaderNavigation from "components/PeopleCraft/HeaderNavigation";
import Loading from "components/Loading";
import DataTable from "react-data-table-component";
import {
  getDownloadReport,
  getTermOfResponsibilityById,
  postDownloadReport,
} from "services/NexiDigitalAPI";
import { useParams } from "react-router-dom";
import "moment/locale/pt-br";
import moment from "moment";

import { Button, Modal } from "react-bootstrap";
import PrinterIcon from "assets/images/printer-icon.svg";
import AttendanceListIcon from "assets/images/attendance_list.svg";
import { Skeleton } from "@material-ui/lab";
import PreloaderBlur from "containers/PeopleCraft/PreloaderBlur";
import "./styles.scss";

const DATA_PER_PAGE = 10;
const RESPONSIBILITY_ID_REPORT = 5;

const customTableStyles = {
  table: {
    style: {
      maxHeight: "70vh",
      overflow: "auto",
    },
  },
  headCells: {
    style: {
      backgroundColor: "#6b48ff",
      color: "white",
      paddingLeft: "30px",
      ">*": {
        ":focus": {
          color: "white !important",
          backgroundColor: "#6b48ff",
        },
        ":hover": {
          color: "white !important",
          backgroundColor: "#6b48ff",
        },
        ":active": {
          color: "white !important",
          backgroundColor: "#6b48ff",
        },
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "30px",
    },
  },
};

const paginationComponentOptions = {
  rowsPerPageText: "Termos por página",
  rangeSeparatorText: "de",
};

const NoDataComponent = memo(() => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 400,
        width: "100%",
        color: "gray",
      }}
    >
      Não foi encontrado nenhum dado
    </div>
  );
});

const HistoryTerms = () => {
  const { id: idTermOfResponsibility } = useParams();
  const [termsHistoryData, setHistoryTermsData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(DATA_PER_PAGE);
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const [loadingReportDownload, setLoadingReportDownload] = useState(false);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });

  useEffect(() => {
    const getTermOfResponsibility = async (params) => {
      setLoading(true);
      const { data } = await getTermOfResponsibilityById(idTermOfResponsibility, params);
      setHistoryTermsData(data);
      setTotalRecords(data.versions.length);
      setLoading(false);
    };

    getTermOfResponsibility(pagination);
  }, [idTermOfResponsibility, pagination]);

  const requestDownloadReport = async () => {
    try {
      const response = await getDownloadReport(RESPONSIBILITY_ID_REPORT);
      const { status, fileUrl } = response?.data || {};

      if (status === "Waiting") {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return requestDownloadReport();
      }

      if (status === "Done" && fileUrl) {
        getFileReport(fileUrl);
      }
    } finally {
      setLoading(false);
      setLoadingReportDownload(false);
    }
  };

  const getFileReport = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.click();
  };

  const generateExcelAcceptanceData = async (versionNumber) => {
    try {
      setLoadingReportDownload(true);
      await postDownloadReport(RESPONSIBILITY_ID_REPORT, {
        idTermOfResponsibility,
        versionNumber,
      });

      await requestDownloadReport();
    } catch (error) {
      setLoading(false);
      setLoadingReportDownload(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        name: "Versão",
        selector: "versionNumber",
        wrap: true,
        style: { textAlign: "center" },
        reorder: false,
        sortable: false,
        cell: (data, index) => (data?.versionNumber ? `V${data?.versionNumber}` : ""),
      },
      {
        name: "Data de criação",
        selector: "creationDate",
        wrap: true,
        style: { textAlign: "center" },
        reorder: false,
        sortable: false,
        cell: (data, index) => moment(data.creationDate).format("MM/DD/YYYY HH:mm:ss"),
      },
      {
        name: "Usuário que realizou a alteração",
        selector: "creatorName",
        wrap: true,
        style: { textAlign: "center" },
        reorder: false,
        sortable: false,
        cell: ({ modifierName, modifierLogin, creatorName, creatorLogin }, index) =>
          modifierName && modifierLogin ? (
            <span>{`${modifierName} - ${modifierLogin}`}</span>
          ) : creatorName && creatorLogin ? (
            <span>{`${creatorName} - ${creatorLogin}`}</span>
          ) : (
            "-"
          ),
      },
      {
        name: "Termo",
        selector: "",
        wrap: true,
        style: { textAlign: "center", cursor: "pointer", color: "blue" },
        reorder: false,
        sortable: false,
        cell: (data, index) => <span onClick={() => showModal(data)}>Visualizar termo</span>,
      },
      {
        name: "Dados de aceite",
        selector: "",
        wrap: true,
        width: "200px",
        style: {
          width: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        },
        reorder: false,
        sortable: false,
        cell: (data, index) => (
          <div
            onClick={() => generateExcelAcceptanceData(data.versionNumber)}
          >
            <img src={AttendanceListIcon} className="attendance-list-icon" alt="" />
          </div>
        ),
      },
    ],
    [],
  );

  const showModal = (data) => {
    setIsVisible(true);
    setModalConfig({
      modalContent: data.content
    });
  };

  const onChangePage = (page) => {
    setPagination((prev) => ({ ...prev, offset: page === 1 ? 0 : (page - 1) * prev.limit }));
  };

  const onChangeRowsPerPage = (limit, page) => {
    setPagination((prev) => ({ ...prev, offset: page === 1 ? 0 : (page - 1) * limit, limit }));
  };

  const paginatedData = useMemo(() => {
    const start = pagination.offset;
    const end = start + pagination.limit;
    return {
      ...termsHistoryData,
      versions: (termsHistoryData?.versions || []).slice(start, end),
    };
  }, [termsHistoryData, pagination]);

  const onHide = () => {
    setIsVisible(false);
  };

  const handlePrint = () => {
    const printContent = document.querySelector(".modal-history-content").innerHTML;
    const printWindow = window.open("", "", "height=600,width=800");

    printWindow.document.write(`
      <html>
        <head>
          <style>
            @media print {
              @page {
                margin: 0;
              }
              body {
                margin: 1.6cm;
              }
              header, footer {
                display: none;
              }
            }
          </style>
        </head>
        <body>
          ${printContent}
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  return (
    <>
    {loadingReportDownload && <PreloaderBlur />}

    <div className="history-term">
      <HeaderNavigation title={`Termos da plataforma  - ${termsHistoryData?.name || ''}`} />
      <h4 className="history-term-title">Histórico de versões</h4>
      {loading ? (
        <Skeleton variant="rectangular" width={"100%"} height={200} />
      ) : (
        <DataTable
          columns={columns}
          customStyles={customTableStyles}
          data={paginatedData.versions}
          progressComponent={
            <Loading containerStyle={{ height: 400 }}>Carregando termos...</Loading>
          }
          noDataComponent={<NoDataComponent />}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationTotalRows={totalRecords}
          paginationComponentOptions={paginationComponentOptions}
          progressPending={loading && termsHistoryData?.versions?.length === 0}
          paginationServer
          noHeader
          fixedHeader
          pagination
          highlightOnHover
        />
      )}
      <Modal
        id={"modal-history"}
        show={isVisible}
        onHide={onHide}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size={"xl"}
      >
        <div className="modal-history-container">
          <Modal.Header
            className="custom-header"
            style={{ backgroundColor: "#6b48ff", color: "#fff" }}
          >
            <Modal.Title>{termsHistoryData.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-history-body">
            <img
              src={PrinterIcon}
              className="modal-history-printer-icon"
              alt="icon-printer"
              onClick={handlePrint}
            />
            <div
              className="modal-history-content"
              dangerouslySetInnerHTML={{ __html: modalConfig.modalContent }}
            />
          </Modal.Body>
          <Modal.Footer className="modal-history-footer">
            <Button
              variant="outline-secondary"
              className="btn-close-history-modal"
              onClick={onHide}
            >
              Fechar
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
    </>
  );
};

export default HistoryTerms;
