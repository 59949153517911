import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getProfileInfo, validateQuestions, modelQuestion } from "utils/Utils";

import { getEditQuestion, saveSetQuestion } from "../Fetch/QuestionAPI";
import { checkSaveBlock } from "../Utils/utilQuestions";

const useCreateQuestion = (
  setShow,
  show,
  setQuestionsList,
  questionsList,
  evaluationModel,
  idQuestion = null,
  returnContentData = null,
) => {
  const [showWarningModal, setShowWarningModal] = useState(false);

  const [sabeBlock, setSaveBlock] = useState(true);

  const [loadingQuestion, setLoadingQuestion] = useState(idQuestion ? true : false);
  const [loadingUpdateContent, setLoadingUpdateContent] = useState(false);
  const [loadingUpdateContentRefresh, setLoadingUpdateContentRefresh] = useState(false);

  const [typeQuestion, setTypeQuestion] = useState(modelQuestion.LIKERT_SCALE);
  const [questionOptions, setQuestionOptions] = useState([]);
  const [questionScales, setQuestionScales] = useState([]);
  const [statement, setStatement] = useState(null);
  const [validateForm, setValidateForm] = useState(false);


  const [tagsEnd, setTagsEnd] = useState([]);
  const [categories, setCategories] = useState([]);

  const profileInfo = getProfileInfo();
  const { idEvaluation } = useParams();

  const handleAddCategories = (objNode) => {
    setCategories((prevCategories) => [...prevCategories, objNode]);
  };

  const clearForm = () => {
    setStatement("");
    setCategories([]);
    setQuestionOptions([]);
    setQuestionScales([]);
    setTagsEnd([]);
  };

  const questionData = () => {
    let idType = 2;
    let data = {};

    if (typeQuestion === modelQuestion.LIKERT_SCALE) {
      idType = 2;
      data = { questionScales: questionScales };
    } else if (typeQuestion === modelQuestion.MULTI_LIKERT_SCALE) {
      idType = 1;
      data = { questionScales: questionScales, questionOptions: questionOptions };
    } else if (typeQuestion === modelQuestion.SINGLE_OPTION_COMMENT) {
      idType = 3;
      data = { questionOptions: questionOptions };
    } else if (typeQuestion === modelQuestion.SINGLE_OPTION) {
      idType = 4;
      data = { questionOptions: questionOptions };
    } else if (typeQuestion === modelQuestion.MULTI_OPTION) {
      idType = 5;
      data = { questionOptions: questionOptions };
    } else if (typeQuestion === modelQuestion.COMMENT) {
      idType = 6;
    } else if (typeQuestion === modelQuestion.TRUE_OR_FALSE) {
      idType = 7;
      data = { questionOptions: questionOptions };
    } else if (typeQuestion === modelQuestion.CORRELATE) {
      idType = 8;
      data = { questionOptions: questionOptions };
    }

    return { idQuestionType: idType, ...data };
  };

  function handleDeny() {
    clearForm();
    setShow(false);
  }

  const saveQuestion = (closeModal = true) => {
    const valid = validateQuestions(statement, typeQuestion, questionScales, questionOptions);
    if (!valid) {
      return;
    }

    saveSetQuestion(
      closeModal,
      idQuestion,
      setLoadingUpdateContent,
      setLoadingUpdateContentRefresh,
      profileInfo,
      statement,
      tagsEnd,
      categories,
      questionData,
      idEvaluation,
      typeQuestion,
      setQuestionsList,
      handleDeny,
      clearForm,
      questionsList,
      evaluationModel,
      returnContentData,
    );
  };

  useEffect(() => {
    checkSaveBlock(typeQuestion, statement, questionScales, questionOptions, setSaveBlock);
  }, [statement, questionOptions, questionScales, typeQuestion, validateForm]);

  useEffect(() => {
    if (idQuestion && show) {
      getEditQuestion(
        setLoadingQuestion,
        setStatement,
        setTypeQuestion,
        setQuestionOptions,
        setQuestionScales,
        setTagsEnd,
        setCategories,
        idQuestion,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idQuestion, show]);

  return {
    setShowWarningModal,
    showWarningModal,
    handleDeny,
    statement,
    setStatement,
    typeQuestion,
    setTypeQuestion,
    questionScales,
    setQuestionScales,
    questionOptions,
    setQuestionOptions,
    handleAddCategories,
    categories,
    setCategories,
    tagsEnd,
    setTagsEnd,
    sabeBlock,
    loadingUpdateContent,
    loadingUpdateContentRefresh,
    saveQuestion,
    loadingQuestion,
    validateForm,
    setValidateForm
  };
};

export default useCreateQuestion;
