import "./styles.scss";

import React, { useContext, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";

import { RiHome2Line } from "react-icons/ri";
import { LMCreateContext } from "../../Contexts/LMContext";

type Props = {
  data?: any;
};


const BreadcrumbControl = ({ data } : Props) => {
  const { setNodeCurrent, nodeData } = useContext(LMCreateContext);
  
  if(!nodeData) return <div className="breadcrumbControl"></div>

  return (
    <div className="breadcrumbControl">
      <Breadcrumb>
        {nodeData.parentNodes.map((it, i) => {
            if(it){
              return (
                <Breadcrumb.Item
                  onClick={(e) => {
                    setNodeCurrent(it);
                    
                  }}
                  key={i}
                  active={false}
                >
                  {i === 0 ? <RiHome2Line /> : <span>{it.title}</span>}
                </Breadcrumb.Item>

               );
            }
          })}

          {/* {(nodeData.parentNodes.length > 1) && */}
            <Breadcrumb.Item
              key={'currentPage'}
              active={true}
            >
              
              <span>{nodeData.title}</span>
              {/* {i === 0 ? <RiHome2Line /> : <span>{it.title}</span>} */}
            </Breadcrumb.Item>
          {/* } */}
      
      </Breadcrumb>
    </div>
  );
};

export default BreadcrumbControl;
