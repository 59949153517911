import "./styles.scss";
import React, { useState, useEffect, useCallback, useRef } from "react";
import Loading from "components/Loading";
import { FiUserPlus } from "react-icons/fi";
import TableComponent from "../../../Components/TableComponent";

const paginationUserComponentOptions = {
  rowsPerPageText: "Usuários por página",
  rangeSeparatorText: "de",
};

const StepComponentQuarter = ({
  numberOfSteps = 0,
  index,
  label,
  isActive,
  loading,
  tableColumn,
  tableData = [],
  NoDataComponent,
  setSelectedUsersToMigration = () => {},
  selectedUsersToMigration = {},
  companyTarget = {},
  companyOrigin = {},
  addUser = () => {},
  getPaginationRowsPerPageOptions = () => {},
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectAll, setSelectAll] = useState(false);
  const [tableKey, setTableKey] = useState(0);
  const initialLoad = useRef(true);
  const previousTableData = useRef([]);

  const resetState = () => {
    setSelectedRows([]);
    setCurrentPage(1);
    setRowsPerPage(10);
    setSelectAll(false);
    setTableKey(0);
    initialLoad.current = true;
    previousTableData.current = [];
    setSelectedUsersToMigration({
      selectedCount: 0,
      selectedRows: [],
    });
  };
 
  useEffect(() => {
    if (isActive && initialLoad.current) {
      setSelectedRows(tableData);
      setSelectedUsersToMigration({
        selectedCount: tableData.length,
        selectedRows: tableData,
      });
      initialLoad.current = false;
    }
  }, [isActive, tableData, setSelectedUsersToMigration]);
 
  useEffect(() => {
    if (isActive && !initialLoad.current) {
      const newRows = tableData.filter(
        (row) => !previousTableData.current.some((selectedRow) => selectedRow.idUser === row.idUser)
      );
      if (newRows.length > 0) {
        const updatedSelectedRows = [...selectedRows, ...newRows];
        setSelectedRows(updatedSelectedRows);
        setSelectedUsersToMigration({
          selectedCount: updatedSelectedRows.length,
          selectedRows: updatedSelectedRows,
        });
        setTableKey((prevKey) => prevKey + 1);
      }
    }

    const filteredSelectedRows = selectedRows.filter((row) =>
      tableData.some((tableRow) => tableRow.idUser === row.idUser)
    );
    if (filteredSelectedRows.length !== selectedRows.length) {
      setSelectedRows(filteredSelectedRows);
      setSelectedUsersToMigration({
        selectedCount: filteredSelectedRows.length,
        selectedRows: filteredSelectedRows,
      });
    }
    previousTableData.current = tableData;
  }, [tableData, isActive, selectedRows, setSelectedUsersToMigration]);

 
  useEffect(() => {
    const paginatedData = tableData.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );
    const allCurrentRowsSelected =
      paginatedData.length > 0 &&
      paginatedData.every((row) =>
        selectedRows.some((selectedRow) => selectedRow.idUser === row.idUser)
      );
    setSelectAll(allCurrentRowsSelected);
  }, [selectedRows, tableData, currentPage, rowsPerPage]);

  useEffect(() => {
    if (!isActive) {
      resetState();
    }
  }, [isActive]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  const handleSelectAllClick = () => {
    const paginatedData = tableData.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    if (newSelectAll) {
      const newSelectedRows = [
        ...selectedRows,
        ...paginatedData.filter(
          (row) => !selectedRows.some((selectedRow) => selectedRow.idUser === row.idUser)
        ),
      ];
      setSelectedRows(newSelectedRows);
      setSelectedUsersToMigration({
        selectedCount: newSelectedRows.length,
        selectedRows: newSelectedRows,
      });
    } else {
      const newSelectedRows = selectedRows.filter(
        (row) =>
          !paginatedData.some(
            (paginatedRow) => paginatedRow.idUser === row.idUser
          )
      );
      setSelectedRows(newSelectedRows);
      setSelectedUsersToMigration({
        selectedCount: newSelectedRows.length,
        selectedRows: newSelectedRows,
      });
    }
    setTableKey((prevKey) => prevKey + 1); 
  };

  const handleRowSelected = useCallback(
    (row) => {
      const isSelected = selectedRows.some(
        (selectedRow) => selectedRow.idUser === row.idUser
      );
      let newSelectedRows = [];

      if (isSelected) {
        newSelectedRows = selectedRows.filter(
          (selectedRow) => selectedRow.idUser !== row.idUser
        );
      } else {
        newSelectedRows = [...selectedRows, row];
      }

      setSelectedRows(newSelectedRows);
      setSelectedUsersToMigration({
        selectedCount: newSelectedRows.length,
        selectedRows: newSelectedRows,
      });
      setTableKey((prevKey) => prevKey + 1);
    },
    [selectedRows, setSelectedUsersToMigration]
  );

  const columns = [
    {
      name: (
        <input
          type="checkbox"
          className="select-all-checkbox"
          checked={selectAll}
          onChange={handleSelectAllClick}
        />
      ),
      selector: "checkbox",
      center: true,
      width: "50px",
      cell: (data) => (
        <input
          type="checkbox"
          checked={selectedRows.some((row) => row.idUser === data.idUser)}
          onChange={() => handleRowSelected(data)}
          className="row-checkbox"
        />
      ),
    },
    ...tableColumn,
  ];

  const handleChangePage = (page) => {
    setCurrentPage(page);
    setTableKey((prevKey) => prevKey + 1);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); 
    setTableKey((prevKey) => prevKey + 1);
  };

  const paginatedData = tableData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const customStyles = {
    rows: {
      style: {
        minHeight: "56px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        color: "gray",
      },
    },
    checkbox: {
      style: {
        backgroundColor: isActive ? "#6b48ff" : "gray",
        borderRadius: "50%",
        border: `2px solid ${isActive ? "#6b48ff" : "gray"}`,
      },
    },
  };

  return (
    <div
      className={`${!isActive ? "step-disabled" : ""} step-component-quarter`}
    >
      {index <= numberOfSteps - 1 && <hr style={{ margin: "21px 0" }} />}
      <div className="step-buttons-container">
        <div
          className="step-badge"
          style={{
            backgroundColor: isActive ? "#6b48ff" : "gray",
          }}
        >
          {index + 1}
        </div>
        <strong className="step-label">{label}</strong>
      </div>
      {isActive && (
        <div className="node-manager-import-table-wrapper mb-2">
          <span className="step-origin-company">
            Saindo da empresa: {companyOrigin?.name}
          </span>
          <div className="step-info-company">
            <span className="step-destination-company">
              Entrando na empresa: {companyTarget?.name}
            </span>
            <div className="select-count-container">
              {selectedUsersToMigration.selectedCount > 0 && (
                <div className="selected-count">
                  {selectedUsersToMigration.selectedCount}{" "}
                  {selectedUsersToMigration.selectedCount === 1
                    ? "usuário selecionado"
                    : "usuários selecionados"}
                </div>
              )}
              <button
                className="button-peop add-user-button"
                onClick={() => addUser(".step-component-second")}
              >
                <FiUserPlus className="mr-2" size={22} />
                Adicionar Usuários
              </button>
            </div>
          </div>
          {isActive && (
            <TableComponent
              key={tableKey}
              header={false}
              extraTableProps={{
                keyField: "idUser",
                columns: columns,
                data: paginatedData,
                className: "custom-table",
                progressComponent: (
                  <Loading containerStyle={{ height: 400 }}>
                    Carregando usuários...
                  </Loading>
                ),
                noDataComponent: NoDataComponent,
                onChangePage: handleChangePage,
                onChangeRowsPerPage: handleChangeRowsPerPage,
                paginationTotalRows: tableData.length,
                progressPending: loading && tableData?.length === 0,
                paginationComponentOptions: paginationUserComponentOptions,
                paginationServer: true,
                noHeader: true,
                fixedHeader: true,
                pagination: true,
                pointerOnHover: true,
                highlightOnHover: true,
                selectableRowsHighlight: true,
                customStyles: customStyles,
                selectableRowSelected: (row) =>
                  selectedRows.some((selectedRow) => selectedRow.idUser === row.idUser),
                paginationRowsPerPageOptions: getPaginationRowsPerPageOptions(
                  tableData.length
                ),
                paginationDefaultPage: currentPage,
                paginationPerPage: rowsPerPage,
                paginationServerOptions: {
                  persistSelectedOnSort: true,
                  persistSelectedOnPageChange: true,
                },
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default StepComponentQuarter;
