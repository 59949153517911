import React, { useState, Fragment } from "react";

import LineItem from "./LineItem";
import RowInfo from "../RowInfo";
import RecursiveItens from "./RecursiveItens";
import SemImagemSmall from "../../../../../assets/images/sem-imagem-small.jpg";

export default function AccordionItens({
  model,
  item,
  idUser,
  endPoint,
  indKey,
  idEnrollment,
  nodeCode,
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  function toggle(e) {
    isExpanded ? setIsExpanded(false) : setIsExpanded(true);

    e.stopPropagation();
  }

  return (
    <div className={`AccordionItens  ${isExpanded && 'border-open'}`}>
      <div
        className={`${model === "userTeam" ? "titleAccordion" : "titleAccordionRes"}`}
        onClick={(e) => {
          toggle(e);
        }}
      >
        {model === "userTeam" ? (
          <>
           <RowInfo isExpanded={isExpanded} role={item.department || '-'} image={item.photo || SemImagemSmall} name={item.name}/>
          </>
        ) : (
          <LineItem item={item} idUser={idUser} arcodeonStatus={isExpanded} />
        )}
      </div>
      {isExpanded && (
        <div className={`collapseAccordion ${model === "userTeam" && "collapseAccordionStudents"}`}>
          {
            <RecursiveItens
              endPoint={endPoint}
              idUser={idUser}
              indice={indKey}
              idEnrollment={idEnrollment}
              nodeCode={nodeCode}
              open={isExpanded}
            />
          }
        </div>
      )}
    </div>
  );
}
