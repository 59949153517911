import "./styles.modules.scss";

import React, { useState, useEffect, Fragment } from "react";
import { Button, Form } from "react-bootstrap";

import { BsTrash as TrashIco } from "react-icons/bs";

const LikertScale = ({ questionScales, setQuestionScales }) => {
  const addAlt = () => {
    setQuestionScales((item) => [...item, { title: "" }]);
  };

  const trashEvent = (index) => {
    const newquestionScale = [...questionScales];
    newquestionScale.splice(index, 1);
    setQuestionScales(newquestionScale);
  };

  const AlternativeCard = ({ item, index }) => {
    return (
      <div className="altIt">
        <div className="altItSelec">
          <Form.Control
            as="textarea"
            rows={1}
            defaultValue={item.title}
            onChange={(e) => {
              item.title = e.currentTarget.value;
            }}
            placeholder={`Alternativa ${index + 1}`}
          />
        </div>

        <div className="altRemove">
          <p>Remover</p>
          <button
            onClick={() => {
              trashEvent(index);
            }}
          >
            <TrashIco />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="form-group form-group-block-02">
      <div className="card">
        <div className="card-header template-card-header">Escala linear</div>
        <div className="card-body template-card-body">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 altContainer">
              {/* <p>Adicione as alternativas e marque a resposta correta</p> */}

              <div className="altList">
                {questionScales.length > 0 ? (
                  questionScales.map((item, index) => {
                    return (
                      <Fragment key={index}>
                        <AlternativeCard item={item} index={index} />
                      </Fragment>
                    );
                  })
                ) : (
                  <div className="noAlternative">Sem nenhuma alternativa.</div>
                )}
              </div>

              <Button
                variant="warning"
                className="btnAdd"
                onClick={() => {
                  addAlt();
                }}
              >
                Adicionar nova alternativa
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LikertScale;
