import React, { useState, useEffect } from "react";
import hexToRgba from "hex-to-rgba";


export const useStyles = (styleData:any) => {

  const appDetails = JSON.parse(localStorage.getItem("appDetails") as string);

  let stylesObj = {
    colors: {
      'dark': '#1d1d1d',
      'light': "#FFF",
      'primary': appDetails.secondaryColor,
      'secondary': appDetails.thirdColor,
      "tertiary": appDetails.fourthColor,
      "quaternary": "#6b48ff",
      "suport-success": "#35B352",
      "suport-failed": "#C54444",
      "suport-expired": "#cc5b08"
    },
    fonts: {
      "family": "SF Pro Text",
      "size-small": "12px",
      "size-paragraph": "14px",
      "size-h1": "28px",
      "size-h2": "24px",
      "size-h3": "21px",
      "size-h4": "18px",
      "line-height-small": "15px",
      "line-height-paragraph": "16px",
      "line-height-h1": "34px",
      "line-height-h2": "29px",
      "line-height-h3": "25px",
      "line-height-h4": "21px",
    },
  };


  if (styleData.length > 0) stylesObj = styleData;

  const createStyleColor = (colors: {}) => {
    Object.values(colors).map((value, index) => {
      // console.log(Object.keys(colors)[index] + " " + value);

      let name = Object.keys(colors)[index];
      let colorRgba: string = hexToRgba(value as string, "1");
      let nameRGB: string = colorRgba.replace("rgba(", "").replace(", 1)", "");
      let root = document.documentElement;

      root.style.setProperty(`--${name}`, `${nameRGB}`);

      return value;
    });
  };


  createStyleColor(stylesObj.colors);


  return stylesObj;
};
