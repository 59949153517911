import React, { useState, useEffect } from "react";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import { withRouter, useParams } from "react-router-dom";

import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";

import {
  getEquivalences,
  getToSetEquivalences,
  postEquivalences,
  removeEquivalents,
} from "../../../services/NexiDigitalAPI";

import Loading from "../../../components/Loading";

import { Tooltip } from "@material-ui/core";

import "./style.modules.scss";

import DataTable from "react-data-table-component";
import NoDataPlaceholder from "../../../components/NoDataPlaceholder";
import { useCallback } from "react";

const defaultPagination = {
  offset: 0,
  limit: 10,
  totalRecords: 999,
};

const paginationComponentOptions = {
  rowsPerPageText: "Itens por página",
  rangeSeparatorText: "de",
};

const EquivalenceManagement = (props) => {
  const colunsDefault =   [
    {
      name: "Título",
      selector: "title",
      reorder: false,
      sortable: false,
    },
    {
      name: "Origem",
      selector: (item) => (item?.parentNodes?.map(elem => elem.title).join(' • ')) || "",
      reorder: false,
      sortable: false,
    },
  ]
  const columnsEquivalence = [
    {
      name: "Título",
      selector: "origin.title",
      reorder: false,
      sortable: false,
    },
    {
      name: "Origem",
      selector: (item) => (item?.origin.parentNodes?.map(elem => elem.title).join(' • ')) || "",
      reorder: false,
      sortable: false,
    },
    {
      name: "Ações",
      wrap: true,
      width: "200px",
      center: true,
      style: { textAlign: "center" },
      cell: (item) => (
        <Tooltip title="Remover" placement="top">
          <span className="btn btn-sm float-right" onClick={() => equivalenceEvent(item, "remove")}>
            <RemoveCircleOutlineOutlinedIcon style={{ color: "#dc3545" }} />
          </span>
        </Tooltip>
      ),
    },
  ];

  const columnsNotEquivalence = [
    ...colunsDefault,
    {
      name: "Ações",
      wrap: true,
      width: "200px",
      center: true,
      style: { textAlign: "center" },
      cell: (item) => (
        <Tooltip title="Adicionar" placement="top">
          <span className="btn btn-sm float-right" onClick={() => equivalenceEvent(item, "add")}>
            <AddCircleOutlineOutlinedIcon style={{ color: "#1e7e34" }} />
          </span>
        </Tooltip>
      ),
    },
  ]


  const {idNode}:{idNode: string} = useParams();
  const idNodeTarget = idNode;

  const [currentTitle, setCurrentTitle] = useState("");
  const [pageEquivalence, setPageEquivalence] = useState([]);
  const [loadingPageEquivalence, setLoadingPageEquivalence] = useState(false);
  const [loadingPageNotEquivalence, setLoadingPageNotEquivalence] = useState(false);
  const [pageNotEquivalence, setPageNotEquivalence] = useState([]);
  const [paginationPageEquivalence, setPaginationPageEquivalence] = useState(defaultPagination);
  const [paginationPageNotEquivalence, setPaginationPageNotEquivalence] = useState( defaultPagination);

  const getParamsRequest = useCallback(
    (customPagination, clearSearchText, pagination) => {
      const queryParams = {
        offset: customPagination?.offset || pagination.offset,
        limit: customPagination?.limit || pagination.limit,
        idNodeTarget,
        title: currentTitle
      };
      if (clearSearchText || customPagination?.searchText || currentTitle) {
        queryParams.title = customPagination?.searchText || currentTitle;
      }
      return queryParams;
    },
    [idNodeTarget, currentTitle],
  );

  const getPageEquivalence = useCallback(
    async (customPagination, clearSearchText) => {
      setLoadingPageEquivalence(true);

      try {
        const containerApi = await getEquivalences(
          getParamsRequest(customPagination, clearSearchText, paginationPageEquivalence),
        );
        setPageEquivalence(containerApi.result);

        setPaginationPageEquivalence((prev) => {return {...prev, totalRecords: containerApi.totalRecords}} );

      } catch (e) {
        setPageEquivalence([]);
      } finally {
        setLoadingPageEquivalence(false);
      }
    },
    [ getParamsRequest],
  );

  const getPageNotEquivalence = useCallback(
    async (customPagination, clearSearchText) => {
      setLoadingPageNotEquivalence(true);
      try {
        const containerApi = await getToSetEquivalences(
          getParamsRequest(customPagination, clearSearchText, paginationPageNotEquivalence),
        );
        setPageNotEquivalence(containerApi.result);
        setPaginationPageNotEquivalence((prev) => ({...prev, totalRecords: containerApi.totalRecords}));

      } catch (e) {
        setPageNotEquivalence([]);
      } finally {
        setLoadingPageNotEquivalence(false);
      }
    },
    [getParamsRequest],
  );

  useEffect(() => {
    getPageEquivalence(paginationPageEquivalence,currentTitle);
  }, [getPageEquivalence]);

  useEffect(() => {
    getPageNotEquivalence(paginationPageNotEquivalence,currentTitle);
  }, [getPageNotEquivalence]);

  const getParamsChangePage = (page, pagination) => ({
    ...pagination,
    offset: --page * pagination.limit,
  });

  const getParamsRowsPerPage = (limit, page, pagination) => ({
    ...pagination,
    limit,
    offset: --page * limit,
  });

  const onChangePageEquivalence = (page) => {
    const _pagination = getParamsChangePage(page, paginationPageEquivalence)
    setPaginationPageEquivalence(_pagination)
    getPageEquivalence(_pagination,currentTitle);
  };

  const onChangePageEquivalenceRowsPerPage = (limit, page) => {
    const _pagination = getParamsRowsPerPage(limit, page, paginationPageEquivalence)
    setPaginationPageEquivalence(_pagination)
    getPageEquivalence(_pagination,currentTitle);
  };

  const onChangePageNotEquivalence = (page) => {
    const _pagination = getParamsChangePage(page, paginationPageNotEquivalence)
    setPaginationPageNotEquivalence(_pagination)
    getPageNotEquivalence(_pagination,currentTitle);
    // getUserNotEnroll(_pagination);
  }

  const onChangePageNotEquivalenceRowsPerPage = (limit, page) => {
    const _pagination = getParamsRowsPerPage(limit,page, paginationPageNotEquivalence)
    setPaginationPageNotEquivalence(_pagination)
    getPageNotEquivalence(_pagination, currentTitle);
  }

  const controlEquivalence =  async (el, type) => {
    const { id } = el;
    if (type === "add") {
      setLoadingPageNotEquivalence(true);
      await postEquivalences({ idNodeTarget, idNodeOrigin: id });
    }else{
      setLoadingPageEquivalence(true);
      await  removeEquivalents({ idNodeTarget, idNodeOrigin: id });
    }
    getPageNotEquivalence(paginationPageNotEquivalence,currentTitle);
    getPageEquivalence(paginationPageEquivalence,currentTitle);
  };

  const equivalenceEvent = async (el, type) => {
   await controlEquivalence(el, type);
  };

  const handleChangeInput = (e) => {
    setPaginationPageEquivalence((prev) => ({...defaultPagination, limit: prev.limit}))
    setPaginationPageNotEquivalence((prev) => ({...defaultPagination, limit: prev.limit}))
    setCurrentTitle(e.target.value)
  }


  return (
    <div className="container groups">
      <div className="header-title-wrapper">
        <div className="header-title">
          <button
            className="btnGoBack"
            onClick={() => {
              props.history.goBack();
            }}
          >
            <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
          </button>
          Configurar Equivalência
        </div>
      </div>

      <div className="post-form">
        <div className="form-group">
          <div className="card">
            <div
              className="card-header template-card-header"
              style={{
                color: "#FFF",
                backgroundColor: "#6b48ff",
              }}
            >
              Buscar páginas
            </div>
            <div className="card-body template-card-body">
              <div className="row">
                <div className="col-md-10 input-container">
                  <input
                    type="text"
                    className="form-control input-default"
                    id="groupDetail"
                    aria-describedby="página"
                    value={currentTitle}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="user-list-container">

        <div className="card">
          <div
            className="card-header template-card-header"
            style={{
              color: "#FFF",
              backgroundColor: "#6b48ff",
            }}
          >
            Páginas selecionadas
          </div>
          <div className="card-body template-card-body">
          <DataTable
               key='userEnrollment'
              columns={columnsEquivalence}
              data={pageEquivalence}
              noDataComponent={<NoDataPlaceholder style={{height: 50}} text="Ainda não há páginas selecionadas" />}
              progressComponent={
                <Loading containerStyle={{ height: 100 }}>Carregando páginas...</Loading>
              }
              onChangePage={onChangePageEquivalence}
              onChangeRowsPerPage={onChangePageEquivalenceRowsPerPage}
              progressPending={loadingPageEquivalence}
              paginationTotalRows={paginationPageEquivalence.totalRecords}
              paginationComponentOptions={paginationComponentOptions}
              noHeader
              fixedHeader
              pagination
              paginationServer
              highlightOnHover
            ></DataTable>
          </div>
        </div>

        <div className="user-list-separator"></div>

        {/* Lista usuarios Disponiveis*/}
        <div className="card">
          <div
            className="card-header template-card-header"
            style={{
              color: "#FFF",
              backgroundColor: "#6b48ff",
            }}
          >
            Selecionar páginas
          </div>
          <div className="card-body template-card-body">
            <DataTable
              key='userNotEnrollment'
              columns={columnsNotEquivalence}
              data={pageNotEquivalence}
              noDataComponent={<NoDataPlaceholder  style={{height: 50}}  text="Ainda não há páginas" />}
              progressComponent={
                <Loading containerStyle={{ height: 100 }}>Carregando páginas...</Loading>
              }
              onChangePage={onChangePageNotEquivalence}
              onChangeRowsPerPage={onChangePageNotEquivalenceRowsPerPage}
              paginationComponentOptions={paginationComponentOptions}
              progressPending={loadingPageNotEquivalence}
              paginationTotalRows={paginationPageNotEquivalence.totalRecords}
              noHeader
              fixedHeader
              pagination
              paginationServer
            ></DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(EquivalenceManagement);
