import React, { useContext, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Switch from "react-bootstrap/esm/Switch";
import Label from "../../../Label";
import { ContentData, StatusTypes } from "../../nodeParamenters.model";
import "./style.scss";

import CustomTooltip from "components/CustomTooltip";
import { LMCreateContext } from "containers/PeopleCraft/LearningManagementNew/Contexts/LMContext";
import { BiSolidInfoCircle } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { putLearningConfiguration } from "services/NexiDigitalAPI";
import LinkGroupsVisibility from "../LinkGroupsVisibility";

interface TagsInputProps {
  contentData: ContentData;
  setContentData: any;
  handleStatusChange: (value: StatusTypes) => void;
  handleBlur: any;
  handleValidityChange: any;
  publishedDisabled: boolean;
  archivedDisabled: boolean;
  draftDisabled: boolean;
  editNodeCode: boolean;
  disabled: boolean
}

const AdditionalSettingsComponent: React.FC<TagsInputProps> = ({
  contentData,
  handleStatusChange,
  handleBlur,
  handleValidityChange,
  setContentData,
  publishedDisabled,
  archivedDisabled,
  draftDisabled,
  editNodeCode,
  disabled = false
}) => {
  const history = useHistory()
  const [linkGroupModal, setlinkGroupModal] = useState(false);
  const [hideMenu, setHideMenu] = useState(contentData?.sideMenu);
  const { sidebarChatInteration } = useContext(LMCreateContext);
  const hasDadEntryPoint = () => {
    let tempHasDadEntryPoint = false;

    if (contentData?.parentNodes) {
      tempHasDadEntryPoint =
        contentData.parentNodes[contentData.parentNodes.length - 1].entryPoint == 1;
    }

    return tempHasDadEntryPoint;
  };

  const hasDadPublished = () => {
    let tempHasDadPublished = false;

    if (contentData?.parentNodes) {
      tempHasDadPublished =
        contentData.parentNodes[contentData.parentNodes.length - 1].status === "PUBLISHED" &&
        contentData.parentNodes[contentData.parentNodes.length - 1].nodeCode.split(".").length !==
        1 &&
        contentData.parentNodes[contentData.parentNodes.length - 1].nodeType === "LEARNING";
    }

    return tempHasDadPublished;
  };

  const hasDadArchived = () => {
    let tempHasDadArchived = false;

    if (contentData?.parentNodes) {
      tempHasDadArchived =
        contentData.parentNodes[contentData.parentNodes.length - 1].status === "ARCHIVED";
    }

    return tempHasDadArchived;
  };

  const isFirstNode = () => {
    let blIsFirstNode = false;

    if (
      contentData.hasOwnProperty("nodeCode") &&
      contentData.nodeCode != null &&
      contentData != undefined
    ) {
      if (contentData.nodeCode.split(".").length == 1) {
        blIsFirstNode = true;
      }
    }

    return blIsFirstNode;
  };

  const updateHideMenu = async (item) => {
    try {
      await putLearningConfiguration(item, contentData.originalNodeCode);
    } catch (e) {
      console.log(e);
    }
  };

  const [validityYear, setValidityYear] = useState<number>((Math.floor((contentData.validity || 0) / 12)));
  const [validityMonth, setValidityMonth] = useState<number>((contentData.validity || 0) % 12);
  const _handleValidityBlur = (years: number, months: number) => {
    handleBlur({ validity: years * 12 + months });
  }

  return (
    <>
      <Modal
        show={linkGroupModal}
        className={"modalLinkGroup"}
        onHide={() => {
          setlinkGroupModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Configurações de visibilidade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LinkGroupsVisibility nodeCode={contentData.nodeCode} />
        </Modal.Body>
      </Modal>

      <div className="form-data">
        <Label name="Status" htmlFor="status" />
        <Form.Control
          disabled={
            contentData?.status === StatusTypes.ARCHIVED ||
            isFirstNode() === true ||
            hasDadPublished() === true
          }
          as="select"
          value={contentData.status || "0"}
          onChange={(e) => {
            handleStatusChange(e.target.value as StatusTypes);
          }}
        >
          <option disabled={draftDisabled} value={StatusTypes.DRAFT}>
            Rascunho
          </option>
          <option disabled={publishedDisabled} value={StatusTypes.PUBLISHED}>
            Publicado
          </option>
          <option disabled={archivedDisabled} value={StatusTypes.ARCHIVED}>
            Arquivado
          </option>
        </Form.Control>
      </div>

      {contentData.entryPoint && (
        <div className="form-data">
          <div className="header-form-data">
            <CustomTooltip
              title="Defina neste campo a validade do treinamento para acompanhar a validade dos treinamentos obrigatórios das pessoas usuárias. Os dados poderão ser consultados no Relatório de Validade dos Treinamentos."
              placement="top"
              disableHoverListener={false}
              arrow
            >
              <div className="importInfo">
                <BiSolidInfoCircle />
              </div>
            </CustomTooltip>
            <Label name="Validade do treinamento" htmlFor="validity" />
          </div>

          <div className="form-data row">
            <div className="form-group col-6">
              <input
                value={validityYear || 0}
                disabled={contentData?.status === StatusTypes.DRAFT ? false : true}
                className="form-control"
                maxLength={2}
                placeholder="Anos"
                type="number"
                id="node-validity-anos"
                aria-describedby="node-horas"
                onChange={(e) => setValidityYear(Number(e.target.value))}
                onBlur={(e) => _handleValidityBlur(Number(e.target.value), validityMonth)}
              />
              <span className="info">{(validityYear || 0) === 1 ? 'ano' : 'anos'}</span>
            </div>
            <div className="form-group col-6">
              <input
                value={validityMonth || 0}
                disabled={contentData?.status === StatusTypes.DRAFT ? false : true}
                className="form-control"
                maxLength={2}
                placeholder="Anos"
                type="number"
                id="node-validity-anos"
                aria-describedby="node-horas"
                max={11}
                onChange={(e) => setValidityMonth(Number(e.target.value))}
                onBlur={(e) => _handleValidityBlur(validityYear, Number(e.target.value))}
              />
              <span className="info">{(validityMonth || 0) === 1 ? 'mês' : 'meses'}</span>
            </div>
          </div>
        </div>
      )}

      {!isFirstNode() && (
        <div className="form-data">
          <div className="header-form-data">
            <CustomTooltip
              title="Caso selecione a opção restrita é necessário realizar a seleção do(s) público-alvo(s)."
              placement="top"
              disableHoverListener={false}
              arrow
            >
              <div className="importInfo">
                <BiSolidInfoCircle />
              </div>
            </CustomTooltip>
            <Label name="Visibilidade da página" htmlFor="visibility" />
          </div>

          {/* <FormGroup>
          <Form.Check
            inline
            label="Pública"
            name="visibilityGroup"
            type="radio"
            value={0}
            checked={contentData.visibility != 1}
            onChange={(e) => {
              handleVisibilityChange( e.target.value );
            }}
          />

          <Form.Check
            inline
            label="Restrita"
            name="visibilityGroup"
            type="radio"
            value={1}
            checked={contentData.visibility == 1}
            onChange={(e) => {
              handleVisibilityChange( e.target.value );
            }}
          />
        </FormGroup>

        {(contentData.visibility == 1) && */}

          <button
            className="btn btn-warning"
            onClick={() => {
              setlinkGroupModal(true);
            }}
          >
            Definir visibilidade da página
          </button>

          {/* } */}
        </div>
      )}



      <div
        className={`form-data flex-row ${contentData?.status !== StatusTypes.DRAFT ? "switch-disabled" : ""
          }`}
      >
        <CustomTooltip
          title="Permitir que essa página seja importada para ser utilizada em outras estruturas de aprendizagem."
          placement="top"
          disableHoverListener={false}
          arrow
          className="header-form-data"
        >
          <div className="importInfo">
            <BiSolidInfoCircle />
          </div>
        </CustomTooltip>
        <Switch
          color="primary"
          id="handleAvailableToImport"
          checked={contentData?.availableToImport}
          disabled={contentData?.status !== StatusTypes.DRAFT}
          onChange={(e) => {
            handleBlur({
              availableToImport: e.target.checked,
            });
          }}
        />
        <Label
          className={"cursor-pointer"}
          name="Permitir importação da página"
          htmlFor="handleAvailableToImport"
        />
      </div>

      {!hasDadEntryPoint() && !isFirstNode() && contentData.nodeType === 'LEARNING' && (
        <div className="form-data">
          <Label name="Interação da página" htmlFor="" />
          <div className="divider-interaction" />
          <button
            className="btn btn-warning"
            onClick={() => {
              sidebarChatInteration(true, contentData);
            }}
          >
            Configurar e moderar interação
          </button>

          {/* } */}
        </div>
      )}
      {contentData.nodeType === 'LEARNING' && (
        <div className="form-data">
          <Label name="Definir equivalência entre páginas" htmlFor="" />
          <div className="divider-interaction" />
          <button
            className="btn btn-warning"
            onClick={() => {
              history.push(`/gestao-de-aprendizagem/equivalencia-entre-paginas/${contentData.idNode}`);

            }}
          >
            Configurar equivalência
          </button>

          {/* } */}
        </div>
      )}
    </>
  );
};

export default AdditionalSettingsComponent;
