import "./styles.modules.scss";

import React, { Fragment } from "react";
import { Button, Form } from "react-bootstrap";

import { BsTrash as TrashIco } from "react-icons/bs";

const MultiLikertScale = ({
  questionScales,
  setQuestionScales,
  questionOptions,
  setQuestionOptions,
}) => {
  const addAlt = (model) => {
    if (model === "scales") {
      setQuestionScales((item) => [...item, { title: "" }]);
    } else {
      setQuestionOptions((item) => [...item, { description: "", isCorrect: true }]);
    }
  };

  const trashEvent = (index, model) => {
    if (model === "scales") {
      const newquestionScale = [...questionScales];
      newquestionScale.splice(index, 1);
      setQuestionScales(newquestionScale);
    } else {
      const newQuestionOptions = [...questionOptions];
      newQuestionOptions.splice(index, 1);
      setQuestionOptions(newQuestionOptions);
    }
  };

  const AlternativeCard = ({ item, index, model }) => {
    return (
      <div className="altIt">
        <div className="altItSelec">
          <Form.Control
            as="textarea"
            rows={1}
            defaultValue={item[model === "scales" ? "title" : "description"]}
            onChange={(e) => {
              item[model === "scales" ? "title" : "description"] = e.currentTarget.value;
            }}
            placeholder={`${model === "scales" ? "Coluna" : "Linha"} ${index + 1}`}
          />
        </div>

        <div className="altRemove">
          <p>Remover</p>
          <button
            onClick={() => {
              trashEvent(index, model);
            }}
          >
            <TrashIco />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="form-group form-group-block-02">
      <div className="card">
        <div className="card-header template-card-header">Escala múltipla</div>
        <div className="card-body template-card-body d-flex flex-row">
          <div className="col-lg-7 col-md-7 col-sm-12 altContainer altContainerInit">
            <div className="altList">
              {questionOptions.length > 0 ? (
                questionOptions.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <AlternativeCard item={item} index={index} model="options" />
                    </Fragment>
                  );
                })
              ) : (
                <div className="noAlternative">Sem nenhuma linha.</div>
              )}
            </div>

            <Button
              variant="warning"
              className="btnAdd"
              onClick={() => {
                addAlt("options");
              }}
            >
              Adicionar nova linha
            </Button>
          </div>

          <div className="col-lg-5 col-md-5 col-sm-12 altContainer">
            <div className="altList">
              {questionScales.length > 0 ? (
                questionScales.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <AlternativeCard item={item} index={index} model="scales" />
                    </Fragment>
                  );
                })
              ) : (
                <div className="noAlternative">Sem nenhuma coluna.</div>
              )}
            </div>

            <Button
              variant="warning"
              className="btnAdd"
              onClick={() => {
                addAlt("scales");
              }}
            >
              Adicionar nova coluna
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiLikertScale;
