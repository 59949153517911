import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { StrictMode } from 'react';
import "./themes/import.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { desabilitaConsoleLog } from "./utils/Utils";
import { ClearCacheProvider } from "react-clear-cache";

// desabilitaConsoleLog()

ReactDOM.render(
  <StrictMode>

  <Router>
    <ClearCacheProvider duration={5000}>
      <App />
    </ClearCacheProvider>
  </Router>
  </StrictMode>
  ,

  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
