import "./styles.modules.scss";

import Loading from "components/Loading";

import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";

import { Tooltip, withStyles } from "@material-ui/core";
import { Button, Form } from "react-bootstrap";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { BsFilter } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import DataTable from "react-data-table-component";

import { dataEvaluation, getEvaluationTypeName, convertToBrDate } from "utils/Utils";
import {
  getMenuData,
  getEvaluations,
  deleteEvaluations,
  postLearningEvaluations,
} from "services/NexiDigitalAPI";

import CompaniesModal from "components/CompaniesModal/CompaniesModal";

import ModalDialog from "../../../PeopleCraft/Modals/ModalDialog";
import HeaderCrumb from "../../Components/HeaderCrumb";

const NoData = () => {
  return <div className="noData">Nenhum registro encontrado</div>;
};

const HighlightText = ({ highlight, value }) => {
  const limit = 100;
  let newText = value !== null ? value : "";

  if (limit && value !== null) {
    newText = value.length > limit ? `${newText.substr(0, limit)}...` : value;
  }

  return <div>{getHighlightedText(newText, value, highlight)}</div>;
};

function getHighlightedText(textLimit, text, highlight) {
  // Split text on highlight term, include term itself into parts, ignore case
  var parts = text.split(new RegExp(`(${highlight})`, "gi"));

  // console.log(parts, highlight, highlight.length);

  return highlight.length > 0
    ? parts.map((part, index) => (
        <React.Fragment key={index}>
          {part.toLowerCase() === highlight.toLowerCase() ? (
            <b style={{ backgroundColor: "#e8bb49" }}>{part}</b>
          ) : (
            part
          )}
        </React.Fragment>
      ))
    : textLimit;
}

const customTableStyles = {
  rows: {
    style: {
      minHeight: "auto",
      padding: "5px 0px",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      textAlign: "center",
      fontSize: "14px",
      fontWeight: "bold",
    },
  },
};

const paginationComponentOptions = {
  rowsPerPageText: "Conteúdos por página",
  rangeSeparatorText: "de",
};

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "14px",
  },
}))(Tooltip);

const BankQuestions = () => {
  const history = useHistory();

  const { evaluationType } = useParams();

  const adminLevel = getMenuData()?.admin;

  const [availableList, setAvailableList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [loadingAvailableList, setLoadingAvailableList] = useState(false);
  const [offset, setOffset] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [idQuestionEdit, setIdQuestionEdit] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  // const [idCompanyCurrent, setIdCompanyCurrent] = useState(getMenuData().idCompany);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [orderBy, setOrderBy] = useState("TITLE");
  const [orderBySort, setOrderBySort] = useState("desc");

  const [selectItem, setSelectItem] = useState(null);
  const [showCreateQuestion, setShowCreateQuestion] = useState(false);

  const isAdminSecondDegree = () => {
    return getMenuData()?.admin >= 2;
  };

  const editEvaluation = (e, res) => {
    history.push(`/evaluation-manager/${evaluationType}/null/${res.idEvaluation}/1`);
    // setIdQuestionEdit(question.idQuestion);
    // setShowCreateQuestion(true);
  };

  const deleteEvaluation = (e, res) => {
    setSelectItem(res);
  };

  const trashConfirmEvent = (e) => {
    // console.log(selectItem);
    setLoadingAvailableList(true);
    deleteEvaluations(selectItem.idEvaluation).then(() => {
      getAvailableQuestions(0, 10);
    });
    setSelectItem(null);
  };

  const handleSort = async (column, sortDirection) => {
    setOrderBy(column.selector === "title" ? "TITLE" : "CREATION_DATE");
    setOrderBySort(sortDirection);
  };

  const createEvaluation = () => {
    const KEData = {
      idCompany: selectedCompany?.idCompany || getMenuData().idCompany,
      idEvaluationType: dataEvaluation(evaluationType).idEvaluationType,
      evaluationParameters: [],
      evaluationTags: [],
      categories: [],
    };
    setLoadingAvailableList(true);
    postLearningEvaluations(KEData)
      .then((res) => {
        const idEvaluation = res.data.idEvaluation;
        console.log(idEvaluation);

        setTimeout(() => {
          history.push(`/evaluation-manager/${evaluationType}/null/${idEvaluation}/`);
          setLoadingAvailableList(false);
        }, 1000 * 1);
      })
      .catch((err) => {
        console.log("Error na criação da avaliação de conhecimento", err.request);
        // alert("Error na criação da avaliação de conhecimento");
      });
  };

  const columns = [
    {
      name: `Título`,
      selector: "title",
      sortable: true,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (res) =>
        res.title ? <HighlightText value={res.title} highlight={searchString} /> : "-",
    },
    {
      name: "Tags",
      sortable: false,
      selector: "tags",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (res) => (
        <div className={"tagItnsList"}>
          {res.tags
            ? res.tags?.split(",").map((tag, index) => (
                <div className="tagItns">
                  <span key={index}>{tag}</span>
                </div>
              ))
            : "-"}
        </div>
      ),
    },
    {
      name: "Autor",
      sortable: false,
      selector: "author",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (res) => <div>{res.author ? res.author : "-"}</div>,
    },
    {
      name: "Data da criação",
      sortable: true,
      selector: "creationDate",
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (res) => <div>{convertToBrDate(res.creationDate)}</div>,
    },
    {
      name: "Ação",
      selector: "checkbox",
      sortable: false,
      center: true,
      wrap: true,
      style: { textAlign: "center" },
      cell: (res) => (
        <div className="btnsList">
          <CustomTooltip
            title={"Editar"}
            placement="top"
            disableHoverListener={Boolean(res.hasQuestionnaire)}
          >
            <Button
              className={`${Boolean(res.hasQuestionnaire) ? "disabledBtn" : ""}`}
              onClick={(e) => {
                editEvaluation(e, res);
              }}
              disabled={Boolean(res.hasQuestionnaire)}
            >
              <FaRegEdit style={{ fontSize: 22, marginRight: 3 }} />
            </Button>
          </CustomTooltip>
          <CustomTooltip title={"Deletar"} placement="top">
            <Button
              className={`${Boolean(res.hasQuestionnaire) ? "disabledBtn" : ""}`}
              onClick={(e) => {
                deleteEvaluation(e, res);
              }}
              disabled={Boolean(res.hasQuestionnaire)}
            >
              <FiTrash2 style={{ fontSize: 22, marginRight: 3 }} />
            </Button>
          </CustomTooltip>
        </div>
      ),
    },
  ];

  const getAvailableQuestions = async (newOffset, limit) => {
    setLoadingAvailableList(true);

    const haveNewOffset = newOffset || newOffset === 0;
    if (haveNewOffset) {
      setOffset(newOffset);
    }

    const questionsParameters = {
      idCompany: selectedCompany?.idCompany || getMenuData().idCompany,
      searchString,
      offset: haveNewOffset ? newOffset : offset,
      limit: limit ? limit : pageLimit,
      orderBy: orderBy,
      sort: orderBySort,
      evaluationType: evaluationType,
    };
    const questionsData = await getEvaluations(questionsParameters).catch((err) => {
      console.log("error", err);
    });
    setLoadingAvailableList(false);
    if (questionsData) {
      setAvailableList(questionsData?.result);
      setTotalRecords(questionsData.totalRecords);
    }
  };

  const onChangePage = (page) => {
    setPageNumber(page);
    const newOffset = (page - 1) * pageLimit;
    getAvailableQuestions(newOffset);
  };

  const onChangeRowsPerPage = (limit, page) => {
    setPageLimit(limit);
    const newOffset = (page - 1) * limit;
    getAvailableQuestions(newOffset, limit);
  };

  useEffect(() => {
    if (selectedCompany.idCompany) {
      getAvailableQuestions(0, 10);
    }
  }, [selectedCompany]);

  useEffect(() => {
    getAvailableQuestions(0, 10);
  }, [orderBy, orderBySort]);

  // const createQuestionComplete = () => {
  //   setPageNumber(0);
  //   setIdQuestionEdit(null);
  //   setOffset(0);
  //   setTotalRecords(0);
  //   getAvailableQuestions(0, 10);
  // };

  const HeaderCrumbLink = () => {
    return (
      <>
        <Link to={`/evaluation-management`}>Gestão de Avaliações</Link>
        <span className="headerCrumbTxt">{"/"}</span>
        <Link to={`/gestao-de-avaliacoes/evaluation-control/${evaluationType}`}>
          Avaliação de {getEvaluationTypeName(evaluationType)}
        </Link>
        <span className="headerCrumbTxt">{"/ Banco de Avaliações"}</span>
      </>
    );
  };

  return (
    <>
      <HeaderCrumb>
        <HeaderCrumbLink />
      </HeaderCrumb>
      <div className="evaluationBank">
        <div className="modal-content">
          <div className="modal-body-warning">
            <div className="form-group form-group-block-02">
              <div className="card">
                <div className="card-body template-card-body">
                  <h4>Banco de avaliações de {getEvaluationTypeName(evaluationType)}</h4>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      {isAdminSecondDegree() && (
                        <div className="containCompanies">
                          <CompaniesModal
                            showAllCompaniesOption
                            showInput={isAdminSecondDegree()}
                            setSelectedCompany={setSelectedCompany}
                            selectedCompany={selectedCompany}
                          />
                        </div>
                      )}

                      <div className="containInfo">
                        <div className="containCompanies">
                          <div className="searchInfo">
                            <AiOutlineSearch />
                            <Form.Control
                              value={searchString}
                              onKeyDown={(input) => {
                                if (input.key === "Enter") {
                                  getAvailableQuestions();
                                }
                              }}
                              onChange={(e) => setSearchString(e.target.value)}
                              placeholder={`Pesquisar...`}
                            />
                            <button onClick={() => setSearchString("")}>
                              <AiOutlineClose />
                            </button>
                          </div>

                          <Button
                            variant="primary"
                            onClick={() => {
                              setPageNumber(0);
                              getAvailableQuestions();
                            }}
                          >
                            <BsFilter />
                            Filtrar
                          </Button>
                        </div>

                        <div className="listBtns">
                          <Button variant="warning" onClick={() => createEvaluation()}>
                            Criar nova avaliação
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group form-group-block-02">
              <div className="card">
                <div className="card-body template-card-body">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="node-manager-import-table-wrapper">
                        {loadingAvailableList && availableList?.length > 0 && (
                          <div className="KELoadingBackdrop">
                            <Loading>Carregando Avaliações...</Loading>
                          </div>
                        )}
                        <DataTable
                          columns={columns}
                          data={availableList}
                          customStyles={customTableStyles}
                          progressComponent={
                            <Loading containerStyle={{ height: 400 }}>
                              Carregando Avaliações...
                            </Loading>
                          }
                          noDataComponent={<NoData />}
                          onChangePage={onChangePage}
                          onChangeRowsPerPage={onChangeRowsPerPage}
                          paginationTotalRows={totalRecords}
                          paginationComponentOptions={paginationComponentOptions}
                          progressPending={loadingAvailableList && availableList?.length === 0}
                          paginationServer
                          noHeader
                          fixedHeader
                          pagination
                          onSort={handleSort}
                          sortServer
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="containerBtn">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 baseBtns">
                  {/* <div className="errorText">{importError}</div> */}
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      history.push(`/gestao-de-avaliacoes/evaluation-control/${evaluationType}`);
                    }}
                    style={{ zIndex: 10 }}
                  >
                    Voltar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalDialog
        text={"Tem certeza de que deseja deletar essa avaliação?"}
        show={!!selectItem}
        setShow={setSelectItem}
        btnCall={{
          className: "",
          variantBtn: "danger",
          text: "Deletar",
          callEvent: () => {
            trashConfirmEvent();
          },
        }}
      />
    </>
  );
};

export default BankQuestions;
