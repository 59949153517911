import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { indexOf } from "underscore";
import { colors } from "../../../../../utils/Colors";
import styles from "./styles.module.scss";

const Table = ({ dados, loading }) => {
  const columns = [
    { name: "Data", selector: "creationdate", sortable: true },
    { name: "Seção", selector: "section", sortable: true },
    { name: "Nome", selector: "userName", sortable: true },
    { name: "Título", selector: "title", sortable: true },
    { name: "Status", selector: "status", sortable: true },
  ];

  if (typeof dados != "undefined" && dados.length > 0) {
    dados.map((item, index) => {
      if (indexOf(item.creationdate, "/") === -1) {
        let dt = item.creationdate.replace("Z", "").replace("z", "");
        let dateItem = new Date(dt);
        console.log(item.creationdate);
        console.log(dt);
        console.log(dateItem);
        let options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        };
        dateItem = dateItem.toLocaleDateString("pt-BR", options);
        console.log(dateItem);
        dados[index].creationdate = dateItem;
      }
    });
  }

  const tableData = {
    columns: columns,
    data: dados,
  };

  const customTableStyles = {
    rows: {
      style: {
        minHeight: "60px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "30px", // override the cell padding for head cells
      },
    },
    cells: {
      style: {
        paddingLeft: "30px", // override the cell padding for data cells
      },
    },
    header: {
      style: {
        fontSize: "22px",
        minHeight: "56px",
        paddingLeft: "28px",
        paddingRight: "8px",
        paddingTop: "30px",
        paddingBottom: "15px",
      },
    },
  };

  const loadingComponent = () => {
    return (
      <div className="tela-login-loading">
        <div className="loader-container" style={{ margin: "300px 0" }}>
          <div
            style={{
              borderTopColor: colors.gray,
              position: "initial",
              margin: "initial",
            }}
            className="loader loader-center loader-big"
          ></div>
          <span
            style={{
              marginTop: 10,
              color: colors.gray,
              fontSize: 20,
            }}
          >
            Carregando dados...
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="report-table-container">
      <DataTableExtensions filter={false} print={false} {...tableData} exportHeaders={true}>
        <DataTable
          columns={columns}
          data={dados}
          pagination
          className={styles.dataTableFormat}
          customStyles={customTableStyles}
          progressPending={loading}
          progressComponent={loadingComponent()}
          noHeader={true}
          noDataComponent="Não foi encontrado nenhum dado"
        />
      </DataTableExtensions>
    </div>
  );
};

export default Table;
