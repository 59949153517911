import React, { useState, useEffect } from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./styles.scss";

import { useParams } from "react-router-dom";


import MoveUsers from "./MoveUsers";
import MovementHistory from "./MovementHistory";

import { getMenuRoutes, getParameterByName, getPermission } from "../../utils/Utils";

export default function Movements() {
  const initialRel = {
    learning: false,
    learningreport: false,
  };

  const [chooseTab, setChooseTab] = useState(0);
  const [chooseRel, setChooseRel] = useState(initialRel);

  const getTab = (text) => {
    switch (text) {
      case "MoveUsers":
        return 1;
      case "MovementHistory":
        return 2;
      default:
        return 0;
    }
  };

  /* [Permission Module] - Permissions states */
  const [permissionTabLearningGeneral, setPermissionTabLearningGeneral] = useState(false);
  const [permissionTabLearningReport, setPermissionTabLearningReport] = useState(true);

  /* [Permission Module] - Menu Options */
  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();

    const objCurrentMenu = menuOptionList.filter((menu) => menu.text === "Relatórios");

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);

      getPermissionsPage(resources);
    }
  }

  /* [Permission Module] - Permission attrs in page by login data information */
  function getPermissionsPage(resources) {
    try {
      setPermissionTabLearningGeneral(getPermission(resources[0], "Treinamentos - Visão Geral"));
      setPermissionTabLearningReport(getPermission(resources[0], "Treinamentos - Relatórios"));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    /* [Permission Module] - Function calls */
    getMenuOption();
    const tabs = getParameterByName("tabs");
    const rel = getParameterByName("rel");
    setChooseTab(getTab(tabs));
    setChooseRel({
      learning: tabs === "learning" ? rel : false,
      learningreport: tabs === "learningreport" ? rel : false,
    });
  }, []);

  return (
    <div className="Reports">
      <div className="header-title-wrapper">
        <div className="header-title">Movimentar Usuários Entre Empresas</div>
      </div>

      <Tabs
        selectedIndex={chooseTab}
        onSelect={(tabIndex) => {
          setChooseTab(tabIndex);
          setChooseRel(initialRel);
        }}
      >
        <TabList>
          {permissionTabLearningGeneral && <Tab>Movimentar usuários</Tab>}
          {permissionTabLearningReport && <Tab>Histórico de movimentação</Tab>}
        </TabList>

        {permissionTabLearningGeneral && (
          <TabPanel>
            <MoveUsers rel={chooseRel["MoveUsers"]} setChooseTab={setChooseTab}/>
          </TabPanel>
        )}
        {permissionTabLearningReport && (
          <TabPanel>
            <MovementHistory rel={chooseRel["MovementHistory"]} />
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
}
