import React from "react";
import { Modal } from "react-bootstrap";

const QrModal = ({ showModal, baseUrl, title, body }) => {
  return (
    <Modal show={showModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <a className="btn btn-secondary" href={baseUrl}>
          OK
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export default QrModal;
