import React, { useContext, useEffect, useCallback, useState } from "react";
import "./Header.scss";
import { AuthContext } from "../context/AuthContext";
import Popover from "@material-ui/core/Popover";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Redirect } from "react-router-dom";
import ModalWarning from "../components/ModalWarning";
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";
import { TbBrandCitymapper } from "react-icons/tb";
import UpgradeTrial from "../components/PeopleCraft/UpgradeTrial";
import ContractPlan from "../components/PeopleCraft/ContractPlan";
import Text from "../components/PeopleCraft/Text";
import { FaCheck } from "react-icons/fa";
import { openModalUpgrade } from "../utils/control-modal";
import { useMemo } from "react";
const Header = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [redirectLogin, setRedirectLogin] = useState(false);
  const { loginData, signOut, updateLicense, getLicense, getDataTrivial, triggerCallBackInterval } = useContext(AuthContext);
  const {activePlan} = getLicense()
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const menuData = JSON.parse(localStorage.getItem("menu-data"));
  const appDetails = JSON.parse(localStorage.getItem("appDetails")) || null;
  const [openModal, setOpenModal] = useState(false);

  const init = async () => {
    const license = getLicense()
    if (activePlan) {
      triggerCallBackInterval(activePlan, () =>
        openModalUpgrade({
          hasRequestedUpgrade: false,
          userIsAdmin: license.userIsAdmin,
          numberRequest: 0,
        }),
      );
    }
  };

  useEffect(() => {
    init();
  }, [activePlan]);

  function logout() {
    signOut();
    setRedirectLogin(true);
  }

  function getUserPhoto() {
    return loginData?.menuData?.photo;
  }

  function getUserName() {
    return loginData?.menuData?.name;
  }

  const _getDatatrivial = useCallback(async () => {
    if (loginData) {
      const data = await getDataTrivial();
      updateLicense(data);
    }
  }, [loginData]);

  const isActiveApp = (app) => {
    let { idApp, idCompany } = menuData;
    return Boolean(app.idApp === idApp && app.idCompany === idCompany);
  };
  const clickRedirectLogin = (data) => {
    if (isActiveApp(data)) {
      return;
    }
    window.open(`https://${data.adminDomain}/?token=${data?.token}`);
  };
  useEffect(() => {
    _getDatatrivial();
  }, [_getDatatrivial]);

  const appsUserAccess = useMemo(() => {
    return menuData?.appsUserAccess?.length > 1 ? menuData.appsUserAccess : null;
  }, [menuData]);
  if (redirectLogin) {
    return <Redirect to="/login" />;
  }

  const firstLetter = getUserName() ? getUserName().substr(0, 1) : "";

  return (
    <div className="Header headerBase">
      <div className="space">
        {!!activePlan?.trial ? (
          <ContractPlan updateLicense={updateLicense} {...getLicense()} />
        ) : (
          <UpgradeTrial updateLicense={updateLicense} {...getLicense()} />
        )}
      </div>
      <div
        className="header-container"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <span style={{ marginRight: 15, fontSize: 14, "font-weight": "bold" }}>
          {getUserName()}
        </span>
        {/* <div className="menu-header-icon">
          <img style={{ color: "white", width: 50 }} src={UserIcon} />
        </div> */}

        <div className="pctAvatar avatarImg avatarHrader">
          <div className="avtContainer">
            <img src={getUserPhoto()} className="avatarImg" />

            {getUserPhoto() ? (
              <img src={getUserPhoto()} className="avatarImg" />
            ) : (
              <div className="avatarTxt">{firstLetter}</div>
            )}
          </div>

          {open ? <AiFillCaretUp /> : <AiFillCaretDown />}
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="meeting-popover-container dropdownMenuSubContent">
          <div
            className="meeting-popover-item"
            onClick={() => {
              setAnchorEl(null);
              setOpenModal(true);
            }}
          >
            <TbBrandCitymapper style={{ height: 25, width: 25, marginRight: 10 }} />
            Redirecionar para o aplicativo
          </div>

          {appsUserAccess && (
            <>
              <hr className="hr" />
              <div className="myAppList">
                <Text component={"small"} text={"Meus Aplicativos"} />
                <ul>
                  {appsUserAccess.map((app) => (
                    <li className={isActiveApp(app) ? "active" : ""}>
                      <div onClick={() => clickRedirectLogin(app)} className="link-company">
                        <span className="containerIconApp">
                          <img src={app.favIcon} alt="" />
                        </span>
                        <Text
                          component={"p"}
                          text={`${app.appName} | ${app.companyName}`}
                          limit={25}
                        />
                        {isActiveApp(app) && <FaCheck className="check-icon" />}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
          <hr className="hr" />

          <div className="meeting-popover-item" onClick={() => logout()}>
            <ExitToAppIcon style={{ height: 25, width: 25, marginRight: 10 }} />
            Sair
          </div>
        </div>
      </Popover>
      {openModal && (
        <ModalWarning
          description={
            <div style={{ textAlign: "center" }}>Você será redirecionado para aplicação!</div>
          }
          acceptAction={() => {
            window.open(`https://${appDetails.domain}/#/login?token=${menuData?.token}`);
            setOpenModal(false);
          }}
          denyAction={() => setOpenModal(false)}
        />
      )}
    </div>
  );
};

export default Header;
