import React from "react";
import NewTermButton from "./NewTermButton";

const EmptyRegister = () => {
  return (
    <div className="box-platform">
      <p className="box-title">Ainda não há nenhum termo cadastrado.</p>
      <p className="box-subtitle">Comece criando um novo termo.</p>
      <div className="margin-top">
        <NewTermButton />
      </div>
    </div>
  );
};

export default EmptyRegister;
