import "./styles.scss";

import React, { useState, useContext } from "react";

import ModalDialog from "containers/PeopleCraft/Modals/ModalDialog";
import TreeDropdown from "components/PeopleCraft/TreeDropdown";
import { useNodesTree } from "./hooks/useNodesTree";
import PlaceholderTree from "./components/PlaceholderTree";
import { LMCreateContext } from "../../Contexts/LMContext";
const NodesTree = () => {
  const [showModalRemove, setShowModalRemove] = useState(false);
  const { preload, nodeList, nodeSelectItem, nodeCurrent, viewModalDelete, deleteItem, viewTeam } =
    useNodesTree(setShowModalRemove);
    const { sidebarChatInteration } =  useContext(LMCreateContext);
  return (
    <>
      <div className="nodesTree">
        <ModalDialog
          title="Você tem certeza que deseja excluir esta página?"
          text="Essa ação é irreversivel e todos os níveis abaixo serão permanentemente removidos. Confirme sua escolha antes de prosseguir."
          variantIco="danger"
          show={showModalRemove}
          setShow={setShowModalRemove}
          btnCall={{
            className: "",
            variantBtn: "danger",
            text: "Sim, excluir permanentemente",
            callEvent: () => {
              deleteItem();
            },
          }}
        />


        <div className="homeNav">
          <div className="homeLeg">
            <div className="leg leg0">Publicado</div>
            <div className="leg leg1">Rascunho</div>
            <div className="leg leg2">Arquivado</div>
          </div>
        </div>

        <div className="treeDropdownBase">
          <div className="">
            {nodeList.length === 0 ? (
              <PlaceholderTree number={4} />
            ) : (
              <TreeDropdown
                childORG={nodeList}
                onSelectCallback={(item, e, updateAuthor) => {
                  nodeSelectItem(item, updateAuthor);
                }}
                nodeCurrent={nodeCurrent}
                preload={preload}
                deleteItem={viewModalDelete}
                viewTeam={viewTeam}
                setIsSidebarOpen={sidebarChatInteration}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NodesTree;
