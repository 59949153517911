
export const StatusObjExternalCourseInit = {
  ALL: true,
  APPROVED: true,
  REJECTED: true,
  PENDING: true,
  CANCELED: true,
};

export const PaginationInit = {
   offset: 0,
    limit: 10,
    totalRecords: 999,
    searchString: "",
    data: [],
    status: StatusObjExternalCourseInit,
};

export const StatusExternalCourse = {
  ALL: "ALL",
  APPROVED: "APPROVED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  CANCELED: "CANCELED",
};

export const StatusExternalCourseDescriptions = {
  ALL: "Todos",
  APPROVED: "Validado",
  PENDING: "Em análise",
  REJECTED: "Rejeitado",
  CANCELED: "Cancelado",
};

export const TypeExternalCourseDescriptions = {
  IN_PERSON: "Presencial",
  ONLINE: "Online",
  BLENDED: "Semi-presencial",
};


export const getFileName = (filePath) => {
    // Divide a string pelo caractere "/" e retorna o último elemento
    return filePath.split('/').pop() || '';
};

