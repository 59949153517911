import React, { useContext } from "react";
import "./styles.scss";
import { Link, useParams, withRouter } from "react-router-dom";
import { transformText, getEvaluationTypeName } from "utils/Utils";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

import { AuthContext } from "../../../../context/AuthContext";
import HeaderCrumb from "../../Components/HeaderCrumb";

type paramsPros = {
  evaluationType: string | null;
};

const EvaluationControl = () => {
  const { evaluationType } = useParams<paramsPros>();

  const { loginData } = useContext(AuthContext);
  const primaryColor = loginData?.primaryColor;
  const getMenuIcon = (icon) => {
    const IconComponent = icon;

    return <IconComponent style={{ color: "white", fontSize: 50 }} />;
  };

  const getRoutes = [
    {
      text: "Banco de Avaliações",
      icon: AssignmentIcon,
      url: `/gestao-de-avaliacoes/bank-evaluations/${evaluationType}`,
    },
    {
      text: "Banco de Questões",
      icon: AssignmentTurnedInIcon,
      url: `/gestao-de-avaliacoes/bank-questions/${evaluationType}`,
    },
  ];

  const HeaderCrumbLink = () => {
    // return "Gestão de Avaliações";
    return (
      <>
        <Link to={`/evaluation-management`}>Gestão de Avaliações</Link>
        <span className="headerCrumbTxt">
          {" / Avaliação de "}
          {getEvaluationTypeName(evaluationType)}
        </span>
      </>
    );
  };

  return (
    <div className="evaluationManagement-container">
      <HeaderCrumb>
        <HeaderCrumbLink />
      </HeaderCrumb>

      <div className="cockpit-cards-container">
        {getRoutes?.map((item, index) => {
          return (
            <Link to={`${item.url}`} className="cockpit-card" key={index}>
              <div className="cockpit-card-header" style={{ backgroundColor: primaryColor }}>
                {getMenuIcon(item.icon)}
              </div>
              <div className="cockpit-card-content">{item.text}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default withRouter(EvaluationControl);
