import { useState } from "react";
import JoditEditorContent from "components/jodit";
import HelpIcon from "@material-ui/icons/Help";
import { Button } from "react-bootstrap";
import { CircularProgress, Tooltip, withStyles } from "@material-ui/core";

const ButtonTerms = ({handlerButtonConfirmation, handlerButtonCancel, text="Salvar"}) => {

  const [loadingUpdateContent, setLoadingUpdateContent] = useState(false);
  const [sabeBlock, setSabeBlock] = useState(false);

  return <>
    <Button
      className="formTermsButtonCancel"
      variant="outline-secondary"
      onClick={() => {
        handlerButtonCancel();
      }}
    >
      Cancelar
    </Button>
    <Button
      variant="warning"
      className={`formTermsButtonSuccess ${sabeBlock ? "btnInativo" : ""} ${
        loadingUpdateContent ? "btnUpdate" : ""
      }`}
      onClick={() => {
        handlerButtonConfirmation();
      }}
      disabled={loadingUpdateContent}
    >
      {text}
      {loadingUpdateContent && (
        <CircularProgress
          size={18}
          style={{ margin: "0px 0px -3px 10px", color: "blue" }}
        />
      )}
    </Button>
  </>
}
export default ButtonTerms;

