import React, { useContext, useState, useEffect } from "react";
import "./Cockpit.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { contentGroups, getMenuData } from "../services/NexiDigitalAPI";
import { transformText, getMenuRoutes, getMenuIconTypes } from "../utils/Utils";
import TermsModal from "../components/TermsModal";

export default function Cockpit() {
  const bucketUrl = window.location.host;
  const urlToken = window.location.search.slice(7);
  const { loginData } = useContext(AuthContext);
  const primaryColor = loginData?.primaryColor;
  const [showModal, setShowModal] = useState(false);
  const appDetails = JSON.parse(localStorage.getItem("appDetails")) || null;
  const filterMenuOptions = getMenuRoutes().filter(function (opc) {
    return opc.enabled == 1;
  });

  function showTerms() {
    const {
      needSignResponsabilityTerms,
      userLicenceTermsAccepted,
      agreementVersion,
      offerTermsAccepted,
    } = getMenuData();

    if (
      needSignResponsabilityTerms ||
      userLicenceTermsAccepted !== agreementVersion ||
      (appDetails?.showOfferTerm === 1 && offerTermsAccepted === 0)
    ) {
      setShowModal(true);
    }
  }

  useEffect(() => {
    if (urlToken.length > 50) {
      // window.location.replace(`//${bucketUrl}/`);
      window.history.replaceState(null, null, `//${bucketUrl}/`);
    }
    showTerms();
    contentGroups()
      .then((res) => {
        let array = res.data.contentGroups;
        localStorage.setItem("contentGroups", JSON.stringify(array));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function getMenuIcon(idSectionType) {
    const IconComponent = getMenuIconTypes(idSectionType);

    return <IconComponent style={{ color: "white", fontSize: 50 }} />;
  }

  return (
    <div className="Cockpit">
      <div className="header-title-wrapper">
        <div className="header-title"> Início </div>
      </div>
      {showModal && <TermsModal showModal={showModal} setShowModal={(e) => setShowModal(e)} />}

      {/* {
        <Onboard />
      } */}

      <div className="cockpit-cards-container">
        {filterMenuOptions?.map((item, index) => {
          return (
            <Link
              to={`/${transformText(item.text)}/${item.sectionTo}`}
              className="cockpit-card"
              key={index}
            >
              <div className="cockpit-card-header" style={{ backgroundColor: primaryColor }}>
                {getMenuIcon(item.idSectionType)}
              </div>
              <div className="cockpit-card-content">
                <span style={{ whiteSpace: "pre-line" }}>{item.text}</span>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
