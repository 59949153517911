import React, { useState, useEffect } from "react";
import Table from "./Table";
import Filter from "./Filter";
import { listPurchaseProductReport } from "../../../../../services/NexiDigitalAPI";
import moment from "moment";
import "moment/locale/pt-br.js";

const VendasProdutos = () => {
  const [loading, setLoading] = useState(false);
  const [purchase, setPurchase] = useState([]);

  const getVendas = async (info) => {
    setLoading(true);
    const list = await listPurchaseProductReport(info);
    setPurchase(list);
    setLoading(false);
  };

  const initialInfo = {
    startDate: moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    idProduct: 1,
  };

  useEffect(() => {}, []);

  return (
    <>
      <Filter actionFilter={getVendas} initialInfo={initialInfo} loading={loading} />
      <Table List={purchase} loading={loading} />
    </>
  );
};

export default VendasProdutos;
